import {
    parseFromUser,
    parsePushRoomMessage,
    parseUserInfo,
} from "../../../common/chatconnection/messageParsers"
import { Topic } from "./base"
import type { ITopicMessage } from "./base"
import type {
    EnterLeaveAction,
    IPushEnterLeave,
    IPushRoomMessage,
    IPushTipAlert,
} from "../../../common/messageInterfaces"
import type { ArgJSONMap } from "@multimediallc/web-utils"

type PrivateRoomShow = {
    broadcaster_uid: string
    show_id: string
}

export abstract class RoomPrivateShowTopic<
    T extends ITopicMessage,
> extends Topic<T> {
    protected roomUid: string
    protected showId: string
    public constructor(roomUid: string, showId: string) {
        // Props are sent through the network, and must be kept in string notation
        super({
            broadcaster_uid: roomUid,
            show_id: showId,
        })
        if (roomUid.length === 0) {
            throw new Error("room uid cannot be empty")
        }
        if (showId.length === 0) {
            throw new Error("show ID cannot be empty")
        }
    }

    public getKey(): string {
        return `${this.getId()}:${this.roomUid}:${this.showId}`
    }

    protected initData(props: PrivateRoomShow): void {
        this.roomUid = props["broadcaster_uid"]
        this.showId = props["show_id"]
    }
}

export class PrivateRoomUserPresenceTopic extends RoomPrivateShowTopic<ITopicMessage> {
    public readonly maxListeners = 1
    public getId(): string {
        return "PrivateRoomUserPresenceTopic"
    }
}

export class PrivateRoomMessageTopic extends RoomPrivateShowTopic<IPushRoomMessage> {
    public getId(): string {
        return "PrivateRoomMessageTopic"
    }

    parseData(data: ArgJSONMap): IPushRoomMessage {
        return {
            ...super.parseData(data),
            ...parsePushRoomMessage(data),
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }
}

export class PrivateRoomTipAlertTopic extends RoomPrivateShowTopic<IPushTipAlert> {
    public getId(): string {
        return "PrivateRoomTipAlertTopic"
    }

    parseData(data: ArgJSONMap): IPushTipAlert {
        return {
            ...super.parseData(data),
            fromUser: parseFromUser(data),
            message: data.getString("message", false),
            amount: data.getNumber("amount"),
            isAnonymousTip: data.getBoolean("is_anonymous_tip"),
            toUsername: data.getString("to_username"),
            ts: Math.floor(data.getNumber("ts") * 1000),
            roomType: "private",
        }
    }
}

export class PrivateRoomEnterLeaveTopic extends RoomPrivateShowTopic<IPushEnterLeave> {
    public getId(): string {
        return "PrivateRoomEnterLeaveTopic"
    }

    parseData(data: ArgJSONMap): IPushEnterLeave {
        return {
            ...super.parseData(data),
            user: parseUserInfo(data.getParsedSubMap("user")),
            action: data.getString("action") as EnterLeaveAction,
            viewers: data.getNumber("count"),
            connections: data.getNumber("num_connections"),
        }
    }
}
