import { isiOS } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../../common/addEventListenerPolyfill"
import { Component } from "../../common/defui/component"
import { getScrollbarWidth, triggerReflow } from "../../common/DOMutils"
import { addPageAction } from "../../common/newrelic"
import { i18n } from "../../common/translation"
import { dom } from "../../common/tsxrender/dom"
import { ScrollDownIcon } from "./svg/scrollDownIcon"

const MAX_UNREAD_COUNT = 10

export interface IScrollDownButtonProps {
    scrollToBottom: () => void
    bottomStyle: string | (() => string)
}

export class ScrollDownButton extends Component<HTMLDivElement> {
    private unreadCount = 0
    private buttonContent: HTMLDivElement
    private textSpan: HTMLSpanElement

    constructor(private props: IScrollDownButtonProps) {
        super("div", props)
        this.hideElement()

        addEventListenerPoly("animationend", this.element, (event) => {
            this.buttonContent.classList.remove("flash")
        })
    }

    protected initUI(props: IScrollDownButtonProps): void {
        // All other styles are set in scrollDownButton.scss
        const wrapperStyles: CSSX.Properties = {
            maxWidth: `calc(100% - ${getScrollbarWidth()}px)`,
        }
        const contentStyles: CSSX.Properties = {
            maxWidth: `calc(100% - 8px)`,
        }
        this.element = <div className="scrollDownButtonWrapper" onClick={() => this.onClick()} style={wrapperStyles} title={i18n.scrollToBottom} data-paction="Chat" data-paction-name="ScrollDownButton">
            <div className="scrollDownButton" style={contentStyles} ref={(el: HTMLDivElement) => this.buttonContent = el}>
                <ScrollDownIcon/>
                <span ref={(el: HTMLSpanElement) => this.textSpan = el} className="scrollDownText" data-testid="scroll-down-text">
                    { i18n.scrollToBottom }
                </span>
            </div>
        </div>
    }

    public getUnreadCount(): number {
        return this.unreadCount
    }

    public incUnread(numUnread = 1): void {
        this.unreadCount += numUnread
        if (this.unreadCount > MAX_UNREAD_COUNT) {
            this.setText(i18n.tenPlusNewMessages)
        } else {
            this.setText(i18n.nNewMessages(this.unreadCount))
        }
        this.flashBackground()
    }

    public clearUnread(): void {
        this.unreadCount = 0
        this.setText(i18n.scrollToBottom)
    }

    private setText(text: string): void {
        this.textSpan.textContent = text
        if (this.isShown() && isiOS()) {
            // ios will occasionally glitch rendering the text if it's triggered on a collapsible notice entering chat.
            // Force rerendering with hide/show as a workaround
            this.hideElement()
            triggerReflow()
            this.showElement()
        }
    }

    private flashBackground(): void {
        if (!this.isShown()) {
            return
        }
        this.buttonContent.classList.add("flash")
    }

    private onClick(): void {
        addPageAction("ScrollDownButtonClicked")
        this.props.scrollToBottom()
        this.hideElement()
    }

    public repositionChildren(): void {
        this.element.style.bottom = typeof this.props.bottomStyle === "string" ? this.props.bottomStyle : this.props.bottomStyle()
    }

    public showElement(): void {
        if (this.isShown()) {
            return
        }
        // Need to check unreadCount here since history messages also trigger showElement even though the button is hidden afterwards
        if (this.unreadCount === 0) {
            addPageAction("ScrollDownButtonShown")
        }
        this.repositionChildren()
        super.showElement("flex")
    }

    public hideElement(): void {
        if (!this.isShown()) {
            return
        }
        this.buttonContent.classList.remove("flash")
        super.hideElement()
    }

    public render(): HTMLDivElement {
        return this.element
    }
}
