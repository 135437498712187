import { getCb } from "../../api"
import { Component } from "../../defui/component"
import { i18n } from "../../translation"
import { buildQueryString } from "../../urlUtil"

export abstract class BaseRoomTab<T extends object> extends Component {
    protected loadingMsg = document.createElement("div")
    protected errorMsg = document.createElement("div")
    protected showing = false
    protected showingError = false
    private params = {}
    private pathname = ""

    constructor() {
        super()
        this.element.style.position = "static"
        this.element.style.minHeight = "325px"
        this.element.style.margin = "14px"
        this.element.style.boxSizing = "border-box"
        this.element.style.width = ""
        this.element.style.height = ""
        this.element.style.paddingLeft = "8px"

        this.loadingMsg.innerText = `${i18n.loadingText}…`
        this.loadingMsg.style.fontSize = "1.2em"

        this.errorMsg.innerText = "An error occurred trying to load this tab"
        this.errorMsg.style.fontSize = "1.2em"
    }

    protected setResourceUrl(pathname: string, params: Record<string, string> = {}): void {
        this.pathname = pathname
        this.params = params
    }

    protected clearContent(): void {
        this.removeAllDOMChildren()
    }

    protected load(showLoading = true, callback?: () => void): void {
        this.showingError = false
        if (this.pathname === "") {
            this.clearContent()
            this.createContent(<T>{})
            return
        }
        if (showLoading) {
            this.clearContent()
            this.element.appendChild(this.loadingMsg)
        }
        const params = buildQueryString(this.params)
        this.fetchData(`${this.pathname}?${params}`).then((xhr) => {
            let data
            try {
                data = this.parseData(xhr.responseText)
            } catch (err) {
                this.showErrorMessage()
                error("Error parsing response from resource", {
                    "path": this.pathname,
                    "reason": err.toString(),
                })
                return
            }
            if (showLoading && this.loadingMsg.parentElement === this.element) {
                this.element.removeChild(this.loadingMsg)
            }
            this.clearContent()
            this.createContent(data)
            if (callback !== undefined) {
                callback()
            }
        }).catch(err => {
            error(`Fetch data error`, {
                pathname: this.pathname,
                reason: err.toString(),
            })
            this.showErrorMessage()
        })
    }

    protected fetchData(path: string): Promise<XMLHttpRequest> {
        return getCb(path)
    }

    protected abstract createContent(parsedData: T): void

    protected abstract parseData(rawData: string): T

    protected showErrorMessage(): void {
        this.clearContent()
        this.showingError = true
        this.element.appendChild(this.errorMsg)
    }

    protected showLoadingMessage(): void {
        this.clearContent()
        this.element.appendChild(this.loadingMsg)
    }

    public show(id?: number, callback?: () => void): void {
        this.showing = true
        this.element.style.display = ""
        this.load(true, callback)
    }

    public hide(): void {
        this.showing = false
        this.element.style.display = "none"
    }

    public checkBeforeSwitch(switchCallback: () => void): void {
        switchCallback()
    }
}
