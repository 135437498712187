import { BioTab } from "./bioTab"
import { ChatTab } from "./chatTab"
import { MoreRoomsTab } from "./moreRoomsTab"
import { PrivateTab } from "./privateTab"
import { RoomMenuTab } from "./roomMenuTab"
import { TokensTab } from "./tokensTab"
import type { BaseRoomTab } from "./baseRoomTab"
import type { MobilePlayer } from "./mobilePlayer"

export const enum TabName {
    Chat = "chat",
    Private = "private",
    Tokens = "tokens",
    Bio = "bio",
    MoreRooms = "moreRooms",
    RoomMenu = "roomMenu",
}

export class TabList {
    private chatTab: ChatTab
    private privateTab: PrivateTab
    private tokensTab: TokensTab
    private bioTab: BioTab
    private moreRoomsTab: MoreRoomsTab
    private roomMenuTab: RoomMenuTab
    private tabList: BaseRoomTab[]

    constructor(private player: MobilePlayer) {
        this.chatTab = new ChatTab()
        this.privateTab = new PrivateTab()
        this.tokensTab = new TokensTab()
        this.bioTab = new BioTab()
        this.moreRoomsTab = new MoreRoomsTab(this.player)
        this.roomMenuTab = new RoomMenuTab(this.player)

        this.tabList = [
            this.chatTab,
            this.privateTab,
            this.tokensTab,
            this.bioTab,
            this.moreRoomsTab,
            this.roomMenuTab,
        ]
    }

    public getList(): BaseRoomTab[] {
        return this.tabList
    }

    public length(): number {
        return this.tabList.length
    }

    public getTabByIndex(index: number): BaseRoomTab | undefined {
        if (index < 0 || index >= this.length()) {
            return undefined
        }

        return this.tabList[index]
    }

    public getIndexByTabName(tabName: TabName): number | undefined {
        const index = this.tabList.findIndex((tab) => tab.getTabName() === tabName)
        return index > -1 ? index : undefined
    }

    public getChatTab(): ChatTab {
        return this.chatTab
    }

    public getPrivateTab(): PrivateTab {
        return this.privateTab
    }

    public getTokensTab(): TokensTab {
        return this.tokensTab
    }

    public getBioTab(): BioTab {
        return this.bioTab
    }

    public getMoreRoomsTab(): MoreRoomsTab {
        return this.moreRoomsTab
    }

    public getRoomMenuTab(): RoomMenuTab {
        return this.roomMenuTab
    }
}
