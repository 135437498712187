import { pageContext } from "../../cb/interfaces/context"
import { featureFlagIsActive } from "../featureFlag"
import { addPageAction } from "../newrelic"
import { parseQueryString } from "../urlUtil"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { getConvivaSession, setConvivaSession } from "./playerSettings"

export function shouldUseCustomControls(): boolean {
    if (pageContext.current.isMobile === true) {
        return true
    }
    const videoMode = videoModeHandler.getVideoMode()
    // Not in videoonly embed or non IFS fullscreen
    return ![VideoMode.VideoOnly, VideoMode.Fullscreen].includes(videoMode)
}

export function convivaEnabled(): boolean {
    // override with query paramter for testing
    if (parseQueryString(window.location.search)["conviva"] !== undefined) {
        return true
    }
    let session = getConvivaSession() 
    if (session === "") {
        session = pageContext.current.contextID
        setConvivaSession(session)
    }
    // Sample to 10% of sessions
    if (session.charAt(session.length - 1) !== "0") {
        return false
    }
    return !pageContext.current.noAnalytics && featureFlagIsActive("VDPEnblConviva")
}

export function LLHLSSupported(): boolean {
    return featureFlagIsActive("VDPLLHLS") || parseQueryString(window.location.search)["force-llhls"] !== undefined
}

export function reportBroadcasterBitrate(streamType: string, bitrate: number): void {
    addPageAction("BroadcastBitrate", { "streamType": streamType, "bitrate": bitrate })
}
