/*
 * Chat settings styles are meant to style elements with the following html structure:
 * <container>
 *     <header />
 *     <rowWrapper>
 *         <label />
 *         <input />
 *     </rowWrapper>
 * </container>
 */

import { addColorClass } from "../cb/colorClasses"
import { applyStyles } from "./DOMutils"

export abstract class ChatSettingsStyles {
    public static styleContainer(container: HTMLDivElement): void {}

    public static styleHeader(header: HTMLDivElement, title: string): void {
        header.innerText = title
        applyStyles(header, {
            fontWeight: "bold",
            fontSize: "12px",
            margin: "14px 0 8px",
            backgroundColor: "",
        })
    }

    public static styleRowWrapper(element: HTMLElement): void {
        applyStyles(element, {
            margin: "4px 0 7px 4px",
        })
    }

    public static styleLabel(label: HTMLElement, title: string): void {
        label.innerText = title
        applyStyles(label, {
            fontSize: "12px",
            display: "inline-block",
            marginBottom: "5px",
        })
    }

    public static styleInput(input: HTMLElement): void {
        addColorClass(input, "chat-settings-input")
        applyStyles(input, {
            display: "block",
            fontSize: "12px",
            marginTop: "0",
            borderWidth: "1px",
            borderStyle: "solid",
        })

        if (input instanceof HTMLSelectElement) {
            addColorClass(input, "chat-settings-select")
            applyStyles(input, {
                height: "24px",
                width: "100%",
            })
        }
    }
}
