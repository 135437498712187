// Component is the basic building block of defui
import { EventRouter } from "../events"

export class Component<T extends HTMLElement = HTMLElement, P extends object = object> {
    private _children: Component[] = []
    protected hasInitUI = false
    public parent?: Component
    public element: T
    private originalDisplayStyle?: string
    didRepositionEvent = new EventRouter("didReposition", { reportIfNoListeners: false })

    // constructors should `addChild` to create child `Components` as well as
    // creating new custom DOM under `this.element`
    constructor(tagOrElement: string | T = "div", props?: P) {
        if (typeof tagOrElement === "string") {
            this.element = this.createBaseElement(tagOrElement, props)
        } else {
            this.element = tagOrElement
        }

        this.initData(props)
        this.initUI(props)

        this.element.setAttribute("ts", `${this.constructor.name}`) // eslint-disable-line @multimediallc/no-set-attribute
    }

    protected createBaseElement(tagName: string, _props?: P): T {
        const el = document.createElement(tagName) as T
        // by default, fill the entire space given to us by the parent container
        el.style.height = "100%" // TODO consider getting rid of this default
        el.style.width = "100%"
        el.style.position = "absolute"
        el.style.overflow = "hidden"
        el.style["-webkit-tap-highlight-color"] = "transparent"
        return el
    }

// initData should be used to initialize instance data what may be needed
    // by initUI (data initialization before creating the UI)
    protected initData(props?: P): void {
    }

    // initUI should be used to create and style the UI for the component
    protected initUI(props?: P): void {
    }

    // lazyInitUI should be used to create and style the UI for a component
    // in a lazy way (when the component is being added as a child to another component)
    protected lazyInitUI(): void {
        this.hasInitUI = true
    }

    // repositionChildren is run after the DOM is wired together
    // do not call it directly. instead, call `repositionChildrenRecursive` so
    // that the `didRepositionEvent` fires
    protected repositionChildren(): void {
    }

    repositionChildrenRecursive(): void {
        this.repositionChildren()
        for (const child of this.children()) {
            child.repositionChildrenRecursive()
        }
        this.didRepositionEvent.fire(undefined)
    }

    // afterDOMConstructed should run after `reposition` runs
    // it is safe to assume that the dom is wired together
    protected afterDOMConstructed(): void {
    }

    afterDOMConstructedIncludingChildren(): void {
        // debug(`afterDOMConstructedIncludingChildren ${this.constructor.name}`)
        this.afterDOMConstructed()
        for (const child of this.children()) {
            child.afterDOMConstructedIncludingChildren()
        }
    }

    // addChild returns Component `c` to facilitate function chaining
    addChild<CT extends Component>(c: CT, appendToElement?: HTMLElement): CT {
        this.attachChild(c)
        // debug(`addChild ${this.constructor.name} ${c.constructor.name}`)
        if (appendToElement === undefined) {
            appendToElement = this.element
        }
        if (!c.hasInitUI) {
            c.lazyInitUI()
        }
        appendToElement.appendChild(c.element)
        return c
    }

// addChild returns Component `c` to facilitate function chaining
    prependChild<CT extends Component>(c: CT, appendToElement?: HTMLElement): CT {
        this.attachChild(c, 0)
        // debug(`addChild ${this.constructor.name} ${c.constructor.name}`)
        if (appendToElement === undefined) {
            appendToElement = this.element
        }
        appendToElement.insertBefore(c.element, this.element.firstChild)
        return c
    }

    // addChildBeforeIndex returns Component `c` to facilitate function chaining
    addChildBeforeIndex<CT extends Component>(c: CT, index: number, appendToElement?: HTMLElement): CT {
        if (index > this._children.length || index < 0) {
            error("tried to add item before an index that does not exist")
            return c
        }
        if (appendToElement === undefined) {
            appendToElement = this.element
        }
        if (!c.hasInitUI) {
            c.lazyInitUI()
        }
        if (index === this._children.length) {
            this.attachChild(c)
            appendToElement.appendChild(c.element)
        } else {
            const insertBefore = this._children[index].element
            this.attachChild(c, index)
            appendToElement.insertBefore(c.element, insertBefore)
        }
        return c
    }

    attachChild(c: Component, index?: number): void {
        if (c.parent !== undefined && c.parent !== this) {
            c.parent.removeChild(c)
        }
        if (index === undefined) {
            this._children.push(c)
        } else {
            this._children.splice(index, 0, c)
        }
        c.parent = this
    }

    addChildBefore<CT extends Component>(c: CT, beforeComponent?: Component): CT {
        if (c.parent !== undefined && c.parent !== this) {
            c.parent.removeChild(c)
        }
        const index = beforeComponent === undefined ? -1 : this._children.indexOf(beforeComponent)
        if (index < 0) {
            this.element.appendChild(c.element)
        } else {
            this.element.insertBefore(c.element, this._children[index].element)
        }
        this._children.splice(index, 0, c)
        c.parent = this
        return c
    }

    removeChild(c: Component): void {
        const index = this._children.indexOf(c)
        if (index === -1) {
            error("tried removing component that doesn't exist")
            return
        }
        this._children.splice(index, 1)
        if (c.parent === this) {
            c.parent = undefined
        }
        this.cleanupChildDOM(c)
        for (const child of c.children()) {
            child.afterRemovedFromParent()
        }
        c.afterRemovedFromParent()
    }

    private cleanupChildDOM(c: Component): void {
        const parent = c.element.parentNode
        if (parent !== null) {
            parent.removeChild(c.element)
        } else {
            error("couldn't find parent element to use for node removal")
        }
    }

    removeAllChildren(): void {
        for (const c of this.children()) {
            if (c.parent === this) {
                c.parent = undefined
            }
            this.cleanupChildDOM(c)
            c.afterRemovedFromParent()
        }
        this._children = []
    }

    removeAllDOMChildren(): void {
        while (this.element.firstChild !== null) {
           this.element.removeChild(this.element.firstChild)
        }
    }

    children(): Component[] {
        return this._children
    }

    siblings(): Component[] {
        return this.parent === undefined ? [] : this.parent._children.filter(c => c !== this)
    }

    protected afterRemovedFromParent(): void {
    }

    showElement(defaultDisplay = "block"): void {
        this.element.style.display = this.originalDisplayStyle === undefined ? defaultDisplay : this.originalDisplayStyle
    }

    showOrHideElement(show: boolean, defaultDisplay = "block"): void {
        if (show) {
            this.showElement(defaultDisplay)
        } else {
            this.hideElement()
        }
    }

    hideElement(): void {
        if (this.element.style.display !== "none") {
            if (this.element.style.display !== "") {
                this.originalDisplayStyle = this.element.style.display
            }
            this.element.style.display = "none"
        }
    }

    isShown(): boolean {
        return this.element.style.display !== "none"
    }

    toggleShowHide(): void {
        if (this.isShown()) {
            this.hideElement()
        } else {
            this.showElement()
        }
    }

    previousSibling(): Component | undefined {
        if (this.parent === undefined) {
            return undefined
        }
        return this.findPreviousSibling(this.parent.children())
    }

    nextSibling(): Component | undefined {
        if (this.parent === undefined) {
            return undefined
        }
        return this.findPreviousSibling(this.parent.children().slice().reverse())
    }

    lastChild(): Component | undefined {
        return this.children().length > 0 ? this.children()[this.children().length - 1] : undefined
    }

    private findPreviousSibling(children: Component[]): Component | undefined {
        let prev: Component | undefined
        for (const child of children) {
            if (child === this) {
                break
            }
            prev = child
        }
        return prev
    }
}
