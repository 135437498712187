import { durationToSeconds } from "../duration";
import { deleteCookie, getCookieOrUndefined } from "./cookie";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isExpirationObj = (obj) => {
    return (typeof obj === "object" &&
        obj["expiration"] !== undefined &&
        typeof obj["expiration"] === "number");
};
const unmarshalWithExpiration = (value, onExpired) => {
    try {
        const unmarshaled = JSON.parse(value);
        if (!isExpirationObj(unmarshaled)) {
            onExpired();
            return undefined;
        }
        if (unmarshaled.expiration < new Date().getTime()) {
            onExpired();
            return undefined;
        }
        return unmarshaled;
    }
    catch (e) {
        return undefined;
    }
};
export function getSessionStorageWithExpiration(name) {
    var _a;
    const stored = window.sessionStorage.getItem(name);
    if (stored === null) {
        return undefined;
    }
    const onExpired = () => window.sessionStorage.removeItem(name);
    return (_a = unmarshalWithExpiration(stored, onExpired)) === null || _a === void 0 ? void 0 : _a.value;
}
export function setSessionStorageWithExpiration(name, value, duration) {
    const d = new Date();
    d.setTime(d.getTime() + 1000 * durationToSeconds(duration));
    const marshaled = JSON.stringify({ value, expiration: d.getTime() });
    window.sessionStorage.setItem(name, marshaled);
}
export function getLocalStorageWithExpiration(name) {
    var _a;
    const stored = window.localStorage.getItem(name);
    if (stored === null) {
        return undefined;
    }
    const onExpired = () => window.localStorage.removeItem(name);
    return (_a = unmarshalWithExpiration(stored, onExpired)) === null || _a === void 0 ? void 0 : _a.value;
}
export function setLocalStorageWithExpiration(name, value, duration) {
    const d = new Date();
    d.setTime(d.getTime() + 1000 * durationToSeconds(duration));
    const marshaled = JSON.stringify({ value, expiration: d.getTime() });
    window.localStorage.setItem(name, marshaled);
}
export function getCookieReplaceWithLocalStorage(name, duration) {
    const cookieValue = getCookieOrUndefined(name);
    if (cookieValue !== undefined) {
        setLocalStorageWithExpiration(name, cookieValue, duration);
        deleteCookie(name);
    }
    return cookieValue;
}
