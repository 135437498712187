// eslint-disable-next-line complexity, @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function deepCompare(x: any, y: any, skipFields: string[] = []): boolean {
    if (isNaN(x) && isNaN(y) && typeof x === "number" && typeof y === "number") {
        return true
    }
    if (x === y) {
        return true
    }

    if (x instanceof Function) {
        return x === y
    }

    if (
        (typeof x === "function" && typeof y === "function") ||
        (x instanceof Date && y instanceof Date) ||
        (x instanceof RegExp && y instanceof RegExp) ||
        (x instanceof String && y instanceof String) ||
        (x instanceof Number && y instanceof Number)) {
        return x.toString() === y.toString()
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
        return false
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
        return false
    }

    if (x.constructor !== y.constructor) {
        return false
    }

    if (x.prototype !== y.prototype) {
        return false
    }

    // Quick checking of one object being a subset of another.
    for (const p in y) {
        if (skipFields.indexOf(p) >= 0) {
            continue
        }
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
            return false
        } else if (typeof y[p] !== typeof x[p]) {
            return false
        }
    }

    for (const p in x) {
        if (skipFields.indexOf(p) >= 0) {
            continue
        }
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
            return false
        } else if (typeof y[p] !== typeof x[p]) {
            return false
        }

        switch (typeof (x[p])) {
            case "object":
            case "function":
                if (!deepCompare(x[p], y[p], skipFields)) {
                    return false
                }
                break

            default:
                if (x[p] !== y[p]) {
                    return false
                }
                break
        }
    }
    return true
}
