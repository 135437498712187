import { ArgJSONMap } from "@multimediallc/web-utils"
import { SubSystemType } from "../../../common/debug"
import { EventRouter } from "../../../common/events"
import { PushServiceClient } from "../baseClient"
import { ConnectionState, SubscriptionState } from "../states"
import { HermodAuthProvider } from "./hermodAuth"
import type { HermodContext } from "./hermodContext"
import type { IClientCallbacks } from "../baseClient"
import type { IConnectionStateChange } from "../states"
import type { IPushPresencePayload } from "../topics/topicManager"

const enum Action {
    HEARTBEAT = 0,
    ACK = 1,
    NACK = 2,
    CONNECT = 3,
    CONNECTED = 4,
    DISCONNECT = 5,
    DISCONENCTED = 6,
    CLOSE = 7,
    CLOSED = 8,
    ERROR = 9,
    ATTACH = 10,
    ATTACHED = 11,
    DETACH = 12,
    DETACHED = 13,
    PRESENCE = 14,
    MESSAGE = 15,
    SYNC = 16,
    AUTH = 17,
}

export const HERMOD_CLIENT_NAME = "hermod"

export class HermodClient extends PushServiceClient {
    context: HermodContext
    public readonly auth: HermodAuthProvider
    public readonly clientName = HERMOD_CLIENT_NAME

    private websocket?: WebSocket

    private connectionState = ConnectionState.unknown

    constructor(callbacks: IClientCallbacks) {
        super(callbacks)
        this.connectionChange = new EventRouter<IConnectionStateChange>("HermodClientConnection")

        this.auth = new HermodAuthProvider(this)
    }

    // Connection Handling

    public close(): void {
        this.websocket?.send("send closing message")
        this.websocket?.close()
    }

    protected _connect(): void {
        this.auth.fetchAccessToken().then((accessToken) => {
            const usingProxy = this.auth.context?.settings.url === undefined
            const url = usingProxy ? `${window.location.hostname}:${window.location.port}/hermod` : `${this.auth.context?.settings.url}`
            this.websocket = new WebSocket(`ws://${url}`)
            this.websocket.onmessage = event => {
                const args = new ArgJSONMap(event.data)
                const action = args.getNumber("action")
                const msgChannelName = args.getString("channel", false)
                switch(action) {
                    case Action.CONNECTED:
                        this.connectionChange.fire({
                            previous: this.connectionState,
                            current: ConnectionState.connected,
                        })
                        this.connectionState = ConnectionState.connected
                        break
                    case Action.DISCONENCTED:
                        this.connectionChange.fire({
                            previous: this.connectionState,
                            current: ConnectionState.disconnected,
                        })
                        this.connectionState = ConnectionState.disconnected
                        break
                    case Action.ERROR:
                        warn("Error on websocket", args, SubSystemType.PushService)
                        break
                    case Action.ATTACHED:
                        // handleSubscribed is called after _subscribe
                        break
                    case Action.DETACHED:
                        // handleUnsubscribed is called after _unsubscribe
                        break
                    case Action.MESSAGE:
                        const messages = args.getList("messages")
                        messages?.forEach((m, index) => {
                            this.checkForReauth(m)
                            const id = `${args.getString("id")}:${index}`
                            const messageData = new ArgJSONMap({
                                ...m.getParsed(),
                                "channel": msgChannelName,
                                "providerData": {
                                    "id": id,
                                    "ts": args.getNumber("timestamp"),
                                },
                            })
                            const topicIdToKeyMap = this.getChannelTopicMap(msgChannelName)
                            let topicKeyToFire
                            if (topicIdToKeyMap !== undefined) {
                                topicKeyToFire = topicIdToKeyMap.get(messageData.getString("_topic"))
                            }
                            if (topicKeyToFire !== undefined) {
                                if (this.handleMessageDuplicate(msgChannelName, messageData, messageData.getString("tid"))) {
                                    return
                                }
                                this.callbacks.onMessage(this.clientName, topicKeyToFire, messageData)
                            } else if (messageData.getStringOrUndefined("_sm") !== "o"){
                                warn("Received message for unknown topic", {
                                    "topic": messageData.getString("_topic"),
                                    "client": this.clientName,
                                })
                            }
                        })
                        break
                }
            }
            this.websocket.onopen = () => {
                this.websocket?.send(JSON.stringify({
                    action: Action.CONNECT,
                    message: { accessToken: accessToken },
                }))
            }
        }).catch((err) => {
            error(err)
        })
        this.connectionState = ConnectionState.connecting
    }

    public updateAuth(): Promise<void> {
        if (!this.isConnected()) {
            return this.connect()
        }

        return this.auth.fetchAccessToken().then(accessToken => {
            if (this.websocket === undefined) {
                return Promise.reject("Invalid websocket during update")
            }
            this.websocket.send(JSON.stringify({
                action: Action.AUTH,
                message: { accessToken: accessToken },
            }))
            return Promise.resolve()
        }).catch((err) => {
            error(err)
        })
    }

    public getConnectionState(): ConnectionState {
        return this.connectionState
    }

    protected getChannelState(channelName: string): SubscriptionState {
        return SubscriptionState.unknown
    }

    public getReconnectCount(): number {
        // Not currently supported
        return -1
    }

    // Subscription Handling
    public _subscribe(topicKey: string): Promise<void> {
        const channelName = this.getChannelName(topicKey)
        if (channelName === undefined) {
            return Promise.reject("invalid channel name reached subscribe")
        }
        return this.ensureConnectedAndAuthed(topicKey).then(() => {
            if (this.websocket === undefined) {
                return Promise.reject("websocket undefined")
            }
            this.websocket.send(JSON.stringify({
                "action": Action.ATTACH,
                "channelName": channelName,
            }))
            return Promise.resolve()
        })
    }

    public _unsubscribe(topicKey: string): Promise<void> {
        const channelName = this.getChannelName(topicKey)
        if (channelName === undefined) {
            return Promise.reject("invalid channel name reached unsubscribe")
        }
        return this.ensureConnectedAndAuthed(topicKey).then(() => {
            if (this.websocket === undefined) {
                return Promise.reject("websocket undefined")
            }
            this.websocket.send(JSON.stringify({
                "action": Action.DETACH,
                "channelName": channelName,
            }))
            return Promise.resolve()
        })
    }

    public enterPresence(topicKey: string, payload?: IPushPresencePayload): Promise<void> {
        const channelName = this.getChannelName(topicKey)
        if (channelName === undefined) {
            return Promise.reject("invalid channel name")
        }
        return this.ensureConnectedAndAuthed(topicKey).then(() => {
            return this.subscribe(topicKey)
        }).then(() => {
            if (this.websocket === undefined) {
                return Promise.reject("websocket undefined")
            }
            this.websocket.send(JSON.stringify({
                "action": Action.PRESENCE,
                "channelName": channelName,
                "message": payload?.data ?? {},
            }))
            return Promise.resolve()
        })
    }

    public leavePresence(topicKey: string): Promise<void> {
        return this.unsubscribe(topicKey)
    }
}
