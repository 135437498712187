import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { pageContext } from "../../interfaces/context"
import { ResultType } from "./inputSuggestions"

const SEARCH_HISTORY_KEY = "searchHistory"
export const MAX_SHOW_HISTORY = 5

export interface ISearchHistory {
    content: string
    type: ResultType
}

export function getUserHistoryKey(): string {
    return pageContext.current.loggedInUser !== undefined ? `${SEARCH_HISTORY_KEY}:${pageContext.current.loggedInUser.username}` : ""
}

export function saveSearchHistory(input: string, type: ResultType): void {
    // Add search history to cookie for logged-in users.
    if (!isLocalStorageSupported()){
        return
    }
    if (pageContext.current.loggedInUser !== undefined && input !== "") {
        const keyword = input.toLowerCase().trim()
        const searchHistory = window.localStorage.getItem(getUserHistoryKey())
        const newEntry = { content: keyword, type: type }
        let newSearchHistory = JSON.stringify([newEntry])
        if (searchHistory !== null) {
            const searches = JSON.parse(searchHistory)
            // Remove if duplicate
            let index = 0
            for (const search of searches) {
                const searchEntry = search as ISearchHistory
                if (searchEntry.content === keyword) {
                    searches.splice(index, 1)
                    break
                }
                index += 1
            }

            // Add to beginning of list
            searches.unshift(newEntry)
            // Remove from end of list if max
            if (searches.length > MAX_SHOW_HISTORY) {
                searches.pop()
            }
            newSearchHistory = JSON.stringify(searches)
        }
        window.localStorage.setItem(getUserHistoryKey(), newSearchHistory)
    }
}

export function deleteFromSearchHistory(keyword: string): void {
    if (!isLocalStorageSupported()) {
        return
    }
    // Find and delete text from autocomplete search cookie
    const searchHistory = window.localStorage.getItem(getUserHistoryKey())
    if (searchHistory !== null) {
        const searches = searchHistory !== null ? JSON.parse(searchHistory) : []
        searches.splice(searches.findIndex((obj: ISearchHistory) => obj.content === keyword), 1)
        const newSearchHistory = JSON.stringify(searches)
        window.localStorage.setItem(getUserHistoryKey(), newSearchHistory)
    }
}

export function cleanSearchHistory(): void {
    const searchHistory = window.localStorage.getItem(getUserHistoryKey())
    if (searchHistory !== null) {
        try {
            const searches = JSON.parse(searchHistory)
            for (const search of searches) {
                if (search["content"] === undefined || search["type"] === undefined) {
                    window.localStorage.removeItem(getUserHistoryKey())
                    return
                }
            }
        } catch {
            warn("Failed to parse and iterate search history, clearing", { searchHistory })
            window.localStorage.removeItem(getUserHistoryKey())
            return
        }
    }
}
