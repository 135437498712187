import { EventRouter } from "../../../common/events"

// Roll our own type bc React's PointerEvent is incompatible with TS PointerEvent, and importing React's even under an alias breaks tsx typing
export type DmInputFocusSource = HTMLElement | { target: EventTarget | null, preventDefault: () => void }

export const requestDmInputFocus = new EventRouter<DmInputFocusSource>("requestDmInputFocus", { listenersWarningThreshold: 1, reportIfNoListeners: false })

export function handleDmInputFocus(source: DmInputFocusSource): void {
    if (requestDmInputFocus.listenerCount() > 0) {
        requestDmInputFocus.fire(source)
    } else if (source instanceof HTMLElement) {
        source.focus()
    }
}
