import { ArgJSONMap } from "@multimediallc/web-utils"
import { parseRoomListSource } from "../cb/roomList"
import { modalAlert } from "./alerts"
import { getCbAndXhr } from "./api"
import { parseRoomStatus, RoomStatus } from "./roomStatus"
import type { IRoomContext } from "./context"
import type { IRoomStatusChangeNotification } from "./messageInterfaces"
import type { IDismissibleMessage } from "./theatermodelib/dismissibleMessages"
import type { ILatencyUpdate, IQualityUpdate } from "../cb/pushservicelib/topics/room"
import type { RoomListSource } from "../cb/roomList"

type DjangoDismissableMessage = {
    id: string
    message_html: string
}

export const enum UserListSortOptions {
    Alphabetical = "a",  // Sorted alphabetically within each color group
    Tokens = "t",  // Sorted by total owned tokens
}

export interface IAppInfo {
    app_name: string
    app_location: string
}

export interface IAnchorInfo {
    text: string
    href: string
}

export enum EnterLeaveSettings {
    AllUsers = 0,
    ModsFansAndTokens,
    ModsAndFans,
    None,
}

export const enum C2CNotificationLimit {
    None = 0,
    FiveMinutes = 300,
    Forever = -1,
}

export interface ISatisfactionScore {
    upVotes: number
    downVotes: number
    percent: number
}

export const enum FollowNotificationFrequency {
    ALL = "all",
    SMART = "smart",
    NONE = "none",
}

export function parseFollowNotificationFrequency(setting: string): FollowNotificationFrequency {
    switch (setting) {
        case FollowNotificationFrequency.ALL:
            return FollowNotificationFrequency.ALL
        case FollowNotificationFrequency.NONE:
            return FollowNotificationFrequency.NONE
        default:
            return FollowNotificationFrequency.SMART
    }
}

export function convertEnterLeaveSettings(setting: string): EnterLeaveSettings {
    switch (setting) {
        case "orga":
            return EnterLeaveSettings.AllUsers
        case "orgb":
            return EnterLeaveSettings.ModsFansAndTokens
        case "org":
            return EnterLeaveSettings.ModsAndFans
        case "none":
            return EnterLeaveSettings.None
        default:
            return EnterLeaveSettings.ModsFansAndTokens
    }
}

export function convertC2CNotificationLimit(setting: number): C2CNotificationLimit {
    const c2cSetting = setting as C2CNotificationLimit
    const options = [C2CNotificationLimit.None, C2CNotificationLimit.FiveMinutes, C2CNotificationLimit.Forever]
    if (options.includes(c2cSetting)) {
        return c2cSetting
    } else {
        error("roomDossier - invalid C2C notification limit")
        return C2CNotificationLimit.FiveMinutes
    }
}

export interface IUserChatSettings {
    fontSize: string
    fontColor: string
    fontFamily: string
    showEmoticons: boolean
    emoticonAutocompleteDelay: number
    sortUsersKey: UserListSortOptions
    modExpiration: number
    roomEntryFor: EnterLeaveSettings
    roomLeaveFor: EnterLeaveSettings
    c2cNotificationLimit: C2CNotificationLimit
    silenceBroadcasters: string
    ignoredUsers: string
    allowedChat: string
    tipVolume: number
    highestTokenColor: string
    collapseNotices: boolean
}

export interface IStaffInfo {
    staffLinks: IAnchorInfo[]
    autoLoadAdminInfo: boolean
    previousUsernames?: string
    deletionAdminNotice?: string
}

export interface IUserColors {
    hasTokens: boolean
    tippedRecently: boolean
    tippedAlotRecently: boolean
    tippedTonsRecently: boolean
}

export interface IRoomDossier {
    userColors?: IUserColors
    viewerUid?: string,
    appsRunning: IAppInfo[]
    userChatSettings: IUserChatSettings
    host: string
    room: string
    roomUid: string
    roomTitle: string
    roomPasswordHash: string
    lastSubmittedPasswordHash: string
    chatRules: string
    chatUserName: string
    userName: string
    viewerPassword: string
    roomGender: string
    roomStatus: RoomStatus
    isModerator: boolean
    broadcasterIsOnNewChat: boolean
    hlsSource: string
    edgeAuth: string
    viewerGender: string
    hiddenMessage: string
    isAgeVerified: boolean
    age?: number
    isWidescreen: boolean
    allowPrivateShow: boolean
    allowAnonymousTipping: boolean
    privatePrice: number
    privateMinMinutes: number
    allowShowRecordings: boolean
    spyPrice: number
    fanClubSpyPrice?: number
    premiumPrivatePrice: number
    premiumPrivateMinMinutes: number
    premiumShowActive: boolean
    privateShowId: string
    following: boolean
    followNotificationFrequency: FollowNotificationFrequency
    hasLowSatisfactionScore: boolean
    tokenBalance: number
    isSupporter: boolean
    needsSupporterToPm: boolean
    serverName: string
    numFollowed: number
    numFollowedOnline: number
    hasStudio: boolean
    isMobile: boolean
    ignoredEmoticons: string[]
    satisfactionScore: ISatisfactionScore
    hideSatisfactionScore: boolean
    tipsInPast24Hours: number
    lastVoteInPast24Hours: number | undefined
    lastVoteInPast90DaysDown: boolean
    tfaEnabled: boolean
    dismissibleMessages: IDismissibleMessage[]
    showMobileSiteBannerLink: boolean
    numViewers: number
    staffInfo?: IStaffInfo
    exploringHashTag: string
    sourceName: RoomListSource
    hasFanClub: boolean
    isInFanClub: boolean
    paidTokensFanClub: boolean
    quality?: IQualityUpdate
    latency?: ILatencyUpdate
    aspAuthUrl: string
    browserId: string
    edgeRegion: string
    userlistColor: string
    activePassword: boolean
    summaryCardImage?: string
}

let currentXMLHttpRequest: XMLHttpRequest | undefined

export function parseApps(apps: string): IAppInfo[] {
    const appsRunning: IAppInfo[] = []
    const apps_running = apps
    if (apps_running !== "") {
        try {
            const apps_array: string[][] = JSON.parse(apps_running)
            apps_array.forEach((app: string[]) => {
                appsRunning.push({ app_name: app[0], app_location: app[1] })
            })
        } catch (e) {
            error(`Error parsing apps ${e.toString()}`)
        }
    }
    return appsRunning
}

function parseDismissibleMessages(messages: DjangoDismissableMessage[]): IDismissibleMessage[] {
    const parsedMessages: IDismissibleMessage[] = []
    for (const message of messages) {
        parsedMessages.push({ idOrName: message["id"], messageHtml: message["message_html"] })
    }
    return parsedMessages
}

export function parseUserColors(settings?: ArgJSONMap): IUserColors | undefined {
    if (settings === undefined) {
        return undefined
    }
    return {
        hasTokens: settings.getBoolean("has_tokens"),
        tippedRecently: settings.getBoolean("tipped_recently"),
        tippedAlotRecently: settings.getBoolean("tipped_alot_recently"),
        tippedTonsRecently: settings.getBoolean("tipped_tons_recently"),
    }
}

function parseQualityUpdateMessage(settings?: ArgJSONMap): IQualityUpdate | undefined {
    if (settings === undefined) {
        return undefined
    }
    return {
        quality: settings.getString("quality"),
        rate: settings.getNumber("rate"),
        stopped: settings.getBoolean("stopped"),
    }
}

export function parseRoomDossierMap(settings: ArgJSONMap): IRoomDossier {
    const chatSettings = settings.getMap("chat_settings")
    const satisfactionScoreMap = settings.getMap("satisfaction_score")
    const roomDossier: IRoomDossier = {
        host: settings.getString("wschat_host"),
        room: settings.getString("broadcaster_username"),
        roomUid: settings.getString("room_uid"),
        viewerUid: settings.getStringOrUndefined("viewer_uid"),
        roomPasswordHash: settings.getString("room_pass"),
        lastSubmittedPasswordHash: settings.getString("last_pass", false), // TODO remove reportError=false once #16821 deploys (https://multimediallc.leankit.com/card/30502080123419)
        chatRules: settings.getString("chat_rules"),
        roomTitle: settings.getString("room_title"),
        roomStatus: parseRoomStatus(settings),
        isSupporter: settings.getBoolean("is_supporter"),
        needsSupporterToPm: settings.getBoolean("needs_supporter_to_pm"),
        roomGender: settings.getString("broadcaster_gender"),
        userName: settings.getString("viewer_username"),
        chatUserName: settings.getString("chat_username"),
        edgeAuth: encodeURI(settings.getString("edge_auth")),
        viewerPassword: settings.getString("chat_password"),
        viewerGender: settings.getString("viewer_gender"),
        exploringHashTag: settings.getString("exploring_hashtag"),
        sourceName: parseRoomListSource(settings.getString("source_name", false)),
        hlsSource: settings.getString("hls_source"),
        isWidescreen: settings.getBoolean("is_widescreen"),
        appsRunning: parseApps(settings.getString("apps_running")),
        allowPrivateShow: settings.getBoolean("allow_private_shows"),
        privatePrice: settings.getNumber("private_show_price"),
        privateMinMinutes: settings.getNumber("private_min_minutes"),
        allowShowRecordings: settings.getBoolean("allow_show_recordings"),
        allowAnonymousTipping: settings.getBoolean("allow_anonymous_tipping"),
        spyPrice: settings.getNumber("spy_private_show_price"),
        fanClubSpyPrice: settings.getNumberOrUndefined("fan_club_spy_private_show_price"),
        premiumPrivatePrice: settings.getNumberOrUndefined("premium_private_price") ?? 0,
        premiumPrivateMinMinutes: settings.getNumberOrUndefined("premium_private_min_minutes") ?? 0,
        premiumShowActive: settings.getBooleanOrUndefined("premium_show_running") ?? false,
        privateShowId: settings.getStringWithNumbers("private_show_id"),
        hasLowSatisfactionScore: settings.getBoolean("low_satisfaction_score"),
        isAgeVerified: settings.getBoolean("is_age_verified"),
        age: settings.getNumberOrUndefined("age"),
        hiddenMessage: settings.getString("hidden_message"),
        following: settings.getBoolean("following"),
        followNotificationFrequency: parseFollowNotificationFrequency(settings.getString("follow_notification_frequency", false)),
        isModerator: settings.getBoolean("is_moderator"),
        broadcasterIsOnNewChat: settings.getBoolean("broadcaster_on_new_chat"),
        tokenBalance: settings.getNumber("token_balance"),
        serverName: settings.getString("server_name"),
        numFollowed: settings.getNumber("num_followed"),
        numFollowedOnline: settings.getNumber("num_followed_online"),
        hasStudio: settings.getBoolean("has_studio"),
        isMobile: settings.getBoolean("is_mobile"),
        ignoredEmoticons: settings.getObject("ignored_emoticons") as string[],
        hideSatisfactionScore: settings.getBoolean("hide_satisfaction_score"),
        tipsInPast24Hours: settings.getNumber("tips_in_past_24_hours"),
        lastVoteInPast24Hours: settings.getNumberOrUndefined("last_vote_in_past_24_hours"),
        lastVoteInPast90DaysDown: settings.getBoolean("last_vote_in_past_90_days_down"),
        dismissibleMessages: parseDismissibleMessages(settings.getObject("dismissible_messages") as DjangoDismissableMessage[]),
        showMobileSiteBannerLink: settings.getBoolean("show_mobile_site_banner_link"),
        numViewers: settings.getNumber("num_viewers"),
        isInFanClub: settings.getBoolean("fan_club_is_member"),
        hasFanClub: settings.getBoolean("performer_has_fanclub"),
        paidTokensFanClub: settings.getBoolean("fan_club_paid_with_tokens"),
        satisfactionScore: {
            upVotes: satisfactionScoreMap.getNumber("up_votes", false),
            downVotes: satisfactionScoreMap.getNumber("down_votes", false),
            percent: satisfactionScoreMap.getNumber("percent", false),
        },
        tfaEnabled: settings.getBoolean("tfa_enabled"),
        userChatSettings: {
            fontColor: chatSettings.getString("font_color"),
            fontFamily: chatSettings.getString("font_family"),
            fontSize: chatSettings.getString("font_size"),
            showEmoticons: chatSettings.getBoolean("show_emoticons"),
            emoticonAutocompleteDelay: chatSettings.getNumber("emoticon_autocomplete_delay"),
            highestTokenColor: chatSettings.getString("highest_token_color"),
            sortUsersKey: chatSettings.getString("sort_users_key") as UserListSortOptions,
            modExpiration: chatSettings.getNumber("mod_expire"),
            roomEntryFor: convertEnterLeaveSettings(chatSettings.getString("room_entry_for")),
            roomLeaveFor: convertEnterLeaveSettings(chatSettings.getString("room_leave_for")),
            c2cNotificationLimit: convertC2CNotificationLimit(chatSettings.getNumber("c2c_notify_limit")),
            silenceBroadcasters: chatSettings.getString("silence_broadcasters"),
            ignoredUsers: chatSettings.getString("ignored_users"),
            allowedChat: chatSettings.getString("allowed_chat"),
            tipVolume: chatSettings.getNumber("tip_volume"),
            collapseNotices: chatSettings.getBoolean("collapse_notices"),
        },
        aspAuthUrl: settings.getString("asp_auth_url"),
        browserId: settings.getString("browser_id"),
        quality: parseQualityUpdateMessage(settings.getParsedSubMapOrUndefined("quality", true)),
        latency: {
            localTimeTranscoderInput: undefined,
            streamTimeTranscoderInput: undefined,
            localTimeSegmentStart: undefined,
            streamTimeSegmentStart: undefined,
        },
        edgeRegion: settings.getString("edge_region"),
        userlistColor: settings.getString("userlist_color"),
        activePassword: settings.getBoolean("active_password"),
        summaryCardImage: settings.getStringOrUndefined("summary_card_image"),
    }

    const staffLinks = settings.getStringOrUndefined("staff_links")
    if (staffLinks !== undefined) {
        roomDossier.staffInfo = {
            staffLinks: JSON.parse(staffLinks),
            autoLoadAdminInfo: settings.getBoolean("auto_load_admin_info"),
            previousUsernames: settings.getStringOrUndefined("previous_usernames"),
            deletionAdminNotice: settings.getStringOrUndefined("deletion_admin_notice"),
        }
    }

    const userColors = settings.getParsedSubMapOrUndefined("user_colors", true)
    if (userColors !== undefined) {
        roomDossier.userColors = parseUserColors(userColors)
    }

    settings.logUnusedDebugging("getRoomDossier")
    return roomDossier
}

export function parseRoomDossier(username: string, rawDossier: string): Promise<IRoomDossier> {
    const settings = new ArgJSONMap(rawDossier)
    return new Promise((resolve, reject) => {
        if (settings.getNumber("status", false) >= 400) {
            reject(`Bad response from server: ${rawDossier}`)
            return
        }
        resolve(parseRoomDossierMap(settings))
    })
}

export function getRoomDossier(username: string): Promise<IRoomDossier> {
    if (currentXMLHttpRequest !== undefined) {
        currentXMLHttpRequest.abort()
        currentXMLHttpRequest = undefined
    }
    return new Promise((resolve, reject) => {
        const promiseAndXhr = getCbAndXhr(`api/chatvideocontext/${username}/`)
        currentXMLHttpRequest = promiseAndXhr[0]
        promiseAndXhr[1].then(xhr => {
            resolve(parseRoomDossier(username, xhr.responseText))
        }).catch(e => {
            if (e.xhr.status === 404) {
                modalAlert("HTTP 404 - Page Not Found\n\nIt's probably just a broken link, or perhaps a cancelled broadcaster.")
                e.xhr.abort()
                return
            }
            reject(e)
        })
    })
}

export function areRoomsInSync(context: IRoomContext, status: IRoomStatusChangeNotification): boolean {
    let inSync = true
    if (status.previousStatus !== RoomStatus.Public &&
        ((context.chatConnection.status === RoomStatus.PrivateRequesting && status.currentStatus === RoomStatus.PrivateRequesting))) {
        inSync = false
    }
    return inSync
}

export function syncRooms(context: IRoomContext, status: IRoomStatusChangeNotification): Promise<void> {
    return getRoomDossier(context.chatConnection.room()).then(
        (dossier): void => {
            context.dossier = dossier
            context.chatConnection.status = dossier.roomStatus
            status.currentStatus = dossier.roomStatus
        })
}
