import { getBindings } from "../tsxrender/dom"
import { Component } from "./component"

/**
 * @class HtmlComponent
 * @extends Component
 * @description A component that is initialized with inline TSX style html so there is no need to pass in a tag name
 * or an element.
 * @example
 * class MyComponent extends HtmlComponent<HTMLDivElement, MyProps> {
 *     createElement(props: MyProps): HTMLDivElement {
 *          return <div>{props.content}</div>
 *     }
 * }
 */
export class HTMLComponent<T extends HTMLElement = HTMLElement, P extends object = object, S extends object = object> extends Component<T, P> {
    protected state: S

    constructor(props: P) {
        super(undefined, props)
    }

    protected initData(props: P): void {
        super.initData(props)
    }

    protected createBaseElement(_: string, props: P): T {
        this.state = this.initState(props)
        return this.createElement(props)
    }

    protected createElement(props: P): T {
        return super.createBaseElement("div", props)
    }

    render(): T {
        return this.element
    }

    /**
     * Sets the state of the component and calls `updateState` to update the component and its children.
     * See the example.tsx for samples of using `bind` attribute in the TSX.
     * @param state The new state of the component.
     */
    setState(state: S): void {
        for (const key in state) {
            if (state.hasOwnProperty(key)) {
                this.state[key] = state[key]
            }
        }
        this.updateState()
    }

    /**
     * For the components that have their element specified as TSX, this method will update any HTML attributes
     * specified in the `bind` attribute. Also, for any other `Component` that is specified in TSX with will be
     * considered as subcomponents their updateState() method is called which in turn will update the attributes
     * specified in the `bind` attribute.
     */
    updateState(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
        const bindings: (HTMLComponent | Function)[] = getBindings(this.element)
        bindings.forEach(b => {
            if (b instanceof Function) {
                b()
            } else if (b !== this) {
                b.updateState()
            }
        })
    }

    private initState(props: P): S {
        return {} as S
    }
}
