import { addColorClass } from "../../cb/colorClasses"
import { BaseSendTipButton, ButtonMenuItem } from "../../cb/ui/tipping"

export class SendTipButton extends BaseSendTipButton {
    protected styleButtonMenuItem(menuItem: ButtonMenuItem): void {
        menuItem.dt.style.fontWeight = "bold"
    }

    protected createCommonButton(): HTMLButtonElement {
        const button = super.createCommonButton()
        addColorClass(button, "mobile")
        button.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"
        button.style.verticalAlign = "text-top"
        button.style.lineHeight = "16px"
        return button
    }
}
