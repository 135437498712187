import { applyStyles } from "../DOMutils"
import { DropDownComponent } from "../dropDownComponent"
import { ToggleEvent } from "../dropDownComponentBase"
import { styleTransition } from "../safeStyle"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"

interface IFullscreenDropdownProps {
    toggleElement: HTMLElement
    enterNativeFn: () => void
    enterInteractiveFn: () => void
}

export class FullscreenDropdown extends DropDownComponent {
    public nativeOption: HTMLDivElement
    public interactiveOption: HTMLDivElement

    constructor(props: IFullscreenDropdownProps) {
        super(props.toggleElement, true, props)
    }

    protected initUI(props: IFullscreenDropdownProps): void {
        const menuStyles: CSSX.Properties  = {
            display: "block",
            visibility: "hidden",
            opacity: "0",
            inset: "",
            height: "max-content",
            width: "max-content",
            padding: "8px 0px",
            borderRadius: "8px",
            bottom: "100%",
            right: "10px",
            textShadow: "none",
            textAlign: "left",
            color: "#ffffff",
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            fontFamily: '"UbuntuRegular", sans-serif',
            fontSize: "13px",
            fontStyle: "normal",
            lineHeight: "16px",
        }

        const optionStyles = {
            padding: "8px 16px",
            cursor: "pointer",
        }

        const enterNativeFn = () => {
            this.hideElement()
            props.enterNativeFn()
        }

        const enterInteractiveFn = () => {
            this.hideElement()
            props.enterInteractiveFn()
        }

        applyStyles(this.element, menuStyles)
        this.element.classList.add("fullscreen-dropdown")
        this.nativeOption = <div style={optionStyles}
                             onClick={() => enterNativeFn()}
                             >{i18n.nativeFullscreenLabel}</div>
        this.interactiveOption = <div style={optionStyles}
                                  onClick={() => enterInteractiveFn()}
                                  >{i18n.interactiveFullscreenLabel}</div>
        this.element.appendChild(this.nativeOption)
        this.element.appendChild(this.interactiveOption)
    }

    public toggleOverlay(evt?: Event): boolean {
        if (this.toggleElement?.classList.contains("disabled") !== false) {
            return false
        }
        const targetIsContainedDropdown = evt !== undefined && evt.composedPath().some(target =>
            target instanceof Element ? this.element.contains(target): false,
        )
        if (targetIsContainedDropdown) {
            return this.isShown()
        }
        if (!this.isShown()) {
            return this.showElement(undefined, evt)
        } else {
            return !this.hideElement(evt)
        }
    }

    public isShown(): boolean {
        return this.element.style.visibility !== "hidden"
    }

    public showElement(defaultDisplay = "", evt?: Event): boolean {
        if (this.isShown()) {
            return false
        }
        styleTransition(this.element, "100ms")
        if (this.toggleElement !== undefined) {
            this.element.style.bottom = `${this.toggleElement.offsetHeight}px`
        }
        this.element.style.visibility = "visible"
        this.element.style.opacity = "1"
        this.toggleEvent.fire(new ToggleEvent(true, evt))
        // toggleEvent can cause UI changes which lead to the element being hidden again, return dynamic value
        return this.isShown()
    }

    public hideElement(evt?: Event): boolean {
        const targetIsContainedDropdown = evt !== undefined && evt.composedPath().some(target =>
            target instanceof Element ? this.element.contains(target): false,
        )
        if (targetIsContainedDropdown) {
            return false
        }
        this.element.style.opacity = "0"
        this.element.style.visibility = "hidden"
        this.toggleEvent.fire(new ToggleEvent(false, evt))
        // toggleEvent can cause UI changes which lead to the element being shown again, return dynamic value
        return !this.isShown()
    }
}
