export function usernameTitleCase(text) {
    const parts = text.toLowerCase().split("_");
    for (let i = 0; i < parts.length; i += 1) {
        parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
    }
    return parts.join("_");
}
export function titleCase(text) {
    const parts = text.toLowerCase().split(" ");
    for (let i = 0; i < parts.length; i += 1) {
        parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
    }
    return parts.join(" ");
}
export function toCamelCase(text) {
    const parts = text.toLowerCase().split("_");
    for (let i = 0; i < parts.length; i += 1) {
        parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
    }
    return parts.join("");
}
export function firstNotEmpty(a, b, c, d) {
    return d === undefined && c === undefined
        ? a === null || a === undefined || a === ""
            ? b === null || b === undefined
                ? ""
                : b
            : a
        : firstNotEmpty(firstNotEmpty(firstNotEmpty(a, b), firstNotEmpty(c, d)), "");
}
export function testIdCase(s) {
    return s
        .toLowerCase()
        .replaceAll(/[ /]/g, "-")
        .replaceAll(/[()/]/g, "")
        .replaceAll("--", "");
}
