import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { goToSettingsTab } from "../chatSettingsUtil"
import { Component } from "../defui/component"
import { addPageAction } from "../newrelic"
import { styleTransition, styleTransitionRemove } from "../safeStyle"
import { dom } from "../tsxrender/dom"
import { roomListRequest } from "../userActionEvents"
import { MenuComponentName } from "./roomMenuTab"
import { isKeyboardOpen } from "./scrollFix"
import { TabName } from "./tabList"
import { isVerticalScroll } from "./touchUtil"
import { userSwitchedTab } from "./userActionEvents"
import { getPortraitDimensions, getViewportWidth } from "./viewportDimension"
import type { TabList } from "./tabList"

interface IRoomTabsProps {
    tabList: TabList
}

export class RoomTabs extends Component<HTMLDivElement, IRoomTabsProps> {
    private tabList: TabList
    private isTouching: boolean
    private isSwipingTabs: boolean
    private isScrollingVertically: boolean
    private isPinching: boolean
    private prevTranslate: number
    private currTranslate: number
    private startX: number
    private startY: number
    private tabWidth: number
    private currIndex: number
    public static currentTab: TabName

    constructor(props: IRoomTabsProps) {
        super("div", props)
    }

    protected initData(props: IRoomTabsProps): void {
        this.tabList = props.tabList

        this.isTouching = false
        this.isSwipingTabs = false
        this.isScrollingVertically = false
        this.isPinching = false
        this.prevTranslate = 0
        this.currTranslate = 0
        this.startX = 0
        this.startY = 0
        this.tabWidth = getPortraitDimensions().width
        this.currIndex = 0
        RoomTabs.currentTab = TabName.Chat
    }

    protected initUI(): void {
        const containerStyle: CSSX.Properties = {
            width: "100%",
            height: "100%",
            backgroundColor: "#FFFFFF",
            display: "flex",
            position: "absolute",
        }

        this.element = <div style={containerStyle} />

        this.tabList.getList().forEach(tab => {this.addChild(tab)})
        this.initSwipeListeners()

        userSwitchedTab.listen(tabName => {
            this.setActiveTab(tabName)
        })

        roomListRequest.listen(() => {
            userSwitchedTab.fire(TabName.MoreRooms)
        })

        goToSettingsTab.listen(() => {
            userSwitchedTab.fire(TabName.RoomMenu)
            this.tabList.getRoomMenuTab().getRoomMenu().openMenuComponentByName(MenuComponentName.CHAT_SETTINGS)
        })
    }

    private initSwipeListeners(): void {
        this.tabList.getList().forEach((tab) => {
            addEventListenerPoly("touchstart", tab.element, (event: TouchEvent) => {this.handleTouchStart(event)})
            addEventListenerPoly("touchmove", tab.element, (event: TouchEvent) => {this.handleTouchMove(event)})
            addEventListenerPoly("touchend", tab.element, (event: TouchEvent) => {this.handleTouchEnd(event)})
        })
    }

    private handleTouchStart(event: TouchEvent): void {
        // don't register touches when user is touching from the edges
        // so it doesn't interact strangely when swiping back to last/next page
        const margin = 20
        const isStartingFromMargins = event.touches[0].pageX < margin
            || event.touches[0].pageX > this.tabWidth - margin
        this.isPinching = event.touches.length >= 2

        if (isStartingFromMargins || isKeyboardOpen() || this.isPinching) {
            return
        }

        styleTransition(this.element, "left 0.1s ease-out")

        this.isTouching = true
        this.startX = event.touches[0].pageX
        this.startY = event.touches[0].pageY
    }

    private handleTouchMove(event: TouchEvent): void {
        const swipeDistance = event.touches[0].pageX - this.startX
        const isLeftSwipe = swipeDistance < 0
        const isOnFirstTab = this.currIndex === 0
        const isOnLastTab = this.currIndex === this.tabList.length() - 1
        const isSwipeOutOfRange = (isOnFirstTab && !isLeftSwipe) || (isOnLastTab && isLeftSwipe)

        if (!this.isTouching || isSwipeOutOfRange || this.isPinching) {
            return
        }

        const currX = event.touches[0].pageX
        const currY = event.touches[0].pageY
        const isScrollingVertically = isVerticalScroll(this.startX, this.startY, currX, currY)

        if (isScrollingVertically && !this.isSwipingTabs) {
            this.isScrollingVertically = true
        }

        if (!this.isScrollingVertically) {
            event.preventDefault() // prevent scrolls from registering while swiping between tabs that have a scrollbar
            this.isSwipingTabs = true
            this.currTranslate = this.prevTranslate + swipeDistance
            this.element.style.left = `${this.currTranslate}px`
        }
    }

    private handleTouchEnd(event: TouchEvent): void {
        if (!this.isTouching) {
            return
        }

        window.setTimeout(() => {
            styleTransitionRemove(this.element)
        }, 100)

        this.isTouching = false
        this.isSwipingTabs = false
        this.isScrollingVertically = false
        this.isPinching = false
        const swipeDistance = this.currTranslate - this.prevTranslate // negative is left swipe
        const swipeThreshold = this.tabWidth / 4 // amount that tab should be visible before fully switching to that tab
        const leftSwipeThresholdReached = swipeDistance <= -swipeThreshold
        const rightSwipeThresholdReached = swipeDistance >= swipeThreshold
        const hasNextTab = this.currIndex + 1 < this.tabList.length()
        const hasPrevTab = this.currIndex > 0

        if (leftSwipeThresholdReached && hasNextTab) {
            const nextTabName = this.tabList.getTabByIndex(this.currIndex + 1)?.getTabName()
            userSwitchedTab.fire(nextTabName ?? RoomTabs.currentTab)
        } else if (rightSwipeThresholdReached && hasPrevTab) {
            const prevTabName = this.tabList.getTabByIndex(this.currIndex - 1)?.getTabName()
            userSwitchedTab.fire(prevTabName ?? RoomTabs.currentTab)
        } else {
            // translate back to originial tab if swipe distance wasn't big enough
            this.currTranslate -= swipeDistance
            this.element.style.left = `${this.currTranslate}px`
            this.prevTranslate = this.currTranslate
        }
    }

    private setActiveTab(tabName: TabName): void {
        const tabIndex = this.tabList.getIndexByTabName(tabName)

        if (tabIndex !== undefined) {
            RoomTabs.currentTab = tabName
            this.currIndex = tabIndex
            const pageActionName = this.tabList.getTabByIndex(tabIndex)?.getPageActionName()

            if (pageActionName !== undefined) {
                addPageAction(pageActionName)
            }
        }

        this.repositionTabs()
    }

    private repositionTabs(): void {
        this.tabWidth = getViewportWidth()
        this.currTranslate = this.currIndex * -this.tabWidth
        this.element.style.left = `${this.currTranslate}px`
        this.prevTranslate = this.currTranslate
    }

    protected repositionChildren(): void {
        this.repositionTabs()
    }
}
