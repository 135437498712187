import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { Component } from "../../../common/defui/component"
import { DivotPosition } from "../../../common/divot"
import { getFixedOffset, getTextWidth, hoverEvent } from "../../../common/DOMutils"
import { EventRouter, eventsPmSessionsCount, ListenerGroup } from "../../../common/events"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { addColorClass, removeColorClass } from "../../colorClasses"
import { RoomUsers, roomUsersUpdate, UserInRoomState } from "../../roomUsers"
import { buildTooltip } from "../../ui/tooltip"

type inRoomIndicatorProps = {
    username: string
    style: CSSX.Properties
}

// Note: the following lines are commented out because this feature is unused and they cause a lottt of "no listeners for event" debugs
// TODO remove InRoomIndicator and related realtime userlist code. After all this time doesn't seem like we'll get to use it https://multimediallc.leankit.com/card/30502080735675
// route document events through InRoomIndicator EventRouters in order to control the listenersWarningThreshold
// addEventListenerPoly("scroll", document, () => InRoomIndicator.documentScroll.fire(), true)
// addEventListenerPoly("click", document, () => InRoomIndicator.documentClick.fire(), true)

export class InRoomIndicator extends Component<HTMLSpanElement> {
    private userInRoomState: UserInRoomState
    private shownTooltip: HTMLDivElement | undefined
    private inRoomTooltip: HTMLDivElement
    private notInPrivateTooltip: HTMLDivElement
    private notInRoomTooltip: HTMLDivElement
    private listeners = new ListenerGroup()
    static documentClick = new EventRouter<void>("InRoomIndicator.documentClick", {
        listenersWarningThreshold: () => 50 + 3 * eventsPmSessionsCount, // 2 per room pm session in room, 1 per room pm session in sitewide dropdown, and buffer for non room pms in the dropdown
    })
    static documentScroll = new EventRouter<void>("InRoomIndicator.documentScroll", {
        listenersWarningThreshold: () => 50 + 3 * eventsPmSessionsCount, // 2 per room pm session in room, 1 per room pm session in sitewide dropdown, and buffer for non room pms in the dropdown
    })

    constructor(props: inRoomIndicatorProps) {
        super("span", props)

        const style: CSSX.Properties = {
            display: "inline-block",
            width: "16px",
            height: "16px",
            marginLeft: "4px",
            ...props.style,
        }
        this.element = <span colorClass="InRoomIndicator" style={style}/>

        this.createTooltips()
        this.setupTooltipListeners()

        roomUsersUpdate.listen(() => {
            this.userInRoomState = RoomUsers.getInstance().userInRoomState(props.username)
            this.updateUI()
        }).addTo(this.listeners)

        this.userInRoomState = RoomUsers.getInstance().userInRoomState(props.username)
        this.updateUI()
    }

    public dispose(): void {
        this.listeners.removeAll()
    }

    private setupTooltipListeners(): void {
        InRoomIndicator.documentClick.listen(this.hideTooltip).addTo(this.listeners)
        InRoomIndicator.documentScroll.listen(this.hideTooltip).addTo(this.listeners)
        hoverEvent(this.element).listen(isHover => {
            if (isHover) {
                this.showTooltip()
            } else {
                this.hideTooltip()
            }
        })
        addEventListenerPoly("touchend", this.element, evt => {
            // Manually handle touchend instead of letting touch interfaces interpret taps as hover. Some still count it
            // as a click and can propagate that to the containing element
            if (this.shownTooltip === undefined) {
                this.showTooltip()
            } else {
                this.hideTooltip()
            }
            evt.preventDefault()
        })
    }

    private createTooltips(): void {
        const baseTooltipProps = {
            hasHTML: false,
            divotPosition: DivotPosition.Bottom,
            divotLeftOrTop: "5px",
        }

        this.inRoomTooltip = buildTooltip({
            content: i18n.userInRoom,
            ...baseTooltipProps,
        })
        this.notInPrivateTooltip = buildTooltip({
            content: i18n.userNotInPrivate,
            ...baseTooltipProps,
        })
        this.notInRoomTooltip = buildTooltip({
            content: i18n.userNotInRoom,
            ...baseTooltipProps,
        })

        const tooltips = [this.inRoomTooltip, this.notInPrivateTooltip, this.notInRoomTooltip]
        for (const tooltip of tooltips) {
            tooltip.style.position = "fixed"
            tooltip.style.display = ""
        }
    }

    private showTooltip(): void {
        this.hideTooltip()

        let tooltip: HTMLDivElement
        switch (this.userInRoomState) {
            case UserInRoomState.InRoom:
                tooltip = this.inRoomTooltip
                break
            case UserInRoomState.InRoomNotInPrivate:
                tooltip = this.notInPrivateTooltip
                break
            case UserInRoomState.NotInRoom:
                tooltip = this.notInRoomTooltip
                break
        }

        document.body.appendChild(tooltip)
        const boundingRect = this.element.getBoundingClientRect()
        const fixedOffset = getFixedOffset()
        const left = -fixedOffset.left + boundingRect.left - 7
        const top = -fixedOffset.top + boundingRect.top - tooltip.offsetHeight - 6

        tooltip.style.top = `${top}px`
        tooltip.style.left = `${left}px`
        // Add 1 to width to account for fractional pixels getting rounded down (browser dependant)
        tooltip.style.width = `${getTextWidth(tooltip.textContent ?? "", tooltip) + 1}px`
        this.shownTooltip = tooltip
    }

    private hideTooltip = (): void => {
        this.shownTooltip?.parentElement?.removeChild(this.shownTooltip)
        this.shownTooltip = undefined
    }

    private updateUI(): void {
        removeColorClass(this.element, "inRoom")
        removeColorClass(this.element, "notInPrivate")
        removeColorClass(this.element, "notInRoom")
        switch (this.userInRoomState) {
            case UserInRoomState.InRoom:
                addColorClass(this.element, "inRoom")
                break
            case UserInRoomState.InRoomNotInPrivate:
                addColorClass(this.element, "notInPrivate")
                break
            case UserInRoomState.NotInRoom:
                addColorClass(this.element, "notInRoom")
                break
        }
    }

    public render(): HTMLSpanElement {
        return this.element
    }
}
