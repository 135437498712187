// we're going to call console in here, so we need to disable this linter
/* eslint-disable @multimediallc/use-custom-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Wrappers for console functions. `log` is not included by design. In most cases,
 * you either want `debug` or `error`
 */
const SUPPRESS_LOGS = false;
let PRODUCTION;
try {
    // Consumers of this package are responsible for making sure `process.env.NODE_ENV` is defined.
    // In the case of CB, webpack will replace it in code at compile time with the appropriate value.
    PRODUCTION = process.env.NODE_ENV === "production";
}
catch (e) {
    console.error("@multimediallc/logging failed to initialize, probably due to undefined process.env.NODE_ENV.", e);
    PRODUCTION = true;
}
const newrelic = window["newrelic"];
// info messages are printed to end users
export function info(x, extra) {
    if (SUPPRESS_LOGS) {
        return;
    }
    if (window.console !== undefined) {
        if (console.info === undefined && !PRODUCTION) {
            console.log(`INFO `, x, objOrEmptyString(extra));
        }
        else {
            console.info(x, objOrEmptyString(extra));
        }
    }
}
// warn is for things that are not feature breaking, but should probably be noted
export function warn(x, extra, subSystemType = "") {
    if (SUPPRESS_LOGS) {
        return;
    }
    if (window.console !== undefined) {
        if (console.warn === undefined && !PRODUCTION) {
            console.log(subSystemType.concat(`WARN `), x, objOrEmptyString(extra));
        }
        else {
            console.warn(subSystemType.concat(`WARN `), x, objOrEmptyString(extra));
        }
        consoleTrace();
    }
    let message;
    try {
        message = typeof x === "object" ? JSON.stringify(x) : String(x);
    }
    catch (e) {
        message = `${x}`;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    reportNewRelicError(subSystemType.concat(`WARN`), new Error(message), extra);
}
// error should be used when there is an error that we can recover from
export function error(x, extra, subSystemType = "") {
    if (SUPPRESS_LOGS) {
        return;
    }
    if (window.console !== undefined) {
        if (console.error === undefined && !PRODUCTION) {
            console.log(subSystemType.concat(`ERROR `), x, objOrEmptyString(extra));
        }
        else {
            console.error(subSystemType.concat(`ERROR `), x, objOrEmptyString(extra));
        }
        consoleTrace();
    }
    let message;
    try {
        message = typeof x === "object" ? JSON.stringify(x) : String(x);
    }
    catch (e) {
        message = `${x}`;
    }
    reportNewRelicError(subSystemType.concat(`ERROR`), new Error(message), 
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    extra);
}
// debug should be used for all messages that shouldn't be seen by end users
export function debug(x, extra) {
    if (SUPPRESS_LOGS) {
        return;
    }
    if (PRODUCTION) {
        return;
    }
    if (window.console !== undefined) {
        if (console.debug === undefined && !PRODUCTION) {
            console.log(`DEBUG `, x, objOrEmptyString(extra));
        }
        else {
            console.debug(x, objOrEmptyString(extra));
        }
    }
}
function consoleTrace() {
    if (SUPPRESS_LOGS || PRODUCTION) {
        return;
    }
    if (window.console === undefined) {
        return;
    }
    if (console.trace === undefined) {
        return;
    }
    console.trace();
}
function reportNewRelicError(level, errorObject, attributes = {}) {
    try {
        if (newrelic === undefined) {
            return;
        }
        const namespacedAttributes = {};
        namespacedAttributes["attributes.level"] = level;
        if (typeof attributes === "object") {
            for (const key in attributes) {
                if (key === "xhrErrorAttributes") {
                    const xhrAttributes = attributes[key];
                    namespacedAttributes["attributes.xhrError"] = true;
                    namespacedAttributes["attributes.xhrState"] =
                        xhrAttributes.readyState;
                    namespacedAttributes["attributes.xhrStatus"] =
                        xhrAttributes.status;
                    namespacedAttributes["attributes.xhrReason"] =
                        xhrAttributes.reason;
                    continue;
                }
                namespacedAttributes[`attributes.${key}`] = attributes[key];
            }
        }
        else {
            namespacedAttributes["attributes.extra"] = attributes;
        }
        newrelic.noticeError(errorObject, namespacedAttributes);
    }
    catch (e) {
        console.error(`New Relic Error in reportError: ${e}`);
    }
}
function objOrEmptyString(extra) {
    if (extra === undefined) {
        return "";
    }
    return extra;
}
