import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { preventMultiClicks } from "../../../common/eventsUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { colorClass } from "../../colorClasses"
import { ReactWrapper } from "../ReactWrapper"
import { RoomCardSubject } from "./roomCardSubject"
import type { IRoomInfo } from "./IRoomInfo"


interface IRoomCardDetails {
    roomInfo: IRoomInfo
}

export class RoomCardDetails extends HTMLComponent<HTMLDivElement, IRoomCardDetails> {
    protected createElement(props: IRoomCardDetails): HTMLDivElement {
        const numViewers = props.roomInfo.viewers
        const subInfoViewers = numViewers > -1 ? i18n.numViewerOrViewers(numViewers) : ""
        const commaInSubInfo = subInfoViewers !== "" && props.roomInfo.timeOnline !== ""
        return <div className="details">
            <div className="cardTitle title">
                {preventMultiClicks(<a href={`/${props.roomInfo.room}/`} data-room={props.roomInfo.room} data-room-nav="true" data-testid="room-card-username">
                    {props.roomInfo.room}
                </a>)}
                <div className="age_gender_container">
                    <span className="age" data-testid="room-card-age">{props.roomInfo.displayAge}</span>
                    <span className={`${colorClass.camAltTextColor} ${RoomCardDetails.getGenderClassName(props.roomInfo.gender)}`}
                          title={i18n.genderSymbolToIconTitle(props.roomInfo.gender)}
                          data-testid="room-card-gender">
                    </span>
                    {props.roomInfo.countryCode && (
                        <span className="thumbnail_flag" data-testid="room-card-thumbnail-flag">
                            <ReactWrapper
                                component="FlagIcon"
                                componentProps={{
                                    countryCode: props.roomInfo.countryCode,
                                    countryName: props.roomInfo.countryName,
                                    "data-testid": "room-card-country",
                                }}
                            />
                        </span>
                    )}
                </div>
            </div>
            <RoomCardSubject subject={props.roomInfo.subject}/>
            <ul className={`sub-info ${colorClass.camAltTextColor}`}>
                {props.roomInfo.location !== undefined ?
                    <li className="location" style={RoomCardDetails.subInfoItemStyle} data-testid="room-card-location">{props.roomInfo.location}</li>
                    : ""}
                <li className={`cams ${commaInSubInfo ? "" : "no-viewers"}`} style={RoomCardDetails.subInfoItemStyle}>
                    <span className={`time${props.roomInfo.viewers === -1 ? " time-since" : ""}`}>{props.roomInfo.timeOnline}</span><span className="comma">{commaInSubInfo ? ", " : ""}</span>
                    <span className="viewers">{subInfoViewers}</span>
                </li>
            </ul>
        </div>
    }

    private static getGenderClassName(gender: string): string {
        if (gender.includes("t")) {
            return "genders"
        } else {
            return `gender${gender}`
        }
    }

    static subInfoItemStyle: CSSX.Properties = {
        whiteSpace: "nowrap",
    }
}
