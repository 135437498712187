import { Component } from "../../../common/defui/component"
import { DivotPosition } from "../../../common/divot"
import { applyStyles } from "../../../common/DOMutils"
import { buildTooltip } from "../../ui/tooltip"

type ToolTipProps = {
    message: string
    toolTipRight?: string
    toolTipBottom?: string
    divotPosition?: DivotPosition
    caretRight?: string
    caretBottom?: string
}

export class ToolTip extends Component<HTMLDivElement, ToolTipProps> {

    constructor(props: ToolTipProps) {
        super("div", props)
    }

    protected initUI(props: ToolTipProps): void {
        // by default tooltip position is above target element
        const divotPosition = props.divotPosition !== undefined ? props.divotPosition : DivotPosition.Bottom
        this.element = buildTooltip({
            content: props.message,
            hasHTML: false,
            width: "auto",
            divotPosition: divotPosition,
            divotLeftOrTop: "auto",
        })

        applyStyles(this.element, {
            fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
            lineHeight: "27px",
            bottom: props.toolTipBottom === undefined ? "40px" : props.toolTipBottom,
            right: props.toolTipRight === undefined ? "0px" : props.toolTipRight,
            padding: "0px 8px",
            whiteSpace: "nowrap",
        })

        const divot = this.element.querySelector<HTMLElement>(".divot")

        if (divot !== null) {
            applyStyles(divot, {
                right: props.caretRight === undefined ? "21px" : props.caretRight,
                bottom: props.caretBottom === undefined ? "auto": props.caretBottom,
            })
        }
    }

    public changeMessage(newText: string): void {
        if (this.element.firstChild) {
            this.element.firstChild.textContent = newText
        }
    }

    public show(): void {
        this.element.style.display = "block"
    }

    public hide(): void {
        this.element.style.display = "none"
    }

    public render(): HTMLDivElement {
        return this.element
    }
}
