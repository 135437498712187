import { parseFromUser, parseMediaList, parseNewsUpdateItem, parseUserInfo } from "../../../common/chatconnection/messageParsers"
import { EventRouter } from "../../../common/events"
import { smcAPIDataAsUser } from "../../components/showMyCam/smcUtil"
import { Topic } from "./base"
import type { ITopicMessage } from "./base"
import type {
    IBroadcasterPrivateStatus,
    IChatMediaOpened,
    IChatMediaRemoved,
    IFollowerTopic,
    IIgnoreTopic,
    INewsSeenUpdate,
    IOneClickPush,
    IPmReadTopic,
    IPushBroadcastWarning,
    IPushNewsUpdateItem,
    IPushPrivateMessage,
    IPushPurchase,
    IPushTipAlert,
    IPushTokenBalanceUpdateNotification,
    IPushUserAlert,
    IPushUserColors,
    IUpdateOfflineTipItem,
    IUserInfo,
    IUserUpdate,
    PrivateRequestStatus,
    UserUpdateType,
} from "../../../common/messageInterfaces"
import type { ArgJSONMap } from "@multimediallc/web-utils"

type User = {
    "user_uid": string,
}

// represents a user-id resolved topic, ie push:user:token_balance:<user_uid>
export abstract class UserTopic<T extends ITopicMessage> extends Topic<T> {
    protected userUid: string
    public constructor(userUid: string) {
        // Props are sent through the network, and must be kept in string notation
        super({
            "user_uid": userUid,
        })
        this.uidCheck(this.userUid)
    }

    public getKey(): string {
        return `${this.getId()}:${this.userUid}`
    }

    protected initData(props: User): void {
        this.userUid = props["user_uid"]
    }
}

export abstract class BaseNewsSeenTopic extends UserTopic<INewsSeenUpdate> {
    public parseData(data: ArgJSONMap): INewsSeenUpdate {
        return {
            ...super.parseData(data),
            timestamp: data.getNumber("read_ts"),
        }
    }
}

export class UserNewsSeenTopic extends BaseNewsSeenTopic {
    public getId(): string {
        return "UserNewsSeenTopic"
    }
}

export class UserMessageTopic extends UserTopic<IPushPrivateMessage> {
    public getId(): string {
        return "UserMessageTopic"
    }

    public parseData(data: ArgJSONMap): IPushPrivateMessage {
        return {
            ...super.parseData(data),
            room: data.getString("room"),
            createdAt: new Date(Math.floor(data.getNumber("ts") * 1000)),
            fromUser: parseFromUser(data.getParsedSubMap("from_user")),
            message: data.getString("message"),
            textColor: data.getStringOrUndefined("font_color", false),
            messageID: data.getString("id", false),
            otherUsername: data.getString("other_user"),
            font: data.getString("font_family"),
            backgroundColor: data.getStringOrUndefined("background"),
            mediaList: parseMediaList(data.getObjectOrUndefined("media")),
            isPrivate: true,
        }
    }
}

export class UserUpdateTopic extends UserTopic<IUserUpdate> {
    public getId(): string {
        return "UserUpdateTopic"
    }

    public parseData(data: ArgJSONMap): IUserUpdate {
        return {
            ...super.parseData(data),
            target: data.getString("target") as UserUpdateType,
        }
    }
}

export class UserColorUpdateTopic extends UserTopic<IPushUserColors> {
    public getId(): string {
        return "UserColorUpdateTopic"
    }

    public parseData(data: ArgJSONMap): IPushUserColors {
        return {
            ...super.parseData(data),
            hasTokens: data.getBoolean("has_tokens"),
            tippedRecently: data.getBoolean("tipped_recently"),
            tippedAlotRecently: data.getBoolean("tipped_alot_recently"),
            tippedTonsRecently: data.getBoolean("tipped_tons_recently"),
        }
    }
}

export class UserTokenUpdateTopic extends UserTopic<IPushTokenBalanceUpdateNotification> {
    public getId(): string {
        return "UserTokenUpdateTopic"
    }

    public parseData(data: ArgJSONMap): IPushTokenBalanceUpdateNotification {
        return {
            ...super.parseData(data),
            tokens: data.getNumber("tokens"),
        }
    }
}

export class UserTipAlertTopic extends UserTopic<IPushTipAlert> {
    public getId(): string {
        return "UserTipAlertTopic"
    }

    public parseData(data: ArgJSONMap): IPushTipAlert {
        return {
            ...super.parseData(data),
            fromUser: parseFromUser(data),
            message: data.getString("message"),
            amount: data.getNumber("amount"),
            isAnonymousTip: data.getBoolean("is_anonymous_tip"),
            toUsername: data.getString("to_username"),
            ts: Math.floor(data.getNumber("ts") * 1000),
            roomType: data.getString("room_type"),
        }
    }
}


export class UserOneClickTopic extends UserTopic<IOneClickPush> {
    public getId(): string {
        return "UserOneClickTopic"
    }

    public parseData(data: ArgJSONMap): IOneClickPush {
        return {
            ...super.parseData(data),
            is_one_click_eligible: data.getBoolean("is_one_click_eligible"),
        }
    }
}


export class OfflineTipNotificationTopic extends UserTopic<IPushNewsUpdateItem> {
    public getId(): string {
        return "OfflineTipNotificationTopic"
    }

    public parseData(data: ArgJSONMap): IPushNewsUpdateItem {
        return {
            ...super.parseData(data),
            ...parseNewsUpdateItem(data),
        }
    }
}

/** Topic used to update an existing unseen offline tip from a user
 * in the case of multiple offline tips. */
export class UpdateOfflineTipNotificationTopic extends UserTopic<IUpdateOfflineTipItem> {
    public getId(): string {
        return "UpdateOfflineTipNotificationTopic"
    }

    public parseData(data: ArgJSONMap): IUpdateOfflineTipItem {
        return {
            ...super.parseData(data),
            ...parseNewsUpdateItem(data),
            fromUsername: data.getString("from_username"),
        }
    }
}

export class RoomBroadcasterPrivateStatusTopic extends UserTopic<IBroadcasterPrivateStatus> {
    public getId(): string {
        return "RoomBroadcasterPrivateStatusTopic"
    }

    public parseData(data: ArgJSONMap): IBroadcasterPrivateStatus {
        return {
            ...super.parseData(data),
            status: data.getString("status") as PrivateRequestStatus,
            privateShowId: data.getStringWithNumbers("show_id"),
            requester: data.getString("requester", false),
            earlyCancelTokens: data.getNumber("early_cancel_tokens"),
            reason: data.getString("reason", false),
            isPremium: data.getBooleanOrUndefined("premium") ?? false,
            delayFiringEvent: data.getBooleanOrUndefined("delay_firing_event") ?? false,
        }
    }

    public getAuthData(): object {
        return {
            "broadcaster_uid": this.userUid,
        }
    }
}

export const showMyCamStarted = new EventRouter<IUserInfo>("showMyCamStarted")
export const showMyCamStopped = new EventRouter<IUserInfo>("showMyCamStopped")
export const showMyCamViewStarted = new EventRouter<IUserInfo>("showMyCamViewStarted")
export const showMyCamViewStopped = new EventRouter<IUserInfo>("showMyCamViewStopped")

interface ISMCTopicData extends IUserInfo, ITopicMessage {
    started: boolean
}

export class UserSMCBroadcastNotifyTopic extends UserTopic<ISMCTopicData> {
    public getId(): string {
        return "UserSMCBroadcastNotifyTopic"
    }

    public parseData(data: ArgJSONMap): ISMCTopicData {
        return {
            ...super.parseData(data),
            ...smcAPIDataAsUser(data),
            started: data.getBoolean("started"),
        }
    }
}

export class UserSMCWatchingTopic extends UserTopic<ISMCTopicData> {
    public getId(): string {
        return "UserSMCWatchingTopic"
    }

    public parseData(data: ArgJSONMap): ISMCTopicData {
        return {
            ...super.parseData(data),
            ...smcAPIDataAsUser(data),
            started: data.getBoolean("started"),
        }
    }
}

export class SMCPresenceTopic extends UserTopic<ITopicMessage> {
    public getId(): string {
        return "SMCPresenceTopic"
    }

    public parseData(data: ArgJSONMap): ITopicMessage {
        return super.parseData(data)
    }

    public getUserUid(): string {
        return this.userUid
    }
}

export class UserAlertTopic extends UserTopic<IPushUserAlert> {
    public getId(): string {
        return "UserAlertTopic"
    }

    public parseData(data: ArgJSONMap): IPushUserAlert {
        return {
            ...super.parseData(data),
            message: data.getString("message"),
        }
    }
}

export class BroadcasterWarningTopic extends UserTopic<IPushBroadcastWarning> {
    public getId(): string {
        return "BroadcasterWarningTopic"
    }

    public parseData(data: ArgJSONMap): IPushBroadcastWarning {
        return {
            ...super.parseData(data),
            text: data.getString("text"),
            warningId: data.getNumber("warning_id"),
            response: data.getBoolean("response"),
        }
    }
}

export class UserChatMediaOpenedTopic extends UserTopic<IChatMediaOpened> {
    public getId(): string {
        return "UserChatMediaOpenedTopic"
    }

    public parseData(data: ArgJSONMap): IChatMediaOpened {
        return {
            ...super.parseData(data),
            mediaId: data.getNumber("media_id"),
            messageId: data.getString("message_id"),
        }
    }
}

export class UserChatMediaRemovedTopic extends UserTopic<IChatMediaRemoved> {
    public getId(): string {
        return "UserChatMediaRemovedTopic"
    }

    public parseData(data: ArgJSONMap): IChatMediaRemoved {
        return {
            ...super.parseData(data),
            mediaId: data.getNumber("media_id"),
            isCompliance: data.getBoolean("is_compliance"),
        }
    }
}

export class UserFollowerTopic extends UserTopic<IFollowerTopic> {
    public getId(): string {
        return "UserFollowerTopic"
    }

    public parseData(data: ArgJSONMap): IFollowerTopic {
        return {
            ...super.parseData(data),
            followerUsername: data.getString("follower_username"),
            followedUsername: data.getString("followed_username"),
            isFollowing: data.getBoolean("is_following"),
        }
    }
}

export class UserIgnoreTopic extends UserTopic<IIgnoreTopic> {
    public getId(): string {
        return "UserIgnoreTopic"
    }

    public parseData(data: ArgJSONMap): IIgnoreTopic {
        return {
            ...super.parseData(data),
            username: data.getString("username"),
            isIgnored: data.getBoolean("is_ignored"),
        }
    }
}

export class UserPmReadTopic extends UserTopic<IPmReadTopic> {
    public getId(): string {
        return "UserPmReadTopic"
    }

    public parseData(data: ArgJSONMap): IPmReadTopic {
        return {
            ...super.parseData(data),
            otherUsername: data.getString("other_username"),
            room: data.getStringOrUndefined("room"),
        }
    }
}

export class UserJoinedFanClubTopic extends UserTopic<IPushPurchase> {
    public getId(): string {
        return "UserJoinedFanClubTopic"
    }

    public parseData(data: ArgJSONMap): IPushPurchase {
        return {
            ...super.parseData(data),
            fromUser: parseUserInfo(data.getParsedSubMap("user")),
            message: data.getString("message"),
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }
}
