/* eslint-disable @typescript-eslint/no-explicit-any */

type apiFunc = (...args: any) => Promise<any>

declare global {
    interface Window {
        ReactAPIRegistry: Record<string, apiFunc>
    }
}

export class ReactAPIRegistry {
    // Returns api function with given name.  Will return rejected promise if API function is not added to registry in React app for given page.
    public static get(name: string): apiFunc {
        if (window["ReactAPIRegistry"] === undefined) {
            error("ReactAPIRegistry is undefined - React code is likely not loaded", {
                "argument": name,
            })
            // Return dummy API func so TS can catch error.
            return (...args: any) => Promise.reject()
        }
        if (window["ReactAPIRegistry"][name] === undefined) {
            error(`ReactAPIRegistry does not contain API function ${name} - React code may not be up to date`, {
                "argument": name,
            })
            // Return dummy API func so TS can catch error.
            return (...args: any) => Promise.reject()
        }
        return window["ReactAPIRegistry"][name]
    }
}
