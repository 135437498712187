import { isHlsSupported, isiOS } from "@multimediallc/web-utils/modernizr"
import { pageContext, roomDossierContext } from "../../cb/interfaces/context"
import { currentSiteSettings } from "../../cb/siteSettings"
import { chaturbateLogo } from "../../cb/ui/logo"
import { addEventListenerPoly, removeEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert } from "../alerts"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { EventRouter } from "../events"
import { setCurrentPlayer } from "../newrelic"
import { parseQueryString } from "../urlUtil"
import { videoModeHandler } from "../videoModeHandler"
import { HlsNativePlayer } from "./hlsNativePlayer"
import { edgeRefreshType } from "./hlsPlayer"
import { JpegPushPlayer } from "./jpegPlayer"
import { sdRatio, wsRatio } from "./playerSettings"
import { VideoJsPlayer } from "./videoJsPlayer"
import { VideoMetrics } from "./videoMetrics"
import type { IPlayer } from "./interfaces"
import type { IRoomContext } from "../context"
import type { RoomStatusNotifier } from "../roomStatusNotifier"
import type { IVideoModeChangeNotification } from "../videoModeHandler";


export class BasePlayer extends Component<HTMLDivElement> {
    playerComponent: IPlayer
    qualityMessage: HTMLDivElement
    videoOfflineChange = new EventRouter<boolean>("videoOfflineChange") // events bubble up from playerComponent
    protected watermark: HTMLImageElement

    constructor(inlineAutoplaySupported: boolean, public roomStatusNotifier: RoomStatusNotifier, options?: { noHook?: boolean }) {
        super()

        const player = this.choosePlayer(inlineAutoplaySupported)
        this.createPlayer(player, options)

        this.watermark = document.createElement("img")
        this.watermark.src = chaturbateLogo()
        this.watermark.className = "cbLogo"
        this.adjustWatermarkStyle({
            position: "absolute",
            width: "90px",
            height: "27px",
            top: "8px",
            right: "8px",
            userSelect: "none",
            msUserSelect: "none",
            pointerEvents: "none",
            visibility: currentSiteSettings.shouldHideWaterMark ? "hidden" : "initial",
        })
        this.watermark.setAttribute("draggable", "false") // eslint-disable-line @multimediallc/no-set-attribute
        this.element.appendChild(this.watermark)

        videoModeHandler.changeVideoMode.listen((event: IVideoModeChangeNotification) => {
            this.playerComponent.showControls()
        })
        this.qualityMessage = document.createElement("div")
        this.qualityMessage.innerText = "Broadcaster's stream is experiencing network issues"
        applyStyles(this.qualityMessage, {
            display: "none",
            color: "#0000ff",
            position: "absolute",
            fontSize: "1.0em",
            fontWeight: "bold",
            top: "8px",
            left: "8px",
            userSelect: "none",
            pointerEvents: "none",
            msUserSelect: "none",
        })
        this.qualityMessage.setAttribute("draggable", "false") // eslint-disable-line @multimediallc/no-set-attribute
        this.element.appendChild(this.qualityMessage)
    }

    protected bindVideoEvents(): void {
        this.playerComponent.requestJPEG?.listen((context) => { this.forceJPEGPushPlayer(context) })
        this.playerComponent.videoOfflineChange.listen((offline) => {
            this.videoOfflineChange.fire(offline)
            this.roomStatusNotifier.setVideoOffline(offline)
        })

        if (pageContext.current.loggedInUser?.isStaff === true && pageContext.current.qualityMessage === true) {
            roomDossierContext.onUpdate.listen(() => {
                const { quality } = roomDossierContext.getState()
                this.qualityMessage.style.display = ((quality?.quality === "okay" || quality?.quality === "bad" || quality?.quality === "stop") ? "" : "none")
                this.qualityMessage.style.color = ((quality?.quality === "bad") ? "#ff0000" : "0000ff")
                this.qualityMessage.innerText = ((quality?.quality === "stop")? "Broadcaster's stream has stopped." : "Broadcaster's stream is experiencing network issues")
            })
        }
    }

    public adjustWatermarkStyle(css: CSSX.Properties): void {
        applyStyles(this.watermark, css)
    }

    protected stylePlayerComponent(): void {
    }

    private chooseHLSPlayer(): string {
        if (isiOS()) {
            return "hlsnative"
        }
        return "videojs"
    }

    protected chooseNoAutoplayHlsSubstitute(): string {
        return "jpegToHls"
    }

    private choosePlayer(inlineAutoplaySupported: boolean): string {
        let playerOverride = parseQueryString(window.location.search)["player"]
        if (playerOverride !== undefined) {
            if (playerOverride === "hls") {
                playerOverride = this.chooseHLSPlayer()
            }
            return playerOverride
        }

        // JpegPushPlayer is the fallback if HLS is not supported
        if (!isHlsSupported()) {
            VideoMetrics.jpegFallback()
            return "jpeg"
        }

        // HLS is supported but video autoplay is blocked
        if (!inlineAutoplaySupported) {
            return this.chooseNoAutoplayHlsSubstitute()
        }

        return this.chooseHLSPlayer()
    }

    protected createVideoJsPlayer(): VideoJsPlayer {
        return new VideoJsPlayer(this.roomStatusNotifier)
    }

    private createPlayer(name: string, options?: { noHook?: boolean }): void {
        let player: IPlayer
        switch (name) {
            case "videojs":
                player = this.createVideoJsPlayer()
                break
            case "hlsnative":
                player = new HlsNativePlayer(this.roomStatusNotifier)
                break
            case "jpeg":
                player = new JpegPushPlayer(this.roomStatusNotifier)
                break
            case "jpegToHls":
                player = new JpegPushPlayer(this.roomStatusNotifier, true)
                break
            default:
                error(`received unexpected player name: ${name}`)
                modalAlert(`unknown player: ${name}`)
                return
        }
        const useHook = !(options?.noHook ?? false)
        this.playerComponent = this.prependChild(player)
        player.setContainerElement(this.element)
        if (useHook) {
            this.afterPlayerCreated()
        }
    }

    protected afterPlayerCreated(): void {
        let playerType: string
        if (this.playerComponent instanceof VideoJsPlayer) {
            playerType = "videojs"
        } else if (this.playerComponent instanceof HlsNativePlayer) {
            playerType = "hlsnative"
        } else if (this.playerComponent instanceof JpegPushPlayer) {
            if (this.playerComponent.isHlsPlaceholder) {
                playerType = "jpegToHls"
            } else {
                playerType = "jpeg"
            }
        } else {
            playerType = "unknown player type"
        }
        setCurrentPlayer(playerType)
        this.bindVideoEvents()
        this.stylePlayerComponent()
    }

    private removePlayer(): void {
        this.playerComponent.stop()
        this.playerComponent.onForceRemoved()
        this.removeChild(this.playerComponent)
    }

    protected forceHlsPlayer(context: IRoomContext, unmute = true, handleEdge = edgeRefreshType.RandomEdge): void {
        const currentSource = this.playerComponent.getSource()
        // WARNING if this method is extended to anything else but JPEG -> HLS
        //      don't forget to add listener groups in each player
        //      players need to remove their listeners (and especially for roomLoaded)
        //      they can be removed in afterRemovedFromParent() using ListenerGroup
        this.removePlayer()

        let hlsPlayer: VideoJsPlayer | HlsNativePlayer
        if (this.chooseHLSPlayer() === "hlsnative") {
            hlsPlayer = new HlsNativePlayer(this.roomStatusNotifier)
        } else {
            hlsPlayer = this.createVideoJsPlayer()
        }

        const setMuted = (event: Event) => {
            hlsPlayer.setMuted(!unmute)
            removeEventListenerPoly("loadeddata", hlsPlayer.videoElement, setMuted)
        }

        addEventListenerPoly("loadeddata", hlsPlayer.videoElement, setMuted)

        hlsPlayer.handleRoomLoaded(context)
        switch (handleEdge) {
            case edgeRefreshType.NewEdge:
                hlsPlayer.setSource(currentSource)
                // Falling back from LLHLS to HLS, get new edge as current should be LLHLS specfic
                hlsPlayer.refreshStreamOnNewEdge(context)
                break
            case edgeRefreshType.SameEdge:
                hlsPlayer.setSource(currentSource)
                // Requesting new stream type, maintain edge
                hlsPlayer.refreshStreamOnSameEdge(context)
                break
            case edgeRefreshType.RandomEdge:
            default:
                // Normal refresh
                hlsPlayer.refreshStream(context)
                break
        }
        if (unmute) {
            hlsPlayer.setVolume(100)
            hlsPlayer.setMuted(false)
        } else {
            hlsPlayer.setMuted(true)
        }

        this.playerComponent = this.prependChild(hlsPlayer)
        this.afterPlayerCreated()

        this.repositionChildren()
    }

    protected forceJPEGPushPlayer(context: IRoomContext): void {
        this.removePlayer()
        this.createPlayer("jpeg")
        this.playerComponent.handleRoomLoaded(context)
    }
}

// getDimensionsWithinBounds returns a width and height that will be either full boundsWidth or full boundsHeight,
// whichever fits without cropping.
export function getDimensionsWithinBounds(boundsWidth: number, boundsHeight: number, isWidescreen: boolean): number[] {
    let width = boundsHeight / (isWidescreen ? wsRatio : sdRatio)
    let height = boundsHeight

    if (width > boundsWidth) {
        width = boundsWidth
        height = width * (isWidescreen ? wsRatio : sdRatio)
    }

    return [width, height]
}
