import { ChatSettingsStyles } from "../chatSettingsStyles"
import { applyStyles } from "../DOMutils"

const fontSize = 16
export const rowPaddingTop = 12
export const rowPaddingSide = 18
const borderColor = "rgb(239, 239, 239)"
const headerBackgroundColor = "rgb(250, 250, 250)"
const rowBackgroundColor = "rgb(255, 255, 255)"
export const selectArrowSize = 18
const borderWidth = 1
export const iconSize = 18
export const maxSettingsWidth = 400

export abstract class MobileChatSettingsStyles extends ChatSettingsStyles {
    public static styleContainer(container: HTMLDivElement): void {
        applyStyles(container, {
            fontSize:`${fontSize}px`,
        })
    }

    public static styleHeader(header: HTMLDivElement, title: string): void {
        super.styleHeader(header, title)
        applyStyles(header, {
            fontSize: `${fontSize}px`,
            padding: `${rowPaddingTop}px`,
            margin: "0",
            borderBottom: `${borderWidth}px solid ${borderColor}`,
            backgroundColor: `${headerBackgroundColor}`,
            boxSizing: "border-box",
        })
    }

    public static styleRowWrapper(element: HTMLElement): void {
        super.styleRowWrapper(element)
        applyStyles(element, {
            fontSize: `${fontSize}px`,
            backgroundColor: `${rowBackgroundColor}`,
            padding: `${rowPaddingTop}px ${rowPaddingSide}px`,
            margin: "0",
            borderBottom: `${borderWidth}px solid ${borderColor}`,
            overflow: "hidden",
            boxSizing: "border-box",
        })
    }

    public static styleLabel(label: HTMLElement, title: string): void {
        super.styleLabel(label, title)
        applyStyles(label, {
            color: "rgb(51, 51, 51)",
            fontSize: `${fontSize}px`,
            padding: "0",
            margin: "0",
        })
    }

    public static styleInput(input: HTMLElement): void {
        super.styleInput(input)
        applyStyles(input, {
            color: "rgb(115, 115, 115)",
            fontSize: `${fontSize}px`,
            cssFloat: "right",
            WebkitAppearance: "none",
            border: "none",
            maxWidth: "45%",
            padding: "0",
            margin: "0",
        })
        if (input instanceof HTMLSelectElement) {
            applyStyles(input, {
                height: "",
                backgroundColor: `${rowBackgroundColor}`,
                backgroundImage: `url(${STATIC_URL_MOBILE}chevron_right_light.svg)`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right bottom",
                backgroundSize: `${selectArrowSize}px ${selectArrowSize}px`,
                padding: `0 ${selectArrowSize + 2}px 0 0`,
                textAlign: "right",
                textAlignLast: "right",
                opacity: "0",
                position: "absolute",
                width: "100%",
                maxWidth: "100%",
            })
        }
    }

    public static styleSelectOverlay(overlaySpan: HTMLSpanElement): void {
        applyStyles(overlaySpan, {
            color: "rgb(115, 115, 115)",
            fontSize: `${fontSize}px`,
            height: "",
            backgroundColor: `${rowBackgroundColor}`,
            backgroundImage: `url(${STATIC_URL_MOBILE}chevron_right_light.svg)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right bottom",
            backgroundSize: `${selectArrowSize}px ${selectArrowSize}px`,
            padding: `0 ${selectArrowSize + 2}px 0 0`,
            margin: "0",
            whiteSpace: "nowrap",
            pointerEvents: "none",
            zIndex: 2,
        })
    }
}
