import { EventRouter } from "../common/events"

// The StartStop events are fired with true on broadcast start and false on broadcast stop
// Note the OBS code will fire OBSBroadcastStartStop when webRTC go live too, but with possibly significant
// lag, so be careful of anyBroadcastStartStop double firing

export const anyBroadcastStartStop = new EventRouter<boolean>("anyRTCBroadcastStartStop")
export const webRTCBroadcastStartStop = new EventRouter<boolean>("webRTCBroadcastStartStop")
export const OBSBroadcastStartStop = new EventRouter<boolean>("OBSBroadcastStartStop")

const fireAnyBroadcastStartStop = (broadcasting: boolean) => {
    anyBroadcastStartStop.fire(broadcasting)
}
webRTCBroadcastStartStop.listen(fireAnyBroadcastStartStop)
OBSBroadcastStartStop.listen(fireAnyBroadcastStartStop)
