import { trackCustomEvent } from "@convivainc/conviva-js-appanalytics"
import Conviva from "@convivainc/conviva-js-coresdk"
import { PageType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { isiOS, isMobileDevice, isSafari, isSamsungBrowser } from "@multimediallc/web-utils/modernizr"
import { pageContext, roomDossierContext } from "../../cb/interfaces/context"
import { PushService } from "../../cb/pushservicelib/pushService"
import { currentSiteSettings } from "../../cb/siteSettings"
import { SubSystemType } from "../../common/debug"
import { addEventListenerPoly, removeEventListenerPoly } from "../addEventListenerPolyfill"
import { postCb } from "../api"
import { Component } from "../defui/component"
import { EventRouter, ListenerGroup } from "../events"
import { featureFlagIsActive } from "../featureFlag"
import { loadRoomRequest } from "../fullvideolib/userActionEvents"
import { loadRoomRequest as mobileLoadRoomRequest } from "../mobilelib/userActionEvents"
import { addPageAction } from "../newrelic"
import { ignoreCatch } from "../promiseUtils"
import { RoomStatus, statusMapLookup } from "../roomStatus"
import { parseQueryString } from "../urlUtil"
import { playerForceMuted } from "../userActionEvents"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { documentVisibilityChange, isDocumentVisible } from "../windowUtils"
import { getFallback, getLLHLSSetting, saveLLHLSSetting, setFallback } from "./playerSettings"
import { convivaEnabled, LLHLSSupported, shouldUseCustomControls } from "./utils"
import { HLSVideoMetrics } from "./videoMetrics"
import type { IPlayer } from "./interfaces"
import type { IQualityLevel } from "./playerSettings"
import type { IRoomContext } from "../context"
import type { RoomStatusNotifier } from "../roomStatusNotifier"

export const maxSegmentDuration = 3000
export const jpegFallbackSeconds = 20

declare const CONVIVA_KEY: string
declare const CONVIVA_TEST_KEY: string
declare const GIT_TAG: string
declare const REVISION: string

const LLHLSEDGE = 20

export interface IHlsPlayer {
    play: () => Promise<void> | undefined
}

export interface IPictureInPictureChange {
    active: boolean
    videoPaused?: boolean // Only set when active is false.  Return button will not pause, close button will pause
}

export const pictureInPictureChange = new EventRouter<IPictureInPictureChange>("pictureInPictureChange")
export const fallbackFromLLHLS = new EventRouter<string>("LLHLSFallback")

export enum edgeRefreshType {
    RandomEdge = 0,
    NewEdge = 1,
    SameEdge = 2,
}

// If api is "new":
//   { success: true } | { success: false, error: object }
// If api is "old":
//   { success: boolean }
type IVideoStartAttempt = { api: "new" | "old", success: boolean, error?: object }

export abstract class HlsPlayer extends Component implements IPlayer {
    public readonly requestJPEG = new EventRouter<IRoomContext>("requestJPEG")
    public readonly requestControlVisibility = new EventRouter<boolean>("requestControlVisibility")
    public readonly setControlVolume = new EventRouter<{ volume: number, save: boolean }>("setControlVolume")
    public readonly setControlIsMuted = new EventRouter<{ isMuted: boolean, save: boolean }>("setControlIsMuted")

    readonly supportsAutoplayWithAudio = true
    readonly videoElement = document.createElement("video")
    protected roomStatus: RoomStatus
    protected isStreamReconnecting = false
    protected connectingOverlay = document.createElement("div")
    protected connectingTimeouts: number[] = []
    protected tooMuchWaitingTimeout: number | undefined
    protected videoMetrics: HLSVideoMetrics
    public possibleQualityLevelsChanged = new EventRouter<IQualityLevel[]>("possibleQualityLevelsChanged")
    public videoOfflineChange = new EventRouter<boolean>("videoOfflineChange")
    public playbackStart = new EventRouter<undefined>("playbackStart")
    private onlinePollingTimeout: number | undefined
    private offlinePollingTimeout: number | undefined
    private lastSeenHlsUrl: string
    private showJpeg = true
    protected videoOffline = false
    protected room: string
    protected roomUid: string
    protected userlistColor: string
    protected isInPictureInPicture = false
    protected context: IRoomContext
    protected tryingTimeoutRefresh = false
    protected startTime = 0
    protected loading = 0
    protected pageLoaded = 0
    protected metaLoaded = 0
    protected dataLoaded = 0
    protected loadStart = 0
    protected minutesSincePlay = 0
    protected startTimesSent = false
    protected updateQuality: number
    protected lastDropped = 0
    protected lastFrames = 0
    protected sessionFrames = 0
    protected sessionDropped = 0
    protected roomCount = 0
    protected leaveFrames: VideoPlaybackQuality | undefined
    protected hiddenTotal = 0
    protected hiddenDropped = 0
    protected videoAnalytics: Conviva.VideoAnalytics | undefined
    protected playerType: string
    public isDisabled = false
    protected enableLLHLS = false
    protected listeners = new ListenerGroup()
    protected currentSrc: string
    protected allowLLHLS = false
    protected waitingCounter = 0
    protected waitingStart: number | undefined
    protected unloadSent = false
    public updateLLHLSButton = new EventRouter<{ allowed: boolean, enabled: boolean }>("updateLLHLSButton")
    protected firstLoad = true
    protected host: string
    protected edge: string
    protected playlistResource: string
    protected playableHlsPlayer: IHlsPlayer
    protected isCasting = false
    protected apiFailures = 0

    protected constructor(protected roomStatusNotifier: RoomStatusNotifier, protected newRelicName: string) {
        // Don't get newRelicName from this.constructor.name since the compiler will change the class name
        super()
        this.element.style.position = "static"
        this.element.style.background = `#333333 url(${STATIC_URL}cam_notice_background.jpg) center center / cover`
        this.element.className = "videoPlayerDiv"
        this.element.dataset.testid = "video-container"

        this.videoElement.setAttribute("webkit-playsinline", "") // eslint-disable-line @multimediallc/no-set-attribute
        this.videoElement.setAttribute("playsinline", "") // eslint-disable-line @multimediallc/no-set-attribute
        this.videoElement.style.margin = "0"
        this.videoElement.style.padding = "0"
        this.videoElement.style.width = "100%"
        this.videoElement.style.height = "100%"
        this.videoElement.style.objectFit = "contain"
        // This is because videoJS's div covers Player Component div, and there is no way to directly make it visibility: hidden
        //  By the time SetReconnecting runs, this.videoElement points to the video element, not the VideoJS div.
        this.videoElement.style.backgroundColor = "rgba(0, 0, 0, 0.0)"
        this.videoElement.dataset.testid = "video"
        this.videoElement.id = "chat-player"
        this.setAutoplay(true)

        this.element.appendChild(this.videoElement)
        window["videoElement"] = this.videoElement // for debugging

        this.connectingOverlay.style.backgroundColor = "#000000"
        this.connectingOverlay.style.opacity = "0.6"
        this.connectingOverlay.style.position = "absolute"
        this.connectingOverlay.style.width = "inherit"
        this.connectingOverlay.style.height = "inherit"

        this.videoMetrics = new HLSVideoMetrics(newRelicName)
        this.videoMetrics.bindAll()

        pictureInPictureChange.listen((pipEvent) => {
            this.isInPictureInPicture = pipEvent.active
        }).addTo(this.listeners)

        loadRoomRequest.listen(() => {
            this.endSession()
        }).addTo(this.listeners)

        mobileLoadRoomRequest.listen(() => {
            this.endSession()
        }).addTo(this.listeners)

        this.videoElement.onloadstart = () => {
            if (this.loading !== 0) {
                this.loadStart = Date.now() - this.loading
            }
            this.startConviva()
        }

        this.videoElement.onloadeddata = () => {
            if (this.loading !== 0) {
                this.dataLoaded = Date.now() - this.loading
            }
        }

        this.videoElement.onloadedmetadata = () => {
            if (this.loading !== 0) {
                this.metaLoaded = Date.now() - this.loading
            }
        }

        this.videoElement.onplaying = ev => {
            if(this.startTimesSent === false){
                this.roomCount += 1
                let playerTime = 0, page = 0
                if (this.loading !== 0){
                    playerTime = Date.now() - this.loading
                }
                if (this.pageLoaded !== 0){
                    page = Date.now() - this.pageLoaded
                }
                this.videoMetrics.sendStartTimes(playerTime, page, this.dataLoaded, this.metaLoaded, this.loadStart, this.roomStatus, this.roomCount, this.videoElement.height)
                this.startQualityTracking()
                this.startTimesSent = true
                if (this.waitingStart !== undefined) {
                    this.waitingCounter += Date.now() - this.waitingStart
                    this.waitingStart = undefined
                }
            }
            if (isSafari()) {
                this.videoElement.style.objectFit = "contain"
            }
            this.updateStreamToggle()
        }
        if (!(parseQueryString(window.location.search)["disable-player-fallback"] !== undefined)) {
            // if llhls is enabled and the viewer gets 15 seconds of buffering events within one minute
            // and the broadcaster quality is not bad, then we force the viewer to fallback to hls
            this.videoElement.onwaiting = () => {
                if (this.enableLLHLS && !getFallback() && roomDossierContext.getState().quality?.quality !== "bad"){
                    if (this.waitingCounter > 15000) {
                        this.forceHlsFallback("Buffering")
                        return
                    }
                    this.waitingStart = Date.now()
                }
            }

            window.setInterval(() => {
                this.waitingCounter = 0
            }, 60000)
        }

        const unpause = () => {
            this.showControls()
            this.play()

            // ios tries for ~500ms after webkitendfullscreen to make sure the video repauses after play() calls
            const play = () => {
                this.play()
            }
            addEventListenerPoly("pause", this.videoElement, play)
            window.setTimeout(() => {
                removeEventListenerPoly("pause", this.videoElement, play)
            }, 600)
        }

        // Desktop on ios doesn't give the same fullscreen events as usual, so we have to listen on the video element
        addEventListenerPoly("webkitendfullscreen", this.videoElement, unpause)

        addEventListenerPoly("leavepictureinpicture", this.videoElement, unpause)

        addEventListenerPoly("beforeunload", window, this.stopConviva.bind(this))

        documentVisibilityChange.listen(this.visibilityChange).addTo(this.listeners)

        addEventListenerPoly("pagehide", window, this.sendUnloadEvent.bind(this))
        addEventListenerPoly("beforeunload", window, this.sendUnloadEvent.bind(this))
        addEventListenerPoly("pageshow", window, this.maybeSendUnloadEvent.bind(this))
        addEventListenerPoly("popstate", window, this.maybeSendUnloadEvent.bind(this))

        fallbackFromLLHLS.listen((reason: string) => {
            this.forceHlsFallback(reason)
        }).addTo(this.listeners)
    }

    private setEnableLLHLS(enable: boolean): void {
        this.enableLLHLS = enable
        this.videoMetrics.setEnableLLHLS(enable)
    }

    private visibilityChange = (isVisible: boolean) => {
        if (!isVisible){
            this.leaveFrames = this.videoElement.getVideoPlaybackQuality()
            if (this.videoAnalytics !== undefined) {
                Conviva.Analytics.reportAppBackgrounded()
            }
        } else {
            if (this.leaveFrames !== undefined){
                const returnFrames = this.videoElement.getVideoPlaybackQuality()
                this.hiddenTotal += returnFrames.totalVideoFrames - this.leaveFrames.totalVideoFrames
                this.hiddenDropped += returnFrames.droppedVideoFrames - this.leaveFrames.droppedVideoFrames
            }
            if (this.videoAnalytics !== undefined) {
                Conviva.Analytics.reportAppForegrounded()
            }
        }

        // Explicitly pause the video on mobile devices when the tab is hidden to prevent background noise, except when casting
        // Check for status after returning, update status if necessary
        // Updating the status should refresh the stream if a viewing status is recieved
        if (isMobileDevice() && !this.isCasting) {
            clearTimeout(this.onlinePollingTimeout)
            this.onlinePollingTimeout = undefined
            this.setAutoplay(isVisible)
            const refreshRoom = () => {
                this.getHlsUrlAndRoomStatus(this.context, false, true).then((urlAndStatus) => {
                    this.apiFailures = 0
                    if (this.videoAnalytics !== undefined) {
                        this.videoAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.SEEK_ENDED)
                    }
                    if (urlAndStatus.status !== this.roomStatus) {
                        this.context.chatConnection.changeStatus(urlAndStatus.status)
                        roomDossierContext.setState({ roomStatus: urlAndStatus.status })
                    } else {
                        this.refreshStreamOnSameEdge(this.context)
                    }
                }).catch(() => {
                    if (this.apiFailures > 1) {
                        this.apiFailures = 0
                        error("Attempting to fallback to JPEG player.")
                        this.setControlIsMuted.fire({ isMuted: true, save: false })
                        this.requestJPEG.fire(this.context)
                    } else {
                        this.apiFailures += 1
                        window.setTimeout(() => {
                            refreshRoom()
                        }, 500)
                    }
                })
            }
            if (isVisible) {
                refreshRoom()
            } else {
                this.videoElement.pause()
            }
        }
    }

    // eslint-disable-next-line complexity
    private async getHlsUrlAndRoomStatus(context: IRoomContext, exclude_current = false, preserve_current = false): Promise<{ url: string, status: RoomStatus }> {
        /**
         * This is a bit of a hack. Basically, we want to set some isFirstURLRequest
         * flag when a room loads, but by the time roomLoaded fires a *lot* of post
         * load operations have already been run, including those that call this
         * function. There's no event I can find that *actually* fires when the room
         * context has loaded but *before* any work gets underway on that context.
         * But what we can do *instead* is say "always try to return a url you haven't
         * returned before". The first time this function is called the dossier will
         * of course have a url we haven't returned before, so we return it and save
         * the redundant call.
         */
        if (
            context.dossier.hlsSource !== this.lastSeenHlsUrl
            && context.dossier.hlsSource !== ""
            && !preserve_current
            && !exclude_current
        ) {
            this.lastSeenHlsUrl = context.dossier.hlsSource
            let lastStatus = context.chatConnection.status
            if (this.videoOffline) {
                lastStatus = RoomStatus.Offline
            }
            return {
                url: this.fullyParseURL(context.dossier.hlsSource),
                status: lastStatus,
            }
        } else {
            let parsedEdge = ""
            if (this.currentSrc !== undefined && this.currentSrc !== "") {
                try {
                    parsedEdge = (new URL(this.currentSrc)).hostname
                } catch (e) {
                    error("Unable to parse current edge", { "error": e, "url": this.currentSrc })
                }
            }
            const response = await postCb("get_edge_hls_url_ajax/", {
                "room_slug": context.dossier.room,
                "bandwidth": "high",
                "current_edge": preserve_current ? parsedEdge : "", // prefer this edge
                "exclude_edge": exclude_current ? parsedEdge : "",
            })
            const data: {
                "success": boolean,
                "url": string,
                "room_status": string,
            } = JSON.parse(
                response.responseText,
            )
            if (data["success"]) {
                this.lastSeenHlsUrl = data["url"]
                return {
                    url: this.fullyParseURL(data["url"]),
                    status: statusMapLookup(data["room_status"]),
                }
            } else {
                // eslint-disable-next-line @typescript-eslint/only-throw-error
                throw response.responseText
            }
        }
    }

    private parseHlsUrl(url: string): string {
        // Overrides the edge server to point to a specific number, only respected
        // until LLHLS fallsback or is intentially toggled off
        const initialEdgeQuery = parseQueryString(window.location.search)["init-edgemm"]
        if (initialEdgeQuery !== undefined && window["_hasUsedEdgeQueryParam"] === undefined && url !== "") {
            window["_hasUsedEdgeQueryParam"] = true
            return url.replace(
                /(^https:\/\/)(.+?)(\/.+)/,
                `https://${initialEdgeQuery}.live.mmcdn.com$3`,
            )
        }
        // Allows for overriding our edge server to point to a specific number
        // with ?edge={n} or ?edgemm=hostname
        const parsedQueryString = parseQueryString(window.location.search)
        if (parsedQueryString["edgemm"] !== undefined) {
            return url.replace(
                /(^https:\/\/)(.+?)(\/.+)/,
                `https://${parsedQueryString["edgemm"]}.live.mmcdn.com$3`,
            )
        } else if (parsedQueryString["edge"] !== undefined) {
            return url.replace(
                /(^https:\/\/)(.+?)(\/.+)/,
                `https://edge${parsedQueryString["edge"]}.stream.highwebmedia.com$3`,
            )
        } else {
            return url
        }
    }

    refreshStream(context: IRoomContext, exclude_current = false, preserve_current = false): void {
        this.stop()
        if (this.isDisabled) {
            this.stopQuality()
            return
        }
        (async () => { // Async wrapper to allow await
            this.stopQuality()
            this.waitingCounter = 0
            this.waitingStart = undefined
            this.pageLoaded = Date.now()
            if (context.dossier.room !== this.room) {
                debug("Trying to refresh old room context")
                return
            }
            // save frames since last source load
            this.sessionFrames += this.lastFrames
            this.sessionDropped += this.lastDropped
            // reset frames since last source load
            this.lastFrames = 0
            this.lastDropped = 0
            // Hidden frames are taken from the video quality, so also reset on source load
            this.hiddenTotal = 0
            this.hiddenDropped = 0
            this.leaveFrames = undefined
            let url, newRoomStatus
            try {
                const urlAndStatus = await this.getHlsUrlAndRoomStatus(context, exclude_current, preserve_current)
                url = urlAndStatus.url
                newRoomStatus = urlAndStatus.status
            } catch (err) {
                error("Unable to refresh HLS stream", { "error": err })
                return
            }
            if (this.roomStatus === RoomStatus.Offline && newRoomStatus !== this.roomStatus) {
                this.isStreamReconnecting = true
            }
            this.videoOffline = newRoomStatus === RoomStatus.Offline
            this.videoOfflineChange.fire(this.videoOffline)
            if (this.roomStatus !== RoomStatus.NotConnected && newRoomStatus === RoomStatus.Offline) {
                this.setOfflinePollingTimeout(context)
                return
            }
            if (this.roomStatus === RoomStatus.PrivateNotWatching && this.roomStatus === newRoomStatus && PushService.isEnabledForUI()) {
                return
            }
            this.roomStatus = newRoomStatus
            this.updateConviva(url, context.dossier.room)
            this.loadHlsStream(context, url)
        })().catch(ignoreCatch)
    }

    public refreshStreamOnNewEdge(context: IRoomContext): void {
        this.refreshStream(context, true, false)
    }

    public refreshStreamOnSameEdge(context: IRoomContext): void {
        this.refreshStream(context, false, true)
    }

    private async tryVideoStart(): Promise<IVideoStartAttempt> {
        // Modern browsers now return a Promise that may throw an error
        const promise: Promise<void> | undefined = this.playableHlsPlayer.play()
        if (promise === undefined) {
            // Older browsers may not return anything from play() but still restrict unmuted
            // autoplays. Attempt to detect this manually.
            return {
                api: "old",
                success: !this.videoElement.paused,
            }
        } else {
            try {
                await promise
                return {
                    api: "new",
                    success: true,
                }
            } catch (e) {
                return {
                    api: "new",
                    success: false,
                    error: e,
                }
            }
        }
    }

    private async playInternal(): Promise<void> {
        // Attempt play.
        const firstAttempt = await this.tryVideoStart()
        if (firstAttempt.success) {
            this.playbackStart.fire(undefined)
            return
        }
        // Process failure.
        if (firstAttempt.api === "old") {
            addPageAction("NoPromiseAutoplayPolicy")
        }
        playerForceMuted.fire(undefined)
        this.setMuted(true)
        // Attempt play again.
        const secondAttempt = await this.tryVideoStart()
        if (secondAttempt.success) {
            this.playbackStart.fire(undefined)
            return
        }
        // Process failure.
        if (secondAttempt.api === "new") {
            if (!this.isStreamReconnecting) {
                warn("Unable to play twice in a row", { "err": secondAttempt.error }, SubSystemType.Video)
                if (isSamsungBrowser()) {
                    // SamsungBrowser has a couple of bugs. Firstly, when autoplay is blocked, it is not
                    // picked up correctly by the can-autoplay library. Secondly, even after the user
                    // clicks on volume and successfuly switches to the HLS player, the next room loaded
                    // via AJAX is paused again. So, we must provide a default JPEG player for these cases.
                    error("Attempting to fallback to JPEG player.")
                    if (this.videoAnalytics !== undefined) {
                        trackCustomEvent({
                            name: "JpegFallback",
                            data: {
                                "roomName": this.room,
                                "broadcasterID": this.roomUid,
                            },
                        })
                        this.videoAnalytics.reportPlaybackError("JpegFallback", Conviva.Constants.ErrorSeverity.FATAL)
                    }
                    this.stopQuality()
                    this.stopConviva()
                    this.setControlIsMuted.fire({ isMuted: true, save: false })
                    this.requestJPEG.fire(this.context)
                }
            }
            this.handleNeverPlayed()
        }
    }

    play(): void {
        if (this.shouldPreventPlay()) {
            this.stopTooMuchWaitingTimeout()
            return
        }
        this.playInternal().catch(ignoreCatch)
    }

    protected abstract loadHlsStream(context: IRoomContext, source: string): void

    stop(): void {
        if (this.roomStatusNotifier.displaysForStatus(this.roomStatus)) {
            this.removeReconnecting()
            this.clearPlayerTimeouts()
        }
        if (this.isStreamReconnecting) {
            this.clearPlayerTimeouts()
        }
        this.stopTooMuchWaitingTimeout()
    }

    public stopVideoAndMetrics(): void {
        this.endSession()
        this.videoMetrics.endSession()
        this.firstLoad = false
        this.stop()
        this.stopConviva()
    }

    public clearPlayerTimeouts(): void {
        for (const timeout of this.connectingTimeouts) {
            clearTimeout(timeout)
        }
        clearTimeout(this.offlinePollingTimeout)
        this.offlinePollingTimeout = undefined
        clearTimeout(this.onlinePollingTimeout)
        this.onlinePollingTimeout = undefined
    }

    abstract setMuted(muted: boolean): void

    setVolume(volume: number): void {
        this.videoElement.volume = volume / 100
    }

    getVolume(): number {
        if (this.videoElement.muted || (!this.isStreamReconnecting && this.videoElement.paused)) {
            return 0
        }
        return this.videoElement.volume * 100
    }

    getMuted(): boolean {
        if (this.videoElement.volume === 0) {
            return true
        }
        return this.videoElement.muted
    }

    protected showNativeControls(): void {
        this.requestControlVisibility.fire(false)
    }

    protected showCustomControls(): void {
        this.requestControlVisibility.fire(true)
    }

    public showControls(): void {
        if (shouldUseCustomControls()) {
            this.showCustomControls()
        } else {
            this.showNativeControls()
        }
    }

    public hideControls(): void {
        this.requestControlVisibility.fire(false)
        this.hideNativeControls()
    }

    protected abstract hideNativeControls(): void

    public setVolumeMuted(volume: number, isMuted: boolean): void {}

    public enterFullScreenMode(): void {}

    abstract setQualityLevel(level: number): void

    abstract setupConvivaPlayer(): void

    getControlBarHeight(): number {return 0}

    handleNeverPlayed(): void {
        // Don't try to reconnect if the video was stopped by the browser
        this.stopTooMuchWaitingTimeout()
    }

    handleRoomLoaded(context: IRoomContext): void {
        this.videoOffline = false
        this.showJpeg = !pageContext.current.isTestbed
        this.videoElement.poster = !this.showJpeg ?
            "" : `${currentSiteSettings.jpegStreamUrl}stream?room=${context.dossier.room}&f=${Math.random()}`
        this.context = context
        this.room = context.dossier.room
        this.roomUid = context.dossier.roomUid
        this.userlistColor = context.dossier.userlistColor
        if (!this.roomStatusNotifier.displaysForStatus(context.dossier.roomStatus) && !this.videoOffline && convivaEnabled()) {
            this.handleConviva()
        }
        if (this.currentSrc === undefined) {
            this.currentSrc = context.dossier.hlsSource
        }
        this.loading = Date.now()
        context.chatConnection.event.statusChange.listen((roomStatusChangeNotification) => {
            this.roomStatus = roomStatusChangeNotification.currentStatus
            this.updateStreamToggle()
            this.videoMetrics.setStatus(this.roomStatus)
            if (this.roomStatusNotifier.displaysForStatus(roomStatusChangeNotification.currentStatus) || this.videoOffline) {
                this.stopVideoAndMetrics()
                if (!this.videoOffline) {
                    clearTimeout(this.onlinePollingTimeout)
                    this.onlinePollingTimeout = undefined
                    this.setOnlinePollingTimeout(context)
                } else {
                    this.setOfflinePollingTimeout(context)
                }
            } else {
                clearTimeout(this.onlinePollingTimeout)
                this.onlinePollingTimeout = undefined
                switch (roomStatusChangeNotification.previousStatus) {
                    case RoomStatus.NotConnected:
                        break
                    case RoomStatus.PrivateRequesting:
                        break

                    default:
                        switch (roomStatusChangeNotification.currentStatus) {
                            case RoomStatus.NotConnected:
                                // Room initially loaded or SPA.
                                this.setReconnecting(context.dossier.room)
                                window.setTimeout(() => {
                                    // use url from context to avoid extra call for initial room load
                                    if (this.firstLoad) {
                                        this.firstLoad = false
                                        this.refreshStream(context)
                                    } else {
                                        // Should only hit this on SPA nav, preserve current edge
                                        this.refreshStreamOnSameEdge(context)
                                    }
                                }, 0)
                                break
                            default:
                                // Let the roomStatusNotifier remove the current message before adding the new one.
                                this.connectingTimeouts.push(window.setTimeout(() => {
                                    this.setReconnecting(context.dossier.room)
                                }, 0))
                                this.connectingTimeouts.push(window.setTimeout(() => {
                                    this.refreshStreamOnSameEdge(context)
                                }, maxSegmentDuration))
                        }
                }
                if (convivaEnabled() && this.videoAnalytics === undefined) {
                    // only setup conviva on status change if it's not already setup
                    this.handleConviva()
                }
            }
        }).addTo(this.listeners)
    }

    private setReconnecting(roomName: string): void {
        const backgroundImage = this.showJpeg ? `${currentSiteSettings.jpegStreamUrl}stream?room=${roomName}&f=${Math.random()}` : ""
        this.element.style.background = `#333333 url(${backgroundImage}) center center / cover`
        this.roomStatusNotifier.showConnectingStatus()
        this.element.insertBefore(this.connectingOverlay, this.element.firstChild)
        this.isStreamReconnecting = true
    }

    // Implementations of HlsPlayer should call this on video play if this.isStreamReconnecting
    protected removeReconnecting(): void {
        this.isStreamReconnecting = false
        this.roomStatusNotifier.hideConnectingStatus()
        if (this.connectingOverlay.parentElement === this.element) {
            this.element.removeChild(this.connectingOverlay)
        }
        this.element.style.background = `#333333 url(${STATIC_URL}cam_notice_background.jpg) center center / cover`
    }

    // This is for when the video is stopped (as in Away/PrivateNotWatching/etc.) to check if the video goes offline
    private setOnlinePollingTimeout(context: IRoomContext): void {
        if (this.onlinePollingTimeout === undefined) {
            this.onlinePollingTimeout = window.setTimeout(async () => {
                const status = (await this.getHlsUrlAndRoomStatus(context, false, true)).status

                if (status === RoomStatus.Offline) {
                    this.videoOffline = true
                    clearTimeout(this.onlinePollingTimeout)
                    this.onlinePollingTimeout = undefined
                    this.setOfflinePollingTimeout(context)
                    this.videoOfflineChange.fire(this.videoOffline)
                } else {
                    clearTimeout(this.onlinePollingTimeout)
                    this.onlinePollingTimeout = undefined
                    this.setOnlinePollingTimeout(context)
                }
            }, 10000)
        }
    }

    // Used both when the video fails to retry connection or when video is offline to check for video coming back online
    protected setOfflinePollingTimeout(context: IRoomContext): void {
        if (this.offlinePollingTimeout === undefined) {
            this.offlinePollingTimeout = window.setTimeout(() => {
                this.offlinePollingTimeout = undefined
                this.refreshStreamOnSameEdge(context)
            }, 10000)
        }
    }

    protected setTooMuchWaitingTimeout(context: IRoomContext, callback = () => {}): void {
        this.stopTooMuchWaitingTimeout()
        if (parseQueryString(window.location.search)["disable-player-fallback"] !== undefined) {
            return
        }
        this.tooMuchWaitingTimeout = window.setTimeout(() => {
            if (this.tryingTimeoutRefresh) {
                // Timed out twice in a row without playing, fallback to jpeg
                this.removeReconnecting()
                this.stopQuality()
                if (this.videoAnalytics !== undefined) {
                    const error = this.enableLLHLS ? "LLHLS Fallback" : "HLS Fallback"
                    this.videoAnalytics.reportPlaybackError(error, Conviva.Constants.ErrorSeverity.FATAL)
                    trackCustomEvent({
                        name: error,
                        data: {
                            "roomName": this.room,
                            "broadcasterID": this.roomUid,
                        },
                    })
                }
                this.stopConviva()
                if (this.enableLLHLS && this.allowLLHLS) {
                    this.forceHlsFallback("Subsequent Timeout")
                } else {
                    error("Attempting to fallback to JPEG player.")
                    this.setControlIsMuted.fire({ isMuted: true, save: false })
                    this.requestJPEG.fire(this.context)
                }
            } else if (!isDocumentVisible() || this.isInPictureInPicture) {
                this.setTooMuchWaitingTimeout(context, callback)
            } else {
                info("Video seems to be stuck, refreshing")
                this.tryingTimeoutRefresh = true
                this.refreshStreamOnSameEdge(context)
                callback()
            }
        }, jpegFallbackSeconds * 1000 / 2)
    }

    protected stopTooMuchWaitingTimeout(): void {
        if (this.tooMuchWaitingTimeout !== undefined) {
            clearTimeout(this.tooMuchWaitingTimeout)
            this.tooMuchWaitingTimeout = undefined
        }
    }

    public getVideoElement(): HTMLVideoElement | undefined {
        return this.videoElement
    }

    public onForceRemoved(): void {
        this.endSession()
        this.listeners.removeAll()
        this.cleanupEventListeners()
        this.videoMetrics.playerForceRemoved()
        this.videoElement.remove()
    }

    public setPageLoaded(pageLoaded: number): void {
        this.pageLoaded = pageLoaded
    }

    public stopQuality(): void {
        clearInterval(this.updateQuality)
    }

    private startQualityTracking(): void {
        this.stopQuality()
        window.setTimeout(() => {
            this.sendQuality(false)
            this.updateQuality = window.setInterval(() => {
                this.sendQuality(false)
            }, 60000)
        }, 15000)
    }

    private sendQuality(force: boolean): void {
        if (force || (!document.hidden && ![RoomStatus.Away, RoomStatus.PrivateNotWatching, RoomStatus.Hidden, RoomStatus.Offline].includes(this.roomStatus))) {
            let totalFrames = 0, totalDropped = 0, percentDropped = 0, segmentDropped = 0, droppedDif = 0, frameDif = 0
            if (!isiOS()) {
                const quality = this.videoElement.getVideoPlaybackQuality()
                totalDropped = quality["droppedVideoFrames"] - this.hiddenDropped
                totalFrames = quality["totalVideoFrames"] - this.hiddenTotal
                // Calculate frame info since last source load
                droppedDif = totalDropped - this.lastDropped
                frameDif = totalFrames - this.lastFrames
                segmentDropped = -1
                if (frameDif > 0) {
                    segmentDropped = droppedDif / frameDif * 100
                }
                // Add frames from previous sources for session totals
                totalFrames = this.sessionFrames + totalFrames
                totalDropped = this.sessionDropped + totalDropped
                percentDropped = -1
                if (totalFrames > 0) {
                    percentDropped = totalDropped / totalFrames * 100
                }
                // Save frames since source load
                this.lastFrames = totalFrames
                this.lastDropped = totalDropped
            }
            let timeToSend = this.minutesSincePlay
            // If forced, it's an unload event
            if (force) {
                timeToSend = -1
            }
            const currentPixels = this.videoElement.videoHeight * this.videoElement.videoWidth
            this.videoMetrics.sendQuality(percentDropped, segmentDropped, totalFrames, frameDif,
                                          currentPixels, this.videoElement.videoHeight, timeToSend, this.roomStatus)
            }
        this.minutesSincePlay += 1
    }

    protected initConviva(): void {
        let key = CONVIVA_KEY
        const settings: Conviva.ConvivaOptions = {}
        if (pageContext.current.isInternal || !PRODUCTION) {
            key = CONVIVA_TEST_KEY
            // uncomment next line to send data to touchstone for QA purposes, never do this in production
            // settings[Conviva.Constants.GATEWAY_URL] = "https://multimedia-test.testonly.conviva.com"
            // uncomment next line to enable debug console logging - very verbose
            // settings[Conviva.Constants.LOG_LEVEL] = Conviva.Constants.LogLevel.DEBUG
        }

        Conviva.Analytics.init(key, null, settings) // eslint-disable-line @multimediallc/no-null-usage

        const deviceMetadata: Conviva.ConvivaDeviceMetadata = {}
        deviceMetadata[Conviva.Constants.DeviceMetadata.CATEGORY] = Conviva.Constants.DeviceCategory.WEB
        deviceMetadata[Conviva.Constants.DeviceMetadata.TYPE] = isMobileDevice() ? Conviva.Constants.DeviceType.MOBILE : Conviva.Constants.DeviceType.DESKTOP

        Conviva.Analytics.setDeviceMetadata(deviceMetadata)

        this.videoAnalytics = Conviva.Analytics.buildVideoAnalytics();
    }

    protected startConviva(): void {
        let viewerId = "anon"
        let loggedIn = "false"
        if (pageContext.current.loggedInUser !== undefined) {
            viewerId = pageContext.current.loggedInUser.userUid
            loggedIn = "true"
        } else {
            viewerId = `${viewerId}_${this.videoMetrics.getSessionID()}`
        }
        if (this.videoAnalytics !== undefined) {
            const contentMetadata: Conviva.ConvivaMetadata = {
                [Conviva.Constants.ASSET_NAME]: this.room,
                [Conviva.Constants.IS_LIVE]: Conviva.Constants.StreamType.LIVE,
                [Conviva.Constants.VIEWER_ID]: viewerId,
                [Conviva.Constants.PLAYER_NAME]: this.playerType,
                ["c3.app.version"]: GIT_TAG !== "" ? GIT_TAG : REVISION,
                ["userColor"]: this.userlistColor,
                ["loggedIn"]: loggedIn,
            }
            const domain = document.location.href.match(/^https?:\/\/([^/]+).*$/)
            contentMetadata.domain = domain !== null ? domain[1] : ""
            this.videoAnalytics.reportPlaybackRequested(contentMetadata)
        }
    }

    protected handleConviva(): void {
        this.stopConviva()
        this.initConviva()
        this.setupConvivaPlayer()
    }

    private updateConviva(url: string, roomName: string): void {
        const edge = url.match(/^https:\/\/edge(.+)\.live/)
        const playlistResource = url.match(/(live-.+)\/amlst/)
        this.host = new URL(url).hostname
        this.edge = edge !== null ? edge[1] : ""
        this.playlistResource = playlistResource !== null ? playlistResource[1] : ""
        if (this.videoAnalytics !== undefined) {
            this.videoAnalytics.setContentInfo({
                [Conviva.Constants.ASSET_NAME]: roomName,
                [Conviva.Constants.STREAM_URL]: url,
                [Conviva.Constants.DEFAULT_RESOURCE]: this.host,
                ["broadcasterID"]: this.roomUid,
                ["edgeName"]: this.edge,
                ["playlistResource"]: this.playlistResource,
                ["streamType"]: this.enableLLHLS ? "llhls" : "hls",
                ["videoMode"]: videoModeHandler.getVideoMode(),
            })
        }
        this.videoMetrics.updateResourceInfo(this.host, this.edge, this.playlistResource)
    }

    protected stopConviva(): void {
        if (this.videoAnalytics !== undefined){
            try {
                this.videoAnalytics.reportPlaybackEnded()
                this.videoAnalytics.release()
                Conviva.Analytics.release()
                this.videoAnalytics = undefined
            } catch (e) {
                error("Error stopping conviva", { "error": e })
            }
        }
    }

    public disable(disable: boolean): void {
        this.isDisabled = disable
    }

    public forceHlsFallback(reason: string): void {
        setFallback(true)
        this.setEnableLLHLS(false)
        error("Attempting to fallback to HLS stream.")
        const edge = this.currentSrc.match(/^https:\/\/edge(.+)\.live/)
        this.videoMetrics.LLHLSFallback(this.currentSrc, edge, this.playerType, reason)
        this.handleConviva()
        this.endSession()
        this.videoMetrics.endSession()
        // Refresh player with new source on new edge
        this.refreshStreamOnNewEdge(this.context)
        if (isSafari()) {
            this.videoElement.style.objectFit = "fill"
        }
    }

    // eslint-disable-next-line complexity
    public forceStream(llhls: boolean): void {
        if (llhls !== this.enableLLHLS) {
            if (this.disableToggle()) {
                return
            }
            saveLLHLSSetting(llhls)
            const edge = this.currentSrc.match(/^https:\/\/edge(.+)\.live/)
            addPageAction("StreamTypeChange", {
                "source": this.currentSrc,
                "streamType": llhls ? "llhls" : "hls",
                "edge": edge !== null ? edge[1] : "",
                "playerType": this.playerType,
            })
            if (convivaEnabled()) {
                trackCustomEvent({
                    name: "StreamTypeChange",
                    data: {
                        "roomName": this.room,
                        "source": this.currentSrc,
                        "streamType": llhls ? "llhls" : "hls",
                        "edge": edge !== null ? edge[1] : "",
                        "playerType": this.playerType,
                    },
                })
            }
            const llhlsOverride = parseQueryString(window.location.search)["force-llhls"]
            this.setEnableLLHLS(llhls || llhlsOverride !== undefined)
            // If user toggles LLHLS on recreate the player with a new source
            if (this.enableLLHLS) {
                // If user is toggling LLHLS on, we want to allow LLHLS in the recreated player
                setFallback(false)
            }
            this.handleConviva()
            this.endSession()
            this.videoMetrics.endSession()
            // Can only manually enable or disable if already on LLHLS edge, so refresh player on same edge
            this.refreshStreamOnSameEdge(this.context)
            if (isSafari()) {
                this.videoElement.style.objectFit = "fill"
            }
        }
    }

    private getTrueURL(url: string): string {
        // the hlsSource url we get from the backend may not know to use LLHLS
        // so, if LLHLS is enabled, and we haven't fallen back from LLHLS,
        // add the LLHLS-specific suffix to the url
        // step 1: add or remove suffix
        url = this.replaceSuffix(url)
        // step 2: if llhls edge, replace llhls type depending on settings and player type
        // disable for passworded, private, and hidden rooms
        if (this.allowLLHLS) {
            const regex = /live-.+amlst/
            let replace = ""
            // if LLHLS is disallowed based on player/device type, or if the saved setting is HLS, or if we've fallen back from LLHLS
            // in the last hour, then we want to use the "fake" HLS cmaf urls
            if (!this.shouldDisallowLLHLS() && getLLHLSSetting() !== "hls" && !getFallback()) {
                this.setEnableLLHLS(true)
                if (pageContext.current.loggedInUser !== undefined) {
                    replace = "live-llhls"
                } else {
                    replace = "live-c-llhls"
                }
            } else {
                this.setEnableLLHLS(false)
                if (pageContext.current.loggedInUser !== undefined) {
                    replace = "live-fhls"
                } else {
                    replace = "live-c-fhls"
                }
            }
            url = url.replace(regex, `${replace}/amlst`)
        }
        return url
    }

    private replaceSuffix(url: string): string {
        if (this.allowLLHLS && url.includes("playlist.m3u8")) {
            url = url.replace("playlist.m3u8", "playlist_sfm4s.m3u8")
        } else if (url.includes("playlist_sfm4s.m3u8")) {
            url = url.replace("playlist_sfm4s.m3u8", "playlist.m3u8")
        }
        return url
    }

    private setEdgeLLHLS(url: string): void {
        if (url.includes("mmcdn") && parseQueryString(window.location.search)["force-llhls"] === undefined) {
            // we want a consistent number of edges to always use LLHLS
            const regex = /^https:\/\/edge(.+)\.live/
            const edge = url.match(regex)
            const specifiedEdge = parseQueryString(window.location.search)["llhls-capable-edge"]
            if (specifiedEdge !== undefined && edge !== null && edge[0].includes(specifiedEdge)) {
                this.allowLLHLS = true
                return
            }
            if (edge !== null && edge[1] !== undefined) {
                const edgeNumber = edge[1].split("-")[0]
                if (Number(edgeNumber) === LLHLSEDGE) {
                    this.allowLLHLS = true
                    return
                }
            }
            // Not an LLHLS edge, disable LLHLS
            this.allowLLHLS = false
            this.setEnableLLHLS(false)
        } else {
            this.allowLLHLS = true
        }
    }

    private fullyParseURL(url: string): string {
        // modifies edge to specified query param
        let parsedUrl = this.parseHlsUrl(url)
        // check if LLHLS is forced
        this.isLLHLSForced()
        if (LLHLSSupported()) {
            // checks if edge is LLHLS specfic
            this.setEdgeLLHLS(parsedUrl)
            // adds or removes LLHLS suffix as needed
            parsedUrl = this.getTrueURL(parsedUrl)
        }
        this.updateStreamToggle()
        if (parsedUrl) {
            this.currentSrc = parsedUrl
        }
        this.videoMetrics.sendStreamType(this.enableLLHLS ? "llhls" : "hls", this.currentSrc)
        return parsedUrl
    }

    protected shouldDisallowLLHLS(): boolean {
        // LLHLS should be not be allowed if the player is native, is embed, or mobile and LLHLS is not forced
        return parseQueryString(window.location.search)["force-llhls"] === undefined &&
            ((this.playerType === "Native" && !featureFlagIsActive("VDPEnblNativeLLHLS")) ||
            videoModeHandler.getVideoMode() === VideoMode.VideoOnly ||
            (isMobileDevice() && !featureFlagIsActive("VDPEnblMobileLLHLS")))
    }

    private isLLHLSForced(): void {
        if (parseQueryString(window.location.search)["force-llhls"] !== undefined) {
            this.allowLLHLS = true
            this.setEnableLLHLS(true)
        }
    }

    protected disableToggle(): boolean {
        return [RoomStatus.Hidden, RoomStatus.PrivateNotWatching, RoomStatus.NotConnected, RoomStatus.Offline, RoomStatus.Away].includes(this.roomStatus)
    }

    public getSource(): string {
        return this.currentSrc
    }

    public setSource(source: string): void {
        this.currentSrc = source
    }

    protected sendUnloadEvent(): void {
        // checking for startTimesSent to ensure playback has started
        if (!this.unloadSent && this.startTimesSent) {
            this.sendQuality(true)
            this.unloadSent = true
        }
    }

    protected maybeSendUnloadEvent(event: PageTransitionEvent | PopStateEvent): void {
        if (event !== undefined && event !== null) {
            // likely history navigation load, end session
            const isPopStateRoomNavigation = (
                event.type === "popstate" &&
                (((event as PopStateEvent).state !== undefined &&
                (event as PopStateEvent).state !== null &&
                (event as PopStateEvent).state["type"] === "room") ||
                UrlState.current.state.pageType === PageType.ROOM)
            )

            const isPersistedPageShow = event.type === "pageshow" && (event as PageTransitionEvent).persisted

            if (isPopStateRoomNavigation || isPersistedPageShow) {
                this.endSession()
            }
        }
    }

    protected cleanupEventListeners(): void {
        removeEventListenerPoly("beforeunload", window, this.stopConviva.bind(this))
        removeEventListenerPoly("pagehide", window, this.sendUnloadEvent.bind(this))
        removeEventListenerPoly("beforeunload", window, this.sendUnloadEvent.bind(this))
        removeEventListenerPoly("pageshow", window, this.maybeSendUnloadEvent.bind(this))
        removeEventListenerPoly("popstate", window, this.maybeSendUnloadEvent.bind(this))
    }

    protected updateStreamToggle(): void {
        this.updateLLHLSButton.fire({ allowed: (this.allowLLHLS && !this.shouldDisallowLLHLS() && !this.disableToggle()), enabled: this.enableLLHLS })
    }

    protected resetRetryCounter(): void {
    }

    protected endSession(): void {
        this.stopQuality()
        this.sendUnloadEvent()
        this.videoMetrics.endSession()
        this.resetRetryCounter()
        this.sessionDropped = 0
        this.sessionFrames = 0
        this.minutesSincePlay = 0
        this.startTimesSent = false
        this.unloadSent = false
        this.lastFrames = 0
        this.lastDropped = 0
        this.hiddenDropped = 0
        this.hiddenTotal = 0
        this.apiFailures = 0
        this.loading = Date.now()
    }

    private shouldPreventPlay(): boolean {
        return isMobileDevice() && document.hidden && !this.isCasting
    }

    // hls player doesn't need to know its container element
    public setContainerElement(container: HTMLElement): void {
        return
    }

    protected setAutoplay(autoplay: boolean): void {
        if (autoplay) {
            this.videoElement.setAttribute("autoplay", "autoplay") // eslint-disable-line @multimediallc/no-set-attribute
        } else {
            this.videoElement.removeAttribute("autoplay")
        }
    }
}
