import { getForm } from "../formUtils"
import { bindRecaptchaValidator } from "./recaptcha"

export function initialize(): void {
    for (const formElement of document.querySelectorAll<HTMLFormElement>("form[data-formvalidate]")) {
        const form = getForm(formElement)

        const captchaEl = formElement.querySelector<HTMLInputElement>("[name='prove_you_are_human']")
        if (captchaEl !== null) {
            bindRecaptchaValidator(captchaEl, form)
        }
    }
}
