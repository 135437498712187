import { isiOS } from "@multimediallc/web-utils/modernizr"
import { normalizeResource } from "../../../common/api"
import { Component } from "../../../common/defui/component"
import { EventRouter } from "../../../common/events"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { safeWindowOpenWithReturn } from "../../../common/windowUtils"
import { pageContext } from "../../interfaces/context"
import { ConversationList } from "./conversationList"
import { ConversationListData } from "./conversationListData"
import { PopoutDmWindow } from "./dmWindow"
import { createDmWindowRequest, removeDmWindowRequest } from "./dmWindowsManager"
import { bindDmWindowsPushEvents } from "./dmWindowUtils"
import { directMessage } from "./userActionEvents"
import type { IPushPrivateMessage } from "../../../common/messageInterfaces"

const changeConvoFuncKey = "changePopoutConvo"
const dmPopoutWindowName = "dmpopout"

declare global {
    interface Window {
        [changeConvoFuncKey]?: (username?: string) => void
    }
}

export class DmPopout {
    public static showingEvent = new EventRouter<void>("DmPopoutShowingEvent")
    private static popout?: Window

    public static show(username?: string): void {
        DmPopout.showingEvent.fire()
        if (this.popout !== undefined && !this.popout.closed) {
            if (isiOS()) {
                // You can't focus to a different tab on ios, it does nothing rather than let you affect current browser tab
                this.popout.close()
            } else if (this.popout[changeConvoFuncKey] !== undefined) {
                this.popout.focus()

                // The browser may decide not to let the above focus() work. If the current document still has focus
                // rather than the popout, then start a new popout rather than use changeConvoFuncKey
                window.setTimeout(() => {
                    if (document.hasFocus()) {
                        DmPopout.openNewPopoutWindow(username)
                    } else if (this.popout !== undefined && this.popout[changeConvoFuncKey] !== undefined) {
                        this.popout[changeConvoFuncKey](username)
                    }
                }, 0)
                return
            }
        }
        DmPopout.openNewPopoutWindow(username)
    }

    private static openNewPopoutWindow(username?: string): void {
        let url = "/dm/"
        if (username !== undefined) {
            url += `${username}/`
        }

        const height = 768
        const width = 850
        const left = (screen.width / 2 - width / 2).toString()
        const top = (screen.height / 2 - height / 2).toString()
        const windowOptions = `resizable,dependent,scrollbars,height=${height},width=${width},top=${top},left=${left}`
        safeWindowOpenWithReturn(url, dmPopoutWindowName, windowOptions).then((handle) => {
            this.popout = handle
        }).catch(() => {})
    }
}

type DmPopoutProps = { username?: string }
export class DmPopoutContent extends Component<HTMLDivElement, DmPopoutProps>{
    private dmList: ConversationList
    private dmWindowsMap: Map<string, PopoutDmWindow>
    private currentDmWindow?: PopoutDmWindow
    private dmWindowContainer: HTMLDivElement
    private numUnreadDOM: HTMLSpanElement

    constructor(props: DmPopoutProps) {
        super("div", props)

        this.bindListeners()

        window[changeConvoFuncKey] = (username?: string) => {
            if (username !== undefined) {
                this.showConversation(username)
            }
        }

        if (props.username !== undefined && props.username !== pageContext.current.loggedInUser?.username) {
            // use createDMWindowRequest bc it generates a placeholder dmList item if needed
            createDmWindowRequest.fire(props.username)
        }
    }

    protected initData(props: DmPopoutProps): void {
        super.initData(props)

        this.dmList = new ConversationList({
            isDms: true,
            clearSearchOnSelect: false,
            isFullVideoMode: false,
            openConversationEvent: createDmWindowRequest,
        })
        this.dmWindowsMap = new Map()
    }

    protected initUI(props: DmPopoutProps): void {
        super.initUI(props)

        const containerStyle: CSSX.Properties = {
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
        }
        const dmListContainerStyle: CSSX.Properties = {
            width: "300px",
            display: "inline-flex",
            flexDirection: "column",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            flexShrink: 0,
        }
        const headerStyle: CSSX.Properties = {
            paddingLeft: "8px",
            height: "34px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderBottomWidth: "2px",
            borderBottomStyle: "solid",
            fontFamily: "UbuntuRegular, Tahoma, Arial, Helvetica, sans-serif",
            fontSize: "14px",
        }
        const numUnreadStyle: CSSX.Properties = {
            marginLeft: "4px",
        }
        const dmWindowContainerStyle: CSSX.Properties = {
            width: "calc(100vw - 301px)",
            display: "inline-block",
            flexGrow: 1,
        }

        this.element = <div style={containerStyle} colorClass="DmPopout" className="fill-view-height">
            <div style={dmListContainerStyle} colorClass="dmListContainer" data-testid="dm-convo-list">
                <div style={headerStyle} colorClass="header">
                    <span>
                        {i18n.directMessageLabel}
                        <span style={numUnreadStyle} ref={(el: HTMLSpanElement) => this.numUnreadDOM = el} data-testid="unread-conversation-count"/>
                    </span>
                </div>
                {this.dmList.element}
            </div>
            <div style={dmWindowContainerStyle} ref={(el: HTMLDivElement) => this.dmWindowContainer = el}/>
        </div>
    }

    private bindListeners(): void {
        directMessage.listen((dmData: IPushPrivateMessage) => {
            this.dmWindowsMap.get(dmData.otherUsername)?.handleNewMessage(dmData)
        })

        createDmWindowRequest.listen((username) => {
            this.showConversation(username)
        })

        removeDmWindowRequest.listen(({ username, deleteWindow }) => {
            if (this.currentDmWindow?.username === username) {
                this.hideCurrentDmWindow()
            }
            if (deleteWindow === true) {
                this.dmWindowsMap.delete(username)
            }
        })

        ConversationListData.conversationRead.listen(({ username, isDm }) => {
            if (isDm) {
                const receivingWindow = this.dmWindowsMap.get(username)
                receivingWindow?.markRead()
            }
        })

        ConversationListData.unreadConversationsCountUpdate.listen(({ dmsCount }) => {
            this.updateNumUnread(dmsCount)
        })

        bindDmWindowsPushEvents(username => this.dmWindowsMap.get(username))
    }

    public showConversation(username: string): void {
        let dmWindow = this.dmWindowsMap.get(username)
        if (dmWindow === undefined) {
            dmWindow = new PopoutDmWindow(username)
            this.dmWindowsMap.set(username, dmWindow)
        } else if (dmWindow === this.currentDmWindow) {
            dmWindow.scrollAndFocus()
            return
        }

        this.hideCurrentDmWindow()

        this.dmWindowContainer.appendChild(dmWindow.element)
        dmWindow.setIsShowing(true)
        dmWindow.openOrCollapseWindow(true, true)
        this.dmList.setCurrentConversation(username)
        this.currentDmWindow = dmWindow

        document.title = i18n.directMessagesPageTitle(pageContext.current.loggedInUser?.username ?? "", username)
        window.history.pushState("","", normalizeResource(`/dm/${username}`))
    }

    public hideCurrentDmWindow(): void {
        this.currentDmWindow?.removeFromDOM()
        this.currentDmWindow = undefined
    }

    private updateNumUnread(numUnread: number): void {
        this.numUnreadDOM.textContent = numUnread > 0 ? `(${numUnread})` : ""
    }
}
