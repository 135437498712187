import { addColorClass } from "../../cb/colorClasses"
import { currentSiteSettings } from "../../cb/siteSettings"
import {
    BaseNotificationsModal, createConfirmButton, createDenyButton,
    createDescriptionElement, createTitleElement,
} from "../../cb/ui/notificationsModal"
import { addEventListenerPoly, removeEventListenerPoly } from "../addEventListenerPolyfill"

function createPopupElement(): HTMLElement {
    const popup = document.createElement("div")
    popup.style.position = "relative"
    popup.style.height = ""
    popup.style.width = "264px"
    popup.style.backgroundColor = "#e0e0e0"
    popup.style.padding = "6px 12px"
    popup.style.borderRadius = "3px"
    popup.style.marginLeft = "0px"
    popup.style.marginTop = "11px"
    popup.style.fontSize = "12.012px"
    popup.style.color = currentSiteSettings.defaultColor
    popup.style.overflow = ""
    popup.style.lineHeight = "16.8px"
    return popup
}

export class NotificationsModal extends BaseNotificationsModal {
    protected lazyInitUI(): void {
        // region DOM Creation
        addColorClass(this.element, "notificationsModal")
        this.element.style.width = ""
        this.element.style.height = ""

        this.overlay.style.opacity = "0.5"
        this.overlay.style.background = "#000000"

        const popup = createPopupElement()
        const titleElement = createTitleElement()
        const descriptionElement = createDescriptionElement()
        const confirmButton = createConfirmButton(() => {
            this.onConfirmClick()
            this.close()
        })
        const denyButton = createDenyButton(() => {
            this.onDenyClick()
            this.close()
        })

        // Append DOM elements
        popup.appendChild(titleElement)
        popup.appendChild(descriptionElement)
        popup.appendChild(confirmButton)
        popup.appendChild(denyButton)
        this.element.appendChild(popup)
        // endregion

        // Bind resize handler
        const resizeHandler = () => {
            this.resize()
        }
        addEventListenerPoly("resize", window, resizeHandler)
        this.closed.once(() => {
            try {
                removeEventListenerPoly("resize", window, resizeHandler)
            } catch (e) {
                debug("NotificationsModal resize listener was already removed")
            }
        })
        super.lazyInitUI()
    }

    afterDOMConstructedIncludingChildren(): void {
        super.afterDOMConstructedIncludingChildren()
        this.resize()
    }

    private resize(): void {
        this.element.style.left = `${Math.max(0, (window.innerWidth - this.element.offsetWidth) / 2)}px`
        this.element.style.top = `${Math.max(0, (window.innerHeight - this.element.offsetHeight) / 2)}px`
    }
}
