interface IColorNames {
    [key: string]: string|undefined
}
export interface IRGB {
    red: number,
    green: number,
    blue: number,
}
export interface IHSV {
    hue: number,
    saturation: number,
    value: number,
}

const colornames: IColorNames = {
    "aqua": "#00ffff", "black": "#000000", "blue": "#0000ff", "fuchsia": "#ff00ff",
    "gray": "#808080", "green": "#008000", "lime": "#00ff00", "maroon": "#800000",
    "navy": "#000080", "olive": "#808000", "orange": "#ffa500", "purple": "#800080",
    "red": "#ff0000", "silver": "#c0c0c0", "teal": "#008080", "white": "#ffffff",
    "yellow": "#ffff00",
}

export function parseColorToRGB(color: string, defaultValue: IRGB = { red: 200, green: 200, blue: 200 }): IRGB {
    let c = color.toLowerCase()
    c = attemptToConvertColorNameToHex(c)
    if (isHex(c)) {
        return hexToRGB(c)
    } else if (c.indexOf("rgb") === 0) {
        let cparts = color.match(/\d+(\.\d+)?%?/g)
        if (cparts === null || cparts.length < 3 || cparts.length > 4) {
            return defaultValue
        }
        cparts = [cparts[0], cparts[1], cparts[2]]
        const numCparts: number[] = []
        for (let i = 0; i < 3; i += 1) {
            if (cparts[i].indexOf("%") !== -1) {
                numCparts[i] = Math.round(parseFloat(cparts[i]) * 2.55)
            } else {
                numCparts[i] = Number(cparts[i])
            }
            if (numCparts[i] < 0) {
                numCparts[i] = 0
            } else if (numCparts[i] > 255) {
                numCparts[i] = 255
            }
        }
        return { red: numCparts[0], green: numCparts[1], blue: numCparts[2] }
    } else {
        debug(`Cannot parse color: "${color}"`)
        return defaultValue
    }
}

function attemptToConvertColorNameToHex(color: string): string {
    if ((/^[a-z]+$/.test(color))) {
        const hex = colornames[color]
        if (hex !== undefined) {
            return hex
        }
    }
    return color
}
export function isHex(color: string): boolean {
    return (/^#([a-fA-F0-9]{3}){1,2}$/.test(color))
}
function hexToRGB(color: string): IRGB {
    if (color.length === 4) {
        color = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`
    }
    const cN = Number(`0x${color.substring(1)}`)
    return { red: cN >> 16 & 255, green: cN >> 8 & 255, blue: cN & 255 }
}

export function HSVtoRGB(hue: number, saturation: number, value: number): IRGB {
    const chroma = value * saturation
    const secondaryColorValue = chroma * (1 - Math.abs(((hue / 60) % 2) - 1))
    const m = value - chroma
    const color = Math.round((chroma + m) * 255)
    const secondaryColor = Math.round((secondaryColorValue + m) * 255)
    const tertiaryColor = Math.round(m * 255)
    if (hue <= 60) {
        return {
            red: color,
            green: secondaryColor,
            blue: tertiaryColor,
        }
    } else if (hue <= 120) {
        return {
            red: secondaryColor,
            green: color,
            blue: tertiaryColor,
        }
    } else if (hue <= 180) {
        return {
            red: tertiaryColor,
            green: color,
            blue: secondaryColor,
        }
    } else if (hue < 240) {
        return {
            red: tertiaryColor,
            green: secondaryColor,
            blue: color,
        }
    } else if (hue <= 300) {
        return {
            red: secondaryColor,
            green: tertiaryColor,
            blue: color,
        }
    } else { // hue <= 360
        return {
            red: color,
            green: tertiaryColor,
            blue: secondaryColor,
        }
    }
}

export function hexToHSV(color: string): IHSV {
    const rgb = parseColorToRGB(color)
    const red = rgb.red / 255
    const green = rgb.green / 255
    const blue = rgb.blue / 255
    const maxColor = Math.max(red, green, blue)
    const minColor = Math.min(red, green, blue)
    const delta = maxColor - minColor

    let hue: number
    if (delta === 0) {
        hue = 0
    } else if (maxColor === red) {
        hue = 60 * ((green - blue) / delta)
    } else if (maxColor === green) {
        hue = 60 * (((blue - red) / delta) + 2)
    } else { // maxColor === blue
        hue = 60 * (((red - green) / delta) + 4)
    }
    if (hue < 0) {
        hue += 360
    }

    let saturation: number
    if (maxColor === 0) {
        saturation = 0
    } else {
        saturation = delta / maxColor
    }
    return {
        hue: Math.round(hue),
        saturation: Number(saturation.toFixed(3)),
        value: Number(maxColor.toFixed(3)),
    }
}

export function rgbToHex(rgb: IRGB): string {
    if (rgb.red > 255 || rgb.green > 255 || rgb.blue > 255 || rgb.red < 0 || rgb.green < 0 || rgb.blue < 0) {
        error(`Invalid rgb color value: r: ${rgb.red}, g: ${rgb.green}, b: ${rgb.blue}`)
    }
    return `000000${((rgb.red << 16) | (rgb.green << 8) | rgb.blue).toString(16)}`.slice(-6)
}
