import { ArgJSONMap } from "@multimediallc/web-utils"
import { roomDossierContext } from "../../cb/interfaces/context"
import { parseRoomListSource } from "../../cb/roomList"
import { featureFlagIsActive } from "../featureFlag"
import { RoomNoticeType } from "../messageInterfaces"
import { stringPart } from "./roomnoticeparts"
import type { IShortcode } from "../../cb/interfaces/shortcode"
import type {
    IAppLog,
    IChatMedia,
    IPrivateMessage,
    IRoomMessage,
    IRoomNotice,
    IRoomNoticePart,
    ISettingsUpdateNotification,
    ITipAlert,
    IUserInfo,
    IUserUpdateItem,
} from "../messageInterfaces"

export function parseFromUser(p: ArgJSONMap): IUserInfo {
    return {
        ...parseUserInfo(p),
        gender: p.getStringOrUndefined("gender", false),
    }
}

export function parseUserInfo(p: ArgJSONMap): IUserInfo {
    const usernamePossibilities = p.getStrings(["from_username", "user", "username"])
    if (usernamePossibilities.size !== 1) {
        error(`getStrings(["from_username", "user", "username"]): length is not 1`, {
            data: usernamePossibilities,
            message: p.stringMessage,
        })
    }
    const username = usernamePossibilities.values().next().value
    return {
        username: username,
        isBroadcaster: username === roomDossierContext.getState().room,
        inFanclub: p.getBoolean("in_fanclub"),
        isFollowing: p.getBooleanOrUndefined("is_following"),
        hasTokens: p.getBoolean("has_tokens"),
        isMod: p.getBoolean("is_mod"),
        tippedRecently: p.getBoolean("tipped_recently"),
        tippedALotRecently: p.getBoolean("tipped_alot_recently"),
        tippedTonsRecently: p.getBoolean("tipped_tons_recently"),
        exploringHashTag: p.getString("exploringHashTag", false),
        sourceName: parseRoomListSource(p.getString("source_name", false)),
        gender: p.getStringOrUndefined("gender"),
    }
}

export function parseRoomMessageArgs(args: string[]): IRoomMessage {
    const p = new ArgJSONMap(args[1])
    if (p.getAny("X-Successful") !== true) {
        error("X-Successful not true?")
    }
    const fromUser = args[0]
    const roomMsg = parseWowzaRoomMessage(fromUser, p)
    p.logUnusedDebugging("parseRoomMessageArgs")
    return roomMsg
}

export function parseWowzaRoomMessage(fromUser: string, p: ArgJSONMap): IRoomMessage {
    if (p.getAny("X-Successful") !== true) {
        error("X-Successful not true?")
    }
    const r: IRoomMessage = {
        fromUser: parseFromUser(p),
        message: p.getString("m"),
        font: p.getStringOrUndefined("f", false),
        textColor: p.getStringOrUndefined("c", false),
        messageID: p.getString("i", false),
        backgroundColor: p.getStringOrUndefined("background", false),
        tid: p.getString("tid", false),
    }
    if (r.fromUser.username !== fromUser) {
        error("Parsing username match failed")
    }
    return r
}

export function parsePushRoomMessage(p: ArgJSONMap): IRoomMessage {
    return {
        fromUser: parseFromUser(p.getParsedSubMap("from_user")),
        message: p.getString("message"),
        font: p.getStringOrUndefined("font_family", false),
        textColor: p.getStringOrUndefined("font_color", false),
        messageID: p.getString("id", false),
        backgroundColor: p.getStringOrUndefined("background", false),
        tid: p.getString("tid", false),
    }
}

export function parsePushPrivateMessage(p: ArgJSONMap): IPrivateMessage {
    return {
        fromUser: parseFromUser(p.getParsedSubMap("from_user")),
        message: p.getString("m"),
        textColor: p.getStringOrUndefined("c", false),
        messageID: p.getString("i", false),
        otherUsername: p.getString("other_user"),
        font: p.getString("f"),
        mediaList: parseMediaList(p.getObjectOrUndefined("media")),
        tid: p.getString("tid", false),
        createdAt: new Date(p.getNumber("created_at") * 1000),
        isPrivate: true,
    }
}

export function parseMediaList(list?: object): IChatMedia[] {
    const output: IChatMedia[] = []
    if (list === undefined) {
        return output
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    for (const item of list as Record<string, any>[]) {
        output.push({
            mediaId: item["media_id"],
            thumbnailUrl: item["media_thumbnail_url"],
            url: item["media_url"],
            opened: item["opened"],
            fromUserUID: item["user_uid"],
        })
    }
    return output
}

export function parsePrivateMessageArgs(args: string[]): IPrivateMessage {
    const p = new ArgJSONMap(args[1])
    if (p.getAny("X-Successful") !== true) {
        error("X-Successful not true?")
    }
    const fromUser = args[0]
    const privateMessage = {
        ...parseWowzaRoomMessage(fromUser, p),
        otherUsername: (args[2] !== "") ? args[2] : args[0],
        mediaList: parseMediaList(p.getObjectOrUndefined("media")),
        isPrivate: true as const,  // Type checker gets slightly confused here without the cast
    }
    p.logUnusedDebugging("parsePrivateMessageArgs")
    return privateMessage
}

export function parseTipAlert(p: ArgJSONMap): ITipAlert {
    return {
        fromUser: parseFromUser(p),
        message: p.getString("message"),
        amount: p.getNumber("amount"),
        isAnonymousTip: p.getBoolean("is_anonymous_tip"),
        toUsername: p.getStringOrUndefined("to_username"),
        tid: p.getString("tid"),
    }
}

export function parseIgnoredUser(p: ArgJSONMap): string {
    return p.getString("username")
}

export function parseSettingsUpdate(p: ArgJSONMap): ISettingsUpdateNotification {
    const r = {
        allowPrivateShow: p.getBoolean("allow_privates"),
        privatePrice: p.getNumber("private_price"),
        spyPrice: p.getNumber("spy_price"),
        privateMinMinutes: p.getNumber("private_min_minutes"),
        allowShowRecordings: p.getBoolean("allow_show_recordings"),
        activePassword: p.getBoolean("active_password"),
        tid: p.getString("tid"),
    } as ISettingsUpdateNotification
    p.logUnusedDebugging("parseSettingsUpdate")
    return r
}

export function parseRoomNotice(data: ArgJSONMap): IRoomNotice {
    const msgUncast = data.getAny("messages")
    const messages: IRoomNoticePart[][] = []
    if (typeof msgUncast === "string") {
        messages.push([stringPart(`Notice: ${msgUncast}`)])
    } else if (msgUncast instanceof Array) {
        for (const msg of msgUncast as string[]) {
            messages.push([stringPart(`Notice: ${msg}`)])
        }
    } else {
        error(`handleAppNotice error: Invalid message type`, {
            data: msgUncast,
        })
    }

    function getNoticeColors() {
        const darkModeEnabled = document.body.classList.contains("darkmode")
        const useDarkModeOpts = featureFlagIsActive("SendNoticeDarkModeOpts") && darkModeEnabled
        let foreground = data.getStringOrUndefined("foreground")
        let background = data.getStringOrUndefined("background")
        if (useDarkModeOpts) {
            const darkmode_foreground = data.getStringOrUndefined("darkmode_foreground")
            const darkmode_background = data.getStringOrUndefined("darkmode_background")
            if (darkmode_foreground !== undefined && darkmode_foreground !== "") {
                foreground = darkmode_foreground
            }
            if (darkmode_background !== undefined && darkmode_background !== "") {
                background = darkmode_background
            }
        }
        return { foreground, background }
    }

    const { foreground, background } = getNoticeColors()

    const weight = data.getStringOrUndefined("weight")
    const shortcodes = data.getList("shortcodes") ?? []
    return {
        foreground,
        background,
        weight: (weight === "") ? undefined : weight, // Wowza sends an empty string as undefined
        messages: messages,
        showInPrivateMessage: data.getBoolean("showInPrivateMessage", false, false),
        shortcodes: parseShortcodes(shortcodes),
        noticeType: RoomNoticeType.App,
        tid: data.getString("tid"),
    } as IRoomNotice
}

export function parseAppLog(data: ArgJSONMap): IAppLog {
    const logType = data.getString("type")
    let messages: IRoomNoticePart[][] | undefined
    let logMessage: string | undefined
    if (logType === "log") {
        logMessage = data.getString("message")
    } else if (logType === "apperrorlog") {
        const msgUncast = data.getAny("message")
        messages = []
        messages.push([stringPart("App Error: ")])
        if (typeof msgUncast === "string") {
            messages.push([stringPart(msgUncast)])
        } else if (msgUncast instanceof Array) {
            for (const msg of msgUncast as string[]) {
                messages.push([stringPart(msg)])
            }
        } else {
            error(`parseAppLog error: Invalid message type`, {
                data: msgUncast,
            })
        }
    } else {
        error(`parseAppLog error: Unknown app log type: ${logType}`)
    }
    return {
        type: logType,
        errorMessages: messages,
        debugMessage: logMessage,
        tid: data.getString("tid"),
    }
}

export function parseNewsUpdateItem(data: ArgJSONMap): IUserUpdateItem {
    return {
        text: data.getString("text"),
        notificationType: data.getString("notification_type"),
        url: data.getString("url"),
        timestamp: data.getNumber("timestamp"),
        timeSince: data.getString("time_since"),
        seen: data.getBoolean("seen", false, false),
    }
}

export function parseShortcodes(list: ArgJSONMap[]): IShortcode[] {
    const output: IShortcode[] = []
    for (const item of list) {
        output.push({
            code: item.getString("code") as IShortcode["code"],
            msg: item.getStringOrUndefined("msg") ?? "",
            amt: item.getNumberOrUndefined("amt") ?? 0,
            signupLink: item.getStringOrUndefined("signup_link") ?? "",
        })
    }
    return output
}
