import { addColorClass } from "../cb/colorClasses"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { BroadcastUserContextMenu } from "./broadcastlib/broadcastUserContextMenu"
import { ChatBadgeManager } from "./chatBadgeManager"
import { Component } from "./defui/component"
import { IRoomMessage, IUserInfo } from "./messageInterfaces"
import { addPageAction } from "./newrelic"
import { getGenderIconUrl, getUsernameColorClass , isCurrentUserRoomOwner } from "./roomUtil"
import { i18n } from "./translation"
import {
    UserContextMenu,
    UserMenuStates,
} from "./userContextMenu"

export class UsernameLabel extends Component {
    private usernameText: HTMLSpanElement
    private badgeSpan: HTMLSpanElement

    constructor(private user: IUserInfo, message?: IRoomMessage, isUserList = false) {
        super()
        addColorClass(this.element, getUsernameColorClass(user))
        this.element.style.height = "auto"
        this.element.style.width = "auto"
        this.element.style.position = ""
        this.element.style.display = "inline-block"
        this.element.style.maxWidth = "100%"
        this.element.style.textOverflow = "ellipsis"
        this.element.style.whiteSpace = "nowrap"
        this.element.style.fontWeight = "bold"
        this.element.style.cursor = "pointer"
        this.element.style.overflow = "visible"
        this.element.dataset.testid = "username-label"

        this.badgeSpan = ChatBadgeManager.createBadges(user.username)
        this.element.appendChild(this.badgeSpan)

        this.usernameText = document.createElement("span")
        this.usernameText.dataset.testid = "username"
        this.usernameText.textContent = user.username
        this.usernameText.title = i18n.usernameText
        this.element.appendChild(this.usernameText)

        if (isUserList) {
            const genderImg = document.createElement("img")
            genderImg.style.display = "inline-block"
            genderImg.style.height = "16px"
            genderImg.style.margin = "0 5px"
            genderImg.style.verticalAlign = "middle"
            genderImg.src = getGenderIconUrl(<string>user.gender)
            genderImg.title = i18n.genderSymbolToIconTitle(<string>user.gender)
            genderImg.dataset.testid = "gender-icon"
            this.element.insertBefore(genderImg, this.element.firstChild)
            this.element.style.whiteSpace = "nowrap"
        }

        this.element.onmouseenter = () => {
            this.element.style.textDecoration = "underline"
        }
        this.element.onmouseleave = () => {
            this.element.style.textDecoration = "none"
        }
        const openContextMenu = (event: MouseEvent) => {
            event.preventDefault()
            if (this.element.parentElement === null) {
                error("Username span has no parent")
                return
            }
            addPageAction("OpenUserContextMenu", { "username": user.username })
            UsernameLabel.createUserContextMenu(user, this.usernameText, message)
        }
        addEventListenerPoly("click", this.element, openContextMenu)
        addEventListenerPoly("contextmenu", this.element, openContextMenu) // right-click
    }

    static createUserContextMenu(user: IUserInfo, clickTargetEle: HTMLElement, message?: IRoomMessage): void {
        if (UserContextMenu.state !== UserMenuStates.Down) {
            return
        }

        if (isCurrentUserRoomOwner()) {
            new BroadcastUserContextMenu(user, clickTargetEle, message)
        } else {
            new UserContextMenu(user, clickTargetEle, message)
        }
    }

    public createMentionLabel(highlight: boolean): HTMLElement {
        this.usernameText.textContent = `@${this.user.username}`
        this.badgeSpan.style.display = "none"
        if (highlight) {
            addColorClass(this.element, "mentionHl")
            this.element.style.padding = "2px 4px"
            this.element.style.borderRadius = "4px"
        }
        return this.element
    }
}
