import { ArgJSONMap } from "@multimediallc/web-utils"
import { addColorClass, colorClass } from "../cb/colorClasses"
import { pageContext, roomDossierContext } from "../cb/interfaces/context"
import { Shortcode } from "../cb/interfaces/shortcode"
import { modalAlert } from "./alerts"
import { getCb } from "./api"
import { isNotLoggedIn } from "./auth"
import { roomLoaded } from "./context"
import { openEmoticonPreview } from "./emoticonPreviewModal"
import { followRoom } from "./follow"
import { FollowButton } from "./mobilelib/followButton"
import { addPageAction } from "./newrelic"
import { ignoreCatch } from "./promiseUtils"
import { ShortcodeParser } from "./specialoutgoingmessages"
import { userChatSettingsUpdate } from "./theatermodelib/userActionEvents"
import { i18n } from "./translation"
import type { EventRouter } from "./events"
import type { IRoomNoticePart, IShortcodeMessage, IShortcodeNotice } from "./messageInterfaces"
import type { ITipRequest } from "./specialoutgoingmessages"
import type { IShortcode } from "../cb/interfaces/shortcode"

export const enum MessagePartType {
    string = 0, // eslint-disable-line id-denylist
    emoticon = 1,
    shortcode = 2,
}

interface IParsePart {
    partType: MessagePartType
}

interface IRenderOptions {
    showEmoticons: boolean,
    ignoredEmoticons: string[],
}

export interface IStringPart extends IParsePart {
    s: string
}

export interface IShortcodePart extends IStringPart {
    code?: Shortcode,
    msg?: string,
    amt?: number,
    signupLink?: string
}

export interface IEmoticon extends IParsePart {
    width: number
    height: number
    imgUrl: string
    name: string
    abuseUrl: string
    thumbUrl?: string
}

const emptyEmoticon: IEmoticon = {
    partType: MessagePartType.emoticon,
    height: 0,
    name: "",
    imgUrl: "",
    width: 0,
    abuseUrl: "",
}

let messageRenderOptions: IRenderOptions | undefined

export function initMessageRenderOptions(): void {
    roomLoaded.listen((context) => {
        messageRenderOptions = {
            showEmoticons: context.dossier.userChatSettings.showEmoticons,
            ignoredEmoticons: context.dossier.ignoredEmoticons,
        }
    })
    fetchMessageRenderOptions().finally(() => {
        userChatSettingsUpdate.listen((userChatSettings) => {
            if (messageRenderOptions !== undefined) {
                messageRenderOptions.showEmoticons = userChatSettings.showEmoticons
            }
        })
    }).catch(ignoreCatch)
}

function fetchMessageRenderOptions(): Promise<void> {
    if (pageContext.current.loggedInUser === undefined) {
        messageRenderOptions = {
            showEmoticons: true,
            ignoredEmoticons: [],
        }
        return Promise.resolve()
    }
    return new Promise((resolve, reject) => {
        getCb("api/ts/chat/message-render-options/")
            .then((xhr) => {
                messageRenderOptions = parseMessageRenderOptions(xhr.responseText)
                resolve()
            }).catch((xhr) => {
                error(xhr)
                reject(xhr)
        })
    })
}

function parseMessageRenderOptions(rawRenderOptions: string): IRenderOptions {
    const parser = new ArgJSONMap(rawRenderOptions)
    return {
        showEmoticons: parser.getBoolean("show_emoticons"),
        ignoredEmoticons: parser.getStringList("ignored_emoticons"),
    }
}

const startToken = "%%%["
const endToken = "]%%%"
const mentionSearchExp = /((^|\s)@\w+)/

export function renderMessage(msg: string): HTMLElement {
    const span = document.createElement("span")
    span.className = "emoticonImage"

    const parts = parsedResult(msg)
    parts.forEach((part) => {
        switch (part.partType) {
            case MessagePartType.shortcode:
            case MessagePartType.string:
                const sPart = (part as IStringPart).s
                if (sPart !== "") {
                    span.appendChild(renderString(sPart))
                }
                break
            case MessagePartType.emoticon:
                const emoticon = part as IEmoticon
                if (emoticon.name !== "") {
                    span.appendChild(renderEmoticon(part as IEmoticon))
                } else {
                    span.appendChild(renderString(""))
                }
                break
        }
    })
    return span
}

function renderShortcodeParts(
    shortcodeMessage: IShortcodeMessage | IShortcodeNotice,
    createShortcodeLink: (
        linkText: string,
        linkUrl: string | undefined,
        callback: () => void,
    ) => HTMLAnchorElement,
    openTipCalloutRequest: EventRouter<ITipRequest>,
    ){
    const span = document.createElement("span")
    span.className = "emoticonImage"
    const parts = parsedResult(shortcodeMessage.message, shortcodeMessage.shortcodes)
    parts.forEach((part) => {
        switch (part.partType) {
            case MessagePartType.string:
                const sPart = (part as IStringPart).s
                if (sPart !== "") {
                    span.appendChild(renderString(sPart))
                }
                break
            case MessagePartType.emoticon:
                const emoticon = part as IEmoticon
                if (emoticon.name !== "") {
                    span.appendChild(renderEmoticon(part as IEmoticon))
                } else {
                    span.appendChild(renderString(""))
                }
                break
            case MessagePartType.shortcode:
                const shortcodePart = part as IShortcodePart
                if (shortcodePart.code !== undefined) {
                    span.appendChild(renderShortcode(shortcodePart, shortcodeMessage, createShortcodeLink, openTipCalloutRequest))
                } else {
                    span.appendChild(renderString(""))
                }
                break
        }
    })
    return span
}

export function renderShortcodeMessage(
    message: IShortcodeMessage,
    createShortcodeLink: (
        linkText: string,
        linkUrl: string | undefined,
        callback: () => void,
    ) => HTMLAnchorElement,
    openTipCalloutRequest: EventRouter<ITipRequest>,
    ): HTMLElement {
    const span = renderShortcodeParts(message, createShortcodeLink, openTipCalloutRequest)
    span.dataset.testid = "shortcodeMessage"
    return span
}

export function renderShortcodeNotice(
    notice: IShortcodeNotice,
    createShortcodeLink: (
        linkText: string,
        linkUrl: string | undefined,
        callback: () => void,
    ) => HTMLAnchorElement,
    openTipCalloutRequest: EventRouter<ITipRequest>,
    ): HTMLElement {
    const span = renderShortcodeParts(notice, createShortcodeLink, openTipCalloutRequest)
    span.dataset.testid = "shortcode-notice"
    return span
}

export function parsedResult(msg: string, shortcodes: IShortcode[] = []): IParsePart[] { // eslint-disable-line complexity
    let buffer: string[] = []
    const parts: IParsePart[] = []
    const hasNoEmoticons: boolean = (msg.indexOf(startToken) < 0)
    if (hasNoEmoticons && !ShortcodeParser.hasShortcodes(msg)) {
        return [makeStringPart(msg)]
    }
    for (let char = 0; char < msg.length; char += 1) {
        if (
            (char + ShortcodeParser.shortcodePrefix.length) < msg.length &&
            ShortcodeParser.isShortcodePrefix(msg.slice(char, char + ShortcodeParser.shortcodePrefix.length))
        ) {
            const shortcodeEndIndex = char + msg.slice(char, msg.length).indexOf(ShortcodeParser.shortcodeSuffix)
            if (shortcodeEndIndex === -1) {
                // prefix false positive
                buffer.push(msg[char])
                continue
            }
            const shortcodeString = msg.slice(
                char,
                shortcodeEndIndex + ShortcodeParser.shortcodeSuffix.length,
            )
            const shortcodeForPart = ShortcodeParser.getShortcodeForPart(shortcodeString, shortcodes)
            if (shortcodeForPart === undefined) {
                // invalid shortcode
                buffer.push(msg[char])
                continue
            }
            if (buffer.length !== 0) {
                parts.push(makeStringPart(buffer.join("")))
            }
            buffer = []
            parts.push(makeShortcodePart(shortcodeString, shortcodeForPart))
            char += shortcodeString.length - 1
        }
        else if (msg[char] === "%") {
            if (msg.slice(char, char + startToken.length) === startToken) {
                const emoticonEndIndex =
                    char + msg.slice(char, msg.length).indexOf(endToken)
                if (
                    msg.slice(
                        emoticonEndIndex,
                        emoticonEndIndex + endToken.length,
                    ) === endToken
                ) {
                    if (buffer.length !== 0) {
                        parts.push(makeStringPart(buffer.join("")))
                    }
                    buffer = []
                    const part = parsePart(
                        msg.slice(char, emoticonEndIndex + endToken.length),
                    )
                    parts.push(part)
                    char = emoticonEndIndex + endToken.length - 1
                }
            } else {
                buffer.push(msg[char])
            }
        }
        else {
            buffer.push(msg[char])
        }
    }
    if (buffer.length !== 0) {
        parts.push(makeStringPart(buffer.join("")))
    }
    return parts
}

function parsePart(part: string): IParsePart {
    const start = part.slice(startToken.length)
    if (start.startsWith("emoticon")) {
        const emoticon = parseEmoticon(part)
        if (emoticon !== undefined) {
            return emoticon
        } else {
            error(`Cannot parse emoticon: ${emoticon}`)
            return emptyEmoticon
        }
    }
    // string matches %%%[ but no actual tokens, just render as string
    return makeStringPart(part)
}

function renderShortcode( // eslint-disable-line complexity
    sc: IShortcodePart,
    scMessage: IShortcodeMessage | IShortcodeNotice,
    createShortcodeLink: (
        linkText: string,
        linkUrl: string | undefined,
        callback: () => void,
    ) => HTMLAnchorElement,
    openTipCalloutRequest: EventRouter<ITipRequest>,
): HTMLElement {
    const { userName, room, hasFanClub } = roomDossierContext.getState()
    let callback, linkText, linkUrl
    switch (sc.code) {
        case Shortcode.Fanclub:
            callback = hasFanClub
                ? () => {}
                : () => {
                      modalAlert(i18n.noFanClub)
                  }
            linkText = i18n.shortcodeFanclubMessage(room)
            linkUrl = sc.signupLink
            break
        case Shortcode.Supporter:
            callback = () => {}
            linkText = i18n.becomeSupporter
            linkUrl = "/supporter/upgrade/"
            break
        case Shortcode.Follow:
            callback = () => {
                void followRoom(room)
                if (pageContext.current.loggedInUser !== undefined) {
                    getCb(`follow/is_following/${room}/`).then((response) => {
                        const data = JSON.parse(response.responseText)
                        const isFollowing: boolean = data["following"]
                        const followButton = new FollowButton()
                        if (!isFollowing && pageContext.current.isMobile){
                            followButton.showBrowserNotification()
                        }
                    },
                    ).catch(ignoreCatch)
                }
            }
            linkText = i18n.shortcodeFollowMessage(room)
            linkUrl = undefined
            break
        case Shortcode.Signup:
            callback = () => {}
            linkText = i18n.joinCB
            linkUrl = sc.signupLink
            break
        case Shortcode.Tip:
            callback = () => {
                if (isNotLoggedIn(i18n.loginToTip)) {
                    return
                }
                openTipCalloutRequest.fire({
                    message: sc.msg,
                    amount: sc.amt,
                })
            }
            linkText = sc.msg !== undefined && sc.msg !== null ? sc.msg : ""
            linkUrl = undefined
            break
        default:
            error(`Unknown shortcode: ${sc.code}`)
            return document.createElement("div")
    }
    const shortcodeLink = createShortcodeLink(linkText, linkUrl, callback)
    shortcodeLink.title = ShortcodeParser.getShortcodeTitle(sc)
    shortcodeLink.dataset.testid = "shortcode-link"
    const isMessage = "fromUser" in scMessage && scMessage.fromUser !== undefined
    const shortCodeAction = {
        shortcode: sc.code,
        from_user: isMessage ? scMessage.fromUser.username : "",
        room: room,
        user_clicked: userName,
        tip: sc.amt !== undefined && sc.code === Shortcode.Tip ? sc.amt : 0,
        message: scMessage.message,
        from_notice: !isMessage,
    }
    shortcodeLink.addEventListener("click", () => {
        addPageAction("ShortcodeLinkClicked", shortCodeAction)
    })
    return shortcodeLink
}

function renderEmoticon(emoticon: IEmoticon): HTMLSpanElement {
    if (shouldRenderEmoticon(emoticon)) {
            const htmlEmoticon = document.createElement("img")
            htmlEmoticon.dataset.testid = "emoticonImg"
            const src = emoticon.thumbUrl
            if (src !== undefined) {
                htmlEmoticon.src = src
            } else {
                htmlEmoticon.src = emoticon.imgUrl
            }

            htmlEmoticon.title = `:${emoticon.name}`
            htmlEmoticon.style.cursor = "pointer"
            htmlEmoticon.onclick = () => {
                const e = {
                    slug: emoticon.name,
                    url: emoticon.imgUrl,
                }
                openEmoticonPreview.fire(e)
            }

            if (pageContext.current.isMobile) {
                emoticon.width = emoticon.width * 0.5
                emoticon.height = emoticon.height * 0.5
            }

            htmlEmoticon.height = emoticon.height
            htmlEmoticon.width = emoticon.width
            htmlEmoticon.style.verticalAlign = "top"
            return htmlEmoticon
    } else {
        const span = document.createElement("span")
        span.dataset.testid = "emoticonText"
        addColorClass(span, colorClass.hrefColor)
        addColorClass(span, "msg-link")
        span.innerText = `:${emoticon.name}`
        span.style.cursor = "pointer"
        span.onclick = () => {
            const e = {
                slug: emoticon.name,
                url: emoticon.imgUrl,
            }
            openEmoticonPreview.fire(e)
        }
        return span
    }
}

function renderString(msg: string): HTMLSpanElement {
    const span = document.createElement("span")
    if (mentionSearchExp.test(msg)) {
        msg.split(mentionSearchExp).forEach((item) => {
            const startsWithSpace = /^\s/.test(item)
            if (item.trim().charAt(0) === "@") {
                const mention = document.createElement("span")
                mention.innerText = item.trimStart()
                mention.className = "username-mention"
                if (startsWithSpace) {
                    span.appendChild(document.createTextNode(" "))
                }
                span.appendChild(mention)
            } else if (item.trim().length > 0) {
                span.appendChild(document.createTextNode(item))
            }
        })
    } else {
        span.innerText = msg
    }

    return span
}

function shouldRenderEmoticon(emoticon: IEmoticon): boolean {
    if (messageRenderOptions === undefined) {
        error("messageRenderOptions accessed before being set")
        return false
    }
    return messageRenderOptions.showEmoticons && !messageRenderOptions.ignoredEmoticons.includes(emoticon.name)
}

function makeStringPart(msg: string): IStringPart {
    return {
        partType: MessagePartType.string,
        s: msg,
    }
}

function makeShortcodePart(sc: string, shortcode: IShortcode): IShortcodePart {
    return {
        partType: MessagePartType.shortcode,
        s: sc,
        code: shortcode.code,
        amt: shortcode.amt,
        msg: shortcode.msg,
        signupLink: shortcode.signupLink,
    }
}

export function renderShortcodePart(
    noticePart: IRoomNoticePart,
    shortcodes: IShortcode[],
    createShortcodeLink: (
        linkText: string,
        linkUrl: string | undefined,
        callback: () => void,
    ) => HTMLAnchorElement,
    openTipCalloutRequest: EventRouter<ITipRequest>,
    ): HTMLSpanElement {
    const msg = noticePart.message
    const shortcodeNotice = {
        message: msg as string ? msg as string : "",
        shortcodes: shortcodes,
    } as IShortcodeNotice
    return renderShortcodeNotice(shortcodeNotice, createShortcodeLink, openTipCalloutRequest)
}

function parseEmoticon(emoticon: string): IEmoticon|undefined {
    emoticon = emoticon.replace("%%%[emoticon ", "").replace("]%%%", "")
    const emoticonParts = emoticon.split(/\|/)
    if (emoticonParts.length === 6) {
        const emoticon: IEmoticon = {
            partType: MessagePartType.emoticon,
            name: emoticonParts[0],
            thumbUrl: emoticonParts[1],
            width: validDimension(emoticonParts[2]) ? Number(emoticonParts[2]) : 80,
            height: validDimension(emoticonParts[3]) ? Number(emoticonParts[3]) : 80,
            imgUrl: emoticonParts[4],
            abuseUrl: emoticonParts[5],
        }
        if (emoticon.thumbUrl !== undefined && validUrl(emoticon.thumbUrl)) {
            return emoticon
        } else {
            return undefined
        }
    } else if (emoticonParts.length === 5) {
        const emoticon: IEmoticon = {
            partType: MessagePartType.emoticon,
            name: emoticonParts[0],
            imgUrl: emoticonParts[1],
            width: validDimension(emoticonParts[2]) ? Number(emoticonParts[2]) : 80,
            height: validDimension(emoticonParts[3]) ? Number(emoticonParts[3]) : 80,
            abuseUrl: emoticonParts[4],
        }
        if (validUrl(emoticon.imgUrl)) {
            return emoticon
        } else {
            return undefined
        }
    } else {
        return undefined
    }
}

function validUrl(str: string): boolean {
    const res = str.match(/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g)
    if (res === null) {
        error("Invalid emoticon URL")
        return false
    } else {
        return true
    }
}

function validDimension(str: string): boolean {
    if (isNaN(parseInt(str, 10))) {
        error("Invalid dimension argument")
        return false
    } else {
        return true
    }
}
