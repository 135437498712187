import { EventRouter } from "../events"
import { mean, standardDeviation } from "../mathUtils"
import { reportBroadcasterBitrate } from "../player/utils"
import { i18n } from "../translation"
import { secondsToStr } from "./timeUtils"
import type { SmoothieChart, TimeSeries } from "smoothie"

export interface IStreamData {
    method: string,
    host?: string,
    region?: string,
    fps?: number,
    status?: string,
    streamType?: string,
    roomPassword?: string,
    streamName?: string,
    transcodeCount?: number,
    bitrate?: number,
    secondsOnline?: number,
    videoFps?: number,
    videoWidth?: number,
    videoHeight?: number,
    videoCodec?: string,
    videoCodecVersion?: string,
    videoCodecString?: string,
    audioCodec?: string,
    audioCodecString?: string,
}

export interface IStreamDataUpdate {
    host: string,
    region: string,
    streamType: string,
    fps: number,
    bitrate: number
    streamTime: string,
    videoWidth: number,
    videoHeight: number,
    videoCodec: string,
    audioCodec: string,
}

export interface IPollingStatusUpdate {
    feedbackHeader: string,
    feedbackMessages: HTMLSpanElement[],
    feedbackType: FeedbackType,
    resolutionTitle: string,
    resolutionType: ResolutionType,
    resolutionText: string,
    bitrateFeedback: FeedbackType,
    fpsFeedback: FeedbackType,
}

export interface IProcessDataUpdate {
    connectedTo: string,
    fps: number,
    bitrate: number,
    timeActive: number,
    videoCodec: string,
    audioCodec: string,
}

export enum ResolutionType {
    obsSD,
    obsHD,
    obsHDPlus,
    obs4k,
}

export enum FeedbackType {
    none,
    bad,
    warning,
    good,
}

type VideoCodecKey = "9" | "7" | "12" | "8"
type AudioCodecKey = "11" | "12" | "2" | "10" | "1" | "9"

export const video_codec_map: Record<VideoCodecKey, string> = {
    "9": "H.263",
    "7": "H.264",
    "12": "H.265",
    "8": "VPX",
}
export const audio_codec_map: Record<AudioCodecKey, string> = {
    "11": "Speex",
    "12": "Opus",
    "2": "MP3",
    "10": "AAC",
    "1": "AC3",
    "9": "Vorbis",
}

const minBitrates = {
    480: 800,
    576: 1200,
    720: 3000,
    1080: 5000,
    1440: 8000,
    2160: 12000,
}
const minBitratesHfr = {
    480: 900,
    576: 3000,
    720: 5000,
    1080: 7500,
    1440: 11000,
    2160: 18000,
}

export const getValue = <T>(value: T | undefined, def: T): T => {
    if (value === undefined || value === null) {
        return def
    }
    return value
}

export class StreamWatcher {
    private started = false
    private closed = false
    private fpsChart: SmoothieChart
    private fpsSeries: TimeSeries
    private bitrateChart: SmoothieChart
    private bitrateSeries: TimeSeries
    private fpsData: number[] = []
    private MAX_FPS_LENGTH = 40
    private bitrateData: number[] = []
    private MAX_BITRATE_LENGTH = 100
    private transcodeCount: number | undefined
    private lastUpdate = 0
    private lastWidth = 0
    private lastHeight = 0
    private lastRatio: number | undefined
    private streamTime = 0
    private infoInterval = 0
    private bitrateReporting: number | undefined
    streamDataUpdate = new EventRouter<IStreamDataUpdate>("streamDataUpdate", {
        listenersWarningThreshold: 1,
    })
    pollingStatusUpdate = new EventRouter<IPollingStatusUpdate>("pollingStatusUpdate", {
        listenersWarningThreshold: 1,
    })
    startPollingEvent = new EventRouter<undefined>("streamWatcherStartPolling", {
        listenersWarningThreshold: 1,
    })
    closeEvent = new EventRouter<undefined>("streamWatcherClose", {
        listenersWarningThreshold: 1,
    })

    public processData(data: IStreamData): void {
        const fps = getValue(data["fps"], 0.0)
        const bitrate = getValue(data["bitrate"], 0.0)
        const region = getValue(data["region"], "")
        const host = getValue(data["host"], "")
        const streamType = getValue(data["streamType"], "obs")
        this.streamTime = getValue(data["secondsOnline"], 0.0)
        this.lastWidth = getValue(data["videoWidth"], 0.0)
        this.lastHeight = getValue(data["videoHeight"], 0.0)
        if (this.lastHeight > 0) {
            this.lastRatio = this.lastWidth / this.lastHeight
        }

        const videoCodec = getValue(data["videoCodecString"], getValue(video_codec_map[getValue(data["videoCodec"], "") as VideoCodecKey], "Unknown"))
        const audioCodec = getValue(data["audioCodecString"], getValue(audio_codec_map[getValue(data["audioCodec"], "") as AudioCodecKey], "Unknown"))
        this.transcodeCount = getValue(data["transcodeCount"], undefined)

        this.fpsData.push(fps)
        this.bitrateData.push(bitrate)
        if (this.fpsData.length > this.MAX_FPS_LENGTH) {
            this.fpsData.shift()
        }
        if (this.bitrateData.length > this.MAX_BITRATE_LENGTH) {
            this.bitrateData.shift()
        }

        this.fpsSeries.append(new Date().getTime(), fps)
        this.bitrateSeries.append(new Date().getTime(), bitrate)

        this.streamDataUpdate.fire({
            host: host,
            region: region,
            streamType: streamType,
            fps: Math.round(fps),
            bitrate: Math.round(bitrate),
            streamTime: secondsToStr(this.streamTime),
            videoWidth: this.lastWidth,
            videoHeight: this.lastHeight,
            videoCodec: videoCodec,
            audioCodec: audioCodec,
        })

        this.lastUpdate = new Date().getTime()
    }

    // eslint-disable-next-line complexity
    private statusMessage(): void {
        const badMessages = []
        const warningMessages = []
        const goodMessages = []
        const basicSpan = (text: string): HTMLSpanElement => {
            const span = document.createElement("span")
            span.innerText = text
            return span
        }
        const htmlSpan = (htmlText: string): HTMLSpanElement => {
            const span = document.createElement("span")
            span.innerHTML = htmlText // eslint-disable-line @multimediallc/no-inner-html
            return span
        }
        let okWarnings = 0
        let minBitrate: number | undefined
        let minBitrateHfr: number | undefined
        let isHfr = false
        let bitrateFeedback = FeedbackType.none
        let fpsFeedback = FeedbackType.none
        if (this.lastHeight > 0) {
            for (const key in minBitrates) {
                if (minBitrates.hasOwnProperty(key)) {
                    if (this.lastHeight >= Number(key)) {
                        // @ts-ignore has runtime check
                        minBitrate = minBitrates[key]
                        // @ts-ignore has runtime check
                        minBitrateHfr = minBitratesHfr[key]
                    }
                }
            }
            if (this.lastHeight < 480 && this.streamTime < 120 && this.streamTime > 2) {
                warningMessages.push(basicSpan(i18n.lowStreamResolution))
            } else if (this.lastHeight < 720 && this.streamTime > 2) {
                goodMessages.push(htmlSpan(i18n.streamNotHD))
            }
            if (this.lastRatio !== undefined && this.lastRatio < 1.70 && this.streamTime < 120 && this.streamTime > 2) {
                warningMessages.push(basicSpan(i18n.streamNotWidescreen))
                okWarnings += 1
            }
        }

        if (this.transcodeCount !== undefined) {
            if (this.streamTime > 10 && this.transcodeCount < 3) {
                badMessages.push(basicSpan(i18n.errorHandlingStream))
            }
        }
        let devFps = 0
        let avgFps = 30
        let avgFpsRecent = 30
        if (this.fpsData.length > 0) {
            devFps = standardDeviation(this.fpsData.slice(-100))
            avgFps = mean(this.fpsData.slice(-10))
            avgFpsRecent = mean(this.fpsData.slice(-5))
        }

        let lowBitrate = minBitrate
        if (avgFpsRecent > 45 && avgFps > 45) {
            isHfr = true
            lowBitrate = minBitrateHfr
        }

        if (lowBitrate !== undefined && this.streamTime > 5) {
            const devBitrate = standardDeviation(this.bitrateData.slice(-100))
            const avgBitrate = mean(this.bitrateData.slice(-10))
            const avgBitrateRecent = mean(this.bitrateData.slice(-5))
            if (avgBitrate > 0.01 && avgBitrate < lowBitrate * 0.95 && avgBitrateRecent < lowBitrate * 0.95) {
                if (avgBitrate < lowBitrate * 0.7 && avgBitrateRecent < lowBitrate * 0.7) {
                    if (isHfr) {
                        badMessages.push(basicSpan(i18n.bitrateMuchLowerThanHigh(lowBitrate)))
                    } else {
                        badMessages.push(basicSpan(i18n.bitrateMuchLower(lowBitrate)))
                    }
                } else {
                    if (isHfr) {
                        warningMessages.push(basicSpan(i18n.bitrateLowerThanHigh(lowBitrate)))
                    } else {
                        warningMessages.push(basicSpan(i18n.bitrateLower(lowBitrate)))
                    }
                }
            } else if (devBitrate > avgBitrate / 8 && this.streamTime > 50 && this.bitrateData.length > 20) {
                okWarnings += 1
                warningMessages.push(basicSpan(i18n.unstableBitrate))
            } else if (avgBitrate > lowBitrate * 1.5) {
                goodMessages.push(basicSpan(i18n.veryGoodBitrate))
            }

            if (avgBitrateRecent < lowBitrate * 0.7) {
                bitrateFeedback = FeedbackType.bad
            } else if (avgBitrateRecent < lowBitrate * 0.95) {
                bitrateFeedback = FeedbackType.warning
            } else {
                bitrateFeedback = FeedbackType.none
            }
        } else {
            bitrateFeedback = FeedbackType.none
        }
        if (avgFpsRecent > 0.01 && avgFps < 21 && avgFpsRecent < 21 && this.streamTime > 5) {
            if (avgFps < 15 && avgFpsRecent < 15) {
                badMessages.push(basicSpan(i18n.frameRateMuchLower))
            } else {
                warningMessages.push(basicSpan(i18n.frameRateLower))
            }
        } else if (avgFpsRecent > 45 && avgFps > 45) {
            if (this.streamTime < 120 && this.streamTime > 2) {
                goodMessages.push(basicSpan(i18n.frameRateHigh))
            }
        } else if (avgFpsRecent > 62 && avgFps > 62) {
            badMessages.push(basicSpan(i18n.frameRateTooHigh))
        } else if (devFps > 6 && this.streamTime > 30 && this.fpsData.length > 20) {
            okWarnings += 1
            warningMessages.push(basicSpan(i18n.fpsVeryUnstable))
        } else if (devFps > 3 && this.streamTime > 30 && this.fpsData.length > 20) {
            okWarnings += 1
            warningMessages.push(basicSpan(i18n.fpsUnstable))
        }
        if (this.streamTime > 5 && this.fpsData.length > 20) {
            if (avgFpsRecent < 15) {
                fpsFeedback = FeedbackType.bad
            } else if (avgFpsRecent > 63) {
                fpsFeedback = FeedbackType.warning
            } else if (avgFpsRecent > 33 && avgFpsRecent < 45) {
                fpsFeedback = FeedbackType.warning
            } else if (avgFpsRecent < 21) {
                fpsFeedback = FeedbackType.warning
            } else {
                fpsFeedback = FeedbackType.none
            }
        }

        if (this.lastUpdate > 0 && (new Date().getTime() - this.lastUpdate) > 10 * 1000) {
            warningMessages.push(basicSpan(i18n.noQualityInformation))
        }

        let feedbackType = FeedbackType.none
        let messageHeader = ""
        let messages: HTMLSpanElement[] = []
        if (badMessages.length > 0) {
            messageHeader = i18n.alert
            messages = badMessages.slice(0, 2)
            feedbackType = FeedbackType.bad
        } else if (warningMessages.length > 0) {
            messageHeader = i18n.warning
            messages = warningMessages.slice(0, 2)
            feedbackType = FeedbackType.warning
        } else if (goodMessages.length > 0 || (this.streamTime > 10)) {
            messageHeader = i18n.yourStreamIsGood
            if (goodMessages.length > 0) {
                messages = goodMessages.slice(0, 2)
            }
            else {
                messages = [basicSpan(i18n.noIssues)]
            }
            feedbackType = FeedbackType.good
        } else {
            feedbackType = FeedbackType.none
        }

        let resClass: ResolutionType
        let resText = ""
        let resTitle = ""
        const warnings = warningMessages.length - okWarnings
        if (this.lastHeight >= 2160 && warnings < 1 && badMessages.length === 0) {
            resClass = ResolutionType.obs4k
            resText = "4K"
            resTitle = "4K"
        } else if (this.lastHeight >= 1080 && warnings < 1 && badMessages.length === 0) {
            resClass = ResolutionType.obsHDPlus
            resText = "HD+"
            resTitle = "Full High Definition"
        } else if (this.lastHeight >= 720 && warnings < 1 && badMessages.length === 0) {
            resClass = ResolutionType.obsHD
            resText = "HD"
            resTitle = "High Definition"
        } else {
            resClass = ResolutionType.obsSD
            resText = "SD"
            resTitle = "Standard Definition"
        }

        this.pollingStatusUpdate.fire({
            feedbackHeader: messageHeader,
            feedbackMessages: messages,
            feedbackType: feedbackType,
            resolutionTitle: resTitle,
            resolutionType: resClass,
            resolutionText: resText,
            bitrateFeedback: bitrateFeedback,
            fpsFeedback: fpsFeedback,
        })
    }

    private startPolling(): void {
        this.startPollingEvent.fire(undefined)
        if (this.started) {
            return
        }
        this.started = true
        const chartOptions = {
            "millisPerPixel": 90,
            "maxValueScale": 1.3,
            "grid": {
                "fillStyle": "rgba(209, 209, 209, 0.69)",
                "strokeStyle": "rgba(141, 142, 126, 0.82)",
                "sharpLines": true,
                "borderVisible": true,
                "millisPerLine": 6000,
                "verticalSections": 2,
            },
            "labels": { "fillStyle": "rgba(35, 34, 34, 0.79)", "fontSize": 10, "precision": 0 },
            "minValue": 0,
            "responsive": true,
        }
        void import("smoothie").then(({ SmoothieChart, TimeSeries }) => {
            this.fpsChart = new SmoothieChart(chartOptions)
            this.fpsSeries = new TimeSeries()
            this.fpsChart.addTimeSeries(this.fpsSeries, { lineWidth: 1.2, strokeStyle: "#033E58" })
            // @ts-ignore element should always exist
            this.fpsChart.streamTo(document.getElementById("fps_chart"), 2000)

            this.bitrateChart = new SmoothieChart(chartOptions)
            this.bitrateSeries = new TimeSeries()
            this.bitrateChart.addTimeSeries(this.bitrateSeries, { lineWidth: 1.2, strokeStyle: "#033E58" })
            // @ts-ignore element should always exist
            this.bitrateChart.streamTo(document.getElementById("bitrate_chart"), 2000)
        })

        this.infoInterval = window.setInterval(() => {
            this.statusMessage()
        }, 1500)
    }

    public isStarted(): boolean {
        return this.started
    }

    public start(): void {
        if (this.started) {
            return
        }
        this.closed = false
        this.startPolling()
        clearInterval(this.bitrateReporting)
        this.bitrateReporting = window.setInterval(() => {
            reportBroadcasterBitrate("obs", this.bitrateData[this.bitrateData.length - 1])
        }, 60000)
    }

    public close(): void {
        if (this.closed || !this.started) {
            return
        }
        this.closed = true
        this.started = false
        this.closeEvent.fire(undefined)
        this.fpsChart.stop()
        this.bitrateChart.stop()
        clearInterval(this.infoInterval)
        clearInterval(this.bitrateReporting)
    }

    public reset(): void {
        if (this.isStarted()) {
            this.close()
        }
        this.started = false
        this.closed = false
        this.fpsData = []
        this.bitrateData = []
        this.lastUpdate = 0
        this.transcodeCount = 0
        this.lastWidth = 0
        this.lastHeight = 0
        this.lastRatio = undefined
        this.streamTime = 0
        this.infoInterval = 0

        this.start()
    }
}
