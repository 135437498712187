import { getIgnoredSet } from "../../cb/api/ignore"
import { pageContext } from "../../cb/interfaces/context"
import { roomLoaded } from "../context"
import { applyStyles } from "../DOMutils"
import { OptimisticUpdate } from "../optimisticUpdate"
import { styleTransition } from "../safeStyle"
import { i18n } from "../translation"
import { MenuComponent } from "./menuComponent"
import { iconSize, maxSettingsWidth, MobileChatSettingsStyles } from "./mobileChatSettingsStyles"
import { getViewportWidth } from "./viewportDimension"
import type { IChatConnection } from "../context"

export class ManageIgnoredUsers extends MenuComponent {
    private ignoredUsersContainer = document.createElement("div") // includes the header and the user list
    private ignoredUsersList = document.createElement("div") // just the user list
    private chatConnection: IChatConnection | undefined

    constructor() {
        super(Math.min(getViewportWidth() - 50, maxSettingsWidth), undefined, true, i18n.backToChatSettings)

        this.element.appendChild(this.ignoredUsersContainer)
        this.ignoredUsersContainer.appendChild(this.ignoredUsersList)
        applyStyles(this.ignoredUsersContainer, { height: "calc(100% - 45px)", overflowY: "scroll" })
        MobileChatSettingsStyles.styleContainer(this.ignoredUsersContainer)

        roomLoaded.listen((context) => {
            this.chatConnection = context.chatConnection
            this.createIgnoredUsersList()
        })
    }

    public show(): void {
        super.show()
        this.createIgnoredUsersList()
    }

    private createIgnoredUsersList(): void {
        this.clearIgnoredUsersList()
        if (pageContext.current.loggedInUser === undefined) {
            return
        }

        if (this.chatConnection === undefined) {
            this.ignoredUsersList.appendChild(this.createSpecialMessage(i18n.loadingText))
        } else {
            const ignoredSet = getIgnoredSet(true)
            if (ignoredSet.size === 0) {
                this.ignoredUsersList.appendChild(this.createSpecialMessage(i18n.noOneIgnored))
            } else {
                for (const user of ignoredSet) {
                    this.createIgnoredUserRow(user)
                }
            }
        }
    }

    private createSpecialMessage(text: string): HTMLDivElement {
        const message = document.createElement("div")
        MobileChatSettingsStyles.styleRowWrapper(message)
        message.innerText = text
        message.style.borderBottom = "none"
        return message
    }

    private clearIgnoredUsersList(): void {
        while (this.ignoredUsersList.firstChild !== null) {
            this.ignoredUsersList.removeChild(this.ignoredUsersList.firstChild)
        }
    }

    private createIgnoredUserRow(username: string): void {
        const row = document.createElement("div")
        const label = document.createElement("label")
        const input = document.createElement("img")

        this.ignoredUsersList.appendChild(row)
        row.appendChild(label)
        row.appendChild(input)

        MobileChatSettingsStyles.styleRowWrapper(row)
        MobileChatSettingsStyles.styleLabel(label, username)
        MobileChatSettingsStyles.styleInput(input)

        input.src = `${STATIC_URL_MOBILE}remove.svg`
        input.style.height = `${iconSize}px`

        let isDisabled = false
        const ignoreUpdate = new OptimisticUpdate<boolean>(
            false,
            (isUnignored: boolean, isPending: boolean) => {
                if (isPending) {
                    row.style.opacity = "0.4"
                } else if (isUnignored) {
                    const transitionTime = 100
                    styleTransition(row, `opacity ${transitionTime}ms`)
                    row.style.opacity = "0"
                    window.setTimeout(() => {
                        this.ignoredUsersList.removeChild(row)
                    }, transitionTime)
                }
            },
            () => {
                row.style.opacity = "1.0"
                isDisabled = false
            },
        )
        input.onclick = () => {
            if (this.chatConnection === undefined) {
                return
            }
            if (isDisabled) {
                return
            }
            isDisabled = true
            ignoreUpdate.dispatch(true, this.chatConnection.unignore(username))
        }
    }
}
