import { addColorClass } from "../../cb/colorClasses"
import { ShowMyCamPlayerManager } from "../../cb/components/showMyCam/smcBroadcaster"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert, modalConfirm } from "../alerts"
import { postCb } from "../api"
import { isNotLoggedIn } from "../auth"
import { roomLoaded } from "../context"
import { applyStyles } from "../DOMutils"
import { IRoomMessage, IUserInfo } from "../messageInterfaces"
import { addPageAction } from "../newrelic"
import { i18n } from "../translation"
import { userModeratorStatusChanged } from "../userActionEvents"
import { UserContextMenu } from "../userContextMenu"

export class BroadcastUserContextMenu extends UserContextMenu {
    private promoteModSection: HTMLDivElement
    private hasTippedSection: HTMLDivElement
    constructor(user: IUserInfo, container: HTMLElement, message?: IRoomMessage) {
        super(user, container, message)
    }

    protected addRestMenu(): void {
        super.addRestMenu()
        if (this.currentUsername === this.user.username) {
            return
        }
        this.modSection.style.display = ""
        this.promoteModSection = document.createElement("div")
        addColorClass(this.promoteModSection, "ucmPromoteModSection")
        applyStyles(this.promoteModSection, {
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            padding: "5px 0",
        })
        this.hasTippedSection = document.createElement("div")
        addColorClass(this.hasTippedSection, "ucmHasTippedSection")
        applyStyles(this.hasTippedSection, {
            padding: "9px 10px",
            borderTopWidth: "1px",
            borderTopStyle: "solid",
        })
        this.menuContents.insertBefore(this.promoteModSection, this.modSection.nextSibling)
        this.menuContents.appendChild(this.hasTippedSection)

        const banLink = this.createLink()
        banLink.dataset.testid = "kick-ban-toggle"
        const banText = this.createLinkSpan()
        banText.style.paddingLeft = "20px"

        this.modSection.appendChild(banLink)
        banLink.appendChild(banText)

        banText.innerText = i18n.kickBan
        banText.title = banText.innerText

        addEventListenerPoly("click", banLink, () => {
            this.hideOverlay()
            if (isNotLoggedIn(`You must be logged in to ban a user. Click "OK" to login.`)) {
                return
            }
            modalConfirm(i18n.banUserConfirm(this.user.username), () => {
                addPageAction("BanUser", { "username": this.user.username })
                postCb(`roomban/${this.user.username}/${this.roomName}/`, {}).catch((xhr) => {
                    error("broadcast_ban_error", xhr)
                    modalAlert(i18n.banUserError(this.user.username))
                })
            })
        })

        const hasTipped = document.createElement("div")
        const tipIcon = document.createElement("img")
        const tipText = document.createElement("span")
        tipIcon.src = `${STATIC_URL}ico-tip.gif`
        applyStyles(tipIcon, {
            width: "13px",
            height: "13px",
            padding: "2px 4px 0 1px",
        })
        hasTipped.appendChild(tipIcon)
        hasTipped.appendChild(tipText)
        tipText.innerText = `${i18n.userHasTippedMe(this.user.username)}:`
        applyStyles(tipText, {
            display: "inline-block",
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
        })
        this.hasTippedSection.appendChild(hasTipped)
        const tippedHour = document.createElement("div")
        const tippedDay = document.createElement("div")
        const tippedTwoWeeks = document.createElement("div")
        tippedHour.style.paddingLeft = "20px"
        tippedDay.style.paddingLeft = "20px"
        tippedTwoWeeks.style.paddingLeft = "20px"

        const tippedPastHour = document.createElement("span")
        const tippedPastDay = document.createElement("span")
        const tippedPastTwoWeeks = document.createElement("span")

        tippedHour.appendChild(tippedPastHour)
        tippedDay.appendChild(tippedPastDay)
        tippedTwoWeeks.appendChild(tippedPastTwoWeeks)

        tippedPastHour.innerText = i18n.tokensInPastHour(0)
        tippedPastHour.dataset.testid = "tipped-hour"
        tippedPastDay.innerText = i18n.tokensInPastDay(0)
        tippedPastDay.dataset.testid = "tipped-day"
        tippedPastTwoWeeks.innerText = i18n.tokensInPastTwoWeeks(0)
        tippedPastTwoWeeks.dataset.testid = "tipped-two-weeks"

        hasTipped.appendChild(tippedHour)
        hasTipped.appendChild(tippedDay)
        hasTipped.appendChild(tippedTwoWeeks)

        if (this.roomName !== undefined && this.currentUsername !== undefined) {
            postCb(
                `api/getchatusermenu/`,
                {
                    "roomname": this.roomName,
                    "fromuser": this.currentUsername,
                    "touser": this.user.username,
                },
            ).then((xhr) => {
                this.promoteModSection.appendChild(this.createChangeModStatusLink(Boolean(xhr.getResponseHeader("X-Target_Is_Mod")), this.user))
                tippedPastHour.innerText = i18n.tokensInPastHour(this.getTipHeaderValue(xhr.getResponseHeader("X-Tipped_Performer_Last_Hour")))
                tippedPastDay.innerText = i18n.tokensInPastDay(this.getTipHeaderValue(xhr.getResponseHeader("X-Tipped_Performer_Last_24_Hours")))
                tippedPastTwoWeeks.innerText = i18n.tokensInPastTwoWeeks(this.getTipHeaderValue(xhr.getResponseHeader("X-Tipped_Performer_Last_Two_Weeks")))
            }).catch((xhr) => {
                error(xhr)
                modalAlert(`Error fetching user menu`)
            })
        }
    }

    protected addLinksData(): void {
        super.addLinksData()

        const showMyCamLink = this.createLink()
        showMyCamLink.style.display = "none"

        const showMyCamIcon = document.createElement("img")
        applyStyles(showMyCamIcon, {
            padding: "0px 5px 0 2px",
            verticalAlign: "bottom",
        })
        showMyCamLink.appendChild(showMyCamIcon)

        const showMyCamText = this.createLinkSpan()
        showMyCamLink.appendChild(showMyCamText)

        roomLoaded.once((context) => {
            const smcManager = ShowMyCamPlayerManager.getInstance()
            if (smcManager !== undefined) {
                const showMyCamLinkInfo = smcManager.getContextMenuLinkInfo(this.user.username)
                if (showMyCamLinkInfo.linkText !== "") {
                    showMyCamIcon.src = showMyCamLinkInfo.icon
                    showMyCamText.textContent = showMyCamLinkInfo.linkText
                    addEventListenerPoly("click", showMyCamLink, () => {
                        showMyCamLinkInfo.action()
                        this.hideOverlay()
                    })
                    showMyCamLink.style.display = ""
                }
            }
        })
        showMyCamLink.dataset.testid = "view-cam"
        this.menuLinks.prepend(showMyCamLink)
    }

    private createChangeModStatusLink(isMod: boolean, u: IUserInfo): HTMLDivElement {
        const changeModStatusLink = this.createLink()
        const changeModStatusSpan = this.createLinkSpan()
        changeModStatusSpan.style.paddingLeft = "20px"
        changeModStatusLink.dataset.testid = "moderator-toggle"
        let notLoggedInMessage: string
        let endpoint: string
        let errorMessage: string
        if (isMod) {
            changeModStatusSpan.innerText = i18n.removeModeratorStatus
            notLoggedInMessage = i18n.removeModeratorLoginError
            endpoint = `removemoderator/${u.username}/${this.roomName}/`
            errorMessage = i18n.moderatorRevokeError(u.username)
        } else {
            changeModStatusSpan.innerText = i18n.promoteToModerator
            notLoggedInMessage = i18n.promoteModeratorLoginError
            endpoint = `addmoderator/${u.username}/${this.roomName}/`
            errorMessage = i18n.moderatorPromoteError(u.username)
        }
        changeModStatusSpan.title = changeModStatusSpan.innerText
        addEventListenerPoly("click", changeModStatusLink, () => {
            this.hideOverlay()
            if (isNotLoggedIn(notLoggedInMessage)) {
                return
            }
            postCb(endpoint, {}).then(() => {
                userModeratorStatusChanged.fire({ isMod: !isMod, username: u.username })
            }).catch((xhr) => {
                error(xhr)
                modalAlert(errorMessage)
            })
        })
        changeModStatusLink.appendChild(changeModStatusSpan)
        return changeModStatusLink
    }

    private getTipHeaderValue(tipAmount: string | null): number {
        if (tipAmount === null) {
            return 0
        }
        const tipAmountNum = Number(tipAmount)
        if (tipAmountNum < 0) {
            return 0
        }
        return tipAmountNum
    }
}
