// Heavily modified from
// https://gist.github.com/waterplea/6cde85e4655202dccf1ee31b5ab3c5fb

import { CbColor } from "../cbcolor/cbcolor"

// #region SETUP
// Note any variables with "Capture" in name include capturing bracket set(s).
const searchFlags = "gi" // ignore case for angles, "rgb" etc
const rAngle = /(?:[+-]?\d*\.?\d+)(?:deg|grad|rad|turn)/ // Angle +ive, -ive and angle types

// technically this line matches some invalid side corner expressions (e.g. "to top top" or "to left right")
// but those expressions would never get through the apps system to begin with
const rSideCornerCapture = /to\s+((?:(?:left|right|top|bottom)(?:\s+(?:top|bottom|left|right))?))/ // optional 2nd part

const rComma = /\s*,\s*/ // Allow space around comma.
const rColorHex = /#(?:[a-f0-9]{6}|[a-f0-9]{3})/ // 3 or 6 character form
const rDigits3 = /\(\s*(?:\d{1,3}\s*,\s*){2}\d{1,3}\s*\)/ // "(1, 2, 3)"
const rDigits4 = /\(\s*(?:\d{1,3}\s*,\s*){2}\d{1,3}\s*,\s*\d*\.?\d+\)/ // "(1, 2, 3, 4)"
const rValue = /(?:[+-]?\d*\.?\d+)(?:%|[a-z]+)?/ // ".9", "-5px", "100%".
const rKeyword = /[_a-z-][_a-z0-9-]*/ // "red", "transparent", "border-collapse".
const rColor = combineRegExp(["(?:", rColorHex, "|", "(?:rgb|hsl)", rDigits3, "|", "(?:rgba|hsla)", rDigits4, "|", rKeyword, ")"], "")
const rColorStop = combineRegExp([rColor, "(?:\\s+", rValue, "(?:\\s+", rValue, ")?)?"], "") // Single Color Stop, optional %, optional length.
const rColorStopList = combineRegExp(["(?:", rColorStop, rComma, ")*", rColorStop], "") // List of color stops min 1.
const rLineCapture = combineRegExp(["(?:(", rAngle, ")|", rSideCornerCapture, ")"], "") // Angle or SideCorner
const gradientSearchList = ["(?:(", rLineCapture, ")", rComma, ")?(", rColorStopList, ")"]
const colorStopSearchList = ["\\s*(", rColor, ")", "(?:\\s+", "(", rValue, "))?", "(?:", rComma, "\\s*)?"]

// Capture 1:"line", 2:"angle" (optional), 3:"side corner" (optional) and 4:"stop list".
const gradientSearch = () => combineRegExp(gradientSearchList, searchFlags)
// Capture 1:"color" and 2:"position" (optional).
const colorStopSearch = () => combineRegExp(colorStopSearchList, searchFlags)

// #endregion

interface IGradientStop {
    color: CbColor
    position: string
}

export class LinearGradient {
    public stops: IGradientStop[]
    public line?: string
    private angle?: string

    private constructor(input: string, search: RegExp, matchGradient: RegExpExecArray) {
        this.stops = []
        this.line = matchGradient[1]
        const group = search.exec(input)
        if (group === null) {
            return
        }
        this.angle = group[1]

        // Loop though all the color-stops.
        const stopSearch = colorStopSearch()
        let matchColorStop = stopSearch.exec(group[4])
        while (matchColorStop !== null) {
            this.stops.push({
                color: CbColor.get(matchColorStop[1]),
                position: matchColorStop[2] ?? "",
            })

            // Continue searching from previous position.
            matchColorStop = stopSearch.exec(group[4])
        }
    }

    public static parse(input = ""): LinearGradient | undefined {
        if (input.trim().length === 0 || !input.trim().toLowerCase().startsWith("linear-gradient")) {
            return undefined
        }
        const search = gradientSearch()
        const matchGradient = search.exec(input)
        if (matchGradient === null) {
            // Match not found.
            return undefined
        }
        return new LinearGradient(input, search, matchGradient)
    }

    public isBlack(): boolean {
        return this.stops.every(stop => stop.color.equals("black"))
    }

    public isWhite(): boolean {
        return this.stops.every(stop => stop.color.equals("white"))
    }

    public toString(): string {
        const grad: string[] = []
        if (this.angle !== undefined) {
            grad.push(this.angle)
        }

        for (const stop of this.stops) {
            grad.push(`${stop.color.toRgbString()} ${stop.position}`.trim())
        }

        return `linear-gradient(${grad.join(", ")})`
    }

    public darkest(bg = "white"): CbColor {
        const background = CbColor.get(bg)
        let darkestColor = CbColor.get("white")
        let darkestLum = darkestColor.getLuminance()
        for (const stop of this.stops) {
            const solid = stop.color.onBackground(background)
            const lum = solid.getLuminance()
            if (lum < darkestLum) {
                darkestLum = lum
                darkestColor = solid
            }
        }
        return darkestColor
    }

    public lightest(bg = "white"): CbColor {
        const background = CbColor.get(bg)
        let lightestColor = CbColor.get("white")
        let lightestLum = lightestColor.getLuminance()
        for (const stop of this.stops) {
            const solid = stop.color.onBackground(background)
            const lum = solid.getLuminance()
            if (lum > lightestLum) {
                lightestLum = lum
                lightestColor = solid
            }
        }
        return lightestColor
    }
}

function combineRegExp(regexpList: ReadonlyArray<string | RegExp>, flags: string): RegExp {
    return new RegExp(
        regexpList.reduce<string>(
            (result, item) => result + (typeof item === "string" ? item : item.source),
            "",
        ),
        flags,
    )
}