import { convivaAppTracker, setUserId, trackPageView } from "@convivainc/conviva-js-appanalytics"
import { enableButtonClickTracking, enableLinkClickTracking, LinkClickTrackingPlugin } from "@convivainc/conviva-js-appanalytics-click-tracking"
import { enableErrorTracking, ErrorTrackingPlugin } from "@convivainc/conviva-js-appanalytics-error-tracking"
import { PerformanceTimingPlugin } from "@convivainc/conviva-js-appanalytics-performance-timing"
import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { getCookieOrEmptyString } from "@multimediallc/web-utils/storage"
import { AdvancedSearchOptions } from "../cb/advancedSearchOptions"
import { dmsEnabled } from "../cb/api/pm"
import { tokenBalanceUpdate } from "../cb/api/tipping"
import { Footer } from "../cb/components/footer"
import { Header } from "../cb/components/header/header"
import { setupDmWindow } from "../cb/components/pm/dmWindowsManager"
import { openFeedbackForm } from "../cb/components/userMenus/ui/userFeedbackFormEvents"
import { UserFeedbackModal } from "../cb/components/userMenus/ui/userFeedbackModal"
import { bindDmListDropDown, bindHeaderOpenedEvents, bindMobileDmsMenu, bindUserMenuDropdown, bindUserUpdatesPanel } from "../cb/components/userMenus/ui/userMenuDropDown"
import { FollowedTab } from "../cb/components/followingTab/followedTab"
import { ReactComponentRegistry } from "../cb/components/ReactRegistry"
import { inactiveTabCheck } from "../cb/inactiveTabCheck"
import { pageContext } from "../cb/interfaces/context"
import { parseSocialAuths } from "../cb/interfaces/socialAuth"
import { parseLoggedInUser } from "../cb/interfaces/user"
import { PushService } from "../cb/pushservicelib/pushService"
import { UserTokenUpdateTopic } from "../cb/pushservicelib/topics/user"
import { RoomReload, setRoomAnimation } from "../cb/roomList"
import { showAVSignupOverlayIfEnabled } from "../cb/ui/ageVerificationSignupOverlay"
import { showLoginOverlay } from "../cb/ui/loginOverlay"
import { bindGetMoreTokensLink, setupOneClickListener, updateTokencountElements } from "../cb/ui/tipping"
import { addEventListenerPoly } from "../common/addEventListenerPolyfill"
import { EmoticonPreviewModal, MobileEmoticonPreviewModal, openEmoticonPreview } from "../common/emoticonPreviewModal"
import { addPageAction, setCustomAttribute } from "../common/newrelic"
import { UserNotesBadgeManager } from "../common/notes"
import {
    commandeerJoinOverlayAnchors,
    convertBaseHeaderFooterLinksToJoinOverlayAnchors,
    JoinOverlay,
    showJoinOverlay,
} from "../common/theatermodelib/joinOverlay"
import type { DiscoverJoinOverlay } from "../common/theatermodelib/discoverJoinOverlay"
import { parseQueryString } from "../common/urlUtil"
import { LOGIN_BASE_PATH, SIGN_UP_BASE_PATH, watchForAccountRedirectNavigation } from "../common/redirectParamUtils"
import { bindAgeGateSignup } from "../cb/ui/agegate/ageGateSignup"
import { initCssVhVarHandling } from "../cb/util/cssVhVarUtil"
import { isAnyOrientationChangeSupported, isOrientationChangeSupported, isScreenOrientationChangeSupported, screenOrientationChanged } from "../common/mobilelib/windowOrientation"
import { initMessageRenderOptions } from "../common/renderMessage"
import { isMobileSpaShown, isReactUserListActive } from "../common/featureFlagUtil"
import { initFullscreenChangeEvent } from "../common/fullscreen"
import { convivaEnabled } from "../common/player/utils"
import { normalizeResource } from "../common/api"
import { modalConfirm } from "../common/alerts"
import { i18n } from "../common/translation"

import type { NewrelicAttributes } from "@multimediallc/web-utils"
import type { DmListDropdown } from "../cb/components/pm/dmListDropdown"
import type { UserMenuDropDown } from "../cb/components/userMenus/ui/userMenuDropDown"
import type { UserUpdatesPanel } from "../cb/components/userMenus/ui/userUpdatesPanel"
import type { IPageContext } from "../cb/interfaces/context"
import type { IEmoticon } from "../common/emoticonAutocompleteModal"
import type { ILoginOverlayProps } from "../cb/ui/loginOverlay"
import { bindGlobalDismissibleNotices } from "../cb/dismissibleNotice"
import { activateI18n } from "../i18n"
import { MobilePushMenu } from "../cb/components/userMenus/ui/mobilePushMenu"

declare const REVISION: string
declare const GIT_TAG: string
declare const CONVIVA_KEY: string
declare const CONVIVA_TEST_KEY: string


type IEntryPoint = new(context: IPageContext) => BaseEntryPoint

export function exportEntryPoint(entryPoint: IEntryPoint): void {
    window["TS"] = entryPoint
}

export class BaseEntryPoint {
    protected context: IPageContext
    public roomReload: object
    public addPageAction: (name: string, attributes: NewrelicAttributes, modernTiming?: boolean) => void
    public showLoginOverlay: (options: ILoginOverlayProps) => void
    public openFeedbackForm: (source: string) => void | undefined // undefined for mobile

    constructor(contextObj: IPageContext) {
        this.parseContext(contextObj)
        pageContext.current = this.context
        this.initI18n()
        this.init()
        this.listenForOrientationChanges()
        this.addErrorAttributes()
    }

    // eslint-disable-next-line complexity, @typescript-eslint/no-explicit-any
    protected parseContext(contextObj: Record<string, any>): void {
        this.context = {
            current_logo: contextObj["current_logo"],
            isMobile: contextObj["is_mobile"],
            sample_metrics_off: contextObj["sample_metrics_off"],
            qualityMessage: contextObj["quality_message"],
            loggedInUser: parseLoggedInUser(contextObj["logged_in_user"]),
            isAgeGated: contextObj["user_age_gated"],
            animateThumbnails: contextObj["animate_thumbnails"],
            socialAuths: parseSocialAuths(contextObj["social_auths"]),
            isBroadcast: false,  // Overridden in broadcast entrypoints
            darkModeEnabled: contextObj["dark_mode_enabled"],
            themeName: contextObj["theme_name"],
            maxPmAge: contextObj["chat_settings"]["max_pm_age"],
            dmsEnabled: contextObj["sitewide_pms_enabled"] ?? false,
            contextID: contextObj["entrypoint_context_id"],
            csrftoken: contextObj["csrftoken"],
            isLoadedFromCache: undefined,
            showPaxumNotice: contextObj["show_paxum_notice"] ?? false,
            realtimeUserlistEnabled: contextObj["realtime_userlist_enabled"] === true,
            isInternal: contextObj["INTERNAL"],
            isDevstage: contextObj["DEVSTAGE"],
            pageLocation: contextObj["pageLocation"] ?? "homepage", // Overridden in entrypoints if needed
            PurchaseEventSources: contextObj["PurchaseEventSources"],
            languageCode: contextObj["language_code"],
            showLocation: Boolean(contextObj["showLocation"]) ?? true,
            showNpsSentimentSurveyGroup: contextObj["show_nps_sentiment_survey_group"] ?? false,
            regions: contextObj["regions"] ?? "",
            isTestbed: contextObj["isTestbed"] ?? false,
            noImage: contextObj["noImage"] ?? false,
            throttleTopicPublish: contextObj["push_throttle_topic_publish"] === true,
            isNoninteractiveUser: false,
            mergePmDm: contextObj["merge_pm_dm"],
            noAnalytics: contextObj["no_analytics"],
            showMobileSiteBannerLink: contextObj["show_mobile_site_banner_link"] ?? false,
            removeMobileSubdomain: contextObj["remove_mobile_subdomain"],
        }
        PushService.initialize(contextObj)
        setRoomAnimation(this.context.animateThumbnails)
        this.setUserInteractive()

        if (isLocalStorageSupported()) {
            const prevContextID = window.localStorage.getItem("contextID")
            window.localStorage.setItem("contextID", this.context.contextID)

            this.context.isLoadedFromCache = prevContextID === this.context.contextID
        }
    }

    /*
    Use this method to bind global site-wide actions. This file should be kept very short and
    concise. Actions must be defined and performed in other files/objects.
    NOTE: several entrypoints override init(), e.g. embed and photovideos, and may need updating too if adding code here
     */
    protected init(): void {
        inactiveTabCheck()
        this.roomReload = RoomReload.exportToJS()
        this.addPageAction = addPageAction
        this.showLoginOverlay = showLoginOverlay
        if (this.context.isMobile) {
            this.initMobile()
        } else {
            this.initDesktop()
        }
        setCustomAttribute("history_length", (history.length.toString()))
        setupOneClickListener()
        initMessageRenderOptions()
        initCssVhVarHandling()
        initFullscreenChangeEvent()
        this.setupConvivaEcoSensor()
        showAVSignupOverlayIfEnabled()
        bindGlobalDismissibleNotices()
        if (this.context.isAgeGated) {
            bindAgeGateSignup()
        }
    }

    protected initMobile(): void {
        this.bindMobilePushMenu()
        this.bindAdvancedSearchOptions(true)
        this.bindPurchaseTokenLinks()
        this.bindTokenCountUpdater()
        this.setupEmoticonPreviewModal(true)
        watchForAccountRedirectNavigation()
        this.updateFlashMessageDisplay()

        if (this.context.loggedInUser !== undefined) {
            this.setupLogoutModal()
        }
    }

    protected initDesktop(): void {
        this.bindUserInfoPanelDropdown()
        this.bindUserUpdatesPanel(false)
        this.bindPurchaseTokenLinks()
        if (!this.context.isAgeGated) {
            this.bindDmListDropdown(false)
            this.bindFollowedTab()
            this.handlePmChatWindows()
        }
        this.setupEmoticonPreviewModal(false)
        this.bindHeader()
        this.bindFooter()

        this.bindTokenCountUpdater()
        watchForAccountRedirectNavigation()

        if (this.context.loggedInUser === undefined) {
            if (window.location.pathname !== LOGIN_BASE_PATH) {
                this.setupLoginOverlay()
            }
            if (parseQueryString(window.location.search)["join_overlay"] !== undefined) {
                if (window.location.pathname !== SIGN_UP_BASE_PATH) {
                    this.setupJoinOverlay()
                }
            }
        }
        if (this.context.loggedInUser !== undefined) {
            this.setupLogoutModal()
        }
        this.setupUserFeedbackForm()
        this.watchForAdIntervention()
        this.openFeedbackForm = (source: string) => {
            openFeedbackForm.fire({ source: source })
        }
    }

    protected initI18n(): void {
        activateI18n(this.context.languageCode)
    }

    // Called only in room entrypoints (broadcast.ts, theatermode.ts, etc), but defined here so they can share the code
    protected initRoom(): void {
        // Definitely other parts of init could go in here too, so feel free to move them here if you notice them

        // Ensure UserNotesChatBadges singleton instance is created and prefetches notes
        UserNotesBadgeManager.getOrCreateInstance()
        this.setupReactGenderIcons()
    }

    // Call in entrypoints that have instances of the React GenderIcon
    protected setupReactGenderIcons(): void {
        if (isReactUserListActive()) {
            const defsDiv = document.createElement("div")
            defsDiv.style.height = "0"
            defsDiv.style.width = "0"
            document.body.appendChild(defsDiv)
            const GenderIconSvgDefs = ReactComponentRegistry.get("GenderIconDefs")
            new GenderIconSvgDefs({}, defsDiv)
        }
    }

    protected addErrorAttributes(): void {
        // adds base attributes to JS errors when reporting to NewRelic
        if (this.context.loggedInUser !== undefined) {
            setCustomAttribute("username", this.context.loggedInUser.username)
            setCustomAttribute("enduser.id", this.context.loggedInUser.userUid)
        } else {
            setCustomAttribute("username", "__anon__")
            setCustomAttribute("enduser.id", "0")
        }
        setCustomAttribute("enduser.session", this.context.contextID)
        setCustomAttribute("revision", REVISION)
        setCustomAttribute("git_tag", GIT_TAG)
    }

    protected setupJoinOverlay(): void {
        convertBaseHeaderFooterLinksToJoinOverlayAnchors()
        commandeerJoinOverlayAnchors.fire(undefined)

        this.addJoinOverlayListener(new JoinOverlay({}))
    }

    protected addJoinOverlayListener(overlay: JoinOverlay): void {
        let joinOverlay: JoinOverlay | DiscoverJoinOverlay | undefined
        showJoinOverlay.listen(joinOverlayAnchor => {
            if (joinOverlay === undefined) {
                joinOverlay = overlay
            }
            window.scrollTo(0, 0)
            joinOverlay.initAndShow(joinOverlayAnchor)
        })
    }

    protected setupUserFeedbackForm(): void {
        openFeedbackForm.listen(data => {
            UserFeedbackModal.show(data)
        })
    }

    protected setupLoginOverlay(): void {
        for (const el of document.querySelectorAll<HTMLAnchorElement>("a.login-link")) {
            el.onclick = (e) => {
                showLoginOverlay({})
                e.preventDefault()
            }
        }
    }

    protected setupLogoutModal(): void {
        const logoutLinks = document.querySelectorAll<HTMLAnchorElement>("a.logout-link")
        if (logoutLinks.length > 0) {
            const logoutForm = document.createElement("form")
            logoutForm.method = "post"
            logoutForm.action = normalizeResource("/auth/logout/")
            logoutForm.target = "_top"
            logoutForm.style.display = "none"

            const csrfField = document.createElement("input")
            csrfField.type = "hidden"
            csrfField.name = "csrfmiddlewaretoken"
            csrfField.value = getCookieOrEmptyString("csrftoken")
            logoutForm.appendChild(csrfField)
            document.body.appendChild(logoutForm)

            for (const el of document.querySelectorAll<HTMLAnchorElement>("a.logout-link")) {
                el.onclick = (e) => {
                    e.preventDefault()
                    const text = this.context.isAgeGated ? i18n.switchAccountModalText : i18n.logoutModalText;
                    modalConfirm(text, () => {
                        csrfField.value = getCookieOrEmptyString("csrftoken")
                        logoutForm.submit()
                    })
                }
            }
        }
    }

    private listenForOrientationChanges(): void {
        if (!isAnyOrientationChangeSupported()) {
            return
        }

        const fireOrientationEvent = () => {
            screenOrientationChanged.fire()
        }

        if (isScreenOrientationChangeSupported()) {
            screen.orientation.addEventListener("change", fireOrientationEvent)
        } else if (isOrientationChangeSupported()) {
            addEventListenerPoly("orientationchange", window, fireOrientationEvent)
        }
    }

    protected bindUserInfoPanelDropdown(): UserMenuDropDown | undefined {
        const userInfoPanelMenuDropdownRoot = document.querySelector<HTMLDivElement>("#userInfoPanelMenuDropdownRoot")
        const userInfoToggleId = "user_information_profile_container"
        const userInformationProfileDropDownToggle = document.getElementById(userInfoToggleId)

        return bindUserMenuDropdown(
            userInfoPanelMenuDropdownRoot,
            userInformationProfileDropDownToggle,
            this.context.loggedInUser !== undefined ? this.context.loggedInUser.username : "",
            this.context.darkModeEnabled,
        )
    }

    protected bindMobilePushMenu(): void {
        if (isMobileSpaShown()) {
            const pushmenuContainer = document.getElementById("pushmenu-container")
            const menuButton = document.getElementById("mmnav")
            if (pushmenuContainer !== null && menuButton !== null) {
                new MobilePushMenu(pushmenuContainer, {
                    menuButton: menuButton as HTMLDivElement,
                })
            }
        } else {
            this.bindUserUpdatesPanel(true)
            if (!this.context.isAgeGated) {
                this.bindDmListDropdown(true)
            }
            bindHeaderOpenedEvents()
        }
    }

    protected bindUserUpdatesPanel(mobile=false): UserUpdatesPanel | undefined {
        const userUpdatesBellRoot = document.querySelector<HTMLDivElement>("#userUpdatesBellRoot")
        const userUpdatesDropDownRoot = document.querySelector<HTMLDivElement>("#userUpdatesMenuDropdownRoot")
        const mmnav = document.querySelector<HTMLDivElement>("#mmnav")

        return bindUserUpdatesPanel(
            userUpdatesBellRoot,
            userUpdatesDropDownRoot,
            mobile,
            mmnav,
        )
    }

    protected bindDmListDropdown(mobile=false): DmListDropdown | undefined{
        const userUpdatesBellRoot = document.querySelector<HTMLDivElement>("#userUpdatesBellRoot")
        const dmListDropdownRoot = document.querySelector<HTMLDivElement>("#dmListDropdownRoot")
        const dmListIconRoot = document.querySelector<HTMLDivElement>("#dmListIconRoot")
        const mmnav = document.querySelector<HTMLDivElement>("#mmnav")

        if (mobile) {
            bindMobileDmsMenu(
                dmListIconRoot,
                userUpdatesBellRoot,
                mmnav,
                dmListDropdownRoot,
            )
        } else {
            return bindDmListDropDown(
                dmListIconRoot,
                dmListDropdownRoot,
            )
        }

        return undefined
    }

    protected bindPurchaseTokenLinks(): void {
        for (const el of document.querySelectorAll<HTMLAnchorElement>(".purchase_tokens a, a.purchase_tokens, a#purchase_tokens")) {
            if (this.context.loggedInUser !== undefined) {
                bindGetMoreTokensLink(el)
            }
            else {
                el.onclick = (e) => {
                    showLoginOverlay({})
                    e.preventDefault()
                }
            }
        }
    }

    protected watchForAdIntervention(): void {
        if (!window.hasOwnProperty("ReportingObserver")) {
            return
        }

        const sendReports = (reports: Report[]) => {
            for (const report of reports) {
                if (report["type"] === "intervention") {
                    addPageAction("AdIntervention", { "report": JSON.stringify(report["body"]) })
                }
            }
        }
        const observer = new window["ReportingObserver"](
            (reports: Report[], observer: ReportingObserver) => {
                sendReports(reports)
            },
            { buffered: true },
        )
        observer.observe()

        // Needed in addition to observer.observe()
        // Experimentation shows beforeunload but not pagehide works to get sendReports in before newrelic flushes its queue
        addEventListenerPoly("beforeunload", window, (event) => {
            const reports = observer.takeRecords()
            sendReports(reports)
        })
    }

    protected bindFollowedTab(): void {
        const followedAnchor = document.getElementById("followed_anchor")
        if (followedAnchor !== null && followedAnchor instanceof HTMLAnchorElement) {
            followedAnchor.onclick = (ev) => {
                if (!ev.ctrlKey && !ev.metaKey) {
                    ev.preventDefault()
                }
            }
            new FollowedTab(followedAnchor,
                this.context.loggedInUser === undefined,
                this.context.loggedInUser !== undefined ? this.context.loggedInUser.username : "")
        }
    }

    protected bindHeader(): Header | undefined  {
        const headerEl = document.querySelector("#header")
        if (headerEl !== null && headerEl instanceof HTMLElement) {
            return new Header(headerEl)
        }
        return undefined
    }

    protected bindFooter(): Footer | undefined {
        const footerEl = document.querySelector(".footer-holder")
        if (footerEl !== null && footerEl instanceof HTMLElement) {
            return new Footer(footerEl)
        }
        return undefined
    }


    protected handlePmChatWindows(): void {
        const isStaffTools = window.location.pathname.startsWith("/staff_tools")
        const headerDiv = document.getElementById("header")
        if (headerDiv !== null && headerDiv.offsetParent !== null && !isStaffTools) {
            if (this.context.loggedInUser?.isStaff === true || (this.context.loggedInUser !== undefined && dmsEnabled())) {
                setupDmWindow(this.context.loggedInUser.username)
            }
        }
    }

    protected setupEmoticonPreviewModal(isMobile = false): void {
        let emoticonPreviewModal: EmoticonPreviewModal | MobileEmoticonPreviewModal | undefined
        openEmoticonPreview.listen((emoticon: IEmoticon) => {
            if (emoticonPreviewModal === undefined) {
                emoticonPreviewModal = isMobile ? new MobileEmoticonPreviewModal() : new EmoticonPreviewModal()
            }
            emoticonPreviewModal.initAndShow(emoticon)
        })
    }

    protected bindAdvancedSearchOptions(isMobile: boolean): AdvancedSearchOptions {
        return new AdvancedSearchOptions(isMobile)
    }

    protected bindTokenCountUpdater(): void {
        const userUid = pageContext.current.loggedInUser?.userUid

        if (userUid !== undefined) {
            new UserTokenUpdateTopic(userUid).onMessage.listen(update => {
                tokenBalanceUpdate.fire(update)
            })
        }

        tokenBalanceUpdate.listen((update) => {
            updateTokencountElements(update.tokens)
        })
    }

    private updateFlashMessageDisplay(): void {
        /**
         * Currently only used on mobile to prevent showing the flash message container in the case that
         * messages with specific tags have been filtered out within the template.
         */
        const flashMessageContainer = document.querySelector<HTMLDivElement>(".flash-message-container")
        if (flashMessageContainer !== null) {
            const messages = flashMessageContainer.querySelectorAll<HTMLParagraphElement>(".message p")

            if (messages.length === 0) {
                flashMessageContainer.style.display = "none"
            }
        }
    }

    private setUserInteractive(): void {
        /*
         * Staff users who are on internal.chaturbate.com are deemed to be non-interactive and will not show up in userlist.
         * They should not be able to participate in a room in any way.
         */
        if (this.context.isInternal && this.context.loggedInUser?.isStaff === true && PRODUCTION && !this.context.isDevstage) {
            this.context.isNoninteractiveUser = true
        }
    }

    private setupConvivaEcoSensor(): void {
        if (convivaEnabled()) {
            let key = CONVIVA_KEY
            if (pageContext.current.isInternal || !PRODUCTION) {
                key = CONVIVA_TEST_KEY
            }
            convivaAppTracker({
                appId: "Multi Media Web",
                convivaCustomerKey: key,
                contexts: {
                    performanceTiming: true,
                },
                plugins: [ PerformanceTimingPlugin(), ErrorTrackingPlugin(), LinkClickTrackingPlugin()],
            })

            if (pageContext.current.loggedInUser) {
                setUserId(pageContext.current.loggedInUser.userUid)
            } else {
                setUserId("anon")
            }

            trackPageView()

            enableLinkClickTracking()
            enableButtonClickTracking()

            enableErrorTracking()
        }
    }
}

exportEntryPoint(BaseEntryPoint)
