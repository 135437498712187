import { Component } from "../../common/defui/component"

export class Wrapper extends Component<HTMLDivElement> {
    constructor(c?: Component | HTMLElement) {
        super("div")
        this.element.style.height = ""
        this.element.style.width = ""
        this.element.style.position = "relative"
        if (c !== undefined) {
            if (c instanceof Component) {
                this.addChild(c)
            } else {
                this.element.appendChild(c)
            }
        }
    }
}
