import { iOSVersion, isAudioContextFullySupported, isAudioElementSupported, isiOS, isMp3Supported } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly, removeEventListenerPoly } from "./addEventListenerPolyfill"
import { documentVisibilityChange } from "./windowUtils"

interface ISound {
    ready: boolean
    buffer?: AudioBuffer
    audioElement?: HTMLAudioElement
}

export const enum AudioHolderSound {
    PrivateShow = "PrivateShow",
    HugeTip = "HugeTip",
    LargeTip = "LargeTip",
    MediumTip = "MediumTip",
    SmallTip = "SmallTip",
    TinyTip = "TinyTip",
}

export class AudioHolder {
    private soundMap = new Map<AudioHolderSound, ISound>()
    private supported = true
    private context: AudioContext | undefined

    constructor() {
        this.supported = isMp3Supported() && (isAudioContextFullySupported() || isAudioElementSupported())
        this.init()

        // beforeunload shouldn't be needed, but we are seeing too many contexts being created and have this code
        // to work around a **possible** browser bug.
        addEventListenerPoly("beforeunload", window, ev => {
            this.close()
        })

        documentVisibilityChange.listen((visible: boolean) => {
            if (visible && isiOS()) {
                const version = iOSVersion()
                // iOS 12 and under are extremely buggy when it comes to the AudioContext.
                // If the user goes to the homepage for more than 6 seconds, it will mute the Context with
                // no way to resume it. Re-initing does require a new user interaction to enable audio.
                if (version !== undefined && version < 13) {
                    this.init()
                } else {
                    if (this.context !== undefined) {
                        // iOS 13 sometimes does not resume the AudioContext, so we explicitly do it
                        this.context.resume()  // eslint-disable-line @typescript-eslint/no-floating-promises
                    }
                }
            }
        })
    }

    private init(): void {
        if (!this.supported) {
            warn("Sounds are not supported.")
            return
        }

        if (isAudioContextFullySupported()) {
            info("Using audio context")
            this.close()
            this.ensureContextCreated(false)

            const initSoundSystemFromEvent = () => {
                this.playEmptySound()
                removeEventListenerPoly("click", document, initSoundSystemFromEvent)
                removeEventListenerPoly("touchstart", document, initSoundSystemFromEvent)
                removeEventListenerPoly("touchend", document, initSoundSystemFromEvent)
            }
            addEventListenerPoly("click", document, initSoundSystemFromEvent)
            addEventListenerPoly("touchstart", document, initSoundSystemFromEvent)
            addEventListenerPoly("touchend", document, initSoundSystemFromEvent)
        } else {
            info("Using audio elements")
        }
    }

    private close(): void {
        if (this.context !== undefined) {
            if ("close" in this.context) {
                this.context.close()  // eslint-disable-line @typescript-eslint/no-floating-promises
            }
            this.context = undefined
        }
    }

    public loadSound(soundName: AudioHolderSound, path: string): void {
        this.soundMap.set(soundName, { ready: false })
        if (this.context !== undefined) {
            const request = new XMLHttpRequest() // eslint-disable-line @multimediallc/no-xhr
            request.open("GET", path, true)
            request.responseType = "arraybuffer"
            request.onload = () => {
                if (request.status < 200 || request.status >= 300) {
                    error("Error requesting sound", {
                        "soundName": soundName,
                        "status": request.status,
                        "statusText": request.statusText,
                    })
                    return
                }
                if (this.context === undefined) {
                    if (this.supported) {
                        error("audioHolder loadSound missing context")
                    }
                    return
                }
                void this.context.decodeAudioData(request.response.slice(0), (buffer) => {
                    debug(`Loaded sound ${soundName}`)
                    this.soundMap.set(soundName, { ready: true, buffer: buffer })
                }, (message) => {
                    error("Error decoding sound", {
                        soundName: soundName,
                        message: message,
                    })
                })  // eslint-disable-line @typescript-eslint/no-floating-promises
            }
            request.onerror = () => {
                error("Error requesting sound", {
                    "soundName": soundName,
                    "status": request.status,
                    "statusText": request.statusText,
                })
            }
            request.send()
        } else if (isAudioElementSupported()) { // For IE support
            this.soundMap.set(soundName, { ready: true, audioElement: new Audio(path) })
        }
    }

    public loadTipSounds(): void {
        this.loadSound(AudioHolderSound.HugeTip, `${STATIC_URL}sounds/classic/huge.mp3`)
        this.loadSound(AudioHolderSound.LargeTip, `${STATIC_URL}sounds/classic/large.mp3`)
        this.loadSound(AudioHolderSound.MediumTip, `${STATIC_URL}sounds/classic/medium.mp3`)
        this.loadSound(AudioHolderSound.SmallTip, `${STATIC_URL}sounds/classic/small.mp3`)
        this.loadSound(AudioHolderSound.TinyTip, `${STATIC_URL}sounds/classic/tiny.mp3`)
    }

    public loadPrivateShowSounds(): void {
        this.loadSound(AudioHolderSound.PrivateShow, `${STATIC_URL}sounds/show.mp3`)
    }

    public playSound(soundName: AudioHolderSound, volume: number): void {
        if (!this.canPlaySound(soundName, volume)) {
            return
        }
        const sound = this.soundMap.get(soundName)
        if (sound === undefined) {
            return
        }
        if (!sound.ready) {
            warn(`sound ${soundName} not ready yet (and may never will be ready)`)
        } else if (sound.buffer !== undefined) {
            this.ensureContextCreated()
            if (this.context === undefined) {
                if (this.supported) {
                    error("audioHolder playSound missing context")
                }
                return
            }
            const source = this.context.createBufferSource()
            source.buffer = sound.buffer
            const gainNode = this.context.createGain()
            source.connect(gainNode)
            gainNode.connect(this.context.destination)
            gainNode.gain.value = (volume / 3.5) / 100
            debug(`play sound ${soundName} volume ${volume / 3.5} (video at ${volume}) gain ${gainNode.gain.value}`)
            source.start(0)
        } else if (sound.audioElement !== undefined) {
            sound.audioElement.volume = volume / 100
            const promise = sound.audioElement.play()
            if (promise !== undefined) {
                // some old browsers (like ie) return nothing
                promise.catch((err) => {
                    error(`Error playing sound ${soundName} (${err})`)
                })
            }
        } else {
            error(`Missing sound buffer or element for ${soundName}`)
        }
    }

    private ensureContextCreated(reportMissingContext = true): void {
        if (!this.supported) {
            return
        }
        if (this.context === undefined) {
            if (reportMissingContext) {
                warn("audioHolder context unexpectedly missing")
            }
            if ("webkitAudioContext" in window) {
                this.context = new webkitAudioContext()
            } else {
                this.context = new AudioContext()
            }
        }
    }

    private canPlaySound(soundName: AudioHolderSound, volume: number): boolean {
        if (!this.supported) {
            debug(`Not playing sound ${soundName} because sound is not supported`)
            return false
        }
        if (volume === 0) {
            debug(`Not playing sound ${soundName} at volume 0`)
            return false
        }
        if (volume > 100 || volume < 0 || isNaN(volume)) {
            error(`setSoundVolume: invalid volume ${volume}`)
            return false
        }
        if (!this.soundMap.has(soundName)) {
            error(`Unknown sound: ${soundName}`)
            return false
        }
        return true
    }

    private playEmptySound(): void {
        if (this.context === undefined) {
            // When the first interaction with the page is a link that navigates away,
            // the audio context will be closed before it attempts to play a sound.
            return
        }
        const source = this.context.createBufferSource()
        source.buffer = this.context.createBuffer(1, 1, 22050)
        source.connect(this.context.destination)
        source.start(0)
    }
}
