import { ArgJSONMap } from "@multimediallc/web-utils"
import { parseIRoomInfo } from "../cb/components/roomlist/roomCard"
import { getCb } from "./api"
import { roomLoaded } from "./context"
import { userCategoryToAbbrev } from "./genders"
import { oneAtATimePromise } from "./promiseUtils"
import type { IRoomContext } from "./context"
import type { BoundListener } from "./events"
import type { IRoomInfo } from "../cb/components/roomlist/IRoomInfo"

export class MoreRoomIterator {
    private seenItems = new Set<string>()
    private genders: string[]
    private offset = 0
    private currentRoomGender: string | undefined
    private currentRoomName: string
    private listener: BoundListener<IRoomContext>

    constructor(private limit = 10) {
        this.offset = 0
        this.listener = roomLoaded.listen(context => {
            this.currentRoomGender = userCategoryToAbbrev(context.dossier.roomGender)
            this.currentRoomName = context.chatConnection.room()
            this.seenItems.add(this.currentRoomName)
        }, false, true)
    }

    public setGenders(genders: string[] = []): void {
        this.genders = genders
        this.offset = 0
        this.seenItems = new Set<string>()
    }

    private getMoreRooms(): Promise<XMLHttpRequest> {
        const queryParams = new URLSearchParams(window.location.search)
        queryParams.set("genders", this.gendersParam().join(","))
        queryParams.set("room", this.currentRoomName)
        queryParams.set("limit", `${this.limit}`)
        queryParams.set("offset", `${this.offset}`)
        return getCb(`api/ts/roomlist/more-rooms/?${queryParams.toString()}`)
    }

    public next = oneAtATimePromise<IRoomInfo[]>(async () => {
        const xhr = await this.getMoreRooms()
        const result = new ArgJSONMap(xhr.responseText)
        const roomsJson: [] = JSON.parse(result.getObjectString("rooms"))
        const roomInfoList: IRoomInfo[] = roomsJson.map(room =>
            parseIRoomInfo(new ArgJSONMap(room)),
        )
        this.offset += roomInfoList.length
        return roomInfoList.filter(roomInfo => !this.seenItems.has(roomInfo.room)).filter(unseenRoom => {
            this.seenItems.add(unseenRoom.room)
            return true
        })
    }, [])

    private gendersParam(): string[] {
        if (this.genders.length > 0) {
            return this.genders
        } else {
            if (this.currentRoomGender === undefined) {
                return window["default_gender"].split("")
            }
            return [this.currentRoomGender]
        }
    }

    public dispose(): void {
        this.listener.removeListener()
    }
}
