import { addColorClass, colorClass } from "../../cb/colorClasses"
import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { realtimeUserlistEnabled, RoomUsers, roomUsersUpdate } from "../../cb/roomUsers"
import { ChatButtonHolder, ChatButtonType } from "../../cb/ui/chatButtonHolder"
import { CollapsibleTab, TabId } from "../../cb/ui/tabs"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert } from "../alerts"
import { roomLoaded } from "../context"
import { applyStyles } from "../DOMutils"
import { EventRouter } from "../events"
import { isReactUserListActive } from "../featureFlagUtil"
import { addPageAction } from "../newrelic"
import { ignoreCatch } from "../promiseUtils"
import { RoomStatus } from "../roomStatus"
import { multipleUsersSvg } from "../svgElement"
import { i18n } from "../translation"
import { UsernameLabel } from "../usernameLabel"
import {
    createInputDiv,
    createInputField,
    createInputForm,
    maxInputChat,
    maxInputPm,
    SendButtonVariant,
} from "./chatTabContents"
import type { ReactComponent } from "../../cb/components/ReactRegistry"
import type { IRoomUsersInfo } from "../../cb/roomUsers"
import type { IChatWindowTab } from "../../cb/ui/iChatTab"
import type { IChatConnection } from "../context"
import type { CustomInput } from "../customInput"
import type { IUserInfo } from "../messageInterfaces"

export const userCountUpdate = new EventRouter<number>("userCountUpdate")

export class UserListTab extends CollapsibleTab implements IChatWindowTab {
    private wrapper: HTMLDivElement
    private buttonHolder: ChatButtonHolder
    private inputField: CustomInput
    private inputDiv: HTMLDivElement
    private anonUsersDiv: HTMLDivElement
    private userCount = 0
    private chatConn?: IChatConnection
    private settingInput = false
    private tabHandleIcon: HTMLElement
    private latestRoomUsersInfo: IRoomUsersInfo | undefined
    private loadingDiv: HTMLDivElement
    private refreshDiv: HTMLDivElement
    private userListContentComponent: ReactComponent
    private activeReactUserList = isReactUserListActive()

    constructor() {
        super()

        this.element.id = "UserListTab"
        this.element.style.overflow = "visible"
        this.element.style.fontFamily = "Tahoma, Helvetica, Arial, sans-serif"
        this.element.style.boxSizing = "border-box"
        this.element.style.position = "relative"
        this.element.dataset["pactionName"] = "Users"

        const flexContainer = document.createElement("div")
        applyStyles( flexContainer, {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
        })
        this.element.appendChild(flexContainer)

        this.wrapper = document.createElement("div")
        this.wrapper.style.width = "100%"
        this.wrapper.style.overflow = "auto"
        this.wrapper.style.padding = "6px"
        this.wrapper.style.boxSizing = "border-box"
        this.wrapper.style.flex = "1"
        flexContainer.appendChild(this.wrapper)
        if (this.activeReactUserList) {
            const UserListContentClass = ReactComponentRegistry.get("UserListContent")
            this.userListContentComponent = new UserListContentClass({
                roomUsers: [],
                createContextMenu: (user: IUserInfo, element: HTMLElement) => {
                    addPageAction("OpenUserContextMenu", { "username": user.username })
                    UsernameLabel.createUserContextMenu(user, element)
                },
                refreshFunc: () => {
                    this.refresh()
                },
                anonCount: 0,
            }, this.wrapper)
        } else {
            this.createAnonUsersDiv()
            this.createLoadingDiv()
        }

        this.inputDiv = createInputDiv()

        const form = createInputForm()
        this.inputField = createInputField(() => false, Math.max(maxInputChat, maxInputPm))
        const switchToChatTab = () => {
            if (this.parent !== undefined && !this.settingInput) {
                this.parent.changeToFirstTab()
            }
        }
        addEventListenerPoly("click", this.inputField.element, switchToChatTab)
        addEventListenerPoly("focus", this.inputField.element, switchToChatTab)
        this.buttonHolder = new ChatButtonHolder()
        const sendButtonRoot = document.createElement("span")
        const SendButton = ReactComponentRegistry.get("SendButton")
        new SendButton({
            "onClick": switchToChatTab,
            "isPm": false,
            "variant": SendButtonVariant.SplitMode,
        }, sendButtonRoot)
        this.buttonHolder.addButton(sendButtonRoot, ChatButtonType.Text)
        form.appendChild(this.inputField.element)
        this.inputDiv.appendChild(form)
        this.inputDiv.appendChild(this.buttonHolder.element)
        flexContainer.appendChild(this.inputDiv)

        roomLoaded.listen((context) => {
            this.chatConn = context.chatConnection
            this.chatConn.event.statusChange.listen((roomStatusChangeNotification) => {
                const cameFromOrEnteredPrivateChat = roomStatusChangeNotification.previousStatus === RoomStatus.PrivateWatching ||
                    roomStatusChangeNotification.currentStatus === RoomStatus.PrivateWatching
                if (cameFromOrEnteredPrivateChat) {
                    window.setTimeout(() => {
                        this.refresh()
                    }, 2000) // Delay to ensure user is connected to room.
                }
            })
            this.chatConn.event.roomCountUpdate.listen((count) => {
                this.userCount = count
                userCountUpdate.fire(this.userCount)
            })
            this.clear(context.dossier.numViewers)
        })

        if (realtimeUserlistEnabled()) {
            roomUsersUpdate.listen(roomUsersInfo => {
                // Don't update list if the tab is currently open.
                // TODO in an upcoming realtime userlist PR, once UserListTab is updating through this for everyone,
                //  make it update in place so we can add/remove users while the tab is open without flickering
                if (!this.isCurrentTab()) {
                    this.displayList(roomUsersInfo)
                }
                this.updateUserCount(roomUsersInfo.anonCount, roomUsersInfo.totalCount)
            })
        }
    }

    private removeUserListChildren(): void {
        while (this.wrapper.firstChild !== null) {
            this.wrapper.removeChild(this.wrapper.firstChild)
        }
    }

    private createAnonUsersDiv(): void {
        this.anonUsersDiv = document.createElement("div")
        addColorClass(this.anonUsersDiv, colorClass.defaultTooltipColor)
        applyStyles(this.anonUsersDiv, { width: "100%", margin: "2px 0", whiteSpace: "nowrap", cssFloat: "left", padding: "4px 0" })
    }

    private createLoadingDiv(): void {
        this.loadingDiv = document.createElement("div")
        addColorClass(this.loadingDiv, colorClass.defaultColor)
        this.loadingDiv.innerText = `${i18n.loadingUserListText}...`
        this.loadingDiv.style.margin = "8px 0"
        this.wrapper.style.fontSize = "12px"
        this.wrapper.appendChild(this.loadingDiv)
    }

    private createRefreshUsersLink(): void {
        this.refreshDiv = document.createElement("div")
        this.refreshDiv.style.margin = "6px"
        this.refreshDiv.style.cssFloat = "right"
        const refreshSpan = document.createElement("span")
        refreshSpan.innerText = i18n.refreshUserListText
        refreshSpan.style.cursor = "pointer"
        addColorClass(refreshSpan, "refreshUsersLink")
        refreshSpan.onmouseenter = () => {
            refreshSpan.style.textDecoration = "underline"
        }
        refreshSpan.onmouseleave = () => {
            refreshSpan.style.textDecoration = "none"
        }
        addEventListenerPoly("click", refreshSpan, ev => {
            this.refreshUserList()
            ev.preventDefault()
        })
        this.refreshDiv.appendChild(refreshSpan)
        this.wrapper.appendChild(this.refreshDiv)
    }

    private refreshUserList(): void {
        if (this.activeReactUserList) {
            return
        }
        this.removeUserListChildren()
        this.createLoadingDiv()
        this.refresh()
    }

    private clear(count: number): void {
        this.userCount = count
        userCountUpdate.fire(this.userCount)
        if (this.activeReactUserList) {
            this.userListContentComponent.update({
                roomUsers: [],
                idShowLoading: Math.random(),
            })
        } else {
            this.removeUserListChildren()
            this.createLoadingDiv()
        }
        this.repositionChildren()
    }

    public refresh(): void {
        if (this.chatConn === undefined) {
            return
        }
        const roomUsers = RoomUsers.getInstance()
        if (realtimeUserlistEnabled()) {
            // Refetch to get the new anon count. It'll update us through the roomUsersUpdate event
            roomUsers.refetch().catch(ignoreCatch)
            this.displayList(roomUsers.getRoomUsersInfo())
        } else {
            roomUsers.fetchRoomUsers().then((roomUsersInfo) => {
                this.latestRoomUsersInfo = roomUsersInfo
                this.displayList(roomUsersInfo)
            }).catch((err) => {
                error("Error retrieving user list", err)
                modalAlert(i18n.errorRefreshingUserList)
                if (this.latestRoomUsersInfo !== undefined) {
                    this.displayList(this.latestRoomUsersInfo)
                }
            })
        }
    }

    private displayList(roomUsersInfo: IRoomUsersInfo): void {
        if (this.activeReactUserList) {
            this.userListContentComponent.update({
                roomUsers: roomUsersInfo.roomUsers,
                idShowUsers: Math.random(),
            })
        } else {
            this.removeUserListChildren()
            this.createRefreshUsersLink()
            for (const user of roomUsersInfo.roomUsers) {
                const usernameDiv = document.createElement("div")
                applyStyles(usernameDiv, { width: "auto", maxWidth: "100%", margin: "2px 0" })
                usernameDiv.appendChild(this.createUsernameLabel(user).element)
                this.wrapper.appendChild(usernameDiv)
            }
            this.wrapper.appendChild(this.anonUsersDiv)
        }
        this.updateUserCount(roomUsersInfo.anonCount, roomUsersInfo.totalCount)
        this.repositionChildren()
    }

    private updateUserCount(anonCount: number, totalCount: number): void {
        this.userCount = totalCount
        userCountUpdate.fire(totalCount)
        if (this.activeReactUserList) {
            this.userListContentComponent.update({ anonCount: anonCount })
        } else {
            this.anonUsersDiv.innerText = i18n.anonUsersCount(anonCount)
        }
    }

    private createUsernameLabel(user: IUserInfo): UsernameLabel {
        return new UsernameLabel(user, undefined, true)
    }

    protected getTabHandleContent(): Node[] {
        if (this.tabHandleIcon === undefined) {
            this.tabHandleIcon = multipleUsersSvg()
            this.tabHandleIcon.style.height = "1em"
            this.tabHandleIcon.style.paddingTop = "1px"
        }
        const text = document.createTextNode(i18n.userListCountCAPS(this.userCount))
        const span = document.createElement("span")
        span.dataset["pactionName"] = "USERS"
        span.style.verticalAlign = "top"
        span.appendChild(text)
        return [span]
    }

    public getTabId(): TabId {
        return TabId.UsersDefault
    }

    public focusCurrentChatInput(): void {
        this.inputField.focus()
    }

    public blurCurrentChatInput(): void {
        this.inputField.blur()
    }

    public isInputFocused(): boolean {
        return document.activeElement === this.inputField.element
    }

    public scrollToBottom(): void {
        this.wrapper.scrollTop = this.wrapper.scrollHeight - this.wrapper.offsetHeight
    }

    public getChatInputField(): undefined {
        return undefined
    }

    protected tabHandleClicked(_: MouseEvent): void {
        super.tabHandleClicked(_)
        addPageAction("FocusTab", { "location": "userlist" })
        this.refresh()
    }
}
