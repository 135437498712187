import { isiOS } from "@multimediallc/web-utils/modernizr"

export function shouldUseFullscreenAPI(): boolean {
    // Certain OS/browsers should not be allowed to use the fullscreen API because of known bugs.
    // iOS:
    //    No fullscreen API
    // UC Browser:
    //    Browser places its own overlay on fullscreen video element
    // Firefox:
    //    Virtual keyboard is an overlay on top of video which blocks view of input
    // Samsung Internet:
    //    Virtual keyboard is an overlay on top of video which blocks view of input
    // Chrome pre-72.0.3626.0:
    //    Virtual keyboard is an overlay on top of video which blocks view of input
    //    See: https://chromium.googlesource.com/chromium/src/+/6af8167

    if (isiOS()) {
        return false
    }

    if (/UCBrowser|Firefox|SamsungBrowser/.test(navigator.userAgent)) {
        return false
    }

    if (/Chrome\//.test(navigator.userAgent)) {
        const chromeVersionStr = navigator.userAgent.split("Chrome/")[1]
        const majorVersion = chromeVersionStr.split(".")[0]
        if (parseInt(majorVersion) < 72) {
            return false
        }
    }
    return true
}
