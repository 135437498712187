import { addEventListenerPoly } from "../addEventListenerPolyfill"

export function isKeyboardOpen(): boolean {
    return document.activeElement instanceof HTMLInputElement
        || document.activeElement instanceof HTMLTextAreaElement
        || (document.activeElement instanceof HTMLElement && document.activeElement.contentEditable === "true")
}

// Allow elements to be scrollable if scrolling was prevented from
// `mobileRoot.preventCertainTouchActions()`.
//
// Note this is only necessary for elements that allow scrolling when the keyboard
// is open (emoticon modal, mention modal, chat message div, etc).
export const scrollFix = (el: HTMLElement): void => {
    el.style.overflowY = "scroll"

    addEventListenerPoly("touchstart", el, (event: TouchEvent) => {
        if (el.scrollTop === 0) {
            el.scrollTop = 1
        }
    })

    addEventListenerPoly("touchmove", el, (event: TouchEvent) => {
        if (!isKeyboardOpen()) {
            return
        }

        event.stopPropagation()
        const hasVerticalScrollbar = el.scrollHeight > el.clientHeight

        // Prevent window scrolling when input is focused
        if (!hasVerticalScrollbar && event.cancelable) {
            event.preventDefault()
        }
    })

    addEventListenerPoly("scroll", el, () => {
        // Always leave a 1px gap in scrollable elements as further check to not trigger pull-to-refresh
        if (el.scrollTop === 0) {
            el.scrollTop = 1
        } else if (el.scrollTop === (el.scrollHeight - el.offsetHeight)) {
            const modifiedScrollTop = (el.scrollHeight - el.offsetHeight) - 1

            // ensure modifiedScrollTop > 0 so that in the case when
            // `(el.scrollHeight - el.offsetHeight) === 1`, it doesn't create an infinite loop
            el.scrollTop = Math.max(modifiedScrollTop, 1)
        }
    })
}
