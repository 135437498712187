import { EventRouter } from "../events"
import { parseQueryString } from "../urlUtil"
import { getValue, StreamWatcher } from "./streamWatcher"
import type { IStreamData } from "./streamWatcher";

export class OriginWatcher {
    private streamWatcher = new StreamWatcher()
    private closed = false
    private websocket: WebSocket | undefined
    private lastInfo = new Date().getTime()
    private streamName = ""
    private origin: string | undefined
    private username: string | undefined
    private auth: string | undefined
    roomStatusUpdate = new EventRouter<string>("originWatcherRoomStatusUpdate")
    alternativeStreamEvent = new EventRouter<string>("originWatcherAlternativeStream")

    constructor(private originWSEndpoint: string) {}

    private close(): void {
        if (this.closed) {
            return
        }
        this.closed = true
        if (this.websocket !== undefined && this.websocket.readyState < 2) {
            this.websocket.close()
        }
        this.streamWatcher.close()
    }

    private start(): void {
        if (this.origin === undefined || this.auth === undefined || this.username === undefined) {
            return
        }
        if (this.websocket !== undefined) {
            if (this.websocket.readyState < 2) {
                return
            } else {
                try {
                    this.websocket.close()
                } catch {
                    // do nothing
                }
            }
        }
        this.closed = false
        this.websocket = new WebSocket(`wss://${this.origin}${this.originWSEndpoint}`)

        this.websocket.onopen = (event: Event) => {
            if (this.websocket !== undefined) {
                this.websocket.send(JSON.stringify({ "method": "connect", "username": this.username, "password": this.auth }))
            }
        }
        this.websocket.onmessage = (event) => {
            const rawData = event.data
            let data
            try {
                data = JSON.parse(rawData)
            } catch (err) {
                // do nothing
            }
            if (data === undefined) {
                return
            }
            switch (data["method"]) {
                case "auth_valid":
                    break
                case "stream_info":
                case "origin_info":
                    this.lastInfo = new Date().getTime()
                    this.handleInfo(data)
                    break
                default:
                    // eslint-disable-next-line @multimediallc/use-custom-console
                    console.warn("Unhandled", data)
            }
        }
        this.websocket.onerror = (event) => {}
        this.websocket.onclose = (event) => {
            if (!this.closed) {
                window.setTimeout(() => {
                    this.start()
                }, 100)
            }
        }
    }

    public isConnected(): boolean {
        if (this.closed) {
            return false
        }
        if (this.websocket === undefined) {
            return false
        }
        return (new Date().getTime() - this.lastInfo) < 5000
    }

    // eslint-disable-next-line complexity
    private handleInfo(data: IStreamData): void {
        let status = getValue(data["status"], "offline")
        const roomPassword = getValue(data["roomPassword"], "")
        if (status === "public" && roomPassword.length > 0) {
            status = "password protected"
        }
        const streamType = getValue(data["streamType"], "obs")
        const forceGraph = Boolean(parseQueryString(window.location.search)["graph"])

        this.roomStatusUpdate.fire(status)

        if (streamType !== "obs" && !forceGraph) {
            let streamTypeDesc = "Unknown"
            switch (streamType) {
                case "webrtc":
                    streamTypeDesc = "WebRTC"
                    break
            }
            this.alternativeStreamEvent.fire(streamTypeDesc)
        }

        if (data["streamName"] !== undefined && (streamType === "obs" || forceGraph)) {
            if (data["streamName"] !== this.streamName) {
                this.streamWatcher.reset()
                this.streamName = data["streamName"]
            } else {
                this.streamWatcher.processData(data)
            }
        } else {
            this.streamWatcher.close()
            if ("remoteHost" in data && data["remoteHost"] !== undefined && "remoteStatus" in data && data["remoteStatus"] !== undefined) {
                if (data["remoteHost"] !== this.origin && data["remoteStatus"] !== "" && data["remoteStatus"] !== "offline") {
                    // @ts-ignore fields populated by django/origin
                    this.update(data["remoteHost"], data["username"], data["originPassword"])
                }
            }
        }
    }

    public getStreamWatcher(): StreamWatcher {
        return this.streamWatcher
    }

    public update(origin: string, username: string, auth: string): void {
        this.origin = origin
        this.username = username
        this.auth = auth
        this.close()
        window.setTimeout(() => {
            this.start()
        }, 10)
    }

    public getOrigin(): string | undefined {
        return this.origin
    }
}
