import { getViewportHeight } from "./mobilelib/viewportDimension"

export function isVirtualKeyboardLikelyShowing(): boolean {
    const isInputFocused = document.activeElement instanceof HTMLInputElement || (document.activeElement as HTMLElement).contentEditable === "true"
    const hasSmallerVisualViewport = Math.round(visualViewport?.height ?? getViewportHeight()) < getViewportHeight()
    return isInputFocused && hasSmallerVisualViewport
}

export function focusMayTriggerVirtualKeyboard(el: HTMLElement): boolean {
    const nonTypingInputTypes = [
        "checkbox",
        "radio",
        "button",
        "reset",
        "submit",
        "file",
    ]

    return (
        el.tagName.toLowerCase() === "textarea" ||
        el.isContentEditable ||
        (el instanceof HTMLInputElement && !nonTypingInputTypes.includes(el.type.toLowerCase()))
    )
}
