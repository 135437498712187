// DefDeck is a custom type of OrderedSet. It is originally designed to handle cycling between tabs, but is
// generic and can probably be used for other purposes.
import { OrderedSet } from "./orderedSet"

export class DefDeck<T> {
    private drawPile = new OrderedSet<T>()
    private discardPile = new OrderedSet<T>()
    currentElem?: T

    addToTop(elem: T): void {
        if (this.currentElem === elem) {
            return
        }
        this.discardPile.remove(elem)
        this.drawPile.addToTop(elem)
    }

    nextElem(): T | undefined {
        const elem = this.drawPile.popFromTop()
        if (this.currentElem !== undefined) {
            this.discardPile.addToTop(this.currentElem)
        }
        this.currentElem = elem
        return elem
    }

    prevElem(): T | undefined {
        const elem = this.discardPile.popFromTop()
        if (this.currentElem !== undefined) {
            this.drawPile.addToTop(this.currentElem)
        }
        this.currentElem = elem
        return elem
    }

    refillFromDiscard(): void {
        for (; ; ) {
            const elem = this.discardPile.popFromBottom()
            if (elem === undefined) {
                break
            }
            this.drawPile.addToBottom(elem)
        }
        if (this.currentElem !== undefined) {
            this.drawPile.addToBottom(this.currentElem)
            this.currentElem = undefined
        }
    }

    emptyDrawIntoDiscard(): void {
        for (; ; ) {
            const elem = this.drawPile.popFromBottom()
            if (elem === undefined) {
                break
            }
            this.discardPile.addToBottom(elem)
        }
        if (this.currentElem !== undefined) {
            this.discardPile.addToBottom(this.currentElem)
            this.currentElem = undefined
        }
    }

    remove(elem: T): void {
        this.discardPile.remove(elem)
        this.drawPile.remove(elem)
        if (this.currentElem === elem) {
            this.currentElem = undefined
        }
    }

    makeCurrent(elem: T): void {
        if (this.currentElem === elem) {
            return
        }
        this.addToTop(elem)
        this.nextElem()
    }
}
