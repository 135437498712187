import { pageContext } from "../cb/interfaces/context"
import { Debouncer, DebounceTypes } from "./debouncer"
import { addPageAction } from "./newrelic"

let blurredAfterFocus = true

// Moved up outside of the file we're using it in so we can guarantee a single instance.
export const focusChatPageActionDebouncer = new Debouncer(
    () => {
        if (pageContext.current.loggedInUser === undefined) {
            // only send if user is anon to mitigate traffic a bit.
            addPageAction("AnonChatInputFocused")
        }
    },
    // Very high bounce limit because we're mostly interested in clicking in,
    // not repeated actions.
    { bounceLimitMS: 1500, debounceType: DebounceTypes.throttle },
)

export const handleFocusedChatInput = (autoBlur = false): void => {
    if (blurredAfterFocus === true) {
        focusChatPageActionDebouncer.callFunc()
        // if we send it a flag, automatically allow the next action to send without blurring.
        // used for the emoji picker, primarily
        if (autoBlur !== true) {
            blurredAfterFocus = false
        }
    }
}

export const handleBlurredChatInput = (): void => {
    blurredAfterFocus = true
}