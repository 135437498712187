// specialoutgoingmessages.ts contains functions to detect if the message a user is sending
// via chat is actually a UI command

import { PrivateMessageSources } from "../cb/api/pm"
import { roomDossierContext } from "../cb/interfaces/context"
import { Shortcode } from "../cb/interfaces/shortcode"
import { i18n } from "./translation"
import type { IShortcodePart } from "./renderMessage"
import type { PrivateMessageSource } from "../cb/api/pm"
import type { IShortcode } from "../cb/interfaces/shortcode"

export interface ITipRequest {
    amount?: number
    message?: string
    usedCtrlS?: boolean
}

export enum OutgoingMessageType {
    ChatMessage = 0,
    TipRequest,
    ToggleDebugMode,
    Shortcode,
    Invalid = ChatMessage,
}

export interface IOutgoingMessage {
    messageType: OutgoingMessageType
}

export interface IShortcodeMessage extends IOutgoingMessage {
    shortcodes: IShortcode[]
    message: string
}

export interface IChatMessage extends IOutgoingMessage {
    messageData: string
}

export interface ITipRequestMessage extends IOutgoingMessage {
    messageData: ITipRequest
}

export interface IOutgoingMessageHandlers {
    onToggleDebugMode(): void
    onChatMessage(message: string): void
    onTipRequest(tipRequest: ITipRequest): void
    onShortcode?(shortcode: IShortcodeMessage): void
}

export interface IRoomMetadata {
    isBroadcaster: boolean
    hasFanClub: boolean
}

// parseTip returns an ITipRequest if this is a tip message, otherwise returns null
function parseDebug(): IOutgoingMessage {
    return { messageType: OutgoingMessageType.ToggleDebugMode }
}

// parseTip returns an ITipRequest if this is a tip message, otherwise returns null
function parseTip(messageParts: string[]): ITipRequestMessage {
    const tipRequest: ITipRequest = {}
    if (messageParts.length > 0) {
        const possibleAmount = Number(messageParts[0])
        if (isNaN(possibleAmount)) {
            tipRequest.message = messageParts.join(" ")
        } else {
            messageParts.shift()
            tipRequest.amount = possibleAmount
            if (messageParts.length > 0) {
                tipRequest.message = messageParts.join(" ")
            }
        }
    }
    return {
        messageData: tipRequest,
        messageType: OutgoingMessageType.TipRequest,
    }
}

const messageParsers = {
    "/tip": parseTip,
    "/debug": parseDebug,
}

export function parseSpecialMessage(message: string): IOutgoingMessage {
    const messageParts = message.match(/(\S+)/g)
    if (messageParts !== null) {
        const action = messageParts.shift() as string
        if (
            Boolean(
                Object.prototype.hasOwnProperty.call(messageParsers, action),
            )
        ) {
            return messageParsers[action as keyof typeof messageParsers](messageParts)
        }
    }
    return { messageType: OutgoingMessageType.Invalid }
}

export function parseOutgoingMessage(
    message: string,
    source = "",
): IOutgoingMessage {
    // Current live site accepts commands like "  /tip"
    if (message.trim().charAt(0) === "/") {
        const outputMessage = parseSpecialMessage(message)
        if (outputMessage.messageType !== OutgoingMessageType.Invalid) {
            return outputMessage
        }
    } else if (
        ShortcodeParser.isShortcodeSyntax(message, source)
    ) {
        return ShortcodeParser.parseShortcodeMessage(message)
    }
    return {
        messageType: OutgoingMessageType.ChatMessage,
        messageData: message,
    } as IChatMessage
}

function isValidNumberString(input: string): boolean {
    const isRegularNumber = new RegExp(/^(\d{1,4})$/)
    const hasThousandSeperator = new RegExp(/^(\d{1}[\.,]\d{3})$/)
    const hasDecimalSeperator = new RegExp(/^[\.,]\d{1,2}$/)

    const isValidNumber =
        isRegularNumber.test(input) || hasThousandSeperator.test(input)
    const hasDecimal = hasDecimalSeperator.test(input)
    if (hasDecimal) {
        return false
    }

    return isValidNumber
}

export class ShortcodeParser {
    static readonly shortcodePrefix = "[cb:"
    static readonly shortcodeSuffix = "]"
    // within shortcodes, allow everything except braces and ignore anything in between quotes for tip message argument
    static readonly shortcodeRegex = /(\[cb:(?:[^\]]){0,100}\])/gi
    static readonly nonGlobalShortcodeRegex = /(\[cb:(?:[^\]]){0,100}\])/i
    // strict regex checks for valid shortcode and argument syntax
    // tip shortcode can have arguments in any order so need A and B
    static readonly shortcodeStrictRegexA =
        /\[cb:([a-z]+)((\s[a-z]+?=[\d,\.]*){0,1})((\s[a-z]+?=("|“)([^\]"“”]*?)("|”))){0,1}\]/gi
    static readonly shortcodeStrictRegexB =
        /\[cb:([a-z]+)((\s[a-z]+?=("|“)([^\]"“”]*?)("|”))){0,1}((\s[a-z]+?=[\d,\.]*){0,1})\]/gi
    // regex for normalizing shortcodes excluding tip
    static readonly shortcodeNormalizeRegex =
        /\[cb:(follow|signup|fanclub|supporter|help)\]/gi

    // REGEX FOR TIP SHORTCODE
    // check for any value included with arguments regardless of validity
    static readonly amountAnyRegex = /amount=[^\s\]]+/i
    static readonly messageAnyRegex = /message=[^\s\]]+/i
    // check for url in message which makes the message invalid
    static readonly urlRegex = /\b(?:https?:\/\/)?(?:www\.)?[^\/\s]+\.[a-zA-Z]{2,}\b/gi
    // check for usage of unsuppported quotes which makes the message invalid
    static readonly messageRegexSingleQuote = /(message=)(?:['|‘|’](?:[^\]"“”]+)['|‘|’]|['|‘|’](?:[^\]"“”]+)["|“|”]|["|“|”](?:[^\]"“”]+)['|‘|’])/i
    static readonly messageInvalidQuotesRegex = /(message=)(?:"[^\]"“”]*[“”])|(?:“[^\]"“”]*["“])/i
    // check for argument validity with more strict regex
    static readonly amountRegex = /amount=([,.\d]*)/i
    static readonly messageStrictRegex = /(message=)("|“)(?<message>[^\]"“”]*)("|”)/i

    // Pulls the code from a shortcode - capture anything following : until whitespace or ]
    static readonly codeRegex = /\[cb:([^\s\]]+)(?:[^\]]*)\]/i
    static getCode(shortcode: string): Shortcode | undefined {
        const match = shortcode.match(ShortcodeParser.codeRegex)
        if (match !== null) {
            // codeRegex is case-insensitive so check against lower
            switch (match[1].toLowerCase()) {
                case Shortcode.Signup:
                    return Shortcode.Signup
                case Shortcode.Follow:
                    return Shortcode.Follow
                case Shortcode.Fanclub:
                    return Shortcode.Fanclub
                case Shortcode.Supporter:
                    return Shortcode.Supporter
                case Shortcode.Tip:
                    return Shortcode.Tip
                case Shortcode.Help:
                    return Shortcode.Help
                default:
                    return undefined
            }
        }
        return undefined
    }

    static getShortcodeTitle(shortcodePart: IShortcodePart): string {
        // returns the shortcode normalized (except message arg)
        // and with whitespace removed around message
        const code = shortcodePart.code
        const msg = shortcodePart.msg?.trim()
        const amt = shortcodePart.amt?.toString()
        if (code === Shortcode.Tip && amt !== undefined && msg !== undefined) {
            return `[cb:${code} amount=${amt} message="${msg}"]`
        }
        return `[cb:${code}]`
    }

    static isShortcodePrefix(prefix: string): boolean {
        // check if provided string is shortcode prefix, case-insensitive
        return prefix.toLowerCase() === ShortcodeParser.shortcodePrefix
    }

    static hasShortcodes(message: string): boolean {
        return message.match(ShortcodeParser.shortcodeRegex) !== null
    }

    /**
     * Checks if a shortcode was typed even if not a valid shortcode
     * @param message
     * @param source - where the message came from, "roomChat" or "pm"
     * @returns boolean
     */
    static isShortcodeSyntax(message: string, source = ""): boolean {
        if (PrivateMessageSources.includes(source as PrivateMessageSource)) {
            return false
        }

        return message.match(this.shortcodeRegex) !== null
    }

    static isValidTipShortcodeSyntax(input: string): boolean {
        const shortcodePatternI = new RegExp(
            /\[cb:tip amount=([\d,.]*) message=(("[^\]"“”]+")|(""|“”)|(“[^\]"“”]+”))\]/i,
        )
        const shortcodePatternII = new RegExp(
            /\[cb:tip message=(("[^\]"“”]+")|(""|“”)|(“[^\]"“”]+”)) amount=([\d,.]*)\]/i,
        )

        return shortcodePatternI.test(input) || shortcodePatternII.test(input)
    }

    static pullRoomMetadata(): IRoomMetadata {
        const dossier = roomDossierContext.getState()
        const { hasFanClub, room, userName } = dossier
        const isBroadcaster = userName === room
        return {
            hasFanClub,
            isBroadcaster,
        }
    }

    static isValidShortcode(shortcode: string): boolean {
        return shortcode.match(ShortcodeParser.shortcodeStrictRegexA) !== null ||
               shortcode.match(ShortcodeParser.shortcodeStrictRegexB) !== null
    }

    static isValidTipShortcode(shortcode: string): boolean {
        if (!ShortcodeParser.isValidTipShortcodeSyntax(shortcode)){
            return false
        }
        // Check if the tip amount is valid
        const amount = shortcode.match(ShortcodeParser.amountRegex)
        if (amount !== null) {
            const amountString = amount[0].split("=")[1]
            if (!isValidNumberString(amountString)) {
                return false
            }
        }
        // Check if the tip message has no URLs/links
        const message = shortcode.match(ShortcodeParser.messageStrictRegex)
        if (message !== null) {
            const messageString = message[0].split("=")[1]
            if (messageString.match(ShortcodeParser.urlRegex)) {
                return false
            }
        }

        return true
    }

    static isValidShortcodeForRoom(shortcode: string): boolean {
        const { hasFanClub, isBroadcaster } = ShortcodeParser.pullRoomMetadata()
        if (!ShortcodeParser.isValidShortcode(shortcode)) {
            return false
        }
        const code = ShortcodeParser.getCode(shortcode)
        switch (code) {
            case Shortcode.Tip:
                return ShortcodeParser.isValidTipShortcode(shortcode) && isBroadcaster
            case Shortcode.Fanclub:
                return /(\[cb:fanclub\])/i.test(shortcode) && hasFanClub
            case Shortcode.Follow:
            case Shortcode.Supporter:
            case Shortcode.Signup:
            case Shortcode.Help:
                return new RegExp(`\\[cb:${code}\\]`, "i").test(shortcode)
            default:
                return false
        }
    }

    static isValidShortcodeMessage(message: string): boolean {
        const shortcodes = message.match(ShortcodeParser.shortcodeRegex)
        return (
            shortcodes?.every((sc) =>
                ShortcodeParser.isValidShortcodeForRoom(sc),
            ) ?? false
        )
    }

    static getShortcodeForPart(shortcode: string, shortcodes: IShortcode[]): IShortcode | undefined {
        // get shortcode object corresponding to provided shortcode string
        const code = ShortcodeParser.getCode(shortcode)
        if (code !== undefined) {
            switch (code) {
                case Shortcode.Tip:
                    if (!ShortcodeParser.isValidTipShortcodeSyntax(shortcode)) {
                        break
                    }
                    return shortcodes.find((s) => {
                        if (s.code !== Shortcode.Tip) {
                            return false
                        }
                        const hasSameAmt = s.amt === ShortcodeParser.getAmountWithoutSeparators(shortcode)
                        const hasSameMsg = s.msg === ShortcodeParser.getMessageContents(shortcode)
                        return hasSameAmt && hasSameMsg
                    })
                default:
                    return shortcodes.find((s) => s.code === code)
            }
        }
        return undefined
    }

    static getAmountWithoutSeparators(tipShortcode: string): number {
        const amount = tipShortcode.match(ShortcodeParser.amountRegex)
        if (amount !== null) {
            const amountString = amount[0].split("=")[1]
            if (isValidNumberString(amountString)) {
                return Number(amountString.replace(/\./gi, "").replace(/,/gi, ""))
            }
        }
        return 0
    }

    static getMessageContents(tipShortcode: string): string {
        const message = tipShortcode.match(ShortcodeParser.messageStrictRegex)
        if (message !== null && message.groups?.message !== undefined) {
            // backend form strips whitespace so trim to match
            return message.groups["message"].trim()
        }
        return ""
    }

    static parseShortcodeMessage(message: string): IShortcodeMessage { // eslint-disable-line complexity
        const validShortcodes = []
        if (!ShortcodeParser.isValidShortcodeMessage(message)) {
            // Return empty shortcodes
            return {
                messageType: OutgoingMessageType.Shortcode,
                shortcodes: [],
                message: message,
            } as IShortcodeMessage
        }

        const shortcodes = message.match(ShortcodeParser.shortcodeRegex)
        if (shortcodes !== null) {
            for (const shortcode of shortcodes) {
                const code = ShortcodeParser.getCode(shortcode)
                if (code !== undefined) {
                    if (code === Shortcode.Tip) {
                        const amt = shortcode.match(ShortcodeParser.amountRegex)
                        const msg = shortcode.match(
                            ShortcodeParser.messageStrictRegex,
                        )
                        let parsed_msg = ""
                        if(msg && msg.groups){
                            parsed_msg = msg.groups["message"]
                        }

                        if (amt !== null && msg !== null) {
                            const parsed_amt = amt[0]
                                .split("=")[1]
                                .replace(/\./gi, "")
                                .replace(/,/gi, "")
                            validShortcodes.push({
                                code: Shortcode.Tip,
                                amt: Number(parsed_amt),
                                msg: parsed_msg,
                            })
                        }
                    } else {
                        validShortcodes.push({ code: code })
                    }
                }
            }
        }

        if (validShortcodes.length > 5) {
            // Don't allow if more than 5 shortcodes per message
            // If more is added, return empty for error handling
            return {
                messageType: OutgoingMessageType.Shortcode,
                shortcodes: [],
                message: message,
            } as IShortcodeMessage
        }

        // normalize shortcodes to prevent sending duplicates (except tip shortcode since restricted to broadcaster)
        message = message.replace(this.shortcodeNormalizeRegex, (shortcode) => {
            return shortcode.toLowerCase()
        })
        return {
            messageType: OutgoingMessageType.Shortcode,
            shortcodes: validShortcodes,
            // Trim the message of any extra spaces so similar messages
            // register as spam and prevent duplicates from being published
            message: message.trim(),
        } as IShortcodeMessage
    }

    static errorBehindShortcode(message: string): string { // eslint-disable-line complexity
        const { hasFanClub, isBroadcaster } =
            ShortcodeParser.pullRoomMetadata()

        const shortcodes = message.match(ShortcodeParser.shortcodeRegex)
        if (shortcodes !== null) {
            if (shortcodes.length > 5) {
                return i18n.tooManyShortcodes
            }

            for (const shortcode of shortcodes) {
                if (
                    !hasFanClub &&
                    new RegExp(/\[cb:fanclub\]/i).test(message)
                ) {
                    return i18n.noFanClub
                }
                if (ShortcodeParser.getCode(shortcode) === Shortcode.Tip) {
                    const tipError = ShortcodeParser.errorBehindTipShortcode(
                        shortcode,
                        isBroadcaster,
                    )
                    if (tipError !== undefined) {
                        return tipError
                    }
                }
            }
        }
        return i18n.shortcodeEnteredError(message)
    }

    static hasTipArguments(shortcode: string): boolean {
        // check that tip shortcode arguments are
        // present without checking value or validity
        let scWithoutQuotedContent = ""
        let inQuotes = false
        for (const char of shortcode) {
            if (
                char === "'" ||
                char === "‘" ||
                char === "’" ||
                char === '"' ||
                char === "“" ||
                char === "”"
            ){
                // ignore content between quotes
                inQuotes = !inQuotes
                continue
            }
            if (inQuotes) {
                continue
            }
            scWithoutQuotedContent += char
        }
        return new RegExp(/amount=/).test(scWithoutQuotedContent) && new RegExp(/message=/).test(scWithoutQuotedContent)
    }

    static hasErrorInMessageArg(shortcode: string): boolean {
        return !ShortcodeParser.messageStrictRegex.test(shortcode) ||
                ShortcodeParser.messageRegexSingleQuote.test(shortcode) ||
                ShortcodeParser.messageInvalidQuotesRegex.test(shortcode)
    }

    static errorBehindTipShortcode(
        shortcode: string,
        isBroadcaster: boolean,
    ): string | undefined {
        if (!isBroadcaster) {
            return i18n.tipNotBroadcaster
        }
        // check arguments present and any value is included
        if (!ShortcodeParser.hasTipArguments(shortcode) ||
            !ShortcodeParser.amountAnyRegex.test(shortcode) ||
            !ShortcodeParser.messageAnyRegex.test(shortcode)) {
            return i18n.tipShortcodeArgsMissing
        }
        // Check for invalid amount and give error
        const amount = shortcode.match(ShortcodeParser.amountAnyRegex)
        if (amount !== null) {
            const amountString = amount[0].split("=")[1]
            if (!isValidNumberString(amountString)) {
                return i18n.inValidTipAmount
            }
        }
        // Check for invalid message and give error
        if (ShortcodeParser.hasErrorInMessageArg(shortcode)) {
            return i18n.tipShortcodeMessageInDoubleQuotes
        }
        const url = shortcode.match(ShortcodeParser.urlRegex)
        if (url !== null) {
            return i18n.shortcodeURLNotAllowedInTip
        }

        // no error found
        return
    }
}
