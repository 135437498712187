import { documentVisibilityChange, isDocumentVisible } from "../common/windowUtils"

// This will not work if the browser tab is the window's
// active tab, even if the window itself isn't active.

export function inactiveTabCheck(): void {
    window.setTimeout(() => {
        if (!isDocumentVisible()) {
            location.reload()
        }
        documentVisibilityChange.listen(() => { location.reload() })
    }, 172800000) // 172800 seconds => 48 hours
}
