import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "./api"

interface IEmojiAPI {
    "character": string
    "name": string
}
export interface IEmoji {
    value: string
    name: string
}
export const enum EmojiGroups {
    recent = "recent",
    smileysAndPeople = "smileys-people",
    animalsAndNature = "animals-nature",
    foodAndDrink = "food-drink",
    activities = "activities",
    travelAndPlaces = "travel-places",
    objects = "objects",
    symbols = "symbols",
    flags = "flags",
}

let emojisPromise: Promise<[IEmoji[], ArgJSONMap]> | undefined
let recentEmojis: IEmoji[] = []
const maxRecentEmojis = 18
const recentEmojiStorageKey = "emojiRecents"
const recentEmojiStorageDelimiter = "|"

export function emojiStringToArray(text: string): string[] {
    // Converts a string to array while preserving surrogate pair symbols like emojis.
    // Array.from() would work but IE doesn't support it and the standard polyfill splits surrogate pairs
    const match = text.match(/[^\uD800-\uDFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDFFF]/g)
    if (match !== null) {
        return match
    } else {
        return []
    }
}

// Find the first maximal substring that parses to a single emoji
export function findEmojiInString(text: string): { emojiStart: number, emojiChars: string } | undefined {
    if (!twemoji.test(text)) {
        return undefined
    }
    let emojiStart = -1
    let emojiChars = ""
    let potentialChars = ""
    let charIdx = 0
    for (const char of emojiStringToArray(text)) {
        if (emojiStart === -1 && twemoji.test(char)) {
            emojiStart = charIdx
            emojiChars = char
            potentialChars = char
        } else if (emojiStart !== -1) {
            potentialChars = `${potentialChars}${char}`
            // Single emojis can have `twemoji.test -> false` chars in the middle but not as the last char
            if (twemoji.test(char) && isSingleTwemoji(twemoji.parse(potentialChars))) {
                emojiChars = potentialChars
            }
        }
        charIdx += char.length
    }
    if (emojiStart !== -1) {
        return { emojiStart: emojiStart, emojiChars: emojiChars }
    }
    return undefined
}

export function isSingleTwemoji(parseResult: string): boolean {
    const imgMatches = parseResult.match(/<img/g)
    return imgMatches !== null && imgMatches.length === 1
}

export function recordRecentEmoji(emojiData: IEmoji): IEmoji[] {
    for (let i = 0; i < recentEmojis.length; i += 1) {
        if (recentEmojis[i].name === emojiData.name) {
            recentEmojis.splice(i, 1)
        }
    }
    if (recentEmojis.length === maxRecentEmojis) {
        recentEmojis.pop()
    }
    recentEmojis.unshift(emojiData)
    const recentEmojisString = recentEmojis.map((emoji) => {
        return JSON.stringify({ "value": emoji.value, "name": emoji.name })
    }).join(recentEmojiStorageDelimiter)
    window.localStorage.setItem(recentEmojiStorageKey, recentEmojisString)
    return recentEmojis
}

function loadRecentEmojis(): Promise<IEmoji[]> {
    return new Promise((resolve) => {
        const recentEmojisString = window.localStorage.getItem(recentEmojiStorageKey)
        if (recentEmojisString !== null) {
            const recentEmojis = recentEmojisString.split(recentEmojiStorageDelimiter)
            resolve(recentEmojis.map((emoji: string) => {
                const storedEmoji = JSON.parse(emoji)
                return { value: storedEmoji["value"], name: storedEmoji["name"] }
            }))
        } else {
            resolve([])
        }
    })
}

export function loadAllEmojis(): void {
    const recentEmojisPromise = loadRecentEmojis()
    const allEmojisPromise = getCb("api/ts/emoticons/search_emojis/")
    emojisPromise = Promise.all([recentEmojisPromise, allEmojisPromise]).then(([loadedRecentEmojis, allEmojisXhr]) => {
        recentEmojis = loadedRecentEmojis
        return [recentEmojis, new ArgJSONMap(allEmojisXhr.responseText)]
    })
}

export function getEmojiGroup(group: EmojiGroups): Promise<IEmoji[]> {
    if (emojisPromise === undefined) {
        loadAllEmojis()
    }
    if (emojisPromise !== undefined) {
        return emojisPromise.then(([recentEmojis, allEmojis]) => {
            if (group === EmojiGroups.recent) {
                return recentEmojis
            } else if (group === EmojiGroups.smileysAndPeople) {
                const smileys = allEmojis.getAny("smileys-emotion") as IEmojiAPI[]
                const people = allEmojis.getAny("people-body") as IEmojiAPI[]
                return translateAPIEmojiGroup(smileys.concat(people))
            } else {
                return translateAPIEmojiGroup((allEmojis.getAny(group) as IEmojiAPI[]))
            }
        })
    }

    // Unreachable
    return Promise.reject()
}

export function searchEmojis(searchStr: string): Promise<IEmoji[]> {
    return getCb(`api/ts/emoticons/search_emojis/?keywords=${encodeURIComponent(searchStr)}`).then((xhr) => {
        const resultEmojis = new ArgJSONMap(xhr.responseText)
        return [
            "smileys-emotion",
            "people-body",
            EmojiGroups.animalsAndNature,
            EmojiGroups.foodAndDrink,
            EmojiGroups.activities,
            EmojiGroups.travelAndPlaces,
            EmojiGroups.objects,
            EmojiGroups.symbols,
            EmojiGroups.flags,
        ].map((group) => {
            return resultEmojis.getAny(group) as IEmojiAPI[] | undefined
        })
            .filter((group) => group !== undefined)
            .map((group) => translateAPIEmojiGroup(group as IEmojiAPI[]))
            .reduce((acc, curr) => acc.concat(curr))
    }).catch(() => {
        return []
    })
}

function translateAPIEmojiGroup(emojis: IEmojiAPI[]): IEmoji[] {
    return emojis.map((emoji) => {
        return { value: emoji["character"], name: emoji["name"] }
    })
}
