import { addColorClass } from "../cb/colorClasses"
import { appPanelHeight, appPanelWidth } from "./appPanel"
import type { ArgJSONMap } from "@multimediallc/web-utils"

interface IAppResponse {
    name: string
    template: string
    firstRowLabel: string
    secondRowLabel: string
    thirdRowLabel: string
    firstRowValue: string
    secondRowValue: string
    thirdRowValue: string
}

function setContent(cell: HTMLTableCellElement, content: string) {
    const contentContainer = document.createElement("div")
    contentContainer.innerText = content
    contentContainer.style.overflow = "hidden"
    contentContainer.style.maxHeight = "21px"
    cell.appendChild(contentContainer)
}

export function templateHTML(rsp?: ArgJSONMap): HTMLTableElement {
    if (rsp === undefined) {
        return createLegacyPanel()
    }
    const formattedResponse = {
        name: rsp.getString("name", false),
        template: rsp.getString("template", false),
        firstRowLabel: rsp.getStringWithNumbers("row1_label", false),
        secondRowLabel: rsp.getStringWithNumbers("row2_label", false),
        thirdRowLabel: rsp.getStringWithNumbers("row3_label", false),
        firstRowValue: rsp.getStringWithNumbers("row1_value", false),
        secondRowValue: rsp.getStringWithNumbers("row2_value", false),
        thirdRowValue: rsp.getStringWithNumbers("row3_value", false),
    }
    return createLegacyPanel(formattedResponse)
}

export function createLegacyPanel(r?: IAppResponse): HTMLTableElement {
    const rowOne = document.createElement("tr")
    const rowTwo = document.createElement("tr")
    const rowThree = document.createElement("tr")

    if (r !== undefined) {
        const template = r.template
        const labels: HTMLTableHeaderCellElement[] = []
        const values: HTMLTableDataCellElement[] = []

        if (r.firstRowLabel !== "") {
            const rowOneLabel = document.createElement("th")
            rowOneLabel.style.padding = "2px"
            setContent(rowOneLabel, r.firstRowLabel)
            labels.push(rowOneLabel)
            rowOne.appendChild(rowOneLabel)
            const rowOneValue = document.createElement("td")
            setContent(rowOneValue, r.firstRowValue)
            rowOne.appendChild(rowOneValue)
            values.push(rowOneValue)
            rowOneValue.style.width = "50%"
            rowOneValue.style.paddingLeft = "10px"
        } else {
            const rowOneValue = document.createElement("td")
            setContent(rowOneValue, r.firstRowValue)
            rowOneValue.colSpan = 2
            values.push(rowOneValue)
            rowOne.appendChild(rowOneValue)
            rowOneValue.style.textAlign = "center"
        }
        if (r.secondRowLabel !== "") {
            const rowTwoLabel = document.createElement("th")
            rowTwoLabel.style.padding = "2px"
            rowTwoLabel.style.fontWeight = "bold"
            setContent(rowTwoLabel, r.secondRowLabel)
            labels.push(rowTwoLabel)
            rowTwo.appendChild(rowTwoLabel)
            const rowTwoValue = document.createElement("td")
            setContent(rowTwoValue, r.secondRowValue)
            values.push(rowTwoValue)
            rowTwo.appendChild(rowTwoValue)
            rowTwoValue.style.width = "50%"
            rowTwoValue.style.paddingLeft = "10px"
        } else {
            const rowTwoValue = document.createElement("td")
            setContent(rowTwoValue, r.secondRowValue)
            rowTwoValue.colSpan = 2
            values.push(rowTwoValue)
            rowTwo.appendChild(rowTwoValue)
            rowTwoValue.style.textAlign = "center"
        }
        if (r.thirdRowLabel !== "") {
            const rowThreeLabel = document.createElement("th")
            rowThreeLabel.style.padding = "2px"
            setContent(rowThreeLabel, r.thirdRowLabel)
            labels.push(rowThreeLabel)
            rowThree.appendChild(rowThreeLabel)
            const rowThreeValue = document.createElement("td")
            setContent(rowThreeValue, r.thirdRowValue)
            values.push(rowThreeValue)
            rowThree.appendChild(rowThreeValue)
            rowThreeValue.style.width = "50%"
            rowThreeValue.style.paddingLeft = "10px"
        } else {
            const rowThreeValue = document.createElement("td")
            setContent(rowThreeValue, r.thirdRowValue)
            rowThreeValue.colSpan = 2
            values.push(rowThreeValue)
            rowThree.appendChild(rowThreeValue)
            rowThreeValue.style.textAlign = "center"
        }

        for (const label of labels) {
            label.style.overflow = "hidden"
            label.style.display = "block"
            label.style.cssFloat = "right"
        }

        if (template === "3_rows_of_labels") {
            addColorClass(labels[0], "threeRowsOfLabels")
            labels[0].style.display = "block"
            labels[0].style.margin = "0px auto"
            labels[0].style.height = "16px"
            labels[0].style.fontWeight = "bold"
        } else if (template === "3_rows_11_21_31" || template === "3_rows_11_22_32") {
            addColorClass(values[0], "threeRows-11-21-31")
            values[0].style.fontWeight = "bold"
        }
    } else {
        const tempTd = document.createElement("td")
        rowOne.appendChild(tempTd.cloneNode())
        rowTwo.appendChild(tempTd.cloneNode())
        rowThree.appendChild(tempTd.cloneNode())
    }

    addColorClass(rowOne, "rowOne")
    rowOne.style.height = "33.3333%"
    addColorClass(rowTwo, "rowTwo")
    rowTwo.style.height = "33.3333%"
    addColorClass(rowThree, "rowThree")
    rowThree.style.height = "33.3333%"

    const appPanelTable = document.createElement("table")
    appPanelTable.id = "AppPanelTable"
    appPanelTable.style.fontSize = "11px"
    appPanelTable.style.lineHeight = "1.7em"
    appPanelTable.style.width = `${appPanelWidth}px`
    appPanelTable.style.height = `${appPanelHeight}px`
    appPanelTable.style.borderCollapse = "collapse"
    appPanelTable.style.margin = "0 auto"
    appPanelTable.style.tableLayout = "fixed"
    appPanelTable.appendChild(rowOne)
    appPanelTable.appendChild(rowTwo)
    appPanelTable.appendChild(rowThree)

    return appPanelTable
}
