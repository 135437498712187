import { Component } from "../defui/component"
import { EventRouter } from "../events"

export interface IMenuLinkDrawerComponent {
    new (a: HTMLAnchorElement): MenuLinkDrawerComponent
}

export abstract class MenuLinkDrawerComponent extends Component<HTMLDivElement> {
    protected drawerContainer = document.createElement("div")
    protected drawer: HTMLDivElement | undefined
    public drawerShown = new EventRouter<undefined>("drawerShown")

    protected constructor(protected menuLink: HTMLAnchorElement) {
        super()
        this.element.style.position = "relative"

        this.element.appendChild(this.menuLink)
        this.element.appendChild(this.drawerContainer)
        this.drawerContainer.style.display = "none"

        this.menuLink.onclick = (event: MouseEvent) => {
            if (this.drawer !== undefined && this.drawer.style.display === "block") {
                this.toggleDrawer(false, false)
            } else {
                this.toggleDrawer(true, false)
            }
        }
    }

    protected toggleDrawer(show: boolean, disposeOld: boolean): void {
        if (disposeOld) {
            this.disposeDrawer()
        }
        if (show) {
            if (this.drawer === undefined) {
                this.initializeDrawer()
            }
            this.addOrShowDrawer()
        } else {
            this.hideDrawer()
        }
    }

    protected abstract initializeDrawer(): void

    protected addOrShowDrawer(): void {
        if (this.drawer !== undefined) {
            if (this.drawer.parentElement !== this.drawerContainer) {
                this.drawerContainer.appendChild(this.drawer)
            }
            this.drawer.style.display = "block"
            this.drawerContainer.style.display = "block"
            this.drawerShown.fire(undefined)
        }
    }

    protected disposeDrawer(): void {
        if (this.drawer !== undefined) {
            if (this.drawer.parentElement === this.drawerContainer) {
                this.drawerContainer.removeChild(this.drawer)
            }
            this.drawer = undefined
        }
        this.drawerContainer.style.display = "none"
    }

    public hideDrawer(): void {
        if (this.drawer !== undefined) {
            this.drawer.style.display = "none"
        }
        this.drawerContainer.style.display = "none"
    }

    public setDrawerColor(color: string): void {
        this.menuLink.style.color = color
    }

    public getDrawerContainer(): HTMLDivElement {
        return this.drawerContainer
    }
}
