import { applyCoords, ICoords, moveBottomEdge, moveLeftEdge, moveRightEdge, moveTopEdge, scanCoords } from "../coords"
import { Debouncer } from "../debouncer"
import { addDragListener } from "../dragListener"
import { Component } from "./component"

interface IResizeOptions {
    componentToOverlay: Component
    minHeight: number
    minWidth: number
    maxHeight: () => number
    maxWidth: () => number
    onStart?: () => void
    onStop?: () => void
}

export class ResizeHandles extends Component {
    private listener: () => void
    private componentToOverlay: Component

    constructor(options: Readonly<IResizeOptions>) {
        super()
        // 0 height/width so we don't block other page elements
        this.element.style.height = "0"
        this.element.style.width = "0"
        this.element.style.overflow = "visible"
        this.element.style.position = "fixed"
        this.element.style.top = "0"
        this.element.style.left = "0"
        this.componentToOverlay = options.componentToOverlay
        this.listener = () => {
            this.repositionChildrenRecursive()
        }
        this.componentToOverlay.didRepositionEvent.listen(this.listener)
        this.addChild(new DragHandleN(this, options))
        this.addChild(new DragHandleS(this, options))
        this.addChild(new DragHandleW(this, options))
        this.addChild(new DragHandleE(this, options))
        this.addChild(new DragHandleNW(this, options))
        this.addChild(new DragHandleNE(this, options))
        this.addChild(new DragHandleSW(this, options))
        this.addChild(new DragHandleSE(this, options))
    }

    protected repositionChildren(): void {
    }

    public prepareToRemove(): void {
        this.componentToOverlay.didRepositionEvent.removeListener(this.listener)
    }
}

interface IMoveVector {
    x: number
    y: number
}

abstract class DragHandle extends Component {
    protected thickness = 8

    constructor(protected holder: ResizeHandles, protected options: IResizeOptions, protected cursor: string) {
        super()
        this.element.style.height = `${this.thickness}px`
        this.element.style.width = `${this.thickness}px`
        this.element.style.cursor = this.cursor
        addDragListener(this.element, (ev: Event, x: number, y: number) => {
            ev.stopPropagation()
            if (options.onStart !== undefined) {
                options.onStart()
            }
            const mouseStartX = x
            const mouseStartY = y
            const startingCoords = Object.freeze(scanCoords(options.componentToOverlay.element))
            const moveDebouncer = new Debouncer(() => {
                options.componentToOverlay.repositionChildrenRecursive()
            }, { bounceLimitMS: 10 })
            return {
                enabled: true,
                move: (x: number, y: number) => {
                    applyCoords(this.moveEdges(startingCoords, {
                        x: x - mouseStartX,
                        y: y - mouseStartY,
                    }), options.componentToOverlay.element)
                    moveDebouncer.callFunc()
                },
                end: () => {
                    if (options.onStop !== undefined) {
                        options.onStop()
                    }
                },
            }
        })
    }

    protected abstract moveEdges(startingCoords: Readonly<ICoords>, m: IMoveVector): ICoords
}

class DragHandleN extends DragHandle {
    constructor(holder: ResizeHandles, options: IResizeOptions) {
        super(holder, options, "ns-resize")
    }

    repositionChildren(): void {
        const rect = this.options.componentToOverlay.element.getBoundingClientRect()
        this.element.style.top = `${rect.top - this.thickness / 2}px`
        this.element.style.left = `${rect.left}px`
        this.element.style.width = `${rect.right - rect.left}px`
    }

    protected moveEdges(startingCoords: Readonly<ICoords>, m: IMoveVector): ICoords {
        return moveTopEdge(startingCoords, m.y, this.options.minHeight)
    }
}

class DragHandleS extends DragHandle {
    constructor(holder: ResizeHandles, options: IResizeOptions) {
        super(holder, options, "ns-resize")
    }

    repositionChildren(): void {
        const rect = this.options.componentToOverlay.element.getBoundingClientRect()
        this.element.style.top = `${rect.top + rect.height - this.thickness / 2}px`
        this.element.style.left = `${rect.left}px`
        this.element.style.width = `${rect.width}px`
    }

    protected moveEdges(startingCoords: Readonly<ICoords>, m: IMoveVector): ICoords {
        return moveBottomEdge(startingCoords, m.y, this.options.minHeight, this.options.maxHeight())
    }
}

class DragHandleW extends DragHandle {
    constructor(holder: ResizeHandles, options: IResizeOptions) {
        super(holder, options, "ew-resize")
    }

    repositionChildren(): void {
        const rect = this.options.componentToOverlay.element.getBoundingClientRect()
        this.element.style.top = `${rect.top}px`
        this.element.style.left = `${rect.left - this.thickness / 2}px`
        this.element.style.height = `${rect.bottom - rect.top}px`
    }

    protected moveEdges(startingCoords: Readonly<ICoords>, m: IMoveVector): ICoords {
        return moveLeftEdge(startingCoords, m.x, this.options.minWidth)
    }
}

class DragHandleE extends DragHandle {
    constructor(holder: ResizeHandles, options: IResizeOptions) {
        super(holder, options, "ew-resize")
    }

    repositionChildren(): void {
        const rect = this.options.componentToOverlay.element.getBoundingClientRect()
        this.element.style.top = `${rect.top}px`
        this.element.style.left = `${rect.right - this.thickness / 2}px`
        this.element.style.height = `${rect.bottom - rect.top}px`
    }

    protected moveEdges(startingCoords: Readonly<ICoords>, m: IMoveVector): ICoords {
        return moveRightEdge(startingCoords, m.x, this.options.minWidth, this.options.maxWidth())
    }
}

class DragHandleNW extends DragHandle {
    constructor(holder: ResizeHandles, options: IResizeOptions) {
        super(holder, options, "nwse-resize")
    }

    repositionChildren(): void {
        const rect = this.options.componentToOverlay.element.getBoundingClientRect()
        this.element.style.top = `${rect.top - this.thickness + this.thickness / 2}px`
        this.element.style.left = `${rect.left - this.thickness + this.thickness / 2}px`
    }

    protected moveEdges(startingCoords: Readonly<ICoords>, m: IMoveVector): ICoords {
        const topMoveCoords = moveTopEdge(startingCoords, m.y, this.options.minHeight)
        const leftMoveCoords = moveLeftEdge(startingCoords, m.x, this.options.minWidth)
        return {
            top: topMoveCoords.top, height: topMoveCoords.height,
            left: leftMoveCoords.left, width: leftMoveCoords.width,
        }
    }
}

class DragHandleNE extends DragHandle {
    constructor(holder: ResizeHandles, options: IResizeOptions) {
        super(holder, options, "nesw-resize")
    }

    repositionChildren(): void {
        const rect = this.options.componentToOverlay.element.getBoundingClientRect()
        this.element.style.top = `${rect.top - this.thickness + this.thickness / 2}px`
        this.element.style.left = `${rect.right - this.thickness / 2}px`
    }

    protected moveEdges(startingCoords: Readonly<ICoords>, m: IMoveVector): ICoords {
        const topMoveCoords = moveTopEdge(startingCoords, m.y, this.options.minHeight)
        const rightMoveCoords = moveRightEdge(startingCoords, m.x, this.options.minWidth, this.options.maxWidth())
        return {
            top: topMoveCoords.top, height: topMoveCoords.height,
            left: rightMoveCoords.left, width: rightMoveCoords.width,
        }
    }
}

class DragHandleSW extends DragHandle {
    constructor(holder: ResizeHandles, options: IResizeOptions) {
        super(holder, options, "nesw-resize")
    }

    repositionChildren(): void {
        const rect = this.options.componentToOverlay.element.getBoundingClientRect()
        this.element.style.top = `${rect.bottom - this.thickness / 2}px`
        this.element.style.left = `${rect.left - this.thickness + this.thickness / 2}px`
    }

    protected moveEdges(startingCoords: Readonly<ICoords>, m: IMoveVector): ICoords {
        const bottomMoveCoords = moveBottomEdge(startingCoords, m.y, this.options.minHeight, this.options.maxHeight())
        const leftMoveCoords = moveLeftEdge(startingCoords, m.x, this.options.minWidth)
        return {
            top: bottomMoveCoords.top, height: bottomMoveCoords.height,
            left: leftMoveCoords.left, width: leftMoveCoords.width,
        }
    }
}

class DragHandleSE extends DragHandle {
    constructor(holder: ResizeHandles, options: IResizeOptions) {
        super(holder, options, "nwse-resize")
    }

    repositionChildren(): void {
        const rect = this.options.componentToOverlay.element.getBoundingClientRect()
        this.element.style.top = `${rect.bottom - this.thickness / 2}px`
        this.element.style.left = `${rect.right - this.thickness / 2}px`
    }

    protected moveEdges(startingCoords: Readonly<ICoords>, m: IMoveVector): ICoords {
        const bottomMoveCoords = moveBottomEdge(startingCoords, m.y, this.options.minHeight, this.options.maxHeight())
        const rightMoveCoords = moveRightEdge(startingCoords, m.x, this.options.minWidth, this.options.maxWidth())
        return {
            top: bottomMoveCoords.top, height: bottomMoveCoords.height,
            left: rightMoveCoords.left, width: rightMoveCoords.width,
        }
    }
}