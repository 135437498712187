import { OverlayModal } from "../../../common/overlayModal"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"

export class PromoteDisabledModal extends OverlayModal {
    private okButton: HTMLDivElement

    constructor(props: object) {
        super(props)
        this.defineTabbableElements([this.okButton])
    }

    protected createElement(): HTMLDivElement {
        return <div className="promoteDisabledModal">
            <div className="title">{i18n.promotionUnavailableTitle}</div>
            <div className="description">{i18n.promotionUnavailableDescription}</div>
            <div className="buttonDiv">
                <div className="okButton"
                     data-testid="promotion-disabled-accept"
                     onClick={() => {this.hide()}}
                     ref={(el: HTMLDivElement) => {this.okButton = el}}
                >{i18n.confirmOK}</div>
            </div>
        </div>
    }
}
