import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { HTMLComponent } from "./defui/htmlComponent"
import { ListenerGroup } from "./events"
import { modalBlockChatFocus, modalExit } from "./modalComponent"
import { dom } from "./tsxrender/dom"

/**
 * Similarly to ModalComponent class: implements a modal with a clickable overlay that stays centered within a
 * resizing browser window.
 *
 * Main motivation for creation of this class is to extend from HTMLComponent instead of Component.
 *
 * OverlayModal expects to be exited before interacting with anything else on the page, therefore it doesn't carry over
 * the same listeners that ModalComponent has.
 */
export class OverlayModal extends HTMLComponent<HTMLDivElement> {
    protected easyExit = true  // Can be overridden in subclasses
    protected isListeningForChange = false
    private tabbableElements: HTMLElement[] = []
    private listenerGroup = new ListenerGroup()
    overlay: HTMLDivElement

    constructor(props: object) {
        super(props)

        // Consistent fxn reference for add/remove listener
        this.keyboardNav = this.keyboardNav.bind(this)
    }

    protected initUI(): void {
        this.overlay = <div className="overlayModalOverlay" onClick={(event: MouseEvent) => {
            event.stopPropagation()
            if (this.easyExit) {
                this.hide()
            }
        }}/>
        this.element.classList.add("overlayModalElement")
        this.element.onclick = (event: MouseEvent) => {
            event.stopPropagation() // Prevent interacting with the component from triggering the overlay.
        }

        addEventListenerPoly("popstate", window, () => {
            this.hide()
        })
    }

    public show(): void {
        if (!this.isListeningForChange) {
            modalExit.listen(() => {
                this.hide()
            }, false).addTo(this.listenerGroup)

            this.isListeningForChange = true
        }

        document.body.appendChild(this.overlay)
        this.overlay.appendChild(this.element)
        modalBlockChatFocus.fire(true)
        document.addEventListener("keydown", this.keyboardNav)
    }

    public hide(): void {
        if (this.isListeningForChange) {
            this.listenerGroup.removeAll()

            this.isListeningForChange = false
        }

        this.overlay.parentElement?.removeChild(this.overlay)
        modalBlockChatFocus.fire(false)
        document.removeEventListener("keydown", this.keyboardNav)
    }

    protected defineTabbableElements(elementList: HTMLElement[]): void {
        this.tabbableElements = elementList
        this.tabbableElements.forEach((el: HTMLElement) => {
            el.tabIndex = 0
            el.onkeydown = (keyEvent: KeyboardEvent) => {
                keyEvent.preventDefault()
                if (keyEvent.key === "Enter" || keyEvent.key === " ") {
                    el.click()
                }
            }
        })
    }

    private keyboardNav(event: KeyboardEvent): void {
        if (event.key === "Escape" && this.easyExit) {
            this.hide()
        } else if (event.key === "Tab") {
            if (document.activeElement !== null && this.tabbableElements.length > 0) {
                event.preventDefault()
                const currentIndex = this.tabbableElements.indexOf(document.activeElement as HTMLElement)
                const newIndex = currentIndex + (event.shiftKey ? -1 : 1)
                const finalIndex = newIndex < 0 ? this.tabbableElements.length - 1 : newIndex
                this.tabbableElements[finalIndex % this.tabbableElements.length].focus()
            }
        }
    }
}
