import { firstNotEmpty } from "@multimediallc/web-utils"
import { Component } from "../../common/defui/component"
import {
    applyStyles,
    getBackgroundColor,
    getCoords,
    numberFromStyle,
} from "../../common/DOMutils"
import { DropDownComponentBase } from "../../common/dropDownComponentBase"
import { resizeDebounceEvent } from "./responsiveUtil"
import type { DropDownComponent } from "../../common/dropDownComponent"
import type { BoundListener } from "../../common/events"

export class WaterfallDropDownBase extends DropDownComponentBase {
    connectedOverlay: ConnectedOverlay
    resizeDebounceListener: BoundListener<boolean>
    protected readonly topMargin: number = 4

    constructor(props: object, public toggleElement: HTMLElement, protected readonly alignRight = true) {
        super(props, toggleElement, false)
        this.initAddChildren()
        this.resizeDebounceListener = resizeDebounceEvent.listen(() => {
            if (this.isShown()) {
                this.reposition()
            }
        })
        document.body.appendChild(this.element)
        this.hideElement()
    }

    protected initAddChildren(): void {
        this.connectedOverlay = new ConnectedOverlay()
        this.addChild(this.connectedOverlay)
    }

    showElement(defaultDisplay = "block", evt?: Event): boolean {
        if (!super.showElement(defaultDisplay, evt)) {
            return false
        }
        this.toggleElement.classList.add("active")
        this.reposition()
        return true
    }

    hideElement(evt?: Event): boolean {
        if (!super.hideElement(evt)) {
            return false
        }
        this.toggleElement.classList.remove("active")
        return true
    }

    reposition(): void {
        const toggleCoord = getCoords(this.toggleElement)
        // Align right if specified to do so via constructor arg
        // or if here isn't room to align left and there IS room to align right.
        const alignRight = this.alignRight ||
            (toggleCoord.left + this.element.offsetWidth > document.documentElement.clientWidth &&
                toggleCoord.right - this.element.offsetWidth > 0)
        this.setDropDownStyleFromToggle(alignRight)
        const borderWidthDiff = numberFromStyle(getComputedStyle(this.element).borderBottomWidth) -
            numberFromStyle(getComputedStyle(this.toggleElement).borderLeftWidth)
        this.setDropDownPosition(toggleCoord, borderWidthDiff, alignRight)
        this.connectedOverlay.positionAndStyle(this.toggleElement, this, borderWidthDiff, alignRight)
        this.element.style.zIndex = getComputedStyle(this.connectedOverlay.element).zIndex
    }

    dispose(): void {
        super.dispose()
        // This component automatically adds itself to document.body, so we need to remove this.element here
        this.element.remove()
        this.resizeDebounceListener.removeListener()
    }

    protected setBorderRadius(alignRight: boolean, radius: string): void {
        if (radius === "") {
            return
        }
        this.element.style.borderRadius = `${alignRight ? `${radius} 0` : `0 ${radius}`} ${radius} ${radius}`
    }

    // eslint-disable-next-line complexity
    protected setDropDownStyleFromToggle(alignRight: boolean): void {
        this.element.style.position = "absolute"
        this.element.style.backgroundColor = getBackgroundColor(this.toggleElement)
        const toggleHasBorder = this.getToggleStyle(alignRight ?
            "borderRightWidth" : "borderLeftWidth") !== "0px"
        if (toggleHasBorder) {
            this.element.style.borderWidth = this.getToggleStyle(alignRight ? "borderRightWidth" : "borderLeftWidth")
            this.element.style.borderStyle = this.getToggleStyle(alignRight ? "borderRightStyle" : "borderLeftStyle")
            this.element.style.borderColor = this.getToggleStyle(alignRight ? "borderRightColor" : "borderLeftColor")
        }
        const radius = this.getToggleStyle(alignRight ? "borderTopRightRadius" : "borderTopLeftRadius")
        this.setBorderRadius(alignRight, radius)
    }

    protected setDropDownPosition(toggleCoord: DOMRect, borderWidthDiff: number, alignRight: boolean): void {
        this.element.style.top = `${toggleCoord.bottom + this.topMargin}px`
        if (alignRight) {
            this.element.style.left = ""
            const right = document.documentElement.clientWidth - toggleCoord.right - borderWidthDiff
            this.element.style.right = `${right}px`
            this.element.style.right = `${(right - getRightDiff(this.toggleElement, this.element) - borderWidthDiff)}px`
        } else {
            this.element.style.right = ""
            const left = toggleCoord.left - borderWidthDiff
            this.element.style.left = `${left}px`
            this.element.style.left = `${left + getLeftDiff(this.toggleElement, this.element) - borderWidthDiff}px`
        }
    }

    private getToggleStyle(styleName: keyof CSSStyleDeclaration): string {
        const computedStyle = getComputedStyle(this.toggleElement)
        return firstNotEmpty(this.toggleElement.style[styleName] as string, computedStyle[styleName] as string)
    }

    toggleStylesToKeep = ["color", "background-color", "text-decoration"]
}

export class ConnectedOverlay extends Component {
    constructor() {
        super()
        applyStyles(this.element, {
            position: "absolute",
            zIndex: 4,
        })
    }

    public positionAndStyle(toggleElement: HTMLElement, dropDown: DropDownComponent, borderWidthDiff: number, alignRight: boolean): void {
        const dropDownStyle = getComputedStyle(dropDown.element)
        this.element.style.borderLeftWidth = dropDownStyle.borderLeftWidth
        this.element.style.borderLeftStyle = dropDownStyle.borderLeftStyle
        this.element.style.borderLeftColor = dropDownStyle.borderLeftColor
        this.element.style.borderRightWidth = dropDownStyle.borderLeftWidth
        this.element.style.borderRightStyle = dropDownStyle.borderLeftStyle
        this.element.style.borderRightColor = dropDownStyle.borderLeftColor
        const width = numberFromStyle(getComputedStyle(toggleElement).width) + 2 * borderWidthDiff
        this.element.style.width = `${width}px`

        const height = dropDown.element.offsetTop - getCoords(toggleElement).bottom + 1
        this.element.style.height = `${height + 2}px`
        this.element.style.top = `-${height + 2}px`

        this.element.style.backgroundColor = dropDownStyle.backgroundColor
        const borderWidth = firstNotEmpty(dropDownStyle.borderWidth, dropDownStyle.borderLeftWidth)
        this.element.style.right = alignRight ? `-${borderWidth}` : ""
        this.element.style.left = alignRight ? "" : `-${borderWidth}`
        this.element.style.zIndex = firstNotEmpty(dropDownStyle.zIndex, this.element.style.zIndex)
        if (alignRight) {
            this.element.style.width = `${(width - getLeftDiff(toggleElement, this.element)) + borderWidthDiff}px`
        } else {
            this.element.style.width = `${(width + getRightDiff(toggleElement, this.element) + borderWidthDiff)}px`
        }
    }
}

function getLeftDiff(el1: HTMLElement, el2: HTMLElement): number {
    return el1.getBoundingClientRect().left - el2.getBoundingClientRect().left
}

function getRightDiff(el1: HTMLElement, el2: HTMLElement): number {
    return el1.getBoundingClientRect().right - el2.getBoundingClientRect().right
}
