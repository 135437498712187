import { AppPanel, appPanelWidth } from "./appPanel"
import { roomLoaded } from "./context"

const reshowAfterMessagesNum = 20
export const waitAfterRoomLoadMilliseconds = 2000

export interface IAppPanelChatConfig {
    appendMessageDiv: (message: HTMLElement) => void
    removeMessageDiv: (message: HTMLElement) => void
    getLastMessageId: () => number
    messagesSinceId: (id: number) => number
    getMessagesScrollTop: () => number
    getMessagesOffsetHeight: () => number
}

export class AppPanelChat {
    public appPanel: AppPanel
    private hasContent: boolean
    private canUpdatePanel: boolean
    private appPanelLastId: number | undefined

    constructor(private config: IAppPanelChatConfig) {
        this.appPanel = new AppPanel()
        this.appPanel.element.style.width = `${appPanelWidth}px`
        this.appPanel.element.style.position = "relative"

        this.hasContent = false
        this.canUpdatePanel = false

        roomLoaded.listen(roomContext => {
            this.hasContent = false
            this.canUpdatePanel = false
            this.appPanelLastId = undefined

            window.setTimeout(() => {
                this.canUpdatePanel = true
                if (this.hasContent) {
                    this.updatePanel()
                }
            }, waitAfterRoomLoadMilliseconds)
        })

        this.appPanel.panelUpdated.listen(() => {
            this.hasContent = true
            if (this.canUpdatePanel) {
                this.updatePanel()
            }
        })
    }

    public updatePanel(): void {
        if (!this.appPanel.hasContent) {
            return
        }

        if (this.appPanelLastId === undefined) {
            this.config.appendMessageDiv(this.appPanel.element)
            this.appPanelLastId = this.config.getLastMessageId()
            return
        }

        const offset = this.appPanel.element.offsetTop - this.config.getMessagesScrollTop()
        if (offset < 0 || offset > (this.config.getMessagesOffsetHeight() - 30)) {
            if (this.config.messagesSinceId(this.appPanelLastId) > reshowAfterMessagesNum) {
                this.config.removeMessageDiv(this.appPanel.element)
                this.config.appendMessageDiv(this.appPanel.element)
                this.appPanelLastId = this.config.getLastMessageId()
            }
        }
    }
}
