// Here is a basic example for how this class should be used:

// const update = new OptimisticUpdate<T>(initialState: T, updateFunction, revertFunction?)
//
// const callAPI = postCb('example/route').then(() => {
//     return true // success
// }).catch(() => {
//     return false // failure
// })
// update.dispatch(newState: T, callAPI)

// This library will apply the dispatched update immediately (aka optimistically).
// When the promise resolves, it will either revert to the last known valid state,
// or commit the change and save it as a valid state.

// This library assumes that all dispatches are stateless,
// meaning the correct state can be determined with only the latest dispatch.

// Each instance of OptimisticUpdate should be independent of all other instances,
// and one instance of OptimisticUpdate should not be shared among different API calls.

// The updateFunction should be crafted to receive the following two parameters:
// @param newState: T
// @param isPending: boolean - useful for displaying a loading state

// The revertFunction is called whenever the revert actually changes the state. It can be used to display an error message.

export class OptimisticUpdate<T> {
    private ID = 0
    private lastValidID = 0
    private initialState: T
    private currentState: T
    private lastValidState: T
    private readonly onUpdate: (value: T, isPending: boolean) => void
    private readonly onRevert: () => void

    constructor(initialState: T, onUpdate: (value: T, isPending: boolean) => void, onRevert: () => void = () => {}) {
        this.initialState = initialState
        this.currentState = initialState
        this.lastValidState = initialState
        this.onUpdate = onUpdate
        this.onRevert = onRevert
    }

    // Sets the initial state outside of the constructor
    public setInitialState(initialState: T): void {
        this.initialState = initialState
        // If no updates have been dispatched
        if (this.ID === 0) {
            this.currentState = initialState
            this.onUpdate(this.currentState, false)
        }
    }

    // Dispatch an update that should either be committed or reverted
    public dispatch(newState: T, promise: Promise<boolean>): void {
        this.ID += 1
        this.currentState = newState
        this.onUpdate(this.currentState, true)
        promise.then((success: boolean) => {
            if (success) {
                this.commit(this.ID)
            } else {
                this.revert(this.ID)
            }
        }).catch(() => {
            this.revert(this.ID)
        })
    }

    // Saves a dispatch as successful
    private commit(ID: number): void {
        if (ID > this.lastValidID) {
            this.lastValidID = ID
            this.lastValidState = this.currentState
            this.onUpdate(this.currentState, ID < this.ID)
        }
    }

    // Reverts a dispatch to the last known good state
    private revert(ID: number): void {
        if (this.lastValidID === 0) {
            this.currentState = this.initialState
            this.onUpdate(this.currentState, false)
            this.onRevert()
        } else if (ID > this.lastValidID) {
            this.currentState = this.lastValidState
            this.onUpdate(this.currentState, ID < this.ID)
            this.onRevert()
        }
    }
}
