import { pageContext } from "../../cb/interfaces/context"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { isNotLoggedIn } from "../auth"
import { ChatSettings } from "../chatSettings"
import { applyStyles } from "../DOMutils"
import { EventRouter } from "../events"
import { i18n } from "../translation"
import {
    MobileChatSettingsStyles,
    rowPaddingSide,
    rowPaddingTop,
} from "./mobileChatSettingsStyles"
import { MobileColorPickerModal } from "./mobileColorPickerModal"
import type { IChoices } from "../../cb/ui/fields"
import type { IRoomContext } from "../context"
import type { IUserChatSettings } from "../roomDossier"
import type { Slider } from "../slider"
import type { ColorPickerModal } from "../theatermodelib/colorPickerModal"

const FONT_SIZE = 16
export class MobileChatSettings extends ChatSettings {
    private selectOverlays: Map<HTMLSelectElement, HTMLSpanElement>
    public showManageIgnoredUsers: EventRouter<undefined>
    private isAgeVerified: boolean

    protected initData(): void {
        super.initData()
        this.selectOverlays = new Map()
        this.showManageIgnoredUsers = new EventRouter<undefined>("ShowManageIgnoredUsers")
        this.isAgeVerified = false
    }

    protected initUI(): void {
        super.initUI()
        this.element.style.padding = "0px"
        this.element.style.margin = "0px"
        this.element.style.fontSize = `${FONT_SIZE}px`
        this.getStyles().styleLabel(this.manageIgnoredUsersLink, i18n.viewEditIgnoredUsers)
        applyStyles(this.form, { maxWidth: "100%" })

        this.manageIgnoredUsersLink.onclick = () => {
            if (isNotLoggedIn(`You must be logged in to edit ignored users. Click "OK" to login.`)) {
                return
            }
            this.showManageIgnoredUsers.fire(undefined)
        }
    }

    protected handleRoomLoaded(context: IRoomContext): void {
        super.handleRoomLoaded(context)
        this.isAgeVerified = context.dossier.isAgeVerified
    }

    protected getStyles(): typeof MobileChatSettingsStyles {
        return MobileChatSettingsStyles
    }

    protected getEmoticonAutocompleteDelayOptions(): IChoices[] {
        return [
            { label: i18n.emoticonAutocompleteDelayOff, value: "-1" },
            { label: i18n.emoticonAutocompleteNoDelay(false), value: "0" },
            { label: i18n.emoticonAutocompleteShortDelay(false), value: "500" },
            { label: i18n.emoticonAutocompleteMediumDelay(false), value: "1000" },
            { label: i18n.emoticonAutocompleteLongDelay(false), value: "1500" },
        ]
    }

    protected getHighestTokenColorOptions(): IChoices[] {
        return [
            { label: i18n.highestTokenColorDarkPurple(false), value: "darkpurple" },
            { label: i18n.highestTokenColorLightPurple(false), value: "lightpurple" },
            { label: i18n.highestTokenColorDarkBlue(false), value: "darkblue" },
            { label: i18n.highestTokenColorLightBlue(false), value: "lightblue" },
        ]
    }

    protected getSaveEndpoint(): string {
        if (this.isBroadcaster()) {
            return "api/broadcasterchatsettings/"
        }
        return super.getSaveEndpoint()
    }

    protected getSaveColorEndpoint(): string {
        if (this.isBroadcaster()) {
            return "choose_broadcaster_chat_color/"
        }
        return super.getSaveColorEndpoint()
    }

    protected getFormData(): Record<string, string> {
        const formData = super.getFormData()

        return formData
    }

    protected isBroadcaster(): boolean {
        return pageContext.current.loggedInUser?.username === this.roomName && this.isAgeVerified
    }

    protected styleWrapperCustom(wrapper: HTMLDivElement): void {
        super.styleWrapperCustom(wrapper)
        wrapper.style.padding = "0"
    }

    protected styleLabelCustom(label: HTMLLabelElement): void {
        super.styleLabelCustom(label)
        label.style.padding = `${rowPaddingTop}px ${rowPaddingSide}px`
    }

    protected styleCheckbox(checkbox: HTMLDivElement): void {
        super.styleCheckbox(checkbox)
        checkbox.style.margin = `6px ${rowPaddingSide}px 6px 0`
        checkbox.style.cssFloat = "right"
    }

    protected styleSlider(slider: Slider): void {
        super.styleSlider(slider)
        slider.element.style.marginTop = "2px"
    }

    protected styleColorSwatch(swatch: HTMLSpanElement): void {
        super.styleColorSwatch(swatch)
        swatch.style.margin = `11px ${rowPaddingSide + 3}px 11px 0px`
    }

    protected createRow(label: HTMLLabelElement, labelText: string, input: HTMLElement, options?: IChoices[], tooltipText?: string): HTMLDivElement {
        const wrapper = super.createRow(label, labelText, input, options, tooltipText)

        if (options !== undefined && input instanceof HTMLSelectElement) {
            this.createSelectOverlay(wrapper, input, label)
            addEventListenerPoly("change", input, () => {
                this.updateSelectOverlay(input)
            })
        }

        return wrapper
    }

    protected createColorPickerModal(): ColorPickerModal {
        return new MobileColorPickerModal(this.chatColorSwatch, (color: string) => this.onColorPicked(color), (color: string) => this.onColorHovered(color))
    }

    protected createLabelWithTooltip(label: HTMLLabelElement, i18nTooltipText: string): HTMLDivElement {
        const labelDiv = super.createLabelWithTooltip(label, i18nTooltipText)
        labelDiv.style.flex = "1"
        return labelDiv
    }

    // iOS opens select dropdown on focus by default however android doesn't allow clicking and opening dropdown programatically due to
    // security reasons and wants an explicit tap on the select to open it. This function hides the select udnerneath the entire wrapper div and
    // creates a fake overlay span to mimic the select element on mobile for making the entire wrapper div clickable to underlying the select dropdown.
    protected createSelectOverlay(wrapper: HTMLDivElement, input: HTMLElement, label: HTMLLabelElement): void {
        if (input instanceof HTMLSelectElement) {
            const overlaySpan = document.createElement("span")
            this.selectOverlays.set(input, overlaySpan)
            wrapper.appendChild(overlaySpan)
            this.styleSelectOverlay(wrapper, overlaySpan, label)
            this.updateSelectOverlay(input)
            wrapper.appendChild(overlaySpan)

            input.onfocus = () => {
                // Simulate focus on the overlay span similar to how the select dropdown would behave
                overlaySpan.style.outline = "-webkit-focus-ring-color auto 1px"
            }

            input.onblur = () => {
                overlaySpan.style.outline = "none"
            }
        }
    }

    private styleSelectOverlay(wrapper: HTMLDivElement, overlaySpan: HTMLSpanElement, label: HTMLLabelElement): void {
        wrapper.style.display = "flex"
        wrapper.style.justifyContent = "space-between"
        wrapper.style.position = "relative"
        this.getStyles().styleSelectOverlay(overlaySpan)
        label.style.flexShrink = "0"
        label.style.marginRight = "12px"
        overlaySpan.style.overflow = "hidden"
        overlaySpan.style.textOverflow = "ellipsis"
    }

    protected updateChatSettings(chatSettings: IUserChatSettings): void {
        super.updateChatSettings(chatSettings)
        this.selectOverlays.forEach((_, k) => this.updateSelectOverlay(k))
    }

    protected updateSelectOverlay(select: HTMLSelectElement): void {
        const overlaySpan = this.selectOverlays.get(select)
        if (overlaySpan !== undefined) {
            overlaySpan.textContent = select.options[select.selectedIndex].text
        }
    }
}
