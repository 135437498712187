import { i18n } from "../translation"

export function secondsToStr(totalSeconds: number): string {
    const numberEnding = (num: number) => {
        return (num > 1) ? "s" : ""
    }

    const hours = Math.floor((totalSeconds) / 3600)
    const minutes = Math.floor((totalSeconds %= 3600) / 60)
    const seconds = totalSeconds % 60
    if (hours > 0) {
        if (minutes > 0) {
            return `${hours} hour${numberEnding(hours)}, ${minutes} minute${numberEnding(minutes)}`
        }
        return `${hours} hour${numberEnding(hours)}`
    }

    if (minutes > 0) {
        if (seconds > 0) {
            return `${minutes} minute${numberEnding(minutes)}, ${seconds} second${numberEnding(seconds)}`
        }
        return `${minutes} minute${numberEnding(minutes)}`
    }
    if (seconds > 0) {
        return `${seconds} second${numberEnding(seconds)}`
    }
    return "less than a second"
}

interface ITimechunk {
    name: string,
    seconds: number,
    translation: (value: number, abbrev?: boolean) => string,
    ago: (value: number) => string,
    fromNow: (value: number) => string,
}
const TIME_CHUNKS: ITimechunk[] = [
    { name: "year", seconds: (60 * 60 * 24 * 365), translation: i18n.years, ago: i18n.yearsAgo, fromNow: i18n.yearsFromNow },
    { name: "month", seconds: (60 * 60 * 24 * 30), translation: i18n.months, ago: i18n.monthsAgo, fromNow: i18n.monthsFromNow },
    { name: "week", seconds: (60 * 60 * 24 * 7), translation: i18n.weeks, ago: i18n.weeksAgo, fromNow: i18n.weeksFromNow },
    { name: "day", seconds: (60 * 60 * 24), translation: i18n.days, ago: i18n.daysAgo, fromNow: i18n.daysFromNow },
    { name: "hour", seconds: (60 * 60), translation: i18n.hours, ago: i18n.hoursAgo, fromNow: i18n.hoursFromNow },
    { name: "minute", seconds: (60), translation: i18n.minutes, ago: i18n.minutesAgo, fromNow: i18n.minutesFromNow },
    { name: "second", seconds: (1), translation: i18n.seconds, ago: i18n.secondsAgo, fromNow: i18n.secondsFromNow },
]
const HOUR_INDEX = TIME_CHUNKS.findIndex((chunk) => chunk.name === "hour")
const MINUTE_INDEX = TIME_CHUNKS.findIndex((chunk) => chunk.name === "minute")

const pluralize = (count: number, ending = "s"): string => {
    if (count === 1) {
        return ""
    }
    return ending
}

const ending = (isFuture: boolean): string => {
    return isFuture ? "from now" : "ago"
}

// WARNING: the output of this function is NOT LOCALIZED! TODO: https://multimediallc.leankit.com/card/30502080246243
/**
 * @param now what to treat as the current time
 * @param then the time that is being described in relation to `now`
 * @returns an English string describing the duration since/until a time.  IF the elapsed time is greater than
 * 24 hours, the string may contain up to two time units, otherwise it will only contain one.
 * Possible time units span from seconds to years, and are not abbreviated.
 * Ex: "1 day, 2 hours ago"; "3 months, 1 week from now"; "20 hours ago"; "1 minute from now"; "32 seconds ago"
 */
export function naturalTime(now: Date, then: Date): string {
    const elapsed = then.getTime() - now.getTime()
    if (elapsed === 0) {
        return i18n.now
    }

    const deltaSeconds = Math.floor(Math.abs(elapsed) / 1000)
    const isFuture = elapsed > 0
    if (deltaSeconds < 60) {
        return `${deltaSeconds} second${pluralize(deltaSeconds)} ${ending(isFuture)}`
    }
    let count = 0
    let seconds = 0
    let result = ""
    let i: number
    for (i = 0; i < TIME_CHUNKS.length; i += 1) {
        count = Math.floor(deltaSeconds / TIME_CHUNKS[i].seconds)
        seconds = TIME_CHUNKS[i].seconds
        if (count !== 0) {
            result = `${count} ${TIME_CHUNKS[i].name}${pluralize(count)}`
            break
        }
    }
    if (deltaSeconds > (60 * 60 * 24) && (i + 1) < TIME_CHUNKS.length) {
        const time = TIME_CHUNKS[i + 1]
        const count2 = Math.floor((deltaSeconds - (seconds * count)) / time.seconds)
        if (count2 !== 0) {
            result = `${result}, ${count2} ${time.name}${pluralize(count2)}`
        }
    }
    result = `${result} ${ending(isFuture)}`
    return result
}

/**
 * Generate a description of how much time has passed since a broadcast start Date, used for room cards
 * @param date the reference point to be compared against the current date/time
 * @returns a localized string describing the elapsed time rounded to the nearest appropriate whole time unit --
 * except for values returned in hours, which are rounded to a single decimal point.
 * Possible units span from minutes to years (though that would be a crazy-long broadcast.) Values returned in
 * minutes or hours (i.e. almost all of them) are abbreviated to "min" / "hrs" but other units are not abbrev'd.
 * Ex: "1 min"; "21 mins"; "1.0 hrs"; "8.9 hrs"; "2 days"; "1 week"; "5 months"
 */
function getTimeOnline(date: Date): string {
    const elapsedSeconds = (new Date().getTime() - date.getTime()) / 1000

    const getConvertedValueTranslation = (index: number) => {
        let convertedValue = (elapsedSeconds) / TIME_CHUNKS[index].seconds
        convertedValue = index === HOUR_INDEX ? truncateHoursDecimal(convertedValue) : Math.floor(convertedValue)
        const abbreviate = (index === HOUR_INDEX || index === MINUTE_INDEX)
        return TIME_CHUNKS[index].translation(convertedValue, abbreviate)
    }

    // Iterate through the TIME_CHUNKS units from large to small, *stopping at minutes*
    // Return the largest time chunk for which the elapsed time is at least 1 unit
    for (let i = 0; i <= MINUTE_INDEX; i += 1) {
        if (elapsedSeconds >= TIME_CHUNKS[i].seconds) {
            return getConvertedValueTranslation(i)
        }
    }
    // If less than one minute has elapsed, render the value as minutes anyway
    return getConvertedValueTranslation(MINUTE_INDEX)
}

/**
 * Generate a description of how much time has passed since a broadcast ended, used for room card details
 * @param date the reference point to be compared against the current date/time
 * @returns a localized string describing when the last broadcast ended, to the nearest appropriate whole unit.
 * Resolution spans from seconds to years, and returns "now" if less than 1 second has elapsed.
 * Ex: "30 seconds ago"; "1 minute ago"; "5 hours ago"; "3 weeks ago"; "1 month ago"
 */
function getLastBroadcast(date: Date): string {
    const elapsedSeconds = (new Date().getTime() - date.getTime()) / 1000

    for (let i = 0; i <= TIME_CHUNKS.length; i += 1) {
        const unit = TIME_CHUNKS[i]
        if (elapsedSeconds >= unit.seconds) {
            return unit.ago(Math.floor(elapsedSeconds / unit.seconds))
        }
    }
    return i18n.now  // elapsed time is less than 1 second
}

/**
 * @param isOffline determines whether the timestamp us treated as the time of the
 *     *previous* broadcast (true) or as the start time of the *current* broadcast (false)
 * @param timestamp a datetime represented in *seconds* since the Unix epoch
 * @returns an appropriate localized string describing either the current broadcast length or the time since
 * the last broadcast, depending on whether the user is online or offline. Used for room card details.
 */
export function getDisplayedTime(isOffline: boolean, timestamp?: number): string {
    if (timestamp === undefined || timestamp === 0) {
        if (isOffline) {
            return i18n.never
        }
        return ""
    }

    const utcDate = new Date(timestamp * 1000)
    if (isOffline) {
        // return naturalTime(new Date(), utcDate)
        return getLastBroadcast(utcDate)
    } else {
        return getTimeOnline(utcDate)
    }
}

/**
 * Displayed broadcast time is not rounded to the nearest tenth, but is actually truncated at that 1 decimal place.
 * This util function exists because there seems to be no built-in way to truncate or floor for decimals
 * @param hours is the number to be formatted for broadcast time display
 */
export function truncateHoursDecimal(hours: number): number {
    // Example: 13.58 -> 13.5
    const floorNum = Math.floor(hours * 10)
    return floorNum / 10
}
