import { Component } from "../../common/defui/component"
import { Subgender } from "../../common/genders"
import { i18n } from "../../common/translation"
import { dom } from "../../common/tsxrender/dom"

const radioTextStyle: CSSX.Properties = {
    marginLeft: "8px",
    verticalAlign: "middle",
    fontSize: "14px",
}

const promptStyle: CSSX.Properties = {
    marginLeft: "10px",
    fontSize: "14px",
}

export class SubgenderSelectionPrompt extends Component<HTMLFormElement>{
    constructor() {
        super()
        this.element = <form className="subgenderModal" style={{ position: "relative" }} >
            <p style={promptStyle}>{i18n.subgenderSelectionHeader}</p>
            <p style={promptStyle}>{i18n.subgenderSelectionPrompt}</p>
            <div style={{ margin: "5px" }}>
                <input type="radio" name ="subgender" id="subgender1" value={Subgender.Transfemme} checked/>
                <label style={radioTextStyle} htmlFor="subgender1">{i18n.subgenderFemme}</label>
            </div>
            <div style={{ margin: "5px" }}>
                <input type="radio" name ="subgender" id="subgender2" value={Subgender.Transmasc}/>
                <label style={radioTextStyle} htmlFor="subgender2">{i18n.subgenderMasc}</label>
            </div>
            <div style={{ margin: "5px" }}>
                <input type="radio" name="subgender" id="subgender3" value={Subgender.Nonbinary}/>
                <label style={radioTextStyle} htmlFor="subgender3">{i18n.subgenderNonbinary}</label>
            </div>
        </form>
    }
}