import { isRoomRoomlistSpaActive } from "@multimediallc/cb-roomlist-prefetch"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getRoomAccess, RoomAccessCode } from "../cb/api/chat"
import { roomDossierContext } from "../cb/interfaces/context"
import { PushService } from "../cb/pushservicelib/pushService"
import { RoomStatusTopic } from "../cb/pushservicelib/topics/room"
import { currentSiteSettings } from "../cb/siteSettings"
import { showLoginOverlay } from "../cb/ui/loginOverlay"
import { resizeDebounceEvent } from "../cb/ui/responsiveUtil"
import { modalAlert } from "./alerts"
import { normalizeResource, XhrError } from "./api"
import { AudioHolder } from "./audioHolder"
import { setAnonymousCallback } from "./auth"
import { ChatConnection } from "./chatconnection/chatConnection"
import { roomCleanup, roomLoaded } from "./context"
import { Component } from "./defui/component"
import { EventRouter, eventsPmSessionsCount, ListenerGroup } from "./events"
import { exitFullscreen } from "./fullscreen"
import { loadRoomRequest } from "./fullvideolib/userActionEvents"
import { setCurrentRoom } from "./newrelic"
import { ignoreCatch } from "./promiseUtils"
import { getRoomDossier } from "./roomDossier"
import { RoomStatus } from "./roomStatus"
import { PrivateShowRequestModal } from "./theatermodelib/privateShowRequestModal"
import { TipCallout } from "./theatermodelib/tipCallout"
import {
    loginOverlayRequest,
    openDefaultTipCalloutRequest,
    privateShowRequestOverlayDismiss,
    privateShowSplitModeRequest,
    userChatSettingsUpdate,
} from "./theatermodelib/userActionEvents"
import { i18n } from "./translation"
import { VideoMode, videoModeHandler } from "./videoModeHandler"
import { getDocumentAgeSeconds } from "./windowUtils"
import type { AudioHolderSound } from "./audioHolder"
import type { IChatConnection, IRoomContext } from "./context"
import type { CustomInput } from "./customInput"
import type { IRoomStatusChangeNotification } from "./messageInterfaces"
import type { BasePlayer } from "./player/basePlayer"
import type { IRoomDossier, IUserChatSettings } from "./roomDossier"
import type { ITipRequest } from "./specialoutgoingmessages"
import type { IPrivateShowRequest } from "./theatermodelib/privateShowRequestModal"

export interface IRoomHistory {
    room: string
    roomTitle: string
}

export interface IChatContents extends Component<HTMLElement> {
    inputDiv: HTMLDivElement,
    customInputField: CustomInput,
}

// Fires before the chat connection is established, so we don't need to wait
// for roomLoaded (several more seconds) to get important info from the dossier.
export const dossierLoaded = new EventRouter<IRoomDossier>("dossierLoaded", {
    listenersWarningThreshold: () => 10 + eventsPmSessionsCount, // Base limit + 1 per PM session
})

export abstract class ChatRoot extends Component {
    private privateShowRequestModal: PrivateShowRequestModal
    protected player: BasePlayer
    protected tipVolume: number
    protected audioHolder = new AudioHolder()
    private lastLoadRoomId = 0
    protected listenerGroup = new ListenerGroup()
    protected chatConnection: IChatConnection
    protected tipCallout: TipCallout
    protected loadedRoom: IRoomHistory = {
        room: "",
        roomTitle: "",
    }

    constructor(roomName: string, inlineAutoplaySupported: boolean) {
        super()
        // Derived classes are responsible for picking and choosing which methods to call in the constructor.
        // Methods that are meant to be shared among multiple derived classes begin with the word "setup"

        // Methods that are meant to be overridden/extended begin with the word "handle"
    }

    // **** List of methods meant to be overridden **** //
    protected handleHistory(roomHistory: IRoomHistory): void {}
    protected handleRoomStatusOffline(chatConnection: IChatConnection): void {}
    protected handleSetWidescreen(isWidescreen: boolean): void {}
    protected handlePlaySound(sound: string): void {}
    protected handleRoomInitiallyOffline(): void {}
    protected handleRoomInitiallyOnline(): void {}
    protected handleRoomStatusPasswordProtected(chatConnection: IChatConnection): void {
        this.cleanupLastRoomCallback?.()
        PushService.forceUpdateAuthorization()
        this.resetLoadedRoom()
    }
    protected handleUpdateChatSettings(chatConnection: IChatConnection, userChatSettings: IUserChatSettings): void {
        chatConnection.updateEnterLeaveSettings(userChatSettings.roomEntryFor, userChatSettings.roomLeaveFor)
    }
    protected handleRoomAccessDenied(username: string, p: ArgJSONMap): void {
        modalAlert(`Access Denied for room: ${username}\n\n${p.getString("detail")}`)
    }
    protected handleRoomUnauthorized(username: string): void {
        if (window.top !== null) {
            window.top.location.href =
                normalizeResource(`/auth/login/?next=${window.location.pathname}${window.location.search}`)
        }
    }
    protected handleRoomPasswordRequired(username: string): void {}
    protected handleRoomDossierErrorDefault(errorText: string, errorExtra?: object): void {
        error(errorText, errorExtra)
    }
    protected handleRoomLoaded(context: IRoomContext): void {}
    protected handleRoomStatusChangeNotification(roomStatusChangeNotification: IRoomStatusChangeNotification): void {}
    protected handleResize(): void {}
    // **** End list **** //

    protected setupAudio(): void {
        userChatSettingsUpdate.listen((userChatSettings) => {
            this.tipVolume = userChatSettings.tipVolume
        })
    }

    protected setupTipCallout(source: string, wrapper: HTMLDivElement, button: HTMLSpanElement): void {
        this.tipCallout = this.addChild(new TipCallout(source, wrapper, button))
        openDefaultTipCalloutRequest.listen((tipRequest: ITipRequest) => {
            if (this.tipCallout.isVisible) {
                this.tipCallout.hide()
            } else {
                this.tipCallout.show(tipRequest)
            }
        })
        this.tipCallout.tipSent.listen(() => {
            this.tipCallout.hide()
        })
    }

    protected setupLoginOverlay(): void {
        loginOverlayRequest.listen((fromFeature) => {
            showLoginOverlay({ fromFeature })
        })
        setAnonymousCallback(() => {
            loginOverlayRequest.fire(true)
        })
    }

    protected setupResizeHandlers(): void {
        resizeDebounceEvent.listen(() => {
            this.repositionChildrenRecursive()
            this.handleResize()
        })
        window.onorientationchange = () => {
            exitFullscreen()
            this.repositionChildrenRecursive()
        }
    }

    protected setupLoadRoom(roomName: string): void {
        this.loadRoom(roomName, true)
        loadRoomRequest.listen((username) => {
            if (getDocumentAgeSeconds() > (48 * 3600)) {
                window.location.pathname = `/${username}/`
            } else {
                this.loadRoom(username, true)
            }
        })
        roomLoaded.listen((context: IRoomContext) => {
            this.handleRoomLoaded(context)
        })
    }

    private createChatConnection(roomDossier: IRoomDossier): void {
        this.chatConnection = new ChatConnection(roomDossier, true)

        const roomStatusTopic = new RoomStatusTopic(roomDossier.roomUid)
        const authFailListener = roomStatusTopic.onAuthFail.once(() => {
            getRoomAccess(roomDossier.room).then(resp => {
                const parsedResponse = new ArgJSONMap(resp.responseText)
                const accessCodeHandled = this.handleRoomAccessCode(roomDossier.room, parsedResponse)
                if (!accessCodeHandled) {
                    modalAlert(i18n.roomLoadError)
                    error("Error loading room", { "error": resp.responseText })
                }
            }).catch(ignoreCatch)
        })
        authFailListener.addTo(this.listenerGroup)
        roomStatusTopic.onSubscribeChange.once(() => {
            authFailListener.removeListener()
        }).addTo(this.listenerGroup)
    }

    protected setDocumentTitle(room: string, subject?: string): void {
        document.title = i18n.mobileDocumentTitle(room, currentSiteSettings.siteName, subject)
    }

    protected cleanupLastRoomCallback?: () => void

    protected loadRoom(username: string, setHistory: boolean): void {
        if (this.isRoomLoaded(username)) {
            return
        }
        this.loadedRoom = {
            room: username,
            roomTitle: "",
        }

        this.lastLoadRoomId += 1
        const loadRoomId = this.lastLoadRoomId
        this.getRoomDossierPromise(username).then((roomDossier) => {
            if (loadRoomId !== this.lastLoadRoomId) { // only the last room should continue
                return
            }
            this.onRoomDossierLoad(roomDossier, setHistory)
        }).catch((e) => {
            this.onRoomDossierError(e, username)
        })
    }

    protected getRoomDossierPromise(username: string): Promise<IRoomDossier> {
        return getRoomDossier(username)
    }

    protected addTitleChangeListener(roomName: string): void {
        this.chatConnection.event.titleChange.listen((title: string) => {
            this.setDocumentTitle(roomName, title)
        }, false).addTo(this.listenerGroup)
    }

    protected onRoomDossierLoad(roomDossier: IRoomDossier, setHistory: boolean): void {
        if (this.cleanupLastRoomCallback !== undefined) {
            this.cleanupLastRoomCallback()
        }
        setCurrentRoom(roomDossier.room)
        this.setDocumentTitle(roomDossier.room, roomDossier.roomTitle)
        this.loadedRoom.roomTitle = roomDossier.roomTitle
        roomDossierContext.setState(roomDossier)
        dossierLoaded.fire(roomDossier)
        this.createChatConnection(roomDossier)
        this.addTitleChangeListener(roomDossier.room)

        this.tipVolume = roomDossier.userChatSettings.tipVolume

        this.audioHolder.loadTipSounds()
        this.chatConnection.event.playSound.listen((sound: AudioHolderSound) => {
            this.handlePlaySound(sound)
        })

        this.handleSetWidescreen(roomDossier.isWidescreen)

        const context = {
            dossier: roomDossier,
            chatConnection: this.chatConnection,
        }

        this.player.playerComponent.handleRoomLoaded(context)

        let roomCountInterval: number
        let roomChangeTimeout: number
        this.cleanupLastRoomCallback = () => {
            clearInterval(roomCountInterval)
            window.clearTimeout(roomChangeTimeout)
            this.chatConnection.disconnect()
            this.player.playerComponent.stop()
            this.listenerGroup.removeAll()
            roomCleanup.fire(undefined)
            this.cleanupLastRoomCallback = undefined
        }

        // RoomRoomlistSPA sets history on click rather than after room load
        if (setHistory && !isRoomRoomlistSpaActive()) {
            this.handleHistory(roomDossier)
        }

        if (this.isRoomInitiallyOffline(context.dossier)) {
            this.handleRoomInitiallyOffline()
        } else {
            this.handleRoomInitiallyOnline()
        }

        roomLoaded.fire(context)

        this.listenerGroup.add(this.chatConnection.event.statusChange.listen(roomStatusChangeNotification => {
            // Room count updates also ping and update users presence in public/private room

            const inPrivateStates = [RoomStatus.PrivateWatching, RoomStatus.PrivateSpying]
            const enteredPrivate = inPrivateStates.includes(roomStatusChangeNotification.currentStatus)
            const leftPrivate = inPrivateStates.includes(roomStatusChangeNotification.previousStatus)

            if (roomStatusChangeNotification.previousStatus === RoomStatus.NotConnected || enteredPrivate || leftPrivate) {
                clearInterval(roomCountInterval)
                roomCountInterval = window.setInterval(() => {
                    this.chatConnection.updateRoomCount(enteredPrivate)
                }, (enteredPrivate ? 5 : 90) * 1000)

                roomChangeTimeout = window.setTimeout(() => {
                    this.chatConnection.updateRoomCount(enteredPrivate || leftPrivate)
                }, 2000) // Delay to ensure user is connected to room.
            }
            switch (roomStatusChangeNotification.currentStatus) {
                case RoomStatus.PasswordProtected:
                    this.handleRoomStatusPasswordProtected(this.chatConnection)
                    break
                case RoomStatus.Offline:
                    this.handleRoomStatusOffline(this.chatConnection)
                    break
            }
            this.handleRoomStatusChangeNotification(roomStatusChangeNotification)
        }))

        this.listenerGroup.add(userChatSettingsUpdate.listen((userChatSettings) => {
            this.handleUpdateChatSettings(this.chatConnection, userChatSettings)
        }))
        this.repositionChildrenRecursive()
    }

    protected isRoomInitiallyOffline(dossier: IRoomDossier): boolean {
        return dossier.roomStatus === RoomStatus.Offline
    }

    protected onRoomDossierError(e: XhrError | Error, username: string): void {
        if (e instanceof XhrError && e.xhr.responseText !== "") {
            if (e.xhr.getResponseHeader("Content-Type") !== "application/json") {
                this.handleRoomDossierErrorDefault("Error reading room dossier error", { "room": username, "error": e.xhr.responseText })
                return
            }
            const p = new ArgJSONMap(e.xhr.responseText)
            const accessCodeHandled = this.handleRoomAccessCode(username, p)
            if (!accessCodeHandled) {
                this.handleRoomDossierErrorDefault("Error parsing room dossier error", { "room": username, "error": e.xhr.responseText })
            }
        } else {
            this.handleRoomDossierErrorDefault("Error occurred while processing room dossier", { "room": username, "error": e })
        }
    }

    private handleRoomAccessCode(username: string, p: ArgJSONMap): boolean {
        switch (p.getStringOrUndefined("code", false)) {
            case RoomAccessCode.AccessDenied:
                this.handleRoomAccessDenied(username, p)
                return true
            case RoomAccessCode.Unauthorized:
                this.handleRoomUnauthorized(username)
                return true
            case RoomAccessCode.PasswordProtected:
                this.handleRoomPasswordRequired(username)
                return true
            case RoomAccessCode.Ok:
                return true
        }
        return false
    }

    protected isRoomLoaded(room: string): boolean {
        const loadedRoomMatches = room === this.loadedRoom.room
        // safari's bfcache can have the wrong room loaded initially on navigation even if loadedRoom matches
        const chatConnMatches = !this.chatConnection || this.chatConnection.room() === room  // eslint-disable-line @typescript-eslint/strict-boolean-expressions
        return loadedRoomMatches && chatConnMatches
    }

    protected setupPrivateShowRequestOverlay(): void {
        this.privateShowRequestModal = this.addChild(new PrivateShowRequestModal())
        privateShowSplitModeRequest.listen((
            privateShowRequest: IPrivateShowRequest|undefined,
        ) => {
            if (videoModeHandler.getVideoMode() === VideoMode.Split) {
                if (privateShowRequest === undefined) {
                    this.privateShowRequestModal.showPending(this.chatConnection)
                } else {
                    this.privateShowRequestModal.show(privateShowRequest)
                }
            }
        }).addTo(this.listenerGroup)

        privateShowRequestOverlayDismiss.listen(() => {
            this.privateShowRequestModal.hide()
        }).addTo(this.listenerGroup)
    }

    protected resetLoadedRoom(): void {
        this.loadedRoom = {
            room: "",
            roomTitle: "",
        }
    }
}
