import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb, postCb } from "./api"
import { isNotLoggedIn } from "./auth"
import { EventRouter } from "./events"
import { FollowNotificationFrequency, parseFollowNotificationFrequency } from "./roomDossier"
import { i18n } from "./translation"
import type { XhrError } from "./api"

export const followingEvent = new EventRouter<IFollowing>("followingEvent")
export const NOTIFY_UTM_CONTENT = ["follow_new", "follow_text", "follow_photo"]

export interface IFollowing {
    roomName: string
    following: boolean
    followNotificationFrequency: FollowNotificationFrequency
    onlineCount?: number
    totalCount?: number
}

export interface IFollowedCount {
    online: number
    total: number
}

export function setFollowNotificationFrequency(
    roomName: string, followNotificationFrequency: FollowNotificationFrequency,
    followingEvent: EventRouter<IFollowing>): Promise<boolean> {
    return postCb(`api/ts/follow/notifications/${roomName}`,
        { "notification_frequency": followNotificationFrequency }).then(() => {
            followingEvent.fire({
                roomName: roomName,
                following: true,
                followNotificationFrequency: followNotificationFrequency,
            })
        return true
    }).catch((err) => {
        error("Unable to update follow notification settings", {
            "room": roomName,
            "reason": err.toString(),
        })
        return false
    })
}

export interface IGetEmailSettings {
    activated: boolean,
    email: string,
    validated: boolean,
}

export function getEmailNotificationSetting(): Promise<boolean | IGetEmailSettings> {
    return getCb(`api/ts/follow/email_notifications/`).then((response) => {
        const p = new ArgJSONMap(response.responseText)
        return {
            activated: p.getBoolean("activated"),
            email: p.getStringOrUndefined("email") !== undefined ? p.getStringOrUndefined("email") : "",
            validated: p.getBoolean("validated"),
        } as IGetEmailSettings
    }).catch((err) => {
        error("Error checking if follow email notifications are turned on", err)
        return false
    })
}

const email_field_name = "email_field"

function parseEmailErrorMessage(err: XhrError): string {
    const response = new ArgJSONMap(err.xhr.responseText)
    const errorString = response.getString("error")
    if (errorString.indexOf(email_field_name) > -1) {
        const emailErrorList = (new ArgJSONMap(errorString)).getList(email_field_name)
        if (emailErrorList !== undefined && emailErrorList.length > 0) {
            const emailErrorObj = emailErrorList[0]
            return emailErrorObj.getString("message")
        }
    }
    return i18n.emailSubmitErrorMessage
}

function postEmailNotificationSetting(activate: boolean, newEmail?: string, currentEmailSettings?: IGetEmailSettings): Promise<string> {
    // Use POST request to save changes
    const currentEmail = currentEmailSettings !== undefined ? currentEmailSettings.email : undefined
    const currentlyValidated = currentEmailSettings !== undefined ? currentEmailSettings.validated : undefined
    return new Promise<string>((resolve, reject) => {
        const newEmailSettings: Record<string, string> = { "activate": `${activate}` }
        if (newEmail !== undefined && newEmail !== "") {
            newEmailSettings[email_field_name] = newEmail
        }
        postCb(`api/ts/follow/email_notifications/`, newEmailSettings).then(() => {
            const emailIsValidated = newEmail !== undefined && newEmail !== "" && currentEmail === newEmail
                && currentlyValidated !== undefined && currentlyValidated
            resolve(`${!emailIsValidated && newEmail !== undefined}`)
        }).catch((err: XhrError) => {
            reject(parseEmailErrorMessage(err))
        })
    })
}
export function setEmailNotificationSetting(activate: boolean, email?: string): Promise<string> {
    // Use GET request to determine if inputted email is already validated
    if (email !== undefined && email !== "") {
        return getEmailNotificationSetting().then((settings: IGetEmailSettings) => {
            // If options are the same as what's on file, don't make POST request, and resend verification email if email is not validated
            if (email === settings.email && activate === settings.activated) {
                return new Promise<string>((resolve, reject) => {
                    if (!settings.validated) {
                        postCb("emailvalidation/resend/", {}).then(() => {
                            resolve("true")
                        }).catch(() => {
                            reject(`Error resending verification email to ${email}`)
                        })
                    }
                    resolve("false")
                })
            }
            return postEmailNotificationSetting(activate, email, settings)
        }).catch(() => {
            return postEmailNotificationSetting(activate, email)
        })
    }
    else {
        return postEmailNotificationSetting(activate)
    }
}

export function followRoom(roomName: string, isAnonymous?: boolean, location = "FollowStar"): undefined | Promise<boolean> {
    if ((isAnonymous === undefined || isAnonymous) && isNotLoggedIn(i18n.loginToFollow, undefined, undefined, { fromFeature: true })) {
        return
    }
    return postCb(`follow/follow/${roomName}/`, { "location": location }).then((response) => {
        const p = new ArgJSONMap(response.responseText)
        followingEvent.fire({
            roomName: roomName,
            following: true,
            followNotificationFrequency: parseFollowNotificationFrequency(p.getString("notification_frequency")),
            onlineCount: p.getNumberOrUndefined("online_count"),
            totalCount: p.getNumberOrUndefined("total_count"),
        })
        return true
    }).catch((err) => {
        error(`Unable to follow`, {
            roomName: roomName,
            reason: err.toString(),
        })
        return false
    })
}

export function unfollowRoom(roomName: string, isAnonymous?: boolean, location = "FollowStar"): undefined | Promise<boolean> {
    if ((isAnonymous === undefined || isAnonymous) && isNotLoggedIn(i18n.loginToFollow, undefined, undefined, { fromFeature: true })) {
        return
    }
    return postCb(`follow/unfollow/${roomName}/`, { "location": location }).then((response) => {
        const p = new ArgJSONMap(response.responseText)
        followingEvent.fire({
            roomName: roomName,
            following: false,
            followNotificationFrequency: FollowNotificationFrequency.NONE,
            onlineCount: p.getNumberOrUndefined("online_count"),
            totalCount: p.getNumberOrUndefined("total_count"),
        })
        return true
    }).catch((err) => {
        error(`Unable to unfollow`, {
            roomName: roomName,
            reason: err.toString(),
        })
        return false
    })
}

export function isFollowAllowed(roomName: string): Promise<boolean> {
    return getCb(`follow/follow_allowed/${roomName}/`).then((response) => {
        const p = new ArgJSONMap(response.responseText)
        return p.getBoolean("can_follow")
    }).catch((err) => {
        error("Error checking if user following is allowed", err)
        return false
    })
}
