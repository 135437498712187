import { addColorClass } from "../../cb/colorClasses"
import { createDmWindowRequest } from "../../cb/components/pm/dmWindowsManager"
import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { isNotLoggedIn } from "../auth"
import { roomCleanup, roomLoaded } from "../context"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { addPageAction } from "../newrelic"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import { BaseRoomTab } from "./baseRoomTab"
import { ChatSettingsComponent } from "./chatSettingsMenuComponent"
import { ManageIgnoredUsers } from "./manageIgnoredUsers"
import { MenuComponent } from "./menuComponent"
import { ReportAbuse } from "./reportAbuse"
import { MobileSatisfactionComment, MobileSatisfactionScore } from "./satisfactionScore"
import { TabName } from "./tabList"
import { toggleDms, userSwitchedTab } from "./userActionEvents"
import { userCountUpdate, UserList } from "./userList"
import { getViewportWidth } from "./viewportDimension"
import type { IBaseRoomTabProps } from "./baseRoomTab"
import type { ReactComponent } from "../../cb/components/ReactRegistry"
import type { BasePlayer } from "../player/basePlayer"

export interface IRoomMenuTabProps extends IBaseRoomTabProps {
    player: BasePlayer
}

export class RoomMenuTab extends BaseRoomTab {
    protected roomMenu: RoomMenu

    constructor(player: BasePlayer) {
        const props: IRoomMenuTabProps = {
            tabName: TabName.RoomMenu,
            tabLabel: "•••",
            pageActionName: "RoomTabOpened",
            player,
        }
        super(props)
    }

    protected initUI(props: IRoomMenuTabProps): void {
        super.initUI(props)
        applyStyles(this.element, { position: "relative" })
        this.roomMenu = this.addChild(new RoomMenu({ player: props.player }))
    }

    public getRoomMenu(): RoomMenu {
        return this.roomMenu
    }
}

interface IRoomMenuProps {
    player: BasePlayer
}

export const enum MenuComponentName {
    USER_LIST,
    SATISFACTION_COMMENT,
    REPORT_ABUSE,
    CHAT_SETTINGS,
}

class RoomMenu extends Component {
    private room: string
    private activeMenuComponent: MenuComponent | undefined
    private userList: UserList
    private sendDmLabel: ReactComponent
    private satisfactionComment: MobileSatisfactionComment
    private satisfactionCommentMenuComponent: MenuComponent
    private reportAbuse: ReportAbuse
    private reportAbuseMenuComponent: MenuComponent
    private chatSettingsComponent: ChatSettingsComponent
    private manageIgnoredUsers: ManageIgnoredUsers

    constructor(props: IRoomMenuProps) {
        super("div", props)
    }

    protected initData(props: IRoomMenuProps): void {
        this.room = ""
        this.userList = new UserList()
        this.satisfactionComment = new MobileSatisfactionComment()
        this.satisfactionCommentMenuComponent = new MenuComponent(getViewportWidth(), this.satisfactionComment, true)
        this.reportAbuse = new ReportAbuse()
        this.reportAbuseMenuComponent = new MenuComponent(getViewportWidth(), this.reportAbuse, true)
        this.chatSettingsComponent = new ChatSettingsComponent()
        this.manageIgnoredUsers = new ManageIgnoredUsers()
    }

    protected initUI(): void {
        const containerStyle: CSSX.Properties = {
            width: "100%",
            height: "100%",
            overflowY: "scroll",
        }

        this.element = (
            <div style={containerStyle}>
                {this.createSendDmMenuItem()}
                {this.createUserListMenuItem()}
                {this.createSatisfactionScoreMenuItem()}
                {this.createReportAbuseMenuItem()}
                {this.createChatSettingsMenuItem()}
                {this.createManageIgnoredUsersMenuItem()}
            </div>
        )

        // remove property set by `scrollFix` bc it creates a new stacking context
        // preventing mobile color picker from showing properly
        this.element.style["-webkit-overflow-scrolling"] = ""

        this.element.dataset.testid = "additional-options-container"

        this.chatSettingsComponent.chatSettings.showManageIgnoredUsers.listen(() => {
            this.manageIgnoredUsers.show()
        })

        roomLoaded.listen((context) => {
            this.room = context.dossier.room
            this.sendDmLabel.update({
                "text": i18n.sendDmToUser(this.room),
            })
        })

        roomCleanup.listen(() => {
            this.activeMenuComponent?.hide()
            this.satisfactionComment.hideElement()
            this.satisfactionComment.reset()
            this.reportAbuse.reset()
        })

        userSwitchedTab.listen((tabName) => {
            if (tabName !== TabName.RoomMenu) {
                this.closeActiveComponent()
            }
        })
    }

    public getUserList(): UserList {
        return this.userList
    }

    public getReportAbuseForm(): ReportAbuse {
        return this.reportAbuse
    }

    public getReportAbuse(): MenuComponent {
        return this.reportAbuseMenuComponent
    }

    public getChatSettings(): ChatSettingsComponent {
        return this.chatSettingsComponent
    }

    private openMenuComponent(component: MenuComponent): void {
        this.closeActiveComponent()
        this.activeMenuComponent = component
        component.show()
    }

    public openMenuComponentByName(componentName: MenuComponentName): void {
        if (componentName === MenuComponentName.USER_LIST) {
            this.openMenuComponent(this.userList)
        } else if (componentName === MenuComponentName.CHAT_SETTINGS) {
            this.openMenuComponent(this.chatSettingsComponent)
        } else if (componentName === MenuComponentName.REPORT_ABUSE) {
            this.openMenuComponent(this.reportAbuseMenuComponent)
        } else if (componentName === MenuComponentName.SATISFACTION_COMMENT) {
            this.openMenuComponent(this.satisfactionCommentMenuComponent)
        }
    }

    private closeMenuComponent(component: MenuComponent): void {
        if (component === this.chatSettingsComponent) {
            this.manageIgnoredUsers.hide()
        }

        this.activeMenuComponent = undefined
        component.hide()
    }

    public closeActiveComponent(): void {
        if (this.activeMenuComponent !== undefined) {
            this.closeMenuComponent(this.activeMenuComponent)
        }
    }

    private createSendDmMenuItem(): HTMLDivElement {
        const MenuLabelRoot = document.createElement("div")
        const MenuLabel = ReactComponentRegistry.get("MenuLabel")
        this.sendDmLabel = new MenuLabel({
            "text": i18n.sendDmToUser(this.room),
            "style": { whiteSpace: "nowrap" },
            "onClick": () => {
                if (isNotLoggedIn(i18n.loggedInToDm)) {
                    return
                }

                toggleDms.fire(true)
                createDmWindowRequest.fire(this.room)
            },
        }, MenuLabelRoot)

        return MenuLabelRoot
    }

    private createUserListMenuItem(): HTMLDivElement {
        const MenuLabelRoot = document.createElement("div")
        const MenuLabel = ReactComponentRegistry.get("MenuLabel")
        const menuLabel = new MenuLabel({
            "text": i18n.usersText,
            "onClick": () => {
                addPageAction("UserlistOpened")
                this.openMenuComponent(this.userList)
            },
            "showChevron": true,
        }, MenuLabelRoot)

        userCountUpdate.listen((count: number) => {
            menuLabel.update({
                "text": `${i18n.usersText} (${count})`,
            })
        })

        return (
            <div>
                {MenuLabelRoot}
                {this.userList.element}
            </div>
        )
    }

    private createSatisfactionScoreMenuItem(): HTMLDivElement {
        // Create custom div overlay to hold menuLabel and satisfactionScore divs together.
        // This should be removed once we have a react component for MobileSatisfactionScore
        const satisfactionLabelRoot = document.createElement("div")
        addColorClass(satisfactionLabelRoot, "MenuLabel-container")

        const MenuLabelRoot = document.createElement("div")
        const MenuLabel = ReactComponentRegistry.get("MenuLabel")
        new MenuLabel({
            "text": i18n.satisfiedText,
            "style": { borderBottom: "none", paddingLeft: "0" },
        }, MenuLabelRoot)

        // TODO: Convert MobileSatisfactionScore into react component and pass it directly to MenuLabel as child prop
        const satisfactionScore = new MobileSatisfactionScore()
        satisfactionScore.commentTriggerEvent.listen((reset) => {
            if (reset) {
                this.satisfactionComment.reset()
            }
            this.satisfactionComment.showElement()
            this.openMenuComponent(this.satisfactionCommentMenuComponent)
        })

        satisfactionScore.hideCommentEvent.listen(() => {
            this.closeMenuComponent(this.satisfactionCommentMenuComponent)
        })

        this.satisfactionComment.hideEvent.listen(() => {
            this.closeMenuComponent(this.satisfactionCommentMenuComponent)
        })

        satisfactionLabelRoot.appendChild(MenuLabelRoot)
        satisfactionLabelRoot.appendChild(satisfactionScore.element)

        return (
            <div>
                {satisfactionLabelRoot}
                {this.satisfactionCommentMenuComponent.element}
            </div>
        )
    }

    private createReportAbuseMenuItem(): HTMLDivElement {
        const MenuLabelRoot = document.createElement("div")
        const MenuLabel = ReactComponentRegistry.get("MenuLabel")
        new MenuLabel({
            "text": i18n.reportAbuseText,
            "style": { color: "#C41721" },
            "onClick": () => {this.openMenuComponent(this.reportAbuseMenuComponent)},
            "showChevron": true,
        }, MenuLabelRoot)

        this.reportAbuse.closeEvent.listen((reset) => {
            if (reset) {
                this.reportAbuse.reset()
            }
            this.closeMenuComponent(this.reportAbuseMenuComponent)
        })

        return (
            <div>
                {MenuLabelRoot}
                {this.reportAbuseMenuComponent.element}
            </div>
        )
    }

    private createChatSettingsMenuItem(): HTMLDivElement {
        const MenuLabelRoot = document.createElement("div")
        const MenuLabel = ReactComponentRegistry.get("MenuLabel")
        new MenuLabel({
            "text": i18n.chatSettings,
            "onClick": () => {
                addPageAction("SettingsOpened")
                this.openMenuComponent(this.chatSettingsComponent)
            },
            "showChevron": true,
        }, MenuLabelRoot)

        return (
            <div>
                {MenuLabelRoot}
                {this.chatSettingsComponent.element}
            </div>
        )
    }

    private createManageIgnoredUsersMenuItem(): HTMLDivElement {
        return (
            <div>
                {this.manageIgnoredUsers.element}
            </div>
        )
    }
    
}
