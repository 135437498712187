export function setPureChatColorData(message: HTMLElement, color: string): void {
    message.setAttribute("data-pureChatColor", color) // eslint-disable-line @multimediallc/no-set-attribute
    message.style.setProperty("--pureChatColor", color)
    message.classList.add("pureChatColor")
}

export function setPureChatColor(message: HTMLDivElement): void {
    const setColorFromAttribute = (elem: HTMLElement) => {
        const pureChatColorAttribute = elem.getAttribute("data-pureChatColor")

        if (pureChatColorAttribute !== null) {
            elem.style.color = pureChatColorAttribute
        }
    }

    if (message.classList.contains("pureChatColor")) {
        setColorFromAttribute(message)
    } else {
        for (const child of message.getElementsByClassName("pureChatColor")) {
            if (child instanceof HTMLElement) {
                setColorFromAttribute(child)
            }
        }
    }
}

const messageDisappearSeconds = 20
const pendingHideTimeouts = new Map<Element, number>()
const hideClass = "pureHidden"

function setPureChatHideTimeout(message: Element): void {
    window.clearTimeout(pendingHideTimeouts.get(message))
    pendingHideTimeouts.set(message, window.setTimeout(() => {
        message.classList.add(hideClass)
        pendingHideTimeouts.delete(message)
    }, messageDisappearSeconds * 1000))
}

export function resetPureChatHideTimeouts(messages: Element[]): void {
    messages.forEach(message => {
        message.classList.remove(hideClass)
        setPureChatHideTimeout(message)
    })
}
