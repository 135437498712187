import { i18n } from "../../common/translation"

export enum Shortcode {
    Fanclub = "fanclub",
    Follow = "follow",
    Signup = "signup",
    Supporter = "supporter",
    Tip = "tip",
    Help = "help",
}

export const primitiveShortcodes = [
    Shortcode.Fanclub,
    Shortcode.Follow,
    Shortcode.Signup,
    Shortcode.Supporter,
    Shortcode.Help,
]

export const Shortcodes = [...primitiveShortcodes, Shortcode.Tip]

export interface IShortcode {
    code: Shortcode
    msg?: string
    amt?: number
    signupLink?: string
}

export interface IShortcodeForm {
    shortcodes: IShortcode[]
    message: string
    room?: string
    private?: boolean
}

export const shortcodeHelpMsg = (
    username: string,
    isBroadcaster: boolean,
): string => {
    let msg = `${i18n.shortcodeHelp}

    ${i18n.shortcode}: [cb:help]
    ${i18n.shortcodeHelpHelp}

    ${i18n.shortcode}: [cb:signup]
    ${i18n.shortcodeHelpSignup()}

    ${i18n.shortcode}: [cb:supporter]
    ${i18n.shortcodeHelpSupporter()}

    ${i18n.shortcode}: [cb:fanclub]
    ${i18n.shortcodeHelpFanclub(username)}

    ${i18n.shortcode}: [cb:follow]
    ${i18n.shortcodeHelpFollow(username)}`

    if (isBroadcaster) {
        msg += `

            ${i18n.shortcode}: [cb:tip amount=50 message="${
                i18n.shortcodeHelpTipExample
            }"]
            ${i18n.shortcodeHelpTip()}`
    }

    return msg
}
