// vim: set ts=4 sw=4 et fileencoding=utf-8:
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getScrollingDocumentElement } from "@multimediallc/web-utils/modernizr"
import { getCb, normalizeResource, postCb } from "../../../common/api"
import { Component } from "../../../common/defui/component"
import { applyStyles } from "../../../common/DOMutils"
import { EventRouter } from "../../../common/events"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { currentSiteSettings } from "../../siteSettings"

export type GameSelectionData =
    | {
          description: string
          gameUrl: string
          image: string
          name: string
          uid: string
          viewers?: number
      }
    | undefined

type SearchAPIResult = {
    image: string
    name: string
    gameUrl: string
    uid: string
    viewers: number
}
export type ISearchAPIResults = SearchAPIResult[]

/**
 * @returns Chaturbate Logo
 */
export const Logo = (): HTMLImageElement => {
    return (
        <img
            src={currentSiteSettings.logoImageName}
            style={{
                maxWidth: "198px",
            }}
        />
    )
}

/**
 * @returns Search result row / game
 */
const SearchResult = (
    props: SearchAPIResult & { onClick: () => void },
): HTMLDivElement => {
    /**
     * @returns Column for row
     */
    const Column = (props: {
        children?: HTMLElement[]
        style?: CSSX.Properties
    }): HTMLDivElement => {
        return (
            <div
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    ...props.style,
                }}
            >
                {props.children}
            </div>
        )
    }

    return (
        <div className="game-search-result" style={{ marginTop: "12px", position: "relative" }}>
            <Column style={{ paddingRight: "1em" }}>
                <img
                    src={props.image}
                    style={{
                        borderRadius: "5px",
                        objectFit: "cover",
                        overflow: "hidden",
                    }}
                    width="42px"
                    height="42px"
                />
            </Column>
            <Column>
                <div
                    style={{
                        fontSize: "14px",
                        lineHeight: "22px",
                        fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
                        height: "50%",
                    }}
                >
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault()
                            props.onClick()
                        }}>
                        {props.name}
                    </a>
                </div>
                <div
                    style={{
                        fontSize: "12px",
                        lineHeight: "20px",
                        height: "50%",
                    }}
                >
                    Viewers: {props.viewers ?? 0}
                </div>
            </Column>
            <Column
                style={{
                    position: "absolute",
                    right: "0",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
            >
                <div
                    style={{
                        fontSize: "12px",
                        lineHeight: "14px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        display: "inline-block",
                        padding: "7px 8px",
                        marginRight: "18px",
                        userSelect: "none",
                    }}
                    className="selectGameButton"
                    data-testid="select-game-button"
                    onClick={props.onClick}
                >
                    Select game
                </div>
            </Column>
        </div>
    )
}

export const p = (html: string): HTMLParagraphElement => {
    const e = document.createElement("p")
    // eslint-disable-next-line @multimediallc/no-inner-html
    e.innerHTML = html.replace(/\s{2,}/gm, " ")
    e.style.width = "90%"
    return e
}

/**
 * Game selection component (use this externally)
 *
 * Modal for allowing dynamic search & current game selection
 */
export class GameSelection extends Component {
    public readonly OBS_TUTORIAL_URL =
        "https://support.chaturbate.com/hc/en-us/articles/360037971952-How-do-I-set-up-OBS-"

    public static readonly GAME_QUERY_URL = "api/ts/games/search/"
    public static readonly GAME_SELECT_URL = "api/ts/games/select/"
    public static readonly GAME_INFO_URL = "api/ts/games/info/"
    public static readonly GAME_DESELECT_URL = "api/ts/games/deselect/"
    public static readonly GAME_CURRENT_URL = "api/ts/games/current/room/"

    public static readonly selectionChange = new EventRouter<GameSelectionData>(
        "gameSelectionChanged",
    )

    /**
     * Duration to wait, in milliseconds, before querying for an input change
     */
    private readonly SEARCH_DELAY = 500

    /**
     * Handle to the pending search query's timer
     */
    private searchTimer?: number

    /**
     * Container for the rendered search results
     */
    private searchResults: HTMLElement
    /**
     * Container for the pagination
     */
    private paginationDOM: HTMLElement

    /**
     * Container for the main display elements
     */
    private displayElement: HTMLElement

    /**
     * API search results for rendering & future re-rendering
     */
    private searchAPIResults?: ISearchAPIResults

    constructor(
        search: ISearchAPIResults = [],
        private totalGames: number,
        private currentPage = 1,
        // The user's search query
        private searchQuery = "",
    ) {
        super()
        applyStyles(this.element, {
            position: "relative",
            width: "unset",
            height: "unset",
        })
        this.element.appendChild(this.render())

        // Render results passed in from template context
        // and parsed through entrypoint
        this.searchAPIResults = search
        this.renderSearchResults()
        this.renderPagination()

        document.getElementById("search_games_input")?.focus() // rendering loses focus
        this.searchTimer = undefined
    }

    /**
     * Renders the main UI to the modal's displayElement
     */
    private render(): HTMLDivElement {
        const isDarkMode = document.body.classList.contains("darkmode")
        return (
            <div
                className="GameSelection"
                ref={(el: HTMLDivElement) => {
                    this.displayElement = el
                }}
            >
                <div className="title">
                    <h1
                        style={{ color: "#494949", marginBottom: "0px", fontSize: "1.3em", fontWeight: "bolder" }} data-testid="select-game-header-text">
                        { i18n.selectGameHeaderText }
                    </h1>
                    <p
                        style={{
                            fontSize: "14px",
                            lineHeight: "22px",
                            margin: "12px 0 0",
                        }}
                        className="title"
                    >
                        { p(i18n.selectGameSectionText(currentSiteSettings.sanitizedSiteName, currentSiteSettings.cbGamesUrl)) }
                    </p>
                </div>

                <div>
                    <div
                        style={{
                            borderRadius: "4px",
                            display: "inline-block",
                            padding: "5px 8px",
                            verticalAlign: "middle",
                            fontSize: "14px",
                        }}
                        className="searchBar"
                    >
                        <input
                            id="search_games_input"
                            type="text"
                            placeholder="Search Games"
                            style={{
                                width: "210px",
                                border: "0",
                                outline: "none",
                            }}
                            onInput={(e) => {
                                this.searchQueryChanged(e)
                            }}
                            value={this.searchQuery ?? ""}
                            maxLength={50}
                        />
                        <label for="search_games_input">
                            <img
                                src={`${STATIC_URL_ROOT}images/search${isDarkMode ? "_dm" : ""}.svg`}
                                width="12"
                                height="12"
                                style={{
                                    verticalAlign: "middle",
                                }}
                            />
                        </label>
                    </div>
                </div>

                <div
                    ref={(e: HTMLElement) => {
                        this.searchResults = e
                    }}
                />
                <ul
                    ref={(e: HTMLElement) => {
                        this.paginationDOM = e
                    }}>
                </ul>
            </div>
        )
    }

    private renderSearchResults(): void {
        if (this.searchAPIResults === undefined) {
            return
        }
        const results = this.searchAPIResults.length > 0 ?
            <div>
                {
                    this.searchAPIResults.map((r) => {
                        return (
                            <SearchResult
                                name={r.name}
                                gameUrl={r.gameUrl}
                                viewers={r.viewers}
                                image={r.image}
                                uid={r.uid}
                                onClick={() => {
                                    window.location.href = normalizeResource(encodeURI(`/games/confirm_game/${r.uid}/`))
                                }}
                            />
                        )
                    })
                }
            </div> :
            <h3 style={{
                fontSize: "14px",
                color: "#0B5D81",
            }}>No Results</h3>

        const newResults = (
            <div>
                { results }
            </div>
        )

        this.displayElement?.replaceChild(newResults, this.searchResults)
        this.searchResults = newResults // due to a non-full render
    }

    private renderPagination(): void {
        let numberOfPages = Math.floor(this.totalGames / 20)
        if ((this.totalGames % 20) > 0) {
            numberOfPages += 1
        }

        const range = (n: number) => Array.from({ length: n }, (value, key) => key)
        const basePath = this.searchQuery === "" ? `?` : `?q=${this.searchQuery}&`

        const pagination = [...range(numberOfPages)].map(page => {
            // Convert 0 based to 1 based
            page = page + 1
            const activeClass = this.currentPage === page ? "active" : "ok"

            return <li
                className={activeClass}
                style={{
                    margin: "0 2px",
                    // Hide if total games is less than 20
                    display: this.totalGames <= 20 ? "none" : "",
                }}>
                <a
                    href={encodeURI(`${basePath}p=${page}`)}>
                    {`${page}`}
                </a>
            </li>
        })

        const paginationDOM = <ul className="paging" style={{
            display: "flex",
            justifyContent: "center",
        }}>
            <li
                style={{
                    display: this.currentPage === 1 ? "none" : "inline-block",
                    cursor: "pointer",
                }}>
                <a
                    className="prev"
                    style={{ height: "100%" }}
                    href={encodeURI(`${basePath}p=${this.currentPage - 1}`)}></a>
            </li>
            { pagination }
            <li
                style={{
                    display: (
                        this.totalGames < 20 || this.currentPage === numberOfPages
                    ) ? "none" : "inline-block",
                    cursor: "pointer",
                }}>
                <a
                    href={encodeURI(`${basePath}p=${this.currentPage + 1}`)}
                    className="next"
                    style={{ height: "100%" }}>
                </a>
            </li>
        </ul>

        this.displayElement?.replaceChild(paginationDOM, this.paginationDOM)
        this.paginationDOM = paginationDOM
    }

    /**
     * Updates state from the control and schedules a search
     */
    private searchQueryChanged(event: Event): void {
        event.stopPropagation()
        this.searchQuery = (event.target as HTMLInputElement).value

        // Backend only queries on zero or 2+ characters
        if (this.searchQuery.length === 0 || this.searchQuery.length > 1) {
            this.currentPage = 1
            this.scheduleSearch()
            const title = document.getElementsByTagName("title")[0].innerHTML // eslint-disable-line @multimediallc/no-inner-html

            const data = this.searchQuery.length === 0 ? {} : {
                "q": this.searchQuery,
            }
            const path = this.searchQuery.length === 0 ? `${location.pathname}` : `${location.pathname}?q=${this.searchQuery}`
            window.history.replaceState(
                data, title, normalizeResource(path),
            )
        }
    }

    /**
     * Schedules a search
     *
     * Pending searches are unscheduled / debounced. Queries are scheduled for
     * SEARCH_DELAY ms into the future.
     */
    private scheduleSearch(): void {
        if (this.searchTimer !== undefined) {
            clearTimeout(this.searchTimer)
        }

        this.searchTimer = window.setTimeout(() => {
            void this.doSearch()
        }, this.SEARCH_DELAY)
    }

    /**
     * Sends the query to the backend, then renders the results upon success
     */
    private async doSearch(): Promise<void> {
        try {
            let url = `${GameSelection.GAME_QUERY_URL}?q=${encodeURI(
                this.searchQuery,
            )}`
            if (this.currentPage !== 1) {
                url += `&p=${this.currentPage}`
            }
            const xhr = await getCb(url)
            const resp = new ArgJSONMap(xhr.responseText)
            this.totalGames = resp.getNumber("total")

            const items = resp.getList("games")
            this.searchAPIResults = items !== undefined ?
                GameSelection.parseSearchResults(items) :
                []

            this.renderSearchResults()
            this.renderPagination()

            document.getElementById("search_games_input")?.focus() // rendering loses focus
            getScrollingDocumentElement().scrollTop = 0
            this.searchTimer = undefined
        } catch (e) {
            this.searchTimer = undefined
        }
    }

    /**
     * Selects a game by notifying the backend
     *
     * Parses the game data from the response and notifies all interested parties.
     */
    public async selectGameAndClose(uid: string): Promise<void> {
        await GameSelection.selectGame(uid)
    }

    /**
     * Selects a game by notifying the backend
     *
     * Parses the game data from the response and notifies all interested parties.
     */
    public static async selectGame(uid: string): Promise<boolean> {
        let selected = false
        try {
            const xhr = await postCb(GameSelection.GAME_SELECT_URL, { uid: uid })
            const gameData = GameSelection.parseSelection(xhr.responseText)
            GameSelection.selectionChange.fire(gameData)
            selected = true
        } catch (_) {
        }
        return selected
    }

    /**
     * Deselects a game by notifying the backend
     */
    public static async deselectGame(): Promise<void> {
        await postCb(GameSelection.GAME_DESELECT_URL, {})
        GameSelection.selectionChange.fire(undefined)
    }

    /**
     * Gets the current game from the backend
     *
     * This is intended to be performed after room loading, in absence of sending
     * an initial room context payload.
     */
    public static async getCurrentGame(
        room: string,
    ): Promise<GameSelectionData> {
        const xhr = await getCb(
            `${GameSelection.GAME_CURRENT_URL}${encodeURI(room)}`,
        )
        return GameSelection.parseSelection(xhr.responseText)
    }

    /**
     * Manual parse to avoid complications with object attribute renaming
     */
    public static parseSearchResults(payload: ArgJSONMap[]): ISearchAPIResults {
        return payload.map((g) => {
            const count = g.getNumberOrUndefined("viewers")
            return {
                image: g.getString("image_64x64"),
                name: g.getString("name"),
                gameUrl: g.getString("game_url"),
                uid: g.getString("uid"),
                viewers: count === undefined ? 0 : count,
            }
        })
    }

    /**
     * Manual parse to avoid complications with object attribute renaming
     */
    public static parseSelection(payload?: string): GameSelectionData {
        if (payload === undefined) {
            return undefined
        }

        const game = JSON.parse(payload)
        if (game === null) {
            return undefined
        }

        return {
            description: game["description"],
            gameUrl: game["game_url"],
            image: game["image_64x64"],
            name: game["name"],
            uid: game["uid"],
            viewers: game["viewers"],
        }
    }

    public static generateHeader(): {
        headerWithPageLink: HTMLAnchorElement,
        header: HTMLDivElement,
        headerContainer: HTMLDivElement
    } {
        let headerWithPageLink: HTMLAnchorElement = <a></a>
        let header: HTMLDivElement = <div></div>

        const headerContainer =  (
            <div className="OBSInfoSection">
                <div>
                    <Logo />
                </div>
                <div className="title">
                    <a
                        href="#"
                        style={{
                            display: "none",
                        }}
                        ref={(el: HTMLAnchorElement) => {
                            headerWithPageLink = el
                        }}>
                        <p
                            style={{
                                fontSize: "24px",
                                lineHeight: "32px",
                                fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
                                margin: "16px 0 10px",
                                color: "#0a5a83",
                            }}>
                            Games
                        </p>
                    </a>
                    <p
                        ref={(el: HTMLDivElement) => {
                            header = el
                        }}
                        style={{
                            fontSize: "24px",
                            lineHeight: "32px",
                            fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
                            margin: "16px 0 10px",
                            display: "block",
                        }}>
                        Games
                    </p>
                </div>
            </div>
        )

        return {
            headerContainer,
            headerWithPageLink,
            header,
        }
    }
}
