import { ArgJSONMap } from "@multimediallc/web-utils"
import { isiOS } from "@multimediallc/web-utils/modernizr"
import { pageContext } from "../cb/interfaces/context"
import { Shortcode } from "../cb/interfaces/shortcode"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { AutocompleteModal } from "./autocompleteModal"
import { AutocompleteModalMobileMixin } from "./autocompleteModalMobileMixin"
import { roomLoaded } from "./context"
import { CaseAgnosticFilteringCache } from "./filteringCaches"
import { scrollFix } from "./mobilelib/scrollFix"
import type { IAutocompleteConfig } from "./autocompleteModal";
import type { ICustomInput } from "./customInput"
import type { FilteringCache, IItem } from "./filteringCaches";

export class ShortcodeAutocompleteModal extends AutocompleteModal<IItem> {
    protected room: string
    protected cacheTTL = 5 * 60 * 1000
    protected maxSearchSlugLength = 100
    protected filterAfterSize = 1
    protected maxOptionLength = 50

    constructor(protected config: IAutocompleteConfig, protected isPM = false) {
        super(config)
        this.element.dataset.testid = "shortcodeAutocompleteModal"
        this.list.dataset.testid = "shortcodeAutocompleteList"
        this.listenerGroup.add(roomLoaded.listen((context) => {
            this.room = context.dossier.room
        }))
    }

    protected handleKeydown(event: KeyboardEvent): void {  // eslint-disable-line complexity
        if (this.isPM) {
            error("Shortcodes are not implemented for PMs")
            event.preventDefault()
            event.stopPropagation()
        } else if (this.visible) {
            if (event.code === "ArrowUp") {
                event.preventDefault()
                this.scrollList(true)
            }
            if (event.code === "ArrowDown") {
                event.preventDefault()
                this.scrollList(false)
            }

            const stringSelected = window.getSelection()?.toString() ?? ""
            const spaceIsTyped = event.code === "Space" && this.items.some((item) => item.slug.includes(`${this.searchSlug} `))
            // Register space key normally instead of hiding the autocomplete
            // - if there is no window selection of text
            // - if user typed in a space and matching options include a space
            const keysToHideTo = stringSelected === "" || spaceIsTyped ?
                ["ArrowRight", "Enter", "Tab"] :
                ["ArrowRight", "Enter", "Space", "Tab"]
            if (keysToHideTo.includes(event.code)) {
                event.preventDefault()
                event.stopPropagation()
                this.hide()

                // mobile always submits on enter (for now) so we don't want to have
                // a whitespace in input after submit
                if (!(pageContext.current.isMobile && event.code === "Enter")) {
                    this.finalizeSelection()
                }
            }
            if (event.code === "Escape" || event.code === "ArrowLeft") {
                event.preventDefault()
                this.hide()
                this.deleteHighlightedSuffix()
            }

            // If a selection of text from autocomplete exists, and space is typed,
            const selectionHaveSpace = event.code === "Space" && stringSelected.includes(" ")
            if (selectionHaveSpace) {
                event.preventDefault()
                event.stopPropagation()
                this.finalizeSelection()
                this.hide()
            }
        }
    }

    protected initData(): void {
        super.initData()
    }

    protected initUI(): void {
        super.initUI()
        this.list.style.width = ""
    }

    protected promptRegex(): string {
        return "\\[cb"
    }

    protected searchSlugRegex(): string {
        return "[:][\\w\\s\\d=\"!]*]?"
    }

    protected getDataEndpoint(): string {
        return `chatmessages/shortcode_autocomplete/?slug=${this.searchSlug}`
    }

    protected show(): void {
        super.show()
    }

    protected getCache(): FilteringCache<IItem> {
        if (this.cache === undefined) {
            this.cache = new CaseAgnosticFilteringCache(this.filterAfterSize, this.cacheTTL)
        }
        return this.cache
    }

    protected normalizedSearchSlug(): string {
        return this.searchSlug.toLowerCase()
    }

    protected parseResponse(response: string): IItem[] {
        const responseMap = new ArgJSONMap(response)
        const shortcodes_resp = responseMap.getList("shortcodes") ?? []
        const shortcodes: IItem[] = []

        for (const scData of shortcodes_resp) {
            const slug = scData.getString("slug")
            const sc = scData.getString("sc")
            if (slug === "") {
                continue
            }
            // Don't show tip shortcode options to viewers
            const isBroadcaster = pageContext.current.loggedInUser?.username === this.room
            if (sc === Shortcode.Tip && !isBroadcaster) {
                continue
            }
            shortcodes.push({
                slug,
                element: elementPlaceholder,
            })
        }
        responseMap.logUnusedDebugging("parseShortcodegAutocompleteResponse")
        return shortcodes
    }

    protected updateListDOM(): void {
        super.updateListDOM()
        this.repositionChildren()
    }

    protected isSpecialFunctionKey(event: KeyboardEvent): boolean {
        const specialFunctionKeys = ["ArrowDown", "ArrowLeft", "ArrowRight", "ArrowUp", "Enter", "Escape", "Tab"]
        return specialFunctionKeys.includes(event.code)
    }
}

export class MobileShortcodeAutocompleteModal extends ShortcodeAutocompleteModal {
    private mobileMixin: AutocompleteModalMobileMixin

    constructor(inputElement: ICustomInput, isPM = false) {
        super({ inputElement: inputElement, leftOffset: 0, rightOffset: 0 }, isPM)
        this.mobileMixin = new AutocompleteModalMobileMixin({
            autocompleteConfig: this.config,
            element: this.element,
            list: this.list,
            overlayClick: this.overlayClick,
            listenerGroup: this.listenerGroup,
            isVisible: () => this.visible,
            hide: () => this.hide(),
            pickItem: (index: number, fromArrowPress: boolean) => this.pickItem(index, fromArrowPress),
            hideOnSpace: false,
        })

        if (!isiOS()) {
            addEventListenerPoly("keydown", this.config.inputElement.element, (event: KeyboardEvent) => {
                if (this.visible) {
                    this.config.inputElement.setCaretToEndOfSelection()
                }
            })
        }
    }

    protected initUI(): void {
        super.initUI()
        this.element.style.border = ""
        this.element.style.fontSize = "14px"
        this.element.style.lineHeight = "26px"
        this.element.style.boxShadow = "0px 0px 8px rgba(0, 0, 0, 0.32)"
        this.element.style.marginLeft = "7px"
        this.element.style.boxSizing = "content-box"

        this.list.style.width = ""
        scrollFix(this.list)
    }

    protected repositionChildren(): void {
        // pass, override super's implementation
    }

    protected appendItem(shortcode: IItem, index: number): HTMLDivElement {
        const div = super.appendItem(shortcode, index)
        this.mobileMixin.customizeItem(div, index)
        return div
    }

    protected show(): void {
        this.mobileMixin.onShow()
        super.show()
    }
}

const elementPlaceholder = document.createElement("div")
