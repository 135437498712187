import { unloadEventName } from "@multimediallc/web-utils/modernizr"
import { getCookieOrEmptyString } from "@multimediallc/web-utils/storage"
import { addEventListenerPoly } from "../../common/addEventListenerPolyfill"
import { normalizeResource, postCb } from "../../common/api"
import { roomCleanup, roomLoaded } from "../../common/context"
import { Debouncer, DebounceTypes } from "../../common/debouncer"

abstract class ConversationReadSender {
    private static readonly endpoint = "api/ts/chatmessages/update_pms_last_seen/"
    private queuedPmReads = new Set<string>()
    private pmReadsBatch?: Set<string>

    // Using a debouncer for sends because reads can have multiple paths to sendRead, eg from both split mode
    // and theater mode chats, and only one call should go through. The debouncer means that all the paths to
    // sendRead get to resolve before the sendPost call.
    private readonly sendDebouncer: Debouncer

    constructor() {
        this.sendDebouncer = new Debouncer(() => { this.sendPost() },
            { bounceLimitMS: 0, debounceType: DebounceTypes.debounce })

        // If the user closes the page, we flush any reads that may have been requeued
        addEventListenerPoly(unloadEventName(), window, () => this.sendBeacon())
    }

    public sendRead(username: string): void {
        this.queuedPmReads.add(username)
        this.sendDebouncer.callFunc()
    }

    protected getFormData(): FormData | undefined {
        if (this.queuedPmReads.size === 0) {
            return undefined
        }

        this.pmReadsBatch = new Set(this.queuedPmReads)
        this.queuedPmReads.clear()
        const formData = new FormData()
        this.pmReadsBatch.forEach(username => { formData.append("from_usernames", username) })
        formData.append("csrfmiddlewaretoken", getCookieOrEmptyString("csrftoken"))
        return formData
    }

    private requeueData(): void {
        this.pmReadsBatch?.forEach(username => this.queuedPmReads.add(username))
    }

    protected sendPost(): void {
        const sendData = this.getFormData()
        if (sendData !== undefined) {
            postCb(ConversationReadSender.endpoint, sendData).catch((err) => {
                error("post error marking PMs as read", err)
                this.requeueData()
            })
        }
    }

    private sendBeacon(): void {
        const sendData = this.getFormData()
        if (sendData !== undefined) {
            const sendBeaconSuccess = navigator.sendBeacon(normalizeResource(ConversationReadSender.endpoint), sendData)
            if (!sendBeaconSuccess) {
                error(`beacon error marking PMs as read`)
                this.requeueData()
            }
        }
    }
}

class PmsReadSender extends ConversationReadSender {
    private room?: string

    constructor() {
        super()
        roomLoaded.listen((context) => {
            this.room = context.chatConnection.room()
        })
        roomCleanup.listen(() =>{
            this.sendPost()
        })
    }

    protected getFormData(): FormData | undefined {
        const formData = super.getFormData()
        if (formData !== undefined && this.room !== undefined) {
            formData.append("room", this.room)
            return formData
        } else {
            return undefined
        }
    }
}

class DmsReadSender extends ConversationReadSender {}

let pmsReadSender: PmsReadSender | undefined
let dmsReadSender: DmsReadSender | undefined
export function sendConversationRead(username: string, isDm = false): void {
    if (isDm) {
        if (dmsReadSender === undefined) {
            dmsReadSender = new DmsReadSender()
        }
        dmsReadSender.sendRead(username)
    } else {
        if (pmsReadSender === undefined) {
            pmsReadSender = new PmsReadSender()
        }
        pmsReadSender.sendRead(username)
    }
}
