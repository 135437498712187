import { addPageAction } from "./newrelic";
const recordedExp = {};
export class Experiment {
    constructor(name) {
        this.active = false;
        this.eligible = false;
        this.name = name;
        const experiments = window["experiments"];
        if (experiments !== undefined) {
            const testRegExp = new RegExp(`\\b${name}\\b`);
            const active = experiments["active"];
            this.active = active !== undefined && testRegExp.test(active);
            const eligible = experiments["eligible"];
            this.eligible = eligible !== undefined && testRegExp.test(eligible);
        }
    }
    record() {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!this.eligible || recordedExp[this.name]) {
            return;
        }
        const attrs = {
            experimentName: this.name,
            experimentGroup: this.active ? "active" : "inactive",
            experimentTreatment: this.active ? this.name : "control",
        };
        addPageAction("ExperimentEntered", attrs);
        recordedExp[this.name] = true;
    }
}
const recordedNamespace = {};
/**
 * Represents a split test with mutually exclusive sides and a control group.
 */
export class MultiSidedExperiment {
    constructor(names, namespace) {
        this.namespace = namespace;
        this.active = false;
        this.eligible = false;
        const experiments = window["experiments"];
        if (experiments !== undefined) {
            const activeNames = experiments["active"];
            const eligibleNames = experiments["eligible"];
            if (eligibleNames !== undefined) {
                for (const name of names) {
                    const testRegExp = new RegExp(`\\b${name}\\b`);
                    if (testRegExp.test(eligibleNames)) {
                        this.eligible = true;
                        break;
                    }
                }
                if (this.eligible && activeNames !== undefined) {
                    for (const name of names) {
                        const testRegExp = new RegExp(`\\b${name}\\b`);
                        if (testRegExp.test(activeNames)) {
                            this.active = true;
                            this.activeSide = name;
                            break;
                        }
                    }
                }
            }
        }
    }
    isControlGroup() {
        return this.eligible && !this.active;
    }
    record() {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!this.eligible || recordedNamespace[this.namespace]) {
            return;
        }
        const attrs = {
            experimentName: this.namespace,
            experimentGroup: this.active ? "active" : "inactive",
            experimentTreatment: this.active ? this.activeSide : "control",
        };
        addPageAction("ExperimentEntered", attrs);
        recordedNamespace[this.namespace] = true;
    }
}
