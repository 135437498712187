import { getLocalStorageWithExpiration, setLocalStorageWithExpiration } from "@multimediallc/web-utils/storage"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { dismissibleSettingsLinkClass, setupDismissibleSettingsLink } from "../chatSettingsUtil"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { i18n } from "../translation"
import type { IRoomContext } from "../context"

export interface IDismissibleMessage {
    idOrName: number|string
    messageHtml: string
}

const cacheKey = (idOrName: number|string) => `dism_msg${idOrName}`

export class DismissibleMessages extends Component {
    protected messagesDisplaying: (number|string)[] = []

    constructor() {
        super()
        this.element.style.position = "static"
        this.element.style.overflow = ""

        roomLoaded.listen((context: IRoomContext) => {
            for (const dismissibleMessage of context.dossier.dismissibleMessages) {
                if (this.messagesDisplaying.indexOf(dismissibleMessage.idOrName) < 0 && !this.getDismissedCache(dismissibleMessage)) {
                    this.messagesDisplaying.push(dismissibleMessage.idOrName)
                    this.render(dismissibleMessage)
                }
            }
        })
    }

    protected render(dismissibleMessage: IDismissibleMessage): void {
        const messageDiv = document.createElement("div")
        addColorClass(messageDiv, "dismissibleMessage")
        addColorClass(messageDiv, colorClass.dismissibleMessageColor)
        messageDiv.innerHTML = dismissibleMessage.messageHtml // eslint-disable-line @multimediallc/no-inner-html
        messageDiv.style.fontSize = "12px"
        messageDiv.style.padding = "3px"
        messageDiv.style.margin = "5px 0"
        messageDiv.style.borderRadius = "2px"
        messageDiv.style.lineHeight = "16px"
        messageDiv.dataset.testid = "dismissible-message-text"

        const dismissLink = document.createElement("a")
        addColorClass(dismissLink, colorClass.hrefColor)
        dismissLink.textContent = i18n.dismissMessage
        dismissLink.style.cursor = "pointer"
        dismissLink.dataset.testid = "dismissible-message-dismiss"
        const leftParen = document.createElement("span")
        leftParen.textContent = " ("
        const rightParen = document.createElement("span")
        rightParen.textContent = ")"

        messageDiv.appendChild(leftParen)
        messageDiv.appendChild(dismissLink)
        messageDiv.appendChild(rightParen)

        this.element.appendChild(messageDiv)

        const links = messageDiv.getElementsByTagName("a")
        for (const link of links ) {
            addColorClass(link, colorClass.hrefColor)
            link.onmouseenter = () => {
                link.style.textDecoration = "underline"
            }
            link.onmouseleave = () => {
                link.style.textDecoration = ""
            }

            if (link.classList.contains(dismissibleSettingsLinkClass)) {
                setupDismissibleSettingsLink(link)
            }
        }

        addEventListenerPoly("click", dismissLink, () => {
            this.dismissMessage(messageDiv, dismissibleMessage)
        })
    }

    protected getDismissedCache(message: IDismissibleMessage): boolean {
        return getLocalStorageWithExpiration(cacheKey(message.idOrName)) === "1"
    }

    protected dismissMessage(messageDiv: HTMLDivElement, message: IDismissibleMessage): void {
        this.element.removeChild(messageDiv)
        this.messagesDisplaying.splice(this.messagesDisplaying.indexOf(message.idOrName), 1)
        setLocalStorageWithExpiration(cacheKey(message.idOrName), "1", { days: 60 })
    }
}
