import { isIgnored } from "../../cb/api/ignore"
import { getApprovedTags } from "../../cb/api/tags"
import { directMessage, privateMessage } from "../../cb/components/pm/userActionEvents"
import { pageContext, roomDossierContext } from "../../cb/interfaces/context"
import { modalAlert } from "../alerts"
import { AudioHolderSound } from "../audioHolder"
import { featureFlagIsActive } from "../featureFlag"
import { logPresenceNow } from "../logPresence"
import {
    EnterLeaveAction, PartType,
} from "../messageInterfaces"
import { ParsedTitle } from "../parsedtitle"
import { ignoreCatch } from "../promiseUtils"
import { RoomStatus } from "../roomStatus"
import { isPrivateShowRequestFeatureActive } from "../theatermodelib/privateShowRequestModal"
import { privateShowRequestOverlayDismiss, resetPrivateShowLink } from "../theatermodelib/userActionEvents"
import { i18n } from "../translation"
import { userModeratorStatusChanged } from "../userActionEvents"
import { hashtagPart, stringPart, userPart } from "./roomnoticeparts"
import type { ChatConnection } from "./chatConnection"
import type { IAppLog, IEnterLeave, IPrivateMessage, IPrivateShowRequestNotification, IPurchase, IRoomAction,
    IRoomMessage, IRoomNoticePart, ISettingsUpdateNotification, ITipAlert } from "../messageInterfaces"

function getTipSoundLevel(amount: number): AudioHolderSound {
    if (amount >= 1000) {
        return AudioHolderSound.HugeTip
    } else if (amount >= 500) {
        return AudioHolderSound.LargeTip
    } else if (amount >= 100) {
        return AudioHolderSound.MediumTip
    } else if (amount >= 15) {
        return AudioHolderSound.SmallTip
    }
    return AudioHolderSound.TinyTip
}

// When an anonymous tip notification arrives for the public chat, it'll contain a blank
// username, so we use the placeholder text: "an anonymous user".
function getTipUserPart(parsed: ITipAlert): IRoomNoticePart {
    if (parsed.fromUser.username === "") {
        return stringPart(i18n.aUser)
    } else {
        return userPart(parsed.fromUser)
    }
}

export function handleTitleChange(conn: ChatConnection, newTitle: string, provided_tags?: string[]): void {
    if (newTitle === "") {
        return
    }
    const titleParts: IRoomNoticePart[] = []
    const titleChange = i18n.subjectChangeMessage(newTitle)
    const parsedTitle = new ParsedTitle(titleChange)
    const fireTitleChange = (approved_tags: string[]) => {
        for (let i = 0; i < parsedTitle.stringParts.length; i += 1) {
            const part = stringPart(parsedTitle.stringParts[i])
            part.parseEmoticon = false
            titleParts.push(part)
            if (i < parsedTitle.hashtagParts.length) {
                titleParts.push(hashtagPart(parsedTitle.hashtagParts[i], approved_tags))
            }
        }
        conn.event.roomNotice.fire({
            messages: [titleParts],
            colorClass: "titleChange",
            countsForUnread: conn.hasAnyMessageOrNoticeBeenAdded(), // When the room subject is the very first notice or message in chat, it should not count
            foreground: "#DC5500", // for mobile compatibility only, can be removed after mobile has dark mode
            weight: "bold",
            showInPrivateMessage: false,
        })
        conn.event.titleChange.fire(newTitle)
    }
    if (provided_tags !== undefined) {
        fireTitleChange(provided_tags)
        return
    }
    getApprovedTags(newTitle, pageContext.current.languageCode).then(fireTitleChange).catch(() => {
        fireTitleChange([])
    })
}

export function handleRoomMessage(conn: ChatConnection, message: IRoomMessage): void {
    if (!isIgnored(message.fromUser.username)) {
        conn.event.roomMessage.fire(message)
    }
}

export function handlePrivateMessage(message: IPrivateMessage, currentRoom: string,  pmRoom?: string): void {
    if (isIgnored(message.fromUser.username)) {
        // don't notify, as this can be abused to spam the chat : 780913493
        return
    }

    // pmRoom is undefined if from wowza, empty string if from sitewide PMs, room name if from room push PMs
    const truePmRoom = pmRoom === undefined ? currentRoom : pmRoom

    if (pageContext.current.mergePmDm) {
        privateMessage.fire(message)
        directMessage.fire({ ...message, room: truePmRoom })
    } else {
        if (currentRoom !== "" && truePmRoom === currentRoom) {
            privateMessage.fire(message)
        } else if (truePmRoom === "") {
            directMessage.fire({ ...message, room: truePmRoom })
        }
    }
}

export function handleRoomEnterLeave(conn: ChatConnection, event: IEnterLeave): void {
    if (event.action === EnterLeaveAction.Enter && (event.connections === undefined || event.connections === 1)) {
        conn.roomEntry(event.user)
    } else if (event.action === EnterLeaveAction.Leave && (event.connections === undefined || event.connections === 0)) {
        conn.roomLeave(event.user)
    }
}

export function handlePromotion(conn: ChatConnection, event: IRoomAction): void {
    if (event.username === conn.username()) {
        conn.event.modStatusChange.fire(true)
        conn.isModerator = true
    }
    const msg = stringPart(i18n.moderatorPromoteMessage(event.fromUser, event.username))
    conn.event.roomNotice.fire({
        messages: [[msg]],
        showInPrivateMessage: [event.fromUser, event.username].includes(conn.username()),
    })
    userModeratorStatusChanged.fire({ isMod: true, username: event.username })
}

export function handleRevoke(conn: ChatConnection, event: IRoomAction): void {
    if (event.username === conn.username()) {
        conn.event.modStatusChange.fire(false)
        conn.isModerator = false
    }
    const msg = stringPart(i18n.moderatorRevokeMessage(event.fromUser, event.username))
    conn.event.roomNotice.fire({
        messages: [[msg]],
        showInPrivateMessage: [event.fromUser, event.username].includes(conn.username()),
    }) // TODO notice with user part
    userModeratorStatusChanged.fire({ isMod: false, username: event.username })
}

export function handlePersonallyKicked(conn: ChatConnection, message: string = i18n.kickedFromRoomMessage, exploringHashTag = ""): void {
    conn.event.roomNotice.fire({ messages: [[stringPart(message)]], showInPrivateMessage: true })
    conn.changeStatus(RoomStatus.NotConnected)
    logPresenceNow(conn, exploringHashTag)
}

export function handlePasswordChanged(conn: ChatConnection, passwordSet = true, passwordAlreadySubmitted = false): void {
    if (!passwordSet) {
        conn.event.roomNotice.fire({ messages: [[stringPart(i18n.passwordRemovedMessage)]], showInPrivateMessage: true })
    } else if (passwordAlreadySubmitted || conn.isBroadcasting) {
        conn.event.roomNotice.fire({ messages: [[stringPart(i18n.passwordSetMessage)]], showInPrivateMessage: true })
    } else {
        conn.event.roomNotice.fire({ messages: [[stringPart(i18n.chatDisconnectedMessage)]], showInPrivateMessage: true })
        conn.changeStatus(RoomStatus.PasswordProtected)
    }
}

export function handleSilence(conn: ChatConnection, event: IRoomAction): void {
    conn.event.removeMessages.fire({ username: event.username })
    if (
        conn.username() === event.fromUser ||
        conn.username() === event.username ||
        conn.username() === conn.room() ||
        conn.isModerator
    ) {
        conn.event.roomNotice.fire({
            messages: [[stringPart(i18n.silenceMessage(event.username, event.fromUser))]],
            showInPrivateMessage: [event.fromUser, event.username].includes(conn.username()),
        })
    }
    conn.event.onBanSilence.fire({
        silenced: event.username,
        silencer: event.fromUser,
        isBan: false,
    })
}

export function handleKick(conn: ChatConnection, event: IRoomAction): void {
    conn.event.removeMessages.fire({ username: event.username })
    if (
        conn.status !== RoomStatus.PrivateNotWatching &&
        conn.status !== RoomStatus.PrivateSpying &&
        (conn.username() === event.fromUser ||
        conn.isModerator)
    ) {
        conn.event.roomNotice.fire({
            messages: [[stringPart(i18n.userKickedAndMessagesRemovedMessage(event.username))]],
            showInPrivateMessage: event.username === conn.username(),
        })
    }
    conn.event.onBanSilence.fire({
        silenced: event.username,
        silencer: event.fromUser,
        isBan: true,
    })
}

let privateShowRequestEventTimeout: number | undefined

export function handlePrivateShowRequest(conn: ChatConnection, event: IPrivateShowRequestNotification): void {
    // shouldnt isBroadcasting check be before this, and not have another check at the bottom
    conn.setPrivateShowRequestingUser(event.userRequesting)

    if (event.delayFiringEvent) {
        privateShowRequestEventTimeout = window.setTimeout(() => {
            conn.event.privateShowRequest.fire(event)
            privateShowRequestEventTimeout = undefined
        }, 3000)
    } else {
        conn.event.privateShowRequest.fire(event)
    }
    if (!conn.isBroadcasting) {
        return
    }
    switch (conn.status) {
        case RoomStatus.Public:
        case RoomStatus.Away:
            break
        default:
            warn(`unexpected status: ${conn.status}`)
    }
    conn.changeStatus(RoomStatus.PrivateRequesting)
}

export function handlePrivateShowApprove(conn: ChatConnection, isPremium = false): void {
    const dossier = roomDossierContext.getState()
    let privatePrice = dossier.privatePrice
    let privateMinMinutes = dossier.privateMinMinutes
    // Request has been accepted, so we want to reset the premium request flag
    conn.premiumRequested = false
    if (featureFlagIsActive("PremPrivShow")) {
        conn.setPremiumShow(isPremium)
        roomDossierContext.setState({ premiumShowActive: isPremium })
        if (isPremium) {
            privatePrice = dossier.premiumPrivatePrice
            privateMinMinutes = dossier.premiumPrivateMinMinutes
        }
    }
    switch (conn.status) {
        case RoomStatus.Public:
            conn.changeStatus(RoomStatus.PrivateNotWatching)
            break
        case RoomStatus.PrivateRequesting:
            conn.leaveRoom()
            conn.joinPrivateRoom()
            conn.setPrivateShowInfo(privatePrice, privateMinMinutes)
            conn.changeStatus(RoomStatus.PrivateWatching)
            break
        default:
            warn(`private show approve unexpected status: ${conn.status}`)
    }
    let msg = stringPart(i18n.privateShowStartedMessage)
    if (isPremium) {
        msg = stringPart(i18n.premiumPrivateShowStartedMessage)
    }
    if (conn.status === RoomStatus.PrivateWatching) {
        conn.event.roomNotice.fire({
            messages: [[msg]],
            foreground: "#222",
            background: "#ff8b45",
            weight: "bold",
            showInPrivateMessage: true,
            toUser: conn.privateShowRequestingUser,
        })

        if (conn.isBroadcasting && !pageContext.current.isMobile) {
            conn.event.roomNotice.fire({
                messages: [[stringPart(i18n.privateShowC2cTip)]],
                showInPrivateMessage: true,
                toUser: conn.privateShowRequestingUser,
            })
        }
    } else {
        let messageBlock = [msg]
        if (!isPremium) {
            messageBlock = messageBlock.concat([
                stringPart(" ("),
                { partType: PartType.spyPrivateShow },
                stringPart(")"),
            ])
        }
        conn.event.roomNotice.fire({
            messages: [messageBlock],
            foreground: "#222",
            background: "#ff8b45",
            weight: "bold",
            showInPrivateMessage: true,
            toUser: conn.privateShowRequestingUser,
        })
    }
    privateShowRequestOverlayDismiss.fire(undefined)
}

// eslint-disable-next-line complexity
export function handlePrivateShowCancel(conn: ChatConnection, isPremium = false): void {
    const changeStatusAndNotify = () => {
        if (featureFlagIsActive("PremPrivShow")) {
            conn.setPremiumShow(false)
            roomDossierContext.setState({ premiumShowActive: false })
        }
        conn.changeStatus(RoomStatus.Away)
        if (!conn.isBroadcasting) {
            const message = isPremium ? i18n.premiumPrivateShowFinishedMessage : i18n.privateShowFinishedMessage
            conn.event.roomNotice.fire({
                messages: [[stringPart(message)]],
                foreground: "#222",
                background: "#ff8b45",
                weight: "bold",
                showInPrivateMessage: true,
            })
        }
    }
    switch (conn.status) {
        case RoomStatus.PrivateRequesting:
            conn.changeStatus(RoomStatus.Public)
            if (privateShowRequestEventTimeout !== undefined) {
                clearTimeout(privateShowRequestEventTimeout)
                privateShowRequestEventTimeout = undefined
            } else {
                const msg = isPremium ? i18n.premiumPrivateShowDeclinedMessage : i18n.privateShowDeclinedMessage
                conn.event.roomNotice.fire({
                    messages: [[stringPart(msg)]],
                    foreground: "#222",
                    background: "#ff8b45",
                    weight: "bold",
                    showInPrivateMessage: true,
                })
            }
            break
        case RoomStatus.Away:
        case RoomStatus.Hidden:
        case RoomStatus.Public:
        case RoomStatus.Offline:
            // Canceling a broadcast by reloading the page will get an Offline status on page load
        case RoomStatus.HiddenWatching:
            // another user probably cancelled a private show request, so ignore
            break

        case RoomStatus.PrivateWatching:
            conn.leavePrivateRoom()
            conn.joinRoom()
            changeStatusAndNotify()
            break

        case RoomStatus.PrivateSpying:
        case RoomStatus.PrivateNotWatching:
            changeStatusAndNotify()
            break

        default:
            warn(`privateShowCancel wrong room status: ${conn.status}`)
    }
    privateShowRequestOverlayDismiss.fire(undefined)
}

export function handleAwayModeCancel(conn: ChatConnection): void {
    switch (conn.status) {
        case RoomStatus.Away:
        case RoomStatus.Public:
        case RoomStatus.PrivateRequesting:
            conn.event.roomNotice.fire({
                messages: [[stringPart(i18n.returnFromAwayModeMessage)]],
                foreground: "#222",
                background: "#ff8b45",
                weight: "bold",
                showInPrivateMessage: true,
            })
            conn.changeStatus(RoomStatus.Public)
            break

        default:
            error(`unexpected state: ${conn.status}`)
    }
}

export function handleTipAlert(conn: ChatConnection, parsed: ITipAlert, fromHistory = false): void {
    if (!fromHistory) {
        conn.event.playSound.fire(getTipSoundLevel(parsed.amount))
    }
    conn.event.tipAlert.fire(parsed)
    const message = [
        getTipUserPart(parsed),
        stringPart(` tipped ${parsed.amount} ${parsed.amount > 1 ? "tokens" : "token"}`),
    ]

    if (parsed.isAnonymousTip) {
        message.push(stringPart(` anonymously`))
    }
    if (conn.isBroadcasting) {
        if (parsed.message !== "") {
            message.push(stringPart(` -- ${parsed.message}`))
        }
    }
    conn.event.roomNotice.fire({
        messages: [message],
        background: "#ff3",
        foreground: "#000",
        weight: "bold",
        showInPrivateMessage: parsed.fromUser.username === conn.username() || conn.isBroadcasting,
        dataNick: parsed.fromUser.username,
        ts: parsed.ts,
    })
}

export function handleAppDebugError(conn: ChatConnection, event: IAppLog): void {
    if (event.type === "log" && event.debugMessage !== undefined) {
        conn.event.appDebugLog.fire(event.debugMessage)
    } else if (event.type === "apperrorlog" && event.errorMessages !== undefined) {
        const roomNotice = {
            messages: event.errorMessages,
            showInPrivateMessage: false,
        }
        conn.event.roomNotice.fire(roomNotice)
    } else {
        error("handleAppDebugError invalid type", event)
    }
}

export function handlePurchaseNotification(conn: ChatConnection, event: IPurchase): void {
    const message = []
    // TODO: Update this to be handled server side like our other notifications
    if (event.message === "RECORDING_SAVED") {
        event.message = i18n.privateShowRecordingSavedMessage
    }
    for (const m of event.message.split(new RegExp(`\\b${event.fromUser.username}\\b`, "gi"))) {
        if (m === "") {
            message.push(userPart(event.fromUser))
        } else {
            message.push(stringPart(m))
            message.push(userPart(event.fromUser))
        }
    }
    // Last iteration is either an empty string or an unmatched username so remove
    message.pop()
    conn.event.roomNotice.fire({
        messages: [message],
        background: "#33ff33",
        foreground: "#000",
        weight: "bold",
        showInPrivateMessage: event.fromUser.username === conn.username(),
        ts: event.ts,
    })
}

// eslint-disable-next-line complexity
export function handleSettingsUpdate(conn: ChatConnection, newSettings: ISettingsUpdateNotification): void {
    conn.event.settingsUpdate.fire(newSettings)
    if (!newSettings.allowPrivateShow) {
        return
    }
    if (conn.privatePrice !== newSettings.privatePrice) {
        conn.privatePrice = newSettings.privatePrice
        const msg = stringPart(i18n.privateShowPriceChangeMessage(conn.room(), newSettings.privatePrice))
        conn.event.roomNotice.fire({
            messages: [[msg]],
            showInPrivateMessage: true,
        })
        if (isPrivateShowRequestFeatureActive()) {
            resetPrivateShowLink.fire(undefined)
        }
    }
    if (conn.spyPrice !== newSettings.spyPrice && newSettings.spyPrice !== 0) {
        conn.spyPrice = newSettings.spyPrice
        const msg = stringPart(i18n.spyShowPriceChangeMessage(conn.room(), newSettings.spyPrice))
        conn.event.roomNotice.fire({
            messages: [[msg]],
            showInPrivateMessage: true,
        })
    }
    if (featureFlagIsActive("FanClubSpying") && conn.fanClubSpyPrice !== newSettings.fanClubSpyPrice && newSettings.spyPrice !== 0) {
        conn.fanClubSpyPrice = newSettings.fanClubSpyPrice
        const msg = stringPart(i18n.fanClubSpyPriceChangeMessage(conn.room(), newSettings.fanClubSpyPrice))
        conn.event.roomNotice.fire({
            messages: [[msg]],
            showInPrivateMessage: true,
        })
    }
    if (featureFlagIsActive("PremPrivShow")) {
        if (conn.premiumPrivatePrice !== newSettings.premiumPrivatePrice) {
            conn.premiumPrivatePrice = newSettings.premiumPrivatePrice
            // don't notify if spying is disabled
            if (newSettings.spyPrice !== 0) {
                const msg = stringPart(i18n.premiumPrivateShowPriceChangeMessage(conn.room(), newSettings.premiumPrivatePrice))
                conn.event.roomNotice.fire({
                    messages: [[msg]],
                    showInPrivateMessage: true,
                })
            }
        }
    }
}

export function handleHiddenShowStatus(conn: ChatConnection, roomStatus: RoomStatus, message: string ): void {
    if (conn.isBroadcasting) {
        return
    }
    conn.event.hiddenMessageChange.fire(message)
    switch(conn.status) {
        case RoomStatus.PrivateRequesting:
            conn.leavePrivateOrSpyShow().catch(ignoreCatch)
            break
        case RoomStatus.Public:
        case RoomStatus.Hidden:
        case RoomStatus.HiddenWatching:
        case RoomStatus.Offline:
            break
        case RoomStatus.Away:
            conn.event.roomNotice.fire({
                messages: [[stringPart(i18n.returnFromAwayModeMessage)]],
                foreground: "#222",
                background: "#ff8b45",
                weight: "bold",
                showInPrivateMessage: true,
            })
            break
        default:
            error(`handleHiddenShowStatusChange unexpected status: ${conn.status}`)
    }
    if (roomStatus === RoomStatus.Public) {
        window.setTimeout(() => {
            conn.changeStatus(RoomStatus.Public)
        }, 3000)
    } else {
        conn.changeStatus(roomStatus)
    }
}

export function handleHiddenApprove(conn: ChatConnection, isInitial: boolean): void {
    if (isInitial) {
        window.setTimeout(() => {
            conn.changeStatus(RoomStatus.HiddenWatching)
        }, 2000)
    } else {
        conn.changeStatus(RoomStatus.HiddenWatching)
    }
}

let openHandleCompliance = false
export function openComplianceModal(message: string): void {
    if (openHandleCompliance) {
        return
    }
    openHandleCompliance = true
    modalAlert(message, () => {
        openHandleCompliance = false
    })
}
