import { warn } from "@multimediallc/logging";
let gettext = window["gettext"];
if (gettext === undefined) {
    if (process.env.NODE_ENV !== "test")
        warn("gettext is undefined.");
    gettext = (text) => {
        return text;
    };
}
let ngettext = window["ngettext"];
if (ngettext === undefined) {
    if (process.env.NODE_ENV !== "test")
        warn("ngettext is undefined.");
    ngettext = (singularText, pluralText, count) => {
        if (count > 1) {
            return pluralText;
        }
        return singularText;
    };
}
let interpolate = window["interpolate"];
if (interpolate === undefined) {
    if (process.env.NODE_ENV !== "test")
        warn("interpolate is undefined.");
    interpolate = (fmt, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj, namedInterpolation) => {
        if (namedInterpolation) {
            return fmt.replace(/%\(\w+\)s/g, (match) => {
                const key = match.slice(2, -2);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const object = obj;
                return String(object[key]);
            });
        }
        else {
            return fmt.replace(/%s/g, () => {
                return String(obj.shift()); // eslint-disable-line @typescript-eslint/no-explicit-any
            });
        }
    };
}
export { gettext, interpolate, ngettext };
