import { Experiment } from "@multimediallc/web-utils"
import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { deleteCookie, getCookieOrUndefined } from "@multimediallc/web-utils/storage"
import { isAnonymous } from "./auth"
import { getChatMode } from "./chatSettings"
import { EventRouter, eventsPmSessionsCount } from "./events"
import { fullscreenChange, fullscreenElement, isFullscreen } from "./fullscreen"
import { MobileMetricsVideoMode } from "./mobilelib/sessionMetrics"
import { addPageAction, setCurrentMode } from "./newrelic"
import { noAffiliateFullvideoName } from "./splitTestUtil"

export const enum VideoMode {
    Split = "split",
    Theater = "theater",
    IFS = "fullscreen",
    Fullscreen = "noninteractive-fullscreen",
    Fullvideo = "fullvideo", // fullvideo only
    VideoOnly = "videoonly", // embed only
}

export interface IVideoModeChangeNotification {
    previousMode: VideoMode
    currentMode: VideoMode
}

const afflThtrMdExperiment = new Experiment(noAffiliateFullvideoName)

class VideoModeHandler {
    private videoMode: VideoMode
    private storageKey = "isTheaterMode"

    private lastVideoMode: VideoMode
    private lastNonFullscreenVideoMode: VideoMode

    public changeVideoMode = new EventRouter<IVideoModeChangeNotification>("changeVideoMode", {
        listenersWarningThreshold: () => 20 + eventsPmSessionsCount, // Base limit + 1 per PM session
    })
    private isMobile: boolean

    private hasFiredAflThtrMdEvents = false

    constructor() {
        const initialVideoMode = this.getInitialVideoMode()
        const lastVideoMode = initialVideoMode === VideoMode.Split ? VideoMode.Theater : VideoMode.Split
        this.videoMode = initialVideoMode
        this.lastVideoMode = lastVideoMode
        this.lastNonFullscreenVideoMode = lastVideoMode
        this.saveVideoModeStorage()
        this.listenForFullscreen()
        setCurrentMode(this.videoMode)

        getChatMode.listen((mode: string) => {
            this.isMobile = mode === "mobile"
            if (this.isMobile) {
                if (isFullscreen()){
                    setCurrentMode(MobileMetricsVideoMode.LF)
                } else {
                    setCurrentMode(MobileMetricsVideoMode.PC)
                }
            }
        })
    }

    private listenForFullscreen(): void {
        fullscreenChange.listen(() => {
            if (isFullscreen()) {
                const isChatPlayer = fullscreenElement()?.id === "chat-player"
                // we have broadcaster's video's which can be viewed in fullscreen also, it needs to be ignored
                if (!isChatPlayer) {
                    return
                }
                // fullscreenchange also fires for IFS. wait and check if VideoControls listener sets videoMode to IFS first
                window.setTimeout(() => {
                    if (this.videoMode !== VideoMode.IFS) {
                        videoModeHandler.setFireVideoMode(VideoMode.Fullscreen)
                    }
                }, 0)
            } else {
                videoModeHandler.setFireVideoMode(this.lastNonFullscreenVideoMode)
            }
        })
    }

    private setVideoModeInternal(newVideoMode: VideoMode, force = false): boolean {
        if (!force && newVideoMode === this.videoMode) {
            // TODO Add a metric for where this check fails and proceed to correct the replicated call if possible.
            return false
        }

        const fullscreenModes = [VideoMode.Fullscreen, VideoMode.IFS, VideoMode.Fullvideo]
        if (fullscreenModes.indexOf(this.videoMode) === -1) {
            this.lastNonFullscreenVideoMode = this.videoMode
        }

        this.lastVideoMode = this.videoMode
        this.videoMode = newVideoMode
        if (this.videoMode === VideoMode.Fullscreen && this.isMobile){
            setCurrentMode(MobileMetricsVideoMode.LF)
        } else {
            setCurrentMode(this.videoMode)
        }
        this.saveVideoModeStorage()
        return true
    }

    public setVideoMode(newVideoMode: VideoMode, force = false): void {
        this.setVideoModeInternal(newVideoMode, force)
    }

    public setFireVideoMode(newVideoMode: VideoMode, force?: { update?: boolean, fire?: boolean }): void {
        const wasUpdated = this.setVideoModeInternal(newVideoMode, force?.update ?? false)
        if (wasUpdated || (force?.fire ?? false)) {
            this.changeVideoMode.fire({
                currentMode: this.videoMode,
                previousMode: this.lastVideoMode,
            })
        }
    }

    private saveVideoModeStorage(): void {
        if ([VideoMode.Split, VideoMode.Theater].includes(this.videoMode) && isLocalStorageSupported()) {
            window.localStorage.setItem(this.storageKey, JSON.stringify({ "isTheaterMode": this.videoMode === VideoMode.Theater }))
        }
    }

    private getInitialVideoMode(): VideoMode {
        let initialMode = afflThtrMdExperiment.active ? VideoMode.Theater : VideoMode.Split

        if (isLocalStorageSupported()) {
            const savedSettings = window.localStorage.getItem(this.storageKey)
            if (savedSettings !== null) {
                const isTheaterMode_Parsed = JSON.parse(savedSettings)["isTheaterMode"]
                initialMode = (Boolean(isTheaterMode_Parsed)) ? VideoMode.Theater : VideoMode.Split
            }
        }

        return initialMode
    }

    public getVideoMode(): VideoMode {
        return this.videoMode
    }

    public getPreviousNonFullscreenVideoMode(): VideoMode {
        return this.lastNonFullscreenVideoMode
    }

    // eslint-disable-next-line complexity
    public fireAfflThtrMdEvents(): void {
        if (this.hasFiredAflThtrMdEvents) {
            error("refiring AfflThtrMd events")
            return
        }
        this.hasFiredAflThtrMdEvents = true

        const location = window.location.toString().includes("fullvideo") ? "fullvideo" : "non-fullvideo"
        const afflThtrMdCookieName = `${noAffiliateFullvideoName}_in`
        const hasAfflThtrMdCookie = getCookieOrUndefined(afflThtrMdCookieName) !== undefined
        if (afflThtrMdExperiment.eligible) {
            if (!hasAfflThtrMdCookie) {
                addPageAction("AfflThtrMd_mystery", {
                    group: afflThtrMdExperiment.active ? "active": "inactive",
                    page: location,
                })
            } else {
                deleteCookie(afflThtrMdCookieName)

                addPageAction("AfflThtrMd", {
                    group: afflThtrMdExperiment.active ? "active": "inactive",
                    page: location,
                })

                const isControlFullvideo = !afflThtrMdExperiment.active && this.videoMode === VideoMode.Fullvideo
                const isActiveTheaterMode = afflThtrMdExperiment.active && this.videoMode === VideoMode.Theater
                if (isControlFullvideo || isActiveTheaterMode) {
                    afflThtrMdExperiment.record()
                }
            }
        } else {
            if (window.location.search.includes("gLDS") && window.location.search.includes("gQZJO")) {
                addPageAction("AfflThtrMd_missing", { page: location, is_anon: isAnonymous(), came_from_in: hasAfflThtrMdCookie })
            }
            if (hasAfflThtrMdCookie) {
                addPageAction("AfflThtrMd_odd_cookie", { page: location, is_anon: isAnonymous() })
            }
        }
    }
}

export const videoModeHandler = new VideoModeHandler()
