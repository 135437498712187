import { ArgJSONMap , titleCase } from "@multimediallc/web-utils"
import { pageContext } from "../cb/interfaces/context"
import { getCb } from "./api"
import { addPageAction }  from "./newrelic"

export const bioInfoSectionHeadLength = 150

export interface IBioPhotoset {
    id: number
    name: string
    coverUrl: string
    tokens: number
    isVideo: boolean
    videoHasSound: boolean
    userCanAccess: boolean
    fanClubOnly: boolean
    fanClubUnlock: boolean
    labelText: string
    labelColor: string
    pendingApproval: boolean
    failedApproval: boolean
    rejectionNotes: string
    videoIsReady: boolean
    videoErrorProcessing: boolean
    userHasPurchased: boolean
}

export interface IBioSocialMedia {
    id: number
    titleName: string
    imageUrl: string
    link: string
    popup: boolean
    tokens: number
    purchased: boolean
    labelText: string
    labelColor: string
}

export interface IBioParsed {
    infoSection: IInfoSection
    aboutMe: string
    wishList: string
    photoSets: IBioPhotoset[]
    socialMedias: IBioSocialMedia[]
    isBroadcasterOrStaff: boolean
    gameSelection?: IBioGame
}

export interface IBioGame {
    description: string
    gameUrl: string
    image: string
    name: string
    uid: string
    viewers: number
}

export interface IInfoSection {
    username: string
    fanClubCost: number
    isInFanClub: boolean
    hasFanClub: boolean
    fanClubJoinUrl: string
    followersCount: number
    location: string
    languages: string | undefined
    displayAge: number
    sex: string
    subgender: string
    interestedIn: string
    realName: string
    bodyDecorations: string
    displayBirthday: string
    lastBroadcast: string | undefined
    smokeDrink: string
    bodyType: string
    roomStatus: string
    needsSupporterToPm: boolean
}

export interface IMainContent {
    main: HTMLDivElement
    infoSection?: HTMLDivElement
    socialMediaSection?: HTMLDivElement
    photoVideoSection?: HTMLDivElement
    userContentSection?: HTMLDivElement
    wishListSection?: HTMLDivElement
}

export function parseBioApiResponse(str: string, username: string): IBioParsed {
    const parser = new ArgJSONMap(str)
    const getPhotoSets = (photosets: string) => {
        const r: IBioPhotoset[] = []
        for (const obj of JSON.parse(photosets)) {
            const parser = new ArgJSONMap(JSON.stringify(obj))
            r.push({
                id: parser.getNumber("id"),
                name: parser.getString("name"),
                coverUrl: parser.getString("cover_url"),
                tokens: parser.getNumber("tokens"),
                isVideo: parser.getBoolean("is_video"),
                videoHasSound: parser.getBoolean("video_has_sound"),
                userCanAccess: parser.getBoolean("user_can_access"),
                fanClubOnly: parser.getBoolean("fan_club_only"),
                fanClubUnlock: parser.getBoolean("fan_club_unlock"),
                labelText: parser.getString("label_text"),
                labelColor: parser.getString("label_color"),
                pendingApproval: parser.getBoolean("pending_approval", false, false),
                failedApproval: parser.getBoolean("failed_approval", false, false),
                rejectionNotes: parser.getString("rejection_notes", false),
                videoIsReady: parser.getBoolean("video_ready", false, false),
                videoErrorProcessing: parser.getBoolean("video_error_processing", false, false),
                userHasPurchased: parser.getBoolean("user_has_purchased"),
            })
            parser.logUnusedDebugging("parseBioApiResponse.photoset")
        }
        return r
    }
    const getSocialMedias = (socials: string) => {
        const r: IBioSocialMedia[] = []
        for (const obj of JSON.parse(socials)) {
            const parser = new ArgJSONMap(JSON.stringify(obj))
            r.push({
                id: parser.getNumber("id"),
                titleName: parser.getString("title_name"),
                imageUrl: parser.getString("image_url"),
                link: parser.getString("link"),
                popup: parser.getBoolean("popup_link"),
                tokens: parser.getNumber("tokens"),
                purchased: parser.getBoolean("purchased"),
                labelText: parser.getString("label_text"),
                labelColor: parser.getString("label_color"),
            })
            parser.logUnusedDebugging("parseBioApiResponse.socials")
        }
        return r
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getGameSelection = (dataObj?: Record<string, any>): IBioGame | undefined => {
        if (dataObj === undefined) {
            return undefined
        }
        return {
            description: dataObj["description"],
            gameUrl: dataObj["game_url"],
            image: dataObj["image_64x64"],
            name: dataObj["name"],
            uid: dataObj["uid"],
            viewers: dataObj["viewers"],
        }
    }

    const r = {
        infoSection: {
            username: username,
            fanClubCost: parser.getNumber("fan_club_cost", false),
            isInFanClub: parser.getBoolean("fan_club_is_member"),
            hasFanClub: parser.getBoolean("performer_has_fanclub"),
            fanClubJoinUrl: `${parser.getString("fan_club_join_url")}?source=${pageContext.current?.PurchaseEventSources["SUPPORTER_SOURCE_JOIN_FAN_CLUB_BUTTON"]}`,
            location: parser.getString("location"),
            interestedIn: parser.getObjectString("interested_in"),
            realName: parser.getString("real_name"),
            sex: titleCase(parser.getString("sex")),
            subgender: titleCase(parser.getString("subgender")),
            followersCount: parser.getNumber("follower_count"),
            displayAge: parser.getNumber("display_age", false),
            bodyDecorations: parser.getString("body_decorations"),
            displayBirthday: parser.getString("display_birthday", false),
            lastBroadcast: parser.getStringOrUndefined("time_since_last_broadcast"),
            smokeDrink: parser.getString("smoke_drink"),
            bodyType: parser.getString("body_type"),
            languages: parser.getStringOrUndefined("languages"),
            roomStatus: parser.getString("room_status"),
            needsSupporterToPm: parser.getBoolean("needs_supporter_to_pm"),
        },
        aboutMe: parser.getString("about_me"),
        wishList: parser.getString("wish_list"),
        photoSets: getPhotoSets(parser.getObjectString("photo_sets")),
        socialMedias: getSocialMedias(parser.getObjectString("social_medias")),
        isBroadcasterOrStaff: parser.getBoolean("is_broadcaster_or_staff"),
        gameSelection: getGameSelection(parser.getObjectOrUndefined("game_selection")),
    } as IBioParsed
    parser.logUnusedDebugging("parseBioApiResponse.bioParsed")
    addPageAction("BioLoaded")
    return r
}

export function getBioContentPromise(username: string): Promise<IBioParsed> {
    return new Promise<IBioParsed>((resolve, reject) => {
        getCb(`api/biocontext/${username}/`).then(xhr => {
            resolve(parseBioApiResponse(xhr.responseText, username))
        }).catch(e => {
            reject(e)
        })
    })
}

export function addPhotoSetClickedPageAction(photoset: IBioPhotoset, source: string): void {
    addPageAction("PhotoSetClicked", {
        "source": source,
        "photoset_id": photoset.id,
        "cost": photoset.tokens,
        "is_video": photoset.isVideo,
    })
}

/**
 * Contain user created HTML content (e.g. bio, wishlist, etc.) within a bounding container.
 * @param content HTML element that has user content
 * @param boundingElem HTML element to which user content should be contained
 * @param extraSanitation Will call `ensureElemWithinBounds` on each element
 * @param resizeImages Will resize images to half their size
 */
export function sanitizeUserCreatedContent(
    content: HTMLElement,
    boundingElem: HTMLElement,
    extraSanitation = false,
    resizeImages = false,
): void {
    const allElems = content.getElementsByTagName("*")
    boundingElem.style.position = "relative"
    // Z-index 0 makes sure that the custom bio content can have a user defined z-index stacking of its own
    // without overlapping with any cb-related components
    boundingElem.style.zIndex = "0"

    for (const elem of allElems) {
        if (!(elem instanceof HTMLElement)) {
            continue
        }

        if (elem.style.position.includes("fixed")) {
            elem.style.position = "absolute"
        }

        if (extraSanitation) {
            ensureElemWithinBounds(elem)
        }

        if (resizeImages && elem.tagName.toLowerCase() === "img") {
            const imageCopy = elem.cloneNode() as HTMLImageElement
            imageCopy.style.visibility = "hidden"
            document.body.appendChild(imageCopy)

            imageCopy.onload = () => {
                elem.style.height = `${imageCopy.offsetHeight * 0.5}px`
                elem.style.width = `${imageCopy.offsetWidth * 0.5}px`
                document.body.removeChild(imageCopy)
            }

            imageCopy.onerror = () => {
                document.body.removeChild(imageCopy)
            }
        }
    }
}

function ensureElemWithinBounds(elem: HTMLElement): void {
    // Fix negative top elements
    if (elem.style.top !== null && parseInt(elem.style.top, 10) < 0) {
        elem.style.top = "0"
    }
    if (elem.style.marginTop !== null && parseInt(elem.style.marginTop, 10) < 0) {
        elem.style.marginTop = "0"
    }
    // Fix negative left elements
    if (elem.style.left !== null && parseInt(elem.style.left, 10) < 0) {
        elem.style.left = "0"
    }
    if (elem.style.marginLeft !== null && parseInt(elem.style.marginLeft, 10) < 0) {
        elem.style.marginLeft = "0"
    }
}
