import { EventRouter, eventsPmSessionsCount } from "../events"
import { IUserChatSettings } from "../roomDossier"
import { ITipRequest } from "../specialoutgoingmessages"
import { IPrivateShowRequest } from "./privateShowRequestModal"

export const switchedToHLS = new EventRouter<undefined>("switchedToHLS")

export const openDefaultTipCalloutRequest = new EventRouter<ITipRequest>("openDefaultTipCalloutRequest")

export const loginOverlayRequest = new EventRouter<boolean>("loginOverlayRequest")

export const userChatSettingsUpdate = new EventRouter<IUserChatSettings>("userChatSettingsUpdate", {
    listenersWarningThreshold: () => 50 + 2 * eventsPmSessionsCount, // Base limit + 2 per pm session
})

export const openPhotoVideoTabRequest = new EventRouter<number | undefined>("openPhotoVideoTabRequest")

export const privateShowSplitModeRequest = new EventRouter<IPrivateShowRequest|undefined>("privateShowSplitModeRequest")

export const privateShowRequestOverlayDismiss = new EventRouter<undefined>("privateShowRequestOverlayDismiss")

export const resetPrivateShowLink = new EventRouter<undefined>("resetPrivateShowLink")
