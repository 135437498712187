import { ArgJSONMap } from "@multimediallc/web-utils"
import { modalAlert } from "../../../common/alerts"
import { isAnonymous } from "../../../common/auth"
import { roomLoaded } from "../../../common/context"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { createHoverText, hoverEvent } from "../../../common/DOMutils"
import { EventRouter, ListenerGroup } from "../../../common/events"
import { featureFlagIsActive } from "../../../common/featureFlag"
import { addPageAction } from "../../../common/newrelic"
import { printCatch } from "../../../common/promiseUtils"
import { RoomStatus } from "../../../common/roomStatus"
import { i18n } from "../../../common/translation"
import { pageContext } from "../../interfaces/context"
import { showLoginOverlay } from "../../ui/loginOverlay"
import { PromoteDisabledModal } from "./promoteDisabledModal"
import { PromoteRoomConfirmation } from "./promoteRoomConfirmation"
import { EligibilityStatus, PromotionApi } from "./promotionApi"
import type { BoundListener } from "../../../common/events"
import type { IRoomStatusChangeNotification } from "../../../common/messageInterfaces"

// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
interface PromoteLinkProps {}

interface PromoteLinkState {
    eligibility: string
    price: number
    isActive: boolean  // Link status
    roomStatus: RoomStatus  // FE perspective
}

export const userPromotionPush = new EventRouter<undefined>("userPromotionPush")
const refreshDataErrorCodes = ["too_soon", "unauthorized"]  // User error msgs to show during refresh errors
const PROMOTION_UNAVAILABLE_STATUSES = new Set([RoomStatus.Unknown, RoomStatus.NotConnected])
const promotionReactivateTimeout = 5.5 * 1000 * 60  // 5.5 minutes

export class PromoteLinkBase extends HTMLComponent<HTMLElement, PromoteLinkProps, PromoteLinkState> {
    private unavailableModal: PromoteDisabledModal | undefined
    private confirmModal: PromoteRoomConfirmation | undefined
    private slug: string
    private listenerGroup = new ListenerGroup()
    private statusChangeListener: BoundListener<IRoomStatusChangeNotification> | undefined

    constructor(props: PromoteLinkProps) {
        super(props)

        // Avoid making API calls when anons can't access feature anyway
        if (isAnonymous()) {
            return
        }

        roomLoaded.listen((context) => {
            // Prevent API call from being made twice on initial load
            // of every room
            let initialCallMade = false
            const updateRoomStatus = (status: RoomStatus) => {
                const previousStatus = this.state.roomStatus
                this.setState({
                    ...this.state,
                    roomStatus: status,
                })

                // Edge-case for when user enter rooms in private status and changes to public
                // without refreshing the data
                if (
                    [
                        RoomStatus.PrivateNotWatching,
                        RoomStatus.Away,
                        RoomStatus.PrivateWatching,
                        RoomStatus.Hidden,
                        RoomStatus.PasswordProtected,
                    ].includes(previousStatus) &&
                    this.state.eligibility ===
                        EligibilityStatus.ACCOUNT_INELIGIBLE
                ) {
                    if (initialCallMade) {
                        void this.refreshData()
                    }
                } else {
                    this.updateActiveState()
                }
            }

            this.slug = context.dossier.room
            updateRoomStatus(context.dossier.roomStatus)

            // Detects when room status has changed to disable the link accordingly
            if (this.statusChangeListener === undefined) {
                this.statusChangeListener = context.chatConnection.event.statusChange.listen(notif => {
                    updateRoomStatus(notif.currentStatus)
                })
                this.statusChangeListener?.addTo(this.listenerGroup)
            }

            this.refreshData().then(() => {
                initialCallMade = true
            }).catch((err) => {
                printCatch(err)
            })
        }).addTo(this.listenerGroup)

        // Detects when promotion has started to disable the link
        userPromotionPush.listen(() => {
            this.promotionStartUpdate()
        }).addTo(this.listenerGroup)
    }

    protected initData(props: PromoteLinkProps): void {
        super.initData(props)
        // Set default state as disabled, until promotion eligibility is confirmed from API
        this.setState({
            eligibility: EligibilityStatus.ACCOUNT_INELIGIBLE,
            price: 0,
            isActive: false,
            roomStatus: RoomStatus.Unknown,
        })
        if (featureFlagIsActive("PremPrivShow")) {
            const tooltip = createHoverText()
            tooltip.style.bottom = "48px"
            if (pageContext.current.loggedInUser === undefined) {
                tooltip.textContent = i18n.userPromotionMustBeLoggedInAlert
            } else {
                tooltip.textContent = i18n.cannotPromoteRoom
            }
            this.element.appendChild(tooltip)
            hoverEvent(this.element).listen((hovering) => {
                if (!this.state.isActive) {
                    tooltip.style.display = hovering ? "block" : "none"
                    tooltip.style.opacity = hovering ? "1" : "0"
                }
            })
        }
    }

    // Fetch for updated price and room-eligibility
    private refreshData(): Promise<void> {
        const query = new URLSearchParams(window.location.search)
        query.set("slug", this.slug)
        return PromotionApi.promotionPrice(query.toString()).then((xhr: XMLHttpRequest) => {
            const priceDataMap = new ArgJSONMap(xhr.responseText)
            const eligibility = priceDataMap.getString("eligibility")
            const price = priceDataMap.getNumber("token_price")
            this.setState({
                ...this.state,
                eligibility: eligibility,
                price: price,
            })
            this.updateActiveState()
        }).catch((err) => {
            printCatch(err)
        })
    }

    // This will enable/disable link
    public updateActiveState(): void {
        const active = (
            this.state.roomStatus === RoomStatus.Public && this.state.eligibility === EligibilityStatus.ELIGIBLE
        )
        if (!active && this.confirmModal?.isShown() === true) {
            this.confirmModal?.hide()
        }
        this.setState({ ...this.state, isActive: active })
    }

    protected clickHandler(): void {
        addPageAction("ViewerPromoteLinkClick", {
            "activeState": `${this.state.isActive}`,
        })
        if (isAnonymous()) {
            showLoginOverlay({ fromFeature: true })
            return
        }

        if (this.state.roomStatus !== RoomStatus.Public) {
            modalAlert(PROMOTION_UNAVAILABLE_STATUSES.has(this.state.roomStatus)
                ? i18n.promotionUnavailableTitle
                : i18n.promotionUnavailableOffline)
            return
        }

        if (pageContext.current.isNoninteractiveUser) {
            modalAlert(i18n.internalStaffPromotion)
            return
        }

        // Check eligibility status and price, show confirmation or unavailable message accordingly
        this.refreshData().then(() => {
            if (this.state.isActive) {
                this.showConfirmationModal()
            } else if (this.state.eligibility === EligibilityStatus.PURCHASER_INELIGIBLE) {
                modalAlert(i18n.promotionIneligibleUserMessage)
            } else {
                this.showDisabledModal()
            }
        }).catch((err) => {
            const responseMap = new ArgJSONMap(err.xhr.responseText)
            const code = responseMap.getStringOrUndefined("code")
            const detail = responseMap.getStringOrUndefined("detail")
            if (refreshDataErrorCodes.includes(code ?? "") && detail !== undefined) {
                modalAlert(detail)
            } else {
                modalAlert(i18n.feedbackUnknownError)
            }
            addPageAction("ViewerPromoteRefreshError", {
                "status": code ?? err.xhr.statusText,
                "detail": detail ?? err.xhr.responseText,
            })
        })
    }

    private showDisabledModal(): void {
        if (this.unavailableModal === undefined) {
            this.unavailableModal = new PromoteDisabledModal({})
        }
        this.unavailableModal.show()
    }

    private showConfirmationModal(): void {
        if (this.confirmModal === undefined) {
            this.confirmModal = new PromoteRoomConfirmation({
                slug: this.slug,
                showDisabledFunc: () => {
                    this.showDisabledModal()
                },
            })
        }
        this.confirmModal.setPrice(this.state.price)
        this.confirmModal.show()
        addPageAction("ViewerPromoteConfirmShow")
    }

    private promotionStartUpdate(): void {
        this.setState({ ...this.state, eligibility: EligibilityStatus.ACTIVE })
        this.updateActiveState()

        // Automatically re-enable the link after 5.5 minutes (5-min promotion + 30-sec buffer of pending start/end)
        // Even though we don't truly know if the room is eligible for promotion after 5.5 minutes, an active link
        // will encourage the user to click on the link and trigger a proper status update
        window.setTimeout(() => {
            this.setState({ ...this.state, eligibility: EligibilityStatus.ELIGIBLE })
            this.updateActiveState()
        }, promotionReactivateTimeout)
    }

    public dispose(): void {
        this.listenerGroup.removeAll()
    }

    public getRoomStatusState(): RoomStatus {
        return this.state.roomStatus
    }
}
