/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Wrappers for console functions. `log` is not included by design. In most cases,
 * you either want `debug` or `error`
 */

import * as logging from "@multimediallc/logging"

export const enum SubSystemType {
    Default = "",
    Video = "VIDEO_",
    PushService = "PUSH_",
    UNCAUGHT_EXCEPTION = "UNCAUGHT_",
}

declare global {
    const error: (x: any, extra?: any, subSystemType?: SubSystemType) => void
    const warn: (x: any, extra?: any, subSystemType?: SubSystemType) => void
    const info:  (x: any, extra?: any) => void
    const debug: (x: any, extra?: any) => void
}

// debug.js handles lifting these to global scope
export const error = logging.error
export const warn = logging.warn
export const info = logging.info
export const debug = logging.debug

// Global exception handler
window.onerror = (msg: string | Event, url?: string, lineNo?: number, columnNo?: number, err?: Error): boolean => {
    let errorMessage: string
    try {
        errorMessage =  JSON.stringify(err)
    } catch (e) {
        errorMessage = `${err}`
    }
    const message = [
        `"Message: " ${msg}`,
        `"URL: "  ${url}`,
        `"Line: " ${lineNo}`,
        `"Column: " ${columnNo}`,
        `"Error object: " ${errorMessage}`,
    ].join("\n")
    error(message, {}, SubSystemType.UNCAUGHT_EXCEPTION)
    return false // still log the global error
}
