import { ChatReport } from "../chatReport"
import { IChatConnection } from "../context"
import { IRoomMessage } from "../messageInterfaces"
import { getViewportHeight, getViewportWidth } from "./viewportDimension"
import { isPortrait } from "./windowOrientation"

export class MobileChatReport extends ChatReport {
    constructor(username: string, message: IRoomMessage, chatConnection: IChatConnection | undefined) {
        super(username, message, chatConnection)

        this.element.style.height = "auto"
        this.element.style.width = "auto"
        this.element.style.maxWidth = "90%"
        this.element.style.background = "white"
        this.element.style.borderRadius = "4px"
        this.element.style.margin = "auto"
        this.element.style.position = "absolute"
        this.element.style.boxShadow = "0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.25)"

        this.commentInput.style.fontSize = "16px" // prevent iOS zooming in on inputs
    }

    repositionChildren(): void {
        super.repositionChildren()

        if (isPortrait() && getViewportWidth() < 768) {
            this.element.style.width = "90%"
        } else {
            const portraitScreenWidth = Math.min(screen.width, screen.height)
            this.element.style.width = `${portraitScreenWidth * 0.9}px`
        }

        this.element.style.top = `${(getViewportHeight() - this.element.offsetHeight) / 2}px`
        this.element.style.left = `${(getViewportWidth() - this.element.offsetWidth) / 2}px`
    }

    protected onIgnoreUser(): void {
        // Override to do nothing.
    }
}
