import { dom } from "../../../common/tsxrender/dom"

export function expandArrow(): SVGElement {
    return <svg width="1.2em" height="1.2em" viewBox="0 -1 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ backgroundColor: "rgba(201, 201, 201, 0.4)", padding: "3px", borderRadius: "50%" }}>
        <path d="M1.74355 1.29511C2.11737 0.939948 2.70361 0.938914 3.07868 1.29276L6.31378 4.34474C6.69906 4.70821 7.30094 4.70821 7.68622 4.34474L10.9213 1.29276C11.2964 0.938915 11.8826 0.939948 12.2565 1.29511V1.29511C12.6604 1.67886 12.6592 2.32304 12.254 2.70536L7.68622 7.01455C7.30094 7.37802 6.69906 7.37802 6.31378 7.01455L1.74604 2.70536C1.34078 2.32304 1.33965 1.67886 1.74355 1.29511V1.29511Z" fill="#033E58"/>
        <path d="M6.65689 3.98104L3.42179 0.92906C2.85358 0.393012 1.96547 0.394579 1.39916 0.932628C0.787271 1.51397 0.788992 2.48987 1.40293 3.06905L5.97067 7.37825C6.54859 7.92346 7.45141 7.92346 8.02933 7.37825L12.5971 3.06905C13.211 2.48987 13.2127 1.51397 12.6008 0.932628C12.0345 0.394579 11.1464 0.393013 10.5782 0.92906L7.34311 3.98104C7.15047 4.16278 6.84953 4.16278 6.65689 3.98104Z" stroke="#EFEFEF" stroke-opacity="0.25"/>
    </svg>
}

export function collapseArrow(): SVGElement {
    return <svg width="1.2em" height="1.2em" viewBox="0 1 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ backgroundColor: "rgba(201, 201, 201, 0.4)", padding: "3px", borderRadius: "50%" }}>
        <path d="M12.2565 7.36681C11.8826 7.72198 11.2964 7.72301 10.9213 7.36917L7.68622 4.31719C7.30094 3.95371 6.69906 3.95371 6.31378 4.31719L3.07868 7.36917C2.70361 7.72301 2.11737 7.72198 1.74355 7.36681V7.36681C1.33965 6.98307 1.34078 6.33889 1.74604 5.95657L6.31378 1.64738C6.69906 1.2839 7.30094 1.2839 7.68622 1.64738L12.254 5.95657C12.6592 6.33889 12.6604 6.98307 12.2565 7.36681V7.36681Z" fill="#033E58"/>
        <path d="M7.34311 4.68089L10.5782 7.73287C11.1464 8.26891 12.0345 8.26735 12.6008 7.7293C13.2127 7.14795 13.211 6.17206 12.5971 5.59287L8.02933 1.28368C7.45141 0.738468 6.54859 0.738468 5.97067 1.28368L1.40293 5.59287C0.788992 6.17206 0.787271 7.14795 1.39916 7.7293C1.96547 8.26735 2.85358 8.26891 3.42179 7.73287L6.65689 4.68089C6.84953 4.49915 7.15047 4.49915 7.34311 4.68089Z" stroke="#EFEFEF" stroke-opacity="0.25"/>
    </svg>
}
