import { EventRouter } from "../events"
import { ITipRequest } from "../specialoutgoingmessages"
import { TabName } from "./tabList"
import { IUserPanelRequest } from "./userPanel"

export interface IUserInitiatePmNotification {
    username: string
    focus: boolean  // Whether to switch to the tab or not.
    showSupporterAlert: boolean  // Whether to show alert about supporter subscription or not.
}

export const switchedToHLS = new EventRouter<undefined>("switchedToHLS")

export const openTipCalloutRequest = new EventRouter<ITipRequest>("openTipCalloutRequest")

export const loadRoomRequest = new EventRouter<string>("loadRoomRequest")

export const userPanelRequest = new EventRouter<IUserPanelRequest>("userPanelRequest")

export const sendMessageInputFocus = new EventRouter<undefined>("sendMessageInputFocus")

export const sendMessageInputBlur = new EventRouter<undefined>("sendMessageInputBlur")

export const siteHeaderMenuOpened = new EventRouter<boolean>("siteHeaderMenuOpened")

export const userSwitchedTab = new EventRouter<TabName>("userSwitchedTab")

export const toggleDms = new EventRouter<boolean>("toggleDms")
