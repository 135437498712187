import { RoomUsers } from "../../cb/roomUsers"
import { roomLoaded } from "../context"
import { applyStyles } from "../DOMutils"
import { EventRouter } from "../events"
import { RoomStatus } from "../roomStatus"
import { i18n } from "../translation"
import { MenuComponent } from "./menuComponent"
import { createUsernameLabel } from "./userToDOM"
import { getViewportWidth } from "./viewportDimension"
import type { IChatConnection } from "../context"
import type { IUserInfo } from "../messageInterfaces"

export const userCountUpdate = new EventRouter<number>("userCountUpdate")

export class UserList extends MenuComponent {
    private userCount = 0
    private isUserListOpen = false
    private userList: IUserInfo[] = []
    private chatConn?: IChatConnection
    private wrapper: HTMLDivElement
    private header: HTMLElement | undefined

    constructor() {
        super(getViewportWidth() - 50, undefined, true)
        this.wrapper = this.createBaseElement("div")
        this.element.appendChild(this.wrapper)
        this.createLoadingDiv()
        applyStyles(this.wrapper, {
            backgroundColor: "#ffffff",
            padding: "19px",
            boxSizing: "border-box",
            fontFamily: "UbuntuRegular, Arial, Helvetica, sans-serif",
            height: "calc(100% - 45px)",
            fontSize: "14px",
            overflowY: "scroll",
        })

        roomLoaded.listen((context) => {
            this.chatConn = context.chatConnection
            this.chatConn.event.statusChange.listen((e) => {
                const cameFromOrEnteredPrivateChat = e.previousStatus === RoomStatus.PrivateWatching ||
                    e.currentStatus === RoomStatus.PrivateWatching
                if (e.previousStatus === RoomStatus.NotConnected || cameFromOrEnteredPrivateChat) {
                    window.setTimeout(() => {
                        this.refreshUsers()
                    }, 2000)
                }
            })
            this.chatConn.event.roomCountUpdate.listen((count) => {
                this.userCount = count
                userCountUpdate.fire(this.userCount)
            })
            userCountUpdate.listen((count) => {
                this.userCount = count
                if (this.header !== undefined) {
                    const oldHeader = this.header
                    this.header = this.createHeadingDiv()
                    this.wrapper.replaceChild(this.header, oldHeader)
                }
            })
        })
    }

    private removeUserListChildren(): void {
        this.header = undefined
        while (this.wrapper.firstChild !== null) {
            this.wrapper.removeChild(this.wrapper.firstChild)
        }
    }

    private createLoadingDiv(): void {
        const loadingDiv = document.createElement("div")
        loadingDiv.innerText = "Loading..."
        loadingDiv.style.margin = "6px"
        this.wrapper.appendChild(loadingDiv)
    }

    private createHeadingDiv(): HTMLDivElement {
        const headingDiv = document.createElement("div")
        const userCountDiv = document.createElement("div")

        applyStyles(headingDiv, {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
        })
        applyStyles(userCountDiv, { fontSize: "16px" })

        headingDiv.appendChild(userCountDiv)
        headingDiv.appendChild(this.createRefreshUsersLink())
        userCountDiv.innerText = `${i18n.usersText} (${this.userCount})`
        return headingDiv
    }

    private createRefreshUsersLink(): HTMLDivElement {
        const refreshDiv = document.createElement("div")
        refreshDiv.innerText = i18n.refreshUserListSentenceCase
        applyStyles(refreshDiv, {
            cursor: "pointer",
            color: "#306A91",
        })
        refreshDiv.onmouseenter = () => {
            refreshDiv.style.color = "#000000"
        }
        refreshDiv.onmouseleave = () => {
            refreshDiv.style.color = "#999999"
        }
        refreshDiv.onclick = ev => {
            this.removeUserListChildren()
            this.createLoadingDiv()
            this.refreshUsers()
        }

        return refreshDiv
    }

    private refreshUsers(): void {
        if (this.chatConn === undefined) {
            // no chat connection yet
            return
        }

        RoomUsers.getInstance().fetchRoomUsers().then((roomUsersInfo) => {
            userCountUpdate.fire(roomUsersInfo.totalCount)
            this.userCount = roomUsersInfo.totalCount
            this.removeUserListChildren()
            this.wrapper.appendChild(this.createHeadingDiv())
            this.userList = roomUsersInfo.roomUsers
            for (const user of this.userList) {
                const usernameDiv = document.createElement("div")
                usernameDiv.style.margin = "0 0 10px"
                usernameDiv.style.cursor = "pointer"
                usernameDiv.style.whiteSpace = "nowrap"
                usernameDiv.style.overflow = "hidden"
                usernameDiv.style.maxWidth = "100%"
                usernameDiv.style.textOverflow = "ellipsis"
                usernameDiv.appendChild(createUsernameLabel(user, undefined, true))
                this.wrapper.appendChild(usernameDiv)
            }
            const anonUsersDiv = document.createElement("div")
            anonUsersDiv.innerText = `+${roomUsersInfo.anonCount} anonymous user${roomUsersInfo.anonCount === 1 ? "" : "s"}`
            applyStyles(anonUsersDiv, {
                width: "100%",
                whiteSpace: "nowrap",
                color: "#A7A7A7",
                margin: "0 0 10px",
            })
            this.wrapper.appendChild(anonUsersDiv)
            this.repositionChildren()
        }).catch((err) => {
            error("Error retrieving mobile user list", err)
        })
    }

    public getUserCount(): number {
        return this.userCount
    }

    public isOpen(): boolean {
        return this.isUserListOpen
    }

    public show(): void {
        super.show()
        this.isUserListOpen = true
        this.refreshUsers()
    }

    public hide(): void {
        super.hide()
        this.isUserListOpen = false
    }

    repositionChildren(): void {
        this.width = getViewportWidth() - 50
    }
}
