import { newrelic, type NewrelicAttributes } from "@multimediallc/web-utils"
import { isPerformanceNowSupported } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly, removeEventListenerPoly } from "./addEventListenerPolyfill"
import { postCb } from "./api"
import { VideoMode } from "./videoModeHandler"
import type { MobileMetricsVideoMode } from "./mobilelib/sessionMetrics"


let currentRoom: string | undefined
let currentPlayer: string | undefined
let currentMode: VideoMode | MobileMetricsVideoMode | undefined

export function addNamespaceAndDefault(attributes: NewrelicAttributes, modernTiming?: boolean): NewrelicAttributes {
    const namespacedAttributes: NewrelicAttributes = {}
    namespacedAttributes["attributes.room_user"] = currentRoom ?? ""
    if (currentMode !== undefined) {
        namespacedAttributes["attributes.currentMode"] = currentMode
    }
    for (const key in attributes) {
        namespacedAttributes[`attributes.${key}`] = attributes[key]
    }
    if (modernTiming !== undefined && modernTiming && isPerformanceNowSupported()) {
        const diff = window.performance.now()
        namespacedAttributes["attributes.timeSinceLoad"] = diff / 1000.0
        namespacedAttributes["attributes.timeSinceLoadMS"] = diff
    }
    if (currentPlayer !== undefined) {
        namespacedAttributes["attributes.player"] = currentPlayer
    }
    if (window.history.length !== undefined) {
        namespacedAttributes["history_length"] = window.history.length
    }

    return namespacedAttributes
}

export function newRelicEnabled(): boolean {
    return newrelic.newRelicEnabled()
}

export function addPlayerAndTipVolume(attributes: NewrelicAttributes = {}): void {
    if (NRGetPlayerVolume() !== -1) {
        attributes["PlayerVol"] = NRGetPlayerVolume()
    }
    if (NRGetTipVolume() !== -1) {
        attributes["TipVol"] = NRGetTipVolume()
    }
}

export function addPageAction(name: string, attributes: NewrelicAttributes = {}, modernTiming?: boolean): void {
    addPlayerAndTipVolume(attributes)
    const namespacedAttributes = addNamespaceAndDefault(attributes, modernTiming)
    newrelic.addPageAction(name, namespacedAttributes)
}

// This affects all PageActions, past and present, do not use for data that needs to be updated/changed
export function setCustomAttribute(name: string, value: string): void {
    newrelic.setCustomAttribute(name, value)
}

export function setCurrentRoom(room: string): void {
    currentRoom = room
}

export function setCurrentPlayer(player: string): void {
    currentPlayer = player
}

function newRelicErrorDetector(): void {
    removeEventListenerPoly("load", window, newRelicErrorDetector)

    for (const script of document.getElementsByTagName("script")) {
        if (script.src.match(/^https:\/\/js-agent\.newrelic\.com\/.*/) !== null) {
            addEventListenerPoly("error", script, () => {
                error("Ad blocker stopped analytics collection") // won't get to New Relic because it's blocked
                postCb("api/adblocker/", {})  // eslint-disable-line @typescript-eslint/no-floating-promises
            })
        }
    }
}

let tipVol = -1
let playerVol = -1

// Muted player will mute tip volume
function NRGetTipVolume(): number {
    if (playerVol === 0) {
        return 0
    }
    return tipVol
}

export function NRGetPlayerVolume(): number {
    return playerVol
}

export function NRSetTipVolume(newTipVol: number): void {
    tipVol = newTipVol
}

export function NRSetPlayerVolume(newPlayerVol: number, isMuted: boolean): void {
    if (isMuted) {
        playerVol = 0
    }
    else {
        playerVol = newPlayerVol
    }
}

export function setCurrentMode(mode: VideoMode | MobileMetricsVideoMode): void {
    if (mode !== VideoMode.VideoOnly) { // Ignore VideoOnly, since it doesn't have to be recorded -- is in embed, not theatermode interactions.
        currentMode = mode
    }
}

addEventListenerPoly("load", window, newRelicErrorDetector)
