// safeStyle provides functions for safely adding -webkit and and -moz styles when setting the base style

export function styleTransition(e: HTMLElement, value: string): void {
    e.style.webkitTransition = value
    e.style.transition = value
    if (e.style.setProperty !== undefined) {
        e.style.setProperty("moz-transition", value)
    }
}

export function styleTransitionRemove(e: HTMLElement): void {
    e.style.webkitTransition = ""
    e.style.transition = ""
    if (e.style.removeProperty !== undefined) {
        e.style.removeProperty("moz-transition")
    }
}

export function styleUserSelect(e: HTMLElement, value: string): void {
    e.style.userSelect = value
    e.style.webkitUserSelect = value
    e.style["msUserSelect"] = value
    if (e.style.setProperty !== undefined) {
        e.style.setProperty("moz-user-select", value)
    }
}