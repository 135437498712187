import { EventRouter } from "../../../common/events"
import { PushService } from "../pushService"
import { TopicManager } from "./topicManager"
import type { ArgJSONMap } from "@multimediallc/web-utils"

export interface ITopicMessage {
    tid?: string, // tid only optional to match wowza interfaces
    providerId?: string,
    publishMethod?: string,
}


export function addBaseTopicFields(obj: Record<string, unknown>): void {
    obj["providerData"] = { "id": "", "ts": 0 }
    obj["pub_ts"] = 0
    obj["method"] = "UI"
}

export interface ISubscribeChange {
    subscribed: boolean
    isCriticalFail: boolean
    retryCallback?: () => void
}

export abstract class Topic<T extends ITopicMessage, P extends object = object> {
    private authData: P
    public readonly subscribeOnlyOnPrimaryBackend: boolean = false
    public readonly onMessage: EventRouter<T>
    public readonly onAuthFail: EventRouter<undefined>
    public readonly onSubscribeChange: EventRouter<ISubscribeChange>
    public readonly maxListeners: number = 50
    protected routerOptions = {
        listenersWarningThreshold: 5,
        onListenerAdded: (): void => {this.onListenerAdded()},
        onListenerRemoved: (): void => {this.onListenerRemoved()},
    }

    protected constructor(props: P) {
        this.initData(props)
        this.authData = props
        this.onMessage = new EventRouter<T>(this.getKey(), this.routerOptions)
        this.onAuthFail = new EventRouter<undefined>("TopicAuthFail", this.routerOptions)
        this.onSubscribeChange = new EventRouter<ISubscribeChange>("TopicSubscribeChange", this.routerOptions)
    }

    protected abstract initData(props?: P): void
    public abstract getId(): string
    public abstract getKey(): string

    public getAuthKey(): string {
        return `${this.getId()}#${this.getKey()}`
    }

    public parseData(data: ArgJSONMap): ITopicMessage {
        return {
            tid: data.getString("tid"),
            providerId: data.getParsedSubMap("providerData").getString("id"),
            publishMethod: data.getString("method"),
        }
    }

    public isSubscribed(): boolean {
        return PushService.isListeningFor(this.getKey())
    }

    public enterPresence(payload?: object): void {
        TopicManager.enterPresence(this, payload)
    }

    public leavePresence(): void {
        TopicManager.leavePresence(this)
    }

    public getAuthData(): P {
        return this.authData
    }

    protected onListenerAdded(): void {
        const totalCount = this.onMessage.listenerCount() + this.onSubscribeChange.listenerCount() + this.onAuthFail.listenerCount()
        if (totalCount === 1) {
            TopicManager.registerTopic(this)
        }
    }

    protected onListenerRemoved(): void {
        const totalCount = this.onMessage.listenerCount() + this.onSubscribeChange.listenerCount() + this.onAuthFail.listenerCount()
        if (totalCount === 0) {
            TopicManager.removeTopic(this)
        }
    }

    protected uidCheck(uid: string): void {
        // Uids are only ever length 7 (if originally a 32-bit number) or length 14 (64-bit number)
        // We cannot check if a uid truly maps to a valid id in the database, but this should catch
        // most instances of erroneously passing in a username instead of a uid
        if (uid.length !== 7 && uid.length !== 14) {
            throw new Error("Invalid uid passed into topic")
        }
    }
}
