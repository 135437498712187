import { isiOS, isSafari } from "@multimediallc/web-utils/modernizr"
import { addPageAction } from "../newrelic"
import { i18n } from "../translation"

const targetResolutions = [
    [640, 360],
    [960, 540],
    [1280, 720],
]

export interface IDevice {
    id: string,
    label: string
}

export interface IResolution {
    id: string,
    camId: string,
    label: string,
    width: number,
    height: number,
    ratio: number,
    isWidescreen: boolean,
    isHD: boolean,
}

export interface ICam extends IDevice {
    resolutions: Map<string, IResolution>
}

export interface IDevices {
    mics: Map<string, IDevice>
    cams: Map<string, ICam>
}

export function stopTracksWithTimeout(tracks: MediaStreamTrack[], timeout?: number): Promise<void> {
    if (timeout === undefined) {
        timeout = 200
    }
    tracks.forEach(track => {
        track.stop()
    })
    return new Promise<void>((resolve) => {
        window.setTimeout(() => {
            resolve()
        }, timeout)
    })
}

export function stopTracksWithState(tracks: MediaStreamTrack[]): Promise<void> {
    // This method, an alternative to stopTracksWithTimeout, proved unsuccessful.
    // At least on Chrome, it seems that readyState is set to "ended" right away
    // instead of waiting for the tracks to stop. The issues that normally show up
    // when the tracks are not stopped, e.g., getUserMedia not setting width and
    // height correctly, still happen when using this method.
    const stopped = new Map<string, boolean>()

    function stopLive(): void {
        tracks.forEach(track => {
            if (stopped.get(track.id) !== true) {
                track.stop()
            }
        })
    }

    function tracksStopped(): boolean {
        let done = true
        tracks.forEach(track => {
            const ended = track.readyState === "ended"
            stopped.set(track.id, ended)
            if (!ended) {
                done = false
            }
        })
        return done
    }

    stopLive()
    while (!tracksStopped()) {
        window.setTimeout(() => {
            stopLive()
        }, 50)
    }

    return Promise.resolve()
}

function nonFatalUserMediaError(err: Error): boolean {
    // Safari returns "Invalid constraint" in the message instead of the
    // standard "OverconstrainedError" in the name
    if (err.message === "Invalid constraint" ||
        err.name === "OverconstrainedError" ||
        err.name === "NotReadableError") {
        return true
    }
    return false
}

function getDeviceCapabilities(deviceId: string): Promise<MediaTrackCapabilities | undefined> {
    return new Promise((resolve, reject) => {
        navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                deviceId: { exact: deviceId },
            },
        }).then(stream => {
            let capabilities: MediaTrackCapabilities | undefined
            stream.getTracks().forEach(track => {
                // some browsers don't support getCapabilities
                if (capabilities === undefined && track.getCapabilities !== undefined) {
                    capabilities = track.getCapabilities()
                }
            })
            stopTracksWithTimeout(stream.getTracks()).then(() => {
                addPageAction("DeviceCapabilities", {
                    "deviceId": deviceId,
                    "capabilities": JSON.stringify(capabilities),
                    "source": "mediaDevices",
                })
                resolve(capabilities)
            }).catch(() => { })
        }).catch(err => {
            if (nonFatalUserMediaError(err)) {
                resolve(undefined)
                return
            }
            reject(err)
        })
    })
}

function getResolution(deviceId: string, width: number, height: number): IResolution | undefined {
    if (isiOS() && isSafari() && width === 960 && height === 540) {
        // iOS devices on Safari do not support 540p. The stream will return an error if this resolution is attempted.
        return undefined
    }
    const ratio = width / height
    const isWidescreen = ratio > 1.4
    const isHD = height >= 720
    const id = `${width}x${height}`
    const label = `${width} x ${height}`
    return {
        id: id,
        camId: deviceId,
        label: label,
        width: width,
        height: height,
        ratio: ratio,
        isWidescreen: isWidescreen,
        isHD: isHD,
    }
}

function getDeviceResolutions(deviceId: string): Promise<Map<string, IResolution> | undefined> {
    return new Promise((resolve, reject) => {
        getDeviceCapabilities(deviceId).then(capabilities => {
            let maxResolution: [number, number] | undefined
            let scalable = false
            let promises: Promise<[number, number] | undefined>[]
            if (capabilities !== undefined && capabilities.width !== undefined && capabilities.height !== undefined) {
                maxResolution = [(capabilities.width["max"] as number), (capabilities.height["max"] as number)]
                // Browsers are inconsistent and inaccurate in reporting supported resolutions for a device.
                // Here we know that if the device has resizeMode "crop-and-scale" we can scale down from
                // its max resolution. We prefer this heuristic since some browsers will only report a couple
                // of resolutions out all the ones that are possible. However, `resizeMode` is not always supported
                // @ts-ignore ignoring mediaDevices
                if (capabilities["resizeMode"] !== undefined) {
                    // @ts-ignore ignoring mediaDevices
                    scalable = capabilities["resizeMode"].some((mode: string) => mode === "crop-and-scale")
                }
            }
            if (scalable && maxResolution !== undefined) {
                promises = targetResolutions.map(resolution => {
                    return new Promise((resolve) => {
                        const width = resolution[0]
                        const height = resolution[1]
                        if (maxResolution !== undefined && width <= maxResolution[0] && height <= maxResolution[1]) {
                            resolve([width, height])
                            return
                        }
                        resolve(undefined)
                    })
                })
            } else {
                // If we can't scale down the max resolution, we need to rely on the resolutions
                // the browser reports
                promises = targetResolutions.map(resolution => {
                    const width = resolution[0]
                    const height = resolution[1]
                    const constraints = {
                        audio: false,
                        video: {
                            deviceId: { exact: deviceId },
                            width: { exact: width },
                            height: { exact: height },
                        },
                    }
                    // calling applyConstraints() on a single stream instead of getting a new stream
                    // for each of the listed resolutions resolves successfully for every one of the
                    // resolutions, which is not correct
                    return new Promise((resolve, reject) => {
                        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                            stopTracksWithTimeout(stream.getTracks()).then(() => {
                                resolve([width, height])
                            }).catch(() => { })
                        }).catch(err => {
                            if (nonFatalUserMediaError(err)) {
                                resolve(undefined)
                                return
                            }
                            reject(err)
                        })
                    })
                })
            }

            // chain promises so that tracks can stop before a new resolution is tried
            type reduceType = Promise<[number, number][]>

            const values = promises.reduce<reduceType>((chain, promise) => {
                return chain.then(values => {
                    return promise.then(value => {
                        if (value !== undefined) {
                            values.push(value)
                        }
                        return Promise.resolve(values)
                    }).catch(() => [])
                })
            }, Promise.resolve([]))

            // Some browsers return an error even when you use the max resolution (as reported by
            // the browser) in the constraints. Here we make sure we add the max resolution,
            // if it's in the list of resolutions we want to include.
            // We are trusting here that the browser didn't report that the device supports
            // resolutions higher than its max resolution
            Promise.resolve(values).then(values => {
                if (maxResolution !== undefined) {
                    const supported = targetResolutions.some(resolution => {
                        return (
                            maxResolution !== undefined &&
                            resolution[0] === maxResolution[0] &&
                            resolution[1] === maxResolution[1]
                        )
                    })
                    if (supported &&
                        values[values.length - 1][0] !== maxResolution[0] &&
                        values[values.length - 1][1] !== maxResolution[1]) {
                        values.push(maxResolution)
                    }
                }
                const devices = new Map<string, IResolution>()
                values.forEach((res: [number, number]) => {
                    // Because of the heuristic above we can't rely on other constraints to be present, such as
                    // `aspectRation` or `frameRate`
                    const ires = getResolution(deviceId, res[0], res[1])
                    if (ires !== undefined) {
                        devices.set(ires.id, ires)
                    }
                })
                resolve(devices)
            }).catch(() => { })
        }).catch(err => {
            reject(err)
        })
    })
}

export function enumerateDevices(findResolutions?: boolean, stopTimeout?: number): Promise<IDevices> {
    return new Promise((resolve, reject) => {
        let grantedPermsAction = "DevicePermsOnLoadGranted"
        let rejectedPermsAction = "DevicePermsOnLoadDenied"
        let firefoxPermanentPermsAction = "PermanentDevicePermsOnLoadDenied"
        const startTime = Date.now()
        // For these pageactions, `OnLoad` describes when permissions are already granted/denied on page load, and
        // 'Request` describes when they are granted/denied through user interaction with the browser prompt.
        // Experimentally, checking Chrome, FF, and Safari, when perms are already granted/denied, this code runs in
        // <400ms. For user interaction it can be as low as 600ms, but realistically will never be <1000ms unless
        // they're very deliberately doing it as fast as possible.
        // Therefore choosing 1000ms as the cutoff to distinguish when to fire `OnLoad`s vs `Request`s. It should
        // accommodate slower browsers without really risking bad data
        window.setTimeout(() => {
            grantedPermsAction = "DevicePermsRequestGranted"
            rejectedPermsAction = "DevicePermsRequestDenied"
            firefoxPermanentPermsAction = "PermanentDevicePermsRequestDenied"
        }, 1000)

        // We need to get the user's permission to access the devices before we can retrieve the device label
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(stream => {
            stopTracksWithTimeout(stream.getTracks(), stopTimeout).then(() => {
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    const promises = Promise.all(
                        devices.map(device => {
                            if (device.kind === "videoinput") {
                                if (findResolutions !== undefined && findResolutions) {
                                    return getDeviceResolutions(device.deviceId)
                                        .then(values => {
                                            return Promise.resolve({ "device": device, "resolutions": values })
                                        })
                                        .catch(err => {
                                            return Promise.reject(err)
                                        })
                                }
                                const resolutions = new Map<string, IResolution>()
                                targetResolutions.forEach((res: [number, number]) => {
                                    const ires = getResolution(device.deviceId, res[0], res[1])
                                    if (ires !== undefined) {
                                        resolutions.set(ires.id, ires)
                                    }
                                })
                                return Promise.resolve({ "device": device, "resolutions": resolutions })
                            }
                            return Promise.resolve({ "device": device })
                        }),
                    )
                    const res = {
                        mics: new Map<string, IDevice>(),
                        cams: new Map<string, ICam>(),
                    }


                    promises.then(devices => {
                        let blankLabels = true
                        devices.forEach(d => {
                            const device = d["device"]
                            if (device.label !== "") {
                                blankLabels = false
                            }
                            if (device.kind === "audioinput") {
                                res.mics.set(device.deviceId, {
                                    id: device.deviceId,
                                    label: device.label,
                                })
                            } else if (
                                device.kind === "videoinput" &&
                                // @ts-ignore ignoring mediaDevices
                                d["resolutions"] !== undefined &&
                                // @ts-ignore ignoring mediaDevices
                                d["resolutions"].size > 0
                            ) {
                                res.cams.set(device.deviceId, {
                                    id: device.deviceId,
                                    label: device.label,
                                    // @ts-ignore ignoring mediaDevices
                                    resolutions: d["resolutions"],
                                })
                            }
                        })
                        if (blankLabels) {
                            // From https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo
                            // For security reasons, the label field is always blank unless an active media stream exists or the
                            // user has granted persistent permission for media device access. The set of device labels could
                            // otherwise be used as part of a fingerprinting mechanism to identify a user.
                            const msg = i18n.permanentPermsMessage
                            addPageAction(firefoxPermanentPermsAction, { "time_elapsed_ms": Date.now() - startTime })
                            reject(new Error(msg))
                            return
                        }
                        addPageAction(grantedPermsAction, { "time_elapsed_ms": Date.now() - startTime })
                        resolve(res)
                    }).catch(err => {
                        reject(err)
                    })
                }).catch(() => { })
            }).catch(() => { })
        }).catch(err => {
            addPageAction(rejectedPermsAction, { "time_elapsed_ms": Date.now() - startTime })
            reject(err)
        })
    })
}

export function checkDevicePermsGranted(): Promise<boolean> {
    return navigator.mediaDevices.enumerateDevices().then(devices => {
        let hasMicrophone = false
        let hasWebcam = false
        devices.forEach((device) => {
            if (device.label === "") {
                return
            }

            if (device.kind === "audioinput") {
                hasMicrophone = true
            }
            if (device.kind === "videoinput") {
                hasWebcam = true
            }
        })
        return hasMicrophone && hasWebcam
    })
}
