import { HTMLComponent } from "../../common/defui/htmlComponent"
import { ReactComponentRegistry } from "./ReactRegistry"
import type { ReactComponent } from "./ReactRegistry"

interface ReactCompProps {
    component: string
    reactRef?: (ref: ReactComponent) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentProps: Record<string, any>
}

/**
 * @class ReactWrapper
 * @extends HTMLComponent
 * @description A component that fetches the correct react component from the
 * component registry, renders onto a dummy element, and returns the inner
 * (actual) react element
 * @example
 * <ReactWrapper component="MyComponent" componentProps={{ content: "Hello World" }} />
 */
export class ReactWrapper extends HTMLComponent<HTMLElement, ReactCompProps> {
    private reactComponent: ReactComponent

    constructor(props: ReactCompProps) {
        super(props)
        // ts should not be "ReactWrapper" but instead the name of the component
        this.element.setAttribute("ts", `${props.component}Wrapper`) // eslint-disable-line @multimediallc/no-set-attribute
    }

    protected createBaseElement(tagName: string, props: ReactCompProps): HTMLElement {
        return document.createElement("div")
    }

    protected initUI(props: ReactCompProps): void {
        this.reactComponent = new (ReactComponentRegistry.get(props.component))(props.componentProps, this.element)
        if (props.reactRef !== undefined) {
            props.reactRef(this.reactComponent)
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public update(componentProps: Record<string, any>): void {
        if (this.reactComponent !== null) {
            this.reactComponent.update(componentProps)
        }
    }

    public dispose(): void {
        if (this.reactComponent !== null) {
            this.reactComponent.dispose()
        }
    }

}
