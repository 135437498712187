import { addColorClass } from "../../cb/colorClasses"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { Component } from "../defui/component"
import { ResizeHandles } from "../defui/resizable"
import { addDragListener, DISABLED_DRAG_LISTENER } from "../dragListener"
import { EventRouter } from "../events"
import { addPageAction } from "../newrelic"
import { WindowPositioner } from "./windowPositioner"
import type { DraggableCanvas } from "./draggableCanvas"
import type { WindowPositionerAnchors } from "./windowPositioner";

interface IDraggableCanvasWindowConfig {
    title?: string // if title is blank, no title bar (or close button) is shown
    windowKey: string // windowName should be unique
    minWidth: number
    minHeight: number
    defaultWidth: number
    defaultHeight: number
    defaultAnchors: WindowPositionerAnchors[]
    resizeable: boolean
    autoSize?: boolean
    onClose?: () => void
    onGainedFocus?: () => void
    onKey?: (event: KeyboardEvent) => boolean
    blurOpacity?: string
    handles?: HTMLElement[]
}

const closeButtonRightOffset = 4
const titleTextLeftOffset = 5
export const windowBorder = 2

export class DraggableCanvasWindow extends Component<HTMLDivElement> {
    public positioner: WindowPositioner
    private stopDraggingOrResizing: () => void
    private resizeHandles?: ResizeHandles
    private titleBarDiv?: HTMLDivElement
    private titleBarDivInner?: HTMLDivElement
    private titleSpan?: HTMLSpanElement
    private isDraggingOrResizing = false
    public draggingOrResizingChanged = new EventRouter<boolean>("draggingOrResizingChanged")
    public onClose?: () => void
    public readonly closable: boolean
    protected closeButton: HTMLElement
    public innerDiv: HTMLDivElement
    public isOpen = false

    constructor(private draggableCanvas: DraggableCanvas, private content: Component,
                private config: IDraggableCanvasWindowConfig) {
        super()
        addColorClass(this.element, "draggableCanvasWindow")
        this.element.style.overflow = "visible"

        this.innerDiv = document.createElement("div")
        addColorClass(this.innerDiv, "innerDiv")
        this.innerDiv.style.height = "100%"
        this.innerDiv.style.width = "100%"
        this.innerDiv.style.position = "absolute"
        this.innerDiv.style.overflow = "visible"

        this.innerDiv.style.boxSizing = "border-box"
        this.innerDiv.style.borderWidth = `${windowBorder}px`
        this.innerDiv.style.borderStyle = "solid"
        this.innerDiv.style.borderRadius = "4px"
        this.closable = config.title !== undefined

        const closeButton = this.closeButton = document.createElement("div")
        addColorClass(this.closeButton, "closeButton")
        this.closeButton.id = `${this.config.windowKey}-close-btn`.toLowerCase()
        this.closeButton.dataset["paction"] = "TheaterChat"
        this.closeButton.dataset["pactionName"] = "ChatTab_Close"
        this.closeButton.style.position = "absolute"
        this.closeButton.style.right = `${closeButtonRightOffset}px`
        this.closeButton.style.height = "10px"
        this.closeButton.style.width = "10px"
        this.closeButton.style.cursor = "pointer"
        this.closeButton.style.borderWidth = "1px"
        this.closeButton.style.borderStyle = "solid"
        this.closeButton.style.borderRadius = "2px"
        addEventListenerPoly("mouseenter", this.closeButton, () => {
            closeButton.style.backgroundImage = `url(${STATIC_URL}grey-close.svg)`
            closeButton.style.backgroundSize = "10px"
        })
        addEventListenerPoly("mouseleave", this.closeButton, () => {
            closeButton.style.backgroundImage = ""
        })
        if (config.title !== undefined) {
            this.titleBarDiv = document.createElement("div")
            addColorClass(this.titleBarDiv, "titleBar")
            this.titleBarDiv.style.position = "relative"
            this.titleBarDiv.style.fontSize = "13px"
            this.titleBarDiv.style.fontWeight = "bold"
            this.titleBarDiv.style.padding = `3px 3px 3px ${titleTextLeftOffset}px`
            this.titleBarDiv.style.borderRadius = "4px 4px 0 0"
            this.titleBarDiv.style.minHeight = "10px"

            this.titleBarDivInner = document.createElement("div")
            this.titleBarDivInner.style.height = "100%"
            this.titleBarDivInner.style.whiteSpace = "nowrap"
            this.titleBarDivInner.style.overflow = "hidden"
            this.titleBarDivInner.style.textOverflow = "ellipsis"
            this.titleBarDiv.appendChild(this.titleBarDivInner)

            this.titleSpan = document.createElement("span")
            this.titleSpan.innerText = config.title
            this.titleBarDivInner.appendChild(this.titleSpan)
            this.onClose = config.onClose
            this.titleBarDiv.appendChild(this.closeButton)
            this.innerDiv.appendChild(this.titleBarDiv)
            addEventListenerPoly("click", this.closeButton, (ev) => {
                ev.stopPropagation()
                this.draggableCanvas.removeChild(this)
            })
        } else {
            this.closeButton.style.top = "8px"
            this.closeButton.style.zIndex = "1" // This just needs a nonzero z-index.
            this.element.appendChild(this.closeButton)
            addEventListenerPoly("click", this.closeButton, (ev) => {
                ev.stopPropagation()
                this.element.style.display = "none"
                this.isOpen = false
            })
        }
        this.addChild(this.content, this.innerDiv)

        this.positioner = new WindowPositioner(this, this.config.windowKey, this.config)
        this.stopDraggingOrResizing = () => {
            this.positioner.save()
            this.repositionChildrenRecursive()
        }
        if (config.handles === undefined) {
            config.handles = this.titleBarDivInner !== undefined ? [this.titleBarDivInner] : [this.element]
        }
        makeDraggable(this.element, {
            dragStart: () => {
                this.focusThisWindow()
                this.setDraggingOrResizing(true)
            },
            dragStop: () => {
                this.setDraggingOrResizing(false)
                this.stopDraggingOrResizing()
                addPageAction("MoveDraggableWindow", {
                    "window": this.getWindowKey(),
                    "left": this.element.offsetLeft,
                    "top": this.element.offsetTop,
                    "windowWidth": window.innerWidth,
                    "windowHeight": window.innerHeight,
                })
            },
            canStart: () => {
                return !this.isDraggingOrResizing
            },
        }, config.handles)
        if (config.resizeable === true) {
            this.makeResizeable()
        }
        this.element.appendChild(this.innerDiv)
    }

    private focusThisWindow(): void {
        this.draggableCanvas.setFocusedWindow(this)
    }

    public setTitle(title: string): void {
        if (this.titleSpan !== undefined) {
            this.titleSpan.innerText = title
        }
    }

    private makeResizeable(): void {
        if (this.resizeHandles !== undefined) {
            return
        }
        this.resizeHandles = this.addChild(new ResizeHandles({
            minWidth: this.config.minWidth,
            minHeight: this.config.minHeight,
            maxWidth: () => {
                return this.draggableCanvas.element.clientWidth
            },
            maxHeight: () => {
                return this.draggableCanvas.element.clientHeight
            },
            componentToOverlay: this,
            onStart: () => {
                this.focusThisWindow()
                this.setDraggingOrResizing(true)
            },
            onStop: () => {
                this.setDraggingOrResizing(false)
                this.stopDraggingOrResizing()
                addPageAction("ResizeDraggableWindow", {
                    "window": this.getWindowKey(),
                    "width": this.element.clientWidth,
                    "height": this.element.clientHeight,
                    "windowWidth": window.innerWidth,
                    "windowHeight": window.innerHeight,
                })
            },
        }))
        this.resizeHandles.repositionChildrenRecursive()
    }

    public resize(defaultHeight: number, defaultWidth: number): void {
        this.config.defaultHeight = defaultHeight
        this.config.defaultWidth = defaultWidth
        this.repositionChildren()
    }

    protected setDraggingOrResizing(isDraggingOrResizing: boolean): void {
        this.isDraggingOrResizing = isDraggingOrResizing
        this.draggingOrResizingChanged.fire(isDraggingOrResizing)
    }

    protected repositionChildren(): void {
        if (this.config.autoSize !== undefined && this.config.autoSize) {
            this.content.element.style.height = "auto"
        } else {
            this.content.element.style.height = `${this.element.clientHeight - this.getTitleBarHeight() - (2 * windowBorder)}px`
        }

        if (this.titleBarDiv !== undefined) {
            if (this.closeButton === undefined) {
                error("expected close button to be defined")
            } else if (this.titleBarDivInner === undefined) {
                error("expected title bar div inner to be defined")
            } else {
                this.titleBarDivInner.style.maxWidth = `${this.titleBarDiv.clientWidth
                - this.closeButton.offsetWidth
                - titleTextLeftOffset
                - (2 * closeButtonRightOffset)
                    }px`
                this.closeButton.style.top = `${(this.titleBarDiv.offsetHeight - this.closeButton.offsetHeight) / 2}px`
            }
        }
    }

    public getWindowKey(): string {
        return this.config.windowKey
    }

    public getTitleBarHeight(): number {
        return this.titleBarDiv === undefined ? 0 : this.titleBarDiv.offsetHeight
    }

    public gainedFocus(): void {
        this.innerDiv.style.opacity = "1"
        if (this.config.onGainedFocus !== undefined) {
            this.config.onGainedFocus()
        }
    }

    public lostFocus(): void {
        this.innerDiv.style.opacity = (this.config.blurOpacity !== undefined) ? this.config.blurOpacity : "1"
    }

    public blur(): void {
        if (this.draggableCanvas.focusedWindow === this) {
            this.draggableCanvas.setFocusedWindow(undefined)
        }
    }

    public handleKeyEvent(event: KeyboardEvent): boolean {
        if (this.config.onKey !== undefined) {
            return this.config.onKey(event)
        }
        return false // not handled
    }
}

interface IDraggableConfig {
    canStart?: () => boolean
    dragStart?: () => void
    dragStop?: () => void
}

// makeDraggable should probably be more tightly coupled with draggable canvas, but due to
// its history, it is utilized as this.  Don't strive to emulate this pattern in future code.
export function makeDraggable(div: HTMLElement, config: IDraggableConfig, handles: HTMLElement[]): void {
    for (const handle of handles) {
        handle.style.cursor = "move"
        addDragListener(handle, (ev: Event, x: number, y: number) => {
            if (config.canStart !== undefined && config.canStart() === false) {
                return DISABLED_DRAG_LISTENER
            }
            const startDivOffsetLeft = div.offsetLeft
            const startDivOffsetTop = div.offsetTop
            const height = div.offsetHeight
            const width = div.offsetWidth
            if (div.parentElement === null) {
                error(`draggable without parent element`)
                return DISABLED_DRAG_LISTENER
            }
            const maxHeight = div.parentElement.clientHeight
            const maxWidth = div.parentElement.clientWidth
            const clientXStart = x
            const clientYStart = y
            let clientXCurrent = clientXStart
            let clientYCurrent = clientYStart
            if (config.dragStart !== undefined) {
                config.dragStart()
            }

            const updateGhost = () => {
                const rightMovement = clientXCurrent - clientXStart
                const downMovement = clientYCurrent - clientYStart
                let top = startDivOffsetTop + downMovement
                let left = startDivOffsetLeft + rightMovement
                if (top < 0) {
                    top = 0
                } else if (top + height > maxHeight) {
                    top = maxHeight - (height)
                }
                if (left < 0) {
                    left = 0
                } else if (left + width > maxWidth) {
                    left = maxWidth - (width)
                }
                div.style.top = `${top}px`
                div.style.left = `${left}px`
            }

            return {
                enabled: true,
                move: (x: number, y: number) => {
                    // if the page feels laggy, do this in a setInterval instead
                    // const ghostInterval = window.setInterval(updateGhost, 14)
                    // and in upListener, do this
                    // clearInterval(ghostInterval)
                    // updateGhost()

                    clientXCurrent = x
                    clientYCurrent = y
                    updateGhost()
                },
                end: () => {
                    if (config.dragStop !== undefined) {
                        config.dragStop()
                    }
                },
            }
        })
    }
}
