import { trackCustomEvent } from "@convivainc/conviva-js-appanalytics"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb, postCb } from "../../common/api"
import { EventRouter } from "../../common/events"
import { convivaEnabled } from "../../common/player/utils"
import { pageContext } from "../interfaces/context"
import type { ITipInPast24HoursNotification, ITokenBalanceUpdateNotification } from "../../common/messageInterfaces"
import type { RoomType } from "../../common/roomUtil"

export const tipsInPast24HoursUpdate = new EventRouter<ITipInPast24HoursNotification>("tipsInPast24HoursUpdate")

export const tokenBalanceUpdate = new EventRouter<ITokenBalanceUpdateNotification>("tokenBalanceUpdate")

export const enum TipType {
    public = "public",
    anonymous = "anonymous",
}

interface ISendTipData {
    roomName: string
    tipAmount: string
    message: string
    source: string
    tipRoomType: RoomType
    tipType: TipType
    videoMode: string
}

export interface ISendTipResponse {
    success: boolean
    tokenBalance?: number
    error?: string
    tipsInPast24Hours?: number
    showPurchaseLink?: boolean
}

export interface ICurrentTokenResponse {
    tokenBalance: number
    tipOptions?: ITipOptions
}

export interface ITipOptions {
    label: string
    options: { label: string }[]
}

export const tipMessageMargin = 6

export function parseCurrentTokensResponse(response: string): ICurrentTokenResponse {
    const p = new ArgJSONMap(response)
    const currentTokensResponse = {
        tokenBalance: p.getNumber("token_balance"),
    } as ICurrentTokenResponse
    const rawTipOptions = p.getStringOrUndefined("tip_options")
    if (rawTipOptions !== undefined) {
        currentTokensResponse.tipOptions = parseTipOptions(rawTipOptions)
    }
    p.logUnusedDebugging("parseCurrentTokensResponse")
    return currentTokensResponse
}

export function parseSendTipResponse(response: string): ISendTipResponse {
    const p = new ArgJSONMap(response)
    const sendTipResponse = {
        success: p.getBoolean("success"),
    } as ISendTipResponse
    const error = p.getStringOrUndefined("error", false)
    if (error !== undefined) {
        sendTipResponse.error = error
        sendTipResponse.showPurchaseLink = p.getBoolean("show_purchase_tokens", false, false)
    } else {
        sendTipResponse.tokenBalance = p.getNumber("token_balance")
        sendTipResponse.tipsInPast24Hours = p.getNumber("tipped_performer_last_24hrs")
    }
    p.logUnusedDebugging("parseSendTipResponse")
    return sendTipResponse
}

function parseOptions(subTipOptions: string): { label: string }[] {
    const tipOpions = []
    for (const option of JSON.parse(subTipOptions)) {
        const p = new ArgJSONMap(JSON.stringify(option))
        tipOpions.push({
            label: p.getAsString("label"),
        })
        p.logUnusedDebugging("parseOptions")
    }
    return tipOpions
}

export function parseTipOptions(rawTipOptions: string): ITipOptions | undefined {
    let p = new ArgJSONMap(rawTipOptions)
    const subTipOptions = p.getObjectStringOrUndefined("tip_options")
    if (subTipOptions === undefined) {
        return
    }
    p = new ArgJSONMap(subTipOptions)
    const tipOptions = {
        label: p.getAsString("label"),
        options: parseOptions(p.getObjectString("options")),
    }
    p.logUnusedDebugging("parseTipOptions")
    return tipOptions
}

export function getTokenBalance(roomName: string): Promise<ICurrentTokenResponse> {
    return new Promise((resolve, reject) => {
        getCb(`tipping/current_tokens/?room=${roomName}`).then((xhr) => {
            const tokenResponse = parseCurrentTokensResponse(xhr.responseText)
            tokenBalanceUpdate.fire({ tokens: tokenResponse.tokenBalance })
            resolve(tokenResponse)
        }).catch((xhr) => {
            reject(xhr.status)
        })
    })
}

let isTipPending = false

export function sendTip(data: ISendTipData): Promise<ISendTipResponse> {
    return new Promise((resolve, reject) => {
        if (!isTipPending) {
            if (convivaEnabled()) {
                trackCustomEvent({
                    name: "SendTip",
                    data: {
                        "roomName": data.roomName,
                        "tipAmount": data.tipAmount,
                        "message": data.message,
                        "source": data.source,
                        "tipRoomType": data.tipRoomType,
                        "tipType": data.tipType,
                        "videoMode": data.videoMode,
                    },
                })
            }
            isTipPending = true
            postCb(`tipping/send_tip/${data.roomName}/`, {
                "tip_amount": data.tipAmount,
                "message": data.message,
                "source": data.source,
                "tip_room_type": data.tipRoomType,
                "tip_type": data.tipType,
                "video_mode": data.videoMode,
                "from_username": pageContext.current.loggedInUser?.username ?? "",
            }).then((xhr) => {
                isTipPending = false
                const sendTipResponse = parseSendTipResponse(xhr.responseText)
                if (sendTipResponse.tokenBalance !== undefined) {
                    tokenBalanceUpdate.fire({ tokens: sendTipResponse.tokenBalance })
                }
                resolve(sendTipResponse)
            }).catch((err) => {
                isTipPending = false
                reject(err.xhr.status)
            })
        }
    })
}
