import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { MultiSidedExperiment } from "@multimediallc/web-utils"
import { isFilterInPathActive } from "../../../common/featureFlagUtil"
import { getCurrentGender } from "../../../common/genders"
import { addPageAction } from "../../../common/newrelic"
import { addColorClass, removeColorClass } from "../../colorClasses"
import { pageContext } from "../../interfaces/context"
import { genderFilterUpdateFromNav } from "../roomlist/spaHelpers"
import {
    parseFollowedText,
} from "./follow"
import { FollowedData } from "./followedData"
import { FollowedDropdown, followedUrl } from "./followedDropdown"
import type {
    IOnlineFollowedStorage } from "./follow"
import type { FollowedOptions } from "./followedData"
import type { INewRooms } from "./followedDropdown"
import type { Gender } from "../../../common/genders"

export class FollowedTab {
    private readonly ratioText: HTMLSpanElement | undefined
    private followingLink: HTMLAnchorElement | undefined
    private orange = false
    private flashInterval: number | undefined
    private readonly dropdown: FollowedDropdown
    private readonly followedData = FollowedData.getInstance()
    private readonly moveFollowCountTest = new MultiSidedExperiment(["MvFlw2", "MvFlw2Ntf", "MvFlw2Onl"], "mv_flw_ct")

    constructor(private toggleEl: HTMLElement, isAnonymous: boolean, username: string) {
        this.ratioText = this.toggleEl.querySelector<HTMLSpanElement>(".followed_counts") ?? undefined
        if (["MvFlw2", "MvFlw2Ntf"].includes(this.moveFollowCountTest.activeSide ?? "") && this.ratioText !== undefined) {
            this.ratioText.remove()
            this.ratioText = undefined
        }

        this.followingLink = (document.getElementById("followed_anchor") as HTMLAnchorElement) ?? undefined

        const followedOptions = {
            username: username,
            isAnonymous: isAnonymous,
            updateFromCBCallback: (unseen: INewRooms) =>  {
                this.updateRatioText(true)
                if (unseen.rooms.length === 0) {
                    this.orange = false
                    this.updateTabHighlight()
                }
            },
            updateViaStorageCallback: (ev: IOnlineFollowedStorage, ignoreFlash: boolean) => {
                this.updateRatioText(false)
                this.orange = ev.unseenRooms.length > 0
                this.updateTabHighlight()
                if (ev.flash && !ignoreFlash) {
                    this.flash()
                }
            },
            followedDropdownClickedCallback: () => {
                this.updateRatioText(false)
                clearInterval(this.flashInterval)
                this.flashInterval = undefined
                this.orange = false
                this.updateTabHighlight()
            },
            dropdown: new FollowedDropdown(this.toggleEl, isAnonymous, this.moveFollowCountTest.active),
            followedTab: this,
        } as FollowedOptions

        this.dropdown = followedOptions.dropdown
        this.dropdown.toggleEvent.listen(e => {
            if (e.isShowing) {
                clearInterval(this.getFlashingInterval())
                this.setFlashingInterval(undefined)
                this.setOrange(false)
                this.updateTabHighlight()
                const numFollowedOnline = this.followedData.getOnlineFollowed().online
                addPageAction("FollowedDropdownOpened", { "onlineBroadcasters": numFollowedOnline })
                this.followedData.setDropdownViewed()
            }
        })

        if (isFilterInPathActive()) {
            UrlState.current.listen(["genders"], (state) => {
                this.updateFollowingLink((state.genders ?? [])[0] ?? "", this.followedData.getOnlineFollowed().online > 0)
            }, this.followingLink)
        } else {
            genderFilterUpdateFromNav.listen((gender) => {
                this.updateFollowingLink(gender, this.followedData.getOnlineFollowed().online > 0)
            })
        }

        // FollowedData was created to separate UI from Data logic in order to serve SPA
        // in which we only want to have one initialization of event listeners
        this.followedData.init(followedOptions)

        if (!this.moveFollowCountTest.active) {
            this.followedData.setOnlineFollowed(parseFollowedText(this.toggleEl.innerText))
            this.updateRatioText(true)
        }
    }

    private setOrangeStyle(active: boolean): void {
        if (active) {
            addColorClass(this.toggleEl, "orange")
            const numUnseenRooms = this.followedData.getlastUnseen().length
            if (numUnseenRooms > 0 && this.moveFollowCountTest.activeSide === "MvFlw2Ntf") {
                addColorClass(this.toggleEl, "text")
                document.getElementById("followed_anchor")?.style?.setProperty(
                    "--unseen-follow-count", `"${numUnseenRooms}"`)
            }
        } else {
            removeColorClass(this.toggleEl, "orange")
            removeColorClass(this.toggleEl, "text")
        }
    }

    setOrange(isOrange: boolean): void {
        this.orange = isOrange
    }

    setFlashingInterval(flashInterval?: number): void {
        this.flashInterval = flashInterval
    }

    getFlashingInterval(): number | undefined {
        return this.flashInterval
    }

    public flash(): void {
        if (this.flashInterval !== undefined) {
            return
        }
        let remaining = 3
        let orange = true
        this.flashInterval = window.setInterval(() => {
            if (orange) {
                this.setOrangeStyle(true)
                remaining -= 1
                if (remaining === 0) {
                    clearInterval(this.flashInterval)
                    this.flashInterval = undefined
                    return
                }
            } else {
                this.setOrangeStyle(false)
            }
            orange = !orange
        }, 500)
    }

    updateRatioText(fromCBCallbackOrInit: boolean): void {
        if (this.moveFollowCountTest.active) {
            this.dropdown.updateFollowedCount()
        }
        if (this.ratioText === undefined) {
            return
        }

        const onlineFollowed = this.followedData.getOnlineFollowed()
        this.toggleEl.style.width = "auto"
        // const oldText = this.ratioText.textContent === undefined ? this.ratioText.textContent : ""

        const onlineOnlyText = pageContext.current.loggedInUser === undefined ? "" : `(${onlineFollowed.online})`
        this.ratioText.textContent = this.moveFollowCountTest.activeSide === "MvFlw2Onl" ?
            onlineOnlyText : `(${onlineFollowed.online}/${onlineFollowed.total})`
        if (this.toggleEl.offsetParent !== null) {
            window.setTimeout(() => {
                // set the tab width to non fractional value because it will cause dropdown sometime be
                // one pixel off in Chrome because of the rounding issues. Timeout is for IE to figure out width in time.
                if (!fromCBCallbackOrInit) {
                    this.toggleEl.style.width = `${this.toggleEl.offsetWidth + 1}px`
                }
                if (this.dropdown.isShown()) {
                    this.dropdown.reposition()
                }
            })
        }
        this.updateFollowingLink(getCurrentGender(), onlineFollowed.online > 0)
    }

    updateTabHighlight(): void {
        if (this.orange && !this.dropdown.isShown()) {
            this.setOrangeStyle(true)
        } else {
            this.setOrangeStyle(false)
        }
    }

    // Update link href to be online/offline using online count and gender-specific
    private updateFollowingLink(gender: Gender, hasOnline: boolean): void {
        if (this.followingLink !== undefined) {
            this.followingLink.href = followedUrl(gender, hasOnline)
        }
    }
}
