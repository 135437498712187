import { normalizeResource } from "../../common/api"
import { HTMLComponent } from "../../common/defui/htmlComponent"
import { Gender, GendersSymbolToNameMap, getVerboseGenderPath } from "../../common/genders"

interface IGenderedLinkState {
    gender: Gender
}

interface IGenderedLinkProps extends IGenderedLinkState {
    anchorElement: HTMLAnchorElement
}

export class GenderedLink extends HTMLComponent<HTMLAnchorElement, IGenderedLinkProps, IGenderedLinkState> {
    private url: URL

    constructor(props: IGenderedLinkProps) {
        super(props)

        if (props.gender !== Gender.All) {
            this.url = new URL("../", this.element.href)
        } else {
            this.url = new URL(this.element.href)
        }
    }

    protected createElement(props: IGenderedLinkProps): HTMLAnchorElement {
        return props.anchorElement
    }

    updateState(): void {
        super.updateState()
        let searchParamString = this.url.searchParams.toString()
        searchParamString = (searchParamString === "") ? "" : `?${searchParamString}`

        const genderString = (this.url.pathname === normalizeResource("/")) ? getVerboseGenderPath(this.state.gender) : GendersSymbolToNameMap.get(this.state.gender)
        const genderPath = (genderString === "") ? "" : `${genderString}/`

        this.element.href = normalizeResource(`${this.url.pathname}${genderPath}${searchParamString}`)
    }
}
