export interface ILoggedInUser {
    username: string
    gender: string|undefined
    subgender: string|undefined
    isSupporter: boolean
    isStaff: boolean
    tokenBalance: number
    userUid: string
    signedUsername: string
    isAgeVerified: boolean
    isBroadcasting: boolean
    totalFollowed: number
    onlineFollowed: number
    canWegOneClick: boolean
    canEpochOneClick: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoggedInUser(settings: Record<string, any>): ILoggedInUser|undefined {
    if (settings === null || window.location.pathname === "/security/tfa/auth/" || window.location.pathname === "/security/tfa/auth/choose_device/") {
        return undefined
    }
    return {
        username: settings["username"],
        gender: settings["gender"],
        subgender: settings["subgender"] ?? undefined,
        isSupporter: settings["is_supporter"],
        isStaff: settings["is_staff"],
        tokenBalance: settings["token_balance"],
        userUid: settings["user_uid"],
        signedUsername: settings["signed_username"],
        isAgeVerified: settings["is_age_verified"],
        isBroadcasting: settings["is_broadcasting"],
        totalFollowed: settings["total_followed"],
        onlineFollowed: settings["online_followed"],
        canWegOneClick: settings["can_weg_oneclick"],
        canEpochOneClick: settings["can_epoch_oneclick"],
    }
}
