import { addColorClass, colorClass } from "../colorClasses"

/*
    Fields use the following structure:
    <Row>
        <LabelContainer>
            <Label />
        </LabelContainer>
        <FieldContainer>
            <Field />
            <HelpText />
            <ErrorContainer />
        </FieldContainer>
    </Row>
 */

export class FieldStyles {
    public styleRow(row: HTMLElement): void {
        row.style.padding = "3px 0"
    }

    public styleLabelContainer(labelContainer: HTMLElement): void {
        labelContainer.style.minWidth = "130px"
        labelContainer.style.width = "auto"
        labelContainer.style.paddingTop = "10px"
        labelContainer.style.paddingBottom = "1px"
        labelContainer.style.verticalAlign = "top"
        labelContainer.style.textAlign = "right"
    }

    public styleLabel(label: HTMLElement): void {
        addColorClass(label, colorClass.defaultColor)
        label.style.textAlign = "right"
        label.style.fontWeight = "bold"
        label.style.font = "1em/1.3em 'UbuntuMedium', Arial, Helvetica, sans-serif"
        label.style.padding = "0"
        label.style.margin = "0 4px 0 0"
    }

    public styleFieldContainer(fieldContainer: HTMLElement): void {
        addColorClass(fieldContainer, colorClass.defaultColor)
    }

    public styleField(field: HTMLElement): void {
        addColorClass(field, "field")
        field.style.borderWidth = "1px"
        field.style.borderStyle = "solid"
        field.style.borderRadius = "4px"
        field.style.padding = "2px 4px"
        field.style.lineHeight = "18px"
        field.style.marginTop = "4px"
        field.style.width = "225px"
    }

    public styleHelpText(helpText: HTMLElement): void {
        addColorClass(helpText, colorClass.defaultColor)
        helpText.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"
        helpText.style.fontSize = "11px"
        helpText.style.width = "350px"
    }
}

export class SelectStyles extends FieldStyles {
    public styleField(field: HTMLElement): void {
        super.styleField(field)
        field.style.width = "auto"
        field.style.marginTop = "7px"
    }
}

export class MultipleSelectStyles extends SelectStyles {
    public styleField(field: HTMLSelectElement): void {
        super.styleField(field)
        field.style.height = ""
        field.size = 10
    }
}

export class InputStyles extends FieldStyles {}

export class TextAreaStyles extends FieldStyles {
    public styleField(field: HTMLElement): void {
        addColorClass(field, "fieldTextArea")
        field.style.width = "350px"
        field.style.lineHeight = "18px"
        field.style.marginTop = "4px"
        field.style.borderWidth = "1px"
        field.style.borderStyle = "solid"
        field.style.borderRadius = "4px"
        field.style.padding = "2px 4px"
        field.style.overflow = "hidden"
        field.style.height = "150px"
    }
}

// only to keep the label left aligned like the rest of the fields on the settings page
export class SettingsTextAreaStyles extends TextAreaStyles {
    public styleLabel(label: HTMLElement): void {
        addColorClass(label, colorClass.defaultColor)
        label.style.width = "100%"
        label.style.textAlign = "left"
        label.style.fontWeight = "bold"
        label.style.font = "1em/1.3em 'UbuntuMedium', Arial, Helvetica, sans-serif"
        label.style.margin = "0px 4px 6px"
    }

    public styleLabelContainer(labelContainer: HTMLElement): void {
        labelContainer.style.minWidth = "130px"
        labelContainer.style.width = "auto"
        labelContainer.style.paddingLeft = "10px"
        labelContainer.style.paddingBottom = "1px"
        labelContainer.style.verticalAlign = "top"
        labelContainer.style.textAlign = "right"
    }
}

export class CheckboxInputStyles extends FieldStyles {
    public styleFieldContainer(fieldContainer: HTMLElement): void {
        super.styleFieldContainer(fieldContainer)
        fieldContainer.style.paddingTop = "7px"
    }
}

export class MultipleCheckboxStyles extends FieldStyles {
    public styleFieldContainer(fieldContainer: HTMLElement): void {
        super.styleFieldContainer(fieldContainer)
        fieldContainer.style.paddingTop = "7px"
    }

    public styleField(field: HTMLElement): void {
        super.styleField(field)
        field.style.width = "auto"
        field.style.marginTop = "7px"
    }
}

export class NoRecaptchaStyles extends FieldStyles {
    public styleField(field: HTMLElement): void {
        super.styleField(field)
        field.style.position = ""
        field.style.padding = ""
        field.style.overflow = ""
        field.style.background = ""
        field.style.border = ""
    }
}
