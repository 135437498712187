import { parseRoomNotice } from "../../../common/chatconnection/messageParsers"
import { RoomTopic } from "./room"
import type { IPushNotice } from "../../../common/messageInterfaces"
import type { ArgJSONMap } from "@multimediallc/web-utils"

export class RoomModeratorNoticeTopic extends RoomTopic<IPushNotice> {
    public getId(): string {
        return "RoomModeratorNoticeTopic"
    }

    public parseData(data: ArgJSONMap): IPushNotice {
        return {
            ...super.parseData(data),
            ...parseRoomNotice(data),
            toGroup: "red",
            toUser: undefined,
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }
}

export class RoomFanClubTopic extends RoomTopic<IPushNotice> {
    public getId(): string {
        return "RoomFanClubTopic"
    }

    public parseData(data: ArgJSONMap): IPushNotice {
        return {
            ...super.parseData(data),
            ...parseRoomNotice(data),
            toGroup: "green",
            toUser: undefined,
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }
}

// Unknown future phase
export class RoomFanClub2Topic extends RoomTopic<IPushNotice> {
    public getId(): string {
        return "RoomFanClub2Topic"
    }

    public parseData(data: ArgJSONMap): IPushNotice {
        return {
            ...super.parseData(data),
            ...parseRoomNotice(data),
            toGroup: undefined,
            toUser: undefined,
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }

}

export class RoomLightPurpleTopic extends RoomTopic<IPushNotice> {
    public getId(): string {
        return "RoomLightPurpleTopic"
    }

    public parseData(data: ArgJSONMap): IPushNotice {
        return {
            ...super.parseData(data),
            ...parseRoomNotice(data),
            toGroup: "lightpurple",
            toUser: undefined,
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }
}

export class RoomDarkPurpleTopic extends RoomTopic<IPushNotice> {
    public getId(): string {
        return "RoomDarkPurpleTopic"
    }

    public parseData(data: ArgJSONMap): IPushNotice {
        return {
            ...super.parseData(data),
            ...parseRoomNotice(data),
            toGroup: "darkpurple",
            toUser: undefined,
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }
}

export class RoomDarkBlueTopic extends RoomTopic<IPushNotice> {
    public getId(): string {
        return "RoomDarkBlueTopic"
    }

    public parseData(data: ArgJSONMap): IPushNotice {
        return {
            ...super.parseData(data),
            ...parseRoomNotice(data),
            toGroup: "darkblue",
            toUser: undefined,
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }
}

export class RoomLightBlueTopic extends RoomTopic<IPushNotice> {
    public getId(): string {
        return "RoomLightBlueTopic"
    }

    public parseData(data: ArgJSONMap): IPushNotice {
        return {
            ...super.parseData(data),
            ...parseRoomNotice(data),
            toGroup: "lightblue",
            toUser: undefined,
            ts: Math.floor(data.getNumber("ts") * 1000),
        }
    }
}
