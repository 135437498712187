import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { MoreRoomIterator } from "../../../../common/moreRoomsIterator"
import { addPageAction } from "../../../../common/newrelic"
import { applySafariBfCacheImageFix } from "../../../../common/safariImageFix"
import { i18n } from "../../../../common/translation"
import { dom } from "../../../../common/tsxrender/dom"
import type { MoreRoomsCard } from "./moreRoomsCard"
import type { MoreRoomsList } from "./moreRoomsList"
import type { IRoomInfo } from "../IRoomInfo"

export interface IGenderedMoreRoomsListProps {
  disableRemovalOnScroll: boolean
}

type GenderOptions = [title: string, label: string]

export const SHOWN_ROOM_SCROLL_REMOVAL_THRESHOLD = 15
export const SHOWN_ROOM_REMOVAL_NUM = 10
export const LOAD_MORE_ROOMS_THRESHOLD = 5

export abstract class GenderedMoreRoomsList<TRoomCard extends MoreRoomsCard, TGenderCheckbox> extends HTMLComponent<HTMLDivElement> {
    protected loadingDiv: HTMLDivElement
    protected selectedGenders: string[]
    protected moreRoomsList: MoreRoomsList<TRoomCard>
    private roomIterator = new MoreRoomIterator(10)
    protected genderCheckboxes: TGenderCheckbox[]
    protected static readonly genderOptions: GenderOptions[] = [
        ["Female", i18n.womenText],
        ["Male", i18n.menText],
        ["Couple", i18n.couplesText],
        ["Trans", i18n.transText],
    ]
    protected static readonly localStorageGenderKey = "genders"
    protected isLoadingRooms: boolean
    protected visible = false
    private readonly disableRemovalOnScroll: boolean
    private latestEmptyFetch: number | undefined

    constructor(props: IGenderedMoreRoomsListProps) { // The source referred to here is not necessarily the same as the RoomListSource
        super(props)
        this.disableRemovalOnScroll = props.disableRemovalOnScroll
        this.loadingDiv = this.createLoadingDiv()
        applySafariBfCacheImageFix()
    }

    protected initData(props: IGenderedMoreRoomsListProps): void {
        super.initData(props)
        this.selectedGenders = []
        if (isLocalStorageSupported()) {
            const storedGenders = window.localStorage.getItem(GenderedMoreRoomsList.localStorageGenderKey)
            if (storedGenders !== null) {
                this.selectedGenders = JSON.parse(storedGenders)
            }
        }
        this.genderCheckboxes = []
        this.isLoadingRooms = false
    }

    protected createElement(props: object): HTMLDivElement {
        const elementStyle: CSSX.Properties = {
            fontSize: "12px",
            overflowY: "scroll", // prevent shifting of gender boxes when no rooms are visible
        }
        return <div style={elementStyle}></div>
    }

    protected appendRoomsToDiv(rooms: IRoomInfo[]): void {
        this.handleLoadingDivRemoval()
        this.moreRoomsList.appendRooms(rooms)
    }

    private handleLoadingDivRemoval(): void {
        if (this.moreRoomsList.element.contains(this.loadingDiv)) {
            this.moreRoomsList.element.removeChild(this.loadingDiv)
        }
    }

    private appendLoadingDiv(): void {
        this.isLoadingRooms = true
        this.moreRoomsList.element.appendChild(this.loadingDiv)
    }

    protected handleGenderChange(): void {
        this.updateSelectedGendersFromCheckboxes()
        addPageAction("ChangeRoomListGender", { "selectedGenders": this.selectedGenders.toString() })
        if (isLocalStorageSupported()) {
            if (this.selectedGenders.length === 0) {
                window.localStorage.removeItem(GenderedMoreRoomsList.localStorageGenderKey)
            } else {
                window.localStorage.setItem(GenderedMoreRoomsList.localStorageGenderKey, JSON.stringify(this.selectedGenders))
            }
        }
        this.resetAndLoad()
    }

    children(): TRoomCard[] {
        return this.moreRoomsList.rooms
    }

    protected resetAndLoad(): void {
        for (const room of this.children()) {
            room.stopStreaming()
        }
        this.appendLoadingDiv()
        this.repositionChildren()
        this.loadFromNewIterator()
    }

    private loadFromNewIterator(): void {
        this.roomIterator.setGenders(this.selectedGenders)
        this.appendLoadingDiv()
        this.moreRoomsList.setState({ roomInfoList: []})
        this.roomIterator.next().then((rooms) => {
            this.moreRoomsList.setState({ roomInfoList: rooms })
            this.repositionChildrenRecursive()
            this.handleLoadingDivRemoval()
            this.isLoadingRooms = false
        }).catch((err) => {
            this.isLoadingRooms = false
            error("getNewIterator", err)
        })
    }


    private loadFromCurrentIterator(): void {
        if (this.isLoadingRooms) {
            return
        }

        this.appendLoadingDiv()
        this.roomIterator.next().then((rooms) => {
            this.insertLoadedRooms(rooms)
        }).catch((err) => {
            this.isLoadingRooms = false
            error("loadMoreRooms", err)
        })
    }

    private insertLoadedRooms(rooms: IRoomInfo[]): void {
        if (rooms.length > 0) {
            this.finishInsertLoadedRooms(rooms)
            this.isLoadingRooms = false
            this.repositionChildrenRecursive()
            this.loadMoreRoomsIfNearBottom()
            this.latestEmptyFetch = undefined
        } else {
            this.isLoadingRooms = false
            this.handleLoadingDivRemoval()
            this.latestEmptyFetch = performance.now()
        }
    }

    protected finishInsertLoadedRooms(rooms: IRoomInfo[]): void {
        this.removeRoomsScrolledPastTop()
        this.appendRoomsToDiv(rooms)
    }

    protected removeRoomsScrolledPastTop(): void {
        const scrolledElement = this.getScrolledElement()

        if (scrolledElement !== undefined && !this.disableRemovalOnScroll) {
            const removeList: string[] = []
            if (this.getScrolledElement().scrollTop > this.calculateScrollRemovalThreshold(SHOWN_ROOM_SCROLL_REMOVAL_THRESHOLD)) {
                for (const room of this.moreRoomsList.rooms) {
                    if (room.element.offsetTop < this.calculateScrollRemovalThreshold(SHOWN_ROOM_REMOVAL_NUM)) {
                        removeList.push(room.getRoomName())
                    } else {
                        break
                    }
                }
            }

            const updatedList = this.moreRoomsList.getRoomInfoList().filter(roomInfo => !removeList.includes(roomInfo["room"]))
            const numRemovedRooms = (this.moreRoomsList.getRoomInfoList().length - updatedList.length)
            if (numRemovedRooms > 0) {
                const lastScrollTop = this.moreRoomsList.element.scrollTop
                this.moreRoomsList.spliceRooms(0, numRemovedRooms)
                this.moreRoomsList.element.scrollTop = lastScrollTop - this.calculateScrollRemovalThreshold(numRemovedRooms, false)

                if (this.visible) {
                    this.loadMoreRoomsIfNearBottom()
                }
            }
        }
    }

    protected abstract calculateScrollRemovalThreshold(numOfRooms: number, includePadding?: boolean): number

    protected loadMoreRoomsIfNearBottom(): void {
        if (this.latestEmptyFetch !== undefined) {
            // Wait a few seconds before fetching again if previous request returned no rooms
            // to prevent loading div append/removal from constantly refetching on scroll
            const elapsedTime = performance.now() - this.latestEmptyFetch
            const waitTime = 3000

            if (elapsedTime < waitTime) {
                return
            }

            this.latestEmptyFetch = undefined
        }
        if (this.getScrolledElement().scrollTop + this.getScrolledElement().offsetHeight + this.calculateScrollRemovalThreshold(LOAD_MORE_ROOMS_THRESHOLD) >= (this.getScrolledElement().scrollHeight)) {
            this.loadFromCurrentIterator()
        }
    }

    protected abstract updateSelectedGendersFromCheckboxes(): void

    protected abstract roomWidth(): number

    protected abstract roomHeight(): number

    protected abstract getScrolledElement(): HTMLElement

    protected abstract createLoadingDiv(): HTMLDivElement
}
