import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "./api"
import { roomLoaded } from "./context"

export interface IMenuData {
    username: string
    displayAge: number
    gender: string
    imageUrl: string
    online: boolean
    canSilence: boolean
    canAccess: boolean
    needsSupporterToPm: boolean
    silenceId?: number
}

function parseMenuDataRes(res: string): IMenuData {
    const p = new ArgJSONMap(res)
    const onlineConv = p.getBoolean("online")
    const username = p.getString("username")
    const displayAge = p.getNumber("display_age", false)
    const gender = p.getString("gender")
    const imageUrl = p.getString("image_url")
    const silenceable = p.getBoolean("can_silence")
    const silenceId = p.getNumberOrUndefined("silence_id")
    const viewable = p.getBoolean("can_access")
    const needsSupporterToPm = p.getBoolean("needs_supporter_to_pm")
    p.logUnusedDebugging("fetchUserMenuData.parseMenuDataRes")
    return {
        username: username,
        displayAge: displayAge,
        gender: gender,
        imageUrl: imageUrl,
        online: onlineConv,
        canSilence: silenceable,
        canAccess: viewable,
        needsSupporterToPm: needsSupporterToPm,
        silenceId: silenceId,
    }
}

let roomName: string | undefined
// eslint-disable-next-line @multimediallc/no-global-listener
roomLoaded.listen((context) => {
    roomName = context.chatConnection.room()
})

export function fetchUserMenuData(username: string): Promise<IMenuData> {
    const room = (roomName === undefined || roomName === "") ? username : roomName
    return new Promise((resolve, reject) => {
        getCb(`api/ts/accounts/usermenu/${username}/${room}/`).then(xhr => {
            resolve(parseMenuDataRes(xhr.responseText))
        }).catch(e => {
            reject(e)
        })
    })
}
