import { populateMap, PushServiceContext } from "../baseClient"
import { HERMOD_CLIENT_NAME } from "./hermod"
import type { IPushContextSettings } from "../baseClient"
import type { ArgJSONMap } from "@multimediallc/web-utils"

interface IHermodContextSettings extends IPushContextSettings {
    url?: string
}

export interface IHermodTokenRequest {
    clientId: string,
    userUid: string,
}

export class HermodContext extends PushServiceContext<IHermodContextSettings> {
    tokenRequest: IHermodTokenRequest | undefined

    constructor(context: ArgJSONMap) {
        super(context)
        this.tokenRequest = context.getObject("token_request") as IHermodTokenRequest

        context.logUnusedDebugging("DevContext")
    }

    public static parseSettings(context: ArgJSONMap): IHermodContextSettings {
        const settings: IHermodContextSettings = {
            backend: HERMOD_CLIENT_NAME,
            flags: new Map<string, boolean>(),
            url: context.getStringOrUndefined("url"),
            verifyEnabled: false,
        }
        populateMap<boolean>(settings.flags, context.getObjectOrUndefined("flags"))
        return settings
    }

    public parseSettings(context: ArgJSONMap): IHermodContextSettings {
        return HermodContext.parseSettings(context)
    }

    public isValid(): boolean {
        return this.tokenRequest !== undefined
    }

    public getTokenRequest(): IHermodTokenRequest {
        if (this.tokenRequest === undefined) {
            throw new Error("Access to tokenRequest on an invalid context!")
        }
        return this.tokenRequest
    }

    public serialize(): string {
        return JSON.stringify(this.tokenRequest)
    }
}
