import { EventRouter } from "../events"
import { ITipRequest } from "../specialoutgoingmessages"

// openBioRequest takes a string username. Routes UserMenu -> DraggableCanvas
export const openBioRequest = new EventRouter<string>("bioClicked")

export const userListRequest = new EventRouter<undefined>("userListRequest")

export const openTipCalloutRequest = new EventRouter<ITipRequest>("openTipCalloutRequest")

export const loadRoomRequest = new EventRouter<string>("loadRoomRequest")

export const reportAbuseRequest = new EventRouter<undefined>("reportAbuseRequest")

export const privateWindowRequest = new EventRouter<undefined>("privateWindowRequest")

export const chatWindowRequest = new EventRouter<undefined>("chatWindowRequest")

export const colorModeChangeRequest = new EventRouter<string>("colorModeChangeRequest")

export const roomChangeColorModeUpdateRequest = new EventRouter<undefined>("roomChangeColorModeUpdateRequest")
