export interface ISettledPromise {
    status: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reason?: any
}

export function allSettledPromises<T>(promises: (Promise<T> | undefined)[]): Promise<ISettledPromise>[] {
    return promises.map((p) => {
        return Promise.resolve(p).then((value) => {
            return {
                status: "fulfilled",
                value: value,
            }
        }).catch((reason) => {
            return {
                status: "rejected",
                reason: reason,
            }
        })
    })
}

export const ignoreCatch: () => void = () => {
    // ignore
}

export const printCatch: (e: unknown) => void = (e) => {
    error(e)
}

export const printCatchThrow: (e: unknown) => void = (e) => {
    error(e)
    throw e
}

/**
 * Return a promise that would either call the promise return by `promiseFn` or resolve to defaultVal if
 * promise call is still in progress and is not resolved or rejectd.
 *
 * This is to avoid multiple API calls for example in case of infinite scroll and loading contents when we are close
 * to the end of the page. If we keep scrolling and the content is not loaded yet we will keep calling the API.
 * Using this function will make sure that only one API call is in progress at a time and we wait until it is resolved
 * to make sure another API call is needed at that point
 *
 * @param promiseFn function that returns a promise to be called.
 * @param defaultVal default value to be resolved to if there is an existing promise call in progress.
 */
 export function oneAtATimePromise<T>(promiseFn: () => Promise<T>, defaultVal: T): () => Promise<T> {
    let isProcessing = false
    return () => new Promise<T>((resolve, reject) => {
        if (isProcessing) {
            resolve(defaultVal)
            return
        }
        isProcessing = true
        return promiseFn().finally(() => {
            isProcessing = false
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
