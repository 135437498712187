import { isiPad, isSafari } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../../common/addEventListenerPolyfill"
import { Debouncer, DebounceTypes } from "../../common/debouncer"
import { Component } from "../../common/defui/component"
import { applyStyles } from "../../common/DOMutils"
import { EventRouter } from "../../common/events"
import { getViewportWidth } from "../../common/mobilelib/viewportDimension"
import type { BoundListener } from "../../common/events"

/**
 * `resizeDebounceEvent` makes sure the listeners are called initially even if the resize hasn't happened to
 * have a chance to fix their position/style based on the current window size.
 * It also tries to make sure the listeners will be called at the end of the resize so they can fix their
 * position/style based on the final size of the window. This is not guaranteed in common `windowReSized` event.
 */
export const resizeDebounceEvent = new class ResizeDebounceEvent extends EventRouter<boolean> {
    listen(listener: (event: boolean) => void, syncHistory = true): BoundListener<boolean> {
        const res = super.listen(listener, syncHistory)
        resizeEndDebouncer.callFunc()
        return res
    }

    addListener(listener: (event: boolean) => void, listeningSource: Component | HTMLElement) {
        super.addListener(listener, listeningSource)
        resizeEndDebouncer.callFunc()
    }
}("resizeDebounceEvent")

export let windowResizeInProgress = false

const resizeEndDebouncer = registerResizeDebouncer((inProgress: boolean) => {
    windowResizeInProgress = inProgress
    resizeDebounceEvent.fire(inProgress)
})

interface IResponsiveStyles {
    name: string,
    min: number | (() => number)
    max: number | (() => number)
}

/**
 * Makes the component responsive by adjusting some property(/ies) width value based on the window width
 *
 * @see /static/scss/common/_mixins.scss make-responsive mixin for a preferred CSS-only alternative,
 *   if you are not passing callables for any of this function's arguments.
 */
export function makeResponsive<T extends Component | HTMLElement>(
    c: T,
    minWindowWidth: number,
    maxWindowWidth: number,
    styles: IResponsiveStyles[],
    additionalCallback?: (styleVal: number) => void,
): () => void {
    const calcStyleVal = (style: IResponsiveStyles): number => {
        const minW = style.min instanceof Function ? style.min() : style.min
        const maxW = style.max instanceof Function ? style.max() : style.max
        const windowWidth = Math.max(Math.min(document.body.clientWidth, maxWindowWidth), minWindowWidth)
        const styleVal = minW + (maxW - minW) * (windowWidth - minWindowWidth) / (maxWindowWidth - minWindowWidth)
        return styleVal
    }
    const adjustStyles = () => {
        const el: HTMLElement = c instanceof Component ? c.element : c
        styles.forEach((style: IResponsiveStyles) => {
            const styleVal = calcStyleVal(style)
            // @ts-ignore - not type checking CSS properties
            el.style[style.name] = `${styleVal}px`
            if (additionalCallback !== undefined) {
                additionalCallback(styleVal)
            }
        })
        if (c instanceof Component) {
            c.repositionChildrenRecursive()
        }
    }
    resizeDebounceEvent.addListener(adjustStyles, c)
    window.setTimeout(() => {
        adjustStyles()
    })
    return adjustStyles
}

export function widthLessThanEvent(minWindowWidth: number): EventRouter<boolean> {
    const eventRouter = new EventRouter<boolean>(`width-less-than${minWindowWidth}`)
    let isLess = false
    resizeDebounceEvent.listen(() => {
        if (getViewportWidth() < minWindowWidth) {
            if (!isLess) {
                isLess = true
                eventRouter.fire(true)
            }
        } else {
            if (isLess) {
                isLess = false
                eventRouter.fire(false)
            }
        }
    })
    return eventRouter
}

/**
 * Register window resize debouncer to be fired on resize based on delayMillis. It will also register
 * another debouncer to capture the end of the resize and call the `fn`
 * @param fn function to call on resize debounce event
 * @param delayMillis delay in millis between function calls during resize
 */
export function registerResizeDebouncer(fn: (inProgress: boolean) => void, delayMillis = 80): Debouncer {
    const debouncer = new Debouncer(() => {
        fn(true)
    }, {
        bounceLimitMS: delayMillis,
        debounceType: DebounceTypes.trailThrottle,
    })
    const resizeEndDebouncer = new Debouncer(() => {
        fn(false)
    }, {
        bounceLimitMS: 200,
        debounceType: DebounceTypes.debounce,
    })
    addEventListenerPoly("resize", window, () => {
        debouncer.callFunc()
        resizeEndDebouncer.callFunc()
    })
    addEventListenerPoly("orientationchange", window, () => {
        fn(false)
        window.setTimeout(() => {
            fn(false)
        }, 80)
    })
    return resizeEndDebouncer
}

if (isiPad() && isSafari()) {
    // resize event is not fired on ipad safari when you zoom in/out.
    // Here we use and elements with percentage with and an interval to detect if the zoom
    // level is changed and fire the resize manually.
    const zoomDetectionEl = document.createElement("div")
    applyStyles(zoomDetectionEl, {
        position: "absolute",
        width: "100%",
    })
    document.body.appendChild(zoomDetectionEl)
    let lastWidth = zoomDetectionEl.offsetWidth
    window.setInterval(() => {
        if (lastWidth !== zoomDetectionEl.offsetWidth) {
            resizeDebounceEvent.fire(true)
            lastWidth = zoomDetectionEl.offsetWidth
        }
    }, 150)
}
