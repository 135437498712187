import { ArgJSONMap } from "@multimediallc/web-utils"
import { appPanelHeight, appPanelWidth } from "./appPanel"

interface IImagePanelResponse {
    name: string
    template: string
    layers?: IImagePanelLayer[]
    table?: IImagePanelTable
}

interface IImagePanelLayer {
    color: string
    filename: string
    fontFamily: string
    fontSize: string
    fontStyle: string
    fontWeight: string
    height: string
    imageUrl: string
    layerType: string
    left: string
    maxWidth: string
    opacity: string
    text: string
    textAlign: string
    top: string
    width: string
}

interface IImagePanelTable {
    backgroundColor: string
    color: string
    fontStyle: string
    fontWeight: string
    textAlign: string
    rowOne?: IImageTemplateRow
    rowTwo?: IImageTemplateRow
    rowThree?: IImageTemplateRow
}

interface IImageTemplateRow {
    backgroundColor: string
    color: string
    fontStyle: string
    fontWeight: string
    textAlign: string
    header?: IImagePanelCol
    data?: IImagePanelCol
}

interface IImagePanelCol {
    backgroundColor: string
    color: string
    left: string
    fontStyle: string
    fontWeight: string
    textAlign: string
    value: string
    width: string
}

export function templateHTMLImageTemplate(rsp: ArgJSONMap): HTMLDivElement {
    const formattedResponse: IImagePanelResponse = {
        name: rsp.getString("name", false),
        template: rsp.getString("template", false),

    }
    const layersAny = rsp.getAny("layers")
    if (layersAny !== undefined) {
        const layers = layersAny as object[]
        if (layers instanceof Array && layers.length !== 0) {
            const imagePanelLayers: IImagePanelLayer[] = []
            for (const l of layers) {
                imagePanelLayers.push(parseLayerString(JSON.stringify(l)))
            }
            formattedResponse.layers = imagePanelLayers
        }
    }
    const table = rsp.getObjectString("table", false)
    if (table.length !== 0) {
        const panelTable = parseTableString(table)
        formattedResponse.table = panelTable
    }
    return createImagePanelDiv(formattedResponse)
}

function parseLayerString(l: string): IImagePanelLayer {
    const layer = new ArgJSONMap(l)
    return {
        color: layer.getStringWithNumbers("color", false),
        filename: layer.getStringWithNumbers("filename", false),
        fontFamily: layer.getStringWithNumbers("font-family", false),
        fontSize: layer.getStringWithNumbers("font-size", false),
        fontStyle: layer.getStringWithNumbers("font-style", false),
        fontWeight: layer.getStringWithNumbers("font-weight", false),
        height: layer.getStringWithNumbers("height", false),
        imageUrl: layer.getStringWithNumbers("image_url", false),
        layerType: layer.getStringWithNumbers("type", false),
        left: layer.getStringWithNumbers("left", false),
        maxWidth: layer.getStringWithNumbers("max-width", false),
        opacity: layer.getStringWithNumbers("opacity", false),
        text: layer.getStringWithNumbers("text", false),
        textAlign: layer.getStringWithNumbers("text-align", false),
        top: layer.getStringWithNumbers("top", false),
        width: layer.getStringWithNumbers("width", false),
    }
}

function parseTableString(t: string): IImagePanelTable {
    const table = new ArgJSONMap(t)
    const panelTable: IImagePanelTable = {
        backgroundColor: table.getStringWithNumbers("background-color", false),
        color: table.getStringWithNumbers("color", false),
        textAlign: table.getStringWithNumbers("text-align", false),
        fontStyle: table.getStringWithNumbers("font-style", false),
        fontWeight: table.getStringWithNumbers("font-weight", false),
    }
    const row_1 = table.getObjectString("row_1", false)
    if (row_1 !== "") {
        panelTable.rowOne = parseRowString(row_1)
    }
    const row_2 = table.getObjectString("row_2", false)
    if (row_2 !== "") {
        panelTable.rowTwo = parseRowString(row_2)
    }
    const row_3 = table.getObjectString("row_3", false)
    if (row_3 !== "") {
        panelTable.rowThree = parseRowString(row_3)
    }
    return panelTable
}

function parseRowString(r: string): IImageTemplateRow {
    const row = new ArgJSONMap(r)
    const panelRow: IImageTemplateRow = {
        backgroundColor: row.getStringWithNumbers("background-color", false),
        color: row.getStringWithNumbers("color", false),
        textAlign: row.getStringWithNumbers("text-align", false),
        fontStyle: row.getStringWithNumbers("font-style", false),
        fontWeight: row.getStringWithNumbers("font-weight", false),
    }
    const header = row.getObjectString("col_1", false)
    if (header !== "") {
        panelRow.header = parseColumnString(header)
    }
    const data = row.getObjectString("col_2", false)
    if (data !== "") {
        panelRow.data = parseColumnString(data)
    }
    return panelRow
}

function parseColumnString(d: string): IImagePanelCol {
    const data = new ArgJSONMap(d)
    return {
        backgroundColor: data.getStringWithNumbers("background-color", false),
        color: data.getStringWithNumbers("color", false),
        left: data.getStringWithNumbers("left", false),
        textAlign: data.getStringWithNumbers("text-align", false),
        fontStyle: data.getStringWithNumbers("font-style", false),
        fontWeight: data.getStringWithNumbers("font-weight", false),
        value: data.getStringWithNumbers("value", false),
        width: data.getStringWithNumbers("width", false),
    }
}

function createImagePanelDiv(r: IImagePanelResponse): HTMLDivElement {
    const appPanelDiv = document.createElement("div")
    appPanelDiv.style.position = "absolute"
    appPanelDiv.style.height = `${appPanelHeight}px`
    appPanelDiv.style.width = `${appPanelWidth}px`
    appPanelDiv.style.backgroundColor = "transparent"
    appPanelDiv.style.overflow = "hidden"
    appPanelDiv.style.font = "1em 'UbuntuRegular', Arial, Helvetica, sans-serif"
    if ( r.layers !== undefined) {
        for (const layer of r.layers) {
            if (layer.layerType === "text") {
                appPanelDiv.appendChild(parseTextLayer(layer))
            } else if (layer.layerType === "image") {
                appPanelDiv.appendChild(parseImageLayer(layer))
                // Don't let dark mode invert default font color when image exists
                appPanelDiv.style.color = "#000000"
            }
        }
    }
    if (r.table !== undefined) {
        const thisTable = parseTableLayer(r.table)
        appPanelDiv.appendChild(thisTable)
    }
    return appPanelDiv
}

function parseTextLayer(l: IImagePanelLayer): HTMLSpanElement {
    const textSpan = document.createElement("span")
    textSpan.style.position = "absolute"
    textSpan.style.whiteSpace = "nowrap"
    textSpan.style.overflow = "hidden"
    textSpan.style.textOverflow = "ellipsis"
    textSpan.style.lineHeight = "normal"
    textSpan.style.color = l.color
    textSpan.style.fontFamily = l.fontFamily
    textSpan.style.fontSize = l.fontSize
    textSpan.style.fontStyle = l.fontStyle
    textSpan.style.fontWeight = l.fontWeight
    textSpan.style.left = l.left
    textSpan.style.maxWidth = l.maxWidth
    textSpan.innerText = l.text
    textSpan.style.textAlign = l.textAlign
    textSpan.style.top = l.top
    textSpan.style.width = l.width
    return textSpan
}

function parseImageLayer(l: IImagePanelLayer): HTMLImageElement {
    const image = document.createElement("img")
    image.style.position = "absolute"
    image.alt = ""
    image.style.height = l.height
    image.src = l.imageUrl
    image.style.left = l.left
    image.style.top = l.top
    image.style.width = l.width
    image.style.opacity = l.opacity
    return image
}

function parseTableLayer(t: IImagePanelTable): HTMLDivElement {
    const appPanelTable = document.createElement("div")
    appPanelTable.style.height = `${appPanelHeight}px`
    appPanelTable.style.width = `${appPanelWidth}px`
    appPanelTable.style.backgroundColor = t.backgroundColor
    appPanelTable.style.color = t.color
    appPanelTable.style.fontStyle = t.fontStyle
    appPanelTable.style.fontWeight = t.fontWeight
    appPanelTable.style.textAlign = t.textAlign
    for (const row of [t.rowOne, t.rowTwo, t.rowThree]) {
        let thisRow: HTMLDivElement
        if (row !== undefined) {
            thisRow = parseTableLayerRow(row)
        } else {
            thisRow = parseTableLayerRow()
        }
        appPanelTable.appendChild(thisRow)
    }
    return appPanelTable
}

function parseTableLayerRow(r?: IImageTemplateRow): HTMLDivElement {
    const row = document.createElement("div")
    row.style.height = `${appPanelHeight / 3}px`
    row.style.lineHeight = `${appPanelHeight / 3}px`
    row.style.width = `${appPanelWidth}px`
    if (r !== undefined) {
        row.style.backgroundColor = r.backgroundColor
        row.style.color = r.color
        row.style.fontStyle = r.fontStyle
        row.style.fontWeight = r.fontWeight
        row.style.textAlign = r.textAlign
        if (r.header !== undefined) {
            const thisHeader = parseTableLayerCol(r.header)
            row.appendChild(thisHeader)
        }
        if (r.data !== undefined) {
            const thisData = parseTableLayerCol(r.data)
            row.appendChild(thisData)
        }
    }
    return row
}

function parseTableLayerCol(d: IImagePanelCol): HTMLDivElement {
    const rowData = document.createElement("div")
    rowData.style.position = "absolute"
    rowData.style.whiteSpace = "nowrap"
    rowData.style.overflow = "hidden"
    rowData.style.textOverflow = "ellipsis"
    rowData.style.position = "absolute"
    rowData.style.backgroundColor = d.backgroundColor
    rowData.style.color = d.color
    rowData.style.fontStyle = d.fontStyle
    rowData.style.fontWeight = d.fontWeight
    rowData.style.textAlign = d.textAlign
    rowData.innerText = d.value
    rowData.style.width = d.width
    rowData.style.left = d.left
    return rowData
}
