import { addDragListener, DragListenerStartCB } from "../../common/dragListener"

export const enum resizePosition {
    topLeft,
    topRight,
    bottomRight,
    bottomLeft,
    leftMiddle,
    rightMiddle,
    bottomMiddle,
    topMiddle,
}

interface IResizeConfig {
    initialBottom: number,
    initialRight: number,
    initialWidth: number,
    initialX: number,
    initialY: number,
    ratio: number,
    minWidth: number
}
interface IResizedDims {
    bottom: number,
    right: number,
    width: number,
}

export function addResizeAreas(element: HTMLElement, resizeListenerCallback: (position: resizePosition) => DragListenerStartCB): void {
    const createClickableOverlay = (cursor: string) => {
        const overlay = document.createElement("div")
        overlay.style.position = "absolute"
        overlay.style.cursor = cursor
        // Required to be clickable on IE. Nonzero alpha sometimes required to be touchable on Puffin
        overlay.style.backgroundColor = "rgba(0, 0, 0, 0.01)"
        element.appendChild(overlay)
        return overlay
    }

    const topLeftResize = createClickableOverlay("nwse-resize")
    topLeftResize.style.height = "10px"
    topLeftResize.style.width = "10px"
    topLeftResize.style.top = "-5px"
    topLeftResize.style.left = "-5px"
    const topRightResize = createClickableOverlay("nesw-resize")
    topRightResize.style.height = "10px"
    topRightResize.style.width = "10px"
    topRightResize.style.top = "-5px"
    topRightResize.style.right = "-5px"
    const bottomRightResize = createClickableOverlay("nwse-resize")
    bottomRightResize.style.height = "10px"
    bottomRightResize.style.width = "10px"
    bottomRightResize.style.bottom = "-5px"
    bottomRightResize.style.right = "-5px"
    const bottomLeftResize = createClickableOverlay("nesw-resize")
    bottomLeftResize.style.height = "10px"
    bottomLeftResize.style.width = "10px"
    bottomLeftResize.style.bottom = "-5px"
    bottomLeftResize.style.left = "-5px"
    const leftMiddleResize = createClickableOverlay("ew-resize")
    leftMiddleResize.style.height = "calc(100% - 10px)"
    leftMiddleResize.style.width = "10px"
    leftMiddleResize.style.top = "5px"
    leftMiddleResize.style.left = "-5px"
    const rightMiddleResize = createClickableOverlay("ew-resize")
    rightMiddleResize.style.height = "calc(100% - 10px)"
    rightMiddleResize.style.width = "10px"
    rightMiddleResize.style.top = "5px"
    rightMiddleResize.style.right = "-5px"
    const bottomMiddleResize = createClickableOverlay("ns-resize")
    bottomMiddleResize.style.height = "10px"
    bottomMiddleResize.style.width = "calc(100% - 10px)"
    bottomMiddleResize.style.left = "5px"
    bottomMiddleResize.style.bottom = "-5px"
    const topMiddleResize = createClickableOverlay("ns-resize")
    topMiddleResize.style.height = "5px"
    topMiddleResize.style.width = "calc(100% - 10px)"
    topMiddleResize.style.left = "5px"
    topMiddleResize.style.top = "-5px"

    addDragListener(bottomRightResize, resizeListenerCallback(resizePosition.bottomRight))
    addDragListener(bottomLeftResize, resizeListenerCallback(resizePosition.bottomLeft))
    addDragListener(topRightResize, resizeListenerCallback(resizePosition.topRight))
    addDragListener(topLeftResize, resizeListenerCallback(resizePosition.topLeft))
    addDragListener(leftMiddleResize, resizeListenerCallback(resizePosition.leftMiddle))
    addDragListener(rightMiddleResize, resizeListenerCallback(resizePosition.rightMiddle))
    addDragListener(bottomMiddleResize, resizeListenerCallback(resizePosition.bottomMiddle))
    addDragListener(topMiddleResize, resizeListenerCallback(resizePosition.topMiddle))
}

export function getResizedDims(config: IResizeConfig, x: number, y: number, position: resizePosition): IResizedDims {
    const rawDifference = getResizeDifference(config, x, y, position)
    const newWidth = Math.min(Math.max(config.minWidth, config.initialWidth + rawDifference), window.innerWidth * 0.66)
    const difference = config.initialWidth - newWidth
    let currentBottom = config.initialBottom
    let currentRight = config.initialRight
    switch (position) {
        case resizePosition.topRight:
            currentRight = config.initialRight + difference
            break
        case resizePosition.bottomRight:
            currentRight = config.initialRight + difference
            currentBottom = config.initialBottom + difference * config.ratio
            break
        case resizePosition.bottomLeft:
            currentBottom = config.initialBottom + difference * config.ratio
            break
        case resizePosition.leftMiddle:
            currentBottom = config.initialBottom + (difference * config.ratio / 2)
            break
        case resizePosition.rightMiddle:
            currentRight = config.initialRight + difference
            currentBottom = config.initialBottom + (difference * config.ratio / 2)
            break
        case resizePosition.bottomMiddle:
            currentRight = config.initialRight + (difference / 2)
            currentBottom = config.initialBottom + difference * config.ratio
            break
        case resizePosition.topMiddle:
            currentRight = config.initialRight + (difference / 2)
            break
    }

    return { bottom: currentBottom, right: currentRight, width: newWidth }
}

function getResizeDifference(config: IResizeConfig, x: number, y: number, position: resizePosition): number {
    switch (position) {
        case resizePosition.topLeft:
            return config.ratio * ((config.initialX - x) + (config.initialY - y))
            break
        case resizePosition.topRight:
            return config.ratio * (-(config.initialX - x) + (config.initialY - y))
            break
        case resizePosition.bottomRight:
            return config.ratio * (-(config.initialX - x) - (config.initialY - y))
            break
        case resizePosition.bottomLeft:
            return config.ratio * ((config.initialX - x) - (config.initialY - y))
            break
        case resizePosition.leftMiddle:
            return config.initialX - x
            break
        case resizePosition.rightMiddle:
            return -(config.initialX - x)
            break
        case resizePosition.bottomMiddle:
            return -(config.initialY - y) / config.ratio
            break
        case resizePosition.topMiddle:
            return (config.initialY - y) / config.ratio
            break
    }
}