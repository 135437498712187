import { isFullscreen } from "../fullscreen"
import { addPageAction } from "../newrelic"
import { SessionMetrics } from "../theatermodelib/sessionMetrics"
import { mobilePureChatDragEnd, playerChangeSize, PlayerSizeChangeType } from "./mobileControlsEvents"
import { isPortrait, screenOrientationChanged } from "./windowOrientation"
import type { IPlayerChangeSize } from "./mobileControlsEvents"
import type { MobilePlayer } from "./mobilePlayer"
import type { VideoMode } from "../videoModeHandler"
import type { NewrelicAttributes } from "@multimediallc/web-utils"

export const enum MobileMetricsVideoMode {
    LF = "landscapeFullscreen",
    PC = "portraitClassic",
    PF = "portraitFullscreen",
}

export class SessionMetricsMobile extends SessionMetrics {
    private chatResizeCount = 0
    private videoResize = {
        videoResizeCount: 0,
        videoRepositionCount: 0,
    }
    protected actionName = "SessionMetricsMobile"
    private player?: MobilePlayer
    private playerHeight: number
    private playerWidth: number
    private oldChat: number

    constructor() {
        super()
        this.loadFromStorage()
        this.currentMode = this.getMode()
        mobilePureChatDragEnd.listen(this.onChatResize)
        playerChangeSize.listen(this.onPlayerResize)
    }

    protected setupModeChangeListener(): void {
        screenOrientationChanged.listen(this.onModeChange)
        this.player?.toggleFullscreen.listen(this.onModeChange)
    }

    public bindPlayer(player: MobilePlayer): void {
        this.player = player
    }

    public playerDimensions(): void {
        if (this.player !== undefined){
            this.playerHeight = this.player.element.offsetHeight
            this.playerWidth = this.player.element.offsetWidth
            this.oldChat = screen.availHeight - this.playerHeight
        }
    }

    public getCurrentMode(): MobileMetricsVideoMode | VideoMode {
        return this.currentMode
    }

    protected resetMetrics(): void {
        super.resetMetrics()

        this.chatResizeCount = 0
        this.videoResize = {
            videoResizeCount: 0,
            videoRepositionCount: 0,
        }

        if (Boolean(window.sessionStorage)) {
            window.sessionStorage.removeItem(this.actionName)
        }
    }

    protected getPageActionAttributes(): NewrelicAttributes {
        return {
            ...super.getPageActionAttributes(),
            "videoResizeCount": this.videoResize.videoResizeCount,
            "videoRepositionCount": this.videoResize.videoRepositionCount,
            "chatResizeCount": this.chatResizeCount,
        }
    }

    protected getMode(): MobileMetricsVideoMode {
        if (isPortrait()) {
            if (this.player !== undefined) {
                return this.player.isFullscreen ? MobileMetricsVideoMode.PF : MobileMetricsVideoMode.PC
            }
            return isFullscreen() ? MobileMetricsVideoMode.PF : MobileMetricsVideoMode.PC
        }
        return MobileMetricsVideoMode.LF
    }

    private onChatResize = (): void => {
        this.chatResizeCount += 1
        this.saveToStorage()
    }

    private onPlayerResize = ({ changeType }: IPlayerChangeSize): void => {
        if (this.currentMode === MobileMetricsVideoMode.LF) {
            return
        }
        if (changeType === PlayerSizeChangeType.Resize) {
            this.videoResize.videoResizeCount += 1
            this.recordResize()
        } else if (changeType === PlayerSizeChangeType.Reposition) {
            this.videoResize.videoRepositionCount += 1
        }
        this.saveToStorage()
    }

    private loadFromStorage(): void {
        if (!Boolean(window.sessionStorage)) {
            return
        }
        const storageItem = window.sessionStorage.getItem(this.actionName)
        if (storageItem === null) {
            return
        }
        const data = JSON.parse(storageItem)
        this.videoResize.videoResizeCount = data.videoResizeCount
        this.videoResize.videoRepositionCount = data.videoRepositionCount
        this.chatResizeCount = data.chatResizeCount
    }

    private saveToStorage(): void {
        if (!Boolean(window.sessionStorage)) {
            return
        }
        window.sessionStorage.setItem(this.actionName, JSON.stringify({
            "videoResizeCount": this.videoResize.videoResizeCount,
            "videoRepositionCount": this.videoResize.videoRepositionCount,
            "chatResizeCount": this.chatResizeCount,
        }))
    }

    public recordResize(): void {
        if (this.player !== undefined) {
            const newChat = screen.availHeight - this.player.element.offsetHeight
            addPageAction("VideoResized", {
                "video_height_old": this.playerHeight,
                "video_height_new": this.player.element.offsetHeight,
                "video_width_old": this.playerWidth,
                "video_width_new": this.player.element.offsetWidth,
                "chat_height_old": this.oldChat,
                "chat_height_new": newChat,
                // Chat width does not change in portrait, this is included for completeness
                "chat_width_old": screen.availWidth,
                "chat_width_new": screen.availWidth,
            })
            this.playerHeight = this.player.element.offsetHeight
            this.playerWidth = this.player.element.offsetWidth
            this.oldChat = newChat
        }
    }
}
