import { isPremium } from "@multimediallc/cb-roomlist-prefetch"
import { isMobileDevice, isPuffin, isSafari } from "@multimediallc/web-utils/modernizr"
import { currentSiteSettings } from "../cb/siteSettings"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { EventRouter } from "./events"

interface IRoomImageStreamerOptions {
    startStreaming: boolean,
    loadInitialImage: boolean,
    fadeOutTimeoutMs: number,  // How long to stream images after stopStreaming is called.
    streamingFadeoutTimeoutMs: number,  // How long to stream images after startStreaming is called, regardless of stream state.
    useWideImages: boolean,
}

export const NEEDS_BF_CACHE_FIX = isSafari() && !isMobileDevice()
export const roomStartStreaming = new EventRouter<string>("roomStartStreaming")

let safariPageShowEvent: EventRouter<void> | undefined

if (NEEDS_BF_CACHE_FIX) {
    safariPageShowEvent = new EventRouter("safariPageShow", { listenersWarningThreshold: 800 })
    addEventListenerPoly("pageshow", window, (event: PageTransitionEvent) => {
        // This listener will be fired when the page is loaded from the cache on a back-button press.
        if (event.persisted && safariPageShowEvent !== undefined) {
            safariPageShowEvent.fire()
        }
    })
}

export class RoomImageStreamer {
    private _streaming = false
    private _imgLoaded = false
    private streamRate: number
    private lastFrameTime = 0
    private fadeOutStopTime = 0  // Datetime in ms when fade out ends and streaming stops.
    private options: IRoomImageStreamerOptions
    private readonly forceNoAnimate: boolean = isPremium()
    private readonly STATIC_BASE_URL: string
    private readonly ANIMATING_BASE_URL: string

    /**
     *
     * @param roomName A room name.
     * @param img Target html image element.
     * @param {Object} options
     * @param options.startStreaming Starts streaming automatically after creation if true.
     * @param options.loadInitialImage Loads a room image automatically after creation if true.
     * @param options.fadeOutTimeoutMs Gradually stop streaming if value is positive.
     */
    constructor(private roomName: string, private img: HTMLImageElement, options: Partial<IRoomImageStreamerOptions> = {}) {
        const defaultOptions = {
            startStreaming: false,
            loadInitialImage: true,
            fadeOutTimeoutMs: 2000,
            streamingFadeoutTimeoutMs: 0,
            useWideImages: true,
        }
        this.options = {
            ...defaultOptions,
            ...options,
        }

        this.STATIC_BASE_URL = `${currentSiteSettings.jpegStreamUrl}${this.options.useWideImages ? "riw" : "ri"}/${this.roomName}.jpg`
        this.ANIMATING_BASE_URL = `${currentSiteSettings.jpegStreamUrl}${this.options.useWideImages ? "minifwap" : "minifap"}/${this.roomName}.jpg`

        this.setStreamRate()
        if (this.options.startStreaming) {
            this.startStreaming()
        } else if (this.options.loadInitialImage) {
            this.loadImage(0).catch(err => {
                warn("Error loading image", { imageSrc: err })
            })
        }
        if (safariPageShowEvent !== undefined) {
            safariPageShowEvent.addListener(() => {
                this.safariReloadImage()
            }, this.img)
        }
    }

    /**
     * Fixes an issue with partially-loaded images stored by bfcache on desktop Safari.
     */
    safariReloadImage(): void {
        if (!this._imgLoaded) {
            this._stopAnimating()
            this.loadImage(0).catch(err => {
                warn("Error loading image", { imageSrc: err })
            })
        }
    }

    private _stopAnimating(): void {
        this.stopStreaming()
        this.fadeOutStopTime = 0
    }

    getStreaming(): boolean {
        return this._streaming
    }

    getFadingOut(): boolean {
        return !this._streaming && new Date().getTime() <= this.fadeOutStopTime
    }

    getAnimating(): boolean {
        return !this.forceNoAnimate && (this.getStreaming() || this.getFadingOut())
    }

    setStreaming(value: boolean): void {
        const noStreamingTimout = this.options.streamingFadeoutTimeoutMs === 0
        if (value) {
            this.fadeOutStopTime = noStreamingTimout ? 0 : new Date().getTime() + this.options.streamingFadeoutTimeoutMs
        } else if (this.fadeOutStopTime === 0) {
            this.fadeOutStopTime = new Date().getTime() + this.options.fadeOutTimeoutMs
        }
        this._streaming = noStreamingTimout && value
    }

    setStreamRate(): void {
        // Higher streamRate on old Puffin browser to avoid flickering
        const userAgent = navigator.userAgent
        const userAgentRE = /Puffin\/(\d)/
        const matchInfo = userAgent.match(userAgentRE)
        if (matchInfo !== null) {
            const versionNumber = parseInt(matchInfo[1])
            if (versionNumber < 8) {
                this.streamRate = 1500
                return
            }
        }
        // Default
        this.streamRate = 140
        return
    }

    loadImage(timestamp: number): Promise<void> {
        if (this.lastFrameTime === 0) {
            this.lastFrameTime = timestamp
        }
        // info(`call timestamp ts ${timestamp} last frame ${this.lastFrameTime}`)
        if (!(timestamp === 0 || timestamp - this.lastFrameTime > this.streamRate)) {
            return Promise.resolve()
        }
        this.lastFrameTime = timestamp
        let src: string
        if (this.getAnimating()) {
            src = `${this.ANIMATING_BASE_URL}?f=${Math.random()}`
        } else {
            src = `${this.STATIC_BASE_URL}?${Math.floor(new Date().getTime() / 30000)}`
        }
        return new Promise((resolve, reject) => {
            const downloadingImage = new Image()
            downloadingImage.onload = () => {
                this.img.onload = () => {
                    this._imgLoaded = true
                }
                this._imgLoaded = false
                this.img.src = downloadingImage.src
                resolve()
            }
            downloadingImage.onerror = () => {
                reject(src)
            }
            downloadingImage.src = src
        })
    }

    startStreaming(): void {
        if (isPuffin() || this.forceNoAnimate) {
            return
        }
        roomStartStreaming.fire(this.roomName)
        const streamingFramesInProgress = this.getAnimating()
        this.setStreaming(true)
        if (streamingFramesInProgress) {
            return
        }
        const streamFrames = (timestamp: number) => {
            if (!this.getAnimating()) {
                return
            }
            this.loadImage(timestamp).then(() => {
                requestAnimationFrame((ts) => {
                    streamFrames(ts)
                })
            }).catch(() => {
                streamFrames(0)
            })
        }
        streamFrames(0)
    }

    stopStreaming(): void {
        this.setStreaming(false)
    }

    stopAnimating(): void {
        this.setStreaming(false)
        this.fadeOutStopTime = 0
    }
}
