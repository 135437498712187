export class Luminance {
    redWeight = 10
    greenWeight = 10
    blueWeight = 10

    constructor(public red: number, public green: number, public blue: number) {
    }

    raiseToMin(minLum: number, noBleeding = false): void {
        const multCoeficient = 0.05

        minLum = Math.min(255, minLum)
        while (minLum > this.brightness() && !(noBleeding && (this.red === 255 || this.green === 255 || this.blue === 255))) {
            this.red = Math.min(this.red + Math.max(1, multCoeficient * this.red), 255)
            this.green = Math.min(this.green + Math.max(1, multCoeficient * this.green), 255)
            this.blue = Math.min(this.blue + Math.max(1, multCoeficient * this.blue), 255)
        }
        this.red = Math.round(this.red)
        this.green = Math.round(this.green)
        this.blue = Math.round(this.blue)
    }

    private totalWeights(): number {
        return this.redWeight + this.greenWeight + this.blueWeight
    }

    public redMultiplier(): number {
        return this.redWeight / this.totalWeights()
    }

    public greenMultiplier(): number {
        return this.greenWeight / this.totalWeights()
    }

    public blueMultiplier(): number {
        return this.blueWeight / this.totalWeights()
    }

    brightness(): number {
        return Math.round(this.redMultiplier() * this.red + this.greenMultiplier() * this.green +
            this.blueMultiplier() * this.blue)
    }
}
