import { Component } from "../../common/defui/component"
import { isScrolledIntoView } from "../../common/DOMutils"
import { addPageAction } from "../../common/newrelic"
import { createNewMessageNoticeDiv } from "../../common/theatermodelib/messageToDOM"

export interface INewMessageLineProps {
    getUnreadCount: () => number | undefined
    isScrolledUp: () => boolean
    isConversationShowing: () => boolean
    setParentScrollTop: (oldScrollTop: number) => void
    scrollParentDiv: HTMLDivElement
}

export class NewMessageLine extends Component<HTMLDivElement> {
    private scrollJumpNeeded = false

    constructor(private props: INewMessageLineProps) {
        super("div", props)
    }

    protected initUI(): void {
        this.element = createNewMessageNoticeDiv()
    }

    public remove(force = false): void {
        if (force || this.props.isConversationShowing()) {
            this.element.remove()
        }
    }

    public getScrollJumpNeeded(): boolean {
        return this.scrollJumpNeeded
    }

    public maybeScrollJump(oldScrollTop: number): void {
        // If new message notice line is present above current current scroll view, 
        // removing the previous line and inserting it DOM again causes the chat to jump.
        // Need to subtract the new notice's height from original scrollTop to maintain chat position.
        if (this.scrollJumpNeeded) {
            this.scrollJumpNeeded = false
            this.props.setParentScrollTop(oldScrollTop - this.element.offsetHeight)
        }
    }

    public shouldAppendNewMessageNotice(countsForUnread: boolean, isFirstHistoryUnread: boolean): boolean {
        // Reposition the newline for the first unread message after the user reaches the bottom(reads all unread messages)
        // and the user scrolls up and the newline goes out of visible area.
        // Also reposition if user is in a different tab/chat session window.
        const doesNewMessageWantNotice = this.props.getUnreadCount() === 1 && countsForUnread
        const isNewMessageOutOfView = this.props.isScrolledUp() || !this.props.isConversationShowing()
        const isNewMessageNoticeOutOfView = !isScrolledIntoView(this.element, this.props.scrollParentDiv) || !this.props.isConversationShowing()
        if (isFirstHistoryUnread || (doesNewMessageWantNotice && isNewMessageOutOfView && isNewMessageNoticeOutOfView)) {
            const wasNoticePreviouslyAppended = this.props.scrollParentDiv.contains(this.element)
            if (wasNoticePreviouslyAppended) {
                this.scrollJumpNeeded = this.element.offsetTop < this.props.scrollParentDiv.scrollTop
            }
            if (this.props.isConversationShowing()) {
                // Track notice only on current active tab & chat content
                // to avoid lot of events being tracked when the notice is appended for offtab chat contents.
                this.trackNoticeAppended(isFirstHistoryUnread, wasNoticePreviouslyAppended)
            }
            return true
        }
        return false
    }

    private trackNoticeAppended(isFirstHistoryUnread: boolean, wasNoticePreviouslyAppended: boolean) {
        const reason = isFirstHistoryUnread ? "First History Unread" : "Scrolled Out of View"
        addPageAction("NewMessageLineNoticeAppended", { "reason": reason, "wasRepositioned": wasNoticePreviouslyAppended })
    }

    public render(): HTMLDivElement {
        return this.element
    }
}
