import {
    ConversationType,
    dmsEnabled, getRoomHistoryMessages,
    pmHistoryBatchSize, PrivateMessageSource, sendPrivateMessage,
} from "../../cb/api/pm"
import { MobileChatLink } from "../../cb/components/pm/chatLinks"
import { ConversationListData } from "../../cb/components/pm/conversationListData"
import { createDmWindowRequest } from "../../cb/components/pm/dmWindowsManager"
import { mobileMediaDockHeight, SelectedMobileMediaDock, showMobileMediaDock } from "../../cb/components/pm/mediaDock"
import { LoadHistoryMessagesDOM } from "../../cb/components/pm/pmControlBar"
import { allPmsRead, privateMessage } from "../../cb/components/pm/userActionEvents"
import { pageContext } from "../../cb/interfaces/context"
import { currentSiteSettings } from "../../cb/siteSettings"
import { isNotLoggedIn } from "../auth"
import { roomCleanup ,roomLoaded, userViewedPm } from "../context"
import { DefDeck } from "../defDeck"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { decEventsPmSessionsCount, EventRouter, incEventsPmSessionsCount } from "../events"
import { addPageAction } from "../newrelic"
import { ignoreCatch } from "../promiseUtils"
import { MobileRoomNotice } from "../roomNotice"
import { createDMChatLinkMessage, createRoomPhotoMessage } from "../theatermodelib/messageToDOM"
import { userChatSettingsUpdate } from "../theatermodelib/userActionEvents"
import { i18n } from "../translation"
import { getMoreHistoryMessages, userInitiatedPm } from "../userActionEvents"
import { ChatContents, inputDivHeight } from "./chatContents"
import { createLogMessage, createRoomMessage, createSupporterSignupMessage } from "./messageToDOM"
import { RoomTabs } from "./roomTabs"
import { TabName } from "./tabList"
import { openTipCalloutRequest, toggleDms, userSwitchedTab } from "./userActionEvents"
import type {
    IPMError } from "../../cb/api/pm";
import type { IPMAnnouncement } from "../../cb/components/pm/chatLinks";
import type { IChatConnection } from "../context";
import type { IPrivateMessage, IRemoveMessagesNotification } from "../messageInterfaces"

interface IPMSession {
    user: string
    numUnread: number
    chatContents: ChatContents
    isNewSessionNotified: boolean
    loadHistoryMessagesDOM: LoadHistoryMessagesDOM
    isInitialHistoryLoaded: boolean
    isAllHistoryLoaded: boolean
    isLoadingHistory: boolean
    isActive: () => boolean
}

interface IPmWindowProps {
    leavePmWindow: () => void
}

export const otherUserInitiatedPm = new EventRouter<IPMAnnouncement>("otherUserInitiatedPm")

export class MobilePmWindow extends Component<HTMLDivElement, IPmWindowProps> {
    public newPMSessionEvent: EventRouter<IPMSession>
    public closePMSessionEvent: EventRouter<IPMSession>
    public numUnreadChanged: EventRouter<number>
    private pmSessions: Map<string, IPMSession>
    private orderedPMSessionKeys: DefDeck<string>
    private chatConnection: IChatConnection
    private numUnread: number
    private leavePmWindow: () => void
    private backButtonContainer: HTMLDivElement
    private sendDmButton: HTMLSpanElement
    private mobileMediaDock: SelectedMobileMediaDock
    private mediaDockHeight = 0

    constructor(props: IPmWindowProps) {
        super("div", props)
    }

    protected initData(props: IPmWindowProps): void {
        this.newPMSessionEvent = new EventRouter<IPMSession>("newPMSession")
        this.closePMSessionEvent = new EventRouter<IPMSession>("closePMSession")
        this.numUnreadChanged = new EventRouter<number>("numUnreadChanged")
        this.pmSessions = new Map<string, IPMSession>()
        this.orderedPMSessionKeys = new DefDeck<string>()
        this.numUnread = 0
        this.leavePmWindow = props.leavePmWindow
    }

    protected initUI(props: IPmWindowProps): void {
        applyStyles(this.element, {
            position: "unset",
            display: "flex",
            flexDirection: "column",
        })
        this.backButtonContainer = this.createBackButton({
            onBackClick: this.leavePmWindow,
            onSendDmClick: () => {
                if (isNotLoggedIn(i18n.loggedInToDm)) {
                    return
                }

                const currPmWindowUser = this.currentlyDisplayedPmSession()?.user

                if (currPmWindowUser !== undefined) {
                    toggleDms.fire(true)
                    createDmWindowRequest.fire(currPmWindowUser)
                }
            },
        })
        this.element.appendChild(this.backButtonContainer)

        roomLoaded.listen((context) => {
            this.chatConnection = context.chatConnection

            if (context.dossier.userName !== context.dossier.room) {
                userInitiatedPm.fire({ username: context.dossier.room, focus: false, showSupporterAlert: context.dossier.needsSupporterToPm })
            }

            context.chatConnection.event.roomNotice.listen((roomNoticeData) => {
                if (roomNoticeData.showInPrivateMessage) {
                    this.possiblyAppendMessageDiv(new MobileRoomNotice({ roomNoticeData, neverCollapse: true }).element)
                }
            })

            context.chatConnection.event.removeMessages.listen((removeMessages: IRemoveMessagesNotification) => {
                this.closePMSession(removeMessages.username, true)
            })

            this.adjustBackButtonSize(context.dossier.userChatSettings.fontSize)
        })

        getMoreHistoryMessages.listen(() => {
            const pmUser = this.orderedPMSessionKeys.currentElem as string
            const pmSession = this.pmSessions.get(pmUser) as IPMSession
            if (pmSession.isAllHistoryLoaded === false) {
                this.loadHistoryMessages(pmSession).catch(ignoreCatch)
            }
        })

        showMobileMediaDock.listen((dockVisible) => {
            this.mediaDockHeight = dockVisible ? mobileMediaDockHeight() + 10 : 0
            this.repositionChildren()
            const session = this.currentlyDisplayedPmSession()
            if (session === undefined) {
                return
            }
            session.chatContents.scrollToBottom()
        })

        userChatSettingsUpdate.listen((settings) => {
            this.adjustBackButtonSize(settings.fontSize)
        })

        userInitiatedPm.listen((notification) => {
            this.hideCurrentPMSession()
            if (this.pmSessions.get(notification.username) === undefined) {
                this.initializePmSession(notification.username, false, notification.showSupporterAlert)
            }
            if (notification.focus) {
                userSwitchedTab.fire(TabName.Private)
                this.definePMLinkOnClick(notification.username)
            }
        })

        privateMessage.listen((m) => {
            this.newPrivateMessage(m)
            const currentSession = this.currentlyDisplayedPmSession()

            if (currentSession !== undefined) {
                this.addPageActions(currentSession, m)

                if (m.otherUsername === currentSession.user &&
                    !currentSession.chatContents.isScrolledUp() &&
                    currentSession.isActive()
                ) {
                    allPmsRead.fire(currentSession.user)
                }
            }
        })

        ConversationListData.conversationRead.listen(({ username, isDm }) => {
            if (!isDm) {
                const pmSession = this.pmSessions.get(username)
                if (pmSession !== undefined) {
                    this.markSessionRead(pmSession)
                }
            }
        })

        roomCleanup.listen(() => {
            this.clear()
            this.resetNumUnread()
        })
    }

    private addPageActions(session: IPMSession, message: IPrivateMessage, fromHistory = false) {
        const messageIsFromSelf = pageContext.current.loggedInUser?.username === message.fromUser.username
        const messageIsForCurrentSession = session.user === message.fromUser.username

        if (session.isActive() && !fromHistory && !messageIsFromSelf && messageIsForCurrentSession) {
            addPageAction("PMReceivedOpen", { "message_id": message.messageID })
        }
    }

    public loadHistoryMessages(pmSession: IPMSession, initialHistory = false): Promise<void> {
        if (pmSession.isAllHistoryLoaded || pmSession.isLoadingHistory) {
            return Promise.reject()
        }
        const oldListLength = pmSession.chatContents.messageList.clientHeight
        const offset = initialHistory ? "0" : pmSession.chatContents.getEarliestMessageId()
        this.setIsLoadingHistory(pmSession, true)
        return getRoomHistoryMessages(pmSession.user, this.chatConnection.room(), offset).then(pmList => {
            const messages = pmList.messages
            if (messages.length < pmHistoryBatchSize) {
                pmSession.isAllHistoryLoaded = true
            }
            // each pmSession has its own numUnread which needs to be used to append the newLine notice properly before latest unread
            const numUnread = ConversationListData.getInstance().getConversation(pmSession.user)?.numUnread ?? -1
            messages.forEach((pm: IPrivateMessage, index: number) => {
                pm.isFirstHistoryUnread = index === (messages.length - numUnread)
                this.newPrivateMessage(pm, true)
            })
            if (messages.length > 0) {
                pmSession.chatContents.setEarliestMessageId(messages[0].messageID)
            }
            const newListLength = pmSession.chatContents.messageList.clientHeight
            pmSession.chatContents.messageListWrapper.scrollTop = newListLength - oldListLength
        }).catch(err => {
            error(err)
        }).finally(() => {
            this.setIsLoadingHistory(pmSession, false)
        })
    }

    public setIsLoadingHistory(pmSession: IPMSession, status: boolean): void {
        pmSession.isLoadingHistory = status
        if (status) {
            pmSession.loadHistoryMessagesDOM.showLoading()
        } else {
            pmSession.loadHistoryMessagesDOM.hideLoading()
        }
    }

    protected repositionChildren(): void {
        super.repositionChildren()
        const session = this.currentlyDisplayedPmSession()
        if (session === undefined) {
            return
        }

        const messageListWrapperHeight = this.element.offsetHeight - inputDivHeight() - this.backButtonContainer.offsetHeight - this.mediaDockHeight
        session.chatContents.setMessageListWrapperHeight(messageListWrapperHeight)
    }

    public sendPrivateMessageFailCallback = (error: IPMError): void => {
        const pmUser = this.orderedPMSessionKeys.currentElem as string
        const pmSession = this.pmSessions.get(pmUser) as IPMSession
        if (dmsEnabled() && error.showDmLink) {
            pmSession.chatContents.appendMessageDiv(createDMChatLinkMessage(error.errorMessage, error.username))
        } else {
            pmSession.chatContents.appendMessageDiv(createLogMessage(error.errorMessage))
        }
    }

    private initializePmSession(pmUser: string, otherUserInitiated: boolean, showSupporterAlert: boolean, fromHistory = false): IPMSession {
        let pmSession = this.pmSessions.get(pmUser)
        if (pmSession === undefined) {
            incEventsPmSessionsCount()
            const pmChatContents = new ChatContents({
                onTipRequest: (tipRequest) => {
                    openTipCalloutRequest.fire(tipRequest)
                },
                onToggleDebugMode: () => {
                    this.chatConnection.toggleAppDebugging()
                },
                onChatMessage: (msg) => {
                    this.orderedPMSessionKeys.addToTop(pmUser)
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    sendPrivateMessage({
                        message: msg,
                        username: pmUser,
                        source: PrivateMessageSource.MobilePM,
                        roomName: this.chatConnection.room(),
                        media: this.mobileMediaDock.mobileMediaList(),
                    }).catch((err) => {
                        this.sendPrivateMessageFailCallback(err)
                    })
                    if (!this.mobileMediaDock.isEmpty()) {
                        this.mobileMediaDock.clear()
                        this.mediaDockHeight = 0
                        this.repositionChildren()
                    }
                    allPmsRead.fire(pmUser)
                },
            }, () => this.isConversationShowing(pmUser), true)

            pmSession = {
                chatContents: pmChatContents,
                numUnread: 0,
                user: pmUser,
                loadHistoryMessagesDOM: new LoadHistoryMessagesDOM(),
                isInitialHistoryLoaded: false,
                isNewSessionNotified: false,
                isAllHistoryLoaded: false,
                isLoadingHistory: false,
                isActive: () => pmChatContents.element.style.display !== "none",
            }
            pmChatContents.scrolledToBottom.listen(() => {
                if (this.currentlyDisplayedPmSession() === pmSession) {
                    allPmsRead.fire(pmUser)
                }
            })

            this.mobileMediaDock = new SelectedMobileMediaDock(pmChatContents)
            pmChatContents.initMediaDock(this.mobileMediaDock)
            this.mobileMediaDock.setRoom(this.chatConnection.room())

            this.newPMSessionEvent.fire(pmSession)
            this.pmSessions.set(pmUser, pmSession)
            pmSession.chatContents.appendNoticeDiv(createLogMessage(i18n.privateConversationWithText(pmUser)))
            pmSession.chatContents.appendNoticeDiv(createLogMessage(i18n.conversationCautionMessage(currentSiteSettings.siteName)))
            if (!otherUserInitiated && showSupporterAlert) {
                const isViewerAgeVerified = pageContext.current.loggedInUser?.isAgeVerified ?? false
                pmSession.chatContents.appendMessageDiv(createSupporterSignupMessage(pmUser, isViewerAgeVerified))
            } else {
                pmSession.chatContents.appendNoticeDiv(pmSession.loadHistoryMessagesDOM.getElement())
            }
            pmSession.chatContents.element.style.display = "none"
            this.addChild(pmSession.chatContents)
        }
        if (!fromHistory && otherUserInitiated && !pmSession.isNewSessionNotified) {
            pmSession.isNewSessionNotified = true
            otherUserInitiatedPm.fire({
                username: pmUser,
                PMChatLink: new MobileChatLink({
                    onClick: () => {
                        userInitiatedPm.fire({ username: pmUser, focus: true, showSupporterAlert: false })
                    },
                    conversationType: ConversationType.PM,
                }),
            })
        }
        this.repositionChildren() // set list height when init
        return pmSession
    }

    private isConversationShowing(pmUser: string): boolean {
        const session = this.getSession(pmUser)
        if (session !== undefined) {
            return session.isActive()
        }
        return false
    }

    public definePMLinkOnClick(pmUser: string): void {
        this.hideCurrentPMSession()
        this.orderedPMSessionKeys.makeCurrent(pmUser)
        this.showCurrentPMSession()
    }

    private newPrivateMessage(m: IPrivateMessage, fromHistory = false): void {
        if (!this.chatConnection.isBroadcasting) {
            return
        }

        this.appendMessageDiv(createRoomMessage(m), m, fromHistory, false)
        const photoMessage = createRoomPhotoMessage(m)
        if (photoMessage !== undefined) {
            this.appendMessageDiv(photoMessage, m, fromHistory, true)
        }

        this.maybeIncrementUnreadForPm(m, fromHistory)
    }

    private maybeIncrementUnreadForPm(m: IPrivateMessage, fromHistory = false): void {
        if (m.fromUser.username === pageContext.current.loggedInUser?.username || fromHistory || (m.isPrivateShowMessage === true && RoomTabs.currentTab === TabName.Chat)) {
            return
        }

        const pmSession = this.pmSessions.get(m.otherUsername)
        if (pmSession && (this.orderedPMSessionKeys.currentElem !== m.otherUsername || !pmSession.isActive())) {
            pmSession.numUnread += 1
            this.incrementUnread()
        }
    }

    public incrementUnread(num = 1): void {
        if (num <= 0) {
            return
        }

        this.numUnread += num
        this.numUnreadChanged.fire(this.numUnread)
    }

    public decrementUnread(num = 1): void {
        if (num <= 0) {
            return
        }

        this.numUnread -= num
        this.numUnreadChanged.fire(this.numUnread)
    }

    private resetNumUnread(): void {
        this.numUnread = 0
        this.numUnreadChanged.fire(this.numUnread)
    }

    private markSessionRead(pmSession: IPMSession): void {
        if (pmSession.numUnread > 0) {
            this.decrementUnread(pmSession.numUnread)
            pmSession.numUnread = 0
        }
        userViewedPm.fire(pmSession.user)
        allPmsRead.fire(pmSession.user)
    }

    public afterListItemRemoved(username: string): void {
        const session = this.pmSessions.get(username)

        if (session !== undefined) {
            this.decrementUnread(session.numUnread)
            session.numUnread = 0
        }
    }

    private appendMessageDiv(c: HTMLDivElement, pm: IPrivateMessage, fromHistory: boolean, isPhotoMessage: boolean): void {
        this.orderedPMSessionKeys.addToTop(pm.otherUsername)
        const pmSession = this.initializePmSession(pm.otherUsername, pm.fromUser.username !== this.chatConnection.username(), false, fromHistory)
        if (fromHistory && pmSession.isInitialHistoryLoaded === false) {
            pmSession.isInitialHistoryLoaded = true
        }
        const isMine = pageContext.current.loggedInUser?.username === pm.fromUser.username
        if (pmSession.isInitialHistoryLoaded) {
            pmSession.chatContents.appendMessageDiv(c, !fromHistory && !isPhotoMessage && !isMine, pm.isFirstHistoryUnread)
        }
    }

    public possiblyAppendMessageDiv(div: HTMLDivElement): void {
        const session = this.currentlyDisplayedPmSession()
        if (session !== undefined && session.isActive()) {
            session.chatContents.appendMessageDiv(div)
        }
    }

    public getNumSessions(): number {
        return this.pmSessions.size
    }

    public currentlyDisplayedPmSession(): IPMSession | undefined {
        if (this.orderedPMSessionKeys.currentElem === undefined) {
            return undefined
        }
        return this.pmSessions.get(this.orderedPMSessionKeys.currentElem)
    }

    // showNextPMSession returns false if there are no more sessions
    public showNextPMSession(): boolean {
        this.hideCurrentPMSession()
        if (this.orderedPMSessionKeys.nextElem() === undefined) {
            return false
        }
        this.showCurrentPMSession()
        return true
    }

    public closePMSession(key: string, showNextSession = false): void {
        const session = this.pmSessions.get(key)
        if (session === undefined) {
            return
        }
        this.closePMSessionEvent.fire(session)
        const wasShowingThisPMSession = this.orderedPMSessionKeys.currentElem === key
        this.orderedPMSessionKeys.remove(key)
        this.removeChild(session.chatContents)
        session.chatContents.dispose()
        this.pmSessions.delete(key)
        if (wasShowingThisPMSession && showNextSession) {
            if (!this.showNextPMSession()) {
                this.leavePmWindow()
            }
        }
        this.decrementUnread(session.numUnread)
        decEventsPmSessionsCount()
    }

    public showCurrentPMSession(): void {
        if (this.orderedPMSessionKeys.currentElem === undefined) {
            error("no currentpmsession")
            return
        }

        const currentSessionKey = this.orderedPMSessionKeys.currentElem
        const newSession = this.pmSessions.get(currentSessionKey)

        if (newSession === undefined) {
            error("PmWindow.showCurrentPMSession - no pm session")
            return
        }
        if (!newSession.isInitialHistoryLoaded) {
            this.loadHistoryMessages(newSession, true).then(() => {
                if (newSession !== undefined) {
                    this.markSessionRead(newSession)
                }
                newSession.isInitialHistoryLoaded = true
            }).catch(ignoreCatch)
        }

        newSession.chatContents.showElement()
        this.sendDmButton.textContent = i18n.sendDmToUser(newSession.user)

        this.repositionChildrenRecursive()
        newSession.chatContents.scrollToBottom()
        if (newSession.isInitialHistoryLoaded) {
            this.markSessionRead(newSession)
        }
    }

    public hideCurrentPMSession(): void {
        const currentlyDisplayedPmSession = this.currentlyDisplayedPmSession()
        if (currentlyDisplayedPmSession !== undefined) {
            currentlyDisplayedPmSession.chatContents.element.style.display = "none"
        }
    }

    public getSession(username: string): IPMSession | undefined {
        return this.pmSessions.get(username)
    }

    public getOrCreateSession(username: string, otherUserInitiated = false, showSupporterAlert = false, fromHistory = false): IPMSession | undefined {
        if (username === "") {
            error("Trying to create PM session for invalid username")
            return undefined
        }
        return this.pmSessions.get(username) ?? this.initializePmSession(username, otherUserInitiated, showSupporterAlert, fromHistory)
    }

    public clear(): void {
        for (const sessionName of this.pmSessions.keys()) {
            this.closePMSession(sessionName)
        }
    }

    private adjustBackButtonSize(size: string): void {
        this.backButtonContainer.style.fontSize = size
    }

    private createBackButton(props: { onBackClick: () => void, onSendDmClick: () => void }): HTMLDivElement {
        const containerStyle: CSSX.Properties = {
            borderBottom: "1px solid #EFEFEF",
            padding: "12px",
            fontSize: "9pt",
            fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
            boxSizing: "border-box",
            display: "flex",
            gap: "16px",
        }
        const backButtonStyle: CSSX.Properties = {
            display: "inline-block",
            color: "#0471a1",
            flex: 1,
        }
        const sendDmStyle: CSSX.Properties = {
            display: "inline-block",
            color: "#DC5500",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        }

        const container = document.createElement("div")
        applyStyles(container, containerStyle)

        const backButton = document.createElement("span")
        applyStyles(backButton, backButtonStyle)
        backButton.onclick = props.onBackClick
        backButton.innerText = i18n.back
        container.appendChild(backButton)

        this.sendDmButton = document.createElement("span")
        applyStyles(this.sendDmButton, sendDmStyle)
        this.sendDmButton.onclick = props.onSendDmClick
        container.appendChild(this.sendDmButton)

        return container
    }
}
