import { normalizeResource } from "../../../common/api"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { dom } from "../../../common/tsxrender/dom"
import { addPageQueryParam } from "./urlUtils"
import type { IPageClickedInfo } from "./paginationButtonInterfaces"

export interface IDirectionalNavigationButtonProps extends IPageClickedInfo {
    baseUrl?: string
    direction: "prev" | "next"
    onClick: (e: MouseEvent) => void
    pageParam?: string
}

export class DirectionalNavigationButton extends HTMLComponent<HTMLAnchorElement, IDirectionalNavigationButtonProps> {
    private _anchorElement: HTMLAnchorElement

    protected createElement(props: IDirectionalNavigationButtonProps): HTMLAnchorElement {
        return <li>
            <a className={`${props.direction} no-link condensed`}
                data-testid={`${props.direction}-button`}
                onClick={props.onClick}
                href={normalizeResource(addPageQueryParam(props.page, props.baseUrl, props.pageParam))}
                ref={(el: HTMLAnchorElement) => { this._anchorElement = el }}
            />
        </li>
    }

    public toggleDisable(disabled: boolean): void {
        if (disabled) {
            this._anchorElement.classList.add("no-link")
        } else {
            this._anchorElement.classList.remove("no-link")
        }
    }

    public isDisabled(): boolean {
        return this.element.classList.contains("no-link")
    }
}
