import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { getScrollbarWidth } from "../DOMutils"
import { getViewportWidth } from "../mobilelib/viewportDimension"
import type { DraggableCanvasWindow } from "./draggableCanvasWindow"

export const enum WindowPositionerAnchors { Left, Right, Top, Bottom, Center }

interface IWindowPositionerConfig {
    minWidth: number
    minHeight: number
    defaultWidth: number
    defaultHeight: number
    defaultAnchors: WindowPositionerAnchors[]
    resizeable: boolean
    autoSize?: boolean
}

interface IBounds {
    leftBound: number,
    topBound: number,
    bottomBound: number,
    rightBound: number,
    width: number,
    height: number,
}

export class WindowPositioner {
    localStorageKey: string
    public bounds = {
        leftBound: NaN,
        topBound: NaN,
        bottomBound: NaN,
        rightBound: NaN,
        width: NaN,
        height: NaN,
    }

    constructor(private draggableCanvasWindow: DraggableCanvasWindow, windowName: string, private config: IWindowPositionerConfig) {
        this.localStorageKey = `window__${windowName}`
        this.bounds = this.getBoundsFromStorage()
        if (isNaN(this.bounds.width)) {
            this.bounds.width = this.config.defaultWidth
        }
        if (isNaN(this.bounds.height)) {
            this.bounds.height = this.config.defaultHeight
        }
        this.setDefaultHorizontalBounds()
        this.setDefaultVerticalBounds()
    }

    private setDefaultVerticalBounds(): void {
        if (isNaN(this.bounds.topBound) && isNaN(this.bounds.bottomBound)) {
            if (this.config.defaultAnchors.indexOf(WindowPositionerAnchors.Bottom) >= 0) {
                this.bounds.bottomBound = getScrollbarWidth()
            } else if (this.config.defaultAnchors.indexOf(WindowPositionerAnchors.Center) >= 0) {
                this.bounds.topBound = Math.max(0, window.innerHeight / 2 - this.config.defaultHeight / 2)
            } else {
                this.bounds.topBound = 0
            }
        }
    }

    private setDefaultHorizontalBounds(): void {
        if (isNaN(this.bounds.leftBound) && isNaN(this.bounds.rightBound)) {
            if (this.config.defaultAnchors.indexOf(WindowPositionerAnchors.Right) >= 0) {
                this.bounds.rightBound = getScrollbarWidth()
            } else if (this.config.defaultAnchors.indexOf(WindowPositionerAnchors.Center) >= 0) {
                this.bounds.leftBound =  Math.max(0, getViewportWidth() / 2 - this.config.defaultWidth / 2)
            } else {
                this.bounds.leftBound = 0
            }
        }
    }

    public getBoundsFromStorage(): IBounds {
        let bounds: IBounds = {
            leftBound: NaN,
            topBound: NaN,
            bottomBound: NaN,
            rightBound: NaN,
            width: NaN,
            height: NaN,
        }

        if (isLocalStorageSupported()) {
            const data = window.localStorage.getItem(this.localStorageKey)
            if (data !== null) {
                bounds = JSON.parse(data)
                Object.keys(bounds).forEach(key => {
                    if (bounds[key as keyof IBounds] === null) {
                        bounds[key as keyof IBounds] = NaN
                    }
                })
                if (isNaN(bounds.width)) {
                    bounds.width = this.config.defaultWidth
                }
                if (isNaN(bounds.height)) {
                    bounds.height = this.config.defaultHeight
                }
            }
        }
        return bounds
    }

    save(): void {
        this.detect()
        if (isLocalStorageSupported()) {
            window.localStorage.setItem(this.localStorageKey, JSON.stringify(this.bounds))
        }
    }

    detect(): void {
        const parent = this.draggableCanvasWindow.element.parentElement
        if (parent === null) {
            error("no parent element")
            return
        }

        const leftBound: number = this.draggableCanvasWindow.element.offsetLeft,
            topBound = this.draggableCanvasWindow.element.offsetTop
        const bottomBound: number = parent.clientHeight - (this.draggableCanvasWindow.element.offsetHeight + topBound),
            rightBound = parent.clientWidth - (this.draggableCanvasWindow.element.offsetWidth + leftBound)
        this.bounds.height = this.draggableCanvasWindow.element.offsetHeight
        this.bounds.width = this.draggableCanvasWindow.element.offsetWidth
        if (leftBound <= rightBound) {
            this.bounds.leftBound = leftBound
            this.bounds.rightBound = NaN
        } else {
            this.bounds.leftBound = NaN
            this.bounds.rightBound = rightBound
        }
        if (topBound <= bottomBound) {
            this.bounds.topBound = topBound

            this.bounds.bottomBound = NaN
        } else {
            this.bounds.topBound = NaN
            this.bounds.bottomBound = bottomBound
        }
    }

    apply(): void {
        const parent = this.draggableCanvasWindow.element.parentElement
        if (parent === null) {
            error("no parent element")
            return
        }
        // if parent is not positioned yet skip this
        if (parent.clientHeight === 0) {
            return
        }
        const setResizeableBounds = () => {
            this.bounds.width = Math.min(this.bounds.width, parent.clientWidth)
            this.bounds.height = Math.min(this.bounds.height, parent.clientHeight)
            this.bounds.width = Math.max(this.bounds.width, this.config.minWidth)
            this.bounds.height = Math.max(this.bounds.height, this.config.minHeight)
        }

        if (this.config.autoSize !== undefined && this.config.autoSize) {
            this.draggableCanvasWindow.innerDiv.style.position = "relative"
            this.draggableCanvasWindow.element.style.width = "auto"
            this.draggableCanvasWindow.element.style.height = "auto"
            this.bounds.height = this.draggableCanvasWindow.element.offsetHeight
            this.bounds.width = this.draggableCanvasWindow.element.offsetWidth
        } else if (this.config.resizeable) {
            setResizeableBounds()
            this.draggableCanvasWindow.element.style.width = `${this.bounds.width}px`
            this.draggableCanvasWindow.element.style.height = `${this.bounds.height}px`
        } else {
            this.bounds.width = this.config.defaultWidth
            this.bounds.height = this.config.defaultHeight + this.draggableCanvasWindow.getTitleBarHeight()
            this.draggableCanvasWindow.element.style.width = `${this.bounds.width}px`
            this.draggableCanvasWindow.element.style.height = `${this.bounds.height}px`
        }

        if (this.sanityCheckBounds()) {
            info(`unexpected bounds: top:${this.bounds.topBound} bottom:${this.bounds.bottomBound} left:${this.bounds.leftBound} right: ${this.bounds.rightBound}`)
            return
        }
        this.setVerticalPosition(parent.clientHeight)
        this.setHorizontalPosition(parent.clientWidth)
    }

    // Returns true if either test fails
    private sanityCheckBounds(): boolean {
        const heightIssue = !isNaN(this.bounds.topBound) && !isNaN(this.bounds.bottomBound) ||
            isNaN(this.bounds.topBound) && isNaN(this.bounds.bottomBound)

        const widthIssue = !isNaN(this.bounds.leftBound) && !isNaN(this.bounds.rightBound) ||
            isNaN(this.bounds.leftBound) && isNaN(this.bounds.rightBound)

        return heightIssue || widthIssue
    }

    private setVerticalPosition(containerHeight: number): void {
        if (!isNaN(this.bounds.topBound)) {
            if (this.bounds.topBound + this.bounds.height > containerHeight || this.bounds.topBound < 0) {
                info("set topBound to container height - bounds height to make fit")
                this.bounds.topBound = Math.max(0, containerHeight - this.bounds.height)
            }
            this.draggableCanvasWindow.element.style.top = `${this.bounds.topBound}px`
        } else if (!isNaN(this.bounds.bottomBound)) {
            if (this.bounds.bottomBound + this.bounds.height > containerHeight || this.bounds.bottomBound < 0) {
                info("set bottomBound to container height - bounds height to make fit")
                this.bounds.bottomBound = Math.max(0, containerHeight - this.bounds.height)
            }
            this.draggableCanvasWindow.element.style.top = `${containerHeight - this.bounds.height - this.bounds.bottomBound}px`
        }
    }

    private setHorizontalPosition(containerWidth: number): void {
        if (!isNaN(this.bounds.leftBound)) {
            if (this.bounds.leftBound + this.bounds.width > containerWidth || this.bounds.leftBound < 0) {
                info("set leftBound to 0 to make fit")
                this.bounds.leftBound = 0
            }
            this.draggableCanvasWindow.element.style.left = `${this.bounds.leftBound}px`
        } else if (!isNaN(this.bounds.rightBound)) {
            if (this.bounds.rightBound + this.bounds.width > containerWidth || this.bounds.rightBound < 0) {
                info("set rightBound to 0 to make fit")
                this.bounds.rightBound = 0
            }
            this.draggableCanvasWindow.element.style.left = `${containerWidth - this.bounds.width - this.bounds.rightBound}px`
        }
    }
}
