import { isiOS } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { mobilePureChatChange } from "./mobilelib/mobilePureChat"
import type { IAutocompleteConfig } from "./autocompleteModal"
import type { EventRouter, ListenerGroup } from "./events"

interface IAutocompleteModalMobileMixinConfig {
    autocompleteConfig: IAutocompleteConfig,
    element: HTMLElement,
    list: HTMLElement,
    overlayClick: EventRouter<undefined>,
    listenerGroup: ListenerGroup,
    isVisible: () => boolean,
    hide: () => void,
    pickItem: (index: number, fromArrowPress: boolean) => void,
    hideOnSpace: boolean,
}

// TS doesn't actually have mixins, so really this is more of a utility class, but it's used like a mixin
export class AutocompleteModalMobileMixin {
    private scrolling = false

    constructor(private config: IAutocompleteModalMobileMixinConfig) {
        const autocompleteConfig = config.autocompleteConfig

        addEventListenerPoly("touchstart", config.element, () => {
            this.scrolling = false
            // Clear selection to avoid ios "cut, copy, paste, ..." menu
            autocompleteConfig.inputElement.setCaretToEndOfSelection()
        })

        config.overlayClick.listen(() => {
            autocompleteConfig.inputElement.setCaretToEndOfSelection()
        })

        config.listenerGroup.add(mobilePureChatChange.listen(() => {
            config.hide()
        }))

        config.list.onscroll = () => {
            this.scrolling = true
        }

        // Android doesn't give event.keyCode or event.key for soft keyboard events, watch for spaces manually
        if (!isiOS() && config.hideOnSpace) {
            addEventListenerPoly("beforeinput", autocompleteConfig.inputElement.element, (event: InputEvent) => {
                if (event.data === " ") {
                    config.hide()
                }
            })
        }
    }

    public customizeItem(item: HTMLDivElement, index: number): void {
        // The hitboxes of selection endpoints on ios will block clicks shorter than the disappearing animation, but touchend works
        item.ontouchend = (event) => {
            if (!this.scrolling) {
                this.config.pickItem(index, false)
                event.preventDefault()
            }
        }
    }

    public onShow(): void {
        this.config.element.style.width = `${this.config.autocompleteConfig.inputElement.element.offsetWidth}px`
    }
}
