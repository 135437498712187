import { followingEvent } from "../../../common/follow"
import { pageContext } from "../../interfaces/context"
import { UserIgnoreTopic, UserJoinedFanClubTopic, UserTipAlertTopic } from "../../pushservicelib/topics/user"
import type { DmWindow } from "./dmWindow"
import type { IFollowing } from "../../../common/follow";
import type { IIgnoreTopic, IPushPurchase } from "../../../common/messageInterfaces"

export function getDmWindowHeight(doc?: Document): number {
    // returns max height of dm window if one is open, otherwise returns height of collapsed window
    const dmWindows = (doc ? doc : document).querySelectorAll(".dmWindow")
    const dmWindowHeight = Array.from(dmWindows).reduce((prevVal, currVal) => {
        return Math.max(prevVal, (currVal as HTMLElement).offsetHeight)
    }, 0)

    return dmWindowHeight
}

export function bindDmWindowsPushEvents(dmWindowGetter: (username: string) => DmWindow | undefined): void {
    const userUid = pageContext.current.loggedInUser?.userUid
    if (userUid !== undefined) {
        new UserTipAlertTopic(userUid).onMessage.listen(tipAlert => {
            const myUsername = pageContext.current.loggedInUser?.username
            const otherUsername = tipAlert.fromUser.username !== myUsername ? tipAlert.fromUser.username : tipAlert.toUsername
            const dmWindow = dmWindowGetter(otherUsername ?? "")
            dmWindow?.onTip(tipAlert)
        })

        new UserIgnoreTopic(userUid).onMessage.listen((update: IIgnoreTopic) => {
            const dmWindow = dmWindowGetter(update.username)
            dmWindow?.onIgnoreUnignore(update.isIgnored)
        })

        followingEvent.listen((followingData: IFollowing) => {
            const dmWindow = dmWindowGetter(followingData.roomName)
            dmWindow?.followUnfollow(followingData.following)
        })

        new UserJoinedFanClubTopic(userUid).onMessage.listen((msg: IPushPurchase) => {
            const receivingWindow = dmWindowGetter(msg.fromUser.username)
            receivingWindow?.setUserInfo({ inFanclub: true })
        })
    }
}
