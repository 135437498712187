import { postCb } from "../../common/api"

export interface IBanIDQuery {
    createdBy: string
    roomUser: string,
    bannedUser: string,
    isSilence: boolean
}

export async function getBanID(query: IBanIDQuery): Promise<number> {
    const obj = {
        "room_user": query.roomUser,
        "created_by": query.createdBy,
        "banned_user": query.bannedUser,
        "is_silence": query.isSilence.toString(),
    }
    return postCb("api/ts/chat/ban-id/", obj).then((xhr) => {
        const data: { banid: number } = JSON.parse(xhr.responseText)
        return data["banid"]
    })
}

export function editRoomBan(ID: number, action: string, roomName: string, successFunc: () => void, failFunc: () => void): void {
    postCb("edit_room_ban/", { "banid": String(ID), "action": action, "room_username": roomName }).then((xhr) => {
        const responseText = xhr.responseText
        if (responseText === null) {
            failFunc()
            return
        }
        let response
        try {
            response = JSON.parse(responseText)
        }
        catch (a) {
            failFunc()
            return
        }
        if (response["error"] !== undefined) {
            failFunc()
            return
        }
        else {
            successFunc()
        }
    }).catch(() => {
        failFunc()
    })
}
