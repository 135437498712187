import { IPrivateMessage } from "../../../common/messageInterfaces"

// When initially loading a conversation, we have two sources of DMs: DM history from an endpoint, and new DMs from
// push service. We can wind up with duplicate messages if a new message is sent before backend gathers DM history but
// after we are listening for messages in push. DmDuplicateChecker allows us to check and filter out those duplicates
export class DmDuplicateChecker {
    private historyDms: Set<string> | undefined
    private newDms: Set<string> | undefined

    constructor() {
        this.historyDms= new Set<string>()
        this.newDms= new Set<string>()
    }

    public filterDuplicates(dms: IPrivateMessage[], fromHistory: boolean): IPrivateMessage[] {
        const dmSet = fromHistory ? this.historyDms : this.newDms
        const otherSet = fromHistory ? this.newDms : this.historyDms

        // undefined means that there's no more possibility of dupes
        if (dmSet === undefined || otherSet === undefined) {
            return dms
        }

        if (otherSet.size === 0) {
            dms.forEach(dm => dmSet.add(dm.messageID))
            return dms
        } else {
            const filteredDms = dms.filter(dm => !otherSet.has(dm.messageID))
            if (filteredDms.length > 0) {
                // If any of the input dms aren't dupes then all future dms won't be dupes either
                this.historyDms = undefined
                this.newDms = undefined
            }
            return filteredDms
        }
    }

    public isMessageDuplicate(dm: IPrivateMessage, fromHistory: boolean): boolean {
        return this.filterDuplicates([dm], fromHistory).length === 0
    }
}
