import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { EventRouter } from "./events"
import { addPageAction } from "./newrelic"

export const dismissibleSettingsLinkClass = "dismissibleSettingsLink"
export const goToSettingsTab = new EventRouter<void>("goToSettingsTab")

export function setupDismissibleSettingsLink(settingsLink: HTMLAnchorElement): void {
    addEventListenerPoly("click", settingsLink, (e) => {
        e.preventDefault()
        addPageAction("DismissibleSettingsLinkClicked")
        goToSettingsTab.fire()
    })
}
