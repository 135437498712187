import { isAudioContextFullySupported } from "@multimediallc/web-utils/modernizr"

export const enum Algorithm {
    Average = 1,
    Max,
}

export class VolumeMeter {
    context: AudioContext
    script: ScriptProcessorNode
    mic: MediaStreamAudioSourceNode
    algorithm = Algorithm.Max
    level = 0

    constructor(algorithm?: Algorithm) {
        if (algorithm !== undefined) {
            this.algorithm = algorithm
        }
    }

    private avgAlgorithm(input: Float32Array): void {
        const sum = input.reduce((accumulator, datum) => {
            accumulator += datum * datum
            return accumulator
        })
        this.level = Math.sqrt(sum / input.length)
    }

    private maxAlgorithm(input: Float32Array): void {
        let max = Number.MIN_SAFE_INTEGER
        input.map((value) => {
            if (value > max) {
                max = value
            }
            return value
        })
        if (input.length === 0 || max < 0) {
            max = 0
        } else if (max > 1) {
            max = 1
        } else {
            this.level = max
        }
    }

    connect(stream: MediaStream): void {
        this.level = 0.0
        if (isAudioContextFullySupported()) {
            if ("webkitAudioContext" in window) {
                this.context = new webkitAudioContext()
            } else {
                this.context = new AudioContext()
            }
            this.script = this.context.createScriptProcessor(2048, 1, 1)
            this.mic = this.context.createMediaStreamSource(stream)
            this.mic.connect(this.script)
            this.script.connect(this.context.destination)

            this.script.onaudioprocess = ((ev: AudioProcessingEvent) => {
                const input = ev.inputBuffer.getChannelData(0)
                if (this.algorithm === Algorithm.Average) {
                    this.avgAlgorithm(input)
                } else if (this.algorithm === Algorithm.Max) {
                    this.maxAlgorithm(input)
                } else {
                    error("invalid algorithm choice", this.algorithm)
                }
            })
        }
    }

    stop(): Promise<void> {
        if (isAudioContextFullySupported()) {
            this.mic.disconnect()
            this.script.disconnect()
            return this.context.close()
        } else {
            return Promise.resolve()
        }
    }
}
