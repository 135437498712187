import { dom } from "../../common/tsxrender/dom"

export function SpinnerIcon(props: { extraStyle?: CSSX.Properties }): HTMLDivElement {
    const style = {
        width: "30px",
        height: "31px",
        background: `url("${STATIC_URL_ROOT}images/loading_spinner.svg")`,
        backgroundSize: "cover",
        animationName: "spin",
        animationDuration: "2s",
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",
        ...props.extraStyle,
    }

    return <div style={style}/>
}

export function WrappedSpinnerIcon(props: { extraStyle?: CSSX.Properties, startHidden?: boolean, myRef?: (div: HTMLDivElement) => void }): HTMLDivElement {
    const wrapperStyle: CSSX.Properties = {
        display: props.startHidden === true ? "none" : "block",
        width: "100%",
        height: "100%",
        position: "absolute",
        ...props.extraStyle,
    }
    const spinnerExtraStyle: CSSX.Properties = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        margin: "-15px 0 0 -15px",
    }

    return <div style={wrapperStyle} ref={props.myRef}>
        <SpinnerIcon extraStyle={spinnerExtraStyle}/>
    </div>
}
