import { Debouncer, DebounceTypes } from "./debouncer"
import { ListenerGroup } from "./events"
import { userChatSettingsUpdate } from "./theatermodelib/userActionEvents"
import { dom } from "./tsxrender/dom"
import { videoModeHandler } from "./videoModeHandler"
import type { RoomNotice } from "./roomNotice"

interface IRoomNoticeDecluttererProps {
    appendNotice: (notice: RoomNotice, countsForUnread: boolean) => void
    appendSentinelDiv: (div: HTMLDivElement) => void
}

export class RoomNoticeDeclutterer {
    private latestSubjectChangeNotice: RoomNotice | undefined
    private heightRestrictedNotices: RoomNotice[] = []
    private readonly resizeSentinel: HTMLDivElement
    private readonly recalculateHeightRestrictionsDebouncer: Debouncer
    private readonly subjectChangeDebouncer: Debouncer
    private readonly noticeResizeObserver?: ResizeObserver
    private listenerGroup = new ListenerGroup()

    constructor(private props: IRoomNoticeDecluttererProps) {
        this.resizeSentinel = <div className="notice_resize_sentinel" style={{ height: "0", overflow: "hidden", opacity: "0" }}>
            {"my long string".repeat(100)}
        </div>
        props.appendSentinelDiv(this.resizeSentinel)

        this.recalculateHeightRestrictionsDebouncer = new Debouncer(this.recalculateNoticeHeightRestrictions, { debounceType: DebounceTypes.debounce, bounceLimitMS: 20 })
        if (window["ResizeObserver"] !== undefined) {
            this.noticeResizeObserver = new ResizeObserver(this.onNoticeResizeObserved)
            this.noticeResizeObserver.observe(this.resizeSentinel)
        }

        this.subjectChangeDebouncer = new Debouncer(() => {
            if (this.latestSubjectChangeNotice !== undefined) {
                this.appendNotice(this.latestSubjectChangeNotice)
                this.latestSubjectChangeNotice = undefined
            }
        }, { debounceType: DebounceTypes.trailThrottle, bounceLimitMS: 10000 })

        userChatSettingsUpdate.listen(this.recalculateNoticeHeightRestrictions).addTo(this.listenerGroup)
        videoModeHandler.changeVideoMode.listen(() => this.recalculateHeightRestrictionsDebouncer.callFunc()).addTo(this.listenerGroup)
    }

    public addRoomNotice(notice: RoomNotice): void {
        if (notice.isRoomSubjectNotice()) {
            this.addRoomSubjectNotice(notice)
        } else {
            this.appendNotice(notice)
        }
    }

    public onRoomCleanup(): void {
        this.heightRestrictedNotices = []
    }

    public dispose(): void {
        this.listenerGroup.removeAll()
        this.noticeResizeObserver?.disconnect()
        this.resizeSentinel.remove()
    }

    private addRoomSubjectNotice(notice: RoomNotice): void {
        this.latestSubjectChangeNotice = notice
        this.subjectChangeDebouncer.callFunc()
    }

    private appendNotice(notice: RoomNotice ): void {
        this.props.appendNotice(notice, notice.countsForUnread())
        if (notice.mayCollapse()) {
            this.heightRestrictedNotices.unshift(notice)
        }
    }

    private onNoticeResizeObserved = () => {
        if (!document.body.contains(this.resizeSentinel)) {
            return
        }
        this.recalculateHeightRestrictionsDebouncer.callFunc()
    }

    private recalculateNoticeHeightRestrictions = () => {
        // Chat history is limited and older messages/notices are removed when the limit is hit. Filter out notices that aged out of chat history
        this.heightRestrictedNotices = this.heightRestrictedNotices.filter(notice => document.body.contains(notice.element))
        for (const notice of this.heightRestrictedNotices) {
            notice.recalculateCollapse()
        }
    }
}
