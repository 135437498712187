import { EventRouter } from "../events"

export const privateSettingsClicked = new EventRouter<undefined>("privateSettingsClicked")

export const spokenLanguageEditLinkClicked = new EventRouter<void>("spokenLanguageEditLinkClicked")

export interface IBroadcastSettings {
    allowPrivateShows: boolean,
    hasRoomPassword: boolean,
    allowGroupShows?: boolean,
    numUsersRequiredForGroup?: number,
}

export interface IGenderSettings {
    gender: string,
    subgender?: string,
}

export const broadcastSettingsSave = new EventRouter<IBroadcastSettings>("broadcastSettingsSaved")

export const genderSettingsSave = new EventRouter<IGenderSettings>("genderSettingsSaved")

export const optInFlagDisplaySettingsSave = new EventRouter<boolean>("optInFlagDisplaySettingsSaved")
