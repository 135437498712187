export const enum DebounceTypes {
    /**
     * Behaviour for each type. For example we have a code:
     * ```JS
     * const example = new Debouncer(() => debug("call"), {bounceLimitMS: 100})
     * window.setTimeout(example.callFunc, 60)
     * window.setTimeout(example.callFunc, 120)
     * window.setTimeout(example.callFunc, 130)
     * window.setTimeout(example.callFunc, 500)
     * window.setTimeout(example.callFunc, 510)
     * ```
     * `throttle` will show debug at 60 and 500 ms
     * `debounce` will show debug at 230 and 610 ms
     * `trailThrottle` will show debug at 60/160 and 500/600 ms
     *
     * Use cases:
     * throttle: new calls ASAP - healthcheck, fetch updates
     * debounce: only last call matter - ajax autocomplete, onClick handler
     * trailThrottle: new calls and last one -  onResize handler, sessionStorage.setItem wrapper
     */
    throttle, // reduce frequency of calls to `bounceLimitMS`
    debounce, // ignore function calls if `bounceLimitMS` hasn't pass
    trailThrottle, // throttle with a single queue up call during the debounced grace period
}

interface IDebouncerConfig {
    // bounceLimitMS specifies how many milliseconds must pass between executions to this function
    bounceLimitMS: number,
    debounceType?: DebounceTypes,
}

export class Debouncer {
    // recentlyLetPassthrough helps us track if we have let the first passthrough
    private recentlyLetPassthrough = false
    private callImmediate = false
    private debounceTimeout: number

    constructor(private func: () => void, private config: IDebouncerConfig) {
    }

    callFunc(): void {
        if (this.config.debounceType === DebounceTypes.debounce) {
            clearTimeout(this.debounceTimeout)
            this.debounceTimeout = window.setTimeout(this.func, this.config.bounceLimitMS)
            return
        }

        const handleFunc = () => {
            if (this.callImmediate && this.config.debounceType === DebounceTypes.trailThrottle) {
                this.callImmediate = false
                this.func()
                window.setTimeout(handleFunc, this.config.bounceLimitMS)
            } else {
                this.recentlyLetPassthrough = false
            }
        }
        if (!this.recentlyLetPassthrough) {
            this.recentlyLetPassthrough = true
            this.func()
            window.setTimeout(handleFunc, this.config.bounceLimitMS)
        } else {
            this.callImmediate = true
        }
    }
}

export class StartEndDebouncer extends Debouncer {
    private isStart = true

    constructor(private fn: (isStart: boolean) => void) {
        super(() => {
            this.isStart = true
            fn(false)
        }, { bounceLimitMS: 300, debounceType: DebounceTypes.debounce })
    }

    callFunc(): void {
        if (this.isStart) {
            this.isStart = false
            this.fn(true)
        }
        super.callFunc()
    }
}
