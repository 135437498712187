/* ====== GENDER CATEGORIES ====== */
// NOTE this file is intentionally free of any external dependencies, please keep it thus!
// Exception: it's okay to import from /roomlist-prefetch package
import { GenderNameToSymbolMap } from "@multimediallc/gender-utils"

// These constants are declared in the roomlist-prefetch package for performance,
// and imported/exported here for their use throughout the typescripts project.
// For sake of explicitness, we're exporting each const/function by name instead of using an asterisk (*)
export {
    Gender,
    GenderNameToSymbolMap,
    Subgender,
    parseSimpleGender,
    GendersSymbolToNameMap,
    getCurrentGender,
} from "@multimediallc/gender-utils"

export { getVerboseGenderPath } from "@multimediallc/cb-roomlist-prefetch"

// For use with roomlist iterators which support either a gender category or `followed`
export function userCategoryToAbbrev(category: string): string {
    const key = GenderNameToSymbolMap.get(category)
    if (key !== undefined) {
        return key
    } else if (category === "followed") {
        return "o"
    } else {
        error(`Unknown user category ${category}`)
        return ""
    }
}
