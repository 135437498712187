import { EventRouter } from "../events"
import { ITouchInfo, PinchEvent, TouchDirection, TouchMoveEvent, TouchState } from "./touchUtil"
import { IScaleInfo, ISwipeInfo, IVideoHeightChangeInfo, SwipeUniqueState } from "./videoControlsInterfaces"
import { getViewportWidth } from "./viewportDimension"

const QUICK_SWIPE_TIME_THRESHOLD = 200 // Maximum time in milliseconds allotted to hit the distance threshold before deciding if an interaction is a quick swipe
const SWIPE_DISTANCE_PERCENTAGE_THRESHOLD = 1 / 3 // Percentage for calculating minimum distance relative to the viewport width needed to be traveled to be considered a full swipe
const QUICK_SWIPE_PERCENTAGE_THRESHOLD = 0.2 // Percentage for calculating minimum distance relative to the viewport width needed to be traveled to be considered a quick swipe
const PINCH_ZOOM_RATIO = 1.25 // Ratio used to determine scale factor during a pinch events
export const FULLSCREEN_PLAYER_SCALE_RATIO = 1.6 // Ratio used to determine scale factor during touch move events

export class VideoPlayerTouchControls {
    public readonly touchMoveEvent = new TouchMoveEvent(this.overlayElement)
    public readonly pinchEvent = new PinchEvent(this.overlayElement)
    public readonly scalePlayerEvent = new EventRouter<IScaleInfo>("scalePlayerEvent")
    public readonly scrollPlayerEvent = new EventRouter<number>("scrollPlayerEvent")
    public readonly swipePlayerEvent = new EventRouter<ISwipeInfo>("swipePlayerEvent")
    public readonly changeVideoHeightEvent = new EventRouter<IVideoHeightChangeInfo>("changeVideoHeightEvent")
    private horizontalSwipeInProgress = true
    private initialSwipeCheckDone: boolean
    private isSwipingEnabled = false // TODO: Replace with feature flag when we implement video swiping

    constructor(protected overlayElement: HTMLElement, protected playerElement: HTMLElement) {
        this.pinchEvent.listen(pinchInfo => {
            const dx = pinchInfo.previousCenterPoint.x - pinchInfo.centerPoint.x
            this.scalePlayerEvent.fire({
                factor: 1 + (pinchInfo.zoomPercentage * PINCH_ZOOM_RATIO),
                pointX: pinchInfo.centerPoint.x,
            })
            this.scrollPlayerEvent.fire(dx)
        })

        this.touchMoveEvent.listen((touchInfo) => {
            if (touchInfo.state === TouchState.Start) {
                this.initialSwipeCheckDone = false
                this.horizontalSwipeInProgress = false
                this.swipeCheck(touchInfo)
            }

            if (this.horizontalSwipeInProgress) {
                const swipeState = (touchInfo.state === TouchState.End && !this.isCompletedSwipe(touchInfo.totalDistance.x, touchInfo.elapsedTime)) ? SwipeUniqueState.Cancelled : touchInfo.state
                this.swipePlayerEvent.fire({
                    state: swipeState,
                    totalDistance: touchInfo.totalDistance.x,
                    elapsedTime: touchInfo.elapsedTime,
                })
            } else {
                const dx = touchInfo.distance.x
                this.handlePlayerResize(touchInfo)
                this.scrollPlayerEvent.fire(dx)
            }
        })
    }

    public isSwipeAllowed(totalHorizontalDistance: number): boolean {
        const scrolledToLeft = this.playerElement.scrollLeft <= 0
        const swipingRight = totalHorizontalDistance <= 0
        const scrolledToRight = Math.ceil(this.playerElement.scrollLeft) + this.playerElement.offsetWidth >= this.playerElement.scrollWidth
        const swipingLeft = totalHorizontalDistance >= 0

        return this.isSwipingEnabled && ((scrolledToLeft && swipingRight) || (scrolledToRight && swipingLeft))
    }

    private isCompletedSwipe(horizontalDistancedMoved: number, elapsedTime: number): boolean {
        const horizontalDistanceThresholdHit = (): boolean => {
            return (Math.abs(horizontalDistancedMoved) >= (getViewportWidth() * SWIPE_DISTANCE_PERCENTAGE_THRESHOLD))
        }

        const isHorizontalQuickSwipe = (elapsedTime: number): boolean => {
            return (elapsedTime <= QUICK_SWIPE_TIME_THRESHOLD) && (Math.abs(horizontalDistancedMoved) >= (getViewportWidth() * QUICK_SWIPE_PERCENTAGE_THRESHOLD))
        }

        return horizontalDistanceThresholdHit() || isHorizontalQuickSwipe(elapsedTime)
    }

    private handlePlayerResize(touchInfo: ITouchInfo): void {
        this.changeVideoHeightEvent.fire({
            startingPoint: touchInfo.startingPoint,
            distance: touchInfo.distance,
        })
    }

    private swipeCheck(touchInfo: ITouchInfo): void {
        if (!this.initialSwipeCheckDone) {
            this.horizontalSwipeInProgress = this.isSwipeAllowed(touchInfo.totalDistance.x) && touchInfo.direction === TouchDirection.Horizontal
            this.initialSwipeCheckDone = true
        }
    }
}
