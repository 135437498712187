import { addEventListenerPoly } from "../../../../../common/addEventListenerPolyfill"
import { addPageAction } from "../../../../../common/newrelic"
import { i18n } from "../../../../../common/translation"
import { pageContext } from "../../../../interfaces/context"
import {
    OfflineTipNotificationTopic,
    UpdateOfflineTipNotificationTopic,
    UserNewsSeenTopic,
} from "../../../../pushservicelib/topics/user"
import { BaseTab, TabId } from "../../../../ui/tabs"
import { Api, NotificationType } from "../../api"
import { Events } from "../../events"
import { MobileUpdatesTabContents, UpdatesTabContents } from "./updatesTabContents"
import type { IPushNewsUpdateItem, IUpdateOfflineTipItem, IUserUpdateItem } from "../../../../../common/messageInterfaces"
import type { Topic } from "../../../../pushservicelib/topics/base"
import type { BaseNewsSeenTopic } from "../../../../pushservicelib/topics/user";

export class UpdatesTab extends BaseTab {
    contents: UpdatesTabContents

    private numUnread = 0
    private isLoaded = false
    private isDropDownVisible = false
    private items: IUserUpdateItem[] = []
    private markAllAsSeenTimeout: number | undefined = undefined
    private updateTabOpenTime = 0
    private readonly isAnonymous: boolean
    public highestUnreadTs = 0

    protected readonly tabTitle = i18n.updatesText
    protected readonly recordLastSeenUrl: string = "notifications/record_last_seen/"
    protected readonly notificationTypes = [NotificationType.TWITTER_FEED, NotificationType.OFFLINE_TIP]
    protected readonly openPageActionName: string = "NewsfeedOpened"
    protected readonly closePageActionName: string = "NewsfeedClosed"

    constructor(isMobile: boolean) {
        super()

        if (isMobile) {
            this.contents = new MobileUpdatesTabContents()
        } else {
            this.contents = new UpdatesTabContents()
        }
        this.addChild(this.contents)

        Events.dropDownToggle.listen(isShowing => {
            this.isDropDownVisible = isShowing
            if (!this.isShowing()) {this.sendUpdatesClosedEvent()}
        })

        this.isAnonymous = pageContext.current.loggedInUser === undefined
        addEventListenerPoly("beforeunload", window, () => this.sendUpdatesClosedEvent())
        addEventListenerPoly("unload", window, () => this.sendUpdatesClosedEvent())
    }

    protected getNewsTopic(userUid: string | undefined): Topic<IPushNewsUpdateItem> | undefined  {
        return undefined
    }

    protected getNewsSeenTopic(userUid: string): BaseNewsSeenTopic {
        return new UserNewsSeenTopic(userUid)
    }

    protected getOfflineTipTopic(userUid: string | undefined): Topic<IPushNewsUpdateItem> | undefined  {
        if (userUid !== undefined) {
            return new OfflineTipNotificationTopic(userUid)
        }
        return undefined
    }

    protected getUpdateOfflineTipTopic(userUid: string | undefined): Topic<IUpdateOfflineTipItem> | undefined  {
        if (userUid !== undefined) {
            return new UpdateOfflineTipNotificationTopic(userUid)
        }
        return undefined
    }
    public hasUnread(): boolean {
        return this.numUnread > 0
    }

    private recordLastSeenNotification(timestamp: number, callback?: () => void): void {
        if (!this.isAnonymous) {
            Api.recordLastSeen(this.recordLastSeenUrl, timestamp, () => {
                if (callback !== undefined) {
                    callback()
                }
            })
        }
    }

    sendUpdatesClosedEvent(): void {
        if (this.updateTabOpenTime !== 0) {
            const now = (new Date()).getTime()
            const eventAttributes = { "duration_seconds": (now - this.updateTabOpenTime) / 1000 }
            this.updateTabOpenTime = 0
            addPageAction(this.closePageActionName, eventAttributes)
        }
    }

    getTabHandleContent(): Node[] {
        let message
        if (this.numUnread > 99) {
            message = `${this.tabTitle} (99+)`
        } else if (this.numUnread > 0) {
            message = `${this.tabTitle} (${this.numUnread})`
        } else {
            message = this.tabTitle
        }
        return [document.createTextNode(message)]
    }

    getTabId(): TabId {
        return TabId.UpdatesDefault
    }

    public showElement(): void {
        super.showElement()
        this.repositionChildren()
    }

    setAsCurrentTab(): void {
        super.setAsCurrentTab()
        addPageAction(this.openPageActionName, { "is_orange": this.hasUnread() })
        this.updateTabOpenTime = (new Date()).getTime()
        this.loadFeed()
    }

    hideElement(): void {
        super.hideElement()
        this.sendUpdatesClosedEvent()
    }

    private isShowing(): boolean {
        return this.isDropDownVisible && this.isCurrentTab()
    }

    private get unreadItems(): IUserUpdateItem[] {
        return this.items.filter(item => !item.seen)
    }

    loadFeed(): void {
        if (!this.isLoaded) {
            Api.getUserUpdates(this.notificationTypes, (items) => {
                this.items = items
                this.contents.setUpdateItems(items)
                const unreadItems = this.unreadItems
                this.numUnread = unreadItems.length
                this.highestUnreadTs = 0
                if (unreadItems.length > 0) {
                    this.highestUnreadTs = Math.max(...unreadItems.map(item => item.timestamp))
                }

                this.refreshTabs()
                this.isLoaded = true
                Events.tabItemsLoaded.fire()

                const userUid = pageContext.current.loggedInUser?.userUid
                if (userUid !== undefined) {
                    this.getNewsSeenTopic(userUid).onMessage.listen((newsItem) => {
                        debug(`news seen update`, newsItem)
                        // when a set of updates have been seen, a message is published with the last timestamp
                        // use it's timestamp as a new high-water mark for seen
                        this.markAsSeen(newsItem.timestamp)
                        this.numUnread = this.unreadItems.length
                        Events.updatesAreRead.fire()
                        this.refreshTabs()
                    })
                }

                this.getNewsTopic(userUid)?.onMessage.listen((newsItem) => {
                    debug(`news item`, newsItem)
                    // we only want to display items we haven't yet received
                    let highestReceivedTs = 0
                    if (this.items.length > 0) {
                        highestReceivedTs = Math.max(...this.items.map(item => item.timestamp))
                    }
                    if (highestReceivedTs < newsItem.timestamp) {
                        this.items.splice(0, 0, newsItem)
                        this.numUnread = this.unreadItems.length
                        this.contents.setUpdateItems(this.items)
                        if (!newsItem.seen) {
                            this.highestUnreadTs = newsItem.timestamp
                            Events.updatesAreUnread.fire()
                        }
                        this.refreshTabs()
                        this.scheduleMarkAllAsSeen()
                    }
                })
                this.getOfflineTipTopic(userUid)?.onMessage.listen((tipItem) => {
                    let highestReceivedTs = 0
                    if (this.items.length > 0) {
                        highestReceivedTs = Math.max(...this.items.map(item => item.timestamp))
                    }
                    if (highestReceivedTs < tipItem.timestamp) {
                        this.items.splice(0, 0, tipItem)
                        this.numUnread = this.unreadItems.length
                        this.contents.setUpdateItems(this.items)
                        if (!tipItem.seen) {
                            this.highestUnreadTs = tipItem.timestamp
                            Events.updatesAreUnread.fire()
                        }
                        this.refreshTabs()
                        this.scheduleMarkAllAsSeen()
                    }
                })

                this.getUpdateOfflineTipTopic(userUid)?.onMessage.listen((updateTipItem) => {
                    const fromUsername = updateTipItem.fromUsername
                    let highestReceivedTs = 0
                    if (this.items.length > 0) {
                        highestReceivedTs = Math.max(...this.items.map(item => item.timestamp))
                    }
                    if (highestReceivedTs < updateTipItem.timestamp) {
                        const items = this.items
                        for (const [i, item] of items.entries()) {
                            if (item.text.includes(fromUsername)) {
                                // 1. Delete the previous offline tip notification
                                items.splice(i, 1)
                                // 2. Append the newest tip notification to top
                                items.splice(0,0,updateTipItem)
                                break
                            }
                        }
                        this.contents.setUpdateItems(this.items)
                        this.refreshTabs()
                        this.scheduleMarkAllAsSeen()
                    }
                })
            })
        } else {
            this.contents.setUpdateItems(this.items)
            this.scheduleMarkAllAsSeen()
            Events.tabItemsLoaded.fire()
        }
    }

    private markAsSeen(lastSeenTimestamp: number): void {
        this.items.forEach(it => {
            if (it.timestamp <= lastSeenTimestamp) {
                it.seen = true
            }
        })
    }

    private scheduleMarkAllAsSeen(): void {
        if (this.numUnread > 0) {
            // if we call this multiple times in a row before the timeout
            // can be hit, let's push the timeout along
            if (this.markAllAsSeenTimeout !== undefined) {
                clearTimeout(this.markAllAsSeenTimeout)
            }
            if (this.isShowing()) {
                this.markAsSeen(this.items[0].timestamp)
                this.markAllAsSeenTimeout = window.setTimeout(() => {
                    this.numUnread = 0
                    this.highestUnreadTs = 0
                    this.recordLastSeenNotification(this.items[0].timestamp, () => {
                        Events.updatesAreRead.fire()
                    })
                }, 500)
            }
        } else {
            Events.updatesAreRead.fire()
        }
    }
}
