import { pageContext } from "../cb/interfaces/context"
import { roomCleanup, userViewedPm } from "./context"
import { ListenerGroup } from "./events"

export class PmNotifier {
    protected dontNotify = new Set<string>()
    protected listenerGroup = new ListenerGroup()

    constructor() {
        this.initListeners()
    }

    protected initListeners(): void {
        userViewedPm.listen((username) => {
            this.dontNotify.add(username)
        }).addTo(this.listenerGroup)

        roomCleanup.listen(() => {
            this.dontNotify.clear()
        }).addTo(this.listenerGroup)
    }

    public shouldNotify(username: string): boolean {
        return !this.dontNotify.has(username) && username !== pageContext.current.loggedInUser?.username
    }

    public preventNotifications(username: string): void {
        this.dontNotify.add(username)
    }

    public dispose(): void {
        this.listenerGroup.removeAll()
    }
}
