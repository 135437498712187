import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { postCb } from "./api"
import { Debouncer, DebounceTypes } from "./debouncer"
import { createUsernameAssist, updateUsernameAssist } from "./formvalidate/usernameAssist"
import { addPageAction } from "./newrelic"
import type { IUsernameAssist } from "./formvalidate/usernameAssist"

/// /////////////
// Form State Class
/// /////////////

//
// This object keeps track of a form's state, and can do useful things like
// let a user know if a form has unsaved changes, create checkpoints for the form.
//
// All targeted forms will trigger a confirmation if leaving the page
// without a submit if there are changes to the entered data on the form.
//
// A further note: Attempting to leave a form that has validation errors will trigger a prompt
// even if no changes were made
//
// All forms with state can be checkpointed, which can be useful
// for saving drafts, cleaning the dirty bit before or after an AJAX call, etc.
//

export class FormState {
    private checkpoints: string[] = []
    private submitting = false

    constructor(private form: HTMLFormElement) {
        const fV = getForm(form, false)
        if (!fV.hasValidationErrors()) {
            this.saveCheckpoint()
        }
        addEventListenerPoly("submit", form, () => {
            this.submitting = true
            return
        })
    }

    saveCheckpoint(): void {
        this.checkpoints.push(this.serializeForm())
    }

    isDirty(): boolean {
        if (this.checkpoints.length === 0) {
            return true
        }
        return (this.serializeForm() !== this.checkpoints[ this.checkpoints.length - 1 ])
    }

    isSubmit(): boolean {
        return this.submitting
    }

    warnOnClosingDirty(warnMsg: string): void {
        addEventListenerPoly("beforeunload", window, (ev) => {
            // If is dirty and we are not leaving via a submit, warn
            if (this.isDirty() && !this.isSubmit()) {
                ev.preventDefault()
                ev.returnValue = false
                return warnMsg
            }
            // Else, continue as normal
            return
        })
    }

    serializeForm(): string {
        return getForm(this.form, false).serialize()
    }
}

/// /////////////
// Form Validate Interfaces and functions
/// /////////////

export interface IField {
    name: string,
    errorName: string,
    elementType: string,
    isMultiSelect: boolean,
    isCheckbox: boolean,
    isHidden: boolean,
    isDisabled: boolean,
    grace: boolean, // False if the field indicated has errors shown, or if the field has been touched/changed
    isDateWidget: boolean,
    isRecaptcha: boolean,
    dateWidgetLabel: string,
    isRecaptchaSet: boolean,
    htmlElement: HTMLElement,
    init: () => void,
    getId: () => string,
    getName: () => string|null,
    getSubmitValue: () => string,
    change: () => void,
    hasError: () => boolean,
    showError: (error: string, disableGrace: boolean) => void,
    clearError: () => void,
    showSpinner: () => void,
    hideSpinner: () => void,
}

function getField(fieldElement: HTMLElement): IField {
    function isDateWidget(fieldElement: Element): boolean {
        const parts = String(fieldElement.getAttribute("id")).split("_")
        const last = parts[parts.length - 1]
        const dwFields: string[] = ["day", "month", "year"]
        let isDateWidget = false
        if (dwFields.indexOf(last) > -1) {
            parts.pop()
            const base = parts.join("_")
            isDateWidget = true
            for (const dwField of dwFields) {
                const id = `${base}_${dwField}`
                if (document.querySelector(`#${id}`) === null) {
                    isDateWidget = false
                }
            }
        }
        return isDateWidget
    }

    function getDateWidgetName(fieldElement: Element): string {
        const parts = String(fieldElement.getAttribute("id")).split("_")
        parts.shift()
        parts.pop()
        return parts.join("_")
    }

    function getRecaptchaName(fieldElement: Element): string {
        const parts = String(fieldElement.getAttribute("id")).split("_")
        parts.shift()
        return parts.join("_")
    }

    function setupRecaptchaValidator(field: IField, fieldElement: Element): void {
        const recapCallbackFn = String(fieldElement.getAttribute("data-callback"))
        if (recapCallbackFn !== "null") {
            // @ts-ignore added dynamically
            window[recapCallbackFn] = function(): void {
                field.isRecaptchaSet = true
                field.clearError()
            }
        }
    }

    const field: IField = {
        name: "",
        errorName: "",
        elementType: "",
        isMultiSelect: false,
        isCheckbox: false,
        isHidden: false,
        isDisabled: false,
        grace: true,
        isDateWidget: false,
        isRecaptcha: false,
        dateWidgetLabel: "",
        isRecaptchaSet: false,
        htmlElement: fieldElement,

        init: function(): void {
            field.elementType = String(fieldElement.tagName.toLowerCase())
            const fieldName = field.getName()
            field.name = fieldName !== null ? fieldName : ""
            field.errorName = field.name

            if (fieldElement.getAttribute("data-type") === "captcha") {
                field.isRecaptcha = true
                setupRecaptchaValidator(field, fieldElement)
            } else if (isDateWidget(fieldElement)) {
                field.isDateWidget = true
                field.errorName = getDateWidgetName(fieldElement)
            }

            const tagType = String(fieldElement.getAttribute("type"))
            if (tagType === "checkbox") {
                field.isCheckbox = true
            } else if (tagType === "hidden") {
                field.isHidden = true
            } else if (field.elementType === "select" && (fieldElement as HTMLSelectElement).type === "select-multiple") {
                field.isMultiSelect = true
            }
            field.isDisabled = fieldElement.getAttribute("disabled") !== null

            if (field.hasError()) {
                field.grace = false
            }
        },

        getName: function(): string|null {
            if (fieldElement.getAttribute("data-type") === "captcha") {
                return getRecaptchaName(fieldElement)
            }
            return fieldElement.getAttribute("name")
        },

        showSpinner: function(): void {
            if (field.isHidden || field.isRecaptcha) {
                return
            }
            const el = document.querySelector(`#${field.errorName}_spinner`)
            if (el !== null) {
                el.classList.remove("formvalidate_hidden")
            }
        },

        hideSpinner: function(): void {
            const el = document.querySelector(`#${field.errorName}_spinner`)
            if (el !== null) {
                el.classList.add("formvalidate_hidden")
            }
        },

        getId: function(): string {
            return String(fieldElement.getAttribute("id"))
        },

        getSubmitValue: function(): string {
            const tmp = fieldElement as HTMLInputElement
            if (field.isCheckbox) {
                if (tmp.checked) {
                    if (tmp.getAttribute("value") !== null) {
                        return (tmp.getAttribute("value") as string)
                    }
                    return "on"
                }
                return ""
            }
            return tmp.value
        },

        change: function(): void {
            field.grace = false
        },

        hasError: function(): boolean {
            if (field.isHidden) {
                return false
            }
            const el = document.querySelector<HTMLElement>(`.${field.errorName}_error`)
            return el !== null && el.style.display !== "none"
        },

        showError: function(error: string, disableGrace: boolean): void {
            if (field.isHidden) {
                return
            }
            if (disableGrace) {
                field.grace = false
            }
            if (!field.grace) {
                const elMsg = document.querySelector(`.${field.errorName}_error .error_msg`) as HTMLElement
                elMsg.appendChild(document.createTextNode(error))
                const el = document.querySelector(`.${field.errorName}_error`) as HTMLElement
                if (String(el.tagName.toLowerCase()) === "tr") {
                    el.style.display = "table-row"
                } else {
                    el.style.display = "block"
                }
            }
        },

        clearError: function(): void {
            if (field.isHidden) {
                return
            }
            const elMsg = document.querySelector(`.${field.errorName}_error .error_msg`) as HTMLElement
            while (elMsg.firstChild !== null) {
                elMsg.removeChild(elMsg.firstChild)
            }
            const el = document.querySelector(`.${field.errorName}_error`) as HTMLElement
            el.style.display = "none"
            const elLabel = document.querySelector(`.${field.errorName}_label`)
            if (elLabel !== null) {
                elLabel.classList.remove("formvalidate_error")
            }
        },
    }

    field.init()
    return field
}

export interface IForm {
    fields: IField[],
    formElement: HTMLFormElement,
    usernameAssist: IUsernameAssist,
    isValid: boolean,
    isSubmitting: boolean,
    isValidating: boolean,
    validateUrl: string,
    usernameAssistUrl?: string,
    fieldsIndex: Record<string, number>,
    lastAllData: Record<string, object>,
    queueValidation: boolean,
    addPreSubmitValidator: (handler: () => Promise<boolean>) => () => void,
    template: string,
    errored_inputs: string[],

    init: () => void,
    validate: (disableGrace: boolean) => void,
    serialize: () => string,
    addGlobalError: (error: string) => void,
    clearGlobalErrors: () => void,
    showGlobalErrors: () => void,
    hasValidationErrors: () => boolean,
}

export function getForm(formElement: HTMLFormElement, ajaxValidate = true): IForm {
    const fields: IField[] = []
    const usernameAssist: IUsernameAssist = {
        fields: undefined,
    }
    const preSubmitValidationHandlers: (() => Promise<boolean>)[] = []
    const elementsToDisable = formElement.getAttribute("data-formvalidate-disable-on-submit") === "1"
        ? [...formElement.querySelectorAll<HTMLButtonElement|HTMLInputElement>("button:not([type=button]),input[type=submit]")]
        : []
    const errored_inputs: string[] = []

    const emulateSubmit = () => {
        const submitButton = document.createElement("button") // emulate click to submit form and fire event
        submitButton.style.display = "none"
        formElement.appendChild(submitButton)
        window.setTimeout(() => {
            // without timeout click happens but doesn't submit form after recaptcha v2 is solved
            submitButton.click()
            formElement.removeChild(submitButton)
        }, 0)
    }

    const addSignupSubmitClickHandler = () => {
        if (form.template.indexOf("register") > -1) {
            for (const button of formElement.querySelectorAll<HTMLInputElement|HTMLButtonElement>("input[type=submit], button[type=submit]")) {
                addEventListenerPoly("click", button, (ev) => {
                    if (!form.isValid && form.errored_inputs.length > 0 && form.template.indexOf("register") > -1) {
                        addPageAction("SignupFailed", { "template": form.template, "inputs": form.errored_inputs.toString() })
                    }
                })
            }
        }
    }

    const form = {
        fields,
        formElement,
        usernameAssist,
        isValid: true,
        isSubmitting: false,
        isValidating: false,
        validateUrl: "",
        usernameAssistUrl: "",
        fieldsIndex: {} as Record<string, number>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        lastAllData: {} as Record<string, any>,
        queueValidation: false,
        isPreSubmitValidationComplete: false,
        preSubmitValidationHandlers,
        template: "",
        errored_inputs,

        init: function(): void {
            form.validateUrl = String(formElement.getAttribute("data-formvalidate")).substring(1)
            form.usernameAssistUrl = String(formElement.getAttribute("data-usernameAssist")).substring(1)
            form.template = formElement.getAttribute("data-template") ?? ""

            if (ajaxValidate) {
                addEventListenerPoly("submit", formElement, (ev) => {
                    if (form.isSubmitting) {
                        ev.preventDefault()
                        return
                    }
                    form.isSubmitting = true
                    if (form.isValid && !form.isValidating) {
                        form.preSubmitValidation(ev)  // eslint-disable-line @typescript-eslint/no-floating-promises
                    } else {
                        form.isPreSubmitValidationComplete = false
                        elementsToDisable.map(el => el.disabled = true)
                        form.validate(true).then(() => {
                            if (form.isValid) {
                                form.preSubmitValidation(ev)  // eslint-disable-line @typescript-eslint/no-floating-promises
                            } else {
                                form.isSubmitting = false
                                elementsToDisable.map(el => el.disabled = false)
                            }
                        }).catch(() => {
                            form.isSubmitting = false
                            elementsToDisable.map(el => el.disabled = false)
                        })
                        ev.preventDefault()
                    }
                })
                const formEagerness = Boolean(formElement.getAttribute("data-formvalidate-eagerness"))
                    ? Number(formElement.getAttribute("data-formvalidate-eagerness"))
                    : 2
                type TElem = HTMLInputElement|HTMLSelectElement

                let debouncer: Debouncer | undefined
                if (formElement.getAttribute("data-formvalidate-debounce") === "1") {
                    debouncer = new Debouncer(() => {
                        form.validate()  // eslint-disable-line @typescript-eslint/no-floating-promises
                    }, { bounceLimitMS: 1000, debounceType: DebounceTypes.trailThrottle })
                }

                for (const el of formElement.querySelectorAll<TElem>("input[type=text], input[type=checkbox], input[type=password], input[type=email], select")) {
                    const eagerness = Boolean(el.getAttribute("data-formvalidate-eagerness"))
                        ? Number(el.getAttribute("data-formvalidate-eagerness"))
                        : formEagerness

                    if (eagerness >= 1) {
                        addEventListenerPoly("change", el, (ev) => {
                            const tmp = ev.target as TElem
                            form.fields[form.fieldsIndex[String(tmp.getAttribute("id"))]].change()
                            form.validate()  // eslint-disable-line @typescript-eslint/no-floating-promises
                        })
                    }
                    if (eagerness >= 2) {
                        addEventListenerPoly("input", el, (ev) => {
                            const tmp = ev.target as TElem
                            form.fields[form.fieldsIndex[String(tmp.getAttribute("id"))]].change()
                            if (debouncer !== undefined) {
                                debouncer.callFunc()
                            } else {
                                form.validate()  // eslint-disable-line @typescript-eslint/no-floating-promises
                            }
                        })
                    }
                }

                addSignupSubmitClickHandler()
            }

            let i = 0
            for (const fieldElement of formElement.querySelectorAll<HTMLElement>("input[type=text], input[type=checkbox], input[type=hidden], input[type=password], input[type=email], select, .g-recaptcha")) {
                const field = getField(fieldElement)
                form.fields.push(field)
                form.fieldsIndex[field.getId()] = i
                i += 1

                // initialize form.lastAllData with initial empty field values
                form.lastAllData[field.name] = field.getSubmitValue()
            }

            createUsernameAssist(form)
        },

        serialize: function(): string {
            return form.fields
                .map((field: IField) => {
                    if (field.name === "" || field.isDisabled) {
                        return undefined
                    } else if (field.isMultiSelect) {
                        return Array
                            .from((field.htmlElement as HTMLSelectElement).options)
                            .filter(opt => opt.selected)
                            .map(opt => `${encodeURIComponent(field.name)}=${encodeURIComponent(opt.value)}`)
                            .join("&")
                    } else if (
                        (!field.isCheckbox && field.elementType !== "radio")
                        || (field.htmlElement as HTMLInputElement).checked
                    ) {
                        const submitValue = field.getSubmitValue()
                        return `${encodeURIComponent(field.name)}=${encodeURIComponent(submitValue)}`
                    } else {
                        return undefined
                    }
                })
            .filter(res => res !== undefined)
            .join("&")
            .replace(/%20/g, "+")
        },

        /**
         * @param {Boolean} disableGrace if true, then all fields will be validated regardless of the field's grace status
         * @returns {Promise<void>}
         */
        validate: function(disableGrace = false): Promise<void> { // eslint-disable-line complexity
            // if we are already validating, and disableGrace is false, bail out, but ask for revalidation after the current one is done
            if (form.isValidating && !disableGrace) {
                form.queueValidation = true
                return Promise.resolve()
            }
            form.isValidating = true
            const postData: Record<string, string> = {}     // what we send to the server
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const allData: Record<string, any> = {}      // used to compare what is in the form this time with what was in the form last time
            let doValidation = false
            let usernameFieldChanged = false
            for (const field of form.fields) {
                allData[field.name] = field.getSubmitValue()
                if (!field.isCheckbox || field.getSubmitValue() !== "") {
                    postData[field.name] = field.getSubmitValue()
                }
                if (allData[field.name] !== form.lastAllData[field.name] || disableGrace) {
                    if (field.name === "username") {
                        usernameFieldChanged = true
                    }
                    field.showSpinner()
                    doValidation = true
                }
            }
            if (!doValidation) {
                form.isValidating = false
                if (form.queueValidation) {
                    form.queueValidation = false
                    form.validate()  // eslint-disable-line @typescript-eslint/no-floating-promises
                }
                return Promise.resolve()
            }
            form.lastAllData = Object.assign({}, allData)
            return postCb(form.validateUrl, postData).then((response) => { // eslint-disable-line complexity
                const data = JSON.parse(response.responseText)
                form.isValid = data["valid"]
                form.errored_inputs = []
                for (const field of form.fields) {
                    if (field.isRecaptcha && !field.isRecaptchaSet) {
                        field.clearError()
                        field.showError("Captcha has to be completed", disableGrace)
                    } else if (data["errors"][field.errorName] !== undefined) {
                        field.clearError()
                        field.showError(data["errors"][field.errorName], disableGrace)
                        if (!form.errored_inputs.includes(field.errorName)) {
                            form.errored_inputs.push(field.errorName)
                        }
                        if (field.name === "username") {
                            const usernameIsEmpty = allData[field.name].trim() === ""
                            if (usernameIsEmpty) {
                                // hide username assist
                                updateUsernameAssist(form.usernameAssist.fields, false, form.usernameAssistUrl, allData[field.name])
                            }
                            else if (usernameFieldChanged && field.grace === false) {
                                // update username assist
                                updateUsernameAssist(form.usernameAssist.fields, true, form.usernameAssistUrl, allData[field.name])
                            }
                        }
                    } else {
                        field.clearError()
                        if (field.name === "username") {
                            // hide username assist
                            updateUsernameAssist(form.usernameAssist.fields, false, form.usernameAssistUrl, allData[field.name])
                        }
                    }
                    field.hideSpinner()
                }

                form.clearGlobalErrors()
                if (data["errors"]["__all__"] !== undefined) {
                    for (const error of data["errors"]["__all__"]) {
                        form.addGlobalError(error)
                    }
                    form.showGlobalErrors()
                }

                form.isValidating = false
                if (form.queueValidation) {
                    form.queueValidation = false
                    form.validate()  // eslint-disable-line @typescript-eslint/no-floating-promises
                }
            }).catch((err) => {
                info(`error: ${err}`)
                form.isValidating = false
                if (form.queueValidation) {
                    form.queueValidation = false
                    form.validate()  // eslint-disable-line @typescript-eslint/no-floating-promises
                } else {
                    throw err
                }
            })
        },

        preSubmitValidation(ev: Event): Promise<void> {
            elementsToDisable.map(el => el.disabled = true)
            if (form.isPreSubmitValidationComplete || form.preSubmitValidationHandlers.length === 0) {
                // let submit to backend, keep `isSubmitting` true to prevent all future submits
                window.setTimeout(() => {
                    // submit buttons should always have the grey effect
                    elementsToDisable.map(el => el.disabled = false)
                }, 700)
                return Promise.resolve()
            }
            ev.preventDefault()
            return Promise.all(form.preSubmitValidationHandlers.map(cb => cb()))
                .then(validationResults => {
                    form.isSubmitting = false
                    window.setTimeout(() => {
                        // prevent button flickering
                        elementsToDisable.map(el => el.disabled = false)
                    }, 10)
                    const allValidatorsSucceed = validationResults.reduce((prev: boolean, current: boolean) => {
                        return prev && current
                    }, true)
                    if (allValidatorsSucceed) {
                        form.isPreSubmitValidationComplete = true
                        emulateSubmit()
                    }
                }).catch(err => {
                    error(`Can't complete preSubmitValidation in form ${formElement.action}`, err)
                    form.isSubmitting = false
                    elementsToDisable.map(el => el.disabled = false)
                    throw err
                })
        },

        addGlobalError: function(error: string): void {
            const globalErrorUl = document.querySelector(`#error_notice > .errorlist`) as HTMLUListElement
            const newLi = document.createElement("li")
            newLi.appendChild(document.createTextNode(error))
            globalErrorUl.appendChild(newLi)
        },

        clearGlobalErrors: function(): void {
            const globalError = document.querySelector(`#error_notice`)
            if (globalError !== null) {
                globalError.classList.add("formvalidate_hidden")
                const globalErrorUl = document.querySelector(`#error_notice > .errorlist`)
                if (globalErrorUl !== null) {
                    while (globalErrorUl.firstChild !== null) {
                        globalErrorUl.removeChild(globalErrorUl.firstChild)
                    }
                }
            }
        },

        showGlobalErrors: function(): void {
            const globalError = document.querySelector(`#error_notice`)
            if (globalError !== null) {
                globalError.classList.remove("formvalidate_hidden")
            }
        },

        hasValidationErrors: function(): boolean {
            let hasFieldValidationErrors = false
            for (const field of form.fields) {
                hasFieldValidationErrors = hasFieldValidationErrors || field.hasError()
            }

            const hasFormValidationErrors = Array.from(formElement.getElementsByClassName("errorlist"))
                // eslint-disable-next-line @multimediallc/no-inner-html
                .filter( el => (el.innerHTML.trim() !== "") )
                .length
                !== 0

            return hasFieldValidationErrors || hasFormValidationErrors
        },

        addPreSubmitValidator(handler: () => Promise<boolean>): () => void {
            form.preSubmitValidationHandlers.push(handler)
            let validatorRemoved = false
            return () => {
                if (validatorRemoved) {
                    return
                }
                form.preSubmitValidationHandlers.splice(
                    form.preSubmitValidationHandlers.indexOf(handler), 1,
                )
                validatorRemoved = true
            }
        },
    }
    form.init()
    return form
}
