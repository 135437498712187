import { BasePlayer } from "../player/basePlayer"
import { MobileGenderedMoreRoomsList } from "./genderedMoreRoomsList"
import { MenuComponent } from "./menuComponent"
import { loadRoomRequest } from "./userActionEvents"
import { getViewportHeight } from "./viewportDimension"

const roomMenuWidth = 270
const genderSelectionScrollThreshold = 120

export class MoreRooms extends MenuComponent {
    private genderSelection: HTMLDivElement
    private roomList: MobileGenderedMoreRoomsList
    private lastTopPanelHeight: number
    private lastScrollTop: number
    private lastPlayerHeight: number
    private scrollTopReference = 0  // For lastScrollTop to reference and determine scroll distance

    constructor(private player: BasePlayer) {
        super(roomMenuWidth, undefined)

        this.roomList = new MobileGenderedMoreRoomsList({ disableRemovalOnScroll: true })

        this.genderSelection = document.createElement("div")
        this.genderSelection.className = "genderSelectionDiv"
        this.roomList.getScrolledElement().appendChild(this.genderSelection)

        for (const gender of ["Female", "Male", "Couple", "Trans"]) {
            this.genderSelection.appendChild(this.roomList.buildGenderCheckbox(gender))
        }

        this.addChild(this.roomList)

        this.roomList.getScrolledElement().onscroll = () => {
            this.roomList.handleScroll()
            this.lastScrollTop = this.roomList.getScrolledElement().scrollTop
            this.showHideGenderSelection()
        }

        loadRoomRequest.listen(() => {
            this.lastScrollTop = this.roomList.getScrolledElement().scrollTop
        })
    }

    public show(): void {
        this.roomList.listVisible()
    }

    public hide(): void {
        this.roomList.listHidden()
    }

    protected repositionChildren(): void {
        if (this.lastScrollTop === this.roomList.getScrolledElement().scrollTop) {
            this.roomList.getScrolledElement().scrollTop = this.roomList.getScrolledElement().scrollTop - this.lastTopPanelHeight + this.roomList.getScrolledElement().offsetTop
        }
        if (this.lastPlayerHeight < this.player.element.offsetHeight) {
            this.roomList.getScrolledElement().scrollTop += this.player.element.offsetHeight - this.lastPlayerHeight
        } else if (this.lastPlayerHeight > this.player.element.offsetHeight) {
            this.roomList.getScrolledElement().scrollTop -= this.lastPlayerHeight - this.player.element.offsetHeight
        }
        this.lastPlayerHeight = this.player.element.offsetHeight
        this.lastTopPanelHeight = this.roomList.getScrolledElement().offsetTop

        let tabRoomHeaderHeight = 0
        const tabRoomHeader = document.getElementById("tab-room-header")
        if (tabRoomHeader !== null) {
            tabRoomHeaderHeight = tabRoomHeader.offsetHeight
        }

        const staticHeader = document.getElementById("static-header")
        const staticHeaderHeight = (staticHeader !== null)? staticHeader.offsetHeight : 0

        this.roomList.element.style.height = `${getViewportHeight() - this.player.element.offsetHeight - tabRoomHeaderHeight - staticHeaderHeight}px`
    }

    private showHideGenderSelection(): void {
        if (this.lastScrollTop <= genderSelectionScrollThreshold) {
            // Detect if at the top of list -> always show gender selection
            this.showGenderSelection()
        } else if (this.lastScrollTop - this.scrollTopReference >= genderSelectionScrollThreshold) {
            // Detect scrolling DOWN past threshold distance -> hide gender selection
            this.scrollTopReference = this.lastScrollTop
            this.hideGenderSelection()
        } else if (this.scrollTopReference - this.lastScrollTop >= genderSelectionScrollThreshold) {
            // Detect scrolling UP past threshold distance -> show gender selection
            this.scrollTopReference = this.lastScrollTop
            this.showGenderSelection()
        }
    }

    private showGenderSelection(): void {
        this.genderSelection.classList.remove("collapsed")
    }

    private hideGenderSelection(): void {
        this.genderSelection.classList.add("collapsed")
    }
}
