import { DropDownComponentBase, ToggleEvent } from "./dropDownComponentBase"

export class DropDownComponent extends DropDownComponentBase {
    constructor(public toggleElement?: HTMLElement, keepOpenOnClickInside = true, props: object = {}) {
        super(props ?? {}, toggleElement, keepOpenOnClickInside)
    }
}

export class PushmenuDropDownComponent extends DropDownComponent {
    constructor(toggleElement?: HTMLElement) {
        super(toggleElement)
    }

    protected initUI(): void {
        super.initUI()
        this.element.style.display = "block"
    }

    showElement(): boolean {
        const parent = this.element.parentElement
        if (parent === null) {
            return false
        }

        parent.classList.toggle("pushmenu-animate", true)
        this.toggleEvent.fire(new ToggleEvent(true))
        return true
    }

    hideElement(): boolean {
        const parent = this.element.parentElement

        if (parent === null) {
            return false
        }

        parent.classList.toggle("pushmenu-animate", false)
        this.toggleEvent.fire(new ToggleEvent(false))
        return true
    }

    isShown(): boolean {
        const parent = this.element.parentElement

        if (parent === null) {
            return false
        }
        return parent.classList.contains("pushmenu-animate")
    }
}
