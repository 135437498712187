export class ParsedTitle {
    stringParts: string[] = []
    hashtagParts: string[] = []

    constructor(title: string) {
        const hashMatches = title.match(/#[a-zA-Z0-9\-_]{2,50}/g)
        if (hashMatches !== null) {
            for (const match of hashMatches) {
                this.hashtagParts.push(match.substr(1))
            }
        }
        this.stringParts = title.split(/#[a-zA-Z0-9\-_]{2,50}/g)
    }
}
