/**
 * Find the index that `value` can be inserted into `array` to maintain sorted order, assuming `array` is already sorted
 * according to `compare`. If an equal value is already present, the sorted index will be after the existing value.
 * sortedIndex(["a", "c"], "b", (a, b) => a.localeCompare(b)) === 1
 * sortedIndex(["a", "b", "c"], "b", (a, b) => a.localeCompare(b)) === 2
 * @param array Array to be searched for the sorted index
 * @param value Value for which we want the sorted index in `array`
 * @param compare Func for comparing `value` with array members. Return value should be -1|0|1, a la string.localeCompare
 * @return index Index where `value` can be inserted in `array` while maintaining sorted order
 */

export function findSortedInsert<T>(array: T[], value: T, compare: (a: T, b: T) => number): number {
    let low = 0
    let high = array.length

    while (low < high) {
        const mid = Math.floor((low + high) / 2)
        if (compare(value, array[mid]) >= 0) {
            low = mid + 1
        } else {
            high = mid
        }
    }
    return low
}

/**
 * Humanizes viewership number by simplifying it to have a max of 3 digits with a unit letter
 * @param num_views Viewership number of a room
 */
export function simplifyViewNumber(num_views: number): string {
    if (num_views < 1000) {
        return `${num_views}`
    }

    const suffixes = ["", "K", "M", "B", "T"]
    let suffixIndex: number = Math.floor((`${num_views}`).length / 3)
    let shortenedNum: number = parseFloat((num_views / Math.pow(1000, suffixIndex)).toPrecision(3))
    if (shortenedNum < 1) {
        shortenedNum = shortenedNum * 1000
        suffixIndex = suffixIndex - 1
    }
    return `${shortenedNum}${suffixes[suffixIndex]}`
}
