import { deleteCb, getCb, postCb } from "../../common/api"
import { BoundListener } from "../../common/events"
import { IIgnoreTopic } from "../../common/messageInterfaces"
import { addPageAction } from "../../common/newrelic"
import { pageContext } from "../interfaces/context"
import { UserIgnoreTopic } from "../pushservicelib/topics/user"

export const IGNORE_USER_LIMIT = 2000  // Corresponds to settings.REDIS_IGNORE_USER_LIMIT in backend

let ignoredSet = new Set<string>()

export function addIgnoreUser(username: string): Promise<boolean> {
    return postCb("api/ts/chat/ignored-users/", { "username": username })
        .then((xhr) => {
            setList(xhr.responseText)
            addPageAction("IgnoreUser", {
                "username": pageContext.current.loggedInUser?.username,
                "to_user": username,
                "list_size": getIgnoredSet().size,
            })
            return true
        })
        .catch((err) => {
            error("Error posting ignore user", err)
            return false
        })
}

export function removeIgnoreUser(username: string): Promise<boolean> {
    return deleteCb("api/ts/chat/ignored-users/", { "username": username })
        .then(() => {
            ignoredSet.delete(username)
            addPageAction("UnignoreUser", {
                "username": pageContext.current.loggedInUser?.username,
                "to_user": username,
                "list_size": getIgnoredSet().size,
            })
            return true
        })
        .catch((err) => {
            error("Error posting unignore user", err)
            return false
        })
}

function setList(rawJson: string): void {
    try {
        ignoredSet = new Set(<string[]>JSON.parse(rawJson)["users"])
    } catch (e) {
        error("Cannot parse ignore list", { "reason": e.toString() })
        // do nothing
    }
}

let ignoreTopicListener: BoundListener<IIgnoreTopic> | undefined
export function loadIgnoreList(): void {
    getCb(`api/ts/chat/ignored-users/`)
        .then((res) => {
            setList(res.responseText)
        })
        .catch((err) => {
            error("Network error retrieving ignore list", err)
        })

    if (ignoreTopicListener === undefined) {
        const userUid = pageContext.current.loggedInUser?.userUid
        if (userUid !== undefined) {
            ignoreTopicListener = new UserIgnoreTopic(userUid).onMessage.listen((update: IIgnoreTopic) => {
                if (update.isIgnored) {
                    addToIgnoreSet(update.username)
                } else {
                    removeFromIgnoreSet(update.username)
                }
            })
        }
    }
}

// Will generally want to call with reload=true whenever displaying the list in order to catch username changes
export function getIgnoredSet(reload=false): Set<string> {
    if (reload) {
        loadIgnoreList()
    }
    return ignoredSet
}

export function isIgnored(username: string): boolean {
    const ignoredUsers = getIgnoredSet()
    if (ignoredUsers === undefined) {
        error("ignoreSet should not be undefined")
        return false
    }
    return ignoredUsers.has(username)
}

// ignore.test.ts helper for testing ChatConnection.isIgnored
export function addToIgnoreSet(username: string): void {
    ignoredSet.add(username)
}

// ignore.test.ts helper for testing ChatConnection.isIgnored
export function removeFromIgnoreSet(username: string): void {
    ignoredSet.delete(username)
}
