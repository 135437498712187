import { populateMap, PushServiceContext } from "../baseClient"
import { PUSHER_CLIENT_NAME } from "./index"
import type { IPushContextSettings } from "../baseClient"
import type { ArgJSONMap } from "@multimediallc/web-utils"

export interface IPusherContextSettings extends IPushContextSettings {
    // backend, flags
    key: string,
    cluster: string,
    host?: string,
}

export class PusherContext extends PushServiceContext<IPusherContextSettings> {
    constructor(context: ArgJSONMap) {
        super(context)

        context.logUnusedDebugging("PusherContext")
    }

    public parseSettings(context: ArgJSONMap): IPusherContextSettings {
        return PusherContext.parseSettings(context)
    }

    public static parseSettings(context: ArgJSONMap): IPusherContextSettings {
        const settings: IPusherContextSettings = {
            backend: PUSHER_CLIENT_NAME,
            flags: new Map<string, boolean>(),
            key: context.getString("key"),
            cluster: context.getString("cluster"),
            host: context.getStringOrUndefined("host"),
            verifyEnabled: false,
        }
        populateMap<boolean>(settings.flags, context.getObjectOrUndefined("flags"))
        return settings
    }

    public isValid(): boolean {
        return true
    }
}
