import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "../../common/api"
import { EventRouter } from "../../common/events"
import { i18n } from "../../common/translation"
import type { IBioPhotoset } from "../../common/bioContent"

export const photoSetDetailRequest = new EventRouter<{
    request: IPhotosetDetailRequest,
    pushState: boolean,
}|undefined>("photoSetDetail")

interface IPhotosetDetailRequest {
    photoset: IPhotoVideoTabPhotoset
    context: IPhotoVideoTabContext
    autoplay: boolean
    scroll: boolean
}

export interface IPhotoVideoTabContext {
    room: string
    isAnonymous: boolean
    isBroadcaster: boolean
    isStaff: boolean
    hasFanClub: boolean
    isFanClubMember: boolean
}

export interface IPhotoVideoTabPhotosetsContext extends IPhotoVideoTabContext {
    photosets: IPhotoVideoTabPhotoset[]
}

export interface IPhotoVideoTabPhotoset extends IBioPhotoset {
    id: number
    name: string
    coverUrl: string
    tokens: number
    isVideo: boolean
    userCanAccess: boolean
    purchaseCount: number
    userHasPurchased: boolean
    pendingApproval: boolean
    failedApproval: boolean
    rejectionNotes: string
    fanClubOnly: boolean
    fanClubUnlock: boolean
    labelText: string
    labelColor: string
    description: string
    numPhotos: number
    created: string
    videoHasSound: boolean
    legacyVideo: boolean
    videoDuration: number
    videoId?: number
    videoIsReady: boolean
    videoErrorProcessing: boolean
    visibility: string
}

export function getPhotoURLsPromise(id: number): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
        getCb(`photo_videos/api/photoset_links/${id}/`).then(xhr => {
            const response = JSON.parse(xhr.responseText)
            if (response["error"]) { /* eslint-disable-line @typescript-eslint/strict-boolean-expressions */
                reject(response["error"])
            }
            resolve(response["urls"])
        }).catch(e => {
            reject(e)
        })
    })
}

export function getVideoURLPromise(id: number): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        getCb(`photo_videos/api/photoset_links/${id}/`).then(xhr => {
            const response = JSON.parse(xhr.responseText)
            if (response["error"]) { /* eslint-disable-line @typescript-eslint/strict-boolean-expressions */
                reject(response["error"])
            }
            resolve(response["urls"][0])
        }).catch(e => {
            reject(e)
        })
    })
}

export function getPhotoSetActionLinks(id: number): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        getCb(`photo_videos/api/photoset_action_links/${id}/`).then(xhr => {
            resolve(xhr.responseText)
        }).catch(e => {
            reject(e)
        })
    })
}

export function getPhotoVideoContextPromise(username: string): Promise<IPhotoVideoTabPhotosetsContext> {
    return new Promise<IPhotoVideoTabPhotosetsContext>((resolve, reject) => {
        getCb(`photo_videos/api/pvcontext/${username}/`).then(xhr => {
            resolve(parsePVTabContextResponse(xhr.responseText))
        }).catch(e => {
            reject(e)
        })
    })
}

export function parsePVTabContextResponse(str: string): IPhotoVideoTabPhotosetsContext {
    const parser = new ArgJSONMap(str)
    return {
        room: parser.getString("room"),
        isAnonymous: parser.getBoolean("is_anonymous"),
        isBroadcaster: parser.getBoolean("is_broadcaster"),
        isStaff: parser.getBoolean("is_staff"),
        hasFanClub: parser.getBoolean("has_fan_club"),
        isFanClubMember: parser.getBoolean("is_fan_club_member"),
        photosets: parsePVTabPhotosets(parser.getObjectString("photo_sets")),
    }
}

function parsePVTabPhotosets(photosets: string): IPhotoVideoTabPhotoset[] {
    const r: IPhotoVideoTabPhotoset[] = []
    for (const obj of JSON.parse(photosets)) {
        const parser = new ArgJSONMap(JSON.stringify(obj))
        r.push({
            id: parser.getNumber("id"),
            name: parser.getString("name"),
            coverUrl: parser.getString("cover_url"),
            tokens: parser.getNumber("tokens"),
            isVideo: parser.getBoolean("is_video"),
            userCanAccess: parser.getBoolean("user_can_access"),
            purchaseCount: parser.getNumber("purchase_count"),
            userHasPurchased: parser.getBoolean("user_has_purchased"),
            pendingApproval: parser.getBoolean("pending_approval", false, false),
            failedApproval: parser.getBoolean("failed_approval", false, false),
            rejectionNotes: parser.getString("rejection_notes", false),
            fanClubOnly: parser.getBoolean("fan_club_only"),
            fanClubUnlock: parser.getBoolean("fan_club_unlock"),
            labelText: parser.getString("label_text"),
            labelColor: parser.getString("label_color"),
            description: parser.getString("description"),
            numPhotos: parser.getNumber("num_photos"),
            created: parser.getString("created"),
            videoHasSound: parser.getBoolean("video_has_sound"),
            legacyVideo: parser.getBoolean("legacy_video"),
            videoDuration: parser.getNumber("video_duration"),
            videoId: parser.getNumberOrUndefined("video_id", false),
            videoIsReady: parser.getBoolean("video_ready", false, false),
            videoErrorProcessing: parser.getBoolean("video_error_processing", false, false),
            visibility: parser.getString("visibility"),
        })
        parser.logUnusedDebugging("parseBioApiResponse.photoset")
    }
    return r
}

export const SIZE_SM = 0
export const SIZE_BIG = 1
export const SIZE_OR = 2

interface ILabelPart {
    token: string
    size: number
}

export const PURCHASE_ONLY = "purchase_only"
export const FANCLUB_OR_PURCHASE = "fanclub_or_purchase"
export const FANCLUB_ONLY = "fanclub_only"
export const FREE = "free"
export const NOT_APPROVED = "not_approved"

export interface IPhotosetLabel {
    labelParts: ILabelPart[]
    labelColor: string
    labelType: string
}

export function buildPhotosetLabel(photoset: IPhotoVideoTabPhotoset | IBioPhotoset, hasFanClub: boolean): IPhotosetLabel {
    const l: IPhotosetLabel = {
        labelColor: "#AAA",
        labelParts: [],
        labelType: FREE,
    }
    if (photoset.failedApproval) {
        l.labelType = NOT_APPROVED
        l.labelColor = "#5e81a4"
        l.labelParts = [{
            token: i18n.failedCAPS,
            size: SIZE_SM,
        }, {
            token: i18n.approvalCAPS,
            size: SIZE_SM,
        }]
    }
    else if (videoErrorProcessing(photoset)) {
        l.labelType = NOT_APPROVED
        l.labelColor = "#5e81a4"
        l.labelParts = [{
            token: i18n.errorCAPS,
            size: SIZE_SM,
        }, {
            token: i18n.processingCAPS,
            size: SIZE_SM,
        }]
    }
    else if (videoProcessing(photoset)) {
        l.labelType = NOT_APPROVED
        l.labelColor = "#5e81a4"
        l.labelParts = [{
            token: i18n.videoCAPS,
            size: SIZE_SM,
        }, {
            token: i18n.processingCAPS,
            size: SIZE_SM,
        }]
    }
    else if (photoset.pendingApproval) {
        l.labelType = NOT_APPROVED
        l.labelColor = "#5e81a4"
        l.labelParts = [{
            token: i18n.pendingCAPS,
            size: SIZE_SM,
        }, {
            token: i18n.approvalCAPS,
            size: SIZE_SM,
        }]
    }
    else if (isPurchaseOnly(photoset.fanClubUnlock, hasFanClub, photoset.tokens)) {
        l.labelType = PURCHASE_ONLY
        l.labelParts = [{
            token: `${photoset.tokens}`,
            size: SIZE_BIG,
        }, {
            token: i18n.tokenOrTokensText(photoset.tokens, true),
            size: SIZE_SM,
        }]
    }
    else if (photoset.fanClubOnly) {
        l.labelType = FANCLUB_ONLY
        l.labelColor = "#090"
        l.labelParts = [{
            token: i18n.fanTextCAPS,
            size: SIZE_BIG,

        }, {
            token: i18n.clubTextCAPS,
            size: SIZE_BIG,
        }]
    }
    else if (photoset.fanClubUnlock && photoset.tokens > 0) {
        l.labelType = FANCLUB_OR_PURCHASE
        l.labelColor = "#090"
        l.labelParts = [{
            token: i18n.fanTextCAPS,
            size: SIZE_BIG,
        }, {
            token: i18n.clubTextCAPS,
            size: SIZE_BIG,
        }, {
            token: i18n.orCAPS,
            size: SIZE_OR,
        }, {
            token: `${photoset.tokens}`,
            size: SIZE_BIG,
        }, {
            token: i18n.tokenOrTokensText(photoset.tokens, true),
            size: SIZE_SM,
        }]
    }
    return l
}

function isPurchaseOnly(fanClubUnlock: boolean, hasFanClub: boolean, tokens: number): boolean {
    return (!fanClubUnlock && tokens > 0) || (!hasFanClub && tokens > 0)
}

function videoErrorProcessing (photoset: IPhotoVideoTabPhotoset | IBioPhotoset): boolean {
    return photoset.isVideo && photoset.videoErrorProcessing
}

function videoProcessing (photoset: IPhotoVideoTabPhotoset | IBioPhotoset): boolean {
    return photoset.isVideo && !photoset.videoIsReady
}
