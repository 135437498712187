import { populateMap, PushServiceContext } from "../baseClient"
import { ABLY_CLIENT_NAME } from "./index"
import type { IPushContextSettings } from "../baseClient"
import type { ArgJSONMap } from "@multimediallc/web-utils"

export interface IAblyContextSettings extends IPushContextSettings {
    // backend, flags
    restHost?: string,
    realtimeHost?: string,
    fallbackHosts: string[],
}

export class AblyContext extends PushServiceContext<IAblyContextSettings> {
    tokenRequest: Ably.TokenRequest | undefined
    jwtToken: string | undefined

    constructor(context: ArgJSONMap) {
        super(context)
        this.tokenRequest = context.getObject("token_request") as Ably.TokenRequest
        this.jwtToken = context.getString("token")
        if (this.jwtToken === "" || this.tokenRequest.keyName === undefined) {
            warn("invalid AblyContext", {
                "contextKeys": context.keys().join(","),
                "context": context.stringMessage,
            })
        }
        context.ignore("token_request")

        context.logUnusedDebugging("AblyContext")
    }

    public parseSettings(context: ArgJSONMap): IAblyContextSettings {
        return AblyContext.parseSettings(context)
    }

    public static parseSettings(context: ArgJSONMap): IAblyContextSettings {
        const settings: IAblyContextSettings = {
            backend: ABLY_CLIENT_NAME,
            restHost: context.getStringOrUndefined("rest_host"),
            realtimeHost: context.getStringOrUndefined("realtime_host"),
            fallbackHosts: context.getStringList("fallback_hosts"),
            flags: new Map<string, boolean>(),
            verifyEnabled: false,
        }
        populateMap<boolean>(settings.flags, context.getObjectOrUndefined("flags"))
        return settings
    }

    public isValid(): boolean {
        return this.tokenRequest !== undefined
    }

    public getTokenRequest(): string {
        if (this.jwtToken === undefined) {
            throw new Error("Access to AblyContext.tokenRequest on an invalid context!")
        }
        return this.jwtToken
    }

    public serialize(): string {
        return JSON.stringify(this.tokenRequest)
    }
}
