import { getCb } from "../api"
import type { IField, IForm } from "../formUtils"


export interface IUsernameAssist {
    fields: IUsernameAssistFields | undefined
}

type UsernameAssistDTO = {
    success: boolean,
    usernames: string[],
}

interface IUsernameAssistFields {
    usernameField: IField
    row: HTMLElement
    available: HTMLElement
    availableButton: HTMLButtonElement
}

export function createUsernameAssist(form: IForm): void {
    if (form.usernameAssistUrl === undefined){
        return
    }
    const usernameField = form.fields.find((f) => f.name === "username")
    const row = document.getElementById("username_assist_row")
    const available = document.getElementById("username_assist_available")

    if (!(usernameField && row && available)) {
        return
    }
    form.usernameAssist.fields = {
        usernameField: usernameField,
        row: row,
        available: available,
        availableButton: document.createElement("button"),
    }

    const styleAssistButton = (button: HTMLButtonElement) => {
        button.style.display = "none"
        button.style.cursor = "pointer"
        button.style.background = "transparent"
        button.style.border = "none"
        button.style.padding = "2px"
        button.style.color = "#0a5a83"
    }

    const setUsername = (elem: HTMLButtonElement) => {
        if (form.usernameAssist.fields === undefined) {
            return
        }
        const usernameInput = form.usernameAssist.fields.usernameField.htmlElement as HTMLInputElement
        usernameInput.value = elem.innerText
        form.validate(false) // eslint-disable-line @typescript-eslint/no-floating-promises
    }

    styleAssistButton(form.usernameAssist.fields.availableButton)
    form.usernameAssist.fields.availableButton.type = "button"
    form.usernameAssist.fields.availableButton.addEventListener("click", (e: Event) => {
        e.preventDefault()
        if (form.usernameAssist.fields === undefined) {
            return
        }
        setUsername(form.usernameAssist.fields.availableButton)
    })
}

function isUsernameAssistHidden(fields: IUsernameAssistFields): boolean {
    return fields.row.style.display === "none"
}

function hideUsernameAssist(fields: IUsernameAssistFields): void {
    fields.row.style.display = "none"
}

export function updateUsernameAssist(fields: IUsernameAssistFields | undefined, showAssist: boolean, usernameAssistUrl: string, username: string): void {
    if (fields === undefined) {
        return
    }
    if (!showAssist) {
        if (isUsernameAssistHidden(fields)){
            return
        }
        hideUsernameAssist(fields)
        return
    }
    updateUsernames(fields, usernameAssistUrl, username)
}

function updateUsernames(fields: IUsernameAssistFields, usernameAssistUrl: string, username: string): void {
    getCb(usernameAssistUrl.concat(`?username=${encodeURIComponent(username)}`)).then((response) => {
        const data = JSON.parse(response.responseText)
        if (data["success"] === true) {
            showAvailable(fields, data)
        } else {
            hideUsernameAssist(fields)
        }
    }).catch(() => {})

    function showButton(fields: IUsernameAssistFields): void {
        fields.available.appendChild(fields.availableButton)
        fields.available.style.display = "block"
    }

    function showAvailable(fields: IUsernameAssistFields, data: UsernameAssistDTO) {
        fields.availableButton.innerText = data["usernames"][0]
        fields.availableButton.style.display = "inline-block"
        showButton(fields)
        fields.row.style.display = "table-row"
    }
}
