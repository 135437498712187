import { Component } from "./defui/component"
import { EventRouter } from "./events"

export class OverlayComponent extends Component<HTMLDivElement> {
    overlay: HTMLDivElement
    overlayClick = new EventRouter<undefined>("overlayClick")

    constructor(props?: object) {
        super("div", props)
    }

    protected initUI(props?: object): void {
        super.initUI(props)
        this.overlay = document.createElement("div")
        this.overlay.style.position = "fixed"
        this.overlay.style.display = "none"
        this.overlay.style.left = "0"
        this.overlay.style.top = "0"
        this.overlay.style.right = "0"
        this.overlay.style.bottom = "0"
        this.overlay.style.zIndex = "1000"
        this.overlay.style.cursor = "auto"
        this.overlay.onclick = (event: MouseEvent) => {
            event.stopPropagation() // Prevent the click from bubbling up to other modal overlays.
            this.hideOverlay()
            this.overlayClick.fire(undefined)
        }

        this.element.style.zIndex = "1001"
        this.element.onclick = (event: MouseEvent) => {
            event.stopPropagation() // Prevent interacting with the component from triggering the overlay.
        }
    }

    protected lazyInitUI(): void {
        super.lazyInitUI()
        window.setTimeout(() => {
            this.afterDOMConstructedIncludingChildren()
        }, 0)
    }

    public afterDOMConstructedIncludingChildren(): void {
        super.afterDOMConstructedIncludingChildren()
        if (this.element.parentElement !== null) { 
            this.element.parentElement.insertBefore(this.overlay, this.element)
        }
    }

    public showOverlay(): void {
        this.overlay.style.display = "block"
    }

    protected hideOverlay(): void {
        this.overlay.style.display = "none"
    }

    protected hide(): void {
        this.hideElement()
        this.hideOverlay()
    }

    protected afterRemovedFromParent(): void {
        super.afterRemovedFromParent()
        if (this.overlay.parentElement !== null) { 
            this.overlay.parentElement.removeChild(this.overlay)
        }
    }
}
