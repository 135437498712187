declare const PRODUCTION_DEFINE: boolean
declare const BASE_STATIC_URL: string

// @ts-ignore
window["PRODUCTION"] = PRODUCTION_DEFINE
// @ts-ignore
window["CHATURBATE_URL_PREFIX"] = "/"
// @ts-ignore
window["STATIC_URL_ROOT"] = BASE_STATIC_URL
// @ts-ignore
window["STATIC_URL_MOBILE"] = BASE_STATIC_URL + "mobileassets/"
// @ts-ignore
window["STATIC_URL"] = BASE_STATIC_URL + "tsdefaultassets/"
