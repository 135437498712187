import { BaseSendTipButton } from "../../cb/ui/tipping"

export class SendTipButton extends BaseSendTipButton {
    protected createCommonButton(): HTMLButtonElement {
        const button = super.createCommonButton()
        button.dataset.testid = "send-tip-button"
        button.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        return button
    }
}
