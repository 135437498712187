import { ArgJSONMap } from "@multimediallc/web-utils"
import { isEdgeMS, isIE } from "@multimediallc/web-utils/modernizr"
import { getCookieOrEmptyString } from "@multimediallc/web-utils/storage"
import { anyBroadcastStartStop } from "../cb/broadcastStatus"
import { ReactComponentRegistry } from "../cb/components/ReactRegistry"
import { openFeedbackForm } from "../cb/components/userMenus/ui/userFeedbackFormEvents"
import { BroadcasterFeedbackModal } from "../cb/components/userMenus/ui/userFeedbackModal"
import { pageContext } from "../cb/interfaces/context"
import { smileyEmoticon } from "../cb/ui/svg/feedbackSmiley"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { getCb, postCb } from "./api"
import { roomLoaded } from "./context"
import { Component } from "./defui/component"
import { RoomTabs } from "./mobilelib/roomTabs"
import { TabName } from "./mobilelib/tabList"
import { openTipCalloutRequest, userSwitchedTab } from "./mobilelib/userActionEvents"
import { isPortrait, screenOrientationChanged } from "./mobilelib/windowOrientation"
import { addPageAction } from "./newrelic"
import { ignoreCatch } from "./promiseUtils"
import { RoomStatus } from "./roomStatus"
import { i18n } from "./translation"
import { dom } from "./tsxrender/dom"
import { parseQueryString } from "./urlUtil"
import type { IChatConnection } from "./context"

// Values are the rating sent to backend
export enum SentimentSurveyOption {
    InTrouble = "0",
    Unhappy = "1",
    Indifferent = "2",
    Smirk = "3",
    InLove = "4",
}

// If updating enum values, also update timerBar.scss
const enum SentimentTimerDuration {
    Short = 7,
    Long = 15,
    Extended = 25,
}

const enum SentimentForceValues {
    Immediate = "1",
    SkipBackend = "2",
}

const NPS_SENTIMENT_SUBMIT_GROUP_ENDPOINT = "feedback/sentiment/nps_group/submit/"
const NPS_SENTIMENT_CANCEL_GROUP_ENDPOINT = "feedback/sentiment/nps_group/cancel/"
const SMC_SENTIMENT_SUBMIT_ENDPOINT = "feedback/sentiment/smc/submit/"
const SMC_SENTIMENT_CANCEL_ENDPOINT = "feedback/sentiment/smc/cancel/"
export const BROADCASTER_SENTIMENT_SUBMIT_ENDPOINT = "feedback/sentiment/broadcaster/submit/"

const BROADCASTER_SENTIMENT_MIN_BROADCAST = 10  // minutes


interface ISentimentSurveyConfig {
    forceParam: string
    contextParam: string
    endpoint: string
    delay: number
}
export enum SentimentSurveyType {
    SMC,
    Broadcaster,
    NPS_GROUP,
}
const sentimentSurveyConfigs = new Map<SentimentSurveyType, ISentimentSurveyConfig>([
    [SentimentSurveyType.NPS_GROUP, {
        forceParam: "force_npsSentimentGroup",
        contextParam: "show_nps_sentiment_survey_group",
        endpoint: NPS_SENTIMENT_SUBMIT_GROUP_ENDPOINT,
        delay: 1000 * 15 }],
    [SentimentSurveyType.SMC, {
        forceParam: "force_smcSurvey",
        contextParam: "show_smc_sentiment_survey",
        endpoint: SMC_SENTIMENT_SUBMIT_ENDPOINT,
        delay: 1000 * 60 * 5 }],
    [SentimentSurveyType.Broadcaster, {
        forceParam: "force_bcastSurvey",
        contextParam: "show_broadcaster_sentiment_survey",
        endpoint: BROADCASTER_SENTIMENT_SUBMIT_ENDPOINT,
        delay: 1000 * 5 }],
])

function showSentimentSurvey(isMobile: boolean, surveyType: SentimentSurveyType, room: string): void {
    let survey
    switch (surveyType) {
        case SentimentSurveyType.NPS_GROUP:
            survey = new NPSSentimentSurvey(room, isMobile)
            break
        case SentimentSurveyType.SMC:
            survey = new CornerSMCSentimentSurvey(room)
            break
        case SentimentSurveyType.Broadcaster:
            // Skipping the mini survey to go straight to the feedback modal, so POST now to set the cooldown
            const { endpoint } = sentimentSurveyConfigs.get(surveyType) as ISentimentSurveyConfig
            postCb(endpoint, {}).catch(ignoreCatch)
            BroadcasterFeedbackModal.show()
            return
    }
    document.body.appendChild(survey.element)
    survey.show()
}

export function scheduleSentimentSurvey(isMobile: boolean, surveyType: SentimentSurveyType): void {
    const room = window.location.pathname.match(/\/([^\/]+)\/?$/)?.[1] ?? ""
    const { forceParam, contextParam, endpoint, delay } = sentimentSurveyConfigs.get(surveyType) as ISentimentSurveyConfig

    const forceValue = parseQueryString(window.location.search)[forceParam]
    if (forceValue === SentimentForceValues.Immediate) {
        showSentimentSurvey(isMobile, surveyType, room)
        return
    }

    const camInactiveStatuses = [RoomStatus.Unknown, RoomStatus.Offline, RoomStatus.NotConnected, RoomStatus.PasswordProtected, RoomStatus.Hidden]
    let chatConn: IChatConnection | undefined
    roomLoaded.listen(context => { chatConn = context.chatConnection })

    window.setTimeout(() => {
        if (chatConn === undefined || (camInactiveStatuses.indexOf(chatConn.status) !== -1 && surveyType !== SentimentSurveyType.Broadcaster)) {
            return
        }
        const chatConnRoom = chatConn.room()

        if (forceValue === SentimentForceValues.SkipBackend) {
            showSentimentSurvey(isMobile, surveyType, chatConnRoom)
            return
        }
        let url = endpoint

        if (surveyType === SentimentSurveyType.NPS_GROUP) {
            url = `${NPS_SENTIMENT_SUBMIT_GROUP_ENDPOINT}?source=${pageContext.current.showNpsSentimentSurveyGroup}`
        }
        getCb(url).then((xhr: XMLHttpRequest) => {
            const dataMap = new ArgJSONMap(xhr.responseText)
            const shouldShowSurvey = dataMap.getBoolean(contextParam)
            if (shouldShowSurvey) {
                showSentimentSurvey(isMobile, surveyType, chatConnRoom)
            }
        }).catch(ignoreCatch)
    }, delay)
}

let lastBroadcastStart: number | undefined
export function setupBroadcasterSentimentSurvey(): void {
    const { forceParam } = sentimentSurveyConfigs.get(SentimentSurveyType.Broadcaster) as ISentimentSurveyConfig
    if (parseQueryString(window.location.search)[forceParam] === "1") {
        scheduleSentimentSurvey(false, SentimentSurveyType.Broadcaster)
    }

    anyBroadcastStartStop.listen(wasStart => {
        if (wasStart) {
            lastBroadcastStart = Date.now()
        } else if (lastBroadcastStart !== undefined) {
            const minutesSinceBroadcastStart = (Date.now() - lastBroadcastStart) / (1000 * 60)
            if (minutesSinceBroadcastStart >= BROADCASTER_SENTIMENT_MIN_BROADCAST) {
                scheduleSentimentSurvey(false, SentimentSurveyType.Broadcaster)
            }
        }
    })
}

class CornerSMCSentimentSurvey extends Component {
    private readonly survey: HTMLDivElement
    protected contentContainer: HTMLDivElement
    protected timerParent: HTMLDivElement
    private timer: HTMLDivElement
    private selectedSentiment?: SentimentSurveyOption
    private closedAction: string
    private popupAction: string
    private describedAction: string
    private source: string
    private submitEndpoint
    private cancelEndpoint
    private initialDuration
    private confirmationDuration

    constructor(private room: string) {
        super()

        const surveyWrapperStyle: CSSX.Properties = {
            position: "fixed",
            width: "288px",
            bottom: "8px",
            right: "20px",
            borderRadius: "4px",
            overflow: "hidden",
            zIndex: 1004,  // Above tooltips and dropdowns, below modals
        }
        const surveyContainerStyle: CSSX.Properties = {
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "4px 1px 1px 1px",
            paddingLeft: "16px",
            height: "110px",
        }
        const closeStyle: CSSX.Properties = {
            position: "absolute",
            width: "14px",
            height: "14px",
            top: "14px",
            right: "14px",
            padding: "2px",
            cursor: "pointer",
            zIndex: 1,
        }
        const timerContainerStyle: CSSX.Properties = {
            margin: "-4px -17px",
        }
        const timerStyle: CSSX.Properties = {
            position: "relative",
            width: "288px",
            height: "4px",
        }

        this.element = this.createWrapperElement(
            surveyWrapperStyle,
            surveyContainerStyle,
            closeStyle,
            timerContainerStyle,
            timerStyle,
        )

        this.survey = this.createSurvey()

        this.closedAction = "FeedbackSMCSentimentClosed"
        this.popupAction = "FeedbackSMCSentimentPopup"
        this.describedAction = "FeedbackSMCSentimentDescribed"
        this.source = "smc_sentiment_survey"
        this.submitEndpoint = SMC_SENTIMENT_SUBMIT_ENDPOINT
        this.cancelEndpoint = SMC_SENTIMENT_CANCEL_ENDPOINT
        this.initialDuration = SentimentTimerDuration.Extended
        this.confirmationDuration = SentimentTimerDuration.Long
    }

    protected createWrapperElement(
        surveyWrapperStyle: CSSX.Properties,
        surveyContainerStyle: CSSX.Properties,
        closeStyle: CSSX.Properties,
        timerContainerStyle: CSSX.Properties,
        timerStyle: CSSX.Properties,
    ): HTMLDivElement {
        const onCloseClick = () => {
            this.close()
            addPageAction(this.closedAction)
        }

        return (
            <div style={surveyWrapperStyle} colorClass="cornerSurveyWrapper">
                <div style={surveyContainerStyle} colorClass="cornerSurveyContainer" ref={(el: HTMLDivElement) => { this.contentContainer = el }}>
                    <img style={closeStyle} src={`${STATIC_URL}popup_survey/close.svg`} onClick={onCloseClick}/>
                    <div style={timerContainerStyle} colorClass="timerContainer" ref={(el: HTMLDivElement) => { this.timerParent = el }}>
                        <div style={timerStyle} colorClass="timer" ref={(el: HTMLDivElement) => { this.timer = el }}></div>
                    </div>
                </div>
            </div>
        )
    }

    public show(): void {
        this.contentContainer.appendChild(this.survey)
        this.setTimer(this.initialDuration, true)
        addPageAction(this.popupAction)
    }

    protected close(): void {
        if (this.contentContainer.contains(this.survey)) {
            postCb(this.cancelEndpoint, {}).catch(ignoreCatch)
        }
        if (this.element.parentElement !== null) {
            this.element.parentElement.removeChild(this.element)
        }
    }

    private submit(option: SentimentSurveyOption): void {
        this.selectedSentiment = option
        const postData = {
            "rating": option,
            "room_user": this.room,
            "url": window.location.href,
        }
        postCb(this.submitEndpoint, postData).catch(ignoreCatch)
        this.contentContainer.removeChild(this.survey)
        this.contentContainer.appendChild(this.createConfirmation())
        this.setTimer(this.confirmationDuration, false)
    }

    protected createSurvey(): HTMLDivElement {
        const titleStyle: CSSX.Properties = {
            position: "relative",
            paddingTop: "16px",
            fontSize: "14px",
            width: "245px",
        }
        const emotesRowStyle: CSSX.Properties = {
            position: "relative",
            padding: "16px 0px 11px 0px",
        }

        return this.createSurveyElement(titleStyle, emotesRowStyle)
    }

    protected createSurveyElement(titleStyle: CSSX.Properties, emotesRowStyle: CSSX.Properties): HTMLDivElement {
        return (
            <div colorClass="survey">
                <div style={titleStyle} colorClass="title">{i18n.howIsSmcDoingToday}</div>
                <div style={emotesRowStyle}>
                    {this.createEmoteDiv(SentimentSurveyOption.InTrouble)}
                    {this.createEmoteDiv(SentimentSurveyOption.Unhappy)}
                    {this.createEmoteDiv(SentimentSurveyOption.Indifferent)}
                    {this.createEmoteDiv(SentimentSurveyOption.Smirk)}
                    {this.createEmoteDiv(SentimentSurveyOption.InLove)}
                </div>
            </div>
        )
    }

    protected createEmoteDiv(option: SentimentSurveyOption): HTMLDivElement {
        const emoteContainerStyle: CSSX.Properties = {
            position: "relative",
            display: "inline-block",
            width: "40px",
            height: "40px",
            boxSizing: "border-box",
            marginRight: "8px",
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "pointer",
        }
        const emoteStyle: CSSX.Properties = {
            position: "relative",
            width: "24px",
            height: "24px",
            top: "7px",
            left: "7px",
        }

        return this.createEmoteDivElement(emoteContainerStyle, emoteStyle, option)
    }

    protected createEmoteDivElement(emoteContainerStyle: CSSX.Properties, emoteStyle: CSSX.Properties, option: SentimentSurveyOption): HTMLDivElement {
        return (
            <div style={emoteContainerStyle} colorClass="emoteContainer" onClick={() => { this.submit(option) }}>
                <div style={emoteStyle}>{smileyEmoticon(option)}</div>
            </div>
        )
    }

    protected createConfirmation(): HTMLDivElement {
        const titleStyle: CSSX.Properties = {
            position: "relative",
            paddingTop: "16px",
            fontSize: "12px",
        }
        const feedbackLinkStyle: CSSX.Properties = {
            display: "block",
            paddingBottom: "12px",
            fontSize: "12px",
        }

        return this.createConfirmationElement(titleStyle, feedbackLinkStyle)
    }

    protected createConfirmationElement(titleStyle: CSSX.Properties, feedbackLinkStyle: CSSX.Properties): HTMLDivElement {
        const onFeedbackLinkClick = () => {
            this.close()
            openFeedbackForm.fire({ source: this.source, sentiment: this.selectedSentiment })
            addPageAction(this.describedAction)
        }

        return (
            <div colorClass="confirmation">
                <div style={titleStyle} colorClass="title">{i18n.yourFeedbackHasBeenSent}</div>
                <a href="#" style={feedbackLinkStyle} colorClass="feedbackLink" onClick={onFeedbackLinkClick}>
                    {i18n.describeYourIssues}
                </a>
            </div>
        )
    }

    private setTimer(seconds: SentimentTimerDuration, pauseOnHover: boolean): void {
        this.timer.classList.remove(`timerBar${SentimentTimerDuration.Extended}s`)
        this.timer.classList.remove(`timerBar${SentimentTimerDuration.Long}s`)
        this.timer.classList.remove(`timerBar${SentimentTimerDuration.Short}s`)
        this.timer.classList.add(`timerBar${seconds}s`)
        if (pauseOnHover) {
            this.element.classList.add("pauseOnHover")
        } else {
            this.element.classList.remove("pauseOnHover")
        }

        // Reset the animation.
        this.timerParent.removeChild(this.timer)
        this.timer = this.timer.cloneNode() as HTMLDivElement
        this.timerParent.appendChild(this.timer)

        if (!isIE() && !isEdgeMS()) {
            // cloneNode doesn't copy event listeners and the removed timer won't fire animationend, so safe to just
            // redeclare this listener
            addEventListenerPoly("animationend", this.timer, () => {
                window.setTimeout(() => { this.close() }, 200)
            })
        } else {
            // The timer animation doesn't work on IE
            window.setTimeout(() => { this.close() }, seconds * 1000 + 200)
        }
    }
}

class NPSSentimentSurvey extends Component {
    private readonly survey: HTMLDivElement
    protected contentContainer: HTMLDivElement
    protected timerParent: HTMLDivElement
    private closedAction: string
    private popupAction: string
    private source: string
    private cancelEndpoint
    private submitEndpoint
    private isOpen: boolean

    constructor(private room: string, isMobile = false) {
        super()

        this.closedAction = "FeedbackNPSClosed"
        this.popupAction = "FeedbackNPSPopup"

        this.source = pageContext.current.showNpsSentimentSurveyGroup as string
        this.cancelEndpoint = NPS_SENTIMENT_CANCEL_GROUP_ENDPOINT
        this.submitEndpoint = NPS_SENTIMENT_SUBMIT_GROUP_ENDPOINT

        const isBottomPositioned = (tabName: TabName) => isMobile && [TabName.Bio, TabName.MoreRooms, TabName.RoomMenu].includes(tabName)
        this.element = this.createWrapperElement(isMobile)
        if (isBottomPositioned(RoomTabs.currentTab)) {
            this.element.style.bottom = "5px"
        }

        this.survey = document.createElement("div")

        const NPSSurveyReact = ReactComponentRegistry.get("NPSSentimentSurvey")
        new NPSSurveyReact(
            {
                onSubmit: (resp: string[], prompts: string[]) => this.submit(resp, prompts),
                closeModal: () => this.close(),
            },
            this.survey,
        )
        if (isMobile) {
            screenOrientationChanged.listen(() => {
                isPortrait() ? this.showElement() : this.hideElement()
            })
            userSwitchedTab.listen((tabName) => {
                if (isBottomPositioned(tabName)) {
                    this.element.style.bottom = "5px"
                } else {
                    this.element.style.bottom = "74px"
                }
            })
            openTipCalloutRequest.listen(() => {
                // avoid focusing on tip input when survey opened
                if (this.isOpen) {
                    const tipInput: HTMLInputElement | null = document.querySelector(".inputAmt.tipAmountInput")
                    if (tipInput) {
                        window.setTimeout(() => tipInput.blur(), 50)
                    }
                }
            })
        }
    }

    protected createWrapperElement(
        isMobile: boolean,
    ): HTMLDivElement {
        return (
            <div colorClass="npsSurveyWrapper" className={`${isMobile ? "npsMobile" : ""}`} ref={(el: HTMLDivElement) => { this.contentContainer = el }}>
            </div>
        )
    }

    public show(): void {
        this.isOpen = true
        this.contentContainer.appendChild(this.survey)
        addPageAction(this.popupAction, { source: this.source })
        if (this.contentContainer.contains(this.survey)) {
            // setting cooldown on the back-end
            const postData: Record<string,string> = { source: this.source }
            postCb(this.cancelEndpoint, postData).catch(ignoreCatch)
        }
    }

    protected close(skipAction = false): void {
        this.isOpen = false
        if (!skipAction) {
            addPageAction(this.closedAction, { source: this.source })
        }
        if (this.element.parentElement !== null) {
            this.element.parentElement.removeChild(this.element)
        }
    }

    private submit(resp: string[], prompts: string[]): void {
        const formData = new FormData()
        formData.append("rating", resp[0])
        resp.forEach(value => {
            formData.append("responses", value)
        })
        prompts.forEach(value => {
            formData.append("prompts", value)
        })
        formData.append("source", this.source)
        formData.append("url", document.location.href)
        formData.append("csrfmiddlewaretoken", getCookieOrEmptyString("csrftoken"))

        const submitData = new FormData()
        submitData.append("rating", resp[0])
        submitData.append("room_user", this.room)
        submitData.append("url", document.location.href)
        submitData.append("source", this.source)

        postCb(this.submitEndpoint, submitData).catch(ignoreCatch)
        postCb("/feedback/submit/", formData).catch(ignoreCatch)
        this.close(true)
    }

}
