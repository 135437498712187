export interface ISocialAuth {
    provider: string,
    name: string,
}

export function parseSocialAuths(settings: string|undefined): ISocialAuth[]|undefined {
    if (settings === undefined) {
        return undefined
    }

    const socialAuths: ISocialAuth[] = []
    for (const auth of JSON.parse(settings)) {
        socialAuths.push({
            provider: auth[0],
            name: auth[1],
        })
    }
    return socialAuths
}
