import { addEventListenerPoly, removeEventListenerPoly } from "./addEventListenerPolyfill"
import { normalizeResource } from "./api"
import { EventRouter } from "./events"
import { exitFullscreen, FULLSCREEN_CLOSING_TIME_TO_WAIT, isFullscreen } from "./fullscreen"

// This is needed to avoid strange issues with opening window while in fullscreen.
//   When fullscreen on most browsers, calling window.open will exit fullscreen after the new window opens, but the
//   original window will gain focus when it exits fullscreen, causing the new window to be hidden behind the original
//   window.
export function safeWindowOpen(url: string, target?: string, features?: string, replace?: boolean): void {
    safeWindowOpenWithReturn(url, target, features, replace)  // eslint-disable-line @typescript-eslint/no-floating-promises
}
export function safeWindowOpenWithReturn(url: string, target?: string, features?: string, replace?: boolean): Promise<Window|undefined> {
    return new Promise<Window|undefined>((resolve) => {
        if (isFullscreen()) {
            exitFullscreen()
            window.setTimeout(() => {
                const newWindow = window.open(normalizeResource(url), target, features)
                if (newWindow !== null) {
                    window.setTimeout(() => {
                        newWindow.focus()
                    }, 200)
                }
                resolve(newWindow ?? undefined)
            }, FULLSCREEN_CLOSING_TIME_TO_WAIT)
        } else {
            resolve(window.open(normalizeResource(url), target, features) ?? undefined)
        }
    })
}
export const toolbarPopupLinkFeatures = "status=0,toolbar=1,menubar=0,directories=0,resizable=1,scrollbars=1,height=768,width=850,top=50,left=50"
export const largePopupLinkFeatures = "status=0,toolbar=0,menubar=0,directories=0,resizable=1,scrollbars=1,height=768,width=850"
export const mediumPopupLinkFeatures = "status=0,toolbar=0,menubar=0,directories=0,resizable=1,scrollbars=1,height=700,width=715"
export const smallPopupLinkFeatures = "status=0,toolbar=0,menubar=0,directories=0,resizable=1,scrollbars=1,height=450,width=600"

export function reloadPage(stripParams?: boolean): void {
    window.onbeforeunload = function (): void {}
    const url = window.location.href.split("?")
    // Only assign if URLs are different
    if (stripParams === true && url.length > 1) {
        window.location.href = normalizeResource(url[0])
    } else {
        window.location.reload()
    }
}

export function goToHomepage(): void {
    window.location.href = normalizeResource("/")
}

let hidden: "hidden" | "msHidden" | "webkitHidden" | "" = ""
let visibilityChange = ""
if ("hidden" in document) {
    hidden = "hidden"
    visibilityChange = "visibilitychange"
} else if ("msHidden" in document) {
    hidden = "msHidden"
    visibilityChange = "msvisibilitychange"
} else if ("webkitHidden" in document) {
    hidden = "webkitHidden"
    visibilityChange = "webkitvisibilitychange"
}

export const documentVisibilityChange = new EventRouter<boolean>("documentVisibilityChange", { maxHistorySize: 1 })
let documentVisible = hidden !== "" ? !(document[hidden] ?? false) : true
const setDocumentVisibility = (isVisible: boolean) => {
    if (documentVisible !== isVisible) {
        documentVisible = isVisible
        documentVisibilityChange.fire(documentVisible)
    }
}
if (hidden !== "" && visibilityChange !== "") {
    const visibilityChangeListener = () => {
        setDocumentVisibility(!document[hidden]) /* eslint-disable-line @typescript-eslint/strict-boolean-expressions */
    }
    addEventListenerPoly(visibilityChange, document, visibilityChangeListener)

    // Older versions of iOS claim to support visibilityChange, but never actually fire the event.
    // Instead, they fire pagehide and pageshow events.
    // On the first instance of a pagehide, replace visibilityChange listener with a pageshow and pagehide listener.
    // Newer browsers use the pagehide event when the document is about to be torn down, so it's fine to hijack it.
    const initPageShowHideFallback = () => {
        removeEventListenerPoly(visibilityChange, document, visibilityChangeListener)
        removeEventListenerPoly("pagehide", window, initPageShowHideFallback)

        setDocumentVisibility(false)

        addEventListenerPoly("pagehide", window, () => {
            setDocumentVisibility(false)
        })

        addEventListenerPoly("pageshow", window, () => {
            setDocumentVisibility(true)
        })
    }
    addEventListenerPoly("pagehide", window, initPageShowHideFallback)
} else {
    // Use window blur/focus events as fallback for document.hidden
    addEventListenerPoly("blur", window, () => {
        setDocumentVisibility(false)
    })

    addEventListenerPoly("focus", window, () => {
        setDocumentVisibility(true)
    })
}

export function isDocumentVisible(): boolean {
    return documentVisible
}

export function isInIframe(): boolean {
    try {
        return window.self !== window.top
    } catch(_) {
        // some browsers block access to window.top
        return true
    }
}

/**
 * Attempts to retrieve the topmost window in the window hierarchy, falling back
 * to the topmost *accessible* window if unavailable (such as from a x-origin frame)
 */
export function getWindowTop(): Window {
    if (!isInIframe()) {
        return window
    }
    let w: Window = window.self
    while (w.parent !== null && w.parent !== w) {
        w = w.parent
    }
    return w
}

const documentLoadTime = (new Date()).getTime()
export function getDocumentAgeSeconds(): number {
    const now = (new Date()).getTime()
    return (now - documentLoadTime) / 1000
}
