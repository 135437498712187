import { editRoomBan } from "../cb/api/chatBansAndSilences"
import { modalConfirm } from "./alerts"
import { IRoomContext } from "./context"
import { Component } from "./defui/component";
import { IBanSilenceInfo } from "./messageInterfaces"
import { i18n } from "./translation"
import { dom } from "./tsxrender/dom"


export function createUndoOptions(container: Component, m: IBanSilenceInfo, banId: number, context: IRoomContext, logFunction: (s: string) => void): HTMLDivElement {
    const divOptions = <div className={`undo-ban-options-${m.silenced}`} style={{ boxSizing: "border-box", padding: "2px 5px 3px 25px", fontWeight: "bold" }}></div>
    const undoBtn = <a href="#" className="msg-link" onClick={undo}>{i18n.inlineUndoText}</a>
    const dismissBtn = <a href="#" style={{ color: "inherit" }} onClick={removeDivOptions}>({i18n.dismissLower})</a>

    divOptions.appendChild(undoBtn)
    undoBtn.dataset.testid = "undo-button"
    dismissBtn.dataset.testid = "dismiss-button"

    function removeDivOptions(ev?: JSX.TargetedMouseEvent<HTMLAnchorElement>): void {
        ev?.preventDefault()
        divOptions.parentElement?.removeChild(divOptions)
        const options = container.element.getElementsByClassName(`undo-ban-options-${m.silenced}`)
        Array.from(options).forEach((opt) => { opt.parentElement?.removeChild(opt) })
    }

    function undo(ev?: JSX.TargetedMouseEvent<HTMLAnchorElement>): void {
        ev?.preventDefault()
        removeDivOptions()
        const successFunc = () => { logFunction(m.isBan ? i18n.banRemovedMessage : i18n.silenceRemovedMessage) } // eventually add a global chat message, Ryan says don't worry for now
        const failFunc = () => { logFunction(m.isBan ? i18n.banRemoveErrorMessage : i18n.silenceRemoveErrorMessage) }
        editRoomBan(banId, m.isBan ? "remove_ban" : "remove_silence", context.dossier.room, successFunc, failFunc)
    }

    if (!m.isBan && context.dossier.userName === context.dossier.room) { // check isUserRoomOwner here because mods have access to UNDO
        function convertToBan(ev?: JSX.TargetedMouseEvent<HTMLAnchorElement>): void {
            ev?.preventDefault()
            modalConfirm(i18n.banUserConfirm(m.silenced), () => {
                removeDivOptions()
                const successFunc = () => {} // pass a no op to avoid duplicate undo options
                const failFunc = () => { logFunction(i18n.banConvertErrorMessage) }
                editRoomBan(banId, "convert_to_ban", context.dossier.room, successFunc, failFunc)
            })
        }
        const banBtn = <a href="#" style={{ color: "red" }} onClick={convertToBan}>{i18n.inlineBanText}</a>
        banBtn.dataset.testid = "ban-button"
        divOptions.appendChild(document.createTextNode("\u00A0".repeat(5)))
        divOptions.appendChild(banBtn)
    }

    divOptions.appendChild(document.createTextNode("\u00A0".repeat(5)))
    divOptions.appendChild(dismissBtn)

    removeDivOptions() // remove any existing undo options

    return divOptions
}
