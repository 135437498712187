import { DmTippingUI } from "../../cb/components/dmTippingUI"
import { applyStyles } from "../DOMutils"
import { dom } from "../tsxrender/dom"

export class MobileDmTippingUI extends DmTippingUI {
    constructor(username: string, removeUI: () => void) {
        super(username, removeUI)
    }

    public createOverlay(): HTMLElement {
        const overlayStyle: CSSX.Properties = {
            display: "block",
            width: "100%",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        }
        this.overlay = <div style={overlayStyle} colorClass="dmWindowTipOverlay" onClick={this.hideTipping} />

        return this.overlay
    }

    public createUI(): HTMLElement {
        const ui = super.createUI()

        applyStyles(ui, {
            bottom: "",
            height: "",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%",
        })
        applyStyles(this.tipInputDOM, {
            fontSize: "16px",
            fontFamily: "UbuntuRegular, Helvetica, Arial, sans-serif",
        })
        applyStyles(this.tipMessageDOM, {
            fontSize: "16px",
            fontFamily: "UbuntuRegular, Helvetica, Arial, sans-serif",
        })
        applyStyles(this.wrongTipNoticeDOM, { marginLeft: "" })
        applyStyles(this.getMoreLinkDOM, {
            display: "block",
            textAlign: "right",
        })

        return ui
    }

    protected showInvalidTipNotice(): void {
        this.wrongTipNoticeDOM.style.display = "block"
    }
}
