import { isSupportedBrowser } from "@multimediallc/web-utils/modernizr"
import { getLocalStorageWithExpiration, setLocalStorageWithExpiration } from "@multimediallc/web-utils/storage"
import { addEventListenerPoly } from "../common/addEventListenerPolyfill"
import { postCb } from "../common/api"

const DISMISS_NOTICE_TFA_HIDE_DAYS = 10
const DISMISS_NOTICE_HIDE_DAYS = 60

export function bindDismissibleNotice(noticeDivEl: HTMLDivElement): void {
    const tfaDismissNotices = noticeDivEl.querySelectorAll<HTMLAnchorElement>("a.dismiss_notice_tfa_and_email")
    const dismissNotices = noticeDivEl.querySelectorAll<HTMLAnchorElement>("a.dismiss_notice")
    const noPersistDismissNotices = noticeDivEl.querySelectorAll<HTMLAnchorElement>("a.dismiss_notice_no_persist")
    const ajaxDismissNotices = noticeDivEl.querySelectorAll<HTMLAnchorElement>("a.dismiss_notice_ajax")

    const noticeCacheKey = noticeDivEl.getAttribute("data-cache-key")
    if (noticeCacheKey !== null) {
        const noticeCacheValue = getLocalStorageWithExpiration(noticeCacheKey)
        if (noticeCacheValue !== "1") {
            noticeDivEl.style.display = ""
        }
    }

    tfaDismissNotices.forEach( (dimissNotice) => {
        addEventListenerPoly("click", dimissNotice, () => {
            dismissDismissibleNotice(dimissNotice, DISMISS_NOTICE_TFA_HIDE_DAYS)
        })
    })

    dismissNotices.forEach( (dismissNotice) => {
        addEventListenerPoly("click", dismissNotice, () => {
            dismissDismissibleNotice(dismissNotice, DISMISS_NOTICE_HIDE_DAYS)
        })
    })

    noPersistDismissNotices.forEach( (dismissNotice) => {
        addEventListenerPoly("click", dismissNotice, () => {
            dismissDismissibleNotice(dismissNotice)
        })
    })

    ajaxDismissNotices.forEach( (dismissNotice) => {
        addEventListenerPoly("click", dismissNotice, () => {
            dismissDismissibleNoticeAjax(dismissNotice)
        })
    })
}

export function bindAllDismissibleNotices(): void {
    const dismissibleNotices = document.querySelectorAll<HTMLDivElement>("div.dismissible_notice")
    for (const notice of dismissibleNotices) {
        if (notice.id === "ie-support-notice" && isSupportedBrowser()) {
            continue
        }
        bindDismissibleNotice(notice)
    }
}

// is called from base.ts entrypoint and used to show some important notices that are visible on all pages which template extends from base.html
export function bindGlobalDismissibleNotices(): void {
    const EXCLUDED_URLS = ["/security"]

    if (EXCLUDED_URLS.some((url) => window.location.pathname.includes(url))) {
        return
    }

    const globalDismissibleNotices = document.querySelectorAll<HTMLDivElement>("div.dismissible_notice_global")
    for (const notice of globalDismissibleNotices) {
        const dismissButton = notice.querySelector<HTMLAnchorElement>("a.dismiss_notice")
        if (dismissButton === null) {
            continue
        }
        const noticeCacheKey = notice.getAttribute("data-cache-key")
        if (noticeCacheKey === null) {
            continue
        }
        const noticeCacheValue = getLocalStorageWithExpiration(noticeCacheKey)
        if (noticeCacheValue === "1") {
            continue
        }

        notice.style.display = ""
        addEventListenerPoly("click", dismissButton, () => {
            notice.style.display = "none"
            setLocalStorageWithExpiration(noticeCacheKey, "1", { days: DISMISS_NOTICE_HIDE_DAYS })
        })
    }
}

export function dismissDismissibleNotice(dismissLink: HTMLAnchorElement, days?: number): void {
    const noticeElement = dismissLink.closest<HTMLDivElement>("div.dismissible_notice")
    if (noticeElement !== null) {
        const noticeDiv = noticeElement
        const noticeCacheKey = noticeDiv.getAttribute("data-cache-key")
        if (noticeCacheKey !== null && days !== undefined) {
            setLocalStorageWithExpiration(noticeCacheKey, "1", { days: days })
        }
        noticeDiv.style.display = "none"
    }
}

function dismissDismissibleNoticeAjax(dismissLink: HTMLAnchorElement): void {
    const noticeElement = dismissLink.closest<HTMLDivElement>("div.dismissible_notice")
    if (noticeElement !== null) {
        const dismissURL = dismissLink.getAttribute("data-dismiss-url")
        if (dismissURL !== null) {
            postCb(dismissURL, {})  // eslint-disable-line @typescript-eslint/no-floating-promises
        }
        noticeElement.style.display = "none"
    }
}
