import { applyStyles } from "../../../common/DOMutils"
import { dom } from "../../../common/tsxrender/dom"
import { InputSuggestions } from "./inputSuggestions"
import type { IInputSuggestionsProps } from "./inputSuggestions"

export class MobileInputSuggestions extends InputSuggestions {
    private mobileAdvancedOptionsContainer: HTMLDivElement

    protected createElement(props: IInputSuggestionsProps): HTMLDivElement {
        return <div className="mobile suggestionsDiv"/>
    }

    protected initData(props: IInputSuggestionsProps): void {
        super.initData(props)
        const optionsContainer = document.getElementById("mobile_advanced_options_container")
        if (optionsContainer instanceof HTMLDivElement) {
            optionsContainer.dataset.testid = "advanced-search-options-component"
            this.mobileAdvancedOptionsContainer = optionsContainer
        }
    }

    public hideSuggestions(): void {
        super.hideSuggestions()
        applyStyles(this.mobileAdvancedOptionsContainer, { overflow: "hidden" })
    }

    public showSuggestions(): void {
        super.showSuggestions()
        applyStyles(this.mobileAdvancedOptionsContainer, { overflow: this.suggestionsList.length ? "visible" : "hidden" })
    }
}
