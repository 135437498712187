import type { ArgJSONMap } from "@multimediallc/web-utils"

export const enum RoomStatus {
    Unknown = "unknown",
    Offline = "offline",
    NotConnected = "notconnected",
    Away = "away",

    PrivateRequesting = "privaterequesting",
    PrivateNotWatching = "privatenotwatching",
    PrivateWatching = "privatewatching",
    PrivateSpying = "privatespying",

    Public = "public",
    Hidden = "hidden",
    HiddenWatching = "hiddenwatching",
    PasswordProtected = "passwordprotected",
}

export enum PrivateSubstatus {
    Standard = "standard",
    Premium = "premium",
}

export function parseRoomStatus(jmap: ArgJSONMap): RoomStatus {
    return statusMapLookup(jmap.getString("room_status"))
}

export function statusMapLookup(status: string): RoomStatus { // eslint-disable-line complexity
    switch (status) {
        case "away":
            return RoomStatus.Away
        case "private":
            return RoomStatus.PrivateNotWatching
        case "private_watching":
            return RoomStatus.PrivateWatching
        case "private_spying":
            return RoomStatus.PrivateSpying
        case "private_requesting":
            return RoomStatus.PrivateRequesting
        case "public":
            return RoomStatus.Public
        case "hidden":
            return RoomStatus.Hidden
        case "hidden_watching":
            return RoomStatus.HiddenWatching
        case "offline":
            return RoomStatus.Offline
        case "password protected":
            return RoomStatus.PasswordProtected
        default:
            error(`unknown room status -- ${status}`)
            return RoomStatus.Unknown
    }
}

export function roomStatusIsWatching(status: RoomStatus): boolean {
    return [
        RoomStatus.Public,
        RoomStatus.PrivateRequesting,
        RoomStatus.PrivateWatching,
        RoomStatus.PrivateSpying,
        RoomStatus.HiddenWatching,
    ].includes(status)
}
