import { isIE } from "@multimediallc/web-utils/modernizr"
import { modalAlert } from "../../../common/alerts"
import { applyStyles } from "../../../common/DOMutils"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { pmMediaRejected } from "../../api/pmMedia"
import { WrappedSpinnerIcon } from "../../ui/spinnerIcon"
import { ChatMediaCarousel, chatMediaThumbnailSize, thumbnailStyle } from "./chatMediaCarousel"
import type { IMediasetProps } from "./chatMediaCarousel";
import type { IChatMedia } from "../../../common/messageInterfaces"

export class MediasetThumbnails {
    public element = <div/>
    public overlays = new Map<number, HTMLSpanElement | HTMLCanvasElement>()  // overlays are the pixelation or the `unopened` badge overlaying the thumbnail
    private thumbnails = new Map<number, HTMLDivElement>()
    private static messageLedger = new Map<string, MediasetThumbnails[]>()
    private static mediaLedger = new Map<number, MediasetThumbnails[]>()

    constructor(private mediasetProps: IMediasetProps) {
        setupRemovalListeners()

        if (!MediasetThumbnails.messageLedger.has(mediasetProps.messageId)) {
            MediasetThumbnails.messageLedger.set(mediasetProps.messageId, [])
        }
        MediasetThumbnails.messageLedger.get(mediasetProps.messageId)?.push(this)

        this.mediasetProps.mediaList.forEach((chatMedia: IChatMedia) => {
            const thumbnail = this.createThumbnail(chatMedia)

            this.element.appendChild(thumbnail)
            this.thumbnails.set(chatMedia.mediaId, thumbnail)

            if (!MediasetThumbnails.mediaLedger.has(chatMedia.mediaId)) {
                MediasetThumbnails.mediaLedger.set(chatMedia.mediaId, [])
            }
            MediasetThumbnails.mediaLedger.get(chatMedia.mediaId)?.push(this)
        })
    }

    private createThumbnail(chatMedia: IChatMedia): HTMLDivElement {
        if (chatMedia.url === "") {
            const thumbnail: HTMLDivElement = <div style={thumbnailStyle}/>
            setDeletedPlaceholder(thumbnail)
            return thumbnail
        }

        let thumbnail: HTMLDivElement  // eslint-disable-line prefer-const
        let img: HTMLImageElement

        let loaded = false
        const onImgLoad = () => {
            loaded = true
            if (chatMedia.opened === true) {
                return
            }

            if (this.mediasetProps.outgoing) {
                // Display status badge for sender
                const span = unopenedSpan()
                this.overlays.set(chatMedia.mediaId, span)
                thumbnail.appendChild(span)
            } else {
                // Display pixelation for receiver
                const pix = pixelate(img)
                pix.style.zIndex = "1000"
                pix.style.position = "absolute"
                this.overlays.set(chatMedia.mediaId, pix)
                thumbnail.insertBefore(pix, thumbnail.firstChild)
            }
        }

        const onImgError = async (e: Event) => {
            // For some reason IE11 calls onError even on successful load.
            // Timeout is to ensure loaded is checked after onLoad would execute
            if (isIE()) {
                await new Promise(resolve => (window.setTimeout(resolve, 300)))
                if (loaded) {
                    return
                }
            }
            setDeletedPlaceholder(thumbnail)
        }

        thumbnail = (
            <div style={thumbnailStyle}>
                <WrappedSpinnerIcon extraStyle={{ background: "rgba(0,0,0,0.6)" }}/>
                <img src={chatMedia.thumbnailUrl}
                     ref={(el: HTMLImageElement) => img = el}
                     style={{ position: "absolute", maxHeight: "100%" }}
                     onLoad={onImgLoad}
                     onError={onImgError}/>
            </div>
        )

        // Assign onclick directly so setDeletedPlaceholder can override it (tsx onClick acts like addEventListener)
        thumbnail.onclick = () => {
            // carouselProps wants its mediaList to be a copy so that ChatMediaCarousel.deleteMedia() and
            // MediaSetThumbnail.deleteMedia() don't have to coordinate removing the media from their respective
            // mediasetProps.mediaLists
            const carouselProps = { ...this.mediasetProps, mediaList: [...this.mediasetProps.mediaList]}
            ChatMediaCarousel.loadMedia(carouselProps, chatMedia)
        }

        return thumbnail
    }

    public static markOpened(messageId: string, mediaID: number): void {
        const sets = MediasetThumbnails.messageLedger.get(messageId) ?? []
        for (const mediasetThumbnails of sets) {
            if (mediasetThumbnails !== undefined) {
                const el = mediasetThumbnails.overlays.get(mediaID)
                el?.parentElement?.removeChild(el)
                mediasetThumbnails.overlays.delete(mediaID)
            }
        }
    }

    public deleteMedia(mediaID: number): void {
        this.mediasetProps.mediaList = this.mediasetProps.mediaList.filter(m => m.mediaId !== mediaID)
        const thumbnail = this.thumbnails.get(mediaID)
        if (thumbnail !== undefined) {
            setDeletedPlaceholder(thumbnail)
        }
    }

    public static deleteMediasetsMedia(mediaID: number): void {
        for (const mediasetThumbnails of MediasetThumbnails.mediaLedger.get(mediaID) ?? []) {
            mediasetThumbnails.deleteMedia(mediaID)
        }
    }
}

function pixelate(input: HTMLImageElement): HTMLCanvasElement {
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")! // eslint-disable-line @typescript-eslint/no-non-null-assertion

    const width = input.width
    const height = input.height

    canvas.width = width
    canvas.height = height

    const size = 8
    ctx.drawImage(input, 0, 0, size, size)
    ctx.imageSmoothingEnabled = false

    // enlarge the minimized image to full size
    ctx.drawImage(canvas, 0, 0, size, size, 0, 0, canvas.width, canvas.height)
    return canvas
}

function unopenedSpan(): HTMLSpanElement {
    const openStatusSpanStyle: CSSX.Properties = {
        color: "#FFFFFF",
        backgroundColor: "#2067AB",
        fontFamily: "'UbuntuBold', Arial, Helvetica, sans-serif",
        fontSize: "8px",
        position: "absolute",
        bottom: "2px",
        right: "2px",
        padding: "0px 1px",
        lineHeight: "20px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: `${chatMediaThumbnailSize - 4}px`,
        boxSizing: "border-box",
        zIndex: 1,
    }
    const span = document.createElement("span")
    span.textContent = i18n.unopenedCAPS
    span.title = span.textContent
    applyStyles(span, openStatusSpanStyle)
    return span
}

function setDeletedPlaceholder(thumbnail: HTMLDivElement): void {
    const errorImg = <img src={`${STATIC_URL_ROOT}images/no_thumbnail_1.jpg`}
                          style={{ position: "absolute", maxHeight: "100%", left: "-9px" }}/>

    while (thumbnail.firstChild !== null) {
        thumbnail.removeChild(thumbnail.firstChild)
    }
    thumbnail.appendChild(errorImg)
    thumbnail.onclick = () => {
        modalAlert(i18n.errorLoadingMedia)
    }
}

let removalListenersInitialized = false
function setupRemovalListeners(): void {
    if (!removalListenersInitialized) {
        pmMediaRejected.listen((mediaID) => {
            MediasetThumbnails.deleteMediasetsMedia(mediaID)
        })
        removalListenersInitialized = true
    }
}
