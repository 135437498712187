import { isiOS } from "@multimediallc/web-utils/modernizr"
import { EventRouter } from "../events"

function isPortraitFromScreenAPI():  boolean | undefined {
    // Since it's experimental, this API doesn't reliably return screen["orientation"]["angle"], i.e., it will sometimes
    // return zero for every angle. So, we must rely on screen["orientation"]["type"]
    const orientation = screen["orientation"] !== undefined ? screen["orientation"]["type"] : undefined
    if (orientation !== undefined) {
        if  (orientation.startsWith("portrait")) {
            return true
        } else if (orientation.startsWith("landscape")) {
            return false
        }
    }

    return undefined
}

export function isPortrait(): boolean {
    if (!isOrientationChangeSupported()) {
        return true
    }

    // Try the new API first, unfortunately it's not supported everywhere
    const portrait = isPortraitFromScreenAPI()
    if (portrait !== undefined) {
        return portrait
    }

    // Now try the deprecated API which should still be supported on some browsers like iOS, IE and Edge
    switch (window.orientation) {
        case 0:
        case 180:
        case -180: // just in case
            return true

        case 90:
        case -90:
        case 270: // just in case
            return false
    }

    // Finally try the least reliable way
    // This is for devices where the height changes when the keyboard is open,
    // potentially making the height smaller than the width, which would trigger landscape mode.
    if (window.innerWidth === Math.min(screen.width, screen.height)) {
        return true
    }

    return window.innerWidth <= window.innerHeight
}

// eslint-disable-next-line complexity
export function isProperLandscape(facingMode?: string): boolean {
    if (!isOrientationChangeSupported()) {
        return true
    }

    // We always need to check window.orientation for iOS
    if (!isiOS()) {
        // Try the new API first, unfortunately it's not supported everywhere
        const portrait = isPortraitFromScreenAPI()
        if (portrait !== undefined) {
            return !portrait
        }
    }

    // Now try the deprecated API which should still be supported on some browsers like iOS, IE and Edge
    switch (window.orientation) {
        case 0:
        case 180:
        case 270: // just in case
        case -180: // just in case
            return false

        case 90:
            if (facingMode === undefined || facingMode === "user") {
                return !isiOS()
            }
            return true

        case -90:
            if (facingMode === "environment") {
                return !isiOS()
            }
            return true
    }

    return true
}

const windowHasOrientationChangeProperty = Object.prototype.hasOwnProperty.call(window, "onorientationchange") // IE doesn't have window.hasOwnProperty
export function isOrientationChangeSupported(): boolean {
    return windowHasOrientationChangeProperty
}

export function isScreenOrientationChangeSupported(): boolean {
    // eslint-disable-next-line compat/compat
    return "orientation" in screen && "onchange" in screen.orientation
}

export function isAnyOrientationChangeSupported(): boolean {
    return isOrientationChangeSupported() || isScreenOrientationChangeSupported()
}

export const screenOrientationChanged = new EventRouter<void>("screenOrientationChanged")
