import { dom } from "../../../common/tsxrender/dom"

export function createFollowerStar(): HTMLDivElement {
    return <div style={{ height: "1.2em", width: "1.2em", display: "inline-block", position: "relative" }} colorClass="followerStarSvg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="8 7 48 46">
            <g transform="translate(0,-952.36218)">
                <path d="M32,959.4l8,14.3l16,3.2L44.9,989l1.9,16.3L32,998.4l-14.8,6.9l1.9-16.3L8,976.9l16-3.2L32,959.4z"/>
            </g>
        </svg>
    </div>
}
