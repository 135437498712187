/**
 * Return an range of numbers from start up to the given size
 * @param start start of the range inclusive
 * @param size size of the range
 */
export function range(start: number, size: number): number[] {
    if (size < 1) {
        return []
    }
    return [...Array(size).keys()].map(i => i + start)
}
