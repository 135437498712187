import { normalizeResource } from "../../../common/api"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { dom } from "../../../common/tsxrender/dom"
import { addPageQueryParam } from "./urlUtils"
import type { IPageClickedInfo } from "./paginationButtonInterfaces"


export interface IPageNumberButtonProps extends IPageClickedInfo {
    baseUrl?: string
    onClick: (e: MouseEvent) => void
    pageParam?: string
}

export class PageNumber extends HTMLComponent<HTMLLIElement, IPageNumberButtonProps> {
    protected createElement(props: IPageNumberButtonProps): HTMLLIElement {
        return <li className="page_number_container">
            <a onClick={props.onClick}
               data-testid="page-number-button"
               href={normalizeResource(addPageQueryParam(props.page, props.baseUrl, props.pageParam))}
               >{props.page}</a>
        </li>
    }

    public toggleSelected(isSelected: boolean): void {
        if (isSelected) {
            this.element.classList.add("active")
        } else {
            this.element.classList.remove("active")
        }
    }

    public isSelected(): boolean {
        // Within the context of Pagination "active" refers to if the page is currently selected
        return this.element.classList.contains("active")
    }
}
