import { addEventListenerPoly } from "../../common/addEventListenerPolyfill"
import { EventRouter } from "../../common/events"
import { RecaptchaField } from "../../common/recaptcha"
import { i18n } from "../../common/translation"
import { addColorClass, removeColorClass } from "../colorClasses"
import { TransparentCheckbox } from "../components/toggle"
import {
    CheckboxInputStyles,
    FieldStyles,
    InputStyles,
    MultipleCheckboxStyles, MultipleSelectStyles,
    NoRecaptchaStyles,
    SelectStyles,
    TextAreaStyles,
} from "./fieldStyles"

type OnChangeListener = (field: Field) => void

export interface IChoices {
    label: string | number,
    value: string | number | boolean,
}

export interface IFieldOptions {
    name: string,
    defaultValue?: string[] | string | number | boolean,
    labelText?: string,
    helpText?: string | HTMLElement,
    required?: boolean,
    disabled?: boolean,
    minLength?: number,
    maxLength?: number,
    onChange?: OnChangeListener,
    styles?: FieldStyles,
    siteKey?: string,  // NoReCaptchaField
}

export abstract class Field {
    protected field: HTMLDivElement
    protected name: string
    protected defaultValue: string
    protected labelContainer: HTMLElement
    protected widgetContainer: HTMLElement
    protected label: HTMLLabelElement
    private labelText: string
    protected widget: HTMLElement
    protected helpText: string | HTMLElement
    protected required = false
    public unsaved = false
    protected disabled = false
    protected errorMessage = ""
    protected errorContainer: HTMLElement
    protected styles: FieldStyles
    protected onChange: OnChangeListener
    public fieldChanged: EventRouter<void>
    public fieldTouched: EventRouter<void>
    protected helpTextElement: HTMLElement
    protected unsavedHelpTextSpan: HTMLSpanElement

    constructor(options: IFieldOptions) {
        this.initializeData(options)

        this.errorContainer = document.createElement("div")
        addColorClass(this.errorContainer, "fieldError")
        this.errorContainer.style.display = "none"

        this.createLabel()
        this.createLabelContainer()
        this.createWidgetContainer()
        this.labelContainer.appendChild(this.label)

        this.createRow()
        this._assembleField()
    }

    protected initializeData(options: IFieldOptions): void {
        this.name = options.name
        this.defaultValue = (options.defaultValue === undefined ? "" : String(options.defaultValue))
        this.labelText = (options.labelText === undefined ? "" : options.labelText)
        this.helpText = (options.helpText === undefined ? "" : options.helpText)
        this.required = (options.required === undefined ? false : options.required)
        this.disabled = (options.disabled === undefined ? false : options.disabled)
        this.onChange = (options.onChange === undefined ? () => {} : options.onChange)
        this.fieldChanged = new EventRouter(`${this.name}Changed`)
        this.fieldTouched = new EventRouter(`${this.name}Touched`)
        this.styles = (options.styles === undefined ? new FieldStyles() : options.styles)
    }

    public getField(): HTMLElement {
        return this.field
    }

    public getWidget(): HTMLElement {
        return this.widget
    }

    public getWidgetContainer(): HTMLElement {
        return this.widgetContainer
    }

    public getName(): string {
        return this.name
    }

    public getLabelContainer(): HTMLElement {
        return this.labelContainer
    }

    public onFormError(): void {}

    public hasError(): boolean {
        return this.errorContainer.style.display === ""
    }

    public setError(err: string): void {
        this.errorContainer.style.display = ""
        this.errorContainer.innerText = err
        addColorClass(this.label, "fieldError")
    }

    public clearError(): void {
        this.errorContainer.style.display = "none"
        this.errorContainer.innerText = ""
        removeColorClass(this.label, "fieldError")
        this.styles.styleLabel(this.label)
    }

    public setDisabled(isDisabled: boolean): void {
        this.disabled = isDisabled
        if ("disabled" in this.widget) {
            this.widget["disabled"] = isDisabled
        }
    }

    public setOnChange(onChange: OnChangeListener): void {
        this.onChange = onChange
    }

    public abstract getValue(): string | string[]

    protected abstract createField(): HTMLElement

    protected abstract assembleField(): void

    private _assembleField(): void {
        this.assembleField()
        this.afterAssembleField()
    }

    protected afterAssembleField(): void {
        this.widgetContainer.appendChild(this.createHelpText())
        this.widgetContainer.appendChild(this.errorContainer)
    }

    protected createLabel(): void {
        this.label = document.createElement("label")
        this.styles.styleLabel(this.label)
        this.label.innerText = this.formatLabelText()
    }

    private createLabelContainer(): void {
        this.labelContainer = document.createElement("th")
        this.styles.styleLabelContainer(this.labelContainer)
        if (this.labelText === "") {
            this.labelContainer.style.display = "none"
        }
    }

    public updateLabelText(newText: string): void {
        this.labelText = newText
        this.label.innerText = this.formatLabelText()
        if (this.labelText === "") {
            this.labelContainer.style.display = "none"
        } else {
            this.labelContainer.style.display = ""
        }
    }

    protected formatLabelText(): string {
        return `${this.labelText}${this.unsaved ? ` ${i18n.unsavedText}` : ""}: ${this.required ? "*" : ""}`
    }

    public setUnsaved(isUnsaved: boolean): void {
        this.unsaved = isUnsaved
        this.label.innerText = this.formatLabelText()
        if (this.unsavedHelpTextSpan !== undefined) {
            this.unsavedHelpTextSpan.style.display = isUnsaved ? "" : "none"
        }
    }

    protected createWidgetContainer(): void {
        this.widgetContainer = document.createElement("td")
        this.styles.styleFieldContainer(this.widgetContainer)
    }

    protected createHelpText(): HTMLElement {
        if (this.helpText instanceof HTMLElement) {
            this.styles.styleHelpText(this.helpText)
            this.helpTextElement = this.helpText
        } else {
            this.helpTextElement = document.createElement("div")
            if (this.helpText === "") {
                this.helpTextElement.style.display = "none"
            } else {
                this.styles.styleHelpText(this.helpTextElement)
                this.helpTextElement.innerHTML = this.helpText // eslint-disable-line @multimediallc/no-inner-html
            }
        }
        return this.helpTextElement
    }

    public createUnsavedHeptextSpan(): HTMLSpanElement {
        this.unsavedHelpTextSpan = document.createElement("span")
        this.unsavedHelpTextSpan.innerText = ` ${i18n.unsavedText}`
        this.unsavedHelpTextSpan.style.display = "none"
        this.unsavedHelpTextSpan.style.whiteSpace = "pre"
        return this.unsavedHelpTextSpan
    }

    public getHelpTextElement(): HTMLElement {
        /**
         * Returns an HTMLElement set and created by createHelpText
         * @returns {HTMLElement}
         */
        return this.helpTextElement
    }

    protected createRow(): void {
        this.field = document.createElement("tr")
        this.styles.styleRow(this.field)

        this.field.appendChild(this.labelContainer)
        this.field.appendChild(this.widgetContainer)
    }
}

interface IInputOptions extends IFieldOptions {
    minLength?: number,
    maxLength?: number,
}

export class Input extends Field {
    protected widget: HTMLInputElement
    protected minLength?: number
    protected maxLength?: number

    constructor(options: IInputOptions) {
        super(options)
    }

    public getValue(): string | string[] {
        return this.widget.value
    }

    protected createField(): HTMLInputElement {
        const input = document.createElement("input")
        input.name = this.name
        input.value = this.defaultValue
        this.styles.styleField(input)
        input.style.width = "225px"
        input.required = this.required
        input.disabled = this.disabled
        if (this.minLength !== undefined) {
            input.minLength = this.minLength
        }
        if (this.maxLength !== undefined) {
            input.maxLength = this.maxLength
        }

        addEventListenerPoly("input", input, () => {
            this.fieldChanged.fire()
        })

        return input
    }

    protected assembleField(): void {
        this.widget = this.createField()
        this.widgetContainer.appendChild(this.widget)
    }

    protected initializeData(options: IInputOptions): void {
        super.initializeData(options)
        this.minLength = options.minLength
        this.maxLength = options.maxLength
        this.styles = (options.styles === undefined ? new InputStyles() : options.styles)
    }
}

export class TextArea extends Field {
    protected widget: HTMLTextAreaElement
    protected minLength?: number
    protected maxLength?: number

    constructor(options: IInputOptions) {
        super(options)
    }

    public getValue(): string {
        return this.widget.value
    }

    protected createField(): HTMLTextAreaElement {
        const textarea = document.createElement("textarea")
        textarea.name = this.name
        textarea.id = `id_${this.name}` // for Lovense
        textarea.value = this.defaultValue
        textarea.required = this.required
        textarea.disabled = this.disabled
        if (this.minLength !== undefined) {
            textarea.minLength = this.minLength
        }
        if (this.maxLength !== undefined) {
            textarea.maxLength = this.maxLength
        }
        this.styles.styleField(textarea)

        const recalculateHeight = () => {
            const offset = textarea.offsetHeight - textarea.clientHeight
            textarea.style.height = "auto"
            textarea.style.height = `${textarea.scrollHeight + offset + 15}px`
        }

        // update height once the field has been attached to the document
        window.setTimeout(() => {
            recalculateHeight()
        }, 0)

        addEventListenerPoly("input", textarea, () => {
            recalculateHeight()
            this.fieldChanged.fire()
        })

        return textarea
    }

    protected assembleField(): void {
        this.widget = this.createField()
        this.widgetContainer.appendChild(this.widget)
    }

    protected initializeData(options: IInputOptions): void {
        super.initializeData(options)
        this.minLength = options.minLength
        this.maxLength = options.maxLength
        this.styles = (options.styles === undefined ? new TextAreaStyles() : options.styles)
    }
}

interface ISelectOptions extends IFieldOptions {
    multiple?: boolean
    choices?: IChoices[]
}

export class Select extends Field {
    private multiple: boolean
    protected widget: HTMLSelectElement
    protected choices: IChoices[]

    constructor(options: ISelectOptions) {
        super(options)
    }

    public getValue(): string | string[] {
        return this.widget.value
    }

    protected createField(): HTMLSelectElement {
        const select = document.createElement("select")
        select.name = this.name
        select.multiple = this.multiple
        select.required = this.required
        select.disabled = this.disabled
        this.styles.styleField(select)

        const selectedValues = this.defaultValue.split(",")

        for (const optionData of this.choices) {
            const option = document.createElement("option")
            option.innerText = String(optionData.label)
            option.value = String(optionData.value)
            option.selected = selectedValues.includes(option.value)

            select.appendChild(option)
        }

        addEventListenerPoly("change", select, () => {
            this.onChange(this)
            this.fieldChanged.fire()
        })

        addEventListenerPoly("blur", select, () => {
            this.fieldTouched.fire()
        })

        return select
    }

    protected assembleField(): void {
        this.widget = this.createField()
        this.widgetContainer.appendChild(this.widget)
    }

    protected initializeData(options: ISelectOptions): void {
        super.initializeData(options)
        this.multiple = (options.multiple === undefined ? false : options.multiple)
        this.choices = (options.choices === undefined ? [] : options.choices)
        this.styles = (options.styles === undefined ? new SelectStyles() : options.styles)
        if (this.defaultValue === "true") {
            this.defaultValue = "True"
        } else if (this.defaultValue === "false") {
            this.defaultValue = "False"
        }
    }
}

interface IMultipleSelectOptions extends ISelectOptions {
    selectedHeader?: string,
    unselectedHeader?: string,
}

export class MultipleSelect extends Select {
    private unselectedHeader: string
    private selectedHeader: string
    private selectedOptions: HTMLSelectElement

    constructor(options: IMultipleSelectOptions) {
        super(options)
    }

    public getValue(): string | string[] {
        const values = []
        for (const option of this.selectedOptions.options) {
            values.push(option.value)
        }
        return values
    }

    protected createField(): HTMLSelectElement {
        const unselectedOptions = document.createElement("select")
        this.selectedOptions = document.createElement("select")
        const hiddenCheckboxesContainer = document.createElement("div")
        hiddenCheckboxesContainer.style.clear = "both"
        hiddenCheckboxesContainer.style.display = "none"
        this.widgetContainer.appendChild(hiddenCheckboxesContainer)

        const addHiddenCheckbox = (value: string): void => {
            const checkbox = document.createElement("input")
            checkbox.type = "checkbox"
            checkbox.style.display = "none"
            checkbox.name = this.name
            checkbox.value = value
            checkbox.checked = true
            checkbox.id = `listselect_${this.name}_${value}`
            hiddenCheckboxesContainer.appendChild(checkbox)
        }

        const removeCheckbox = (value: string): void => {
            const checkbox = document.getElementById(`listselect_${this.name}_${value}`)
            if (checkbox !== null) {
                hiddenCheckboxesContainer.removeChild(checkbox)
            }
        }

        this.styles.styleField(unselectedOptions)
        this.styles.styleField(this.selectedOptions)

        const defaultValueList = this.defaultValue.split(",")
        for (const optionData of this.choices) {
            const option = document.createElement("option")
            option.innerText = String(optionData.label)
            option.value = String(optionData.value)
            if (defaultValueList.includes(option.value)) {
                this.selectedOptions.appendChild(option)
                addHiddenCheckbox(option.value)
            } else {
                unselectedOptions.appendChild(option)
            }
        }

        unselectedOptions.multiple = true
        unselectedOptions.disabled = this.disabled
        this.selectedOptions.multiple = true
        this.selectedOptions.disabled = this.disabled

        const swapSelected = (fromElement: HTMLSelectElement, toElement: HTMLSelectElement, addCheckbox: boolean) => {
            const selectedCount = fromElement.selectedOptions.length
            const selectedList = [...fromElement.selectedOptions]
            for (let i = 0; i < selectedCount; i += 1) {
                const selectedOption = selectedList[i]
                selectedOption.selected = false
                toElement.appendChild(selectedOption)
                if (addCheckbox) {
                    addHiddenCheckbox(selectedOption.value)
                } else {
                    removeCheckbox(selectedOption.value)
                }
            }
        }

        addEventListenerPoly("change", unselectedOptions, () => {
            swapSelected(unselectedOptions, this.selectedOptions, true)
            this.fieldChanged.fire()
            this.onChange(this)
        })

        addEventListenerPoly("change", this.selectedOptions, () => {
            swapSelected(this.selectedOptions, unselectedOptions, false)
            this.fieldChanged.fire()
            this.onChange(this)
        })

        return unselectedOptions
    }

    protected initializeData(options: IMultipleSelectOptions): void {
        super.initializeData(options)
        this.selectedHeader = (options.selectedHeader === undefined ? "" : options.selectedHeader)
        this.unselectedHeader = (options.unselectedHeader === undefined ? "" : options.unselectedHeader)
        this.styles = (options.styles === undefined ? new MultipleSelectStyles() : options.styles)
    }

    private createHeader(text: string): HTMLElement {
        const header = document.createElement("strong")
        addColorClass(header, "label")
        header.style.fontWeight = "bold"
        header.innerText = text

        return header
    }

    private createSelectContainer(): HTMLElement {
        const container = document.createElement("div")
        container.style.marginRight = "5px"
        container.style.cssFloat = "left"
        container.style.width = "200px"

        return container
    }

    protected assembleField(): void {
        const container1 = this.createSelectContainer()
        const container2 = this.createSelectContainer()
        const header1 = this.createHeader(this.unselectedHeader)
        const header2 = this.createHeader(this.selectedHeader)

        this.widget = this.createField()

        container1.appendChild(header1)
        container1.appendChild(this.widget)

        container2.appendChild(header2)
        container2.appendChild(this.selectedOptions)

        this.widgetContainer.appendChild(container1)
        this.widgetContainer.appendChild(container2)
    }
}

export class StyledCheckboxInput extends Field {
    private transparentCheckbox: TransparentCheckbox

    protected createField(): HTMLElement {
        this.transparentCheckbox = new TransparentCheckbox(16, this.defaultValue === "True", () => {
            this.transparentCheckbox.setCheckboxValue(this.getChecked() ? "True" : "False")
            this.onChange(this)
        })

        this.transparentCheckbox.setName(this.name)
        this.styles.styleField(this.transparentCheckbox.element)
        this.setDisabled(this.disabled)

        addEventListenerPoly("change", this.transparentCheckbox.element, () => {
            this.fieldChanged.fire()
        })
        return this.transparentCheckbox.element
    }

    protected assembleField(): void {
        this.widget = this.createField()
        this.widgetContainer.appendChild(this.widget)
    }

    public getValue(): string | string[] {
        return this.transparentCheckbox.getCheckboxValue()
    }

    protected initializeData(options: IInputOptions): void {
        super.initializeData(options)
        this.styles = (options.styles === undefined ? new CheckboxInputStyles() : options.styles)
        if (this.defaultValue === "true") {
            this.defaultValue = "True"
        } else if (this.defaultValue === "false") {
            this.defaultValue = "False"
        }
    }

    public setChecked(isChecked: boolean): void {
        this.transparentCheckbox.setCheckedDirectly(isChecked)
    }

    public getChecked(): boolean {
        return this.transparentCheckbox.isChecked()
    }

    public setDisabled(isDisabled: boolean): void {
        if (isDisabled) {
            this.transparentCheckbox.disable()
        } else {
            this.transparentCheckbox.enable()
        }
    }
}

export class CheckboxInput extends Input {
    protected createField(): HTMLInputElement {
        const checkbox = document.createElement("input")
        checkbox.name = this.name
        checkbox.value = this.defaultValue
        checkbox.checked = (this.defaultValue === "True")
        checkbox.required = this.required
        checkbox.disabled = this.disabled
        checkbox.type = "checkbox"
        this.styles.styleField(checkbox)
        addEventListenerPoly("change", checkbox, () => {
            this.widget.value = checkbox.checked ? "True" : "False"
            this.fieldChanged.fire()
            this.onChange(this)
        })

        return checkbox
    }

    public getValue(): string | string[] {
        return this.widget.value
    }

    protected initializeData(options: IInputOptions): void {
        super.initializeData(options)
        this.styles = (options.styles === undefined ? new CheckboxInputStyles() : options.styles)
        if (this.defaultValue === "true") {
            this.defaultValue = "True"
        } else if (this.defaultValue === "false") {
            this.defaultValue = "False"
        }
    }

    public setChecked(isChecked: boolean): void {
        this.widget.checked = isChecked
    }

    public getChecked(): boolean {
        return this.widget.checked
    }
}

export interface IMultipleCheckboxOptions extends IFieldOptions {
    choices?: IChoices[]
}

export class MultipleCheckbox extends Input {
    private checkboxes: TransparentCheckbox[]
    protected choices: IChoices[]

    constructor(options: IMultipleCheckboxOptions) {
        super(options)
    }

    public getValue(): string | string[] {
        const values = []
        for (const checkbox of this.checkboxes) {
            if (checkbox.isChecked()) {
                values.push(checkbox.getCheckboxValue())
            }
        }
        return values
    }

    protected initializeData(options: IMultipleCheckboxOptions): void {
        super.initializeData(options)
        this.choices = (options.choices === undefined ? [] : options.choices)
        this.styles = (options.styles === undefined ? new MultipleCheckboxStyles() : options.styles)
    }

    protected createCheckboxesField(): HTMLElement {
        const container = document.createElement("div")
        this.checkboxes = []
        for (const value of this.choices) {
            const initiallyChecked = this.defaultValue.indexOf(String(value.value)) !== -1
            const transparentCheckbox = new TransparentCheckbox(16, initiallyChecked)
            transparentCheckbox.setName(this.name)
            transparentCheckbox.setCheckboxValue(String(value.value))
            this.styles.styleField(transparentCheckbox.element)
            if (this.disabled) {
                transparentCheckbox.disable()
            }
            addEventListenerPoly("change", transparentCheckbox.element, () => {
                this.fieldChanged.fire()
            })
            this.checkboxes.push(transparentCheckbox)

            const label = document.createElement("label")
            label.className = "checkboxFieldLabel"
            const labelText = document.createTextNode(String(value.label))

            label.appendChild(transparentCheckbox.element)
            label.appendChild(labelText)
            container.appendChild(label)
        }

        return container
    }

    protected assembleField(): void {
        const container  = this.createCheckboxesField()
        this.widgetContainer.appendChild(container)
    }
}

interface ICustomFieldOptions extends IFieldOptions {
    customElement: HTMLElement
}

export class CustomField extends Field {
    private customElement: HTMLElement

    constructor(options: ICustomFieldOptions) {
        super(options)
    }

    protected initializeData(options: ICustomFieldOptions): void {
        super.initializeData(options)
        this.customElement = options.customElement
    }

    public getValue(): string {
        return ""
    }

    protected createField(): HTMLElement {
        return document.createElement("div") // dummy element
    }

    protected assembleField(): void {
        this.widgetContainer.appendChild(this.customElement)
    }
}

interface IDateSelectOptions extends IFieldOptions {
    day?: number,
    month?: number,
    year?: number,
}

export class DateSelect extends Select {
    private dayField: HTMLSelectElement
    private monthField: HTMLSelectElement
    private yearField: HTMLSelectElement
    private defaultDay: string
    private defaultMonth: string
    private defaultYear: string

    constructor(options: IDateSelectOptions) {
        super({
            ...options,
            choices: [],
        })
    }

    protected initializeData(options: IDateSelectOptions): void {
        super.initializeData({
            ...options,
            choices: [],
        })
        this.defaultDay = (options.day === undefined ? "0" : options.day.toString(10))
        this.defaultMonth = (options.month === undefined ? "0" : options.month.toString(10))
        this.defaultYear = (options.year === undefined ? "0" : options.year.toString(10))
    }

    public getValue(): string | string[] {
        return [
            this.monthField.value,
            this.dayField.value,
            this.yearField.value,
        ]
    }

    private createDateField(options: IChoices[], defaultValue: string, datePart: string): HTMLSelectElement {
        const select = document.createElement("select")
        select.name = `${this.name}_${datePart}`
        this.styles.styleField(select)
        for (const optionData of options) {
            const option = document.createElement("option")
            option.innerText = `${optionData.label}`
            option.value = `${optionData.value}`
            select.appendChild(option)
        }
        select.value = defaultValue
        select.required = this.required

        return select
    }

    protected getDayOptions(): IChoices[] {
        const NUM_DAYS = 31
        const dayOptions = []
        dayOptions.push({ label: "---", value: "0" })
        for (let i = 1; i <= NUM_DAYS; i += 1) {
            const dayStr = i.toString(10)
            dayOptions.push({ label: dayStr, value: dayStr })
        }
        return dayOptions
    }

    private getMonthOptions(): IChoices[] {
        const monthOptions = []
        const MONTHS = [
            i18n.january,
            i18n.february,
            i18n.march,
            i18n.april,
            i18n.may,
            i18n.june,
            i18n.july,
            i18n.august,
            i18n.september,
            i18n.october,
            i18n.november,
            i18n.december,
        ]
        monthOptions.push({ label: "---", value: "0" })
        for (let i = 0; i < MONTHS.length; i += 1) {
            monthOptions.push({ label: MONTHS[i], value: (i + 1).toString(10) })
        }
        return monthOptions
    }

    private getYearOptions(): IChoices[] {
        const yearOptions = []
        const MIN_YEAR = 1900
        const MAX_YEAR = new Date().getFullYear() - 18
        yearOptions.push({ label: "---", value: "0" })
        for (let i = MAX_YEAR; i > MIN_YEAR; i -= 1) {
            const yearStr = (i).toString(10)
            yearOptions.push({ label: yearStr, value: yearStr })
        }
        return yearOptions
    }

    protected assembleField(): void {
        this.dayField = this.createDateField(this.getDayOptions(), this.defaultDay, "day")
        this.monthField = this.createDateField(this.getMonthOptions(), this.defaultMonth, "month")
        this.yearField = this.createDateField(this.getYearOptions(), this.defaultYear, "year")

        this.widgetContainer.appendChild(this.monthField)
        this.widgetContainer.appendChild(this.dayField)
        this.widgetContainer.appendChild(this.yearField)

        addEventListenerPoly("change", this.dayField, () => {
            this.fieldChanged.fire()
        })

        addEventListenerPoly("change", this.monthField, () => {
            this.fieldChanged.fire()
        })

        addEventListenerPoly("change", this.yearField, () => {
            this.fieldChanged.fire()
        })
    }
}

// used for both NoReCaptcha and ReCaptcha3
interface INoReCaptchaField extends IFieldOptions {
    siteKey: string
}

// NoReCaptchaField is implemented as a reCaptcha to match live behavior
export class NoReCaptchaField extends Field {
    private recaptcha: RecaptchaField
    private sitekey: string

    constructor(options: INoReCaptchaField) {
        super(options)
    }

    protected initializeData(options: INoReCaptchaField): void {
        super.initializeData(options)
        this.styles = (options.styles === undefined ? new NoRecaptchaStyles() : options.styles)
        this.sitekey = options.siteKey
    }

    public getValue(): string {
        return this.recaptcha.getResponse()
    }

    protected createField(): HTMLElement {
        this.recaptcha = new RecaptchaField()
        this.styles.styleField(this.recaptcha.element)
        return this.recaptcha.element
    }

    protected assembleField(): void {
        this.widget = this.createField()
        this.recaptcha.render({ sitekey: this.sitekey })
        this.widgetContainer.appendChild(this.widget)
    }

    public onFormError (): void {
        super.onFormError()
        this.recaptcha.reset()
    }
}
