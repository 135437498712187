import { ArgJSONMap } from "@multimediallc/web-utils"
import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly, removeEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { modalAlert } from "../../../common/alerts"
import { getCb, postCb } from "../../../common/api"
import { BadgeType, ChatBadgeManager } from "../../../common/chatBadgeManager"
import { ChatConnection } from "../../../common/chatconnection/chatConnection"
import { stringPart, userPart } from "../../../common/chatconnection/roomnoticeparts"
import { ChatReport } from "../../../common/chatReport"
import { Component } from "../../../common/defui/component"
import { addDragListener } from "../../../common/dragListener"
import { EventRouter } from "../../../common/events"
import { getUserType } from "../../../common/messageToDOM"
import { addPageAction } from "../../../common/newrelic"
import { BasePlayer } from "../../../common/player/basePlayer"
import { jpegFallbackSeconds } from "../../../common/player/hlsPlayer"
import { ignoreCatch } from "../../../common/promiseUtils"
import { C2CNotificationLimit, getRoomDossier } from "../../../common/roomDossier"
import { RoomStatus } from "../../../common/roomStatus"
import { RoomStatusNotifier } from "../../../common/roomStatusNotifier"
import { scheduleSentimentSurvey, SentimentSurveyType } from "../../../common/sentimentSurvey"
import { userChatSettingsUpdate } from "../../../common/theatermodelib/userActionEvents"
import { i18n } from "../../../common/translation"
import { safeWindowOpenWithReturn } from "../../../common/windowUtils"
import { anyBroadcastStartStop } from "../../broadcastStatus"
import { addColorClass, colorClass } from "../../colorClasses"
import { pageContext, roomDossierContext } from "../../interfaces/context"
import { enterSmcWatcherPresence, leaveSmcWatcherPresence } from "../../pushservicelib/topics/smcPresence"
import {
    showMyCamStarted, showMyCamStopped, SMCPresenceTopic, UserSMCBroadcastNotifyTopic,
} from "../../pushservicelib/topics/user"
import { addResizeAreas, getResizedDims } from "../../ui/resizingUtil"
import { resizeDebounceEvent } from "../../ui/responsiveUtil"
import { dmsHeightChanged } from "../pm/dmWindowsManager"
import { getDmWindowHeight } from "../pm/dmWindowUtils"
import {
    baseZIndex, checkOnline, createCornerViewerButton, createCornerViewerControlsContainer, smcAPIDataAsUser,
} from "./smcUtil"
import type { ICategories } from "../../../common/chatReport"
import type { IChatConnection } from "../../../common/context"
import type { BoundListener } from "../../../common/events"
import type { IRoomStatusChangeNotification, IUserInfo } from "../../../common/messageInterfaces"
import type { IPlayer } from "../../../common/player/interfaces"
import type { IRoomDossier } from "../../../common/roomDossier"
import type { resizePosition } from "../../ui/resizingUtil"

/* smcBroadcaster as in the broadcaster who can view viewers' cams */

const CAM_LIMIT = 5
const CAM_RATIO = 9 / 16
const CAM_MIN_WIDTH = 200
const showMyCamPlayerSettingsKey = "smcPlayerSettings"

const enum ShowMyCamState {
    Active = 0,
    Inactive,
    None,
}

interface INoticeRateLimit {
    preventStartNotice: boolean
    timeout?: number
}

const stopViewingCamRequest = new EventRouter<ShowMyCamPlayer>("stopCamRequest")
const shutdownCamRequest = new EventRouter<string>("shutdownCamRequest")

export class ShowMyCamPlayerManager extends Component {
    private static singletonInstance: ShowMyCamPlayerManager | undefined
    private readonly camUserInfos = new Map<string, IUserInfo>()
    private readonly cams = new Map<string, { state: ShowMyCamState, player: ShowMyCamPlayer | undefined }>()
    private readonly playerContainer: HTMLDivElement
    private readonly dummyRoomStatusNotifier = new RoomStatusNotifier(false)
    private noticeRateLimits = new Map<string, INoticeRateLimit>()
    private noticeRateLimitSetting: C2CNotificationLimit
    private height = 0
    private bottom = 16
    private cachedBottom = this.bottom
    private right = 16
    private width = 208
    private numCams = 0
    private listenedForPublicStatus = false

    private constructor(private readonly roomChatConn: IChatConnection) {
        super()

        addColorClass(this.element, "SmcBroadcaster")
        this.getSettingsLocalstorage()
        this.element.style.display = "none"
        this.element.style.width = `${this.width}px`
        this.element.style.height = ""
        this.element.style.position = "fixed"
        this.element.style.right = `${this.right}px`
        this.element.style.overflow = "visible"
        this.element.style.zIndex = `${baseZIndex}`
        this.recalcPosition()

        addEventListenerPoly("scroll", window, () => { this.recalcPosition() })
        resizeDebounceEvent.listen(() => { this.recalcPosition() })

        this.playerContainer = document.createElement("div")
        this.playerContainer.style.display = "table-cell"
        this.playerContainer.style.verticalAlign = "bottom"
        this.playerContainer.style.width = "100%"
        this.element.appendChild(this.playerContainer)

        this.addDrag()
        this.addResize()

        const userUid = pageContext.current.loggedInUser?.userUid
        if (userUid !== undefined) {
            new UserSMCBroadcastNotifyTopic(userUid).onMessage.listen((msg) => {
                if (msg.started) {
                    showMyCamStarted.fire(msg)
                } else {
                    showMyCamStopped.fire(msg)
                }
            })
        }

        const connectingCams = new Set<string>()
        showMyCamStarted.listen((user: IUserInfo) => {
            if (this.getCamState(user.username) !== ShowMyCamState.None || connectingCams.has(user.username)) {
                return
            }
            connectingCams.add(user.username)
            // api/online may take some time to update for the cam, so wait for it before updating state
            let isOnline = false
            let camStopped = false
            let numTries = 10
            const camStop = showMyCamStopped.listen((stoppedUser: IUserInfo) => {
                if (stoppedUser.username === user.username) {
                    camStopped = true
                    camStop.removeListener()
                }
            }, false)
            const onlineCheckInterval = window.setInterval(() => {
                if (isOnline || camStopped || numTries <= 0) {
                    clearInterval(onlineCheckInterval)
                    connectingCams.delete(user.username)
                    camStop.removeListener()
                    if (isOnline && !camStopped) {
                        this.camUserInfos.set(user.username, user)
                        this.setCamState(user.username, ShowMyCamState.Inactive)
                    }
                    return
                }
                numTries -= 1
                checkOnline(user.username).then((online) => {
                    isOnline = online
                }).catch(ignoreCatch)
            }, 1000)

            checkOnline(user.username).then((online) => {
                isOnline = online
            }).catch(ignoreCatch)
        })

        showMyCamStopped.listen((user: IUserInfo) => {
            const username = user.username
            const cam = this.cams.get(username)
            this.camUserInfos.set(user.username, user)
            if (cam?.player !== undefined) {
                this.stopViewingCam(username)
            }
            this.setCamState(username, ShowMyCamState.None)
        })

        this.roomChatConn.event.statusChange.listen((roomStatusChangeNotification) => {
            if (roomStatusChangeNotification.currentStatus === RoomStatus.PrivateWatching) {
                this.endAllCamsExcept(this.roomChatConn.getPrivateShowUser())
            }
        })

        window.setInterval(() => { this.monitorInactive() }, 30 * 1000)

        anyBroadcastStartStop.listen((wasStart) => {
            if (wasStart) {
                if (!this.listenedForPublicStatus) {
                    // Only start listening once chat connects
                    const onStatusChange = (status: IRoomStatusChangeNotification) => {
                        if (status.currentStatus === RoomStatus.Public) {
                            roomChatConn.event.statusChange.removeListener(onStatusChange)
                            this.listenedForPublicStatus = true
                            const initialStops: string[] = []
                            const initialStopsListener = showMyCamStopped.listen((user: IUserInfo) => {
                                initialStops.push(user.username)
                            })
                            getCb("api/ts/chat/share-my-cam/").then((xhr) => {
                                const response = new ArgJSONMap(xhr.responseText)
                                const sharedCams = response.getList("shared_rooms")
                                if (sharedCams !== undefined) {
                                    for (const camData of sharedCams) {
                                        if (initialStops.indexOf(camData.getString("username")) === -1) {
                                            showMyCamStarted.fire(smcAPIDataAsUser(camData))
                                        }
                                    }
                                }
                                initialStopsListener.removeListener()
                            }).catch(ignoreCatch)
                        }
                    }
                    roomChatConn.event.statusChange.listen(onStatusChange)
                }
            } else {
                this.stopAllCams()
                this.listenedForPublicStatus = false
            }
        })
        stopViewingCamRequest.listen((stoppedCam) => {
            this.stopViewingCam(stoppedCam.username)
        })
        shutdownCamRequest.listen((username) => {
            const cam = this.cams.get(username)
            if (cam?.player !== undefined) {
                this.stopViewingCam(username)
            }
            this.setCamState(username, ShowMyCamState.None)
        })
        addEventListenerPoly("beforeunload", window, () => {
            this.stopAllCams()
        })

        this.noticeRateLimitSetting = roomDossierContext.getState().userChatSettings.c2cNotificationLimit
        userChatSettingsUpdate.listen(settings => {
            this.noticeRateLimitSetting = settings.c2cNotificationLimit
            for (const username of this.noticeRateLimits.keys()) {
                this.unsetNoticeRateLimit(username)
            }
        })

        dmsHeightChanged.listen(() => {
            this.recalcPosition()
        })

        ChatBadgeManager.registerGenerator(BadgeType.SMC, username => this.createChatBadge(username))
    }

    public static getOrCreateInstance(chatConnection: IChatConnection): ShowMyCamPlayerManager {
        if (ShowMyCamPlayerManager.singletonInstance === undefined) {
            ShowMyCamPlayerManager.singletonInstance = new ShowMyCamPlayerManager(chatConnection)
        }
        return ShowMyCamPlayerManager.singletonInstance
    }

    public static getInstance(): ShowMyCamPlayerManager | undefined {
        return ShowMyCamPlayerManager.singletonInstance
    }

    private addDrag(): void {
        addDragListener(this.playerContainer, (ev: Event, x: number, y: number) => {
            const clientStartRight = this.getCorrectedRight(this.right)
            const clientStartBottom = this.getCorrectedBottom(this.bottom)
            let currentRight = clientStartRight
            let currentBottom = clientStartBottom

            const clientXStart = x
            const clientYStart = y

            let dragging = false
            const movePlayers = (clientXCurrent: number, clientYCurrent: number) => {
                const rightMovement = clientXCurrent - clientXStart
                const downMovement = clientYCurrent - clientYStart
                // leave a few pixels of leeway so buttons don't feel unresponsive if user accidentally drags a bit
                if (!dragging && (Math.abs(rightMovement) > 5 || Math.abs(downMovement) > 5)) {
                    dragging = true
                    this.forEachPlayer((username, player) => {
                         player.setDragging(true)
                    })
                }
                currentBottom = this.getCorrectedBottom(clientStartBottom - downMovement)
                currentRight = this.getCorrectedRight(clientStartRight - rightMovement)
                this.element.style.bottom = `${currentBottom}px`
                this.element.style.right = `${currentRight}px`
                this.forEachPlayer((username, player) => {
                    player.positionChanged()
                })
            }

            return {
                enabled: true,
                move: movePlayers,
                end: () => {
                    this.right = currentRight
                    this.bottom = currentBottom
                    this.cachedBottom = currentBottom
                    this.saveSettingsLocalstorage()
                    dragging = false
                    window.setTimeout(() => {
                        this.forEachPlayer((username, player) => {
                            player.setDragging(false)
                        })
                    }, 100)
                },
            }
        })
    }

    private addResize(): void {
        const resizeListenerCallback = (position: resizePosition)  => {
            return (ev: Event, x: number, y: number) => {
                ev.stopPropagation()
                const resizeConfig = {
                    initialBottom: this.bottom,
                    initialRight: this.right,
                    initialWidth: this.width,
                    initialX: x,
                    initialY: y,
                    minWidth: CAM_MIN_WIDTH,
                    ratio: CAM_RATIO,
                }
                let currentRight = this.right
                let currentBottom = this.bottom
                let newWidth = this.width

                const resizePlayers = (x: number, y: number) => {
                    const dims = getResizedDims(resizeConfig, x, y, position);
                    [currentRight, currentBottom, newWidth] = [dims.right, dims.bottom, dims.width]
                    currentRight = this.getCorrectedRight(currentRight, newWidth)
                    this.element.style.right = `${currentRight}px`
                    this.element.style.width = `${newWidth}px`
                    this.forEachPlayer((username, player) => {
                        player.updateHeight()
                    })
                    const newHeight = this.element.offsetHeight
                    currentBottom = this.getCorrectedBottom(currentBottom, newHeight)
                    this.element.style.bottom = `${currentBottom}px`
                    this.forEachPlayer((username, player) => {
                        player.positionChanged()
                    })
                }

                return {
                    enabled: true,
                    move: resizePlayers,
                    end: () => {
                        this.bottom = currentBottom
                        this.cachedBottom = currentBottom
                        this.right = currentRight
                        this.width = newWidth
                        this.height = this.element.getBoundingClientRect().height
                        this.saveSettingsLocalstorage()
                    },
                }
            }
        }

        addResizeAreas(this.element, resizeListenerCallback)
    }

    private getCorrectedBottom(currentBottom: number, height = this.height): number {
        return Math.max(0, Math.min(window.innerHeight - height, currentBottom))
    }

    private getCorrectedRight(currentRight: number, width = this.width): number {
        return Math.max(0, Math.min(window.innerWidth - width, currentRight))
    }

    private recalcPosition(): void {
        const dmWindowHeight = getDmWindowHeight()
        const margin = 4

        if (dmWindowHeight > 0) {
            this.bottom = Math.max(this.cachedBottom, dmWindowHeight + margin)
        } else {
            this.bottom = this.cachedBottom
        }

        this.element.style.bottom = `${this.getCorrectedBottom(this.bottom)}px`
        this.element.style.right = `${this.getCorrectedRight(this.right)}px`
    }

    private getSettingsLocalstorage(): void {
        if (!isLocalStorageSupported()) {
            return
        }
        const settingsString = window.localStorage.getItem(showMyCamPlayerSettingsKey)
        if (settingsString !== null) {
            const settings = JSON.parse(settingsString)
            this.bottom = settings["bottom"]
            this.cachedBottom = settings["bottom"]
            this.right = settings["right"]
            this.width = settings["width"]
        }
    }

    private saveSettingsLocalstorage(): void {
        if (!isLocalStorageSupported()) {
            return
        }
        const data = {
            "bottom": this.bottom,
            "right": this.right,
            "width": this.width,
        }
        window.localStorage.setItem(showMyCamPlayerSettingsKey, JSON.stringify(data))
    }

    private viewCamFailedListeners = new Map<string, BoundListener<undefined>>()
    private startViewingCam(username: string): void {
        this.unsetNoticeRateLimit(username)
        if (this.numCams >= CAM_LIMIT) {
            modalAlert(i18n.showMyCamTooManyCams)
            addPageAction("SharedCamViewStartFailed", { "cam_user": username })
            addPageAction("SharedCamViewStartTooManyCams", { "cam_user": username })
            return
        }
        if (this.getCamState(username) === ShowMyCamState.None) {
            error("smcBroadcaster - no such cam")
            addPageAction("SharedCamViewStartFailed", { "cam_user": username })
            return
        }

        checkOnline(username).then((online) => {
            if (online) {
                getRoomDossier(username).then((dossier) => {
                    const cam = this.cams.get(username)
                    if (cam !== undefined && cam.player !== undefined) {
                        // User could hit 'view cam' twice if connection is slow
                        return
                    }
                    this.element.style.display = "table"
                    const player = new ShowMyCamPlayer(dossier, this.roomChatConn, this.dummyRoomStatusNotifier)
                    this.playerContainer.appendChild(player.element)
                    player.updateHeight()
                    player.startViewing()
                    this.numCams += 1
                    this.height = this.element.getBoundingClientRect().height
                    this.recalcPosition()
                    addPageAction("SharedCamViewStarted", { "cam_user": username, "num_cams_open": this.numCams })

                    // Only set to active once video playback starts
                    this.setCamState(username, ShowMyCamState.Inactive, player)
                    const connectionTimeout = window.setTimeout(() => {
                        const cam = this.cams.get(username)
                        if (cam?.player !== undefined) {
                            this.stopViewingCam(username)
                        }
                        playbackStartListener.removeListener()
                        addPageAction("SharedCamViewStartFailed", { "cam_user": username })
                    }, (jpegFallbackSeconds - 1) * 1000)
                    const playbackStartListener = player.playerComponent.playbackStart.once(() => {
                        // Ensure that this listener is still valid. Stopping and removing a cam player does not
                        // necessarily stop the video element itself, so playbackStart could still fire.
                        const cam = this.cams.get(username)
                        if (cam !== undefined && player === cam.player) {
                            this.setCamState(username, ShowMyCamState.Active, player)
                            enterSmcWatcherPresence(dossier.roomUid)
                            this.viewCamFailedListeners.get(username)?.removeListener()
                            const listener = new SMCPresenceTopic(dossier.roomUid).onAuthFail.listen(() => {
                                if (this.getCamState(username) === ShowMyCamState.Active) {
                                    this.stopViewingCam(username)
                                    modalAlert(i18n.showMyCamCouldNotView)
                                }
                            })
                            this.viewCamFailedListeners.set(username, listener)
                            clearTimeout(connectionTimeout)
                        }
                    })
                    const offlineListener = player.playerComponent.videoOfflineChange.listen((offline) => {
                        if (offline) {
                            this.stopViewingCam(username)
                            offlineListener.removeListener()
                        }
                    })
                }).catch(ignoreCatch)
            } else {
                this.setCamState(username, ShowMyCamState.None)
                modalAlert(i18n.showMyCamCamOffline)
                addPageAction("SharedCamViewStartFailed", { "cam_user": username })
            }
        }).catch(ignoreCatch)
    }

    private stopViewingCam(username: string): void {
        this.viewCamFailedListeners.get(username)?.removeListener()
        const camState = this.getCamState(username)
        const cam = this.cams.get(username)
        scheduleSentimentSurvey(false, SentimentSurveyType.SMC)
        if (cam !== undefined && cam.player !== undefined) {
            cam.player.stopViewing()
            this.setCamState(username, ShowMyCamState.Inactive)
            const player = cam.player
            // delay hiding manager until reportMenu is closed
            if (cam.player.reportMenu !== undefined) {
                cam.player.reportMenu.closeChatReportRequest.once(() => {
                    this.cleanUpCam(username, player)
                })
            } else {
                this.cleanUpCam(username, player)
            }
        } else {
            warn("stopViewingCam no player for cam", {
                "camUser": username,
                "state": camState,
            })
        }
    }

    private cleanUpCam(username: string, player: ShowMyCamPlayer): void {
        player.element.style.height = "0"
        player.element.style.width = "0"
        this.numCams -= 1
        this.height = this.element.getBoundingClientRect().height
        this.recalcPosition()
        if (this.numCams === 0) {
            this.element.style.display = "none"
        }
        addPageAction("SharedCamPlayerRemoved", { "cam_user": username, "num_cams_open": this.numCams })
    }

    private forEachPlayer(callback: (username: string, player: ShowMyCamPlayer) => void): void {
        for (const username of this.cams.keys()) {
            const cam = this.cams.get(username)
            if (cam !== undefined && cam.player !== undefined) {
                callback(username, cam.player)
            }
        }
    }

    private stopAllCams(): void {
        this.forEachPlayer((username, player) => {
            this.stopViewingCam(username)
        })
    }

    private endAllCamsExcept(exception: string): void {
        for (const username of this.cams.keys()) {
            if (username === exception) {
                continue
            }

            const cam = this.cams.get(username)
            if (cam !== undefined && cam.player !== undefined) {
                this.stopViewingCam(username)
            }
            this.setCamState(username, ShowMyCamState.None)
        }
    }

    private monitorInactive(): void {
        // Make sure cams are still live. If a cam crashed it wouldn't get a chance to hit api/ts/chat/show-cam/
        for (const username of this.cams.keys()) {
            const camState = this.getCamState(username)
            if (camState !== ShowMyCamState.None) {
                checkOnline(username).then((online) => {
                    if (!online) {
                        const cam = this.cams.get(username)
                        if (cam?.player !== undefined) {
                            this.stopViewingCam(username)
                        }
                        this.setCamState(username, ShowMyCamState.None)
                    }
                }).catch(ignoreCatch)
            }
        }
    }

    private getCamState(username: string): ShowMyCamState {
        const cam = this.cams.get(username)
        return cam !== undefined ? cam.state : ShowMyCamState.None
    }

    private setCamState(username: string, state: ShowMyCamState, player?: ShowMyCamPlayer): void {
        const camUserInfo = this.camUserInfos.get(username)
        const prevState = this.getCamState(username)
        switch (state) {
            case ShowMyCamState.None:
                this.cams.delete(username)
                this.camUserInfos.delete(username)
                if (prevState !== ShowMyCamState.None) {
                    addPageAction("SharedCamViewerBroadcastStopped", { "cam_user": username, "num_cams_total": this.cams.size })
                }
                break
            case ShowMyCamState.Inactive:
                this.cams.set(username, { state: ShowMyCamState.Inactive, player: player })
                if (prevState === ShowMyCamState.None) {
                    addPageAction("SharedCamViewerBroadcastStarted", { "cam_user": username, "num_cams_total": this.cams.size })
                }
                break
            case ShowMyCamState.Active:
                if (player !== undefined) {
                    this.cams.set(username, { state: ShowMyCamState.Active, player: player })
                } else {
                    error("smcBroadcaster - no player provided for active cam")
                }
                break
        }
        this.updateChatBadges(username)
        if (camUserInfo !== undefined) {
            this.sendChatNoticeForStateChange(camUserInfo, prevState, state)
        }
    }

    private sendChatNoticeForStateChange(camUserInfo: IUserInfo, prevState: ShowMyCamState, newState: ShowMyCamState): void {
        if (prevState === newState) {
            return
        }

        let chatNotice: string | undefined
        if (prevState === ShowMyCamState.None && newState !== ShowMyCamState.None) {
            chatNotice = this.getCamStartNotice(camUserInfo.username)
        }

        if (chatNotice !== undefined) {
            const userType = getUserType(this.roomChatConn.room(), camUserInfo)
            this.roomChatConn.event.roomNotice.fire({
                messages: [[
                    stringPart(userType === "User " ? i18n.showMyCamSharingPrefix : userType),
                    userPart(camUserInfo),
                    stringPart(chatNotice),
                ]],
                showInPrivateMessage: false,
            })
        }
    }

    private getCamStartNotice(username: string): string | undefined {
        const noticeRateLimit = this.noticeRateLimits.get(username)
        if (noticeRateLimit?.preventStartNotice === true) {
            return undefined
        }
        this.setNoticeRateLimit(username)
        return i18n.showMyCamStartedSharing
    }

    private setNoticeRateLimit(username: string): void {
        let preventStartNotice, timeout
        switch (this.noticeRateLimitSetting) {
            case C2CNotificationLimit.None:
                preventStartNotice = false
                break
            case C2CNotificationLimit.FiveMinutes:
                preventStartNotice = true
                timeout = window.setTimeout(() => this.unsetNoticeRateLimit(username), 5 * 60 * 1000)
                break
            case C2CNotificationLimit.Forever:
                preventStartNotice = true
                break
            default:
                error("smcBroadcaster - invalid rate limit setting", this.noticeRateLimitSetting)
                preventStartNotice = false
        }
        this.noticeRateLimits.set(username, { preventStartNotice, timeout })
    }

    private unsetNoticeRateLimit(username: string): void {
        const noticeRateLimit = this.noticeRateLimits.get(username)
        if (noticeRateLimit === undefined) {
            error("smcBroadcaster - no notice rate limit to unset")
            return
        }
        window.clearTimeout(noticeRateLimit.timeout)
        this.noticeRateLimits.set(username, { preventStartNotice: false })
    }

    public getContextMenuLinkInfo(username: string): { linkText: string, icon: string, action: () => void } {
        const camState = this.getCamState(username)

        if (camState === ShowMyCamState.None) {
            return { linkText: "", icon: "", action: () => {} }
        }

        const cam = this.cams.get(username)
        let text, icon, action
        if (camState === ShowMyCamState.Inactive) {
            icon = `${STATIC_URL_ROOT}broadcastassets/inactive-cam.svg`
        } else {
            icon = `${STATIC_URL_ROOT}broadcastassets/active-cam.svg`
        }
        if (cam !== undefined && cam.player !== undefined) {
            text = i18n.showMyCamStopView
            action = () => {
                addPageAction("SharedCamViewStopClicked", { "cam_user": username })
                this.stopViewingCam(username)
            }
        } else {
            text = i18n.showMyCamView
            action = () => {
                addPageAction("SharedCamViewStartClicked", { "cam_user": username })
                this.startViewingCam(username)
            }
        }
        return { linkText: text, icon: icon, action: action }
    }

    public createChatBadge(username: string): HTMLImageElement {
        const camState = this.getCamState(username)
        const badge = document.createElement("img")
        badge.style.marginRight = ".2em"
        badge.style.height = "1.2em"
        badge.style.position = "relative"
        badge.title = i18n.sharingCam
        if (camState === ShowMyCamState.None) {
            badge.style.display = "none"
        } else if (camState === ShowMyCamState.Inactive) {
            badge.src = `${STATIC_URL_ROOT}broadcastassets/inactive-cam.svg`
        } else {
            badge.src = `${STATIC_URL_ROOT}broadcastassets/active-cam.svg`
        }
        return badge
    }

    private updateChatBadges(username: string): void {
        const camState = this.getCamState(username)
        const badges = ChatBadgeManager.getBadgeElements(BadgeType.SMC, username) as HTMLImageElement[]
        let src = ""
        if (camState === ShowMyCamState.Inactive) {
            src = `${STATIC_URL_ROOT}broadcastassets/inactive-cam.svg`
        } else if (camState === ShowMyCamState.Active) {
            src = `${STATIC_URL_ROOT}broadcastassets/active-cam.svg`
        }

        // Iterate in reverse so applies to visible messages first.
        for (let i = badges.length - 1; i >= 0; i -= 1) {
            const badge = badges[i]
            badge.src = src
            if (src !== "") {
                badge.style.display = ""
            } else {
                badge.style.display = "none"
            }
        }
    }
}

class ShowMyCamPlayer extends BasePlayer {
    public readonly username: string
    private dragging = false
    private usernameBar: HTMLDivElement
    private controls: HTMLDivElement
    private controlsFrozen: boolean
    private reportDiv: HTMLDivElement
    public reportMenu: ChatReport | undefined
    private hideAllTooltips = new EventRouter<boolean>("hideAllTooltips")
    private popoutWindow: Window | undefined
    private chatConn: ChatConnection // TODO delete once SMC3 is live

    constructor(private camDossier: IRoomDossier, private roomChatConn: IChatConnection, roomStatusNotifier: RoomStatusNotifier) {
        super(true, roomStatusNotifier)
        this.element.dataset.testid = "cam-to-cam-broadcast-panel"
        this.playerComponent.element.dataset.testid = "cam-to-cam-video"
        this.watermark.style.visibility = "hidden"

        this.element.style.position = "relative"
        this.element.style.width = "100%"
        this.element.style.minWidth = `${CAM_MIN_WIDTH}px`
        this.element.style.backgroundColor = "#000"
        this.element.style.border = "1px solid #FFF"
        this.element.style.marginTop = "-1px"
        this.element.style.overflow = "visible"

        addEventListenerPoly("mouseenter", this.element, (event) => {
            this.controls.style.display = "block"
        })
        addEventListenerPoly("mouseleave", this.element, (event) => {
            if (!this.controlsFrozen) {
                this.controls.style.display = "none"
            }
        })

        let touchCount = 0
        addEventListenerPoly("touchstart", this.element, (event) => {
            if (this.controls.style.display === "none") {
                this.controls.style.display = "block"
                touchCount = 1
            } else {
                touchCount += 1
            }
        })
        addEventListenerPoly("touchend", this.element, (event) => {
            // Timeout to wait for drag listener on parent to potentially set this.dragging
            window.setTimeout(() => {
                if ((touchCount > 1 || this.dragging) && !this.controlsFrozen) {
                    this.controls.style.display = "none"
                }
            }, 0)
        })
        addEventListenerPoly("touchstart", document, (event) => {
            if (!this.element.contains((event.target as HTMLElement)) && !this.controlsFrozen) {
                this.controls.style.display = "none"
            }
        })

        this.username = camDossier.room
        this.element.appendChild((this.createUsernameBar()))
        this.element.appendChild(this.createControls())
        this.element.appendChild(this.createReportDiv())
    }

    public startViewing(): void {
        this.chatConn =  new ChatConnection(this.camDossier)
        this.playerComponent.handleRoomLoaded({ dossier: this.camDossier, chatConnection: this.chatConn })
    }

    // Do not call this directly, instead use stopViewingCamRequest or ShowMyCamManager.stopViewingCam
    public stopViewing(): void {
        this.playerComponent.stop()
        if (this.popoutWindow !== undefined && !this.popoutWindow.closed) {
            this.popoutWindow.close()
        }

        // TODO Backend code still requires leaving the cam chatconnection. Remove this when that code is removed once SMC3 is live
        this.chatConn.leaveRoom()

        // TODO replace presence
        leaveSmcWatcherPresence(this.camDossier.roomUid)
        if (this.reportMenu !== undefined) {
            if (this.usernameBar?.firstChild !== null) {
                this.usernameBar.firstChild.textContent += ` ${i18n.showMyCamConnectionClosed}`
            }
            this.reportMenu.closeChatReportRequest.once(() => {
                this.attemptRemoveFromDom()
            })
        } else {
            this.attemptRemoveFromDom()
        }
    }

    public setDragging(dragging: boolean): void {
        this.dragging = dragging
    }

    private reportCam(): void {
        const newReportMenu = new ShowMyCamReport(this, this.username, this.roomChatConn)
        newReportMenu.closeChatReportRequest.listen((wasFormSubmitted) => {
            if (this.reportMenu !== undefined) {
                this.reportDiv.removeChild(this.reportMenu.element)
                this.reportMenu.tearDown()
                this.reportMenu = undefined
            }
            if (wasFormSubmitted) {
                addPageAction("SharedCamReportSent", { "cam_user": this.username })
                this.attemptRemoveFromDom()
            } else {
                this.reportDiv.style.display = "none"
                this.controls.style.display = "none"
                this.hideAllTooltips.fire(true)
                this.freezeControls(false)
            }
        })

        this.freezeControls(true)
        this.reportDiv.appendChild(newReportMenu.element)
        this.reportMenu = newReportMenu
        this.reportDiv.style.display = "block"
        this.reportMenu.focusForm()
    }

    private freezeControls(freeze: boolean): void {
        this.controlsFrozen = freeze
        // Freeze button tooltips too
        if (freeze) {
            addEventListenerPoly("mouseenter", this.element, this.blockMouseEvent, true)
            addEventListenerPoly("mouseleave", this.element, this.blockMouseEvent, true)
        } else {
            removeEventListenerPoly("mouseenter", this.element, this.blockMouseEvent, true)
            removeEventListenerPoly("mouseleave", this.element, this.blockMouseEvent, true)
        }
    }

    private blockMouseEvent = (event: MouseEvent) => {
        event.stopPropagation()
    }

    private popoutCam(): void {
        if (this.popoutWindow !== undefined && !this.popoutWindow.closed) {
            this.popoutWindow.focus()
            return
        }

        const height = 768
        const width = 850
        const left = (screen.width / 2 - width / 2).toString()
        const top = (screen.height / 2 - height / 2).toString()
        const windowOptions = `resizable,dependent,scrollbars,height=${height},width=${width},top=${top},left=${left}`
        safeWindowOpenWithReturn(`/popout/${this.username}`, "_blank", windowOptions).then((window) => {
            this.popoutWindow = window
        }).catch(ignoreCatch)
    }

    private attemptRemoveFromDom(): void {
        if (this.element.parentElement !== null) {
            this.element.parentElement.removeChild(this.element)
        }
    }

    private createUsernameBar(): HTMLDivElement {
        const usernameBar = document.createElement("div")
        usernameBar.style.position = "absolute"
        usernameBar.style.bottom = "0px"
        usernameBar.style.width = "100%"
        usernameBar.style.height = "auto"
        usernameBar.style.minHeight = "20px"
        usernameBar.style.maxHeight = "40px"
        usernameBar.style.color = "#FFF"
        usernameBar.style.backgroundColor = "rgba(0, 0, 0, 0.4)"
        usernameBar.style.zIndex = `${baseZIndex}`

        const text = document.createElement("span")
        text.style.zIndex = `${baseZIndex}`
        text.textContent = this.username
        text.style.fontFamily = "UbuntuRegular"
        text.style.fontSize = "12px"
        text.style.color = "#EEE"
        text.style.position = "relative"
        text.style.left = "5px"
        text.style.bottom = "-3px"
        usernameBar.appendChild(text)
        this.usernameBar = usernameBar

        return usernameBar
    }

    private createControls(): HTMLDivElement {
        const controls = createCornerViewerControlsContainer()

        const stopButton = createCornerViewerButton({ iconAsset: "broadcastassets/close.svg",
                                                            tooltipText: i18n.showMyCamCloseCam,
                                                            iconSize: 23,
                                                            centerTop: "50%",
                                                            onClick: () => {
                                                                if (!this.controlsFrozen && !this.dragging) {
                                                                    addPageAction("SharedCamViewStopClicked", { "cam_user": this.username })
                                                                    stopViewingCamRequest.fire(this)
                                                                }
                                                            },
                                                            hideAllTooltips: this.hideAllTooltips })
        stopButton.style.left = "50%"
        stopButton.style.transform = "translateX(-50%) translateX(-50px)"
        controls.appendChild(stopButton)

        const popoutButton = createCornerViewerButton({ iconAsset: "tsdefaultassets/smc_popout.svg",
                                                              tooltipText: i18n.showMyCamPopoutCam,
                                                              iconSize: 23,
                                                              centerTop: "50%",
                                                              onClick: () => {
                                                                  if (!this.controlsFrozen && !this.dragging) {
                                                                      this.popoutCam()
                                                                  }
                                                              },
                                                              hideAllTooltips: this.hideAllTooltips })
        popoutButton.style.left = "50%"
        popoutButton.style.transform = "translateX(-50%)"
        controls.appendChild(popoutButton)

        const reportButton = createCornerViewerButton({ iconAsset: "broadcastassets/report.svg",
                                                              tooltipText: i18n.showMyCamReportCam,
                                                              iconSize: 23,
                                                              centerTop: "50%",
                                                              onClick: () => {
                                                                  if (!this.controlsFrozen && !this.dragging) {
                                                                      this.reportCam()
                                                                  }
                                                              },
                                                              hideAllTooltips: this.hideAllTooltips })
        reportButton.style.left = "50%"
        reportButton.style.transform = "translateX(-50%) translateX(50px)"
        controls.appendChild(reportButton)

        this.controls = controls
        return controls
    }

    private createReportDiv(): HTMLDivElement {
        const reportDiv = document.createElement("div")
        addColorClass(reportDiv, "reportDiv")
        reportDiv.style.width = "207px"
        reportDiv.style.borderWidth = "1px"
        reportDiv.style.borderStyle = "solid"
        reportDiv.style.position = "absolute"
        reportDiv.style.transform = "translate(-100%, -100%) translateX(-5px)"
        reportDiv.style.borderRadius = "4px"
        reportDiv.style.display = "none"
        reportDiv.style.zIndex = "999"
        reportDiv.style.fontWeight = "normal"
        reportDiv.style.fontSize = "12px"
        reportDiv.onmouseenter = () => {
            reportDiv.style.cursor = "default"
        }
        this.reportDiv = reportDiv
        return reportDiv
    }

    public updateHeight(): void {
        this.element.style.height = `${this.element.offsetWidth * CAM_RATIO}px`
    }

    public positionChanged(): void {
        if (this.element.getBoundingClientRect().left - this.reportDiv.offsetWidth < 0) {
            this.reportDiv.style.transform = `translateY(-100%) translateX(${this.element.offsetWidth + 5}px)`
        } else {
            this.reportDiv.style.transform = "translate(-100%, -100%) translateX(-5px)"
        }
    }
}

class ShowMyCamReport extends ChatReport {
    constructor(private cam: ShowMyCamPlayer, protected username: string, protected chatConnection: IChatConnection) {
        super(username, undefined, chatConnection)

        // Stop propagation to drag listener
        addEventListenerPoly("touchstart", this.element, (event) => {
            event.stopPropagation()
        })
        addEventListenerPoly("mousedown", this.element, (event) => {
            event.stopPropagation()
        })
    }

    protected categories(): ICategories {
        return {
            "public": i18n.showMyCamReportPublic,
            "rude": i18n.showMyCamReportRude,
            "intoxicated": i18n.showMyCamReportIntoxicated,
            "sleeping": i18n.showMyCamReportSleeping,
            "spam": i18n.showMyCamReportSpamming,
            "underage": i18n.showMyCamReportUnderage,
            "other": i18n.reportMessageOther,
        }
    }

    protected reportEndpoint(): string {
        return `abuse/report/${this.username}/`
    }

    protected additionalFinishReportLinks(): HTMLButtonElement[] {
        const room = this.chatConnection.room()
        const silenceUserLink = document.createElement("button")
        const banUserLink = document.createElement("button")

        addColorClass(silenceUserLink, colorClass.hrefColor)
        addColorClass(banUserLink, colorClass.hrefColor)
        silenceUserLink.textContent = i18n.silenceDurationMessage
        banUserLink.textContent = i18n.kickBan
        banUserLink.dataset.testid= "kick-ban"

        silenceUserLink.onclick = (event: Event) => {
            event.preventDefault()
            postCb(`roomsilence/${this.username}/${room}/`, {}).catch((err) => {
                error("smcBroadcaster Unable to silence user", {
                    "room": room,
                    "username": this.username,
                    "reason": err.toString(),
                })
                modalAlert(`Error silencing user ${this.username}`)
            })
            silenceUserLink.style.color = ""
            silenceUserLink.style.cursor = ""
            silenceUserLink.onclick = () => {}
        }

        banUserLink.onclick = (event: Event) => {
            event.preventDefault()
            postCb(`roomban/${this.username}/${this.chatConnection.room()}/`, {}).catch((err) => {
                error("smcBroadcaster Unable to ban user",{
                    "room": room,
                    "username": this.username,
                    "reason": err.toString(),
                })
                modalAlert(i18n.banUserError(this.username))
            })
            silenceUserLink.style.color = ""
            silenceUserLink.style.cursor = ""
            silenceUserLink.onclick = () => {}
            banUserLink.style.color = ""
            banUserLink.style.cursor = ""
            banUserLink.onclick = () => {}
        }

        return [silenceUserLink, banUserLink]
    }

    protected onChatReportClosed(wasFormSubmitted: boolean): void {
        super.onChatReportClosed(wasFormSubmitted)
        if (wasFormSubmitted) {
            stopViewingCamRequest.fire(this.cam)
        }
    }
}

// Make sure cams still know the broadcaster is viewing them if the broadcaster is viewing through the cam's room page
let hasExecuted = false
export function smcOnRoomPlaybackStart(roomDossier: IRoomDossier, playerComponent: IPlayer): void {
    if (hasExecuted) {
        return
    }
    hasExecuted = true

    if (roomDossier.roomUid !== "") {
        new SMCPresenceTopic(roomDossier.roomUid).onAuthFail.listen(() => {
            playerComponent.stop()
            modalAlert(i18n.showMyCamCouldNotView)
            leaveSmcWatcherPresence(roomDossier.roomUid)
        })
    }

    if (pageContext.current?.loggedInUser?.isBroadcasting === true) {
        // Clear any current smc presence first, in case of loading a new room on the same page
        leaveSmcWatcherPresence()
        getCb("api/ts/chat/share-my-cam/").then((xhr) => {
            const response = new ArgJSONMap(xhr.responseText)
            const sharedCams = response.getList("shared_rooms")
            if (sharedCams !== undefined) {
                for (const camData of sharedCams) {
                    if (camData.getString("username") === roomDossier.room) {
                        enterSmcWatcherPresence(roomDossier.roomUid)
                    }
                }
            }
        }).catch(ignoreCatch)
    }
}
