import { getCb, postCb } from "../../../common/api"


export class PromotionApi {
    static promotionPrice(query: string = ""): Promise<XMLHttpRequest> {
        return getCb(`promotion/api/promote_price/?${query}`, { timeout: 20000 })
    }

    static promotionState(): Promise<XMLHttpRequest> {
        return getCb("promotion/api/promotion_info/")
    }

    static submitPromotion(formData: FormData): Promise<XMLHttpRequest> {
        return postCb("promotion/api/purchase_promotion/", formData)
    }

    static promotionReport(): Promise<XMLHttpRequest> {
        return getCb("promotion/api/promotion_report/")
    }
}

export enum EligibilityStatus {
    ELIGIBLE = "ELIGIBLE",
    ACCOUNT_INELIGIBLE = "ACCOUNT_INELIGIBLE",
    PURCHASER_INELIGIBLE = "PURCHASER_INELIGIBLE",
    ROOM_STATUS_OFFLINE = "ROOM_STATUS_OFFLINE",
    PENDING_START = "PENDING_START",
    PENDING_FINISH = "PENDING_FINISH",
    ACTIVE = "ACTIVE",
}
