import { addEventListenerMultiPoly } from "./addEventListenerPolyfill"
import { normalizeResource } from "./api"
import { roomLoaded } from "./context"
import { getEligibleTargetAnchor } from "./DOMutils"
import type { IRoomContext } from "./context"

export interface IRedirectInfo {
    nextParam?: string
    roomParam?: string
}

const ROOM_REDIRECT_PARAM_NAME = "room"
const REDIRECT_PARAM_NAME = "next"
export const SIGN_UP_BASE_PATH = "/accounts/register/"
export const LOGIN_BASE_PATH = "/auth/login/"
export const REDIRECT_ATTRIBUTE = "account-redirect"

interface IRoomParamOptions {
    room?: string  // An optional parameter representing a broadcaster room to redirect users to
    ignoreExistingRoom?: boolean  // If set to true, disregard check for pre-existing "room" param
}
export function getRedirectInfo(roomParamOptions: IRoomParamOptions): IRedirectInfo {
    /**
     * Returns information regarding how we should handle redirecting users. If a search param with key REDIRECT_PARAM_NAME is found, this will always take precedence over a provided room value.
     *
     * @param {IRoomParamOptions} roomParamOptions - Options for how we want to handle the ROOM_REDIRECT_PARAM_NAME param
     */
    const queryParams = new URLSearchParams(window.location.search)

    const redirectParam = queryParams.get(REDIRECT_PARAM_NAME)
    if (redirectParam !== null) {
        return {
            nextParam: redirectParam,
        }
    }

    const roomRedirectParam = roomParamOptions.room !== undefined
        ? roomParamOptions.room : (roomParamOptions.ignoreExistingRoom === true
            ? undefined : queryParams.get(ROOM_REDIRECT_PARAM_NAME))

    if (roomRedirectParam !== null && roomRedirectParam !== undefined) {
        return {
            roomParam: roomRedirectParam,
        }
    }

    // Avoid providing redirect param if we are on the homepage with no additional search params, the login page, or sign up page
    if (!([SIGN_UP_BASE_PATH, LOGIN_BASE_PATH].includes(window.location.pathname) ||
        (CHATURBATE_URL_PREFIX === window.location.pathname && window.location.search === ""))) {
        return {
            nextParam: getCurrentPathWithSearchQuery(),
        }
    }
    return {}
}

export function getCurrentPathWithSearchQuery(): string {
    return `${window.location.pathname}${window.location.search}`
}

export function getRelativePathWithRedirectQuery(relativeUrl: string, redirectInfo: IRedirectInfo): string {
    /**
     * Returns a path with appropriate redirect search parameters.
     *
     * @param {string} relativeUrl - A url pathname
     * @param {IRedirectInfo} redirectInfo - Information regarding set values for REDIRECT_PARAM_NAME and ROOM_REDIRECT_PARAM_NAME
     */
    const url = new URL(relativeUrl, window.location.origin)

    url.searchParams.delete(ROOM_REDIRECT_PARAM_NAME)
    url.searchParams.delete(REDIRECT_PARAM_NAME)

    if (redirectInfo.nextParam !== undefined) {
        url.searchParams.set(REDIRECT_PARAM_NAME, redirectInfo.nextParam)
    } else if (redirectInfo.roomParam !== undefined) {
        url.searchParams.set(ROOM_REDIRECT_PARAM_NAME, redirectInfo.roomParam)
    }

    return `${url.pathname}${url.search}`
}

export function watchForAccountRedirectNavigation(): void {
    /**
     * Sets up listeners for handling sign up and login pages that should include redirect information in the search param.
     */
    let room: string

    roomLoaded.listen((roomContext: IRoomContext) => {
        room = roomContext.dossier.room
    })

    addEventListenerMultiPoly(["mousedown", "touchstart"], window.document, (event: MouseEvent) => {
        const anchorTarget = getEligibleTargetAnchor(event.target)

        if (anchorTarget !== undefined && anchorTarget.hasAttribute(REDIRECT_ATTRIBUTE)) {
            let roomOptions: IRoomParamOptions = {}
            if (new URL(anchorTarget.href, window.location.origin).pathname === normalizeResource(SIGN_UP_BASE_PATH)) {
                // "room" query param is only valid for registering accounts
                roomOptions = { room: room }
            }
            const redirectInfo = getRedirectInfo(roomOptions)
            anchorTarget.href = normalizeResource(getRelativePathWithRedirectQuery(anchorTarget.href, redirectInfo))
        }
    })
}

export function addAccountRedirectAttribute(anchorEl: HTMLAnchorElement): void {
    anchorEl.setAttribute(REDIRECT_ATTRIBUTE, "") // eslint-disable-line @multimediallc/no-set-attribute
}

export function updateFormRedirectUrl(form: HTMLFormElement, redirectUrl?: string): void {
    if (redirectUrl === undefined) {
        redirectUrl = `${window.location.pathname}${window.location.search}`
    }
    const inputEl = form.querySelector(`input[name=${REDIRECT_PARAM_NAME}]`) as HTMLInputElement
    if (inputEl !== null) {
        inputEl.value = redirectUrl
    }
}
