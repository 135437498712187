import { dmsEnabled } from "../cb/api/pm"
import { addColorClass, removeColorClass } from "../cb/colorClasses"
import { isShowingDMUserContextMenu } from "../cb/components/pm/dmUserContextMenu"
import { createDmWindowRequest } from "../cb/components/pm/dmWindowsManager"
import { ReactComponentRegistry } from "../cb/components/ReactRegistry"
import { pageContext, roomDossierContext } from "../cb/interfaces/context"
import { resizeDebounceEvent } from "../cb/ui/responsiveUtil"
import { createFollowerStar } from "../cb/ui/svg/followerStar"
import { buildTooltip } from "../cb/ui/tooltip"
import { addEventListenerPoly, removeEventListenerPoly } from "./addEventListenerPolyfill"
import { modalAlert, modalConfirm } from "./alerts"
import { normalizeResource, postCb } from "./api"
import { isNotLoggedIn } from "./auth"
import { ChatReport } from "./chatReport"
import { roomLoaded } from "./context"
import { DivotPosition } from "./divot"
import { getFixedOffset, hoverEvent } from "./DOMutils"
import { type BoundListener, ListenerGroup } from "./events"
import { fetchUserMenuData } from "./fetchUserMenuData"
import { RoomFollowersManager } from "./followers"
import { fullscreenChange, fullscreenElement, isFullscreen } from "./fullscreen"
import { loadRoomRequest } from "./fullvideolib/userActionEvents"
import { addPageAction } from "./newrelic"
import { UserNotesBadgeManager } from "./notes"
import { OverlayComponent } from "./overlayComponent"
import {
    getGenderIconUrl,
    getUserContextMenuSubText,
    getUserContextMenuText,
    getUsernameColorClass,
} from "./roomUtil"
import { loginOverlayRequest } from "./theatermodelib/userActionEvents"
import { i18n } from "./translation"
import { mentionUser, userInitiatedPm } from "./userActionEvents"
import Key = JQuery.Key
import type { IChatConnection } from "./context"
import type { IMenuData } from "./fetchUserMenuData"
import type { IRoomMessage, IUserInfo } from "./messageInterfaces"
import type { ReactComponent } from "../cb/components/ReactRegistry"

export function isShowingUserContextMenu(): boolean {
    const UCMState = UserContextMenu.state
    return UCMState === UserMenuStates.Loading || UCMState === UserMenuStates.Visible
}

export function isShowingAnyUserContextMenu(): boolean {
    return isShowingUserContextMenu() || isShowingDMUserContextMenu()
}

export const enum UserMenuStates {
    Loading = 0,
    Visible = 1,
    Down = 2,
    Disabled = 3,
}

export function disableUserMenu(): void {
    UserContextMenu.setUserContextMenuState(UserMenuStates.Disabled)
}

const UCM_ZINDEX = 1010

export class UserContextMenu extends OverlayComponent {
    public static state = UserMenuStates.Down
    private static ucmContainer?: HTMLElement
    public static setUCMContainer(container: HTMLElement): void {
        UserContextMenu.ucmContainer = container
    }
    private age: HTMLSpanElement
    private genderSpan: HTMLSpanElement
    private genderIcon: HTMLImageElement
    protected menuLinks: HTMLDivElement
    protected menuContents: HTMLDivElement
    protected modSection: HTMLDivElement
    protected data: IMenuData | undefined
    protected roomName: string | undefined
    protected currentUsername: string | undefined
    protected chatConn: IChatConnection | undefined
    private chatReport: ChatReport | undefined
    private name: HTMLElement
    private showSupporterAlert: boolean
    private clickTargetEl: HTMLElement
    private parentEl: HTMLElement
    private ucmListenerGroup = new ListenerGroup()
    private reactUserNote: ReactComponent | undefined
    private fullscreenChangeListener: BoundListener<void> | undefined

    constructor(protected user: IUserInfo, clickTargetEle: HTMLElement, private message?: IRoomMessage) {
        super()

        this.clickTargetEl = clickTargetEle
        this.parentEl = UserContextMenu.ucmContainer ?? clickTargetEle.offsetParent as HTMLElement

        this.createPlaceholder()

        addEventListenerPoly("keydown", document, this.tearDownListener)
        this.fullscreenChangeListener = fullscreenChange.listen(this.hideOverlayListener)
        addEventListenerPoly("popstate", window, this.hideOverlayListener)
        loginOverlayRequest.listen(this.hideOverlayListener, false).addTo(this.ucmListenerGroup)
        loadRoomRequest.listen(this.hideOverlayListener, false).addTo(this.ucmListenerGroup)
        let previousWidth = window.innerWidth
        resizeDebounceEvent.listen(() => {
            if (window.innerWidth < previousWidth) {
                this.hideOverlayListener()
            }
            previousWidth = window.innerWidth
        }).addTo(this.ucmListenerGroup)

        this.parentEl.appendChild(this.element)
        this.afterDOMConstructedIncludingChildren()

        this.overlayClick.listen(() => {
            this.hideOverlay()
        })
        super.showOverlay()
        this.loadData()
    }

    public getUsername(): string {
        return this.user.username
    }

    public static setUserContextMenuState(state: number): void {
        UserContextMenu.state = state
    }

    // eslint-disable-next-line complexity
    private tearDownListener = (event: KeyboardEvent): void => {
        const ctrlKey = event.ctrlKey || event.metaKey
        const isTab = event.keyCode === Key.Tab
        const isCtrlTipping = ctrlKey && event.keyCode === 83 // Ctrl or Command + S(83): tip
        const isNextCam = ctrlKey && event.keyCode === 191 // Ctrl or Command + /(191): next camera
        const isSlashTipping = event.keyCode === 191 // /tip xx
        const isClosingPM = ctrlKey && event.keyCode === 76
        const escKey = event.keyCode === 27
        if (isTab || isCtrlTipping || isNextCam || isClosingPM || escKey || isSlashTipping) { // eslint-disable-line complexity
            this.hideOverlay()
        }
    }

    private hideOverlayListener = (): void => {
        this.hideOverlay()
    }

    private createPlaceholder(): void {
        this.initializeMenu()
        this.addHeader()
        this.addUserLabels()
        this.addRestMenu()

        this.addHeaderLeftData()
        this.addLinksData()
    }

    private loadData(): void {
        UserContextMenu.setUserContextMenuState(UserMenuStates.Loading)
        this.position(true)
        fetchUserMenuData(this.user.username).then((data) => {
            if (UserContextMenu.state === UserMenuStates.Down) {
                return
            }
            this.fillData(data)
            this.position()
        }).catch((err) => {
            error("Could not process user menu request", {
                "username": this.user.username,
                "reason": err.toString(),
            })
        })
    }

    private fillData(data: IMenuData): void {
        if (this.data !== undefined) {
            return
        }
        this.data = data
        this.showSupporterAlert = this.data.needsSupporterToPm
        this.addHeaderRightData()
        this.addSilenceIfAllowed()
        UserContextMenu.setUserContextMenuState(UserMenuStates.Visible)
    }

    private initializeMenu(): void {
        UserContextMenu.setUserContextMenuState(UserMenuStates.Loading)

        this.element = document.createElement("div")
        this.element.id = "user-context-menu"
        this.element.dataset.testid = "user-context-menu"

        this.menuContents = document.createElement("div")

        this.modSection = document.createElement("div")

        this.menuContents.style.backgroundColor = ""
        this.element.appendChild(this.menuContents)
        this.element.style.textShadow = "none"
        this.element.style.position = "absolute"
        this.element.style.borderRadius = "4px"
        this.element.style.borderStyle = "solid"
        this.element.style.borderWidth = "1px"
        this.element.style.top = "0"
        this.element.style.fontWeight = "normal"
        this.element.style.fontFamily = "Tahoma, Arial, Helvetica, sans-serif"
        this.element.style.fontSize = "12px"
        this.element.style.width = "188px"
        this.element.style.cursor = "default"
        this.element.style.visibility = "visible"
        this.element.style.zIndex = `${UCM_ZINDEX}`

        this.overlay.style.zIndex = `${UCM_ZINDEX}`

        roomLoaded.listen((context) => {
            this.roomName = context.dossier.room
            this.currentUsername = context.dossier.userName
            this.chatConn = context.chatConnection
        }).addTo(this.ucmListenerGroup)
    }

    private addLoadingStyle(ele: HTMLElement): void {
        addColorClass(ele, "ucmPlaceholder")
    }

    private removeLoadingStyle(ele: HTMLElement): void {
        removeColorClass(ele, "ucmPlaceholder")
    }

    private addHeader(): void {
        const header = document.createElement("div")
        addColorClass(header, "ucmHeader")
        header.style.padding = "9px 10px"
        header.style.fontWeight = "bold"
        header.style.borderTopLeftRadius = "4px"
        header.style.borderTopRightRadius = "4px"

        this.name = document.createElement("div")
        this.name.style.display = "inline-block"
        this.name.style.maxWidth = "100px"
        this.name.style.height = "12px"
        this.name.style.marginRight = "4px"
        header.appendChild(this.name)

        const isUserFollowing = RoomFollowersManager.getOrCreateInstance().hasFollower(this.user.username)
        if (pageContext.current.isBroadcast && isUserFollowing && this.user.username !== roomDossierContext.getState().room) {
            const followerStar = createFollowerStar()

            const tooltip = buildTooltip({
                content: i18n.followsYou,
                hasHTML: false,
                divotPosition: DivotPosition.Bottom,
                divotLeftOrTop: "8px",
            })
            tooltip.style.padding = "6px"
            tooltip.style.fontFamily = "UbuntuLight, Helvetica, Arial, sans-serif"
            tooltip.style.lineHeight = "normal"
            tooltip.style.position = "fixed"
            tooltip.style.display = "block"
            tooltip.style.width = "max-content"
            tooltip.style.zIndex = `${UCM_ZINDEX + 1}`
            hoverEvent(followerStar).listen(isHover => {
                if (isHover) {
                    document.body.appendChild(tooltip)
                } else {
                    tooltip.parentElement?.removeChild(tooltip)
                }
                const badgeBoundingRect = followerStar.getBoundingClientRect()
                const fixedOffset = getFixedOffset()
                tooltip.style.top = `${-fixedOffset.top + badgeBoundingRect.top - tooltip.offsetHeight - (badgeBoundingRect.height / 5) - 5}px`
                tooltip.style.left = `${-fixedOffset.left + badgeBoundingRect.left + (badgeBoundingRect.width / 2) - 18}px`
            })


            header.appendChild(followerStar)
        }

        const headerRight = document.createElement("div")
        headerRight.style.display = "inline-block"
        headerRight.style.width = "38px"
        headerRight.style.height = "12px"
        headerRight.style.position = "absolute"
        headerRight.style.right = "8px"

        this.age = document.createElement("span")
        addColorClass(this.age, "ucmAgespan")
        this.age.style.display = "inline-block"
        this.age.style.width = "15px"
        this.age.style.minHeight = "14px"
        this.age.style.fontSize = "12px"
        this.age.style.fontWeight = "bold"
        this.age.style.marginRight = "6px"
        this.age.style.verticalAlign = "top"
        headerRight.appendChild(this.age)
        this.addLoadingStyle(this.age)

        this.genderSpan = document.createElement("span")
        this.genderSpan.style.display = "inline-block"
        this.genderSpan.style.width = "15px"
        this.genderSpan.style.height = "14px"
        this.genderSpan.style.verticalAlign = "top"
        this.genderIcon = document.createElement("img")
        if (this.user.gender !== undefined) {
            this.genderIcon.src = getGenderIconUrl(this.user.gender)
            this.genderIcon.style.display = "inline-block"
            this.genderIcon.title = i18n.genderSymbolToIconTitle(this.user.gender)
        } else {
            this.addLoadingStyle(this.genderSpan)
            this.genderIcon.style.display = "none"
        }
        this.genderIcon.height = 14
        this.genderIcon.width = 14
        this.genderSpan.appendChild(this.genderIcon)
        headerRight.appendChild(this.genderSpan)

        header.appendChild(headerRight)

        this.menuContents.appendChild(header)
    }

    private addHeaderLeftData(): void {
        const nameLink = document.createElement("a")
        nameLink.innerText = this.user.username
        addColorClass(nameLink, getUsernameColorClass(this.user))
        nameLink.dataset.testid = "username"
        nameLink.style.fontWeight = "bold"
        nameLink.href = normalizeResource(`/${this.user.username}/`)
        nameLink.target = "_blank"
        nameLink.style.textDecoration = "none"
        nameLink.style.overflow = "hidden"
        nameLink.style.textOverflow = "ellipsis"
        nameLink.style.maxWidth = "100px"
        nameLink.style.display = "inline-block"
        nameLink.style.cursor = "pointer"
        nameLink.onmouseenter = () => {
            nameLink.style.textDecoration = "underline"
        }

        nameLink.onmouseleave = () => {
            nameLink.style.textDecoration = "none"
        }
        addEventListenerPoly("click", this.name, (event) => {
            addPageAction("ViewProfile", { "username": this.user.username })
            event.stopPropagation()
            this.hideOverlay()
        })
        this.name.appendChild(nameLink)
        this.overlay.style.background = "rgba(0, 0, 0, 0.01)" // IE10- requires a background to receive click events
        this.element.style.visibility = "visible"
    }

    private addHeaderRightData(): void {
        const userImgDiv = document.createElement("div")
        userImgDiv.style.width = "188px"
        userImgDiv.style.height = "75px"
        userImgDiv.style.overflow = "hidden"
        userImgDiv.style.borderRadius = "4px 4px 0px 0px"

        if (this.data?.online === true) {
            const userImg = document.createElement("img")

            addEventListenerPoly("load", userImg, () => {
                const imgContainerRect = userImgDiv.getBoundingClientRect()
                const imgRect = userImg.getBoundingClientRect()

                if (imgContainerRect.height < imgRect.height) {
                    userImg.style.position = "relative"
                    userImg.style.left = "0px"
                    userImg.style.top = `${(imgContainerRect.height - imgRect.height) / 2}px`
                }
            })

            userImg.src = this.data.imageUrl
            userImg.dataset.testid = "gender-icon"
            userImg.style.width = "100%"
            userImgDiv.appendChild(userImg)
            this.adjustForImg()
            this.element.insertBefore(userImgDiv, this.element.firstChild)
        }

        this.removeLoadingStyle(this.age)
        this.removeLoadingStyle(this.genderSpan)
        if (this.data) {
            this.genderIcon.src = getGenderIconUrl(this.data.gender)
            this.genderIcon.style.display = "inline-block"
            if (this.data.canAccess === true) {
                if (!isNaN(this.data.displayAge) && this.data.displayAge >= 18) {
                    this.age.innerText = this.data.displayAge.toString()
                }
                this.removeLoadingStyle(this.age)
            }
        }
    }

    private addUserLabels(): void {
        const labelText = getUserContextMenuText(this.user)
        const subLabelText = getUserContextMenuSubText(this.user)

        if (labelText !== undefined) {
            const userLabel = document.createElement("div")
            addColorClass(userLabel, "ucmUserLabel")
            userLabel.style.padding = "9px 10px"
            userLabel.style.borderTopWidth = "1px"
            userLabel.style.borderTopStyle = "solid"
            this.menuContents.appendChild(userLabel)

            const userLabelSpan = document.createElement("div")
            userLabelSpan.dataset.testid = "user-label"
            userLabelSpan.style.width = "74px"
            userLabelSpan.style.height = "12px"
            userLabelSpan.style.width = "100%"
            userLabelSpan.innerText = labelText
            addColorClass(userLabelSpan, getUsernameColorClass(this.user))
            userLabelSpan.style.fontWeight = "bold"
            userLabel.appendChild(userLabelSpan)

            if (subLabelText !== undefined) {
                const subLabelSpan = document.createElement("div")
                addColorClass(subLabelSpan, "ucmSublabel")
                subLabelSpan.style.height = "10px"
                subLabelSpan.style.fontSize = "10px"
                subLabelSpan.style.marginTop = "4px"
                subLabelSpan.style["-webkit-text-size-adjust"] = "none"
                subLabelSpan.innerText = subLabelText
                userLabel.appendChild(subLabelSpan)
            }
        }
    }

    // Override this (or any other menu methods) to edit menu
    protected addRestMenu(): void {
        this.addLinks(this.user)
        this.addModSection()
        this.addNotes(this.user.username)
    }

    private addNotes(username: string): void {
        if (this.currentUsername === undefined || this.currentUsername === username) {
            return
        }

        const userNoteDiv = document.createElement("div")
        const UserNoteClass = ReactComponentRegistry.get("UserNote")
        this.reactUserNote = new UserNoteClass(
            {
                username: username,
                badgeManager: UserNotesBadgeManager.getOrCreateInstance(),
                isNotLoggedIn: isNotLoggedIn,
                removeMenu: () => {
                    this.hideOverlay()
                },
            },
            userNoteDiv,
        )
        this.menuContents.appendChild(userNoteDiv)
    }

    private addLinks(u: IUserInfo): void {
        this.menuLinks = document.createElement("div")
        addColorClass(this.menuLinks, "ucmLinks")
        this.menuLinks.style.padding = "5px 0"
        this.menuLinks.style.fontSize = "12px"
        this.menuLinks.style.borderTopWidth = "1px"
        this.menuLinks.style.borderTopStyle = "solid"

        this.menuContents.appendChild(this.menuLinks)
    }

    private addModSection(): void {
        this.menuContents.appendChild(this.modSection)
        addColorClass(this.modSection, "ucmModSection")
        this.modSection.style.borderTopWidth = "1px"
        this.modSection.style.borderTopStyle = "solid"
        this.modSection.style.padding = "5px 0"
        this.modSection.style.display = "none"
    }

    protected addLinksData(): void {
        if (this.currentUsername !== this.user.username) {
            if (this.chatConn === undefined) {
                error(`ignoreText: Chatconnection should be defined`)
                return
            }

            const sendPmLink = this.createSendLink(this.user.username, true)
            this.menuLinks.appendChild(sendPmLink)

            if (dmsEnabled()) {
                const sendDmLink = this.createSendLink(this.user.username, false)
                this.menuLinks.appendChild(sendDmLink)
            }

            const mentionLink = this.createMentionLink(this.user.username)
            this.menuLinks.appendChild(mentionLink)

            if (this.roomName !== this.currentUsername) {
                const ignoreLink = this.createIgnoreLink(this.user.username, this.chatConn)
                this.menuLinks.appendChild(ignoreLink)
            }

            if (this.message !== undefined) {
                const reportLink = this.createReportLink(this.user.username, this.message)
                this.menuLinks.appendChild(reportLink)
            }
        } else {
            this.menuLinks.style.display = "none"
        }
    }

    private addSilenceIfAllowed(): void {
        // We create the silence functionality here so we don't slow down load times for non-mods
        if (this.data !== undefined && this.data.canSilence) {
            const silenceDiv = document.createElement("div")
            const silenceLink = this.createLink()
            const silenceText = this.createLinkSpan()
            const silenceUser = this.data.username
            silenceText.style.paddingLeft = "20px"
            silenceLink.appendChild(silenceText)
            silenceLink.dataset.testid = "silence-toggle"
            silenceDiv.appendChild(silenceLink)
            if (this.data.silenceId !== undefined) {
                silenceText.innerText = i18n.removeSilenceMessage
            } else {
                silenceText.innerText = i18n.silenceDurationMessage
            }
            silenceText.title = silenceText.innerText
            silenceLink.onclick = () => {
                if (this.data !== undefined && this.data.silenceId !== undefined && this.roomName !== undefined) {
                    postCb(
                        "edit_room_ban/",
                        { "banid": String(this.data.silenceId), "action": "remove_silence", "room_username": this.roomName },
                    ).catch((xhr) => {
                        error("remove_silence_ error", xhr)
                        modalAlert(`Error removing silence from user ${silenceUser}`)
                    })
                } else {
                    modalConfirm(`Silence ${this.user.username}?`, () => {
                        addPageAction("SilenceUser", { "username": this.user.username, "to_user": this.user.username })
                        postCb(`roomsilence/${this.user.username}/${this.roomName}/`, {}).catch((xhr) => {
                            error("silence_user_error", xhr)
                            modalAlert(`Error silencing user ${this.user.username}`)
                        })
                    })
                }
                this.hideOverlay()
            }
            this.modSection.appendChild(silenceDiv)
            this.modSection.style.display = ""
        }
    }

    private findBoundary(): {
        boundV: HTMLElement | undefined,
        boundH: HTMLElement | undefined,
    } {
        if (isFullscreen()) {
            return {
                boundV: fullscreenElement(),
                boundH: fullscreenElement(),
            }
        }

        const checkAncestorForBound = (ancestor: HTMLElement, currBoundV: HTMLElement | undefined, currBoundH: HTMLElement | undefined) => {
            const style = window.getComputedStyle(ancestor)
            if (currBoundV === undefined && style.overflowY === "hidden") {
                currBoundV = ancestor
            }
            if (currBoundH === undefined && style.overflowX === "hidden") {
                currBoundH = ancestor
            }
            return [currBoundV, currBoundH]
        }

        let boundV
        let boundH
        for (
            let ancestor: HTMLElement | undefined = this.parentEl;
            // If we hit the body element, it's basically the viewport, so just return undefined.
            // This is checked to account for fullview mode's body element's 0 height and 0 width.
            ancestor !== undefined && ancestor.tagName.toLowerCase() !== "body";
            ancestor = ancestor.parentElement === null ? undefined : ancestor.parentElement
        ) {
            [boundV, boundH] = checkAncestorForBound(ancestor, boundV, boundH)
            if (boundV !== undefined && boundH !== undefined) {
                break
            }
        }
        return {
            boundV,
            boundH,
        }
    }

    private trapInBoundary(undefBoundV: HTMLElement | undefined, undefBoundH: HTMLElement | undefined): void {
        const boundV = undefBoundV === undefined ? document.documentElement : undefBoundV
        const boundH = undefBoundH === undefined ? document.documentElement : undefBoundH

        const boundVRect = boundV.getBoundingClientRect()
        const boundHRect = boundH.getBoundingClientRect()
        const menuRect = this.element.getBoundingClientRect()

        const maxY = boundVRect.bottom - menuRect.height - 38 // leave room for notes submit button
        const maxX = boundHRect.right - menuRect.width

        const minY = boundVRect.top
        const minX = boundHRect.left

        const realY = menuRect.top
        const realX = menuRect.left

        // pull the UCM slightly away from edge
        const buffer = 4

        if (minY > realY) {
            const offsetY = minY - realY
            this.element.style.top = `${this.element.offsetTop + offsetY + buffer}px`
        }
        if (minX > realX) {
            const offsetX = minX - realX
            this.element.style.left = `${this.element.offsetLeft + offsetX + buffer}px`
        }

        if (maxY < realY) {
            const offsetY = maxY - realY
            this.element.style.top = `${this.element.offsetTop + offsetY - buffer}px`
        }
        if (maxX < realX) {
            const offsetX = maxX - realX
            this.element.style.left = `${this.element.offsetLeft + offsetX - buffer}px`
        }
    }

    private alignNameWith(targetRect: DOMRect): void {
        const isNameAttached = document.body.contains(this.name)
        if (!isNameAttached) {
            return
        }

        const nameRect = this.name.getBoundingClientRect()

        const offsetY = targetRect.top - nameRect.top
        const offsetX = targetRect.left - nameRect.left

        const idealTop =  this.element.offsetTop + offsetY
        const idealLeft = this.element.offsetLeft + offsetX

        this.element.style.top = `${idealTop}px`
        this.element.style.left = `${idealLeft}px`
    }

    private shiftUpToSeeNotes(): void {
        if (this.currentUsername === undefined || this.currentUsername === this.user.username) {
            return
        }
        // Shift up to show notes textarea & save/cancel buttons (buttons + notes are the 85px)
        const shiftHeight = window.innerHeight - (this.element.getBoundingClientRect().top + this.element.offsetHeight + 85)
        if (shiftHeight < 0) {
            const elementTop = parseInt(this.element.style.top.slice(0, -2))
            this.element.style.top = `${elementTop + shiftHeight}px`
        }
    }

    private adjustForImg(): void {
        this.element.style.top = `${this.element.offsetTop - 75}px`
    }

    private repositionToMatch(target: DOMRect): void {
        this.alignNameWith(target)
        this.shiftUpToSeeNotes()
    }

    private position(matchTarget = false): void {
        if (matchTarget) {
            // in case of chat, we should only do this once, because chat scrolls
            this.repositionToMatch(this.clickTargetEl.getBoundingClientRect())
        }

        const bounds = this.findBoundary()
        this.trapInBoundary(bounds.boundV, bounds.boundH)
    }

    hideOverlay(): void {
        if (UserContextMenu.state === UserMenuStates.Down) {
            return
        }

        super.hideOverlay()
        if (this.chatReport !== undefined) {
            this.chatReport.tearDown()
        }
        removeEventListenerPoly("keydown", document, this.tearDownListener)
        this.fullscreenChangeListener?.removeListener()
        this.fullscreenChangeListener = undefined
        removeEventListenerPoly("popstate", window, this.hideOverlayListener)
        this.ucmListenerGroup.removeAll()

        this.parentEl.removeChild(this.element)
        this.afterRemovedFromParent()

        UserContextMenu.setUserContextMenuState(UserMenuStates.Down)

        this.reactUserNote?.dispose()
    }

    private createSendLink(username: string, isPmLink: boolean): HTMLDivElement {
        const sendLink = this.createLink()
        const sendIcon = document.createElement("div")
        const sendText = this.createLinkSpan()

        addColorClass(sendLink, "ucmPMLink")
        sendLink.dataset.testid = isPmLink ? "send-pm" : "send-dm"
        sendLink.appendChild(sendIcon)
        sendLink.appendChild(sendText)

        sendText.innerText = isPmLink ? i18n.sendPrivateMessageText : i18n.sendDirectMessageText
        sendText.title = sendText.innerText
        addColorClass(sendIcon, isPmLink ? "ucmSendPmIcon" : "ucmSendDmIcon")
        sendIcon.style.padding = "0px 4px 0 0"
        sendIcon.style.verticalAlign = "bottom"
        sendIcon.style.width = "14px"
        sendIcon.style.height = "14px"
        sendIcon.style.display = "inline-block"
        sendIcon.style.marginRight = "4px"

        addEventListenerPoly("click", sendLink, (event) => {
            this.hideOverlay()
            if (isNotLoggedIn(isPmLink ? i18n.loggedInToPm : i18n.loggedInToDm)) {
                return
            }

            if (isPmLink) {
                addPageAction("StartPrivateMessage", { "username": username, "to_user": username, "location": "PMTab" })
                userInitiatedPm.fire({ username: username, focus: true, showSupporterAlert: this.showSupporterAlert })
            } else {
                addPageAction("StartDirectMessage", { "username": username, "to_user": username })
                createDmWindowRequest.fire(username)
            }

            event.stopPropagation()
        })

        return sendLink
    }

    private createMentionLink(username: string): HTMLDivElement {
        const mentionLink = this.createLink()
        const mentionIcon = document.createElement("div")
        const mentionText = this.createLinkSpan()

        mentionText.innerText = i18n.mentionUserText
        mentionText.title = mentionText.innerText
        addColorClass(mentionIcon, "ucmMentionIcon")
        mentionIcon.style.width = "14px"
        mentionIcon.style.height = "14px"
        mentionIcon.style.padding = "0px 4px 0 0px"
        mentionIcon.style.verticalAlign = "bottom"
        mentionIcon.style.display = "inline-block"
        mentionIcon.style.marginRight = "4px"

        mentionLink.id = "ucm-mentionuser"
        mentionLink.dataset.testid = "mention-user"
        mentionLink.appendChild(mentionIcon)
        mentionLink.appendChild(mentionText)

        addEventListenerPoly("click", mentionLink, () => {
            mentionUser.fire(username)
            this.hideOverlay()
        })

        return mentionLink
    }

    private createIgnoreLink(username: string, chatConn: IChatConnection): HTMLDivElement {
        const ignoreLink = this.createLink()
        ignoreLink.dataset.testid = "ignore-toggle"
        const ignoreIcon = document.createElement("div")
        const ignoreText = this.createLinkSpan()

        addColorClass(ignoreLink, "ucmIgnoreLink")
        ignoreText.style.paddingLeft = "0"
        ignoreText.innerText = chatConn.isIgnored(username)? i18n.unignoreThisUserText : i18n.ignoreThisUserText
        ignoreLink.appendChild(ignoreIcon)
        ignoreText.title = ignoreText.innerText
        ignoreLink.appendChild(ignoreText)
        addColorClass(ignoreIcon, "ucmIgnoreIcon")
        ignoreIcon.style.padding = "0px 4px 0 0px"
        ignoreIcon.style.verticalAlign = "bottom"
        ignoreIcon.style.width = "14px"
        ignoreIcon.style.height = "14px"
        ignoreIcon.style.display = "inline-block"
        ignoreIcon.style.marginRight = "4px"

        addEventListenerPoly("click", ignoreLink, () => {
            if (chatConn.isIgnored(username)) {
                chatConn.unignore(username)  // eslint-disable-line @typescript-eslint/no-floating-promises
            } else {
                chatConn.ignore(username)  // eslint-disable-line @typescript-eslint/no-floating-promises
            }
            this.hideOverlay()
        })

        return ignoreLink
    }

    private createReportLink(username: string, message: IRoomMessage): HTMLDivElement {
        const reportLink = this.createLink()
        const reportIcon = document.createElement("div")
        const reportText = this.createLinkSpan()

        addColorClass(reportLink, "ucmReportLink")
        reportLink.appendChild(reportIcon)
        reportLink.appendChild(reportText)
        reportLink.dataset.testid = "report-room"

        addColorClass(reportIcon, "ucmReportIcon")
        reportIcon.style.padding = "0 4px 0 0"
        reportIcon.style.verticalAlign = "middle"
        reportIcon.style.width = "14px"
        reportIcon.style.height = "14px"
        reportIcon.style.display = "inline-block"
        reportIcon.style.marginRight = "4px"
        reportText.innerText = i18n.reportThisMessageText
        reportText.title = reportText.innerText

        addEventListenerPoly("click", reportLink, () => {
            addPageAction("ReportChatUser", { "username": username, "to_user": username })
            this.element.removeChild(this.menuContents)
            this.chatReport = new ChatReport(username, message, this.chatConn)
            this.element.appendChild(this.chatReport.element)
            this.position()
            this.chatReport.focusForm()
            this.chatReport.closeChatReportRequest.listen(() => {
                this.hideOverlay()
            })
        })

        return reportLink
    }

    protected createLink(): HTMLDivElement {
        const link = document.createElement("div")
        link.style.padding = "3px 10px"
        link.style.whiteSpace = "nowrap"
        link.style.overflow = "hidden"
        link.style.textOverflow = "ellipsis"
        link.style.fontWeight = "normal"
        link.style.fontFamily = "Tahoma, Arial, Helvetica, sans-serif"
        link.style.fontSize = "12px"
        link.style["-webkit-text-size-adjust"] = "none"
        addColorClass(link, "ucmLinkColor")
        this.addLinkMouseover(link)

        return link
    }

    protected createLinkSpan(): HTMLElement {
        const span = document.createElement("span")
        span.style.fontWeight = "normal"
        span.style.fontFamily = "Tahoma, Arial, Helvetica, sans-serif"
        span.style.fontSize = "12px"
        span.style["-webkit-text-size-adjust"] = "none"
        addColorClass(span, "ucmLinkColor")

        return span
    }

    private addLinkMouseover(link: HTMLDivElement): void {
        // Need to create const and use inside onmouseenter in case colormode is changed
        // while menu is open. That way the colors stay consistent while its open
        addColorClass(link, "ucmLinkHover")
        link.onmouseenter = () => {
            link.style.cursor = "pointer"
        }
        link.onmouseleave = () => {
            link.style.cursor = "default"
        }
    }
}
