import { ArgJSONMap } from "@multimediallc/web-utils"
import { isiOS } from "@multimediallc/web-utils/modernizr"
import { getPanel, parsePanel } from "../cb/api/asp"
import { pageContext } from "../cb/interfaces/context"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { getCb } from "./api"
import { roomLoaded } from "./context"
import { Component } from "./defui/component"
import { hoverEvent } from "./DOMutils"
import { EventRouter } from "./events"
import { featureFlagIsActive } from "./featureFlag"
import { templateHTMLImageTemplate } from "./imagePanelTemplate"
import { templateHTML } from "./legacyPanelTemplate"
import { i18n } from "./translation"
import { VideoMode, videoModeHandler } from "./videoModeHandler"
import type { IChatConnection } from "./context";
import type { IVideoModeChangeNotification } from "./videoModeHandler";
import type { IPanelApp } from "../cb/interfaces/asp"

export const appPanelWidth = 270
export const appPanelHeight = 69
export const appPanelTallHeight = 337

export const appPanelUpdated = new EventRouter<undefined>("panelUpdated")
export const multiAppPanel = featureFlagIsActive("MultiAppPanel")


export class AppPanelCarousel extends Component<HTMLDivElement> {
    private panelList: IPanelApp[]
    private currentApp?: IPanelApp
    private roomUid: string
    private appPanelWrapper: HTMLDivElement
    private appPanel: AppPanel2
    private chatConnection: IChatConnection
    public panelUpdated = appPanelUpdated
    public hasContent = true
    public bcpFallback = false;

    constructor() {
        super()
        this.element.style.width = "320px"
        this.element.style.height = `${appPanelHeight}px`
        this.element.style.position = "relative"
        this.element.style.overflow = ""

        roomLoaded.listen(roomContext => {
            this.roomUid = roomContext.dossier.roomUid
            this.getInitialPanel(true)
            this.chatConnection = roomContext.chatConnection

            roomContext.chatConnection.event.refreshPanel.listen((data) => {
                if (data === undefined || data.appId === undefined || data.appSystem === undefined) {
                    this.getInitialPanel(false)
                } else {
                    this.refreshPanel(data.appId, data.appSystem)
                }
            })
        })

        videoModeHandler.changeVideoMode.listen((videoModeChange: IVideoModeChangeNotification) => {
            if (
                videoModeChange.currentMode !== videoModeChange.previousMode &&
                videoModeChange.currentMode === VideoMode.Split
            ) {
                this.getInitialPanel(false)
            }
        })

        this.appPanelWrapper = document.createElement("div")
        this.appPanelWrapper.style.height = `${appPanelHeight}px`
        this.appPanelWrapper.style.overflow = "hidden"
        this.appPanelWrapper.style.boxSizing = "border-box"
        this.appPanel = new AppPanel2()
        this.appPanelWrapper.appendChild(this.appPanel.element)
        this.element.appendChild(this.appPanelWrapper)

        const onMessage = (ev: MessageEvent) => {
            switch (ev.data) {
                case "webc-bcp-fallback":
                    if (ev.origin !== this.appPanel.getWebcOrigin()) {
                        return
                    }
                    this.bcpFallback = true
                    this.chatConnection.event.refreshPanel.fire(undefined)
                    break
                case "request-auth-token":
                    if (ev.origin !== this.appPanel.getWebcOrigin()) {
                        return
                    }
                    this.appPanel.sendAuthToken()
                    break
                default:
                    break
            }
        }

        addEventListenerPoly("message", window, onMessage)

        this.setupListeners()
    }

    private getAppIndex(appId?: string): number {
        if (appId === undefined) {
            return -1
        }
        for (let i = 0; i < this.panelList.length; i++) {
            if (appId === this.panelList[i].id) {
                return i
            }
        }
        return -1
    }

    private getInitialPanel(isPageLoad: boolean) {
        getPanel(this.roomUid, this.bcpFallback).then((data) => {
            this.reset(data, isPageLoad)
        }).catch(() => { })
    }

    private reset(data: ArgJSONMap, showPreview: boolean) {
        this.clear()
        this.hasContent = true
        const panelData = parsePanel(data)
        this.panelList = panelData.appList
        if (this.panelList.length > 0) {
            let currentAppIndex = this.getAppIndex(this.currentApp?.id)
            if (currentAppIndex === -1) {
                currentAppIndex = 0
            }
            this.currentApp = this.panelList[currentAppIndex]
            if (panelData.error === undefined) {
                this.appPanel = new AppPanel2(data, showPreview)
            } else {
                this.hasContent = false
                this.appPanel = new AppPanel2()
            }
        } else {
            this.currentApp = undefined
            this.hasContent = false
            this.appPanel = new AppPanel2()
        }
        this.appPanelWrapper.appendChild(this.appPanel.element)
        this.panelUpdated.fire(undefined)
    }

    extendPanel(): void {
        this.appPanel.element.style.position = "absolute"
        this.appPanel.element.style.zIndex = "1000"
        this.appPanel.element.style.height = `${appPanelTallHeight}px`
    }

    shrinkPanel(): void {
        this.appPanel.element.style.position = "static"
        this.appPanel.element.style.zIndex = "auto"
        this.appPanel.element.style.height = `${appPanelHeight}px`
    }

    private setupListeners() {
        const hoverFunc = (hover: boolean) => {
            if (hover && this.currentApp !== undefined) {
                this.extendPanel()
            } else {
                this.shrinkPanel()
            }
        }
        hoverEvent(this.appPanelWrapper, { handleTouch: true }).listen(hoverFunc)
        if (isiOS()) {
            // IOS workaround for hover event since touch events don't work when there is a child iframe
            window.setInterval(() => {
                if (this.element.contains(document.activeElement) && this.currentApp !== undefined) {
                    this.extendPanel()
                } else {
                    this.shrinkPanel()
                }
            }, 300)
        }
    }

    previous(): void {
        const index = this.getAppIndex(this.currentApp?.id)
        if (index < 1) {
            return
        }
        this.currentApp = this.panelList[index - 1]
        this.refreshPanel(this.currentApp.id, this.currentApp.panelType)
    }

    next(): void {
        const index = this.getAppIndex(this.currentApp?.id)
        if (index === this.panelList.length - 1) {
            return
        }
        this.currentApp = this.panelList[index + 1]
        this.refreshPanel(this.currentApp.id, this.currentApp.panelType)
    }

    refreshPanel(appId: string, system: string): void {
        getPanel(this.roomUid, this.bcpFallback, appId, system).then((data) => {
            const panel = parsePanel(data)
            this.panelList = panel.appList

            if (panel.error !== undefined && this.currentApp?.id === appId) {
                this.getInitialPanel(false)
                return
            }
            this.reset(data, false)
        }).catch(() => { })
    }

    private removeDOMChildren(element: HTMLElement) {
        while (element.firstChild !== null) {
            element.removeChild(element.firstChild)
        }
    }

    private clearAppPanel() {
        this.removeDOMChildren(this.appPanelWrapper)
    }

    clear(): void {
        this.clearAppPanel()
    }
}


class AppPanel2 extends Component<HTMLDivElement> {
    readonly previewTime = 5000
    private panel: HTMLTableElement | HTMLDivElement | HTMLIFrameElement
    private preview: HTMLDivElement
    private previewTimer: number
    private webcOrigin?: string
    private webcAuthToken?: string

    constructor(data?: ArgJSONMap, showPreview = true) {
        super()

        this.element.style.position = "relative"
        this.element.style.display = "inline-block"
        this.element.style.width = `${appPanelWidth}px`
        this.element.style.overflow = "hidden"
        this.element.style.textShadow = "none"
        this.element.style.fontSize = "11px"
        this.element.style.lineHeight = "1.7em"

        this.update(data, showPreview)
    }

    update(data?: ArgJSONMap, showPreview = true) {
        this.cleanupPanel()
        if (data === undefined) {
            this.panel = templateHTML()
            this.element.appendChild(this.panel)
            return
        }
        const panel = parsePanel(data)
        if (panel.url !== undefined) {
            this.element.style.width = "320px"
            this.webcOrigin = panel.webcOrigin?.toLowerCase()
            this.webcAuthToken = panel.webcAuthToken
            this.prepareIframePanel(panel.url, showPreview)
        } else {
            this.panel = this.createHTMLPanel(panel.template, data)
        }
        this.element.appendChild(this.panel)
    }

    getWebcOrigin() {
        return this.webcOrigin
    }

    sendAuthToken() {
        if (this.webcOrigin === undefined) {
            return
        }

        (this.panel as HTMLIFrameElement).contentWindow?.postMessage(
            { type: "auth-token", value: this.webcAuthToken },
            this.webcOrigin,
        )
    }

    private prepareIframePanel(url: string, showPreview: boolean) {
        if (!pageContext.current.isBroadcast || !showPreview) {
            this.panel = this.createIframePanel(url)
            return
        }

        this.preview = document.createElement("div")
        this.preview.style.width = "320px"
        this.preview.style.height = "69px"
        this.preview.style.textAlign = "center"
        this.preview.style.lineHeight = "5em"
        this.preview.style.fontSize = "14px"
        this.preview.style.fontWeight = "bold"
        this.preview.style.boxShadow = "inset 0 0 100px 10px rgba(0, 0, 0, 0.32)"
        this.preview.innerText = i18n.loadingPreview
        this.element.appendChild(this.preview)
        this.panel = this.createIframePanel("")
        this.panel.style.display = "none"
        this.previewTimer = window.setTimeout(() => {
            this.element.removeChild(this.preview)
            // @ts-ignore panel is an iframe
            this.panel.src = url
            this.panel.style.display = "block"
        }, this.previewTime)
    }

    private createHTMLPanel(template: string, data: ArgJSONMap): HTMLTableElement | HTMLDivElement {
        switch (template) {
            case "image_template": {
                return templateHTMLImageTemplate(data)
            }
            case "3_rows_11_21_31":
            case "3_rows_11_22_32":
            case "3_rows_12_21_31":
            case "3_rows_12_22_31":
            case "3_rows_of_labels": {
                return templateHTML(data)
            }
            default: {
                warn(`template of type (${template}): is not supported`)
                return templateHTML()
            }
        }
    }

    private createIframePanel(url: string): HTMLIFrameElement {
        const iframe = document.createElement("iframe")
        iframe.id = "appPanelFrame"
        iframe.src = url
        iframe.style.border = "none"
        iframe.style.padding = "0"
        iframe.style.background = "#FFFFFF"
        iframe.style.width = "100%"
        iframe.style.height = "100%"
        hoverEvent(iframe).listen((hover) => {
            this.element.style.filter = hover ? "drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.3))" : ""
        })

        if (isiOS()) {
            // IOS workaround for hover event since touch events don't work when there is a child iframe
            window.setInterval(() => {
                if (this.element.contains(document.activeElement)) {
                    this.element.style.filter = "drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.3))"
                } else {
                    this.element.style.filter = ""
                }
            }, 300)
        }

        return iframe
    }

    private cleanupPanel(): void {
        clearTimeout(this.previewTimer)
        this.removeAllDOMChildren()
    }
}

export class AppPanel extends Component<HTMLDivElement> {
    private appPanelTable: HTMLTableElement | HTMLDivElement
    readonly panelUpdated = new EventRouter<undefined>("panelUpdated")
    public hasContent = false

    constructor() {
        super()

        this.element.style.display = "inline-block"
        this.element.style.paddingLeft = "5px"
        this.element.style.paddingTop = "3px"
        this.element.style.paddingRight = "5px"
        this.element.style.paddingBottom = "3px"
        this.element.style.position = ""
        this.element.style.width = `${appPanelWidth}px`
        this.element.style.height = `${appPanelHeight}px`
        this.element.style.textShadow = "none"
        this.element.style.fontSize = "11px"
        this.element.style.lineHeight = "1.7em"
        this.element.style.overflow = "hidden"

        roomLoaded.listen(roomContext => {
            this.updateAppPanel(roomContext.chatConnection.room())

            roomContext.chatConnection.event.refreshPanel.listen(() => {
                this.updateAppPanel(roomContext.chatConnection.room())
            })

            roomContext.chatConnection.event.clearApp.listen(() => {
                this.updateAppPanel(roomContext.chatConnection.room())
            })
        })

        this.appPanelTable = templateHTML()
        this.element.appendChild(this.appPanelTable)
    }

    updateAppPanel(room: string): void {
        getCb(`api/panel_context/${room}/`).then((appResponse) => {
            this.cleanupPanel()
            const rsp = new ArgJSONMap(appResponse.responseText)
            if (rsp.getString("code", false) === "no-content") {
                this.hasContent = false
                this.appPanelTable = templateHTML()
                this.element.appendChild(this.appPanelTable)
                this.panelUpdated.fire(undefined)
                return
            }
            this.hasContent = true
            const templateName = rsp.getString("template", false)
            switch (templateName) {
                case "image_template": {
                    this.appPanelTable = templateHTMLImageTemplate(rsp)
                    break
                }
                case "3_rows_11_21_31":
                case "3_rows_11_22_32":
                case "3_rows_12_21_31":
                case "3_rows_12_22_31":
                case "3_rows_of_labels": {
                    this.appPanelTable = templateHTML(rsp)
                    break
                }
                default: {
                    this.hasContent = false
                    this.appPanelTable = templateHTML()
                    warn(`template of type (${templateName}): is not supported`)
                    break
                }
            }
            rsp.logUnusedDebugging("updateAppPanel")
            this.element.appendChild(this.appPanelTable)
            this.panelUpdated.fire(undefined)
        }).catch((err: Error) => {
            if (err.message.toLowerCase().includes("network error")) {
                return
            }
            error("Error fetching app panel", err)
        })
    }

    private cleanupPanel(): void {
        if (this.element.children.length > 0) {
            this.element.removeChild(this.appPanelTable)
        }
    }
}
