/* eslint-disable @typescript-eslint/no-explicit-any */
import { addEventListenerPoly } from "../addEventListenerPolyfill"

export type TsxChild = HTMLElement | SVGElement | Comment | DocumentFragment |
    string | number | boolean | null | undefined | TsxChild[]
export type TsxTag = (props: object | null) => string | HTMLElement | SVGElement
export type TsxClass = new(props: object | null, ...args: any[]) => {
        render(): ElementOrFragment,
    }
export type ElementOrFragment = HTMLElement | SVGElement | DocumentFragment

export const Fragment = (): TsxChild => "FRAGMENT"


const attributeSetters = {
    "style": (element: SVGElement | HTMLElement, val: any) => {
        // e.g. origin: <element style={{ prop: value }} />
        Object.assign(element.style, val)
    },
    "className": (element: SVGElement | HTMLElement, val: any) => {
        element.setAttribute("class", val) // eslint-disable-line @multimediallc/no-set-attribute
    },
    "class": (element: SVGElement | HTMLElement, val: any) => {
        element.setAttribute("class", val) // eslint-disable-line @multimediallc/no-set-attribute
    },
    "htmlFor": (element: SVGElement | HTMLElement, val: any) => {
        element.setAttribute("for", val) // eslint-disable-line @multimediallc/no-set-attribute
    },
    "colorClass": (element: SVGElement | HTMLElement, val: any) => {
        const colorClass: string | string[] = val
        if (Array.isArray(colorClass)) {
            colorClass.forEach((cls) => {
                if (cls !== "") {
                    element.classList.add(cls)
                }
            })
        } else {
            element.classList.add(colorClass)
        }
    },
    "xlinkHref": (element: SVGElement | HTMLElement, val: any) => {
        element.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", val)
    },
    "dangerouslySetInnerHTML": (element: SVGElement | HTMLElement, val: any) => {
        element.innerHTML = val.__html // eslint-disable-line @multimediallc/no-inner-html
    },
    "bind": (element: SVGElement | HTMLElement, val: any) => {
        assignBindAttribute(element, val)
    },

    "ref": (element: SVGElement | HTMLElement, val: any) => {
        if (typeof val === "function") {
            val(element)
        }
    },
    "display": (element: SVGElement | HTMLElement, val: any) => {
        element.style.display = val
    },
}

function setAttribute(prop: string, element: SVGElement | HTMLElement, val: any) {
    // @ts-ignore no type check
    const setter = attributeSetters[prop]
    if (setter !== undefined) {
        setter(element, val)
    } else if (prop.indexOf("on") === 0) {
        setEventAttribute(prop, element, val)
    } else {
        // any other prop will be set as attribute
        element.setAttribute(prop, val) // eslint-disable-line @multimediallc/no-set-attribute
    }
}

function setAttributes(element: SVGElement | HTMLElement, attrs: Record<string, any> | null) {
    if (attrs != null) {
        const attrKeys = Object.keys(attrs)
        const colorClassIndex = attrKeys.indexOf("colorClass")

        // add `colorClass` to the end to make sure it's set after `className`
        if (colorClassIndex >= 0) {
            attrKeys.push(attrKeys.splice(colorClassIndex, 1)[0])
        }

        attrKeys.forEach(prop => {
            setAttribute(prop, element, attrs[prop])
        })
    }
}

function setEventAttribute(prop: string, element: SVGElement | HTMLElement, listener: EventListener) {
    let event = prop.toLowerCase()
    let capture = false
    if (prop.indexOf("Capture", prop.length - 7) > -1) {
        capture = true
        event = event.slice(-7)
    }
    // @ts-ignore no type check
    if (element[event] === null) {
        // the event is valid for this element
        addEventListenerPoly(event.slice(2), element as HTMLElement, listener, capture)
    } else {
        // warn rather than error because we may try to add events that aren't implemented in this browser
        //   onTouchStart in IE, for example
        warn(`Event declared for JSX element that does not exist on type ${element.tagName}: ${prop}`)
    }
}

function createElements(tagName: string, attrs: object | null, children: TsxChild[]): HTMLElement | SVGElement {
    const element = isSVG(tagName)
        ? document.createElementNS("http://www.w3.org/2000/svg", tagName)
        : document.createElement(tagName)

    // one or multiple will be evaluated to append as string or HTMLElement
    const fragment = createFragmentFrom(children)
    element.appendChild(fragment)
    copyBindings(element, fragment)
    setAttributes(element, attrs)

    return element
}

function composeToFunction(tsxTag: TsxTag, elementProps: object | null, children: TsxChild[]): ElementOrFragment {
    const props = Object.assign({}, {}, elementProps, { children })
    const result = tsxTag(props)
    let el: ElementOrFragment

    if (result === "FRAGMENT") {
        el = createFragmentFrom(children)
    } else {
        el = result as HTMLElement | SVGElement
    }
    callRef(props, el)
    return el
}

function composeToClass(tsxClass: TsxClass, elementProps: Record<string, any> | null, children: TsxChild[]): ElementOrFragment {
    const props = Object.assign({}, {}, elementProps, { children })
    let unsafeArgs = []
    if (props["unsafeArgs"] !== undefined) {
        unsafeArgs = props["unsafeArgs"]
    }
    const c = new tsxClass(props, ...unsafeArgs)
    const el = c.render()
    callClassRef(props, c)
    callRef(props, el)
    if (Object.getPrototypeOf(c).updateState !== undefined) {
        addBinding(el, c)
    }
    return el
}

function callClassRef(props: Record<string, any>, c: any): void {
    if (typeof props["classRef"] === "function") {
        props["classRef"](c, props)
    }
}

function callRef(props: Record<string, any>, el: ElementOrFragment): void {
    if (typeof props["ref"] === "function") {
        props["ref"](el, props)
    }
}

function assignBindAttribute(element: SVGElement | HTMLElement, bindObj: Record<string, () => string>): void {
    addBinding(element, () => {
        Object.keys(bindObj).forEach(key => {
            const fn = bindObj[key]
            if (!(fn instanceof Function)) {
                warn(`Wrong binding for element ${element.outerHTML} key ${key} is not a function ${fn}`)
                return
            }
            const val = bindObj[key]()
            if (key === "display") {
                element.style.display = val
            } else if (key === "text") {
                element.textContent = val
            } else {
                setAttribute(key, element, val)
            }
        })
    })
}

function isSVG(element: string): boolean {
    const patt = new RegExp(`^${element}$`, "i")
    const SVGTags = ["path", "svg", "use", "g"]

    return SVGTags.some(tag => patt.test(tag))
}

function createFragmentFrom(children: TsxChild[]): DocumentFragment {
    // fragments will help later to append multiple children to the initial node
    const fragment = document.createDocumentFragment()

    function processDOMNodes(child: TsxChild): void { // eslint-disable-line complexity
        if (
            child instanceof HTMLElement ||
            child instanceof SVGElement ||
            child instanceof Comment ||
            child instanceof DocumentFragment
        ) {
            fragment.appendChild(child)
            copyBindings(fragment, child)
        } else if (typeof child === "string" || typeof child === "number") {
            const textNode = document.createTextNode(`${child}`)
            fragment.appendChild(textNode)
        } else if (child instanceof Array) {
            child.forEach(processDOMNodes)
        } else if (child === false || child === null || child === undefined) {
            // expression evaluated as false e.g. {false && <Elem />}
            // expression evaluated as false e.g. {null && <Elem />}
            debug(`Logic expression in Tsx fragment: ${child}`)
        } else {
            // later other things could not be HTMLElement nor strings
            warn(`Unexpected child in Tsx fragment: ${child}`)
        }
    }

    children.forEach(processDOMNodes)

    return fragment
}

export function dom(element: TsxTag | TsxClass | string, attrs: object | null, ...children: TsxChild[]): ElementOrFragment {
    // Custom Components will be functions
    if (typeof element === "function") {
        if (element.prototype === undefined || element.prototype.render === undefined) {
            /**
             * e.g. const CustomTag = (props: { text: string }) => <span>{props.text}</span>
             * will be used
             * e.g. <CustomTag text="foo" />
             * becomes: CustomTag({ text: "foo"})
             */
            return composeToFunction(element as TsxTag, attrs, children)
        } else {
            /**
             * e.g. class CustomTag {
             *     constructor(private props: {text: string}, private one: string, private two: string) {
             *     }
             *     render(): HTMLElement {
             *         return <span>{this.props.text}{this.one}{this.two}</span>
             *     }
             * }
             * will be used
             * e.g. <CustomTag unsafeArgs={["foo", "bar"]} text="text" />
             * becomes: new CustomTag({ text: "text" }, "foo", "bar").render()
             */
            return composeToClass(element as TsxClass, attrs, children)
        }
    }

    // regular html components will be strings to create the elements
    // this is handled by the babel plugins
    return createElements(element, attrs, children)
}

export const DOM_BINDINGS_KEY = "__mm_bindings__"

function addBinding(el: any, bindings: any) {
    if (!(DOM_BINDINGS_KEY in el)) {
        el[DOM_BINDINGS_KEY] = []
    }
    el[DOM_BINDINGS_KEY].push(bindings)
}

function copyBindings(el: any, anotherEl: any) {
    if (DOM_BINDINGS_KEY in anotherEl) {
        for (const bindings of anotherEl[DOM_BINDINGS_KEY]) {
            addBinding(el, bindings)
        }
    }
}

export function getBindings(el: HTMLElement): any[] {
    return el[DOM_BINDINGS_KEY] ?? []
}

