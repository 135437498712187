import { isMobileDevice } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly, removeEventListenerPoly } from "../../../../common/addEventListenerPolyfill"
import { Component } from "../../../../common/defui/component"
import { applyStyles, tabListenerFactory } from "../../../../common/DOMutils"
import { EventRouter } from "../../../../common/events"
import { makeDraggable } from "../../../../common/fullvideolib/draggableCanvasWindow"
import { SentimentSurveyOption } from "../../../../common/sentimentSurvey"
import { i18n } from "../../../../common/translation"
import { FormItem } from "../../../../common/ui/forms"
import { addColorClass, removeColorClass } from "../../../colorClasses"
import { currentSiteSettings } from "../../../siteSettings"
import { smileyEmoticon } from "../../../ui/svg/feedbackSmiley"
import Key = JQuery.Key
import type { UserFeedbackModal } from "./userFeedbackModal"

/**
 * @styles: scss/theme/shared/userFeedbackModal.scss
 */

export const canvasOpen = new EventRouter<undefined>("canvasOpen")
export const canvasClose = new EventRouter<undefined>("canvasClose")

export const createSimpleDiv = (parent?: HTMLElement): HTMLDivElement => {
    const group = document.createElement("div")
    group.style.display = "block"

    if (parent !== undefined) {
        parent.appendChild(group)
    }
    return group
}

export class CloseControl extends Component {
    control: HTMLButtonElement

    protected initData(): void {
        this.element = createSimpleDiv()

        this.control = document.createElement("button")
        this.control.type = "button"
        this.control.tabIndex = 0
        this.element.appendChild(this.control)
    }

    protected initUI(): void {
        this.element.style.position = "absolute"
        this.element.style.top = "0"
        this.element.style.right = "0"
        this.element.style.margin = "12px 12px 0 0"

        addColorClass(this.control, "closeControl")
        this.control.style.padding = "4px"
        this.control.style.cursor = "pointer"
        this.control.style.width = "12px"
        this.control.style.height = "12px"
        this.control.style.border = "none"
        this.control.style.boxSizing = "content-box"
    }
}

export class Header extends Component {
    header: HTMLHeadingElement

    protected initData(): void {
        this.element = createSimpleDiv()
        addColorClass(this.element, "header")
        this.header = document.createElement("h2")
        this.header.textContent = i18n.sendFeedbackTextLc
        this.element.appendChild(this.header)
    }

    protected initUI(): void {
        applyStyles(this.header, {
            fontSize: "14px",
            lineHeight: "18px",
            fontFamily: "UbuntuRegular, Helvetica, Arial, sans-serif",
            margin: "0 0 8px",
        })
    }
}

export class SubmitRow extends Component {
    submit: HTMLButtonElement

    protected initData(): void {
        this.element = createSimpleDiv()
        addColorClass(this.element, "submitRow")
        this.submit = document.createElement("button")
        this.submit.type = "submit"
        this.submit.textContent = i18n.feedbackSubmit
        this.submit.disabled = true
        this.element.appendChild(this.submit)
    }

    protected initUI(): void {
        this.element.style.textAlign = "right"
        applyStyles(this.submit, {
            fontFamily: "UbuntuRegular, Helvetica, Arial, sans-serif",
            marginTop: "16px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "4px",
            boxSizing: "border-box",
            opacity: "0.5",
            padding: "7px",
        })
    }

    public disable(): void {
        this.submit.disabled = true
        this.submit.style.opacity = "0.5"
        this.submit.style.cursor = "default"
    }

    public enable(): void {
        this.submit.disabled = false
        this.submit.style.opacity = "1"
        this.submit.style.cursor = "pointer"
    }
}

export class SentimentSelection extends Component {
    readonly formInput: FormItem
    public readonly inputChange = new EventRouter<string>("inputChange")
    public element: HTMLDivElement
    private readonly defaultPrompt = i18n.feedbackSentimentLabel(currentSiteSettings.siteName)

    constructor() {
        super()

        this.element = createSimpleDiv()

        this.formInput = new FormItem("hidden", "sentiment", this.defaultPrompt, false)
        this.element.appendChild(this.formInput.element)

        const sentimentOptions = createSimpleDiv()
        applyStyles(sentimentOptions, {
            height: "auto",
            width: "224px",
            margin: "12px auto 16px",
            position: "relative",
        })
        sentimentOptions.appendChild(this.createSentimentSelection(SentimentSurveyOption.InTrouble))
        sentimentOptions.appendChild(this.createSentimentSelection(SentimentSurveyOption.Unhappy))
        sentimentOptions.appendChild(this.createSentimentSelection(SentimentSurveyOption.Indifferent))
        sentimentOptions.appendChild(this.createSentimentSelection(SentimentSurveyOption.Smirk))
        sentimentOptions.appendChild(this.createSentimentSelection(SentimentSurveyOption.InLove, true))
        this.element.appendChild(sentimentOptions)
    }

    private createSentimentSelection(option: SentimentSurveyOption, last = false): HTMLButtonElement {
        const optionButton = document.createElement("button")
        optionButton.type = "button"
        addColorClass(optionButton, "sentimentOption")
        optionButton.appendChild(smileyEmoticon(option))
        applyStyles(optionButton, {
            height: "24px",
            width: "24px",
            padding: "4px",
            boxSizing: "content-box",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            display: "inline-block",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            marginRight: last ? "0" : "16px",
            verticalAlign: "middle",
        })

        this.inputChange.listen((value: string) => {
            if (value === option) {
                addColorClass(optionButton, "selected")
            } else {
                removeColorClass(optionButton, "selected")
            }
        })
        optionButton.onclick = () => {
            this.setValue(option)
        }

        return optionButton
    }

    public setValue(option: SentimentSurveyOption | ""): void {
        this.formInput.input.value = option
        this.inputChange.fire(option)
    }

    public getValue(): SentimentSurveyOption | "" {
        return this.formInput.input.value as SentimentSurveyOption | ""
    }

    public setPrompt(prompt: string): void {
        if (prompt === "") {
            this.formInput.label.textContent = this.defaultPrompt
        } else {
            this.formInput.label.textContent = prompt
        }
    }

    public clearSelection(): void {
        this.setValue("")
    }
}

// NOTE: When using Comments, make sure feedback/models.py:MAX_FEEDBACK_PROMPTS is >= prompts.length. Increase its value
//       if needed.
interface ICommentsProps {
    prompts: string[],
    onChange: () => void,
    required: boolean,
    textBoxHeight: number,
}
export class Comments extends Component {
    commentItems: FormItem[] = []

    constructor(private props: ICommentsProps) {
        super()
        // Recreate this.element to avoid Component default styling
        this.element  = createSimpleDiv()

        for (const prompt of props.prompts) {
            const comment = new FormItem("textarea", "comments", prompt, props.required)
            addColorClass(comment.input, "feedbackComment")
            applyStyles(comment.input, {
                width: "306px",
                resize: "none",
                minHeight: `${props.textBoxHeight}px`,
                boxSizing: "border-box",
                marginBottom: "8px",
            })
            comment.input.maxLength = 5000

            addColorClass(comment.error, "error")
            comment.error.style.marginBottom = "12px"

            addEventListenerPoly("keyup", comment.input, props.onChange)
            addEventListenerPoly("touchend", comment.input, props.onChange)
            addEventListenerPoly("paste", comment.input, props.onChange)
            addEventListenerPoly("cut", comment.input, props.onChange)

            this.element.appendChild(comment.element)
            this.commentItems.push(comment)
        }

        for (let i = 0; i < this.commentItems.length; i++) {
            addEventListenerPoly("keydown", this.commentItems[i].input, tabListenerFactory(() => {
                return i + 1 < this.commentItems.length ? this.commentItems[i + 1].input : undefined
            }, () => {
                return i > 0 ? this.commentItems[i - 1].input : undefined
            }))
        }
    }

    public prompts(): string[] {
        return this.props.prompts
    }

    public values(): string[] {
        return this.commentItems.map(comment => comment.input.value)
    }

    public clear(): void {
        for (const comment of this.commentItems) {
            comment.input.value = ""
        }
    }

    public focus(): void {
        this.commentItems[0].input.focus()
    }

    public blur(): void {
        this.commentItems.forEach(comment => comment.input.blur())
    }

    public firstInput(): HTMLTextAreaElement {
        return this.commentItems[0].input as HTMLTextAreaElement
    }

    public lastInput(): HTMLTextAreaElement {
        return this.commentItems[this.commentItems.length - 1].input as HTMLTextAreaElement
    }

    public setError(errorStr: string, index: number): void {
        if (index < 0 || index >= this.commentItems.length) {
            error("Feedback comments error index out of range")
        }
        if (errorStr.length > 0) {
            this.commentItems[index].setError(errorStr)
        }
    }

    public clearErrors(): void {
        this.commentItems.forEach(comment => comment.clearError())
    }

    public anyCommentsNonempty(): boolean {
        return this.commentItems.some(comment => comment.input.value.trim() !== "")
    }
}

type screenshotProps = { control: ScreenshotControl }

class Screenshot extends Component {
    editToggle: HTMLButtonElement
    editImage: HTMLImageElement
    editText: HTMLDivElement
    closeButton: HTMLButtonElement

    constructor(control: ScreenshotControl, private canvas: Canvas) {
        super("div", { control: control })
    }

    protected initData(props: screenshotProps): void {
        this.element = document.createElement("div")
        addColorClass(this.element, "screenshot")

        this.editToggle = document.createElement("button")
        this.editToggle.type = "button"
        addColorClass(this.editToggle, "editToggle")

        this.editImage = new Image()
        this.editImage.src = `${STATIC_URL_ROOT}images/feedback/select.svg`
        this.editToggle.appendChild(this.editImage)
        this.editText = document.createElement("div")
        this.editText.textContent = i18n.feedbackCanvasToggle
        this.editToggle.appendChild(this.editText)

        addEventListenerPoly("click", this.editToggle, () => {
            this.canvas.show()
        })
        addEventListenerPoly("mouseenter", this.editToggle, () => {
            this.editImage.src = `${STATIC_URL_ROOT}images/feedback/select_active.svg`
        })
        addEventListenerPoly("mouseleave", this.editToggle, () => {
            this.editImage.src = `${STATIC_URL_ROOT}images/feedback/select.svg`
        })
        this.element.appendChild(this.editToggle)

        this.closeButton = document.createElement("button")
        this.closeButton.type = "button"
        this.closeButton.onclick = () => {
            props.control.removeScreenshot()
        }
        this.element.appendChild(this.closeButton)
    }

    protected initUI(): void {
        this.element.style.width = "100%"
        this.element.style.minHeight = "140px"
        this.element.style.maxHeight = "340px"
        this.element.style.position = "relative"
        this.element.style.backgroundSize = "contain"
        this.element.style.backgroundRepeat = "no-repeat"
        this.element.style.backgroundPosition = "center"
        this.element.style.justifyContent = "center"
        this.element.style.alignItems = "center"
        this.element.style.display = "none"

        this.editToggle.style.width = "75%"
        this.editToggle.style.height = "100px"
        this.editToggle.style.boxShadow = "0px 4px 16px rgba(0, 0, 0, 0.3)"
        this.editToggle.style.borderRadius = "4px"
        this.editToggle.style.textAlign = "center"
        this.editToggle.style.cursor = "pointer"
        this.editToggle.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"

        this.editText.style.width = "100%"

        applyStyles(this.closeButton, {
            position: "absolute",
            right: "10px",
            top: "10px",
            height: "24px",
            width: "24px",
            padding: "4px",
            background: `transparent url(${STATIC_URL}feedback/remove-screenshot.svg) no-repeat center center`,
            border: "none",
            cursor: "pointer",
        })
    }
}

type canvasOnlyProps = { canvas: Canvas }

export class ScreenshotControl extends Component {
    button: HTMLButtonElement
    error: HTMLElement
    screenshot: Screenshot
    isCapturing = false

    info: HTMLDivElement
    private videoStream: MediaStream

    constructor(private modal: UserFeedbackModal, private canvas: Canvas) {
        super("div", { canvas: canvas })
    }

    protected initData(props: canvasOnlyProps): void {
        this.element = createSimpleDiv()

        this.button = document.createElement("button")
        this.button.type = "button"
        this.button.textContent = i18n.feedbackAddScreenshot
        addEventListenerPoly("click", this.button, (event: MouseEvent) => {
            event.preventDefault()
            this.addScreenshot()
        })
        this.element.appendChild(this.button)

        this.screenshot = new Screenshot(this, props.canvas)
        this.addChild(this.screenshot, this.element)

        this.info = document.createElement("div")
        this.info.style.display = "none"
        this.element.appendChild(this.info)

        this.error = document.createElement("span")
        this.element.appendChild(this.error)
    }

    protected initUI(): void {
        this.element.style.width = "306px"
        this.element.style.textAlign = "center"
        this.element.style.marginBottom = "8px"
        addColorClass(this.element, "screenShotControl")

        addColorClass(this.button, "sscButton")
        applyStyles(this.button, {
            fontFamily: "UbuntuRegular, Arial, Helvetica, sans-serif",
            border: "none",
            boxSizing: "border-box",
            display: "block",
            cursor: "pointer",
            textDecoration: "underline",
            padding: "0",
            marginBottom: "16px",
        })

        addColorClass(this.error, "error")
        addColorClass(this.info, "info")
        this.info.style.margin = "8px 0 10px"
    }

    private addScreenshot(): void {
        if (!this.canvas.canScreenshot()) {
            return
        }

        this.removeScreenshot()
        this.canvas.createScreenshot()
        this.error.textContent = ""

        if (this.canvas.screenshot === undefined) {
            return
        }
        const context = this.canvas.screenshot.getContext("2d")
        if (context === null) {
            return
        }

        this.isCapturing = true
        this.modal.hide()

        let streaming = false
        const video = document.createElement("video")
        video.style.display = "none"
        this.canvas.element.appendChild(video)

        this.canvas.getDisplayMedia()
            .then((stream: MediaStream) => {
                video.srcObject = stream
                this.videoStream = stream
                video.play()  // eslint-disable-line @typescript-eslint/no-floating-promises

                addEventListenerPoly("canplay", video, () => {
                    if (!streaming) {
                        streaming = true
                        window.setTimeout(() => {
                            const windowWidth = window.innerWidth
                            const windowHeight = window.innerHeight
                            const aspectRatio = video.videoWidth / video.videoHeight
                            let width, height
                            if (windowHeight * aspectRatio < windowWidth) {
                                width = windowHeight * aspectRatio
                                height = windowHeight
                            } else {
                                height = windowWidth / aspectRatio
                                width = windowWidth
                            }

                            this.canvas.setScreenshotDims(width, height)
                            context.drawImage(video, 0, 0, width, height)

                            this.screenshot.element.style.backgroundImage = `url("${context.canvas.toDataURL("image/png")}")`
                            this.screenshot.element.style.height = `${306 * (height / width)}px`

                            this.screenshot.element.style.display = "flex"
                            this.info.style.display = "block"
                            this.button.style.display = "none"

                            video.pause()
                            this.videoStream.getTracks().forEach((track) => {
                                track.stop()
                            })

                            this.canvas.element.removeChild(video)
                            this.modal.show()

                            window.setTimeout(() => {
                                this.isCapturing = false
                            }, 1000)
                        }, 250)
                    }
                }, false)
            })
            .catch((error: MediaStreamError) => {
                this.modal.show()
                if (error.name === "NotAllowedError") {
                    this.error.textContent = i18n.feedbackPermissionDenied
                } else {
                    this.error.textContent = i18n.feedbackUnknownCaptureError
                }

                this.canvas.element.removeChild(video)
                this.removeScreenshot()
            })
    }

    public removeScreenshot(): void {
        if (this.canvas.canScreenshot()) {
            this.screenshot.element.style.backgroundImage = ""
            this.screenshot.element.style.display = "none"
            this.button.textContent = i18n.feedbackAddScreenshot
            this.info.style.display = "none"
            this.button.style.display = "block"

            this.canvas.deleteScreenshot()
            this.canvas.hide()
        }
    }
}

class DrawControls extends Component<HTMLDivElement> {
    drag: HTMLDivElement
    highlight: HTMLButtonElement
    highlightImage: HTMLImageElement
    highlightText: HTMLSpanElement
    hide: HTMLButtonElement
    hideImage: HTMLDivElement
    hideText: HTMLSpanElement
    hr: HTMLHRElement
    done: HTMLAnchorElement

    private screenshot: Screenshot | undefined

    constructor(private canvas: Canvas) {
        super("div", { canvas: canvas })
        addColorClass(this.element, "drawControls")
    }

    public setScreenshot(screenshot: Screenshot): void {
        this.screenshot = screenshot
    }

    protected initData(props: canvasOnlyProps): void {
        this.element = createSimpleDiv()
        this.element.tabIndex = -1  // Prevents escape from closing modal after interacting with this element

        this.drag = document.createElement("div")
        this.drag.style.background = `url(${STATIC_URL_ROOT}images/feedback/drag.svg) no-repeat center`
        this.element.appendChild(this.drag)

        this.highlight = document.createElement("button")
        this.highlight.type = "button"
        addColorClass(this.highlight, "highlight")

        this.highlightImage = new Image()
        this.highlightImage.src = `${STATIC_URL_ROOT}images/feedback/highlight_issue.svg`
        this.highlight.appendChild(this.highlightImage)

        this.highlightText = document.createElement("span")
        this.highlightText.textContent = i18n.feedbackHighlightControl
        this.highlight.appendChild(this.highlightText)

        addEventListenerPoly("click", this.highlight, () => {
            this.canvas.highlightBrush()
        })
        this.element.appendChild(this.highlight)

        this.hide = document.createElement("button")
        this.hide.type = "button"

        this.hideImage = document.createElement("div")
        this.hide.appendChild(this.hideImage)

        this.hideText = document.createElement("span")
        this.hideText.textContent = i18n.feedbackHideControl
        this.hide.appendChild(this.hideText)
        addColorClass(this.hide, "highlight")

        addEventListenerPoly("click", this.hide, () => {
            this.canvas.hideBrush()
        })
        this.element.appendChild(this.hide)

        this.hr = document.createElement("hr")
        this.element.appendChild(this.hr)

        this.done = document.createElement("a")
        addColorClass(this.done, "done")
        this.done.style.textDecoration = "none"
        addEventListenerPoly("click", this.done, (event: MouseEvent) => {
            event.preventDefault()
            this.updateScreenshotBackground()
            this.canvas.hide()
        })

        this.element.appendChild(this.done)
        makeDraggable(this.element, {}, [this.drag])

        addEventListenerPoly("keydown", props.canvas.element, (event: KeyboardEvent) => {
            event.stopPropagation()
            if (event.keyCode === Key.Escape && this.element.style.display === "block") {
                event.stopImmediatePropagation()
                this.updateScreenshotBackground()
                this.canvas.hide()
            }
        })
    }

    private updateScreenshotBackground(): void {
        if (this.canvas.screenshot !== undefined && this.screenshot !== undefined) {
            this.screenshot.element.style.backgroundImage = `url("${this.canvas.screenshot.toDataURL("image/png")}")`
        }
    }

    protected initUI(): void {
        applyStyles(this.element, {
            borderRadius: "4px",
            padding: "10px 15px 5px 15px",
            position: "fixed",
            zIndex: 2010,
            textAlign: "right",
            width: "115px",
            height: "101px",
            fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
            userSelect: "none",
            boxShadow: "10px 10px 25px -5px rgba(0,0,0,0.75)",
        })

        applyStyles(this.drag, {
            width: "20px",
            height: "10px",
            position: "absolute",
            right: "0",
            top: "5px",
        })

        addColorClass(this.highlight, "highlight")
        applyStyles(this.highlight, {
            border: "0",
            display: "inline-block",
            padding: "5px",
            position: "relative",
            margin: "7px 0 0 0",
            left: "-15px",
            width: "145px",
            textAlign: "left",
            cursor: "pointer",
            fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
        })

        applyStyles(this.highlightImage, {
            width: "23px",
            verticalAlign: "middle",
            marginRight: "10px",
        })

        this.highlightText.style.verticalAlign = "middle"

        addColorClass(this.hide, "highlight")
        applyStyles(this.hide, {
            border: "0",
            display: "inline-block",
            padding: "5px",
            position: "relative",
            margin: "0",
            left: "-15px",
            width: "145px",
            textAlign: "left",
            cursor: "pointer",
            fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
        })

        addColorClass(this.hideImage, "hideImage")
        applyStyles(this.hideImage, {
            width: "20px",
            verticalAlign: "middle",
            marginRight: "10px",
            height: "20px",
            display: "inline-block",
        })

        this.hideText.style.verticalAlign = "middle"

        applyStyles(this.done, {
            padding: "4px",
            cursor: "pointer",
            textAlign: "right",
        })
        this.done.textContent = i18n.doneText
    }
}

export class Canvas extends Component {
    screenshot: HTMLCanvasElement | undefined
    draw: HTMLCanvasElement | undefined
    infoTooltip: HTMLDivElement
    controls: DrawControls
    brushMouseListeners: undefined | { down: (e: MouseEvent) => void, up: (e: MouseEvent) => void, move: (e: MouseEvent) => void }

    // getDisplayMedia is still WD, so TS cannot type it right
    private mediaStreams: any // eslint-disable-line @typescript-eslint/no-explicit-any
    private fillRect = false

    constructor() {
        super()

        this.mediaStreams = navigator["mediaDevices"]

        if (this.mediaStreams === undefined || this.mediaStreams["getDisplayMedia"] === undefined) {
            this.mediaStreams = undefined
        }

        // old versions of Microsoft Edge (<=18) may have a polyfill that does not really work
        // testing for toBlob is best way to know if we are in that use case without user agent sniffing
        const c = document.createElement("canvas")
        if (c.toBlob === undefined) {
            this.mediaStreams = undefined
        }

        this.controls = new DrawControls(this)
        this.addChild(this.controls, this.element)
    }

    protected initData(): void {
        this.element = createSimpleDiv()

        this.infoTooltip = createSimpleDiv(this.element)
        this.infoTooltip.innerHTML = `${i18n.feedbackHighlightInfo} (<a href="#" onclick="this.parentNode.style.display='none';">${i18n.dismissLower}</a>)` // eslint-disable-line @multimediallc/no-inner-html
        addEventListenerPoly("click", this.infoTooltip, () => {
            this.draw?.focus()  // so that we can escape to close after (avoids focusing body)
        })
        this.infoTooltip.style.userSelect = "none"
    }

    protected initUI(): void {
        addColorClass(this.element, "canvas")
        applyStyles(this.element, {
            display: "none",
            position: "absolute",
            height: "100%",
            width: "100%",
        })

        addColorClass(this.infoTooltip, "infoToolTip")
        applyStyles(this.infoTooltip, {
            position: "fixed",
            borderWidth: "1px",
            borderStyle: "solid",
            boxSizing: "border-box",
            zIndex: 2020,
            top: "100px",
            left: "0",
            right: "0",
            margin: "0 auto",
            padding: "12px",
            fontWeight: "bold",
            width: "500px",
            textAlign: "center",
            fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
            fontSize: "1.3em",
        })
    }

    public canScreenshot(): boolean {
        // Screen capture feature detection doesn't work right for chrome on mobile - see chromium issue 1038244.
        return this.mediaStreams !== undefined && !isMobileDevice()
    }

    // getDisplayMedia is still WD, so TS cannot type it right
    public getDisplayMedia(): any { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (!this.canScreenshot()) {
            throw new ReferenceError("Display media not supported by browser")
        }

        const options = {
            // note the displaySurface constraint is not supported by most browsers
            video: { displaySurface: "browser", cursor: false },
            audio: false,
        }

        return this.mediaStreams["getDisplayMedia"](options)
    }

    public createScreenshot(): void {
        this.deleteScreenshot()

        this.screenshot = document.createElement("canvas")
        applyStyles(this.screenshot, {
            zIndex: 2000,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            margin: "0px auto",
            position: "fixed",
        })
        this.hide()
        this.element.appendChild(this.screenshot)
    }

    public deleteScreenshot(): void {
        this.deleteDraw()
        if (this.screenshot !== undefined) {
            this.element.removeChild(this.screenshot)
            this.screenshot = undefined
        }
    }

    public setScreenshotDims(width: number, height: number): void {
        if (this.screenshot !== undefined) {
            this.screenshot.width = width
            this.screenshot.height = height
            this.screenshot.style.width = `${width}px`
            this.screenshot.style.height = `${height}px`
        }
    }

    private createDraw(): void {
        if (this.screenshot === undefined) {
            return
        }

        if (this.draw !== undefined) {
            this.element.removeChild(this.draw)
            this.draw = undefined
        }

        this.draw = this.screenshot.cloneNode() as HTMLCanvasElement
        this.draw.style.zIndex = "2001"
        this.draw.tabIndex = 0
        this.element.appendChild(this.draw)

        this.refreshDraw()
    }

    private deleteDraw(): void {
        if (this.draw !== undefined) {
            this.element.removeChild(this.draw)
            this.draw = undefined
        }
    }

    public hide(): void {
        this.element.style.display = "none"
        this.clearBrush()
        this.deleteDraw()

        document.body.style.overflow = ""

        canvasClose.fire(undefined)
    }

    public show(): void {
        this.element.style.display = "block"

        this.controls.element.style.left = "75px"
        this.controls.element.style.bottom = "30px"
        this.infoTooltip.style.display = "block"

        document.body.style.overflow = "hidden"

        this.createDraw()

        this.highlightBrush()

        if (this.draw !== undefined) {
            this.draw.focus()
        }
        canvasOpen.fire(undefined)
    }

    private copyCanvas(source: HTMLCanvasElement | undefined, destination: HTMLCanvasElement | undefined): void {
        if (source === undefined || destination === undefined) {
            return
        }

        const context = destination.getContext("2d")

        if (context === null) {
            return
        }

        const oldAlpha = context.globalAlpha

        context.globalAlpha = 1
        context.clearRect(0, 0, destination.width, destination.height)
        context.drawImage(source, 0, 0)

        context.globalAlpha = oldAlpha
    }

    public refreshDraw(): void {
        if (this.draw === undefined) {
            return
        }
        const context = this.draw.getContext("2d")

        if (context === null) {
            return
        }

        this.copyCanvas(this.screenshot, this.draw)

        const oldAlpha = context.globalAlpha
        const oldStyle = context.strokeStyle

        context.globalAlpha = 0.1
        context.strokeStyle = "rgb(0, 0, 0)"
        context.fillRect(0, 0, this.draw.width, this.draw.height)

        context.globalAlpha = oldAlpha
        context.strokeStyle = oldStyle
    }

    public clearBrush(): void {
        this.controls.hide.style.backgroundColor = "transparent"
        this.controls.hide.classList.toggle("active", false)
        this.controls.highlight.style.backgroundColor = "transparent"
        this.controls.highlight.classList.toggle("active", false)

        if (this.draw !== undefined) {
            if (this.brushMouseListeners !== undefined) {
                removeEventListenerPoly("mousedown", this.draw, this.brushMouseListeners.down)
                removeEventListenerPoly("mouseup", window, this.brushMouseListeners.up)
                removeEventListenerPoly("mousemove", window, this.brushMouseListeners.move)
                this.brushMouseListeners = undefined
            }

            this.draw.style.cursor = "default"
        }
    }

    public hideBrush(): void {
        this.clearBrush()
        this.createDraw()

        if (this.draw === undefined) {
            return
        }
        const context = this.draw.getContext("2d")
        if (context === null) {
            return
        }

        addColorClass(this.controls.hide, "active")

        context.globalAlpha = 1
        context.strokeStyle = "rgb(0, 0, 0)"
        context.lineWidth = 5
        this.fillRect = true
        this.canvasBrush()
    }

    public highlightBrush(): void {
        this.clearBrush()
        this.createDraw()

        if (this.draw === undefined) {
            return
        }
        const context = this.draw.getContext("2d")
        if (context === null) {
            return
        }

        addColorClass(this.controls.highlight, "active")

        context.globalAlpha = 0.8
        context.strokeStyle = "rgb(249, 185, 144)"
        context.lineWidth = 5
        this.fillRect = false
        this.canvasBrush()
    }

    private canvasBrush(): void {
        if (this.draw === undefined) {
            return
        }

        this.draw.style.cursor = "crosshair"
        const context = this.draw.getContext("2d")
        if (context === null) {
            return
        }

        let last_mousex = 0
        let last_mousey = 0
        let mousex = 0
        let mousey = 0
        let width = 0
        let height = 0
        let mousedown = false

        const reset = () => {
            last_mousex = 0
            last_mousey = 0
            width = 0
            height = 0
            mousedown = false
        }

        this.brushMouseListeners = {
            down: (event: MouseEvent) => {
                if (mousedown) {
                    this.drawBoxOnScreenshot(last_mousex, last_mousey, width, height, context)
                    reset()
                }

                const rect = (this.draw as HTMLCanvasElement).getBoundingClientRect()
                last_mousex = event.clientX - rect.left
                last_mousey = event.clientY - rect.top
                mousedown = true
                context.canvas.style.cursor = "crosshair"
            },
            up: (event: MouseEvent) => {
                if (mousedown) {
                    this.drawBoxOnScreenshot(last_mousex, last_mousey, width, height, context)
                    reset()
                }
            },
            move: (event: MouseEvent) => {
                if (mousedown) {
                    const rect = (this.draw as HTMLCanvasElement).getBoundingClientRect()
                    mousex = event.clientX - rect.left
                    mousey = event.clientY - rect.top
                    this.refreshDraw()
                    width = mousex - last_mousex
                    height = mousey - last_mousey
                    this.drawBox(last_mousex, last_mousey, width, height, context)
                }
            },
        }

        addEventListenerPoly("mousedown", this.draw, this.brushMouseListeners.down)
        addEventListenerPoly("mouseup", window, this.brushMouseListeners.up)
        addEventListenerPoly("mousemove", window, this.brushMouseListeners.move)
    }

    private drawBox(x: number, y: number, width: number, height: number, context: CanvasRenderingContext2D): void {
        context.beginPath()
        context.rect(x, y, width, height)
        if (this.fillRect) {
            context.fill()
        } else {
            context.stroke()
        }
    }

    private drawBoxOnScreenshot(x: number, y: number, width: number, height: number, drawContext: CanvasRenderingContext2D): void {
        if (this.screenshot === undefined) {
            return
        }

        this.screenshot.style.cursor = "crosshair"
        const context = this.screenshot.getContext("2d")
        if (context === null) {
            return
        }

        context.globalAlpha = drawContext.globalAlpha
        context.strokeStyle = drawContext.strokeStyle
        context.lineWidth = drawContext.lineWidth

        this.drawBox(x, y, width, height, context)
    }
}
