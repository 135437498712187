import { SMCPresenceTopic } from "./user"

let smcPresenceTopics: SMCPresenceTopic[] = []
export function enterSmcWatcherPresence(userUid: string): void {
    if (smcPresenceTopics.some(t => t.getUserUid() === userUid)) {
        return
    }
    const topic = new SMCPresenceTopic(userUid)
    topic.enterPresence()
    smcPresenceTopics.push(topic)
}

export function leaveSmcWatcherPresence(userUid?: string): void {
    if (userUid !== undefined) {
        smcPresenceTopics = smcPresenceTopics.filter(t => {
            if (t.getUserUid() === userUid) {
                t.leavePresence()
                return false
            }
            return true
        })
    } else {
        // if no username is provided, stop watching ALL cams
        smcPresenceTopics.forEach(t => {t.leavePresence()})
        smcPresenceTopics = []
    }
}
