import { dom } from "../../../common/tsxrender/dom"

export function caretLeft(): SVGElement {
    return <svg width="8" height="12" viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.19276e-07 5.98581C-0.000425611 5.65497 0.147594 5.33999 0.406233 5.12135L6.21431 0.216066C6.6146 -0.106384 7.20982 -0.0630863 7.55493 0.313586C7.90004 0.690259 7.8688 1.26253 7.48456 1.6025L2.40562 5.89181C2.37742 5.91555 2.36125 5.9498 2.36125 5.98581C2.36125 6.02182 2.37742 6.05607 2.40562 6.07981L7.48456 10.3691C7.75191 10.5845 7.87679 10.922 7.81086 11.251C7.74493 11.58 7.49854 11.8489 7.16704 11.9537C6.83554 12.0584 6.47093 11.9826 6.21431 11.7556L0.408316 6.85177C0.149051 6.63276 0.000328855 6.31734 9.19276e-07 5.98581Z" fill="#222222" />
    </svg>
}

export function caretRight(): SVGElement {
    return <svg width="8" height="12" viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 5.98581C8.00043 5.65497 7.85241 5.33999 7.59377 5.12135L1.78569 0.216066C1.3854 -0.106384 0.790178 -0.0630863 0.44507 0.313586C0.0999627 0.690259 0.131205 1.26253 0.515437 1.6025L5.59438 5.89181C5.62258 5.91555 5.63875 5.9498 5.63875 5.98581C5.63875 6.02182 5.62258 6.05607 5.59438 6.07981L0.515437 10.3691C0.248085 10.5845 0.123215 10.922 0.189144 11.251C0.255072 11.58 0.501458 11.8489 0.832959 11.9537C1.16446 12.0584 1.52907 11.9826 1.78569 11.7556L7.59168 6.85177C7.85095 6.63276 7.99967 6.31734 8 5.98581Z" fill="#222222"/>
    </svg>
}