import { applyStyles } from "./DOMutils"

export const enum BadgeType {
    SMC = "smcBadge",
    Follower = "followerBadge",
    Notes = "notesBadge",
}

type BadgeGenerator = (username: string) => HTMLElement

const badgeOrder = [BadgeType.SMC, BadgeType.Follower, BadgeType.Notes]

export class ChatBadgeManager {
    private static badgeGenerators = new Map<BadgeType, BadgeGenerator>()

    public static registerGenerator(badgeType: BadgeType, badgeGenerator: BadgeGenerator): void {
        if (ChatBadgeManager.badgeGenerators.has(badgeType)) {
            error("Generator already registered for badge", badgeType)
            return
        }
        ChatBadgeManager.badgeGenerators.set(badgeType, badgeGenerator)
    }

    public static createBadges(username: string): HTMLSpanElement {
        const badgeSpan = document.createElement("span")
        applyStyles(badgeSpan, {
            display: "inline-block",
            verticalAlign: "middle",
            marginTop: "-.5em",  // Negative margin to keep badges from increasing height of the message div
            marginBottom: "-.5em", // Balance margin-top so the badges don't shift up
            marginRight: "1px",
            overflow: "visible",
        })

        badgeOrder.forEach((badgeType) => {
            const generator = ChatBadgeManager.badgeGenerators.get(badgeType)
            if (generator !== undefined) {
                const badge = generator(username)
                badge.classList.add(`${badgeType}_${username}`)
                badge.dataset.testid = `${badgeType}`
                badgeSpan.appendChild(badge)
            }
        })

        return badgeSpan
    }

    public static getBadgeElements(badgeType: BadgeType, username: string): HTMLElement[] {
        return Array.from(document.body.querySelectorAll(`.${badgeType}_${username}`))
    }
}
