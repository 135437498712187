import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "../api"

export interface IBroadcastDossier {
    room: string
    originHost: string
    originPassword: string
    originWSEndpoint: string
}

export function getBroadcastDossier(): Promise<IBroadcastDossier> {
    return new Promise((resolve, reject) => {
        getCb(`api/broadcastvideocontext/`).then(xhr => {
            resolve(parseBroadcastDossier(xhr.responseText))
        }).catch(reject)
    })
}

function parseBroadcastDossier(rawDossier: string): Promise<IBroadcastDossier> {
    return new Promise((resolve, reject) => {
        const settings = new ArgJSONMap(rawDossier)
        if (settings.getNumber("status", false) >= 400) {
            reject(`Bad response from server: ${rawDossier}`)
            return
        }

        const broadcastDossier = {
            room: settings.getString("room"),
            originHost: settings.getString("originHost"),
            originPassword: settings.getString("originPassword"),
            originWSEndpoint: settings.getString("originWSEndpoint"),
        } as IBroadcastDossier
        resolve(broadcastDossier)
    })
}
