import { isMobileDevice } from "@multimediallc/web-utils/modernizr"
import { Debouncer, DebounceTypes } from "../../../common/debouncer"
import { Component } from "../../../common/defui/component"
import { EventRouter, ListenerGroup } from "../../../common/events"
import { addPageAction } from "../../../common/newrelic"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { pageContext } from "../../interfaces/context"
import Key = JQuery.Key
import type {
    INavigateSuggestionInfo,
    ISuggestionActiveInfo,
    IUpdateSearchBarInfo,
} from "../../components/pm/userActionEvents"

function isArrowKey(e: KeyboardEvent): boolean {
    return e.key === "ArrowDown" || e.key === "ArrowUp" || e.keyCode === Key.ArrowDown || e.keyCode === Key.ArrowUp
}

type SearchBarProps = {
    isFullVideoMode: boolean,
    room: string,
}

interface ISearchBarEvents {
    navigateSuggestions: EventRouter<INavigateSuggestionInfo>
    openCurrentSuggestion: EventRouter<undefined>
    inputChange: EventRouter<IUpdateSearchBarInfo>
    suggestionActiveEvent: EventRouter<ISuggestionActiveInfo>
}

export class SearchBar extends Component<HTMLDivElement> {
    private debouncer: Debouncer
    private input: HTMLInputElement
    private listenerGroup: ListenerGroup
    public events: ISearchBarEvents
    public value: string

    constructor(props: SearchBarProps) {
        super("div", props)
        this.listenerGroup = new ListenerGroup()
        this.events = {
            navigateSuggestions: new EventRouter<INavigateSuggestionInfo>("navigateSuggestions"),
            openCurrentSuggestion: new EventRouter<undefined>("openCurrentSuggestion"),
            inputChange: new EventRouter<IUpdateSearchBarInfo>("inputChange"),
            suggestionActiveEvent: new EventRouter<ISuggestionActiveInfo>("suggestionActiveEvent"),
        }
        this.value = ""

        this.events.suggestionActiveEvent.listen((info: ISuggestionActiveInfo) => {
            const slug = info.slug
            this.input.value = slug === "" ? this.value : slug
        }).addTo(this.listenerGroup)

        this.debouncer = new Debouncer(() => {
            const match = this.value.match(/^[\w-]*$/)
            this.events.inputChange.fire({
                isFullVideo: props.isFullVideoMode,
                isValid: match !== null,
                prefix: this.value,
            })
        }, { bounceLimitMS: 100, debounceType: DebounceTypes.debounce })
    }

    initUI(props: SearchBarProps): void {
        const searchBarStyle: CSSX.Properties = {
            position: "relative",
            width: "100%",
            padding: "4px",
            boxSizing: "border-box",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
        }
        const inputStyle: CSSX.Properties = {
            background: "none",
            height: "24px",
            borderWidth: "1px",
            borderStyle: "solid",
            boxSizing: "border-box",
            borderRadius: "3px",
            margin: "0",
            padding: "5px 22px 5px 8px",
            width: "100%",
            outline: "none",
            fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
            fontSize: pageContext.current.isMobile ? "16px" : "12px",
        }
        const searchIconImageStyle: CSSX.Properties = {
            height: "1em",
            margin: "auto",
            position: "absolute",
            right: "10px",
            top: pageContext.current.isMobile ? "7px" : "10px",
        }

        this.element = (
            <div colorClass="SearchBar" style={searchBarStyle}>
                <input
                    colorClass="searchUserInput"
                    className="search_autocomplete"
                    data-testid="username-search"
                    type="text"
                    placeholder={i18n.searchUsers}
                    style={inputStyle}
                    onFocus={() => {
                        const location = props.room === "" ? "PMWindow" : "PMTab"
                        addPageAction("PMSearchStarted", { "location": location })
                    }}
                    onKeyDown={(e: KeyboardEvent) => {
                        if (isArrowKey(e)) {
                            e.preventDefault()
                            this.events.navigateSuggestions.fire({ event: e })
                        } else if (e.key === "Enter" || e.keyCode === Key.Enter) {
                            e.preventDefault()
                            if (isMobileDevice()) {
                                this.input.blur()
                            }
                            this.events.openCurrentSuggestion.fire(undefined)
                        }
                    }}
                    onInput={() => {
                        const val = this.input.value.toLowerCase()
                        this.value = val
                        this.input.value = val
                        this.debouncer.callFunc()
                    }}
                    ref={(el: HTMLInputElement) =>
                        this.input = el
                    }
                />
                <img
                    src={`${STATIC_URL}pms/icon-search.svg`}
                    alt="Search"
                    style={searchIconImageStyle}
                />
            </div>
        )
    }

    public getInput(): HTMLInputElement {
        return this.input
    }

    public focus(): void {
        this.input.focus()
    }

    public render(): HTMLDivElement {
        return this.element
    }

    public clear(): void {
        this.input.value = ""
    }

    public dispose(): void {
        this.listenerGroup.removeAll()
    }
}
