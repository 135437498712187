import { Component } from "../../common/defui/component"

export class ArrowContainers extends Component {
    public leftContainer: HTMLDivElement
    public rightContainer: HTMLDivElement
    private leftArrow: HTMLDivElement
    private rightArrow: HTMLDivElement

    constructor(leftClick: (ev: MouseEvent) => void, rightClick: (ev: MouseEvent) => void, private userCanAccess = true) {
        super()
        this.leftContainer = document.createElement("div")
        this.rightContainer = document.createElement("div")
        this.leftContainer.style.display = this.rightContainer.style.display = "inline-block"
        this.leftContainer.style.height = this.rightContainer.style.height = "calc(100% - 20px)"
        this.leftContainer.style.width = this.rightContainer.style.width = `50%`
        this.leftContainer.style.top = this.rightContainer.style.top = "0px"
        this.leftContainer.style.position = this.rightContainer.style.position = "absolute"
        this.leftContainer.style.left = this.rightContainer.style.right = "0px"
        if (this.userCanAccess) {
            this.leftContainer.style.cursor = this.rightContainer.style.cursor = "pointer"
        }

        this.leftArrow = document.createElement("div")
        this.rightArrow = document.createElement("div")
        this.leftArrow.style.position = this.rightArrow.style.position = "absolute"
        this.leftArrow.style.top = this.rightArrow.style.top = `${(this.leftContainer.offsetHeight - 50) / 2}px`
        this.leftArrow.style.height = this.rightArrow.style.height = "50px"
        this.leftArrow.style.width = this.rightArrow.style.width = "30px"
        this.leftArrow.style.userSelect = this.rightArrow.style.userSelect = "none"
        this.leftArrow.style.webkitUserSelect = this.rightArrow.style.webkitUserSelect = "none"
        this.leftArrow.style.left = this.rightArrow.style.right = "0"

        const createArrowImage = (arrowPos: string) => {
            const arrowSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
            const path = document.createElementNS("http://www.w3.org/2000/svg", "path")
            path.setAttribute("d", "M5.7 0L0 5.55 18.6 24 0 42.45 5.7 48 30 24 5.7 0z") // eslint-disable-line @multimediallc/no-set-attribute
            path.setAttribute("fill", "#E2E2E2") // eslint-disable-line @multimediallc/no-set-attribute
            if (arrowPos === "left") {
                path.setAttribute("transform", "translate(30, 0) scale(-1,1)") // eslint-disable-line @multimediallc/no-set-attribute
            }
            arrowSvg.appendChild(path)
            arrowSvg.setAttribute("viewBox", "0 0 30 50") // eslint-disable-line @multimediallc/no-set-attribute
            return arrowSvg
        }

        const leftArrowSvg = createArrowImage("left")
        leftArrowSvg.dataset.testid="left-arrow"
        const rightArrowSvg = createArrowImage("right")
        rightArrowSvg.dataset.testid="right-arrow"
        this.leftArrow.appendChild(leftArrowSvg)
        this.rightArrow.appendChild(rightArrowSvg)
        this.leftContainer.appendChild(this.leftArrow)
        this.rightContainer.appendChild(this.rightArrow)

        const leftPath = <SVGPathElement>(<SVGSVGElement>this.leftArrow.firstChild).firstChild
        const rightPath = <SVGPathElement>(<SVGSVGElement>this.rightArrow.firstChild).firstChild
        const mouseEnterHandler = (arrowPos: string): void => {
            if (this.userCanAccess) {
                arrowPos === "left" ? leftPath.style.fill = "#B8B8B8" : rightPath.style.fill = "#B8B8B8"
            }
        }
        const mouseLeaveHandler = (arrowPos: string): void => {
            arrowPos === "left" ? leftPath.style.fill = "#E2E2E2" : rightPath.style.fill = "#E2E2E2"
        }
        this.leftContainer.onmouseenter = () => {mouseEnterHandler("left")}
        this.leftContainer.onmouseleave = () => {mouseLeaveHandler("left")}
        this.rightContainer.onmouseenter = () => {mouseEnterHandler("right")}
        this.rightContainer.onmouseleave = () => {mouseLeaveHandler("right")}
        this.leftContainer.onclick = leftClick
        this.rightContainer.onclick = rightClick
    }

    repositionArrows(height: number): void {
        this.leftArrow.style.top = this.rightArrow.style.top = `${(height - 50) / 2}px`
    }

    shiftArrows(spaceFromEdge: number): void {
        this.leftArrow.style.left = this.rightArrow.style.right = `${spaceFromEdge}px`
    }

    showArrows(): void {
        this.leftContainer.style.visibility = this.rightContainer.style.visibility = "normal"
        if (this.userCanAccess) {
            this.leftContainer.style.cursor = this.rightContainer.style.cursor = "pointer"
        }
    }

    hideArrows(): void {
        this.leftContainer.style.visibility = this.rightContainer.style.visibility = "hidden"
    }
}
