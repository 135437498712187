import { Component } from "./defui/component"

export class Params {
    reportIfNoListeners = true
    maxHistorySize = 10  // newly connected listeners can read up to maxHistorySize old messages when starting
    listenersWarningThreshold: number | (() => number) = 50  // If using a callback for this, please include a comment explaining it
    onListenerAdded?: (count: number) => void
    onListenerRemoved?: (count: number) => void
}

// NOTE: Only use eventsPmSessionsCount for listenersWarningThreshold callbacks! Never use it for anything else. It will
// be easy for new code to forget to call inc or dec if it needs to, so this should not be relied on.
// TODO refactor PMs to use a data-only PM sessions singleton that can be our single source of truth for all things PMs
export let eventsPmSessionsCount = 0
export function incEventsPmSessionsCount(): void { eventsPmSessionsCount += 1 }
export function decEventsPmSessionsCount(): void { eventsPmSessionsCount -= 1 }

export class BoundListener<T> {
    constructor(private router: EventRouter<T>, private listener: (event: T) => void) {
    }

    public removeListener(): void {
        this.router.removeListener(this.listener)
    }

    public addTo(group: ListenerGroup): void {
        group.add(this)
    }
}

export class EventRouter<T> {
    private listeners: ((event: T) => void)[] = []
    private options: Params
    private history: T[] = []
    private counter = 0
    private idFieldName: string
    // ListenersMap is used for listeners that are added through `addListener` method which are kept in a map
    // to be able to self clean them after the listeningSource element is removed from the DOM.
    private listenersMap = new Map<string, [HTMLElement | Component, (event: T) => void]>()
    private listenersCleanUpTimeout: number | undefined

    constructor(private eventName: string, options?: Partial<Params>) {
        this.options = Object.assign(new Params(), options)
        this.idFieldName = `_eventRouter${this.eventName}Id`
    }

    public listen(listener: (event: T) => void, syncHistory = true, fireLast = false): BoundListener<T> {
        this.listeners.push(listener)
        if (syncHistory) {
            for (const event of this.history) {
                listener(event)
            }
        } else if (fireLast && this.history.length > 0) {
            listener(this.history[this.history.length - 1])
        }
        this.warnTooManyListeners()
        if (this.options.onListenerAdded !== undefined) {
            this.options.onListenerAdded(this.listeners.length)
        }
        return new BoundListener(this, listener)
    }

    private warnTooManyListeners() {
        const listenersWarningThreshold = this.getListenersWarningThreshold()
        if (listenersWarningThreshold !== -1 && this.size() > listenersWarningThreshold) {
            warn("EventRouter has too many listeners", {
                "event": this.eventName,
                "listeners": this.size(),
                "max-listeners": listenersWarningThreshold,
            })
        }
    }

    /**
     * Listens to the event safely meaning that the listener will be removed
     * if the listening source is removed from the DOM. So there is no need to call removeListener.
     *
     * **Note**: Re-adding the `listeningSource` to the DOM will not re-add the listener.
     *
     * @param listener listener
     * @param listeningSource element or component listening to the event
     */
    public addListener(listener: (event: T) => void, listeningSource: Component | HTMLElement): void {
        if (!listeningSource.hasOwnProperty(this.idFieldName)) {
            this.counter += 1
            const c = this.counter
            Object.defineProperty(listeningSource, this.idFieldName, { get() { return c } })
        }
        // @ts-ignore - runtime check/fix
        const id = listeningSource[this.idFieldName]
        this.listenersMap.set(id, [listeningSource, listener])
        window.setTimeout(() => {
            if (!EventRouter.isElementConnected(listeningSource)) {
                const listenSrcText = (listeningSource instanceof Component ? listeningSource.element : listeningSource).outerHTML
                warn(
                    `addListener for EventRouter "${this.eventName}" is called on a listeningSource which is not attached to the DOM.
                    This can result in the listener not being registered. Make sure the element is attached in the same thread as calling addListener.`,
                    { listeningSource: listenSrcText },
                )
            }
        })
        if (this.listenersCleanUpTimeout !== undefined) {
            window.clearTimeout(this.listenersCleanUpTimeout)
        }
        this.listenersCleanUpTimeout = window.setTimeout(() => {
            this.cleanUpRemovedElements()
            this.warnTooManyListeners()
        }, 100)
    }

    private cleanUpRemovedElements() {
        const listenersThresholdToClear = Math.max(1, Math.floor(this.getListenersWarningThreshold() / 2))
        if (this.listenersMap.size > listenersThresholdToClear) {
            for (const elementListenerPair of this.listenersMap.values()) {
                if (!EventRouter.isElementConnected(elementListenerPair[0])) {
                    // @ts-ignore added dynamically and has runtime checks
                    this.listenersMap.delete(elementListenerPair[0][this.idFieldName])
                }
            }
        }
    }

    private static isElementConnected(listeningSource: Component<HTMLElement, Component> | HTMLElement) {
        return (listeningSource instanceof HTMLElement && listeningSource.isConnected) ||
            (listeningSource instanceof Component && listeningSource.element.isConnected)
    }

    public once(listener: (event: T) => void, syncHistory = true): BoundListener<T> {
        const wrapped = (event: T) => {
            listener(event)
            this.removeListener(wrapped)
        }
        return this.listen(wrapped, false, syncHistory)
    }

    // fire sends the event to every listener
    public fire(event: T): void {
        const historyLen = this.history.push(event)
        if (historyLen > this.options.maxHistorySize) {
            this.history.shift()
        }
        if (this.listeners.length === 0 && this.listenersMap.size === 0 && this.options.reportIfNoListeners) {
            debug(`No listeners for event: ${this.eventName}`)
        }
        for (const listener of [...this.listeners]) {
            this.callListener(listener, event)
        }
        for (const elementListenerPair of this.listenersMap.values()) {
            const source = elementListenerPair[0]
            const listener = elementListenerPair[1]
            if (EventRouter.isElementConnected(source)) {
                this.callListener(listener, event)
            } else {
                // give another chance for the element to be connected
                window.setTimeout(() => {
                    if (EventRouter.isElementConnected(source)) {
                        this.callListener(listener, event)
                    } else {
                        // @ts-ignore added dynamically and has runtime checks
                        this.listenersMap.delete(source[this.idFieldName])
                    }
                })
            }
        }
    }

    private callListener(listener: (event: T) => void, event: T) {
        try {
            listener(event)
        } catch (e) {
            error("Event listener error", {
                "reason": e.toString(),
                "event_name": this.eventName,
                "event_listeners": this.size(),
                "listener": listener,
                "error_stack": e.stack,
            })
        }
    }

    size(): number {
        return this.listeners.length + this.listenersMap.size
    }

    public removeListener(listener: (event: T) => void): void {
        const i = this.listeners.indexOf(listener)
        if (i >= 0) {
            this.listeners.splice(i, 1)
        }
        if (this.options.onListenerRemoved !== undefined) {
            this.options.onListenerRemoved(this.listeners.length)
        }
    }

    public listenerCount(): number {
        return this.listeners.length
    }

    public historyLength(): number {
        return this.history.length
    }

    private getListenersWarningThreshold(): number {
        if (typeof this.options.listenersWarningThreshold === "number") {
            return this.options.listenersWarningThreshold
        } else {
            return this.options.listenersWarningThreshold()
        }
    }
}

// ListenerGroup allows you to avoid keeping references to each listener and calling `.remove()` on them.
// Instead, you can keep a reference to a single listenerGroup and instead call `.removeAll()`
export class ListenerGroup {
    private boundListeners: BoundListener<any>[] = [] // eslint-disable-line @typescript-eslint/no-explicit-any

    public add(boundListener: BoundListener<any>): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        this.boundListeners.push(boundListener)
    }

    public removeAll(): void {
        for (const boundListener of this.boundListeners) {
            boundListener.removeListener()
        }

        this.boundListeners = []
    }
}
