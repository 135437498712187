import { ALL, isRoomRoomlistSpaActive, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { addColorClass } from "../cb/colorClasses"
import { addEventListenerPoly, removeEventListenerPoly } from "./addEventListenerPolyfill"
import { roomCleanup } from "./context"
import { applyStyles } from "./DOMutils"
import { fullscreenElement } from "./fullscreen"
import { KeyboardEventHandlerChain } from "./keyboardEventHandler"
import { modalBlockChatFocus } from "./modalComponent"
import Key = JQuery.Key

interface IDisplayArgs {
    msg: string
    yesCallback: (form?: HTMLFormElement) => void
    noCallback?: () => void
    innerHTML?: boolean
    config?: IAdvancedModalConfig
}

const orangeButtonStyle: CSSX.Properties = {
    backgroundColor: "#F47321",
    color: "#FFFFFF",
    border: "1px solid #CD5D26",
}

class ModalAlert {
    protected message = document.createElement("div")
    private container = document.createElement("div")
    private dialog = document.createElement("div")
    private title = document.createElement("div")
    protected decline = document.createElement("button")
    protected accept = document.createElement("button")
    protected yesCallback?: (_?: HTMLFormElement) => void
    protected noCallback?: () => void
    protected teardown = () => {
    }
    protected active: boolean
    private queue: IDisplayArgs[] = []

    constructor() {
        addColorClass(this.container, "modalAlert")
        this.container.style.display = "none"
        this.container.style.position = "fixed"
        this.container.style.overflow = "auto"
        this.container.style.top = "0"
        this.container.style.left = "0"
        this.container.style.width = "100%"
        this.container.style.height = "100%"
        this.container.style.zIndex = "2000"

        addColorClass(this.dialog, "dialog")
        this.dialog.style.width = "440px"
        this.dialog.style.minWidth = "240px"
        this.dialog.style.position = "relative"
        this.dialog.style.display = "inline-block"
        this.dialog.style.borderRadius = "10px"
        this.dialog.style.textAlign = "left"
        this.dialog.style.overflow = "hidden"
        this.dialog.style.zIndex = "2001"
        this.dialog.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"

        this.title.style.display = "block"
        this.title.style.fontSize = "20px"
        this.title.style.paddingTop = "20px"
        this.title.style.paddingLeft = "20px"
        this.title.style.paddingRight = "20px"
        this.title.style.paddingBottom = "1px"
        this.title.style.fontWeight = "bold"
        this.title.style.overflow = "hidden"
        this.title.style.textOverflow = "ellipsis"
        this.dialog.appendChild(this.title)

        this.message.style.display = "inline-block"
        this.message.style.wordWrap = "break-word"
        this.message.style.padding = "20px"
        this.message.style.textAlign = "left"
        this.dialog.appendChild(this.message)

        const dialogForm = document.createElement("div")
        addColorClass(dialogForm, "dialogForm")
        applyStyles(dialogForm, {
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            textAlign: "center",
            height: "40px",
            gap: "10px",
        })

        addColorClass(this.accept, "accept")
        this.accept.style.minWidth = "70px"
        this.accept.style.padding = "3px"
        this.accept.style.marginRight = "15px"
        this.accept.style.boxSizing = "border-box"
        this.accept.innerText = "OK"
        this.accept.style.cursor = "pointer"
        this.accept.style.borderWidth = "1px"
        this.accept.style.borderStyle = "solid"
        this.accept.tabIndex = 1
        this.accept.onclick = (ev: MouseEvent) => {
            ev.stopPropagation()
            if (this.yesCallback !== undefined) {
                this.yesCallback()
            }
            modal.teardown()
        }

        addColorClass(this.decline, "decline")
        this.decline.style.minWidth = "70px"
        this.decline.style.padding = "3px"
        this.decline.innerText = "Cancel"
        this.decline.style.cursor = "pointer"
        this.decline.tabIndex = 2
        this.decline.onclick = (ev: MouseEvent) => {
            ev.stopPropagation()
            if (this.noCallback !== undefined) {
                this.noCallback()
            } else if (this.yesCallback !== undefined) {
                // no `noCallback` signifies that we are in a confirm box, and in that case we
                // always call our yesCallback
                this.yesCallback()
            }
            modal.teardown()
        }

        dialogForm.appendChild(this.decline)
        dialogForm.appendChild(this.accept)

        this.dialog.appendChild(dialogForm)
        this.container.appendChild(this.dialog)

        // ideally use URLState "room" listener in modal component bases to handle hiding all modals once it's ready,
        // (https://multimediallc.leankit.com/card/30502080746332 and https://multimediallc.leankit.com/card/30502080976671)
        if (isRoomRoomlistSpaActive()) {
            UrlState.current.listenGlobal(ALL, () => {
                this.teardown()
            })
        } else {
            // need popstate listeners for safari browser back and forward navigations to roomlist page
            addEventListenerPoly("popstate", window, () => {
                this.teardown()
            })
            roomCleanup.listen(() => {
                this.teardown()
            })
        }
    }

    resize(): void {
        this.dialog.style.width = `${Math.min(440, document.documentElement.clientWidth - 40)}px`
        this.dialog.style.left = `${Math.max(0, (this.container.offsetWidth - this.dialog.offsetWidth) / 2)}px`
        this.dialog.style.top = `${Math.max(0, (this.container.offsetHeight - 80 - this.dialog.offsetHeight) / 2)}px`
    }

    // display will show the modal.  if a `noCallback` is not set, then
    // the cancel button is not shown and the `yesCallback` will be called if
    // the user presses escape
    display(msg: string, yesCallback: (_?: HTMLFormElement) => void, noCallback?: () => void, config?: IAdvancedModalConfig, preventCancel = false): void {
        if (this.active) {
            this.queue.push({ msg: msg, yesCallback: yesCallback, noCallback: noCallback, config: config })
            return
        }

        this.message.innerText = msg

        this.handleConfig(config)

        this.container.style.display = "block"
        this.yesCallback = yesCallback
        if (noCallback === undefined) {
            modal.decline.style.display = "none"
        } else {
            this.noCallback = noCallback
            modal.decline.style.display = ""
        }

        // region keyhandlers
        const handlers = new KeyboardEventHandlerChain()
        const keyDownHandler = (event: KeyboardEvent) => {
            event.stopPropagation()
            event.preventDefault()
            handlers.execute(event)
        }
        handlers.addHandler({
            keyCode: Key.Enter,
            requiresCtrlOrMeta: false,
            handle: event => {
                if (noCallback !== undefined && document.activeElement === modal.decline) {
                    noCallback()
                    this.teardown()
                    return
                }
                yesCallback()
                this.teardown()
            },
        })
        // Only allow escaping modal when declining is an option
        if (!preventCancel) {
            handlers.addHandler({
                keyCode: Key.Escape,
                requiresCtrlOrMeta: false,
                handle: event => {
                    if (noCallback !== undefined) {
                        noCallback()
                    } else { // If it's an alert we need to know it's closed
                        yesCallback()
                    }
                    this.teardown()
                },
            })
        }
        // Allow tab to cycle through buttons when decline button is showing
        if (noCallback !== undefined) {
            handlers.addHandler({
                code: "Tab",
                requiresCtrlOrMeta: false,
                handle: event => {
                    if (document.activeElement === modal.accept) {
                        modal.decline.focus()
                    } else {
                        modal.accept.focus()
                    }
                },
            })
        }
        // endregion

        this.active = true
        const resizeHandler = () => {
            this.resize()
        }
        addEventListenerPoly("keydown", document, keyDownHandler, true)
        addEventListenerPoly("resize", window, resizeHandler)
        addEventListenerPoly("orientationchange", window, resizeHandler)
        this.teardown = () => {
            if (!this.active) {
                return
            }
            modalBlockChatFocus.fire(false)
            try {
                removeEventListenerPoly("keydown", document, keyDownHandler, true)
            } catch (e) {
                debug("ModalAlert keydown listener was already removed")
            }
            try {
                removeEventListenerPoly("resize", window, resizeHandler)
            } catch (e) {
                debug("ModalAlert resize listener was already removed")
            }
            this.container.style.display = "none"
            this.active = false
            if (this.container.parentElement === document.body) {
                document.body.removeChild(this.container)
            }

            // it's nice to un-define these early so that garbage collection for these closures can run
            this.yesCallback = undefined
            this.noCallback = undefined
            // Check if another alert is waiting in the queue
            const args = this.queue.shift()
            if (args !== undefined) {
                this.display(args.msg, args.yesCallback, args.noCallback, args.config)
            }
        }

        const fullScreenEl = fullscreenElement()
        if (fullScreenEl !== undefined && Boolean(fullScreenEl)) {
            fullScreenEl.appendChild(this.container)
        } else {
            document.body.appendChild(this.container)
        }
        this.resize()
        modalBlockChatFocus.fire(true)
    }

    handleConfig(config?: IAdvancedModalConfig): void {
        // ? null logical operator is required, instead of a higher undefined check.
        // Otherwise "else" statements won't execute.
        if (config?.title !== undefined) {
            this.title.style.display = "block"
            this.title.innerText = config.title
            if (config.titleSmall === true) {
                this.title.style.fontSize = "16px"
            }
        } else {
            this.title.style.display = "none"
        }

        if (config?.allowDecline === false) {
            applyStyles(this.decline, { display: "none" })
        }

        if (config?.acceptText !== undefined) {
            this.accept.innerText = config.acceptText
        } else {
            this.accept.innerText = "OK"
        }

        if (config?.declineText !== undefined) {
            this.decline.innerText = config.declineText
        } else {
            this.decline.innerText = "Cancel"
        }
    }

    rotateDismissTeardownHandler = (): void => {
        removeEventListenerPoly("orientationchange", window, this.rotateDismissTeardownHandler)
        this.teardown()
    }
}

interface IAdvancedModalConfig {
    title?: string
    titleSmall?: boolean
    acceptText?: string
    declineText?: string
    separator?: boolean
    allowDecline?: boolean
    orange?: boolean
}

const modal = new ModalAlert()

export function modalAlert(msg: string, callback?: () => void, config?: IAdvancedModalConfig): void {
    if (callback === undefined) {
        callback = () => {
        }
    }
    modal.display(msg, callback, undefined, config)
}

export function modalConfirm(msg: string, yesCallback?: () => void, noCallback?: () => void, config?: IAdvancedModalConfig, preventCancel = false): void {
    yesCallback = yesCallback ?? (() => {})
    noCallback = noCallback ?? (() => {})

    modal.display(msg, yesCallback, noCallback, config, preventCancel)
}

export function modalAlertRotateDismiss(msg: string, callback?: () => void, config?: IAdvancedModalConfig): void {
    const updatedCallBack = () => {
        if (callback !== undefined) {
            callback()
        }
        removeEventListenerPoly("orientationchange", window, modal.rotateDismissTeardownHandler)
    }

    modal.display(msg, updatedCallBack, undefined, config)
    addEventListenerPoly("orientationchange", window, modal.rotateDismissTeardownHandler)
}

class ModalForm extends ModalAlert {
    private form: HTMLFormElement

    constructor() {
        super()
    }

    // displayForm will show the form. Any existing modal forms will be cancelled and closed.
    displayForm(
        form: HTMLFormElement,
        submitCallback: (form: HTMLFormElement) => void,
        cancelCallback: () => void,
        message?: string,
        config?: IAdvancedModalConfig,
        preventCancel = false,
    ): void {
        this.cancelActiveForm()
        message = message ?? ""
        super.display(message, submitCallback, cancelCallback, config, preventCancel)

        const separator = document.createElement("div")
        applyStyles(separator, { minHeight: config !== undefined && config.separator === true ? "15px" : "0px" })

        this.message.appendChild(separator)

        this.form = form
        this.message.appendChild(this.form)

        if (config?.acceptText === undefined) {
            this.accept.innerText = "Apply"
        }

        if (config?.orange === true) {
            applyStyles(this.accept, orangeButtonStyle)
        }

        this.accept.onclick = (ev: MouseEvent) => {
            ev.stopPropagation()
            if (this.yesCallback !== undefined) {
                this.yesCallback(this.form)
            }
            modalForm.teardown()
        }
        this.decline.onclick = (ev: MouseEvent) => {
            ev.stopPropagation()
            if (this.noCallback !== undefined) {
                this.noCallback()
            }
            modalForm.teardown()
        }
    }

    private cancelActiveForm(): void {
        if (this.active) {
            if (this.noCallback !== undefined) {
                this.noCallback()
            }
            modalForm.teardown()
        }
    }
}

const modalForm = new ModalForm()

export function modalSubmitForm(
    form: HTMLFormElement,
    submitCallback: (form: HTMLFormElement) => void,
    cancelCallback?: () => void,
    message?: string,
    config?: IAdvancedModalConfig,
    preventCancel = false,
): void {
    cancelCallback = cancelCallback ?? (() => {})

    modalForm.displayForm(form, submitCallback, cancelCallback, message, config, preventCancel)
}
