import { pageContext } from "../cb/interfaces/context"
import { showLoginOverlay } from "../cb/ui/loginOverlay"
import { modalConfirm } from "./alerts"
import type { ILoginOverlayProps } from "../cb/ui/loginOverlay"

export function isAnonymous(): boolean {
    return pageContext.current?.loggedInUser === undefined
}

let anonymousCallback: (() => void) | undefined

export function isNotLoggedIn(msg = "Login required", useAnonCallback = true, cancelCallback?: () => void, loginOverlayProps?: ILoginOverlayProps): boolean {
    if (isAnonymous()) {
        if (anonymousCallback !== undefined && useAnonCallback) {
            anonymousCallback()
        } else {
            modalConfirm(msg, () => {
                showLoginOverlay(loginOverlayProps ?? {})
            }, cancelCallback)
        }
        return true
    }
    return false
}

export function setAnonymousCallback(callback: () => void): void {
    anonymousCallback = callback
}
