import { addEventListenerPoly } from "../../common/addEventListenerPolyfill"
import { modalAlert } from "../../common/alerts"
import { Component } from "../../common/defui/component"
import { applyStyles } from "../../common/DOMutils"
import { ListenerGroup } from "../../common/events"
import { addPageAction } from "../../common/newrelic"
import { ignoreCatch } from "../../common/promiseUtils"
import { RoomType } from "../../common/roomUtil"
import { i18n } from "../../common/translation"
import { dom } from "../../common/tsxrender/dom"
import { videoModeHandler } from "../../common/videoModeHandler"
import { getTokenBalance, sendTip, tipsInPast24HoursUpdate, TipType, tokenBalanceUpdate } from "../api/tipping"
import { pageContext } from "../interfaces/context"
import { cleanTipAmountInput, createTipAmountInput, isValidTipInput, popUpPurchasePage, popUpTokenPurchaseModal } from "../ui/tipping"
import { handleDmInputFocus } from "./pm/dmUtil"
import { CloseButton, DmWindow } from "./pm/dmWindow"

// TODO try to refactor to extend TipCallout
export class DmTippingUI extends Component {
    protected overlay: HTMLDivElement
    protected tipInputDOM: HTMLInputElement
    protected tipMessageDOM: HTMLTextAreaElement
    protected getMoreLinkDOM: HTMLAnchorElement
    private currentBalanceDOM: HTMLSpanElement
    private tokenBalance = 0
    protected wrongTipNoticeDOM: HTMLSpanElement
    private sendTipButtonDOM: HTMLButtonElement
    private listenerGroup: ListenerGroup
    private isHighTipAmountWarningActive: boolean

    constructor(private username: string, private removeUI: () => void) {
        super()

        this.listenerGroup = new ListenerGroup()
        this.isHighTipAmountWarningActive = false
        this.updateCurrentTipBalance()

        tokenBalanceUpdate.listen((tokenBalanceUpdateNotification) => {
            this.updateTokenBalance(tokenBalanceUpdateNotification.tokens)
        }, false).addTo(this.listenerGroup)
    }

    public createOverlay(): HTMLElement {
        const overlayStyle: CSSX.Properties = {
            display: "block",
            width: "100%",
            height: `calc(100% - ${DmWindow.headerHeight})`,
            position: "absolute",
            bottom: 0,
            left: 0,
            cursor: "pointer",
        }
        this.overlay = <div style={overlayStyle} colorClass="dmWindowTipOverlay" onClick={this.hideTipping} />
        return this.overlay
    }

    public createUI(): HTMLElement {
        const tippingWindowStyle: CSSX.Properties = {
            position: "absolute",
            bottom: "0px",
            right: "0px",
            height: "183px",
            minWidth: "280px",
            maxWidth: "100%",
            fontSize: "12px",
            zIndex: 999,
        }
        const tippingHeaderStyle: CSSX.Properties = {
            height: "31px",
            lineHeight: "31px",
            borderRadius: "4px 4px 0px 0px",
            padding: "0 6px",
        }
        const tippingBodyStyle: CSSX.Properties = {
            padding: "6px",
            boxSizing: "border-box",
        }
        const headerTitleStyle: CSSX.Properties = {
            fontSize: "14px",
            fontWeight: "bold",
        }
        const currentBalanceStyle: CSSX.Properties = {
            margin: "0 5px",
            display: "inline-block",
            width: "90px",
        }
        const getMoreStyle: CSSX.Properties = {
            textDecoration: "underline",
            cursor: "pointer",
        }
        const tipAmountInputStyle: CSSX.Properties = {
            width: "69px",
            height: "24px",
            borderWidth: "1px",
            borderStyle: "solid",
            boxSizing: "border-box",
            borderRadius: "4px",
            marginLeft: "5px",
        }
        const textareaStyle: CSSX.Properties = {
            width: "100%",
            height: "32px",
            borderRadius: "4px",
            resize: "none",
            borderWidth: "1px",
            borderStyle: "solid",
            margin: "5px 0 0 0",
            boxSizing: "border-box",
        }
        const wrongTipStyle: CSSX.Properties = {
            marginLeft: "8px",
            display: "none",
        }
        const sendTipButtonContainerStyle: CSSX.Properties = {
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
        }
        const sendTipButtonStyle: CSSX.Properties = {
            boxSizing: "border-box",
            maxWidth: "100%",
            height: "32px",
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        }

        this.tipInputDOM = createTipAmountInput("tipInput")
        applyStyles(this.tipInputDOM, tipAmountInputStyle)
        addEventListenerPoly("input", this.tipInputDOM, this.tipAmountChange)
        addEventListenerPoly("keydown", this.tipInputDOM, this.handleTipKeyPress)
        addEventListenerPoly("pointerdown", this.tipInputDOM, handleDmInputFocus)

        this.element = <div colorClass="dmWindowTipCallout" style={tippingWindowStyle} data-testid="dm-window-tip-callout">
            <div style={tippingHeaderStyle} colorClass="titleBar">
                <span style={headerTitleStyle}>{i18n.sendTipText}</span>
                <CloseButton style={{ right: "5px", top: "-4px", cursor: "pointer" }} clickHandler={this.hideTipping} />
            </div>
            <div style={tippingBodyStyle}>
                <div>
                    <span>{i18n.currentBalanceText}</span>
                    <span style={currentBalanceStyle} data-testid="token-balance" colorClass="tokenBalance" ref={(el: HTMLSpanElement) => this.currentBalanceDOM = el} />
                    <a onClick={() => {popUpPurchasePage({ source: pageContext.current.PurchaseEventSources["TOKEN_SOURCE_PRIVATE_MESSAGE_WINDOW"] })}}
                       colorClass="purchaseLink"
                       data-testid="purchase-tokens"
                       style={getMoreStyle}
                       ref={(el: HTMLAnchorElement) => this.getMoreLinkDOM = el}>{i18n.getMoreLabel}</a>
                </div>
                <div>
                    <span>{i18n.tipAmountText}</span>
                    { this.tipInputDOM }
                    <span style={wrongTipStyle} colorClass="tokenInputError" ref={(el: HTMLSpanElement) => {this.wrongTipNoticeDOM = el}}>{i18n.invalidAmount}</span>
                </div>
                <div>{i18n.TipOptionalMessageLabel}</div>
                <textarea style={textareaStyle}
                          data-testid="tip-message-textarea"
                          colorClass="tipMessage"
                          onPointerDown={ handleDmInputFocus }
                          ref={(el: HTMLTextAreaElement) => this.tipMessageDOM = el} />
                <div style={sendTipButtonContainerStyle}>
                    <button style={sendTipButtonStyle}
                            colorClass="sendTipButton"
                            data-testid="send-tip-button"
                            onClick={this.sendTipFn}
                            ref={(el: HTMLButtonElement) => this.sendTipButtonDOM = el}>{i18n.sendTipToUser(this.username)}</button>
                </div>
            </div>
        </div>

        return this.element
    }

    public dispose(): void {
        this.listenerGroup.removeAll()
    }

    public focus(): void {
        handleDmInputFocus(this.tipInputDOM)
        this.tipInputDOM.setSelectionRange(0, 9999)
    }

    public setFields(tipAmount?: number, message?: string): void {
        if (tipAmount !== undefined) {
            this.tipInputDOM.value = String(tipAmount)
        }
        if (message !== undefined) {
            this.tipMessageDOM.value = message
        }

        this.checkValidTip()
        this.updateCurrentTipBalance()
    }

    private updateCurrentTipBalance(): void {
        getTokenBalance(this.username).then((currentTokensResponse) => {
            this.tokenBalance = currentTokensResponse.tokenBalance
            this.currentBalanceDOM.innerText = `${currentTokensResponse.tokenBalance} ${i18n.tokenOrTokensText(currentTokensResponse.tokenBalance, false)}`
        }).catch(ignoreCatch)
    }

    private updateTokenBalance(tokenBalance: number): void {
        this.currentBalanceDOM.innerText = `${tokenBalance} ${i18n.tokenOrTokensText(tokenBalance, false)}`
    }

    private tipAmountChange = () => {
        cleanTipAmountInput(this.tipInputDOM)
        this.checkValidTip()
        if (this.isHighTipAmountWarningActive) {
            this.resetTipButtonText()
        }
    }

    private handleTipKeyPress = (event: KeyboardEvent) => {
        if (event.code === "Enter") {
            this.sendTipFn()
            event.preventDefault()
        }
    }

    private checkValidTip(): void {
        if (!isValidTipInput(this.tipInputDOM.value)) {
            this.showInvalidTipNotice()
            this.sendTipButtonDOM.style.cursor = ""
            this.sendTipButtonDOM.disabled = true
        } else {
            this.hideInvalidTipNotice()
            this.sendTipButtonDOM.style.cursor = "pointer"
            this.sendTipButtonDOM.disabled = false
        }
    }

    protected showInvalidTipNotice(): void {
        this.wrongTipNoticeDOM.style.display = ""
    }

    protected hideInvalidTipNotice(): void {
        this.wrongTipNoticeDOM.style.display = "none"
    }

    private sendTipFn = () => {
        if (!isValidTipInput(this.tipInputDOM.value)) {
            modalAlert(i18n.tipAmountInvalid)
            return
        }

        const tipAmount = parseInt(this.tipInputDOM.value)

        if (pageContext.current.isNoninteractiveUser) {
            modalAlert(i18n.internalStaffTip)
            return
        }

        if (tipAmount > this.tokenBalance) {
            popUpTokenPurchaseModal(i18n.notEnoughTokensMessage, pageContext.current.PurchaseEventSources["TOKEN_SOURCE_LOW_TOKEN_BALANCE"])
            return
        }

        addPageAction("SendTipClicked", { "amount": tipAmount, "location": "DmWindow" })

        if (tipAmount > 100 && !this.isHighTipAmountWarningActive) {
            this.setTipButtonText(i18n.tipConfirmationMessage(tipAmount))
            this.isHighTipAmountWarningActive = true
            this.sendTipButtonDOM.focus()
            return
        }

        sendTip({
            roomName: this.username,
            tipAmount: this.tipInputDOM.value,
            message: this.tipMessageDOM.value,
            source: "DM",
            tipRoomType: RoomType.DM,
            tipType: TipType.public,
            videoMode: videoModeHandler.getVideoMode(),
        }).then((sendTipResponse) => {
            if (sendTipResponse.success) {
                addPageAction("SendTipSuccess", { "amount": tipAmount, "location": "DmWindow" })
                this.hideTipping()
            } else {
                if (sendTipResponse.error !== undefined) {
                    if (sendTipResponse.showPurchaseLink === true) {
                        popUpTokenPurchaseModal(sendTipResponse.error)
                    } else {
                        modalAlert(sendTipResponse.error)
                    }
                } else {
                    error("unknown send tip error")
                    modalAlert(i18n.errorSendingTip)
                }
            }
            this.tipMessageDOM.value = ""
            if (sendTipResponse.tipsInPast24Hours !== undefined) {
                tipsInPast24HoursUpdate.fire({ tokens: sendTipResponse.tipsInPast24Hours, roomName: this.username })
            }
        }).catch(err => {
            error(`Error sending tip (${err})`)
            modalAlert(i18n.errorSendingTip)
        }).finally(() => {
            this.resetTipButtonText()
        })
    }

    private setTipButtonText(text: string): void {
        this.sendTipButtonDOM.textContent = text
        this.sendTipButtonDOM.title = text
    }

    private resetTipButtonText(): void {
        this.setTipButtonText(i18n.sendTipToUser(this.username))
        this.isHighTipAmountWarningActive = false
    }

    protected hideTipping = (): void => {
        this.removeUI()
    }

}
