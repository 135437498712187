import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { hexToHSV, HSVtoRGB, isHex, rgbToHex } from "../colorUtils"
import { ColorPickerModal } from "../theatermodelib/colorPickerModal"
import { i18n } from "../translation"
import { getViewportHeight } from "./viewportDimension"
import { isPortrait } from "./windowOrientation"

const colorBarHeight = 16
const selectorBorderWidth = 2

export class MobileColorPickerModal extends ColorPickerModal {
    private palette: HTMLDivElement
    private paletteSelector: HTMLDivElement
    private preview: HTMLDivElement
    private bar: HTMLDivElement
    private barSelector: HTMLDivElement
    private hue = 180 // between [0, 360], representing the standard color wheel
    private saturation = 1 // between [0, 1]
    private value = 1 // between [0, 1]
    private parentHasTouchScrolling = false

    constructor(swatchElement: HTMLElement, onColorPicked: (color: string) => void, onColorHovered: (color: string) => void) {
        super(swatchElement, onColorPicked, onColorHovered)

        this.element.style.position = "fixed"
        this.element.style.top = "15%"
        this.element.style.boxShadow = "0 20px 30px -3px rgba(0, 0, 0, 0.4), 0 8px 12px -2px rgba(0, 0, 0, 0.2)"
        this.element.style.backgroundColor = "white"
        this.element.style.padding = "12px"
        this.element.style.borderRadius = "8px"
        this.element.style.left = "10%"
        this.element.style.right = "10%"
        this.hexInput.style.fontSize = "16px"
        this.hexInput.style.backgroundColor = "#f1f3f4"
        this.hexInput.style.color = "rgb(88, 91, 94)"
        this.hexInput.style.padding = "6px 4px"
        this.hexInput.style.border = "none"
        this.hexInput.style.width = "calc(100% - 33px)"
        this.hexInput.style.boxSizing = "border-box"
        addEventListenerPoly("input", this.hexInput, () => {
            const color = this.hexInput.value
            if (isHex(color)) {
                this.updateColorSelectors(color, false)
            }
        })

        this.createSaveAndCancel()
    }

    protected createColorCanvas(): void {
        this.palette = document.createElement("div")
        this.paletteSelector = document.createElement("div")
        const barContainer = document.createElement("div")
        this.bar = document.createElement("div")
        this.barSelector = document.createElement("div")
        this.preview = document.createElement("div")

        this.element.appendChild(this.palette)
        this.palette.appendChild(this.paletteSelector)
        this.element.appendChild(barContainer)
        barContainer.appendChild(this.bar)
        this.element.appendChild(this.preview)
        this.bar.appendChild(this.barSelector)

        this.preview.style.height = "25px"
        this.preview.style.width = "25px"
        this.preview.style.borderRadius = "100%"
        this.preview.style.border = "1px solid black"
        this.preview.style.cssFloat = "left"
        this.preview.style.marginRight = "6px"

        this.palette.style.width = "100%"
        this.palette.style.height = "200px"
        this.palette.style.position = "relative"
        this.bar.style.background = "linear-gradient(90deg,red,#ff0,#0f0,#0ff,#00f,#f0f,red)"

        barContainer.style.padding = "2px 0"
        this.bar.style.position = "relative"
        this.bar.style.width = "100%"
        this.bar.style.height = `${colorBarHeight}px`
        this.bar.style.borderRadius = "6px"
        this.bar.style.margin = "24px 0"
        this.bar.style.padding = "0 4px"
        this.bar.style.boxSizing = "border-box"

        this.styleSelector(this.paletteSelector)
        this.styleSelector(this.barSelector)
        this.barSelector.style.boxShadow = "0 0 4px rgba(0, 0, 0, 0.8)"

        const calculateBarSelectorX = (event: TouchEvent): void => {
            event.stopPropagation()
            event.preventDefault()
            const x = event.touches[0].clientX - this.bar.getBoundingClientRect().left
            this.setBarSelectorPosition(x)
        }
        barContainer.ontouchstart = (event: TouchEvent): void => {
            calculateBarSelectorX(event)
        }
        barContainer.ontouchmove = (event: TouchEvent): void => {
            calculateBarSelectorX(event)
        }

        const calculatePaletteSelectorXY = (event: TouchEvent): void => {
            event.stopPropagation()
            event.preventDefault()
            const x = event.touches[0].clientX - this.palette.getBoundingClientRect().left
            const y = event.touches[0].clientY - this.palette.getBoundingClientRect().top
            this.setPaletteSelectorPosition(x, y)
        }
        this.palette.ontouchstart = (event: TouchEvent): void => {
            calculatePaletteSelectorXY(event)
        }
        this.palette.ontouchmove = (event: TouchEvent): void => {
            calculatePaletteSelectorXY(event)
        }
    }

    private styleSelector(selector: HTMLDivElement): void {
        selector.style.position = "absolute"
        selector.style.height = `${colorBarHeight}px`
        selector.style.width = `${colorBarHeight}px`
        selector.style.border = `${selectorBorderWidth}px solid #fff`
        selector.style.borderRadius = "100%"
        selector.style["-webkit-user-select"] = "none"
        selector.style.top = `${-selectorBorderWidth}px`
    }

    private setBarSelectorPosition(xPos: number): void {
        let left = Math.min(xPos, this.bar.offsetWidth)
        left = Math.max(0, left)
        this.hue = (left / this.bar.offsetWidth) * 360

        this.barSelector.style.left = `${left - (this.barSelector.offsetWidth / 2)}px`
        this.updateColors(true)
    }

    private setBarSelectorPercentage(percentage: number): void {
        let clampedPercentage = Math.min(1, percentage)
        clampedPercentage = Math.max(0, clampedPercentage)
        this.barSelector.style.left = `${(clampedPercentage * this.bar.offsetWidth) - (this.barSelector.offsetWidth / 2)}px`
    }

    private setPaletteSelectorPosition(xPos: number, yPos: number): void {
        let left = Math.min(xPos, this.palette.offsetWidth)
        left = Math.max(0, left)
        this.saturation = (left / this.palette.offsetWidth)

        let top = Math.min(yPos, this.palette.offsetHeight)
        top = Math.max(0, top)
        this.value = 1 - (top / this.palette.offsetHeight)

        this.paletteSelector.style.left = `${left - (this.paletteSelector.offsetWidth / 2)}px`
        this.paletteSelector.style.top = `${top - (this.paletteSelector.offsetWidth / 2)}px`
        this.updateColors(true)
    }

    private setPaletteSelectorPercentage(xPercentage: number, yPercentage: number): void {
        this.paletteSelector.style.left = `${(xPercentage * this.palette.offsetWidth) - (this.paletteSelector.offsetWidth / 2)}px`
        this.paletteSelector.style.top = `${(yPercentage * this.palette.offsetHeight) - (this.paletteSelector.offsetHeight / 2)}px`
    }

    protected repositionChildren(): void {
        this.palette.style.height = `${getViewportHeight() / 3}px`
        this.setPaletteSelectorPercentage(this.saturation, 1 - this.value)
        if (!isPortrait()) {
            this.hide()
        }
    }

    protected colorPicked(value: string): void {
        super.colorPicked(value)

        this.preview.style.backgroundColor = `${value}`
    }

    private createSaveAndCancel(): void {
        const save = this.createButton(i18n.saveCAPS)
        const cancel = this.createButton(i18n.cancelCAPS)
        save.style.backgroundColor = "rgb(255, 112, 2)"
        cancel.style.backgroundColor = "transparent"
        cancel.style.color = "#1C6B92"
        cancel.style.marginRight = "4px"
        save.onclick = () => {
            this.colorPicked(this.hexInput.value)
            this.hide()
        }
        cancel.onclick = () => {
            this.hide()
        }

        this.element.appendChild(save)
        this.element.appendChild(cancel)
    }

    private updateColors(overwriteInput: boolean): void {
        const paletteRGB = HSVtoRGB(this.hue, 1, 1)
        this.palette.style.background = `linear-gradient(to top, rgb(0, 0, 0), transparent), linear-gradient(to left, rgb(${paletteRGB.red}, ${paletteRGB.green}, ${paletteRGB.blue}), rgb(255, 255, 255))`
        this.barSelector.style.backgroundColor = `rgb(${paletteRGB.red}, ${paletteRGB.green}, ${paletteRGB.blue})`

        const previewRGB = HSVtoRGB(this.hue, this.saturation, this.value)
        this.preview.style.backgroundColor = `rgb(${previewRGB.red}, ${previewRGB.green}, ${previewRGB.blue})`
        this.paletteSelector.style.backgroundColor = `rgb(${previewRGB.red}, ${previewRGB.green}, ${previewRGB.blue})`
        if (overwriteInput) {
            this.hexInput.value = `#${rgbToHex(previewRGB).toLocaleUpperCase()}`
        }
    }

    private createButton(text: string): HTMLButtonElement {
        const button = document.createElement("button")
        button.textContent = text
        button.style.borderRadius = "4px"
        button.style.fontSize = "16px"
        button.style.color = "white"
        button.style.textAlign = "center"
        button.style.cursor = "pointer"
        button.style.border = "none"
        button.style.padding = "6px 16px"
        button.style.margin = "12px 0 4px 0"
        button.style.display = "inline-block"
        button.style.cssFloat = "right"

        return button
    }

    private updateColorSelectors(hexColor: string, overwriteInput: boolean): void {
        const hsv = hexToHSV(hexColor)
        this.hue = hsv.hue
        this.saturation = hsv.saturation
        this.value = hsv.value
        this.setBarSelectorPercentage(this.hue / 360)
        this.setPaletteSelectorPercentage(this.saturation, 1 - this.value)
        this.updateColors(overwriteInput)
    }

    public show(initialColor: string): void {
        this.initialColor = initialColor
        this.styleForShow()
        this.updateColorSelectors(initialColor, true)

        // iOS has a bug with displaying fixed components that are children of scrollable elements
        // so temporarily disable smooth scrolling while this color picker is shown
        if (this.parent !== undefined) {
            if (this.parent.element.style["-webkit-overflow-scrolling"] === "touch") {
                this.parent.element.style["-webkit-overflow-scrolling"] = ""
                this.parentHasTouchScrolling = true
            }
        }
    }

    public hide(): void {
        super.hide()
        if (this.parent !== undefined && this.parentHasTouchScrolling) {
            this.parent.element.style["-webkit-overflow-scrolling"] = "touch"
            this.parentHasTouchScrolling = false
        }
    }
}
