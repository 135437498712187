import { PageType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { getScrollingDocumentElement } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../common/addEventListenerPolyfill"
import { postCb } from "../common/api"
import { Component } from "../common/defui/component"
import { DivotPosition } from "../common/divot"
import { applyStyles } from "../common/DOMutils"
import { EventRouter } from "../common/events"
import { isEnterKey } from "../common/eventsUtil"
import { isFilterInPathActive } from "../common/featureFlagUtil"
import { addPageAction } from "../common/newrelic"
import { printCatch } from "../common/promiseUtils"
import { i18n } from "../common/translation"
import { dom } from "../common/tsxrender/dom"
import { AdvancedSettingsForm } from "./components/advancedSearchOptions/AdvancedSettingsForm"
import { RefreshThumbnailsForm } from "./components/advancedSearchOptions/refreshThumbnailsForm"
import { SearchRegionsForm } from "./components/advancedSearchOptions/searchRegionsForm"
import { pageContext, spaPageContext } from "./interfaces/context"
import { RoomReload, setRoomAnimation } from "./roomList"
import { MobileSearchInput } from "./ui/searchBar/mobileSearchInput"
import { buildTooltip } from "./ui/tooltip"


export const showSearchingOverlay = new EventRouter<boolean>("showSearchingOverlay")
export const updateShowLocation = new EventRouter<boolean>("updateShowLocation")
// Pixel breakpoints for different sizes of the searching overlay spinner
export const MIN_SEARCH_OVERLAY_BREAKPOINT = 50
export const MID_SEARCH_OVERLAY_BREAKPOINT = 200
export const MAX_SEARCH_OVERLAY_BREAKPOINT = 550

export class AdvancedSearchOptions extends Component<HTMLDivElement> {
    private optionsUpdateTimeout: number
    private isAnimThumbUnlocked = false
    private showAnimateThumbnails = false
    private animateThumbnailsInput: HTMLInputElement | null
    private closeButton?: HTMLImageElement
    readonly regionsChanged = new EventRouter<string>("regionsChanged")

    // NOTE use skipOverlays if initializing the AdvancedSearchOptions in a context where it's more convenient
    // for the calling code to control when the overlay event router fires, and have this component ONLY listen.
    constructor(private isMobile = false, private skipOverlays = false) {
        super()
        const advancedSearchOptions = document.querySelector<HTMLDivElement>("#advanced_search_options")
        if (advancedSearchOptions !== null) {
            this.element = advancedSearchOptions
            this.element.dataset.testid = "advanced-search-options-component"
            const advancedSearchElement = this.createAdvancedSearchForms()
            const header = advancedSearchOptions.firstElementChild
            if (header !== null) {
                header.insertAdjacentElement("afterend", advancedSearchElement)
            }
        }

        this.bindAnimateThumbnailForm()
        this.bindFilterLocationForm()
        this.bindFilterOptionsForm()
        this.bindAdvancedSearchRegion()
        this.bindRefreshCamForm()
        this.bindShowLocationOptionsForm()
        this.bindSearchInput()
        this.bindCloseButton()
        this.bindOpenCloseButtons()

        if (this.isAnimThumbUnlocked) {
            setRoomAnimation(this.showAnimateThumbnails)
        }

        showSearchingOverlay.listen((showOverlay) => {
            if (showOverlay) {
                this.showSearchingOverlays()
            } else {
                this.hideSearchingOverlays()
            }
        })
    }

    private createAdvancedSearchForms(): HTMLDivElement {
        const isAnimateThumbnailsDisabled = !pageContext.current.loggedInUser || !pageContext.current.loggedInUser.isSupporter
        return <div className="advance-search-element">
            {(UrlState.current.state.pageType !== PageType.HOME || !isFilterInPathActive()) && <SearchRegionsForm initial={pageContext.current.regions}></SearchRegionsForm>}
            <AdvancedSettingsForm
                showLocation={ pageContext.current.showLocation }
                animateThumbnails={ pageContext.current.animateThumbnails }
                animateThumbnailsDisabled={ isAnimateThumbnailsDisabled }
            >
            </AdvancedSettingsForm>
            <RefreshThumbnailsForm initial={pageContext.current.refreshFrequency}></RefreshThumbnailsForm>
        </div>
    }

    private setTooltipDisplayBehavior(tooltip: HTMLElement, inputLabel: HTMLLabelElement): void {
        tooltip.onmouseover = () => {
            tooltip.style.display = "block"
        }

        const isHover = (el: HTMLElement) => {
            return el.matches(":hover")
        }

        tooltip.onmouseout = () => {
            window.setTimeout(() => {
                if (!isHover(tooltip) && !isHover(inputLabel)) {
                    tooltip.style.display = "none"
                }
            }, 75)
        }

        inputLabel.onmouseover = () => {
            tooltip.style.display = "block"
        }

        inputLabel.onmouseout = () => {
            window.setTimeout(() => {
                if (!isHover(tooltip) && !isHover(inputLabel)) {
                    tooltip.style.display = "none"
                }
            }, 75)
        }
    }

    private createRoomAnimTooltip(inputEl: HTMLInputElement, inputLabel: HTMLLabelElement): HTMLDivElement {
        inputLabel.style.cursor = "default"
        inputLabel.style.opacity = "0.5"
        inputEl.style.cursor = "default"

        const disabledToolTip = buildTooltip({
            content: i18n.supporterToUseFeature(pageContext.current.PurchaseEventSources["SUPPORTER_SOURCE_PREVIEW_ROOMS_UPSELL"]),
            hasHTML: true,
            width: "auto",
            divotPosition: DivotPosition.Top,
            divotLeftOrTop: "40px",
        })

        const toolTipUpgradeLink = disabledToolTip.querySelector("a")
        if (toolTipUpgradeLink !== null) {
            toolTipUpgradeLink.onclick = () => {
                addPageAction("SupporterPageOpened", { "source": "AnimateRoomImagesTooltip" })
            }
        }

        applyStyles(disabledToolTip, {
            marginTop: "5px",
            marginLeft: "42px",
            borderStyle: "solid",
        })


        this.setTooltipDisplayBehavior(disabledToolTip, inputLabel)

        inputEl.readOnly = true
        inputEl.onclick = () => {
        }

        return disabledToolTip
    }

    private bindFilterLocationForm(): void {
        const locationFilterForm = document.querySelector<HTMLFormElement>("#filter_location_form")
        if (locationFilterForm !== null) {
            locationFilterForm.onchange = () => {
                this.onFilterFormChange(locationFilterForm)
            }
            locationFilterForm.querySelectorAll<HTMLInputElement>("input[type=checkbox]").forEach(checkbox => {
                addEventListenerPoly("change", checkbox, () => {
                    const formValue = new FormData(locationFilterForm).getAll("visible_regions").join(",")
                    this.regionsChanged.fire(formValue)
                    addPageAction("RoomsFilterRegionClicked", {
                        "region": checkbox.value,
                        "checked": checkbox.checked,
                    })
                })
            })
        }
    }

    private bindFilterOptionsForm(): void {
        const optionsFilterForm = document.querySelector<HTMLFormElement>("#filter_options_form")
        if (optionsFilterForm !== null) {
            optionsFilterForm.onchange = () => {
                this.onFilterFormChange(optionsFilterForm)
            }
        }
    }

    private bindAnimateThumbnailForm(): void {
        const animateThumbnailsForm = document.querySelector<HTMLFormElement>("#animate_thumbnails_form")
        this.animateThumbnailsInput = document.querySelector<HTMLInputElement>("#id_animate_thumbnails")
        if (animateThumbnailsForm !== null) {
            if (this.animateThumbnailsInput !== null) {
                if (!this.animateThumbnailsInput.disabled) {
                    this.isAnimThumbUnlocked = true
                }

                this.showAnimateThumbnails = this.animateThumbnailsInput.checked
                addEventListenerPoly("change", this.animateThumbnailsInput, () => {
                    if (this.isAnimThumbUnlocked) {
                        this.onFilterFormChange(animateThumbnailsForm)
                    }
                })
                const listeningEl = this.isAnimThumbUnlocked ? this.animateThumbnailsInput : animateThumbnailsForm
                listeningEl.onclick = (e) => {
                    addPageAction("AnimateRoomImagesClicked", {
                        "checked": this.animateThumbnailsInput?.checked,
                    })
                }
                if (!this.isAnimThumbUnlocked) {
                    const animateThumbnailsLabel = animateThumbnailsForm.querySelector<HTMLLabelElement>("label[for='id_animate_thumbnails']")
                    if (animateThumbnailsLabel !== null) {
                        const toolTip = this.createRoomAnimTooltip(this.animateThumbnailsInput, animateThumbnailsLabel)
                        animateThumbnailsForm.appendChild(toolTip)
                    }
                }
            }
        }
    }

    toggle = (): void => {
        this.toggleShowHide()
        addPageAction("AdvancedSearchOptionsClicked")
    }

    private bindOpenCloseButtons(): void {
        const toggleButton = document.querySelector<HTMLElement>(".advanced_search_button")
        if (toggleButton !== null) {
            toggleButton.dataset.testid = "advanced-search-button"
            toggleButton.onclick = this.toggle
        }
    }

    bindAdvancedSearchRegion = (): void => {
        const advancedSearchRegions = document.querySelectorAll<HTMLElement>(".advanced_search_region")
        const main = document.querySelector<HTMLElement>("#main")
        if (main !== null) {
            advancedSearchRegions.forEach((region) => {
                region.onclick = () => {
                    this.showElement()
                    if (getScrollingDocumentElement().scrollTop > main.offsetTop) {
                        // scroll with JS rather than simply navigating to `#main` to avoid firing popstate event
                        main.scrollIntoView()
                    }
                }
            })
        }
    }

    private showSearchingOverlays(): void {
        clearTimeout(this.optionsUpdateTimeout)
        const searchingOverlays = document.querySelectorAll<HTMLDivElement>(".searching-overlay")
        searchingOverlays.forEach((overlay) => {
            this.updateOverlayPosition(overlay)
            overlay.style.display = "block"
        })
    }

    private hideSearchingOverlays(): void {
        const searchingOverlays = document.querySelectorAll<HTMLDivElement>(".searching-overlay")
        searchingOverlays.forEach((overlay) => {
            this.updateOverlayPosition(overlay)
        })
        clearTimeout(this.optionsUpdateTimeout)
        this.optionsUpdateTimeout = window.setTimeout(() => {
            searchingOverlays.forEach((overlay) => {
                overlay.style.display = "none"
            })
        }, 300)
    }

    private onFilterFormChange(formElement: HTMLFormElement): void {
        this.setShowAnimateThumbnails()
        if (!this.skipOverlays) {
            showSearchingOverlay.fire(true)
        }
        postCb(formElement.action, new FormData(formElement))
            .then(() => {
                if (this.isAnimThumbUnlocked) {
                    setRoomAnimation(this.showAnimateThumbnails)
                }
                this.bindAdvancedSearchRegion()
                RoomReload.getInstance().loadRooms(() => {
                    if (!this.skipOverlays) {
                        showSearchingOverlay.fire(false)
                    }
                })
            }).catch(printCatch)
    }

    private getTargetedOverlayContainer(overlay: HTMLDivElement): Element | undefined {
        const overlayTargetContainer = overlay.nextElementSibling
        if (overlayTargetContainer !== null) {
            if (overlayTargetContainer.id === "discover_root") {
                return overlayTargetContainer
            } else {
                let targetedRoomList = overlayTargetContainer.querySelector(".list")
                if (targetedRoomList === null) {
                    targetedRoomList = overlayTargetContainer.lastElementChild
                }
                return (targetedRoomList === null) ? undefined : targetedRoomList
            }
        }
        return undefined
    }

    private updateOverlayPosition(overlay: HTMLDivElement): void {
        const targetedContainer = this.getTargetedOverlayContainer(overlay)

        if (targetedContainer !== undefined) {
            const targetedElement = targetedContainer as HTMLElement
            const overlayWidth = targetedElement.getBoundingClientRect().width
            const overlayHeight = targetedElement.getBoundingClientRect().height
            overlay.style.height = `${overlayHeight}px`
            overlay.style.left = `${targetedElement.offsetLeft}px`
            overlay.style.top = `${targetedElement.offsetTop}px`
            overlay.style.right = `${document.documentElement.clientWidth - targetedElement.offsetLeft - overlayWidth}px`
            overlay.style.lineHeight = `${overlayHeight}px`

            const overlaySpinner = overlay.querySelector(".searching-overlay-spinner")
            if (overlaySpinner !== null) {
                if (overlayHeight < MIN_SEARCH_OVERLAY_BREAKPOINT) {
                    overlaySpinner.classList.add("searching-overlay-spinner-very-small")
                    overlaySpinner.classList.remove("searching-overlay-spinner-small")
                    overlaySpinner.classList.remove("searching-overlay-spinner-medium")
                } else if (overlayHeight >= MIN_SEARCH_OVERLAY_BREAKPOINT && overlayHeight < MID_SEARCH_OVERLAY_BREAKPOINT) {
                    overlaySpinner.classList.remove("searching-overlay-spinner-very-small")
                    overlaySpinner.classList.add("searching-overlay-spinner-small")
                    overlaySpinner.classList.remove("searching-overlay-spinner-medium")
                } else if (overlayHeight >= MID_SEARCH_OVERLAY_BREAKPOINT && overlayHeight < MAX_SEARCH_OVERLAY_BREAKPOINT) {
                    overlaySpinner.classList.remove("searching-overlay-spinner-very-small")
                    overlaySpinner.classList.remove("searching-overlay-spinner-small")
                    overlaySpinner.classList.add("searching-overlay-spinner-medium")
                } else {
                    overlaySpinner.classList.remove("searching-overlay-spinner-very-small")
                    overlaySpinner.classList.remove("searching-overlay-spinner-small")
                    overlaySpinner.classList.remove("searching-overlay-spinner-medium")
                }
            }
        }
    }

    private bindRefreshCamForm(): void {
        const refreshFrequencyForm = document.querySelector<HTMLFormElement>("#refresh_cams_form")
        if (refreshFrequencyForm !== null) {
            refreshFrequencyForm.onchange = () => {
                const refreshFrequencyInput = refreshFrequencyForm.querySelector<HTMLInputElement>("#id_refresh_frequency")
                if (refreshFrequencyInput !== null) {
                    const time = parseInt(refreshFrequencyInput.value)
                    this.setShowAnimateThumbnails()
                    postCb(refreshFrequencyForm.action, new FormData(refreshFrequencyForm)).then(() => {
                        addPageAction("RefreshFrequencyChanged", { "seconds": time })
                        if (this.isAnimThumbUnlocked) {
                            setRoomAnimation(this.showAnimateThumbnails)
                        }
                        RoomReload.scheduleImageRefresh(time)
                        spaPageContext.setState({ refreshFrequency: time })
                    }).catch(printCatch)
                }
            }
        }
    }

    showElement(defaultDisplay = "block"): void {
        super.showElement(defaultDisplay)
        this.element.classList.remove("collapsed")
        if (this.closeButton !== undefined) {
            // Close button is absolutely positioned so it isn't hidden when overflowing the collapsed container
            this.closeButton.style.display = "block"
        }
    }

    hideElement(): void {
        super.hideElement()
        this.element.classList.add("collapsed")
        if (this.closeButton !== undefined) {
            // Close button is absolutely positioned so it isn't hidden when overflowing the collapsed container
            this.closeButton.style.display = "none"
        }
    }

    isShown(): boolean {
        return !this.element.classList.contains("collapsed")
    }

    private setShowAnimateThumbnails = (): void => {
        this.showAnimateThumbnails = (this.animateThumbnailsInput !== null) ? this.animateThumbnailsInput.checked : false
    }

    private bindSearchInput(): void {
        if (this.isMobile) {
            const searchInputDiv = new MobileSearchInput({})
            const SearchInputEntry = document.getElementById("SearchInputEntry")
            if (SearchInputEntry !== null) {
                SearchInputEntry.appendChild(searchInputDiv.element)
            }
        }

        const searchInput = document.querySelector<HTMLInputElement>(".search_input")
        const searchIcon = document.querySelector<HTMLInputElement>("#search_icon")
        const suggestions = document.querySelector<HTMLInputElement>(".suggestionsDiv")

        if (searchInput !== null && searchIcon !== null && suggestions !== null) {
            const sendSearchPageAction = () => {
                addPageAction("RoomsFilterSearchClicked", {
                    "keyword": searchInput.value,
                })
            }
            [searchIcon, suggestions].forEach(el => {
                addEventListenerPoly("click", el, evt => {
                    sendSearchPageAction()
                }, true)
            })
            const elements = [searchInput, searchIcon, suggestions]
            elements.forEach(el => {
                addEventListenerPoly("keydown", el, (evt: KeyboardEvent) => {
                    if (isEnterKey(evt)) {
                        sendSearchPageAction()
                    }
                }, true)
            })
        }
    }

    private bindCloseButton(): void {
        if (!this.isMobile) {
            const closeButtonStyle: CSSX.Properties = {
                position: "absolute",
                height: "12px",
                width: "14px",
                right: "17px",
                top: "25px",
                cursor: "pointer",
                display: "none",  // Panel initializes closed, button should not be shown
            }
            this.closeButton = <img style={closeButtonStyle} src={`${STATIC_URL}option_close.svg`} onClick={this.toggle} />
            this.element.appendChild(this.closeButton as HTMLImageElement)
        }
    }

    private bindShowLocationOptionsForm() {
        const showLocationInput = document.querySelector<HTMLInputElement>("#id_show_location")
        if (showLocationInput !== null) {
            addEventListenerPoly("change", showLocationInput, () => {
                addPageAction("ShowLocationClicked", {
                    "checked": showLocationInput.checked,
                })
                updateShowLocation.fire(showLocationInput.checked)
            })
        }
    }
}
