import { addEventListenerPoly, removeEventListenerPoly } from "./addEventListenerPolyfill"
import { Component } from "./defui/component"

export class ScrollableComponent extends Component {
    constructor() {
        super()
        this.element.style.overflow = ""
        this.element.style.position = "static"
        this.element.style.overflowX = "hidden"
        this.element.style.overflowY = "auto"
        this.enableMomentumScrollingForIPad()
    }

    private enableMomentumScrollingForIPad(): void {
        if (/iPad|iPhone|iPod/.test(navigator.platform)) {
            this.element.style["-webkit-overflow-scrolling"] = "touch"
            // the overflow style has to be scroll, so if the content is large enough to
            // create scrolling then change the overflow from "auto" to "scroll"
            const addScrollStyleOnceFn = <EventListener>() => {
                this.element.style.overflowY = "scroll"
                removeEventListenerPoly("scroll", this.element, addScrollStyleOnceFn)
            }
            addEventListenerPoly("scroll", this.element, addScrollStyleOnceFn)
        }
    }
}