import {
    deleteCookie,
    getNumberCookieOrUndefined,
} from "@multimediallc/web-utils/storage"
import { OverlayModal } from "../../common/overlayModal"
import { dom } from "../../common/tsxrender/dom"
import { mediumPopupLinkFeatures, safeWindowOpen } from "../../common/windowUtils"
import { pageContext } from "../interfaces/context"
import { showLoginOverlay } from "./loginOverlay"

const iframePath = "/accounts/register_iframe/?src=avsuccess"
export class AgeVerificationSignupOverlay extends OverlayModal {
    protected easyExit = false  // Prevent exiting with esc key/clicking in overlay
    private loginLink: HTMLAnchorElement
    private dismissLink: HTMLAnchorElement
    private loadingMessage: HTMLDivElement
    private iframeForm: HTMLIFrameElement

    constructor(props: object) {
        super(props)
        this.defineTabbableElements([this.loginLink, this.dismissLink])

        this.iframeForm.addEventListener("load",  () => {
            // Make sure popup links (eg, SSO signup) function correctly, similar to JoinOverlay component implementation.
            this.iframeForm.contentDocument?.querySelectorAll<HTMLAnchorElement>("a.medium_popup_link").forEach((link) => {
                link.onclick = (event) => {
                    event.preventDefault()
                    safeWindowOpen(link.href, "_blank", mediumPopupLinkFeatures)
                }
            })
            // Hide loading message when iframe completes loading the form
            this.loadingMessage.style.display = "none"
            this.iframeForm.style.display = "inline"
        })

        // Fixes orientation-change issues on tablet
        this.iframeForm.addEventListener("orientationchange",  () => {
            this.iframeForm.contentWindow?.location.reload()
        })
    }

    protected createElement(props: object): HTMLDivElement {
        return <div id="age-gate-signup">
            <div className="left-half">
                <div className="text-container">
                    <img className="verified-check" src={`${STATIC_URL_ROOT}images/circle-check-green.svg`}/>
                    <h2>You've been successfully verified.</h2>
                    <p>Sign up or log in so you don't have to do this again.</p>
                </div>
                <div className="link-row">
                    <a href="#" className="login"
                    ref={(el: HTMLAnchorElement) => {this.loginLink = el}}
                    onClick={(e) => {
                        e.preventDefault()
                        showLoginOverlay({})
                    }}
                    >
                        Already have an account
                    </a>
                    <a href="#" className="age-gate-signup-dismiss nooverlay"
                    onClick={(e) => this.dismissLinkClicked(e)}
                    ref={(el: HTMLAnchorElement) => {this.dismissLink = el}}
                    >
                        Skip for now
                    </a>
                </div>
            </div>
            <div className="right-half">
                <div className="loading-msg" ref={(el: HTMLDivElement) => {this.loadingMessage = el}}>Loading...</div>
                <iframe src={iframePath} ref={(el: HTMLIFrameElement) => {this.iframeForm = el}}/>
            </div>
        </div>
    }

    private dismissLinkClicked(evt: MouseEvent) {
        evt.preventDefault()
        deleteCookie("AG_Key")
        this.hide()
    }
}

const COOKIE_NAME = "AG_PAVSM"

export function showAVSignupOverlayIfEnabled(): void {
    // Make sure we don't show the modal over other account pages
    // (And particularly don't show it within the registration iframe :p)
    if (window.location.pathname.startsWith("/accounts/")) {
        return
    }
    // Shouldn't generally happen, but double-check to only show the overlay to users who:
    // have passed the age gate, aren't already logged in, and are on desktop site
    if (pageContext.current.isAgeGated || pageContext.current.loggedInUser !== undefined || pageContext.current.isMobile) {
        return
    }
    const avSignupExpGroup = getNumberCookieOrUndefined(COOKIE_NAME)
    // Prevent modal from being re-shown if dismissed, regardless of user choice. Since we only
    // set the cookie after successful AV, it won't be shown again unless they need to re-verify.
    deleteCookie(COOKIE_NAME)
    if (avSignupExpGroup === undefined || ![0, 1].includes(avSignupExpGroup)) {
        deleteCookie("AG_Key")
        return
    }
    if (avSignupExpGroup === 1) {
        new AgeVerificationSignupOverlay({}).show()
    }
}
