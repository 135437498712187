export const SPOKEN_LANGUAGES_MAP = new Map([
    ["en", "English"],
    ["id", "Bahasa Indonesia"],
    ["ms", "Bahasa Melayu (Malay)"],
    ["ca", "Català (Catalan)"],
    ["da", "Dansk (Danish)"],
    ["de", "Deutsch (German)"],
    ["es", "Español (Spanish)"],
    ["fr", "Français (French)"],
    ["it", "Italiano (Italian)"],
    ["hu", "Magyar (Hungarian)"],
    ["nl", "Nederlands (Dutch)"],
    ["no", "Norsk (Norwegian)"],
    ["pl", "Polski (Polish)"],
    ["pt", "Português (Portuguese)"],
    ["ro", "Română (Romanian)"],
    ["sk", "Slovenčina (Slovak)"],
    ["fi", "Suomi (Finnish)"],
    ["sv", "Svenska (Swedish)"],
    ["tl", "Tagalog"],
    ["vi", "Tiếng Việt (Vietnamese)"],
    ["tr", "Türkçe (Turkish)"],
    ["cs", "Čeština (Czech)"],
    ["el", "Ελληνικά (Greek)"],
    ["bg", "Български (Bulgarian)"],
    ["ru", "Русский (Russian)"],
    ["uk", "Українська (Ukrainian)"],
    ["ar", "العربية (Arabic)"],
    ["hi", "मानक हिन्दी (Hindi)"],
    ["th", "ภาษาไทย (Thai)"],
    ["zh", "中文 (Chinese)"],
    ["ja", "日本語 (Japanese)"],
    ["ko", "한국어 (Korean)"],
]);
