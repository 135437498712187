import { CbColor, ColorSpace } from "../cbcolor/cbcolor"
import { colorModeChangeRequest } from "../fullvideolib/userActionEvents"
import { IRoomMessage, IRoomNotice } from "../messageInterfaces"
import { LinearGradient } from "./linearGradient"

colorModeChangeRequest.listen((mode) => {
    const toAdjust = document.querySelectorAll(".dm-adjust")
    const isToDarkMode = mode === "darkmode"
    for (const outerDiv of toAdjust) {
        swapColors(outerDiv, isToDarkMode)
    }
})

const darkmodeChatBackground = "#202C39"

// eslint-disable-next-line complexity
export function swapColors(outerDiv: Element, isToDarkMode: boolean): void {
    if (outerDiv.getAttribute("type") === "photo") {
        return
    }
    const msg = <HTMLDivElement | null>outerDiv.firstElementChild
    if (msg !== null) {
        const isRoomNotice = msg.classList.contains("roomNotice")
        if (isRoomNotice && !(msg.textContent ?? "").startsWith("Notice:")) {
            return
        }
        const bg = isToDarkMode ? msg.getAttribute("dm-adjust-bg") ?? darkmodeChatBackground : msg.getAttribute("dm-init-bg") ?? "white"
        if (bg.startsWith("linear-gradent")) {
            msg.style.backgroundImage = bg
        } else {
            msg.style.background = bg
        }

        const fg = isToDarkMode ? msg.getAttribute("dm-adjust-fg") : msg.getAttribute("dm-init-fg")
        const fgElement = isRoomNotice ? msg : msg.querySelector("span.msg-text")
        if (fg !== null && fgElement !== null) {
            (<HTMLElement>fgElement).style.color = fg
        }
    }
}

CbColor.setColorSpace(ColorSpace.YPbPr)
const bgLightness = CbColor.darkModeBg.lightness
const fgLightness = CbColor.darkModeFg.lightness

export class DarkModeHandler {
    public background?: string
    public foreground?: string
    public bgDark?: string
    public fgDark?: string

    private constructor(msg: { fg?: string, bg?: string }) {
        this.background = msg.bg
        this.foreground = msg.fg
        this.calc()
    }

    private calc(): void {
        const gradient = LinearGradient.parse(this.background)
        const fg = CbColor.get(this.foreground ?? CbColor.darkModeFg).setAlpha(1)
        this.fgDark = fg.setLightness(fgLightness).setAlpha(1).toRgbString()

        if (gradient !== undefined) {
            gradient.stops.forEach(stop => {
                stop.color = stop.color.onBackground(CbColor.darkModeBg).setLightness(bgLightness)
            })
            this.bgDark = gradient.toString()
        } else {
            const bg = CbColor.get(this.background)
            if (!bg.isValid || bg.isWhite || bg.isTransparent || bg.isBlack) {
                this.bgDark = darkmodeChatBackground
                return
            }
            this.bgDark = bg.setLightness(bgLightness).toRgbString()
        }
    }

    public static parseNotice(msg: IRoomNotice): DarkModeHandler {
        return new DarkModeHandler({ fg: msg.foreground, bg: msg.background })
    }

    public static parseMessage(msg: IRoomMessage): DarkModeHandler {
        return new DarkModeHandler({ fg: msg.textColor, bg: msg.backgroundColor })
    }
}

function stroke(elm: HTMLElement): void {
    elm.style.textShadow = "1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000"
}

function hasNoticeInnerText(msgDiv: HTMLElement): boolean {
    if (!msgDiv.innerText) {
        return false
    }
    return msgDiv.innerText.startsWith("Notice:")
}

// for theater mode, set the colors once and forget about them
export function setColors(outerDiv: HTMLElement, initFg?: string, initBg?: string): void {
    initFg = (initFg ?? "").trim()
    initBg = (initBg ?? "").trim()

    const innerDiv = <HTMLElement> outerDiv.firstElementChild

    let msgDiv = innerDiv
    let bgDiv = outerDiv
    if (innerDiv.classList.contains("roomNotice")) {
        bgDiv = innerDiv
        if (!hasNoticeInnerText(msgDiv)) {
            return
        }
    } else {
        msgDiv = <HTMLElement> outerDiv.querySelector(".msg-text")
    }

    const fg = CbColor.get(initFg)
    if (fg.rgbRange < 10) {
        // if the color has barely any saturation, just use the default
        msgDiv.style.color = "#D3D3D3"
    } else {
        msgDiv.style.color = fg.setLightness(fgLightness).setAlpha(1).toRgbString()
    }

    if (initBg.length > 0) {
        const gradient = LinearGradient.parse(initBg)
        if (gradient !== undefined) {
            gradient.stops.forEach(stop => {
                const alpha = stop.color.getAlpha()
                if (stop.color.rgbRange < 10) {
                    stop.color = CbColor.transparent
                } else {
                    stop.color = stop.color.setLightness(bgLightness).setAlpha(alpha)
                }
            })
            bgDiv.style.background = gradient.toString()
        } else {
            let bg = CbColor.get(initBg)
            if (bg.rgbRange < 10) {
                bgDiv.style.removeProperty("background")
                stroke(msgDiv)
            } else {
                const alpha = bg.getAlpha()
                bg = bg.setLightness(bgLightness).setAlpha(alpha)
                bgDiv.style.background = bg.toRgbString()
                if (alpha < 1) {
                    stroke(msgDiv)
                }
            }
        }
    } else {
        stroke(msgDiv)
    }
}