import { isiPad, isiPhone } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { HTMLComponent } from "./defui/htmlComponent"
import { EventRouter } from "./events"
import type { BoundListener } from "./events"

export class DropDownComponentBase extends HTMLComponent<HTMLDivElement> {
    toggleEvent = new EventRouter<ToggleEvent>("dropDownToggled", { reportIfNoListeners: false })
    documentClickListener: BoundListener<Event>

    constructor(props: object, public toggleElement?: HTMLElement, keepOpenOnClickInside = true) {
        super(props)

        if (toggleElement !== undefined) {
            addEventListenerPoly("click", toggleElement, (evt: MouseEvent) => {
                if (evt.metaKey || evt.ctrlKey) {
                    return
                }
                this.toggleOverlay(evt)
            }, false)

            addEventListenerPoly("keydown", toggleElement, (evt: KeyboardEvent) => {
                if (evt.keyCode === 32 || evt.code === "Space") { // space
                    evt.preventDefault()
                    this.toggleOverlay(evt)
                }
            })
            toggleElement.classList.add("dropdown-anchor")
        }
        this.autoHide(keepOpenOnClickInside)
    }

    protected initUI(props?: object): void {
        super.initUI(props)
        this.element.style.overflow = "visible"
        this.element.style.position = "absolute"
        this.element.style.top = "0"
        this.element.style.left = "0"
        this.element.style.right = "auto"
        this.element.style.bottom = "auto"
        this.element.style.width = ""
        this.element.style.height = ""
        this.element.style.display = "none"
        this.element.style.zIndex = "4"
        this.element.classList.add("dropdown")
    }

    // Returns bool, whether element was successfully shown
    showElement(defaultDisplay = "block", evt?: Event): boolean {
        if (this.isShown()) {
            return false
        }
        this.element.style.display = defaultDisplay
        this.toggleEvent.fire(new ToggleEvent(true, evt))
        // toggleEvent can cause UI changes which lead to the element being hidden again, return dynamic value
        return this.isShown()
    }

    // Returns bool, whether element was successfully hidden
    hideElement(evt?: Event): boolean {
        const targetIsContainedDropdown = evt !== undefined && evt.composedPath().some(target =>
            target instanceof Element ? (this.element.contains(target) && target.matches(".dropdown-anchor")) : false,
        )
        if (targetIsContainedDropdown || !this.isShown()) {
            return false
        }
        this.element.style.display = "none"
        this.toggleEvent.fire(new ToggleEvent(false, evt))
        // toggleEvent can cause UI changes which lead to the element being shown again, return dynamic value
        return !this.isShown()
    }

    // Returns bool, whether overlay is shown
    toggleOverlay(evt?: Event): boolean {
        if (!this.isShown()) {
            return this.showElement(undefined, evt)
        } else {
            return !this.hideElement(evt)
        }
    }

    autoHide(keepOpenOnClickInside = true): void {
        this.documentClickListener = documentClickEvent.listen(event => {
            if (this.isShown() && event.target instanceof Element &&
                (!keepOpenOnClickInside || !this.element.contains(event.target)) &&
                (this.toggleElement === undefined || !this.toggleElement.contains(event.target))) {
                this.hideElement(event)
            }
        })
    }

    dispose(): void {
        this.documentClickListener.removeListener()
    }
}


export const documentClickEvent = new EventRouter<Event>("documentClick")

let dragging = false
let touchendTimeout: number | undefined

addEventListenerPoly("touchmove", document, () => {
    dragging = true
})
addEventListenerPoly("touchstart", document, () => {
    dragging = false
})
addEventListenerPoly("click", document, (event) => {
    documentClickEvent.fire(event)
    if (touchendTimeout !== undefined) {
        clearTimeout(touchendTimeout)
        touchendTimeout = undefined
    }
})
// iPad and iOS <= 12 sometimes do not trigger click event all the way up to the document hence we listen to touchend too.
if (isiPad() || isiPhone()) {
    addEventListenerPoly("touchend", document, (event) => {
        if (!dragging) {
            // if we close too early on touch event the click event after that will be triggered
            // for elements underneath the dropdown causing unwanted click on the content hence
            // we have a timeout here to allow the click to be captured on the dropdown if it does.
            touchendTimeout = window.setTimeout(() => {
                touchendTimeout = undefined
                documentClickEvent.fire(event)
            }, 200)
        }
    }, false)
}

export class ToggleEvent {
    isShowing: boolean
    event?: Event

    constructor(isShowing: boolean, event?: Event) {
        this.isShowing = isShowing
        this.event = event
    }
}
