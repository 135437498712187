import { Component } from "../../common/defui/component"
import { applyStyles } from "../../common/DOMutils"
import { dom } from "../../common/tsxrender/dom"

export enum ChatButtonType {
    Text,
    Icon,
}

interface IChatButtonHolderProps {
    columnGap: string
    containerStyle?: CSSX.Properties
}

export class ChatButtonHolder extends Component<HTMLDivElement, IChatButtonHolderProps> {
    private columnGap: string | undefined
    private lastChildEl: HTMLElement | undefined

    constructor(props?: IChatButtonHolderProps) {
        super("div", props)
    }

    protected initData(props?: IChatButtonHolderProps): void {
        this.columnGap = props?.columnGap
    }

    protected initUI(props?: IChatButtonHolderProps): void {
        const containerStyle: CSSX.Properties = {
            display: "flex",
            alignItems: "center",
            minWidth: "0px", // Allow grid to shrink ChatButtonHolder below default content size
            ...props?.containerStyle,
        }

        this.element = <div style={containerStyle} />
    }

    public addButton(button: Component | HTMLElement, buttonType: ChatButtonType): void {
        if (this.lastChildEl !== undefined && this.columnGap !== undefined) {
            // Can switch to using `gap` rather than `margin-right` when all our supported browsers support `gap` with flex layouts
            applyStyles(this.lastChildEl, { marginRight: this.columnGap })
        }

        if (button instanceof Component) {
            this.addChild(button)
        } else {
            this.element.appendChild(button)
        }

        const buttonEl = button instanceof Component ? button.element : button
        if (buttonType === ChatButtonType.Text) {
            applyStyles(buttonEl, { minWidth: "0px" }) // Allow flex to shrink text buttons below content size
        }
        this.lastChildEl = buttonEl
    }
}
