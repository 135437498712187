interface KeyboardHandler {
    keyCode?: number,
    code?: string,
    key?: string,
    requiresCtrlOrMeta: boolean,
    handle: (event: KeyboardEvent) => void
}

export class KeyboardEventHandlerChain {
    private handlers: KeyboardHandler[] = []

    addHandler(handler: KeyboardHandler): void {
        this.handlers.push(handler)
    }

    // eslint-disable-next-line complexity
    execute(event: KeyboardEvent): boolean {
        for (const handler of this.handlers) {
            if (handler.requiresCtrlOrMeta && !(event.ctrlKey || event.metaKey)) {
                continue
            }
            if (handler.code !== undefined && event.code === handler.code) {
                handler.handle(event)
                return true
            }
            if (handler.key !== undefined && event.key === handler.key) {
                handler.handle(event)
                return true
            }
            // keyCode is deprecated and which are deprecated
            if (event.which === handler.keyCode && (!handler.requiresCtrlOrMeta || event.ctrlKey || event.metaKey)) {
                handler.handle(event)
                return true
            }
        }
        return false
    }
}
