import { i18n } from "../../../../common/translation"
import { pageContext } from "../../../interfaces/context"

export const enum MoreRoomRecommendationSources {
    popular = "Popular",
    recommendedConfig = "rec_config",
    unknown = "unknown",
}

export function isRecommendedConfigLabel(label: string): boolean {
    return resolveRecommendedLabel(label) === MoreRoomRecommendationSources.recommendedConfig
}

export function useRecommendedLabel(label: string | undefined): boolean {
    return label !== undefined && isRecommendedConfigLabel(label)
}

export function determineRoomLabel(sourceInfo: string | undefined, roomLabel: string = MoreRoomRecommendationSources.unknown): string {
    if (sourceInfo !== undefined) {
        const potentialLabel = resolveRecommendedLabel(sourceInfo)
        if (potentialLabel === MoreRoomRecommendationSources.recommendedConfig && pageContext.current?.isInternal) {
            return sourceInfo
        }
    }
    return roomLabel
}

function resolveRecommendedLabel(label: string): MoreRoomRecommendationSources {
    if (label.startsWith(MoreRoomRecommendationSources.recommendedConfig)) {
        return MoreRoomRecommendationSources.recommendedConfig
    }
    switch (label) {
        case MoreRoomRecommendationSources.popular:
            return MoreRoomRecommendationSources.popular
        default:
            return MoreRoomRecommendationSources.unknown
    }
}

export function getRecommendedDisplayLabel(label: string): string {
    switch (label) {
        case "recommended":
            return i18n.recommendedCAPS
        default:
            return label
    }
}
