import { getPageHashtag, isPremium, isRoomRoomlistSpaActive } from "@multimediallc/cb-roomlist-prefetch"
import { titleCase } from "@multimediallc/web-utils"
import { getCookieOrUndefined, setSessionCookie } from "@multimediallc/web-utils/storage"
import { EventRouter } from "../../../common/events"
import { featureFlagIsActive } from "../../../common/featureFlag"
import { isBlockMetaDataActive, isLanguageSubdomainActive } from "../../../common/featureFlagUtil"
import { Gender, getCurrentGender } from "../../../common/genders"
import { i18n } from "../../../common/translation"
import { reloadPage } from "../../../common/windowUtils"
import { pageContext, spaPageContext } from "../../interfaces/context"
import { currentSiteSettings } from "../../siteSettings"

export function getRoomlistPageTitle(): string {  // eslint-disable-line complexity
    if (useSpaMetaUpdate() && currentSiteSettings.isWhiteLabel && currentSiteSettings.homepageTitle !== undefined && currentSiteSettings.homepageTitle !== "") {
        return currentSiteSettings.homepageTitle
    }

    const titleStrings: string[] = []
    // At least for now, don't pass includeParams to getPageHashtag() because we only want
    // the hashtag title condition to apply for actual /tag/<sometag>/ paths.
    const hashtag = getPageHashtag()
    const gender = getCurrentGender()
    const siteName = titleCase(currentSiteSettings.siteName)
    // Title strings are not mutually exclusive, include all that apply
    if (hashtag !== undefined) {
        titleStrings.push(i18n.roomlistPageTitleHashtag(titleCase(hashtag), siteName))
    }
    if (isPremium()) {
        titleStrings.push(i18n.roomlistPageTitlePremium(siteName))
    }

    if (gender === Gender.Female) {
        titleStrings.push(i18n.roomlistPageTitleFemale(siteName))
    } else if (gender === Gender.Male) {
        titleStrings.push(i18n.roomlistPageTitleMale(siteName))
    } else if (gender === Gender.Couple) {
        titleStrings.push(i18n.roomlistPageTitleCouple(siteName))
    } else if (gender === Gender.Trans || gender === Gender.OldTrans) {
        titleStrings.push(i18n.roomlistPageTitleTrans(siteName))
    } else if (hashtag === undefined && !isPremium()) {
        titleStrings.push(i18n.roomlistPageTitleDefault(siteName))
    }

    return titleStrings.join(" ")
}

export const genderFilterUpdateFromNav = new EventRouter<Gender>("genderFilterUpdateFromNav")
// Can be fired to trigger SPA navigation handling
export const spaNavigation = new EventRouter<MouseEvent>("spaNavigation")
// Fired at the *end* of SPA navigation handling
export const newSpaLoad = new EventRouter<void>("newSpaLoad")

export const ROOMLIST_NAV_ATTRIBUTE = "data-roomlist-spa-nav"

// We need to handle the join overlay separately on SPA for now, so to simplify this handling we have some
// helper functions that set/check a session cookie that prevents it from being shown
const OVERLAY_DISMISSED_KEY = "jnoldsms"  // Session cookie to prevent showing overlay repeatedly

function isJoinOverlayDismissed(): boolean {
    return getCookieOrUndefined(OVERLAY_DISMISSED_KEY) === "1"
}

export function setJoinOverlayDismissed(): void {
    setSessionCookie(OVERLAY_DISMISSED_KEY, "1", isLanguageSubdomainActive())
}

// Verifies whether the join overlay should be enabled and has not previously been dismissed. If eventTarget is
// passed it will also perform some additional checks to verify that this element should really trigger the overlay
// Unlike typical join overlay setup, this explicitly excludes the (Get More) tokens link to avoid a double-overlay
export function shouldShowJoinOverlay(eventTarget?: HTMLAnchorElement): boolean {
    const isValidTarget = (eventTarget === undefined) ? true
        : !(eventTarget.classList.contains("nooverlay") || eventTarget.classList.contains("purchase_tokens") || eventTarget.getAttribute("href") === "#")
    return (
        isValidTarget
        && pageContext.current.loggedInUser === undefined
        && !isJoinOverlayDismissed()
    )
}

export function shouldBlur(): boolean {
    if (!featureFlagIsActive("BlurThumbnails")) {
        return false
    }

    const entrance_terms_shown = document.getElementsByClassName("entrance-terms--shown").length > 0
    if (entrance_terms_shown) {
        const entrance_terms = document.getElementById("entrance_terms_overlay")
        if (entrance_terms !== null) {
            if (checkTampered(entrance_terms)) { reloadPage() }
        } else { // they deleted the modal
            reloadPage()
        }
        return true
    }

    if (pageContext.current.isAgeGated) {
        const age_gate = document.getElementById("age_gate_overlay")
        if (age_gate !== null) {
            if (checkTampered(age_gate)) {
                reloadPage()
            }
        } else { // they deleted the modal
            reloadPage()
        }
        return true
    }

    return false
}

function checkTampered(element: HTMLElement): boolean {
    return element.style.display === "none" || element.style.visibility === "hidden" || element.style.opacity !== "" || element.style.zIndex !== ""
}

export function isRoomRoomlistSpaEligiblePage(): boolean {
    return isRoomRoomlistSpaActive() && spaPageContext.getState().pageLocation !== undefined
}

export const useSpaMetaUpdate = (): boolean => isBlockMetaDataActive() || isRoomRoomlistSpaEligiblePage()

