export function durationToSeconds(duration) {
    let seconds = 0;
    if (duration.days !== undefined) {
        seconds += duration.days * 24 * 60 * 60;
    }
    if (duration.hours !== undefined) {
        seconds += duration.hours * 60 * 60;
    }
    if (duration.minutes !== undefined) {
        seconds += duration.minutes * 60;
    }
    if (duration.seconds !== undefined) {
        seconds += duration.seconds;
    }
    return seconds;
}
