import { addPageAction, newRelicEnabled } from "../../common/newrelic"
import type { ArgJSONMap } from "@multimediallc/web-utils"

interface IVerifyItem {
    clientName: string,
    tid: string,
    topicKey: string,
    messageHash: string,
    initialClients: string[],
}

interface IVerifyMeta {
    messageHash: string,
    timestamp: number,
    clients: string[]
    initialClients: string[],
}

export class PushClientVerifier {
    private readonly startTime = new Date().getTime()
    private messages = new Map<string, IVerifyMeta>()
    private timeouts = new Set<number>()
    private totalFailures = 0
    private connectedClients: string[] = []
    private connectionHistory: Record<string, number> = {}
    private messageCount: Record<string, number> = {}
    private roomCount: number

    private readonly expireTime = 90 * 1000
    private readonly lateTime = 3000
    constructor(private activeClients: string[]) {
        // always remove wowza from client lists
        this.invalidateClient("w")
    }

    public verify(clientName: string, topicKey: string, data: ArgJSONMap): void {
        this.setLastMessage(clientName, new Date().getTime())
        if (!this.activeClients.includes(clientName)) {
            // add back to clients
            this.activeClients.push(clientName)
        }
        if (!this.connectedClients.includes(clientName)) {
            this.connectedClients.push(clientName)
        }
        if (this.activeClients.length < 2) {
            // only one client, dont need to verify
            return
        }
        const item: IVerifyItem = {
            clientName,
            topicKey,
            tid: data.getString("tid"),
            messageHash: this.hashMessage(data),
            initialClients: this.connectedClients,
        }
        this.doVerify(item)
    }

    public invalidateClient(clientName: string): void {
        // update clients so we know to stop looking for it
        this.activeClients = this.activeClients.filter(c => c !== clientName)
    }

    private buildMeta(item: IVerifyItem): IVerifyMeta {
        return {
            messageHash: item.messageHash,
            timestamp: new Date().getTime(),
            clients: [item.clientName],
            initialClients: item.initialClients,
        }
    }

    private doVerify(item: IVerifyItem) {
        const existing = this.messages.get(item.tid)
        if (existing !== undefined) {
            return this.handleItemMatch(existing, item)
        }

        const meta = this.buildMeta(item)
        this.messages.set(item.tid, meta)
        this.trackTimeout(() => {
            this.handleItemNeverArrived(item.tid, meta, item.topicKey)
            this.messages.delete(item.tid)
        })
    }

    private recordFailure(reason: string, atts: object = {}, topicKey: string): void {
        this.totalFailures += 1
        const nrAtts = {
            ...atts,
            ...this.connectionHistory,
            ...this.messageCount,
            "topic": topicKey.split(":")[0],
            "topicKey": topicKey,
            "userid": topicKey.split(":")[1],
            "reason": reason,
            "start_time": this.startTime,
            "total_failures": this.totalFailures,
            "room_count": this.roomCount,
        }
        debug("Push Service clients verifier failure", nrAtts)
        if (newRelicEnabled()) {
            addPageAction("PushClientVerifier", nrAtts)
        }
    }


    private handleItemMatch(existing: IVerifyMeta, item: IVerifyItem): void {
        if (existing.clients.includes(item.clientName)) {
            // not sure how this is possible
            // dont add so counts add up properly
            return
        }
        existing.clients.push(item.clientName)
        const howLate = new Date().getTime() - existing.timestamp
        if (howLate > this.lateTime) {
            this.handleItemLate(existing, item, howLate)
        }
        if (existing.messageHash !== item.messageHash) {
            this.handleItemDiffers(existing, item)
        }
        const failedClients = this.activeClients.filter(client => !existing.clients.includes(client))
        if (failedClients.length === 0) {
            debug("Push service clients verifier success", existing)
        }
    }

    private handleItemNeverArrived(tid: string, existing: IVerifyMeta, topicKey: string): void {
        const failedClients = this.activeClients.filter(client => !existing.clients.includes(client))
        if (failedClients.length > 0) {
            this.recordFailure("never_arrived", {
                "tid": tid,
                "first_ts": existing.timestamp,
                "first_client": existing.clients[0],
                "failedClients": failedClients,
                "initialClients": existing.initialClients,
                "connectedClients": this.connectedClients,
                "isConnected": this.connectedClients.includes(failedClients[0]) ? true : false,
            }, topicKey)
        }
    }

    private handleItemDiffers(existing: IVerifyMeta, item: IVerifyItem): void {
        this.recordFailure("differs", {
            "tid": item.tid,
            "first_ts": existing.timestamp,
            "first_client": existing.clients[0],
            "failed_client": item.clientName,
            "first_hash": existing.messageHash,
            "differ_hash": item.messageHash,
        }, item.topicKey)
    }

    private handleItemLate(existing: IVerifyMeta, item: IVerifyItem, late: number): void {
        this.recordFailure("late", {
            "late": late,
            "tid": item.tid,
            "first_ts": existing.timestamp,
            "first_client": existing.clients[0],
            "failed_client": item.clientName,
        }, item.topicKey)
    }

    private trackTimeout(fn: () => void): void {
        const timeoutId = window.setTimeout(() => {
            fn()
            this.timeouts.delete(timeoutId)
        }, this.expireTime)
        this.timeouts.add(timeoutId)
    }

    private hashMessage(data: ArgJSONMap): string {
        // we need to use raw json to explicitly drop keys
        const json = JSON.parse(data.stringMessage)
        delete json["providerData"]
        delete json["id"]
        delete json["pub_ts"]
        delete json["ts"]
        delete json["method"]
        delete json["channel"]
        return JSON.stringify(json)
    }

    public recordDisconnect(client: string): void {
        this.connectedClients = this.connectedClients.filter(c => c !== client)
    }

    private setLastMessage(client: string, time: number): void {
        this.connectionHistory[`${client}_time`] = time
        this.messageCount[`${client}_messages`] = (this.messageCount[`${client}_messages`] ?? 0) + 1
    }

    public setRoomCount(roomCount: number): void {
        this.roomCount = roomCount
    }

    public validateClient(client: string): void {
        if (!this.activeClients.includes(client)) {
            this.activeClients.push(client)
        }
    }
}
