export function mean(values: number[]): number {
    let s = 0
    for (const value of values) {
        s += value
    }
    return s / values.length
}

export function variance(values: number[]): number {
    const m = mean(values)
    const squares: number[] = []
    for (const value of values) {
        squares.push(Math.abs(value - m))
    }
    return Math.sqrt(mean(squares))
}

export function standardDeviation(values: number[]): number {
    return Math.sqrt(variance(values))
}
