import { postCb } from "../../common/api"
import { refreshBroadcastBioTabSocialMedia } from "../../common/broadcastlib/broadcastTabs/broadcastBioTab"
import type { Subgender } from "../../common/genders"


export function updateSubgenderFromForm(data: FormData): Promise<Subgender> {
        return new Promise<Subgender> ((resolve, reject) => {
        postCb(`api/ts/accounts/editsubgender/`, data).then(() => {
                resolve(data.get("subgender") as Subgender)
            if (typeof window[refreshBroadcastBioTabSocialMedia] === "function") {
                window[refreshBroadcastBioTabSocialMedia]()
            }
        }).catch(() => {
            reject()
        })
    })
}
