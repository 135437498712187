import { normalizeResource } from "../../../common/api"
import { Component } from "../../../common/defui/component"
import { getTextWidth } from "../../../common/DOMutils"
import { dom } from "../../../common/tsxrender/dom"
import { addColorClass, removeColorClass } from "../../colorClasses"

type DmUsernameLabelProps = {
    username: string,
    colorClass?: string,
    clickUsernameFn: () => void
}

export class UsernameLabel extends Component<HTMLDivElement, DmUsernameLabelProps> {
    private usernameLink: HTMLAnchorElement
    private icon: HTMLImageElement | undefined
    private numUnreadDisplay: DmNumUnreadDisplay
    private colorClass: string

    constructor(private props: DmUsernameLabelProps) {
        super("div", props)
        this.colorClass = props.colorClass ?? "defaultUser"
        this.applyColor(this.colorClass)
    }

    protected initUI(props: DmUsernameLabelProps): void {
        const usernameDivStyle: CSSX.Properties = {
            position: "relative",
            display: "inline-block",
            margin: "10px 0 0 8px",
            width: "calc(100% - 48px)",
            fontSize: "12px",
            fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
            fontWeight: "bold",
            lineHeight: "14px",
        }
        const usernameTextStyle: CSSX.Properties = {
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "inline-block",
            cursor: "pointer",
        }

        const onUsernameTextClick = (e: MouseEvent) => {
            e.stopPropagation()
            props.clickUsernameFn()
        }

        this.element = <div style={usernameDivStyle}>
            <a style={usernameTextStyle} onClick={onUsernameTextClick} target="_blank" ref={(el: HTMLAnchorElement) => this.usernameLink = el} data-testid = "username">
                {props.username}
            </a>
            <DmNumUnreadDisplay classRef={c => this.numUnreadDisplay = c}/>
        </div>
    }

    public applyColor(colorClass: string): void {
        removeColorClass(this.usernameLink, this.colorClass)
        this.colorClass = colorClass
        addColorClass(this.usernameLink, colorClass)

        // Add fanclub member icon, or remove it
        if (colorClass === "inFanclub") {
            if (this.icon === undefined) {
                this.icon = document.createElement("img")
                this.icon.src = `${STATIC_URL}badge_star.svg`
                this.icon.height = 16
                this.element.insertBefore(this.icon, this.usernameLink)
                this.usernameLink.style.position = "relative"
                this.usernameLink.style.marginLeft = "5px"
                this.usernameLink.style.marginBottom = "2px"
                this.usernameLink.style.maxWidth = "calc(100% - 24px)"
            }
        } else if (this.icon !== undefined) {
            this.element.removeChild(this.icon)
            this.icon = undefined
            this.usernameLink.style.position = ""
            this.usernameLink.style.marginLeft = ""
            this.usernameLink.style.marginBottom = ""
            this.usernameLink.style.maxWidth = "100%"
        }
    }

    public updateNumUnread(num: number): void {
        this.numUnreadDisplay.updateNum(num)
    }

    public toggleUnreadDot(show: boolean): void {
        this.numUnreadDisplay.toggleDot(show)
    }

    public activateUsernameLink(): void {
        this.usernameLink.href = normalizeResource(`/${this.props.username}`)
    }

    public clone(): UsernameLabel {
        const clone = new UsernameLabel({ ...this.props, colorClass: this.colorClass })
        clone.updateNumUnread(this.numUnreadDisplay.getNum())
        clone.toggleUnreadDot(this.numUnreadDisplay.usingDot())
        return clone
    }

    render(): HTMLDivElement {
        return this.element
    }
}

// TODO move into its own file. For now keeping here to not break the PR file limit as badly
class DmNumUnreadDisplay extends Component<HTMLDivElement> {
    private numDisplay: HTMLDivElement
    private dot: HTMLImageElement
    private numUnread: number

    constructor() {
        super("div")
    }

    protected initUI(): void {
        const wrapperStyle: CSSX.Properties = {
            display: "none",
        }
        const unreadNumDivStyle: CSSX.Properties = {
            position: "relative",
            display: "none",
            left: "4px",
            bottom: "2px",
            minWidth: "16px",
            height: "15px",
            paddingTop: "1px",
            backgroundColor: "#F47321",
            color: "#FFFFFF",
            fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
            textAlign: "center",
            borderRadius: "4px",
            fontWeight: "normal",
        }
        const unreadDotStyle: CSSX.Properties = {
            position: "relative",
            top: "-8px",
            marginLeft: "4px",
            display: "none",
        }

        this.element = <div style={wrapperStyle}>
            <div className="numDisplay"
                 style={unreadNumDivStyle}
                 ref={(el: HTMLDivElement) => this.numDisplay = el}
            />
            <img src={`${STATIC_URL}orange_dot.svg`}
                 style={unreadDotStyle}
                 ref={(el: HTMLImageElement) => this.dot = el}
            />
        </div>
    }

    public updateNum(numUnread: number): void {
        this.numUnread = numUnread
        if (numUnread > 0) {
            this.element.style.display = "inline-block"
            this.numDisplay.textContent = numUnread > 9 ? "9+" : `${numUnread}`
            this.numDisplay.style.width = `${getTextWidth(this.numDisplay.textContent, this.numDisplay) + 4}px`
        } else {
            this.element.style.display = "none"
        }
    }

    public getNum(): number {
        return this.numUnread
    }

    public toggleDot(show: boolean): void {
        if (show) {
            this.numDisplay.style.display = "none"
            this.dot.style.display = ""
        } else {
            this.numDisplay.style.display = ""
            this.dot.style.display = "none"
        }
    }

    public usingDot(): boolean {
        return this.dot.style.display === ""
    }

    public render(): HTMLDivElement {
        return this.element
    }
}
