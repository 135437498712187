import { parse, write } from "sdp-transform"
import { parseQueryString } from "../urlUtil"
import type { SessionDescription } from "sdp-transform"

const filterVideoCodecs = ["H264", "VP8", "VP9"]

type SDPMediaDescription = SessionDescription["media"][0]

export class SDPLib {
    readonly sdpStr: string
    readonly sdp: SessionDescription

    readonly videoBitrateOverriden: boolean
    readonly videoCodecsOverriden: boolean

    constructor(sdpStr: string) {
        this.sdpStr = sdpStr
        this.sdp = parse(sdpStr)

        const bitrate = parseQueryString(window.location.search)["video-bitrate"]
        if (bitrate !== undefined) {
            const videoBitrate = parseInt(bitrate)
            this.forceVideoBitrate(videoBitrate)
            this.videoBitrateOverriden = true
        }

        const codecStr = parseQueryString(window.location.search)["video-codecs"]
        if (codecStr !== undefined) {
            const codecs = codecStr.split(",")

            let profiles: string[] | undefined
            const profileStr = parseQueryString(window.location.search)["video-profiles"]
            if (codecs.length > 0 && profileStr !== undefined) {
                profiles = profileStr.split(",")
            }

            this.forceVideoCodecs(codecs, profiles)
            this.videoCodecsOverriden = true
        }
    }

    write(): string {
        return write(this.sdp)
    }

    forceVideoBitrate(height?: number): number | undefined {
        if (!this.videoBitrateOverriden) {
            const videoBitrate = this.videoBitrate(height)
            this.sdp.media.forEach(media => {
                if (media.type === "video") {
                    this.addBitrates(videoBitrate, media, filterVideoCodecs)
                }
            })
            return videoBitrate
        }

        return undefined
    }

    forceVideoCodecs(codecs: string[], profiles?: string[]): void {
        if (!this.videoCodecsOverriden) {
            this.sdp.media.forEach(media => {
                if (media.type === "video") {
                    this.filterCodecs(codecs, media, filterVideoCodecs, profiles)
                }
            })
        }
    }

    private videoBitrate(height?: number): number {
        let videoBitrate = 1500
        if (height !== undefined) {
            if (height <= 360) {
                videoBitrate = 800
            } else if (height > 540) {
                videoBitrate = 3000
            }
        }
        return videoBitrate
    }

    private addBitrates(videoBitrate: number, media: SDPMediaDescription, filter: string[]): number {
        const payloads = this.findCodecs(filter, media)
        const bitrates: { "payload": number, "config": string }[] = []
        payloads.forEach(p => {
            bitrates.push({ "payload": p, "config": `x-google-min-bitrate=${videoBitrate}` })
            bitrates.push({ "payload": p, "config": `x-google-max-bitrate=${videoBitrate}` })
        })
        media["fmtp"] = media["fmtp"].concat(bitrates)

        return videoBitrate
    }

    private filterCodecs(codecs: string[], media: SDPMediaDescription, filter: string[], profiles?: string[]): void {
        const include = this.findCodecs(filter, media)
        const payloads = this.findCodecs(codecs, media, profiles)
        media["rtp"].filter(r => {
            return include.some(c => c === r["payload"]) && !payloads.some(c => c === r["payload"])
        }).map(r => {
            return r["payload"]
        }).forEach(p => {
            this.removeCodec(p, media)
        })
    }

    private findCodecs(codecs: string[], media: SDPMediaDescription, profiles?: string[]): number[] {
        // VP8 is special because it doesn't have profiles
        let vp8Payload: number | undefined
        let payloads = media["rtp"].filter(r => {
            const includes = codecs.some(c => c.toUpperCase() === r["codec"].toUpperCase())
            if (includes && r["codec"].toUpperCase() === "VP8") {
                vp8Payload = r["payload"]
            }
            return includes
        }).map(r => {
            return r["payload"]
        })
        if (profiles !== undefined) {
            const profileKeys = ["profile-level-id=", "profile-id="]
            const payloadProfiles = media["fmtp"].filter(f => {
                if (payloads.some(p => p === f["payload"])) {
                    let match = false
                    f["config"].split(";").forEach(c => {
                        profileKeys.forEach(k => {
                            if (c.startsWith(k)) {
                                const i = c.split(k)[1].toUpperCase()
                                if (profiles.some(f => f.toUpperCase() === i)) {
                                    match = true
                                }
                            }
                        })
                    })
                    return match
                }
                return false
            }).map(f => {
                return f["payload"]
            })
            // Put back VP8 if it was removed
            if (vp8Payload !== undefined && payloads.some(p => p === vp8Payload)) {
                payloadProfiles.unshift(vp8Payload)
            }
            payloads = payloadProfiles
        }

        return payloads
    }

    private removeCodec(payload: number, media: SDPMediaDescription): void {
        // remove from payload line
        const payloadsSep = " "
        const aptSep = "apt="
        if (media["payloads"] === undefined) {
            return
        }

        let payloads = media["payloads"].split(payloadsSep)
        payloads = payloads.filter((p: string) => {
            return parseInt(p) !== payload
        })
        media["payloads"] = payloads.join(payloadsSep)

        // remove from rtp
        media["rtp"] = media["rtp"].filter(r => {
            return r["payload"] !== payload
        })

        // remove from rtcpFb
        const rtcpFb = media["rtcpFb"]
        if (rtcpFb !== undefined) {
            media["rtcpFb"] = rtcpFb.filter(r => {
                return r["payload"] !== payload
            })
        }

        // remove from fmtp
        media["fmtp"] = media["fmtp"].filter(r => {
            return r["payload"] !== payload
        })

        // remove related transports
        media["fmtp"].filter(f => {
            const c = f["config"]
            if (c.startsWith(aptSep)) {
                const p = c.split(aptSep)[1]
                if (parseInt(p) === payload) {
                    return true
                }
            }
            return false
        }).map(f => {
            return f["payload"]
        }).forEach(p => {
            this.removeCodec(p, media)
        })

        // TODO remove orphaned related transports
    }
}
