import { getCb, normalizeResource, postCb } from "../api"
import { addPageAction } from "../newrelic"
import { buildQueryString, parseQueryString } from "../urlUtil"
import type { IForm } from "../formUtils"

const hasPerformance = window.performance !== undefined && performance.now !== undefined

class ValidationError extends Error {
    isValidationError = true
}
class FormInvalidError extends Error {
    isFormInvalidError = true
}

export const bindRecaptchaValidator = (elem: HTMLInputElement, form: IForm): void => {
    const { formElement } = form
    const validator = () => {
        if (typeof window.injectReCaptchaV3Token !== "function") {
            error("window.injectReCaptchaV3Token is not a function: ", typeof window.injectReCaptchaV3Token)
            return Promise.resolve(true) // do nothing, allow to fallback to server validation
        }
        const startTime = hasPerformance ? performance.now() : 0

        return window.injectReCaptchaV3Token(elem)
            .then((token: string) => {
                if (form.isValid) {
                    return signRecaptcha(token, formElement)
                }
                throw new FormInvalidError() // form became invalid, prevent future actions
            })
            .then((signed_value: string) => {
                if (signed_value === "") {
                    const sign_recaptcha_failed = document.createElement("input")
                    sign_recaptcha_failed.type = "hidden"
                    sign_recaptcha_failed.name = "sign_recaptcha_failed"
                    sign_recaptcha_failed.value = "1"
                    formElement.appendChild(sign_recaptcha_failed)
                } else {
                    const signed_recaptcha = document.createElement("input")
                    signed_recaptcha.type = "hidden"
                    signed_recaptcha.name = "signed_recaptcha"
                    signed_recaptcha.value = signed_value
                    formElement.appendChild(signed_recaptcha)
                }
                addPageAction("ReCAPTCHA3_frontend", {
                    result: "success",
                    timing_milliseconds: hasPerformance ? performance.now() - startTime : undefined,
                })
                return true // recaptcha complete
            }).catch((err: FormInvalidError) => {
                if (err.isFormInvalidError) {
                    return false
                }
                addPageAction("ReCAPTCHA3_frontend", {
                    result: JSON.stringify(err),
                    timing_milliseconds: hasPerformance ? performance.now() - startTime : undefined,
                })
                return replaceWithNoReCaptcha(elem, formElement).then(replaced => {
                    if (replaced) {
                        // NoReCaptcha has been shown, return false to prevent form submitting
                        removeValidator()
                        return false
                    } else {
                        return true // replacing is failed, fallback to server validation
                    }
                })
            })
    }
    const removeValidator = form.addPreSubmitValidator(validator)
}

const replaceWithNoReCaptcha = (elem: HTMLInputElement, form: HTMLFormElement): Promise<boolean> => {
    if (typeof window.bindNoReCaptchaField !== "function") {
        error("window.bindNoReCaptchaField is not a function: ", typeof window.bindNoReCaptchaField)
        return Promise.resolve(false)
    }
    if (elem.parentNode === null) {
        error("replaceWithNoReCaptcha: captcha element hasn't a parent")
        return Promise.resolve(false)
    }
    return getCb("accounts/ajax_norecaptcha_html/").then(response => {
        const dummy = document.createElement("div")

        if (elem.parentNode === null || typeof window.bindNoReCaptchaField !== "function") {
            return false
        }

        elem.parentNode.insertBefore(dummy, elem)
        dummy.outerHTML = response.responseText // eslint-disable-line @multimediallc/no-inner-html
        const noReCaptchaElem = form.querySelector<HTMLElement>(".g-recaptcha")
        if (noReCaptchaElem === null) {
            error("replaceWithNoReCaptcha: html doesn't contain norecaptcha")
            return false
        }

        window.bindNoReCaptchaField(noReCaptchaElem)

        const parser = document.createElement("a")
        parser.href = normalizeResource(form.action)
        const queryString = parseQueryString(parser.search)
        queryString["force-nocaptcha"] = "1"
        const prefix = parser.pathname[0] === "/" ? "" : "/" // IE returns `pathname` without lead slash
        form.action = normalizeResource(`${prefix}${parser.pathname}?${buildQueryString(queryString)}`)
        return true
    })
}

const signRecaptcha = (token: string, form: HTMLFormElement): Promise<string> => {
    // Try to perform ajax token validation and return signed unique field value
    const fieldToSign = form.querySelector<HTMLInputElement>("[data-formvalidate-sign-url]")
    if (fieldToSign === null) {
        return Promise.resolve("")
    }
    const signUrl = fieldToSign.getAttribute("data-formvalidate-sign-url")
    const csrfField = form.querySelector<HTMLInputElement>("[name=csrfmiddlewaretoken]")
    if (typeof signUrl !== "string" || csrfField === null) {
        return Promise.resolve("")
    }
    return postCb(signUrl, {
        "recaptcha3": token,
        "field_to_sign": fieldToSign.value,
        "csrfmiddlewaretoken": csrfField.value,
    }).then(response => {
        const result = JSON.parse(response.responseText)
        if (result["valid"] !== true) {
            throw new ValidationError(result["errors"])
        }
        return result["signed_value"]
    }).catch(err => {
        if (err.isValidationError as boolean) {  // we can't use instanceof because a class will be transpiled to a function
            throw err
        }
        // other errors (network, 5XX, invalid JSON), fallback to server validation
        error("Can't sign recaptcha: ", err)
        return ""
    })
}
