import { AutocompleteModal } from "../../../common/autocompleteModal"
import { AutocompleteModalMobileMixin } from "../../../common/autocompleteModalMobileMixin"
import { EventRouter } from "../../../common/events"
import { scrollFix } from "../../../common/mobilelib/scrollFix"
import { getUsernameColorClass } from "../../../common/roomUtil"
import { addColorClass } from "../../colorClasses"
import { MentionUserList } from "./MentionUserList"
import type { IAutocompleteConfig } from "../../../common/autocompleteModal"
import type { IItem } from "../../../common/filteringCaches"
import type { IUserInfo } from "../../../common/messageInterfaces"

export const updateUserMention = new EventRouter<undefined>("updateUserMention", { reportIfNoListeners: false })

export interface IUserSearch extends IItem {
    user: IUserInfo
    recent: boolean
}

export class UserMentionAutocompleteModal extends AutocompleteModal<IUserSearch> {
    private userList = MentionUserList.getInstance()

    constructor(protected config: IAutocompleteConfig) {
        super(config)
    }

    protected initData(): void {
        super.initData()
        this.disableCache = true
        this.maxOptionLength = 30
    }

    protected initUI(config: IAutocompleteConfig): void {
        super.initUI(config)
        this.element.dataset.testid = "user-mention-modal"
    }

    protected cleanUp(): void {
        super.cleanUp()
        this.userList.clearList()
    }

    protected promptRegex(): string {
        return "@"
    }

    protected searchSlugRegex(): string {
        return "[\\w-]*"
    }

    private userMatchesPrefix(user: IUserInfo): boolean {
        return user.username.toLowerCase().startsWith(this.searchSlug.toLowerCase())
    }

    protected async getData(): Promise<void> {
        if (this.isGetDataCancelled()) {
            return
        }
        await this.userList.mentionUserList()
        this.setItems(this.userList.getSortedList().filter(item => this.userMatchesPrefix(item.user)))
        return Promise.resolve()
    }

    protected appendItem(item: IUserSearch, index: number): HTMLDivElement {
        const div = super.appendItem(item, index)
        addColorClass(div, getUsernameColorClass(item.user))
        div.dataset.testid = "user-mention-modal-item"
        div.style.fontWeight = "bold"
        return div
    }

    protected pickItem(index: number, fromArrowPress: boolean): void {
        super.pickItem(index, fromArrowPress)
        if (!fromArrowPress) {
            this.finalizeSelection()
            this.hide()
        }
    }

    // No endpoint, data is handled by this.userList
    protected getDataEndpoint(): string {
       return ""
    }

    // No parsing, data is handled by this.userList
    protected parseResponse(response: string): IUserSearch[] {
        return []
    }

    protected getDataDebounceMS(): number {
        return 0
    }
}

export interface IMobileUsernameHighlightConfig extends IAutocompleteConfig {
    inputDivHeight: number
}

export class MobileUserMentionAutocompleteModal extends UserMentionAutocompleteModal {
    constructor(config: IMobileUsernameHighlightConfig) {
        super(config)
        scrollFix(this.list)
        new AutocompleteModalMobileMixin({
            autocompleteConfig: this.config,
            element: this.element,
            list: this.list,
            overlayClick: this.overlayClick,
            listenerGroup: this.listenerGroup,
            isVisible: () => this.visible,
            hide: () => this.hide(),
            pickItem: (index: number, fromArrowPress: boolean) => this.pickItem(index, fromArrowPress),
            hideOnSpace: true,
        })
    }

    protected initUI(config: IMobileUsernameHighlightConfig): void {
        super.initUI(config)
        this.element.style.border = ""
        this.element.style.fontSize = "14px"
        this.element.style.lineHeight = "26px"
        this.element.style.boxShadow = "0px 0px 8px rgba(0, 0, 0, 0.32)"
        this.element.style.marginLeft = "7px"
        this.element.style.boxSizing = "content-box"
        this.element.style.position = "fixed"
        this.element.style.bottom = `${config.inputDivHeight - 3}px`
    }

    protected repositionChildren(): void {
        // pass, override super's implementation
    }
}
