import { addColorClass, colorClass } from "../cb/colorClasses"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { postCb, XhrError } from "./api"
import { IRoomContext, roomLoaded } from "./context"
import { IEmoticon } from "./emoticonAutocompleteModal"
import { EventRouter } from "./events"
import { ModalComponent } from "./modalComponent"

let ignoredEmoticons: string[] = []
// eslint-disable-next-line @multimediallc/no-global-listener
roomLoaded.listen((context: IRoomContext) => {
    ignoredEmoticons = context.dossier.ignoredEmoticons
})

export const openEmoticonPreview = new EventRouter<IEmoticon>("openEmoticonPreview")
export const closeEmoticonPreview = new EventRouter<undefined>("closeEmoticonPreview")
export const emoticonPreviewClosed = new EventRouter<undefined>("emoticonPreviewClosed")

export class EmoticonPreviewModal extends ModalComponent {
    private emoticon: IEmoticon
    protected readonly previewImage: HTMLImageElement
    private readonly reportLink: HTMLDivElement
    protected readonly reportWrapper: HTMLDivElement
    private readonly undoWrapper: HTMLDivElement

    constructor() {
        super()

        addColorClass(this.element, "emoticonPreviewModal")
        this.element.style.position = "fixed"
        this.element.style.width = "auto"
        this.element.style.height = "auto"
        this.element.style.left = "50%"
        this.element.style.top = "50%"
        this.element.style.transform = "translate(-50%, -50%)"
        this.element.style.borderRadius = "6px"
        this.element.style.padding = "10px"
        this.element.style.fontSize = "12px"
        this.element.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        this.element.style.zIndex = "1005" // Similar to dm window
        this.element.style.textAlign = "center"
        this.element.style.boxShadow = "0 0 18px rgba(0, 0, 0, 0.4)"

        this.overlay.style.backgroundColor = "#000000"
        this.overlay.style.opacity = "0.4"
        this.overlay.style.zIndex = "1005" // Similar to dm window

        this.element.dataset.testid = "emoticon-modal"

        const closeButton = document.createElement("div")
        addColorClass(closeButton, "closeButton")
        closeButton.style.display = "inline-block"
        closeButton.style.position = "absolute"
        closeButton.style.width = "10px"
        closeButton.style.height = "10px"
        closeButton.style.top = "9px"
        closeButton.style.right = "9px"
        closeButton.style.cursor = "pointer"
        closeButton.style.opacity = "1"
        closeButton.onclick = () => {
            this.hide()
        }
        closeButton.onmouseenter = () => {
            closeButton.style.opacity = "1"
        }
        closeButton.onmouseleave = () => {
            closeButton.style.opacity = "0.5"
        }
        closeButton.dataset.testid = "close-button"
        this.element.appendChild(closeButton)

        this.previewImage = document.createElement("img")
        this.previewImage.style.verticalAlign = "middle"
        this.previewImage.style.padding = "10px"
        this.previewImage.style.maxHeight = "75vh"
        this.previewImage.style.maxWidth = "75vw"
        addEventListenerPoly("load", this.previewImage, () => {
            this.previewImage.style.visibility = "visible"
            this.repositionChildren()
        })
        this.previewImage.dataset.testid = "preview-image"
        this.element.appendChild(this.previewImage)

        this.reportLink = document.createElement("div")
        addColorClass(this.reportLink, colorClass.hrefColor)
        this.reportLink.innerText = "REPORT EMOTICON"
        this.reportLink.style.cursor = "pointer"
        this.reportLink.onclick = () => {
            this.reportLink.style.display = "none"
            this.reportWrapper.style.display = "block"
            this.repositionChildren()
        }
        this.reportLink.onmouseenter = () => {
            this.reportLink.style.textDecoration = "underline"
        }
        this.reportLink.onmouseleave = () => {
            this.reportLink.style.textDecoration = "none"
        }
        this.reportLink.dataset.testid = "report-link"
        this.element.appendChild(this.reportLink)

        this.reportWrapper = document.createElement("div")
        this.reportWrapper.style.display = "none"
        this.element.appendChild(this.reportWrapper)

        const categoryLabel = document.createElement("span")
        categoryLabel.innerText = "Choose a category:"
        categoryLabel.style.marginRight = "6px"
        categoryLabel.dataset.testid = "category-label"
        this.reportWrapper.appendChild(categoryLabel)

        const categorySelect = document.createElement("select")
        addColorClass(categorySelect, "categorySelect")
        categorySelect.style.borderRadius = "4px"
        categorySelect.style.marginBottom = "2px"
        for (const highestTokenColorOption of reportOptions) {
            const option = document.createElement("option")
            option.innerText = highestTokenColorOption.label
            option.value = highestTokenColorOption.value
            option.dataset.testid = highestTokenColorOption.value.replace("_", "-")
            categorySelect.appendChild(option)
        }
        categorySelect.dataset.testid = "category-select"
        this.reportWrapper.appendChild(categorySelect)

        const buttonsWrapper = document.createElement("span")
        buttonsWrapper.style.display = "inline-block"

        const cancelButton = document.createElement("span")
        addColorClass(cancelButton, "cancelButton")
        cancelButton.innerText = "CANCEL"
        cancelButton.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        cancelButton.style.fontSize = "12px"
        cancelButton.style.padding = "2px 6px"
        cancelButton.style.borderRadius = "4px"
        cancelButton.style.boxSizing = "border-box"
        cancelButton.style.cursor = "pointer"
        cancelButton.style.display = "inline-block"
        cancelButton.style.marginLeft = "6px"
        cancelButton.onclick = () => {
            this.reportLink.style.display = "block"
            this.reportWrapper.style.display = "none"
            this.repositionChildren()
        }
        cancelButton.dataset.testid = "cancel-button"
        buttonsWrapper.appendChild(cancelButton)

        const reportButton = document.createElement("span")
        addColorClass(reportButton, "reportButton")
        reportButton.innerText = "REPORT"
        reportButton.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        reportButton.style.fontSize = "12px"
        reportButton.style.padding = "2px 6px"
        reportButton.style.borderRadius = "4px"
        reportButton.style.boxSizing = "border-box"
        reportButton.style.cursor = "pointer"
        reportButton.style.display = "inline-block"
        reportButton.style.marginLeft = "6px"
        reportButton.onclick = () => {
            const reportURL = `emoticon_report_abuse/${this.emoticon.slug}/`
            postCb(reportURL, { "category": categorySelect.value }).then((xhr: XMLHttpRequest) => {
                ignoredEmoticons.push(this.emoticon.slug)
                this.reportWrapper.style.display = "none"
                this.undoWrapper.style.display = "block"
                this.repositionChildren()
            }).catch((err: XhrError) => {
                error("Error reporting emoticon", err)
            })
        }
        reportButton.dataset.testid = "report-button"
        buttonsWrapper.appendChild(reportButton)

        this.reportWrapper.appendChild(buttonsWrapper)

        this.undoWrapper = document.createElement("div")
        this.undoWrapper.style.display = "none"
        this.element.appendChild(this.undoWrapper)

        const undoLabel = document.createElement("span")
        undoLabel.innerText = "EMOTICON REPORTED - "
        undoLabel.dataset.testid = "emoticon-reported-label"
        this.undoWrapper.appendChild(undoLabel)

        const undoLink = document.createElement("span")
        addColorClass(undoLink, colorClass.hrefColor)
        undoLink.innerText = "UNDO"
        undoLink.style.cursor = "pointer"
        undoLink.onclick = () => {
            const reportURL = `emoticon_report_abuse/${this.emoticon.slug}/`
            postCb(reportURL, { "category": categorySelect.value }).then((xhr: XMLHttpRequest) => {
                const index = ignoredEmoticons.indexOf(this.emoticon.slug, 0)
                if (index >= 0) {
                    ignoredEmoticons.splice(index, 1)
                }
                this.undoWrapper.style.display = "none"
                this.reportLink.style.display = "block"
                this.repositionChildren()
            }).catch((err: XhrError) => {
                error("Error reporting emoticon", err)
            })
        }
        undoLink.onmouseenter = () => {
            undoLink.style.textDecoration = "underline"
        }
        undoLink.onmouseleave = () => {
            undoLink.style.textDecoration = "none"
        }
        undoLink.dataset.testid = "undo-link"
        this.undoWrapper.appendChild(undoLink)

        this.overlayClick.listen(() => {
            this.hide()
        })

        closeEmoticonPreview.listen(() => {
            this.hide()
        })
    }

    public initAndShow(emoticon: IEmoticon): void {
        this.emoticon = emoticon
        this.previewImage.style.visibility = "hidden"
        this.previewImage.src = this.emoticon.url
        this.reportLink.style.display = "none"
        this.reportWrapper.style.display = "none"
        this.undoWrapper.style.display = "none"
        if (ignoredEmoticons.indexOf(emoticon.slug) >= 0) {
            this.undoWrapper.style.display = "block"
        } else {
            this.reportLink.style.display = "block"
        }
        super.show()
        this.removeDocumentFocus()
        this.repositionChildren()
    }

    public hide(): void {
        super.hide()
        emoticonPreviewClosed.fire(undefined)
    }

    public show(): void {
        super.show()
        this.element.style.visibility = ""
        this.repositionChildren()
    }

    private removeDocumentFocus(): void {
        if (document.activeElement !== null) {
            (<HTMLElement>document.activeElement).blur()
        }
    }
}

export class MobileEmoticonPreviewModal extends EmoticonPreviewModal {
    constructor() {
        super()
        this.previewImage.style.maxWidth = "calc(100vw - 64px)"
        this.reportWrapper.style.width = "300px"
        this.reportWrapper.style.margin = "auto"
    }
}

const reportOptions = [
    { label: "Just ignore this emoticon", value: "ignore" },
    { label: "Offensive", value: "offensive" },
    { label: "Disgusting", value: "disgusting" },
    { label: "Fake Tip", value: "fake_tip" },
    { label: "Advertising", value: "advertising" },
]
