import { ALL, isRoomRoomlistSpaActive, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { roomCleanup } from "./context"
import { Component } from "./defui/component"
import { EventRouter, ListenerGroup } from "./events"
import { fullscreenChange, fullscreenElement, isFullscreen } from "./fullscreen"
import Key = JQuery.Key

const modalReposition = new EventRouter<undefined>("modalReposition")
addEventListenerPoly("resize", window, () => {
    modalReposition.fire(undefined)
})
addEventListenerPoly("orientationchange", window, () => {
    modalReposition.fire(undefined)
})
const modalEscape = new EventRouter<KeyboardEvent>("modalEscape")
addEventListenerPoly("keydown", document, (ev: KeyboardEvent) => {
    if (ev.keyCode === Key.Escape) {
        modalEscape.fire(ev)
    }
})
// hide modals on navigation
export const modalExit = new EventRouter("modalExit")

// ideally use URLState "room" listener in modal component bases to handle hiding all modals once it's ready,
// (https://multimediallc.leankit.com/card/30502080746332 and https://multimediallc.leankit.com/card/30502080976671)
if (isRoomRoomlistSpaActive()) {
    UrlState.current.listenGlobal(ALL, () => {
        modalExit.fire(undefined)
    })
} else {
    // need popstate listeners for safari browser back and forward navigations to roomlist page
    addEventListenerPoly("popstate", window, () => {
        modalExit.fire(undefined)
    })
    roomCleanup.listen(() => {
        modalExit.fire(undefined)
    })
}


export const modalBlockChatFocus = new EventRouter<boolean>("modalOpened")

export interface IModalOptions {
    onShow?: () => void
    onHide?: (() => void) | ((fromNavigation: boolean) => void)
    easyExit: boolean
}

export class ModalComponent extends Component {
    overlay: HTMLDivElement
    overlayClick = new EventRouter<undefined>("overlayClick")
    private repositionCallback: () => void
    private fullscreenChangeCallback: () => void
    private listenerGroup = new ListenerGroup()
    protected isListeningForChange = false
    protected hideOverlay = false
    protected overlayClickStayOpen = false
    protected preventChatFocus = false

    constructor(private options: IModalOptions = { easyExit: true }) {
        super()

        this.overlay = document.createElement("div")
        this.overlay.style.position = "fixed"
        this.overlay.style.display = "none"
        this.overlay.style.left = "0"
        this.overlay.style.top = "0"
        this.overlay.style.right = "0"
        this.overlay.style.bottom = "0"
        this.overlay.style.zIndex = "1100"
        this.overlay.style.background = "rgb(0,0,0,0)"
        this.overlay.onclick = (event: MouseEvent) => {
            event.stopPropagation() // Prevent the click from bubbling up to other modal overlays.
            this.overlayClick.fire(undefined)
            if (!this.overlayClickStayOpen) {
                this.hide()
            }
        }

        this.element.style.zIndex = "1101"
        this.element.onclick = (event: MouseEvent) => {
            event.stopPropagation() // Prevent interacting with the component from triggering the overlay.
        }

        this.repositionCallback = () => {
            if (!this.isListeningForChange) {
                return
            }
            this.repositionChildrenRecursive()
        }

        this.fullscreenChangeCallback = () => {
            if (!this.isListeningForChange) {
                return
            }
            this.show()
        }
    }

    // Should not be extended - use onShow or init before show instead
    show(): void {
        const fullscreenEl = fullscreenElement()
        if (isFullscreen() && fullscreenEl !== undefined) {
            fullscreenEl.appendChild(this.overlay)
            fullscreenEl.appendChild(this.element)
        } else {
            document.body.appendChild(this.overlay)
            document.body.appendChild(this.element)
        }

        if (!this.isListeningForChange) {
            modalReposition.listen(this.repositionCallback, false).addTo(this.listenerGroup)
            fullscreenChange.listen(this.fullscreenChangeCallback, false).addTo(this.listenerGroup)
            modalExit.listen(() => {
                this.hide(true)
            }, false).addTo(this.listenerGroup)
            if (this.options.easyExit) {
                modalEscape.listen((ev: KeyboardEvent) => {
                    ev.preventDefault()
                    this.hide()
                }, false).addTo(this.listenerGroup)
            }
            if (this.preventChatFocus) {
                modalBlockChatFocus.fire(true)
            }
            if (this.options.onShow !== undefined) {
                this.options.onShow()
            }
            this.isListeningForChange = true
        }
        if (!this.hideOverlay) {
            this.overlay.style.display = "block"
        }
    }

    // Should not be extended - use onHide instead
    hide(fromNavigation = false): void {
        this.overlay.style.display = "none"
        // safari scrolls to bottom if an input is focused when hiding
        if (document.activeElement && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur()
        }
        if (this.element.parentElement !== null) {
            this.element.parentElement.removeChild(this.element)
        }
        if (this.overlay.parentElement !== null) {
            this.overlay.parentElement.removeChild(this.overlay)
        }
        if (this.isListeningForChange) {
            this.listenerGroup.removeAll()
            if (this.preventChatFocus) {
                modalBlockChatFocus.fire(false)
            }
            if (this.options.onHide !== undefined) {
                this.options.onHide(fromNavigation)
            }
            this.isListeningForChange = false
        }
    }
}
