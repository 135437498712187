import { addColorClass } from "../cb/colorClasses"

export enum DivotPosition {
    Top,
    Bottom,
    Left,
    Right,
}

export function createDivotTop(borderColor: string, backgroundColor: string, left?: string, borderThickness = 3): HTMLDivElement {
    const divot = document.createElement("div")
    divot.style.position = "absolute"
    divot.style.height = "0"
    divot.style.width = "0"
    divot.style.top = "-8px"
    if (left !== undefined) {
        divot.style.left = left
    }

    const border = document.createElement("div")
    addColorClass(border, "divotBorder")
    border.style.borderLeft = "8px solid transparent"
    border.style.borderRight = "8px solid transparent"
    border.style.borderBottomWidth = "8px"
    border.style.borderBottomStyle = "solid"
    if (borderColor !== "") {
        border.style.borderBottomColor = `${borderColor}`
    }
    divot.appendChild(border)

    const backgroundSize = 8 - borderThickness
    const background = document.createElement("div")
    addColorClass(background, "divotBackground")
    background.style.position = "absolute"
    background.style.top = `${borderThickness}px`
    background.style.left = `${borderThickness}px`
    background.style.borderLeft = `${backgroundSize}px solid transparent`
    background.style.borderRight = `${backgroundSize}px solid transparent`
    background.style.borderBottomWidth = `${backgroundSize}px`
    background.style.borderBottomStyle = "solid"
    if (backgroundColor !== "") {
        background.style.borderBottomColor = `${backgroundColor}`
    }
    border.appendChild(background)

    return divot
}

export function createDivotBottom(borderColor: string, backgroundColor: string, left?: string, borderThickness = 3): HTMLDivElement {
    const divot = document.createElement("div")
    divot.style.position = "absolute"
    divot.style.height = "0"
    divot.style.width = "0"
    divot.style.bottom = "0"
    if (left !== undefined) {
        divot.style.left = left
    }

    const border = document.createElement("div")
    addColorClass(border, "divotBorder")
    border.style.borderLeft = "8px solid transparent"
    border.style.borderRight = "8px solid transparent"
    border.style.borderTopWidth = "8px"
    border.style.borderTopStyle = "solid"
    if (borderColor !== "") {
        border.style.borderTopColor = `${borderColor}`
    }
    divot.appendChild(border)

    const backgroundSize = 8 - borderThickness
    const background = document.createElement("div")
    addColorClass(background, "divotBackground")
    background.style.position = "absolute"
    background.style.top = "0"
    background.style.left = `${borderThickness}px`
    background.style.borderLeft = `${backgroundSize}px solid transparent`
    background.style.borderRight = `${backgroundSize}px solid transparent`
    background.style.borderTopWidth = `${backgroundSize}px`
    background.style.borderTopStyle = "solid"
    if (backgroundColor !== "") {
        background.style.borderTopColor = `${backgroundColor}`
    }
    border.appendChild(background)

    return divot
}

export function createDivotLeft(borderColor: string, backgroundColor: string, top?: string, borderThickness = 3): HTMLDivElement {
    const divot = document.createElement("div")
    divot.style.position = "absolute"
    divot.style.height = "0"
    divot.style.width = "0"
    divot.style.left = "-8px"
    if (top !== undefined) {
        divot.style.top = top
    }

    const border = document.createElement("div")
    addColorClass(border, "divotBorder")
    border.style.borderTop = "8px solid transparent"
    border.style.borderBottom = "8px solid transparent"
    border.style.borderRightWidth = "8px"
    border.style.borderRightStyle = "solid"
    if (borderColor !== "") {
        border.style.borderRightColor = `${borderColor}`
    }
    divot.appendChild(border)

    const backgroundSize = 8 - borderThickness
    const background = document.createElement("div")
    addColorClass(background, "divotBackground")
    background.style.position = "absolute"
    background.style.top = `${borderThickness}px`
    background.style.left = `${borderThickness}px`
    background.style.borderTop = `${backgroundSize}px solid transparent`
    background.style.borderBottom = `${backgroundSize}px solid transparent`
    background.style.borderRightWidth = `${backgroundSize}px`
    background.style.borderRightStyle = "solid"
    if (backgroundColor !== "") {
        background.style.borderRightColor = `${backgroundColor}`
    }
    border.appendChild(background)

    return divot
}

export function createDivotRight(borderColor: string, backgroundColor: string, top?: string, borderThickness = 3): HTMLDivElement {
    const divot = document.createElement("div")
    divot.style.position = "absolute"
    divot.style.height = "0"
    divot.style.width = "0"
    divot.style.right = "0"
    if (top !== undefined) {
        divot.style.top = top
    }

    const border = document.createElement("div")
    addColorClass(border, "divotBorder")
    border.style.borderTop = "8px solid transparent"
    border.style.borderBottom = "8px solid transparent"
    border.style.borderLeftWidth = "8px"
    border.style.borderLeftStyle = "solid"
    if (borderColor !== "") {
        border.style.borderLeftColor = `${borderColor}`
    }
    divot.appendChild(border)

    const backgroundSize = 8 - borderThickness
    const background = document.createElement("div")
    addColorClass(background, "divotBackground")
    background.style.position = "absolute"
    background.style.top = `${borderThickness}px`
    background.style.right = `-${backgroundSize}px`
    background.style.borderTop = `${backgroundSize}px solid transparent`
    background.style.borderBottom = `${backgroundSize}px solid transparent`
    background.style.borderLeftWidth = `${backgroundSize}px`
    background.style.borderLeftStyle = "solid"
    if (backgroundColor !== "") {
        background.style.borderLeftColor = `${backgroundColor}`
    }
    border.appendChild(background)

    return divot
}

export function createDivot(divotPosition: DivotPosition, divotLeftOrTop?: string, divotBorderWidth = 3): HTMLDivElement | undefined {
    let divotFunc
    let divotClass = ""

    switch (divotPosition) {
        case DivotPosition.Top:
            divotFunc = createDivotTop
            divotClass = "divotTop"
            break
        case DivotPosition.Bottom:
            divotFunc = createDivotBottom
            divotClass = "divotBottom"
            break
        case DivotPosition.Left:
            divotFunc = createDivotLeft
            divotClass = "divotLeft"
            break
        case DivotPosition.Right:
            divotFunc = createDivotRight
            divotClass = "divotRight"
            break
        default:
            divotFunc = undefined
    }

    if (divotFunc !== undefined) {
        const divot = divotFunc("", "", divotLeftOrTop, divotBorderWidth)
        addColorClass(divot, divotClass)
        addColorClass(divot, "divot")
        return divot
    }
    return
}
