import { addEventListenerMultiPoly, addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { getDisplayedTime } from "../../../common/broadcastlib/timeUtils"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { preventMultiClicks } from "../../../common/eventsUtil"
import { isPrivateSpyBadgesActive } from "../../../common/featureFlagUtil"
import { RoomStatus } from "../../../common/roomStatus"
import { DISCOVER_JOIN_OVERLAY_ATTRIBUTE } from "../../../common/theatermodelib/discoverJoinOverlay"
import { registerLaterLinkClicked } from "../../../common/theatermodelib/joinOverlay"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { colorClass } from "../../colorClasses"
import { pageContext } from "../../interfaces/context"
import { isRoomAnimationEnabled, parseRoomListSource, startStreaming, stopStreaming } from "../../roomList"
import { RoomFollowStar } from "../followStar"
import { getValidCountryData } from "./countryFlagUtils"
import { determineRoomLabel, isRecommendedConfigLabel } from "./moreRooms/moreRoomRecommendationSources"
import { RoomCardDetails } from "./roomCardDetails"
import { shouldBlur } from "./spaHelpers"
import type { IExtendedRoomInfo, IRoomInfo } from "./IRoomInfo"
import type { RoomList } from "./roomList"
import type { ArgJSONMap } from "@multimediallc/web-utils"

export function parseIRoomInfo(rawRoomInfo: ArgJSONMap): IRoomInfo {
    const getViewerCount = (rawRoomInfo: ArgJSONMap, key: string): number => {
        const viewers = rawRoomInfo.getNumber(key, false)
        return isNaN(viewers) ? -1 : viewers
    }

    // We want to use startTimestamp to perform calculations for timeOnline on the FE
    const roomLabel = rawRoomInfo.getString("label", false)
    const startTs = rawRoomInfo.getNumberOrUndefined("start_timestamp", false)
    const countryCode = rawRoomInfo.getStringOrUndefined("country")?.toLowerCase()
    const { flagCode, flagName } = getValidCountryData(countryCode)
    return {
        room: rawRoomInfo.getString("room", false) || rawRoomInfo.getString("username", false),
        label: roomLabel,
        img: rawRoomInfo.getString("img"),
        gender: rawRoomInfo.getString("gender"),
        subject: rawRoomInfo.getString("subject", false) || rawRoomInfo.getString("room_subject", false),
        timeOnline: getDisplayedTime(roomLabel === RoomStatus.Offline, startTs === 0 ? undefined : startTs),
        viewers: Math.max(getViewerCount(rawRoomInfo, "viewers"), getViewerCount(rawRoomInfo, "num_users")),
        location: rawRoomInfo.getStringOrUndefined("location"),
        displayAge: rawRoomInfo.getStringWithNumbers("display_age", false) || rawRoomInfo.getStringWithNumbers("age", false),
        isFollowing: rawRoomInfo.getBoolean("is_following", false, false),
        isNew: rawRoomInfo.getBoolean("is_new", false, false),
        isGaming: rawRoomInfo.getBoolean("is_gaming", false, false),
        sourceName: parseRoomListSource(rawRoomInfo.getStringOrUndefined("source_name")),
        sourcePosition: rawRoomInfo.getNumberOrUndefined("source_position"),
        sourceInfo: rawRoomInfo.getStringOrUndefined("source_info"),
        startTimestamp: startTs,
        countryCode: flagCode,
        countryName: flagName,
        privatePrice: rawRoomInfo.getNumberOrUndefined("private_price"),
        spyPrice: rawRoomInfo.getNumberOrUndefined("spy_show_price"),
        extendedInfo: parseExtendedRoomInfo(rawRoomInfo),
    }
}

function parseExtendedRoomInfo(rawRoomInfo: ArgJSONMap): IExtendedRoomInfo | undefined {
    const params = (new URL(window.location.href)).searchParams
    if (!pageContext.current?.isInternal || params.get("show_rankings") !== "1") {
        return undefined
    }
    return {
        score: rawRoomInfo.getNumberOrUndefined("score"),
        scoreRank: rawRoomInfo.getNumberOrUndefined("score_rank"),
        baseScore: rawRoomInfo.getNumberOrUndefined("base_score"),
        baseRank: rawRoomInfo.getNumberOrUndefined("base_rank"),
        satScore: rawRoomInfo.getNumberOrUndefined("sat_score"),
        satPercent: rawRoomInfo.getNumberOrUndefined("sat_percent"),
        defaultPenalty: rawRoomInfo.getNumberOrUndefined("default_penalty"),
        manualPenalty: rawRoomInfo.getNumberOrUndefined("manual_penalty"),
        numRegs: rawRoomInfo.getNumberOrUndefined("num_regs"),
        coloredRegs: rawRoomInfo.getNumberOrUndefined("colored_regs"),
        tipRate: rawRoomInfo.getNumberOrUndefined("tip_rate"),
        smallRoomScore: rawRoomInfo.getNumberOrUndefined("small_room_sort_score"),
    }
}

export interface IRoomCardProps {
    roomList: RoomList<RoomCard>
    roomInfo: IRoomInfo
    roomIndex: number
    animate: boolean
}

export class RoomCard<T extends IRoomCardProps = IRoomCardProps> extends HTMLComponent<HTMLLIElement, T> {
    protected img: HTMLImageElement
    private props: T
    protected followStar: RoomFollowStar

    protected initUI(props: T): void {
        this.props = props
        this.setImageStreamer()
        addEventListenerMultiPoly(["click", "mousedown", "touchstart"], this.element, (e: UIEvent) => {
            this.fireBeforeRoomClick(e)
        })
    }

    private fireBeforeRoomClick(e: UIEvent): void {
        if (e.target instanceof HTMLElement && e.target.getAttribute("data-room-nav") !== null) {
            this.props.roomList.roomBeforeClickEvent.fire({
                index: this.props.roomIndex,
                roomInfo: this.props.roomInfo,
                event: e,
            })
        }
    }

    protected fireRoomClick(e: UIEvent): void {
        if (e.target instanceof HTMLElement && e.target.getAttribute("data-room-nav") !== null) {
            this.props.roomList.roomClickEvent.fire({
                index: this.props.roomIndex,
                roomInfo: this.props.roomInfo,
                event: e,
            })
        }
    }

    protected onRoomClick(e: UIEvent): void {
        if (this.element.querySelector(`a[${DISCOVER_JOIN_OVERLAY_ATTRIBUTE}]`)) {
            // Handles join overlay on discover page
            registerLaterLinkClicked.listen(() => this.fireRoomClick(e))
        } else {
            this.fireRoomClick(e)
        }
    }

    public blurThumbnail(props: IRoomCardProps): void {
        // When the age-gate or agree-terms is up, we override the thumbnail image with a blurred image
        props.roomInfo.img = props.roomInfo.img.replace("/riw/", "/ribw/")  // Desktop thumbs
        props.roomInfo.img = props.roomInfo.img.replace("/ri/", "/rib/")  // Mobile thumbs
    }


    protected createElement(props: IRoomCardProps): HTMLLIElement {
        const roomLabel = determineRoomLabel(props.roomInfo.sourceInfo, props.roomInfo.label)
        // preventMultiClicks debounces thumbnail link to help reduce duplicate BroadcasterLinkSource events
        // Without this, clicking many times before the room loads can fire multiple requests, each causing a BLS event

        if (shouldBlur()) {
            this.blurThumbnail(props)
        }

        return <li
            className={`roomCard ${colorClass.camBgColor}`}
            data-testid="room-card"
            onClick={e => this.onRoomClick(e)}
        >
            {preventMultiClicks(<a href={`/${props.roomInfo.room}/`} data-room-nav="true" data-room={props.roomInfo.room}>
                <img width={180} height={101} src={props.roomInfo.img} loading="lazy" data-testid="room-card-image"
                    alt={props.roomInfo.room} data-room-nav="true" ref={(el: HTMLImageElement) => { this.img = el }}
                    className="room_thumbnail"
                />
            </a>)}
            {this.showPrivateSpyRoomTokens(props, roomLabel)}
            <RoomCardDetails roomInfo={props.roomInfo} />
            {this.createFollowStar(props)}
        </li> as HTMLLIElement
    }

    protected createFollowStar(props: IRoomCardProps): RoomFollowStar | void {
        return <RoomFollowStar slug={props.roomInfo.room} isFollowing={props.roomInfo.isFollowing}
            classRef={(c: RoomFollowStar) => this.followStar = c}
        />
    }

    public setImageStreamer(): void {
        addEventListenerPoly("mouseenter", this.element, () => {
            if (isRoomAnimationEnabled()) {
                this.img.removeAttribute("loading")
                startStreaming(this.props.roomInfo.room, this.img)
            }
        })
        addEventListenerPoly("mouseleave", this.element, () => {
            if (isRoomAnimationEnabled()) {
                stopStreaming(this.props.roomInfo.room)
            }
        })
    }

    protected afterRemovedFromParent(): void {
        if (isRoomAnimationEnabled() && this.props !== undefined) {
            stopStreaming(this.props.roomInfo.room)
        }
        super.afterRemovedFromParent()
    }

    static labelTextMap: Record<string, string> = {
        "promoted": i18n.promotedCAPS,
        "recommended": i18n.recommendedCAPS,
        "offline": i18n.offlineCAPS,
        "exhib": i18n.exhibitionistCAPS,
        "new": i18n.newCAPS,
        "private": i18n.inPrivateCAPS,
        "gaming": i18n.gamingCAPS,
    }

    static labelClassMap: Record<string, string> = {
        "promoted": "thumbnail_label thumbnail_label_c_promoted",
        "offline": "thumbnail_label thumbnail_label_offline",
        "exhib": "thumbnail_label thumbnail_label_exhibitionist",
        "new": "thumbnail_label thumbnail_label_c_new",
        "private": "thumbnail_label_featured thumbnail_label_c_private_show",
        "gaming": "thumbnail_label thumbnail_label_c_gaming",
        "recommended": "thumbnail_label thumbnail_label_c thumbnail_label_recommender",
    }

    public getRoomName(): string {
        return this.props.roomInfo.room
    }

    public updateFollowStar(isFollowing: boolean): void {
        this.followStar?.setFollowing(isFollowing)
    }

    private showPrivateSpyRoomTokens(props: IRoomCardProps, roomLabel: string): HTMLDivElement {
        // For testing PvtSpyBdgs, Shows private or spy tokens,
        // otherwise show just the default label.
        if (isPrivateSpyBadgesActive()) {
            return <div className="labelContainer">
                <ThumbnailLabel roomLabel={roomLabel} />
                <TokenLabel
                    roomLabel={roomLabel}
                    privateTokens={props.roomInfo.privatePrice}
                    spyTokens={props.roomInfo.spyPrice}
                />
            </div>
        } else {
            return <ThumbnailLabel roomLabel={roomLabel}/>
        }
    }

}

export const ThumbnailLabel = (props: { roomLabel: string }): HTMLDivElement => {
    const label = isRecommendedConfigLabel(props.roomLabel)
        ? props.roomLabel
        : RoomCard.labelTextMap[props.roomLabel] ?? ""
    const classes = isRecommendedConfigLabel(props.roomLabel)
        ? RoomCard.labelClassMap["recommended"]
        : RoomCard.labelClassMap[props.roomLabel] ?? "thumbnail_label"
    return (
        <div className={classes} data-testid="thumbnail-label">
            {label}
        </div>
    )
}

export const TokenLabel = (props: {
        roomLabel: string,
        spyTokens?: number,
        privateTokens?: number
    }): HTMLDivElement | undefined => {
    const tokens = props.privateTokens ?? (props.roomLabel === "private" ? props.spyTokens : undefined)
    if (tokens === undefined || tokens === 0) {
        return undefined
    }

    const tokenClassNames = props.privateTokens !== undefined ? "privateTokens" : "spyTokens"
    const toolip = props.privateTokens !== undefined ? i18n.tooltipPrivateMinute : i18n.tooltipSpyMinute
    return <div className={`${tokenClassNames} tokenLabel thumbnail_label`} data-tooltip={toolip}>
        {i18n.tokensBadge(tokens)}
    </div>
}

export class PlaceHolderRoomCard extends RoomCard {
    protected initUI(_props: IRoomCardProps): void {
    }

    protected createElement(_props: IRoomCardProps): HTMLLIElement {
        return <li
            className={`roomCard placeholder ${colorClass.camBgColor}`}
            data-testid="room-card-placeholder"
        >
            <a>
                <div className="room_thumbnail" />
            </a>
            <div className="details">
                <div className="title">&nbsp;</div>
                <div className="subject">&nbsp;</div>
                <div className="sub-info">&nbsp;</div>
            </div>
        </li>
    }
}

export const FAKE_ROOM_CARD_PROPS: IRoomCardProps = {
    roomList: {} as RoomList<RoomCard>,
    roomIndex: 0,
    roomInfo: {} as IRoomInfo,
    animate: false,
}

export const createPlaceholderCard = (): PlaceHolderRoomCard => {
    return new PlaceHolderRoomCard(FAKE_ROOM_CARD_PROPS)
}
