// // ICoords represents the top/left
// // types probably should be "absolute" positioned

export interface IOffsets {
    left: number
    top: number
}

export interface ICoords extends IOffsets {
    width: number
    height: number
}

export function applyCoords(c: ICoords, elem: HTMLElement): void {
    elem.style.left = `${c.left}px`
    elem.style.top = `${c.top}px`
    elem.style.width = `${c.width}px`
    elem.style.height = `${c.height}px`
}

export function scanCoords(elem: HTMLElement): ICoords {
    return {
        left: elem.offsetLeft,
        top: elem.offsetTop,
        width: elem.offsetWidth,
        height: elem.offsetHeight,
    }
}

export function moveTopEdge(startingCoords: Readonly<ICoords>, yPixels: number, minHeight: number): ICoords {
    const newCoords: ICoords = { ...startingCoords }
    newCoords.top = startingCoords.top + yPixels
    newCoords.height = startingCoords.height - yPixels
    if (newCoords.top < 0) {
        newCoords.height += newCoords.top
        newCoords.top = 0
    } else if (newCoords.height < minHeight) {
        newCoords.top -= (minHeight - newCoords.height)
        newCoords.height = minHeight
    }
    return newCoords
}

export function moveBottomEdge(startingCoords: Readonly<ICoords>, yPixels: number,
                               minHeight: number, maxY: number): ICoords {
    const newCoords: ICoords = { ...startingCoords }
    newCoords.height = startingCoords.height + yPixels
    if (newCoords.top + newCoords.height > maxY) {
        newCoords.height = (maxY - newCoords.top)
    } else if (newCoords.height < minHeight) {
        newCoords.height = minHeight
    }
    return newCoords
}

export function moveLeftEdge(startingCoords: Readonly<ICoords>, xPixels: number, minWidth: number): ICoords {
    const newCoords: ICoords = { ...startingCoords }
    newCoords.left = startingCoords.left + xPixels
    newCoords.width = startingCoords.width - xPixels
    if (newCoords.left < 0) {
        newCoords.width += newCoords.left
        newCoords.left = 0
    } else if (newCoords.width < minWidth) {
        newCoords.left -= (minWidth - newCoords.width)
        newCoords.width = minWidth
    }
    return newCoords
}

export function moveRightEdge(startingCoords: Readonly<ICoords>, xPixels: number,
                              minWidth: number, maxX: number): ICoords {
    const newCoords: ICoords = { ...startingCoords }
    newCoords.width = startingCoords.width + xPixels
    if (newCoords.left + newCoords.width > maxX) {
        newCoords.width = (maxX - newCoords.left)
    } else if (newCoords.width < minWidth) {
        newCoords.width = minWidth
    }
    return newCoords
}
