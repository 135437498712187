import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCookieOrUndefined, setCookieWithExpiration } from "@multimediallc/web-utils/storage"
import { modalConfirm } from "./alerts"
import { getCb, normalizeResource, postCb } from "./api"
import { isLanguageSubdomainActive } from "./featureFlagUtil"
import { i18n } from "./translation"
import { goToHomepage } from "./windowUtils"
import type { IChatConnection } from "./context"
import type { IPushBroadcastWarning } from "./messageInterfaces"

let logPresenceTimeout: number | undefined
const ignoredKeys: string[] = ["total_viewers", "image_url", "password_warning"]
let warningModalDisplayed: number[] = []

export function scheduleLogPresence(chatConnection: IChatConnection, exploringHashTag: string): void {
    clearTimeout(logPresenceTimeout)
    const timeout = 55000
    if (chatConnection.viewerIsAnonymous()) {
        storeRoomAgeViewInCookie(chatConnection)
    }
    const url = parsePresenceUrl(chatConnection, exploringHashTag)
    logPresenceTimeout = window.setTimeout(() => {
        logPresence(url)
        scheduleLogPresence(chatConnection, exploringHashTag)
    }, timeout)
}

export function logPresenceNow(chatConnection: IChatConnection, exploringHashTag: string): void {
    const url = parsePresenceUrl(chatConnection, exploringHashTag)
    logPresence(url)
}

function parsePresenceUrl(chatConnection: IChatConnection, exploringHashTag: string): string {
    let url = `contest/log/${chatConnection.room()}/`
    if (exploringHashTag !== "") {
        const hashTagJSON = new ArgJSONMap(exploringHashTag)
        const hashtag = hashTagJSON.getString("hashtag")
        url = `${url}?hashtag=${encodeURIComponent(hashtag)}`
    }
    return url
}

function logPresence(url: string): void {
    getCb(url).then((xhr) => {
        const p = new ArgJSONMap(xhr.responseText)
        const canAccess = p.getBoolean("can_access")
        const isBanned = p.getBoolean("is_banned")
        const warnings = p.getList("warnings")
        // handle unused keys from json response
        for (const key of ignoredKeys) {
            p.ignore(key)
        }
        if (!canAccess) {
            window.location.reload()
        }
        if (isBanned) {
            goToHomepage()
        }
        if (warnings !== undefined) {
            warnings.forEach((warning) => {
                warningAlert({ text: warning.getString("text"), warningId: warning.getNumber("id"), response: warning.getBoolean("allow_response") })
            })
        }
        p.logUnusedDebugging("logPresence")
    }).catch((err) => {
        error("log_presence failed", err)
    })
}

export function warningAlert(warning: IPushBroadcastWarning): void {
    if (warning.response) {
        window.location.href = normalizeResource(`/accounts/site_message/?next=${window.location.href}`)
    } else if (warningModalDisplayed.includes(warning.warningId) === false) {
        modalConfirm(warning.text,
            () => { window.open(normalizeResource(`/accounts/site_message/?next=${window.location.href}&question=true`), "_blank"); },
            () => {
                postCb(`/accounts/site_message/`, {}).catch((err) => {
                    error(`post error accessing site messages: ${err}`)
                })
                warningModalDisplayed = warningModalDisplayed.filter((id) => id !== warning.warningId)
            }, {
            acceptText: i18n.broadcasterWarningQuestion,
            declineText: i18n.broadcasterWarningAknowledge,
            title: i18n.broadcasterWarningMessage,
            titleSmall: true,
        }, true)
        warningModalDisplayed.push(warning.warningId)
    }
}

function storeRoomAgeViewInCookie(chatConnection: IChatConnection): void {
    const roomAge = chatConnection.age()
    if (roomAge !== undefined) {
        const viewedAgeGroups: string[] = []
        ageGroups.forEach((v, k) => {
            if (v[0] <= roomAge && v[1] > roomAge) {
                viewedAgeGroups.push(k)
            }
        })
        let allAgeViews: Record<string, number> = {}
        try {
            allAgeViews = JSON.parse(getCookieOrUndefined("ag") ?? "{}")
        } catch (e) {
            // pass
        }
        viewedAgeGroups.forEach(ageGroup => {
            allAgeViews[ageGroup] = (allAgeViews[ageGroup] ?? 0) + 1
        })
        setCookieWithExpiration("ag", JSON.stringify(allAgeViews), { days: 30 }, "/", isLanguageSubdomainActive())
    }
}

export function cancelLogPresence(): void {
    clearTimeout(logPresenceTimeout)
}

const ageGroups = new Map<string, number[]>([
    ["teen-cams", [18, 20]],
    ["18to21-cams", [18, 22]],
    ["20to30-cams", [20, 31]],
    ["30to50-cams", [30, 51]],
    ["mature-cams", [50, 20]],
])
