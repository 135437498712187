import { addColorClass } from "../../cb/colorClasses"
import { widthLessThanEvent } from "../../cb/ui/responsiveUtil"
import { modalAlert } from "../alerts"
import { postCb } from "../api"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { EventRouter } from "../events"
import { minTokensToVote, SatisfactionScore } from "../satisfactionScore"
import { i18n } from "../translation"
import { MenuLinkDrawerComponent } from "./menuDrawerComponent"
import type { IRoomContext } from "../context"
import type { IVoteState } from "../satisfactionScore"

export class MobileSatisfactionMenuItem extends MenuLinkDrawerComponent {
    private satScore = new MobileSatisfactionScore()
    private satComment: MobileSatisfactionComment | undefined

    constructor(a: HTMLAnchorElement) {
        super(a)

        this.element.style.height = ""
        this.menuLink.onclick = () => {}
        this.menuLink.appendChild(this.satScore.element)

        this.satScore.commentTriggerEvent.listen((reset: boolean) => {
            this.toggleDrawer(true, reset)
        })
        this.satScore.hideCommentEvent.listen(() => {
            this.toggleDrawer(false, false)
        })
    }

    protected toggleDrawer(show: boolean, dispose: boolean): void {
        super.toggleDrawer(show, dispose)
        if (dispose && this.satComment !== undefined) {
            this.satComment.reset()
        }
    }

    protected initializeDrawer(): void {
        if (this.satComment === undefined) {
            this.satComment = new MobileSatisfactionComment()
            this.satComment.hideEvent.listen(() => {
                this.toggleDrawer(false, false)
            })
        }
        this.drawer = this.satComment.element
    }
}

export class MobileSatisfactionScore extends SatisfactionScore {
    private enabled = false
    public commentTriggerEvent = new EventRouter<boolean>("satisfactionCommentShow")
    public hideCommentEvent = new EventRouter<undefined>("satisfactionCommentHide")
    protected label = document.createElement("span")
    protected satisfiedText = i18n.satisfiedText
    protected hideSatisfiedText = false

    constructor() {
        super()
        this.element.id = "satisfactionScore"
        this.element.style.height = ""
        this.element.style.width = ""
        this.element.style.position = "static"
        this.element.style.display = "block"
        this.element.style.cursor = "pointer"
        this.innerDiv.style.backgroundColor = ""
        this.innerDiv.style.textShadow = ""
        this.innerDiv.style.color = "#6C6C78"
        this.innerDiv.style.textDecoration = "none"
        this.element.onclick = () => {
            this.divClicked()
        }

        this.percent.style.fontWeight = "bold"

        addColorClass(this.label, "satisfactionLabel")
        this.label.textContent = this.satisfiedText
        this.label.style.margin = "0"
        this.label.style.verticalAlign = "top"
        this.label.style.display = "none"
        this.innerDiv.prepend(this.label)
        widthLessThanEvent(620).listen(isLess => {
            this.hideSatisfiedText = isLess
            this.label.textContent = this.getSatisfiedLabelText()
        })

        this.thumbsUp.style.marginRight = "20px"
        this.thumbsUpActive.style.marginRight = "20px"
        this.thumbsDown.style.marginLeft = "20px"
        this.thumbsDownActive.style.marginLeft = "20px"


        this.disableVoting()
    }

    protected onVoteError(): void {
        super.onVoteError()
        this.hideCommentEvent.fire(undefined)
        modalAlert(i18n.errorVotingForUser(this.roomName))
    }

    protected styleThumb(thumb: HTMLImageElement, source: string): void {
        super.styleThumb(thumb, source)
        thumb.height = 18
        thumb.width = 18
        thumb.style.height = "18px"
        thumb.style.width = "18px"
    }

    protected enableVoteMsg(): string {
        return i18n.afterTipVoteSatMobileText(this.roomName, minTokensToVote)
    }

    protected triggerComment(reset: boolean): void {
        this.commentTriggerEvent.fire(reset)
    }

    protected stopProp(ev: MouseEvent): void {
        if (this.enabled) {
            ev.stopPropagation()
        }
    }

    protected enableVoting(): void {
        this.element.style.color = "#333333"
        this.label.style.color = "#0471A1"
        this.enabled = true
        super.enableVoting()
    }

    protected disableVoting(): void {
        this.element.style.color = "#6C6C78"
        this.label.style.color = ""
        this.enabled = false
        super.disableVoting()
    }

    private divClicked(): void {
        if (!this.enabled) {
            modalAlert(i18n.allowToVoteMessage(this.roomName, minTokensToVote))
        }
    }

    protected setVoteState(voteState: IVoteState, isPending: boolean): void {
        super.setVoteState(voteState, isPending)
        if (isPending) {
            this.element.style.pointerEvents = "none"
            this.element.style.opacity = "0.7"
        } else {
            this.element.style.pointerEvents = ""
            this.element.style.opacity = "1"
        }
    }

    private getSatisfiedLabelText(context?: IRoomContext): string {
        if (context !== undefined && context.dossier.hideSatisfactionScore && context.dossier.staffInfo !== undefined) {
            return this.hideSatisfiedText ? "" : i18n.hiddenStaffOnlyTextCAPS
        } else {
            return this.hideSatisfiedText ? "" : i18n.satisfiedText
        }
    }

}

export class MobileSatisfactionComment extends Component<HTMLDivElement> {
    public hideEvent = new EventRouter<undefined>("satisfactionCommentHide")
    private hideTimeout: number
    private message = document.createElement("div")
    private commentSection = document.createElement("div")
    private label = document.createElement("p")
    private textBox = document.createElement("textarea")
    private textLimit = document.createElement("span")
    private buttonBar = document.createElement("div")
    private submittedMsg = document.createElement("p")
    private roomName = ""
    private submitted = false

    constructor() {
        super()
        applyStyles(this.element, {
            display: "none",
            position: "static",
            color: "#6C6C78",
            fontSize: "14px",
            height: "calc(100% - 45px)",
            padding: "19px",
            boxSizing: "border-box",
            overflowY: "scroll",
        })
        this.element.onclick = (ev: MouseEvent) => {
            ev.stopPropagation()
        }

        this.message.style.fontSize = "14px"
        this.element.appendChild(this.message)

        this.label.style.fontWeight = "bold"
        this.label.style.margin = "0.5em 0px 0.2em"
        this.label.style.display = "none"
        this.label.innerText = i18n.optionalCommentNote

        this.textBox.maxLength = 155
        this.textBox.style.boxSizing = "border-box"
        this.textBox.style.width = "100%"
        this.textBox.style.fontSize = "16px"
        this.textBox.style.padding = "3px"
        this.textBox.style.border = "1px solid #A4A4A4"
        this.textBox.style.height = "55px"
        this.textBox.style.display = "none"
        this.textBox.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"
        this.textBox.value = ""

        this.textLimit.style.display = "none"
        this.textLimit.style.float = "right"
        this.updateTextLimit()

        this.textBox.oninput = () => {
            this.updateTextLimit()
        }

        this.buttonBar.style.paddingTop = "25px"
        this.buttonBar.style.display = "none"

        const submitBtn = document.createElement("a")
        submitBtn.textContent = i18n.submitCAPS
        submitBtn.style.cssFloat = "right"
        submitBtn.style.display = "block"
        submitBtn.style.width = "auto"
        submitBtn.style.padding = "6px 16px"
        submitBtn.style.fontSize = "16px"
        submitBtn.style.color = "#FFF"
        submitBtn.style.backgroundColor = "#FF7002"
        submitBtn.onclick = () => {
            this.submitComment(this.textBox.value)
        }
        this.buttonBar.appendChild(submitBtn)

        const cancelBtn = document.createElement("a")
        cancelBtn.textContent = i18n.cancelText
        cancelBtn.style.cssFloat = "right"
        cancelBtn.style.display = "block"
        cancelBtn.style.width = "auto"
        cancelBtn.style.padding = "6px 12px"
        cancelBtn.style.fontSize = "16px"
        cancelBtn.style.color = "#1C6B92"
        cancelBtn.style.marginRight = "8px"
        cancelBtn.onclick = () => {
            this.reset()
            this.hide()
        }
        this.buttonBar.appendChild(cancelBtn)

        this.submittedMsg.textContent = i18n.commentSubmittedMessage
        this.submittedMsg.style.display = "none"
        this.submittedMsg.style.height = "80px"
        this.submittedMsg.style.lineHeight = "80px"
        this.submittedMsg.style.textAlign = "center"
        this.submittedMsg.style.fontWeight = "bold"

        this.commentSection.appendChild(this.label)
        this.commentSection.appendChild(this.textBox)
        this.commentSection.appendChild(this.textLimit)
        this.commentSection.appendChild(this.buttonBar)
        this.commentSection.appendChild(this.submittedMsg)
        this.element.appendChild(this.commentSection)

        roomLoaded.listen((context: IRoomContext)  => {
            this.setRoom(context.dossier.room)
        })
    }

    setRoom(room: string): void {
        this.roomName = room
        this.message.innerText = i18n.confidentalVoteMessage(room)
    }

    show(): void {
        this.element.style.display = "block"
        if (this.textBox.parentNode !== null) {
            this.textBox.focus()
        }
        if (this.submitted) {
            this.delayHide(5000)
        }
    }

    reset(): void {
        this.submitted = false
        this.textBox.value = ""
        this.updateTextLimit()
        this.submittedMsg.style.display = "none"
        this.label.style.display = "block"
        this.textBox.style.display = "block"
        this.textLimit.style.display = "block"
        this.buttonBar.style.display = "block"
    }

    private updateTextLimit(): void {
        this.textLimit.innerText = `${this.textBox.value.length}/${this.textBox.maxLength}`
    }

    private submitComment(comment: string): void {
        this.label.style.display = "none"
        this.textBox.style.display = "none"
        this.textLimit.style.display = "none"
        this.buttonBar.style.display = "none"
        this.submittedMsg.textContent = i18n.submittingText
        this.submittedMsg.style.display = "block"
        this.submitted = true
        postCb(`tipping/add_comment/${this.roomName}/`, { "comment": comment }).then(() => {
            this.submittedMsg.textContent = i18n.commentSubmittedMessage
            this.delayHide(5000)
        }).catch((err) => {
            error("Error rating model", err)
            this.submittedMsg.textContent = i18n.errorSubmitting
            this.delayHide(5000)
        })
    }

    delayHide(timeout: number): void {
        clearTimeout(this.hideTimeout)
        this.hideTimeout = window.setTimeout(() => {
            this.hide()
        }, timeout)
    }

    hide(): void {
        clearTimeout(this.hideTimeout)
        this.element.style.display = "none"
        this.hideEvent.fire(undefined)
        this.reset()
    }
}
