import { Component } from "./defui/component"
import { EventRouter } from "./events"
import { colorModeChangeRequest } from "./fullvideolib/userActionEvents"
import type { BoundListener } from "./events"

interface IRecaptchaRenderParameters {
    sitekey: string
    theme?: "dark" | "light"
    size?: "compact" | "normal"
    tabindex?: number
    callback?: (token: string) => void
    "expired-callback"?: () => void
    "error-callback"?: () => void
}

let grecaptcha: ReCaptchaV2.ReCaptcha
const recaptchaLoaded = new EventRouter<undefined>("recaptchaLoaded", { maxHistorySize: 1 })

// Callback for when the recaptcha <script> finishes loading. See room_login.html for what the <script> should look like
window["recaptchaLoadCallback"] = () => {
    grecaptcha = window["grecaptcha"]
    recaptchaLoaded.fire(undefined)
}

export class RecaptchaField extends Component {
    private listener: BoundListener<string> | undefined
    private widgetData: { widgetID: number, parameters: IRecaptchaRenderParameters } | undefined

    constructor() {
        super()
        this.element.style.height = ""
        this.element.style.width = ""
    }

    public render(parameters: IRecaptchaRenderParameters): void {
        recaptchaLoaded.once(() => {
            if (parameters.theme === undefined) {
                parameters.theme = document.body.classList.contains("darkmode") ? "dark" : "light"
            }
            if (this.listener === undefined) {
                this.listener = colorModeChangeRequest.listen((colorMode: string) => {
                    if (this.widgetData !== undefined) {
                        this.widgetData.parameters.theme = colorMode === "darkmode" ? "dark" : "light"
                        this.reset()
                    }
                })
            }
            const widgetID = grecaptcha.render(this.element, parameters)
            this.widgetData = {
                widgetID: widgetID,
                parameters: parameters,
            }
        })
    }

    public reset(): void {
        if (this.widgetData !== undefined) {
            grecaptcha.reset(this.widgetData.widgetID)
            this.updateTheme()
        }
    }

    public getResponse(): string {
        return this.widgetData !== undefined ? grecaptcha.getResponse(this.widgetData.widgetID) : ""
    }

    private updateTheme(): void {
        if (this.widgetData !== undefined) {
            const recaptchaIframe = this.element.getElementsByTagName("iframe")[0]
            if (this.widgetData.parameters.theme === "dark") {
                recaptchaIframe.src = recaptchaIframe.src.replace("theme=light", "theme=dark")
            } else {
                recaptchaIframe.src = recaptchaIframe.src.replace("theme=dark", "theme=light")
            }
        }
    }
}
