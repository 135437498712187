import { dom } from "../../../common/tsxrender/dom"
import { MobileInputSuggestions } from "./mobileInputSuggestions"
import { ISearchInputProps, SearchInput } from "./searchInput"

export class MobileSearchInput extends SearchInput {
    protected initUI(props?: ISearchInputProps): void {
        super.initUI(props)
        this.element.classList.add("MobileSearchInput")
        // Create the Submit button for mobile
        const submitButtonHandler = (e: MouseEvent) => {
            e.preventDefault()
            this.onSubmitSearchInput()
        }
        const submitButton = <button className="submit" onClick={submitButtonHandler}>search</button>
        this.form.appendChild(submitButton)
        this.handleMobileSortDisplay()
    }

    protected initSuggestions(): MobileInputSuggestions {
        return <MobileInputSuggestions
            classRef = {(ref) => {this.suggestionsDiv = ref}}
            input={this.input}
            searchInput={this}
        />
    }

    public onSubmitSearchInput(): void {
        super.onSubmitSearchInput()
        this.handleMobileSortDisplay()
    }

    private handleMobileSortDisplay(): void {
        const mobileSortContainer = document.querySelector<HTMLElement>("#mobile-sort-container")
        if (mobileSortContainer) {
            if(this.searchKeywords !== "") {
                mobileSortContainer.style.display = "none"
            } else {
                mobileSortContainer.style.display = "block"
            }
        }
    }
}
