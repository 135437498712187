import { ScrollableComponent } from "../../../../../common/scrollableComponent"
import { i18n } from "../../../../../common/translation"
import { UserUpdateRow } from "./userUpdateRow"
import type { IUserUpdateItem } from "../../../../../common/messageInterfaces"

export class UpdatesTabContents extends ScrollableComponent {
    constructor() {
        super()
        this.addChild(new UserUpdateRow(({
            text: `${i18n.loadingTextLower}...`, seen: true, timestamp: 0, timeSince: "", url: "",
        } as IUserUpdateItem)))
    }

    setUpdateItems(data: IUserUpdateItem[]): void {
        this.removeAllChildren()
        if (data.length === 0) {
            this.addChild(new UserUpdateRow(({
                text: `${i18n.noNewUpdates}`, seen: true, timestamp: 0, timeSince: "", url: "",
            } as IUserUpdateItem)))
        }
        data.forEach(item => {
            this.addChild(new UserUpdateRow(item))
        })
    }

    protected initUI(): void {
        this.element.style.maxHeight = "307px"
        // needed for iPad scrolling creating empty spaces
        this.element.style["-webkit-transform"] = "translateZ(0)"
    }
}

export class MobileUpdatesTabContents extends UpdatesTabContents {
    protected initUI(): void {
        super.initUI()
        this.element.style.maxHeight = ""
        this.element.style.overflowY = "scroll"
    }
}
