import { Topic } from "./base"
import type { ITopicMessage } from "./base"
import type { IPushBackendChange } from "../../../common/messageInterfaces"
import type { ArgJSONMap } from "@multimediallc/web-utils"

// Represents a global topic, ie push:global:news
export abstract class GlobalTopic<T extends ITopicMessage> extends Topic<T> {
    public constructor() {
        super({})
    }

    public getKey(): string {
        return `${this.getId()}`
    }

    protected initData(props: object): void {
        // nothing to init
    }
}

export class GlobalPushServiceBackendChangeTopic extends GlobalTopic<IPushBackendChange> {
    public getId(): string {
        return "GlobalPushServiceBackendChangeTopic"
    }

    public parseData(data: ArgJSONMap): IPushBackendChange {
        return {
            ...super.parseData(data),
            backends: data.getList("backends")?.map((backend) => backend.getString("backend")) ?? [],
        }
    }
}
