import { loadRoomRequest } from "../../../common/fullvideolib/userActionEvents"
import { i18n } from "../../../common/translation"
import { isRoomRoomlistSpaEligiblePage } from "../../components/roomlist/spaHelpers"
import { BaseSuggestedItem } from "./baseSuggestedItem"
import type { ISuggestionProps } from "./baseSuggestedItem"

export class OnlineSuggestedItem extends BaseSuggestedItem {
    protected getIconClassName(): string {
        return "icon-online"
    }

    protected getStatusText(): string {
        return i18n.broadcastingNow
    }

    protected getRowClassName(): string {
        return "broadcaster_suggestion"
    }

    protected onClickHandler(props: ISuggestionProps, evt?: MouseEvent): void {
        super.onClickHandler(props, evt)
        if (isRoomRoomlistSpaEligiblePage()) {
            evt?.preventDefault()
            loadRoomRequest.fire(props.labelText)
        }
    }
}
