import { getCb } from "../../common/api"

export enum RoomAccessCode {
    AccessDenied = "access-denied",
    Unauthorized = "unauthorized",
    PasswordProtected = "password-required",
    Ok = "ok",
}

export function getRoomAccess(username: string): Promise<XMLHttpRequest> {
    return getCb(`api/ts/chat/room-access/${username}/`)
}
