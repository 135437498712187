import { pageContext, roomDossierContext } from "../cb/interfaces/context"
import { IUserInfo } from "./messageInterfaces"
import { i18n } from "./translation"

export enum RoomType {
    Public = "public",
    Private = "private",
    DM = "sitewidePMs",
}

export function getUsernameColorClass(u: IUserInfo): string {
    if (u.isBroadcaster) {
        return "broadcaster"
    } else if (u.isMod) {
        return "mod"
    } else if (u.inFanclub) {
        return "inFanclub"
    } else if (u.tippedTonsRecently) {
        return "tippedTonsRecently"
    } else if (u.tippedALotRecently) {
        return "tippedALotRecently"
    } else if (u.tippedRecently) {
        return "tippedRecently"
    } else if (u.hasTokens) {
        return "hasTokens"
    }
    return "defaultUser"
}

export function getBrightUsernameColor(u: IUserInfo): string {
    if (u.isBroadcaster) {
        return "#ff6200"
    } else if (u.isMod) {
        return "#DC0000"
    } else if (u.inFanclub) {
        return "#00ff00"
    } else if (u.tippedTonsRecently) {
        return "#ad62e1"
    } else if (u.tippedALotRecently) {
        return "#d4a0ff"
    } else if (u.tippedRecently) {
        return "#8a98ff"
    } else if (u.hasTokens) {
        return "#84c6dc"
    }
    return "#b3b3b3"
}

export function getUserContextMenuText(u: IUserInfo): string | undefined {
    if (u.isBroadcaster) {
        return i18n.isBroadcaster
    } else if (u.isMod) {
        return i18n.isMod
    } else if (u.inFanclub) {
        return i18n.inFanClub
    } else if (u.tippedTonsRecently) {
        return i18n.tippedTonsRecently
    } else if (u.tippedALotRecently) {
        return i18n.tippedALotRecently
    } else if (u.tippedRecently) {
        return i18n.tippedRecently
    } else if (u.hasTokens) {
        return i18n.hasTokens
    }
    return undefined
}

export function getUserContextMenuSubText(u: IUserInfo): string | undefined {
    if (u.isMod || u.isBroadcaster) {
        return undefined
    } else if (u.inFanclub) {
        return undefined
    } else if (u.tippedTonsRecently) {
        return i18n.tippedTonsRecentlyDef
    } else if (u.tippedALotRecently) {
        return i18n.tippedALotRecentlyDef
    } else if (u.tippedRecently) {
        return i18n.tippedRecentlyDef
    } else if (u.hasTokens) {
        return i18n.hasTokensDef
    }
    return undefined
}

export function getGenderIconUrl(gender: string): string {
    gender = gender.toLowerCase()
    const base = `${STATIC_URL}gendericons/`
    if (["m", "male"].indexOf(gender) > -1) {
        return `${base}male.svg`
    } else
    if (["f", "female"].indexOf(gender) > -1) {
        return `${base}female.svg`
    } else
    if (["t", "s", "trans"].indexOf(gender) > -1) {
        return `${base}trans.svg`
    } else
    if (["c", "couple"].indexOf(gender) > -1) {
        return `${base}couple.svg`
    }
    error("Could not set menu gender icon. Defaulting to male.")
    return `${base}male.svg`
}

// Warning, until we fix roomDossierContext to be set before the page is built, this can initially return a false negative
export function isCurrentUserRoomOwner(): boolean {
    return pageContext.current.loggedInUser?.username === roomDossierContext.getState().room
}

export function getCurrentRoom(): string {
    return roomDossierContext.getState().room
}
