import { addEventListenerPoly, removeEventListenerPoly } from "../../../../common/addEventListenerPolyfill"
import { isAnonymous } from "../../../../common/auth"
import { MobileVideoControls } from "../../../../common/mobilelib/mobileVideoControls"
import { OverlayComponent } from "../../../../common/overlayComponent"
import { isNonChatInputFocused } from "../../../../common/theatermodelib/eventListeners"
import { i18n } from "../../../../common/translation"
import { addColorClass } from "../../../colorClasses"
import { pageContext, roomDossierContext } from "../../../interfaces/context"
import type { IChatContents } from "../../../../common/chatRoot"
import type { CustomInput } from "../../../../common/customInput"
import type { IUserChatSettings } from "../../../../common/roomDossier"

let rulesModal: RulesModal | undefined

function hashFn(s: string): string {
    return (
        Array.from(
            s, ch => ch.charCodeAt(0),
        ).reduce(
            (a, b) => ((a << 5) - a + b) | 0, 0,
        ) >>> 0
    ).toString(16)
}

function areRulesAccepted(roomName: string, chatRules: string): boolean {
    const username = pageContext.current.loggedInUser?.username

    if (username === roomName) {
        return true
    }

    const rulesHash = hashFn(chatRules.trim())
    const key = `rules_accepted_${username}_${roomName}`
    return localStorage.getItem(key) === rulesHash
}

export function getRulesModal(): RulesModal | undefined {
    return rulesModal
}

export function rulesModalVisible(): boolean {
    // used in eventListener to identify if any modal is open to keep focus in modal for 'Tab' keypdown events
    return rulesModal !== undefined && rulesModal?.isShown()
}

export function hideRulesModal(): void {
    return rulesModal?.hide()
}

export function maybeShowRulesModal(parent: IChatContents | MobileVideoControls): boolean {
    // just in case we missed removing a modal from a previous room
    hideRulesModal()
    const currentDossier = roomDossierContext.getState()
    const roomName = currentDossier.room
    const chatRules = currentDossier.chatRules
    const userChatSettings = currentDossier.userChatSettings
    if (!isAnonymous() && chatRules.trim().length > 0 && !areRulesAccepted(roomName, chatRules)) {
        if (parent instanceof MobileVideoControls) {
            rulesModal = new RulesModal({
                inputContainer: parent.centerControlsDiv,
                customInputField: parent.chatInput,
                roomName: roomName,
                chatRules: chatRules,
                container: parent,
                chatSettings: userChatSettings,
            }, true)
            rulesModal.element.style.position = "fixed"
            rulesModal.element.style.zIndex = "999999"
        } else {
            rulesModal = new RulesModal({
                inputContainer: parent.inputDiv,
                customInputField: parent.customInputField,
                roomName: roomName,
                chatRules: chatRules,
                container: parent,
                chatSettings: userChatSettings,
            })
            rulesModal.element.style.zIndex = "1001"
        }
        rulesModal.show()
        return true
    }
    return false
}

export interface IRulesModalSetup {
    inputContainer: HTMLElement
    customInputField: CustomInput
    roomName: string
    chatRules: string
    container: IChatContents | MobileVideoControls
    chatSettings: IUserChatSettings
}

export class RulesModal extends OverlayComponent {
    private inputContainer: HTMLElement
    private customInput: CustomInput
    private textPar: HTMLDivElement

    private acceptBtn: HTMLButtonElement
    private closeBtn: HTMLButtonElement
    private chatRules: string
    private isMobile: boolean

    public roomName: string
    private scrollIndicator: HTMLDivElement
    private container: IChatContents | MobileVideoControls

    private isVisible = false
    public parent?: IChatContents | MobileVideoControls

    constructor(props: IRulesModalSetup, mobileFullscreen = false) {
        super(props)
        this.isMobile = pageContext.current.isMobile
        this.overlayClick.listen(() => {
            this.hide()
        })

        if (mobileFullscreen) {
            this.element.style.width = "70%"
            this.element.style.left = "15%"
        } else {
            this.element.style.width = "95%"
            this.element.style.left = "2.5%"
        }
        this.updateScrollIndicator()
    }

    // needed for mobile to handle font changes because the
    // modal isn't a descendant of the chat contents
    private applyChatSettingsFontStyles(settings: IUserChatSettings): void {
        this.element.style.fontFamily = settings.fontFamily
        this.element.style.fontSize = settings.fontSize
    }

    protected initData(props: IRulesModalSetup): void {
        this.chatRules = props.chatRules.trim()
    }

    protected initUI(props: IRulesModalSetup): void {
        super.initUI()
        this.element.style.bottom = "10px"
        this.element.style.borderWidth = "1px"
        this.element.style.borderStyle = "solid"
        this.element.style.borderRadius = "4px"
        this.element.style.margin = "0 auto"
        this.element.style.padding = "10px"
        this.element.style.boxSizing = "border-box"
        this.element.style.maxHeight = "95%"
        this.applyChatSettingsFontStyles(props.chatSettings)
        addColorClass(this.element, "rulesModal")

        this.inputContainer = props.inputContainer
        this.customInput = props.customInputField
        this.roomName = props.roomName
        this.container = props.container

        this.element.style.position = "absolute"
        this.element.style.textAlign = "center"
        this.element.style.height = "auto"

        this.element.style.display = "none"

        const rulesStart = document.createElement("div")
        rulesStart.style.fontWeight = "bold"
        rulesStart.style.textAlign = "left"
        rulesStart.style.paddingBottom = "5px"
        rulesStart.style.paddingRight = "10px"
        rulesStart.style.whiteSpace = "nowrap"
        rulesStart.style.textOverflow = "ellipsis"
        rulesStart.style.overflow = "hidden"
        const capitalizedRoomName = `${this.roomName[0].toUpperCase()}${this.roomName.substring(1)}`
        rulesStart.innerText = `${capitalizedRoomName}'s Rules:`
        this.element.appendChild(rulesStart)

        this.textPar = this.createTextContent(this.chatRules)
        this.scrollIndicator = this.createScrollIndicator()
        const textContainer = document.createElement("div")
        textContainer.style.position = "relative"
        textContainer.appendChild(this.textPar)
        textContainer.appendChild(this.scrollIndicator)
        textContainer.dataset["testid"] = "chat-rules"
        this.element.appendChild(textContainer)

        this.acceptBtn = this.createAcceptButton()
        this.closeBtn = this.createCloseButton()

        this.element.appendChild(this.acceptBtn)
        this.element.appendChild(this.closeBtn)

        this.repositionChildren()
    }

    private createTextContent(text: string): HTMLDivElement {
        const div = document.createElement("div")
        div.style.whiteSpace = "pre-line"
        div.style.textAlign = "left"
        div.style.lineHeight = "1.4"
        div.style.height = "auto"
        div.style.paddingBottom = "15px"
        div.style.boxSizing = "border-box"
        div.style.width = "100%"
        div.style.overflowY = "scroll"
        div.style.overflowWrap = "break-word"
        div.style.wordBreak = "break-word"
        div.style.wordWrap = "break-word"
        div.style.cssText += "; -ms-overflow-style: -ms-autohiding-scrollbar"
        div.style.marginBottom = "3px"
        div.style.paddingRight = "15px"
        div.onscroll = () => { this.updateScrollIndicator() }

        div.appendChild(document.createTextNode(text))

        return div
    }

    private isTextScrolledToBottom(): boolean {
        return this.textPar.scrollHeight - this.textPar.scrollTop - this.textPar.clientHeight <= 1
    }

    private updateScrollIndicator(): void {
        if (this.isTextScrolledToBottom()) {
            this.scrollIndicator.style.display = "none"
        } else {
            this.scrollIndicator.style.display = "block"
        }
    }

    private createScrollIndicator(): HTMLDivElement {
        const div = document.createElement("div")
        addColorClass(div, "scrollIndicator")
        div.style.height = "50px"
        div.style.position = "absolute"
        div.style.bottom = "0"
        div.style.fontWeight = "bold"
        div.style.textAlign = "center"
        div.style.boxSizing = "border-box"
        div.style.width = "100%"
        return div
    }

    protected repositionChildren(): void {
        const h = this.container.element.getBoundingClientRect().height
        this.textPar.style.maxHeight = `calc(${h}px - 120px)`
        this.updateScrollIndicator()
    }

    private captureKeys = (event: KeyboardEvent) => {
        if (isNonChatInputFocused()) {
            return
        }

        if (event.key === "Tab") {
            if (document.activeElement === this.acceptBtn) {
                this.closeBtn.focus()
            } else {
                this.acceptBtn.focus()
            }
            event.stopPropagation()
            event.preventDefault()
        } else if (event.key === "Escape") {
            this.hide()
            event.stopPropagation()
            event.preventDefault()
        } else if (event.key !== "Enter") {
            event.stopPropagation()
            event.preventDefault()
        }
    }

    public show(): void {
        if (this.isVisible) {
            return
        }
        this.container.addChild(this)
        this.isVisible = true
        this.element.style.display = "block"
        this.customInput.disable()
        this.inputContainer.style.pointerEvents = "none"
        this.showOverlay()
        this.repositionChildren()
        // ideally we really need to avoid messing with tabs and let browser handle it
        // disable for a mobile at least for now
        if (!this.isMobile) {
            addEventListenerPoly("keydown", document, this.captureKeys, true)
        }
    }

    public hide(): void {
        if (!this.isVisible) {
            return
        }
        super.hide()
        this.isVisible = false
        this.customInput.enable()
        this.inputContainer.style.pointerEvents = ""
        this.element.remove()
        this.overlay.remove()
        this.container.repositionChildrenRecursive()
        rulesModal = undefined

        if (!this.isMobile) {
            removeEventListenerPoly("keydown", document, this.captureKeys, true)
        }
    }

    private accept(): void {
        const key = `rules_accepted_${pageContext.current.loggedInUser?.username}_${this.roomName}`
        const rulesHash = hashFn(this.chatRules)
        localStorage.setItem(key, rulesHash)
        this.hide()
        this.customInput.focus()
    }

    private createAcceptButton(): HTMLButtonElement {
        const button = document.createElement("button")
        button.innerText = i18n.acceptRules
        button.style.fontFamily = "'UbuntuRegular',sans-serif"
        button.style.display = "inline-block"
        button.style.boxSizing = "content-box"
        button.style.width = "calc(100% - 16px)"
        button.style.padding = "8px"
        button.style.borderRadius = "4px"
        button.style.cursor = "pointer"
        button.style.fontWeight = "500"
        button.style.lineHeight = "16px"
        button.style.whiteSpace = "nowrap"
        button.style.overflow = "hidden"
        button.style.textOverflow = "ellipsis"
        button.style.fontSize = "120%"
        button.style.zIndex = "2"
        button.dataset["testid"] = "chat-rules-accept"

        addEventListenerPoly("click", button, () => { this.accept() })
        addColorClass(button, "acceptRulesButton")
        return button
    }

    private createCloseButton(): HTMLButtonElement {
        const button = document.createElement("button")
        const img = document.createElement("img")
        img.src = `${STATIC_URL}close_icon.svg`
        img.style.backgroundColor = "inherit"
        button.appendChild(img)
        button.style.boxSizing = "border-box"
        button.style.padding = "5px 5px 4px 5px"
        button.style.position = "absolute"
        button.style.top = "2px"
        button.style.right = "2px"
        button.style.cursor = "pointer"
        button.dataset["testid"] = "chat-rules-close"
        addEventListenerPoly("click", button, () => { this.hide() })
        addColorClass(button, "closeRulesButton")
        return button
    }
}
