import { durationToSeconds } from "../duration";
// Given a cookie key `name`, returns the value of
// the cookie or empty string, if the key is not found.
export function getCookieOrUndefined(name) {
    const nameLenPlus = name.length + 1;
    // eslint-disable-next-line no-restricted-syntax
    return document.cookie
        .split(";")
        .map((c) => c.trim())
        .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
    })
        .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
    })[0];
}
export function getCookieOrEmptyString(name) {
    var _a;
    return (_a = getCookieOrUndefined(name)) !== null && _a !== void 0 ? _a : "";
}
function getDomainWithPrefixedDot() {
    let domain = window.location.hostname;
    // remove www. from domain
    if (domain.indexOf("www.") !== -1) {
        domain = domain.replace("www.", "");
    }
    // check if there is 2 letter language subdomain or chinese dialects
    // through regex and remove it
    const langSubdomain = domain.match(/^(?:[a-z]{2}|zh-hans|zh-hant)\./);
    if (langSubdomain) {
        domain = domain.replace(langSubdomain[0], "");
    }
    // Add a dot in front of the domain if it's not there
    if (domain.indexOf(".") !== 0) {
        domain = `.${domain}`;
    }
    return domain;
}
// Sets cookie value for given duration
export function setCookieWithExpiration(cookieName, cookieValue, duration, path = "/", explicitlySetDomain = false) {
    const d = new Date();
    d.setTime(d.getTime() + 1000 * durationToSeconds(duration));
    let cookie = `${cookieName}=${cookieValue}; expires=${d.toUTCString()}; path=${path}`;
    if (explicitlySetDomain) {
        cookie += `; domain=${getDomainWithPrefixedDot()}`;
    }
    document.cookie = cookie; // eslint-disable-line no-restricted-syntax
}
export function setSessionCookie(cookieName, cookieValue, explicitlySetDomain = false) {
    let cookie = `${cookieName}=${cookieValue}; path=/`;
    if (explicitlySetDomain) {
        cookie += `; domain=${getDomainWithPrefixedDot()}`;
    }
    document.cookie = cookie; // eslint-disable-line no-restricted-syntax
}
export function getNumberCookieOrUndefined(name) {
    // getCookieOrEmptyString also returns undefined, so, can't use
    const cookie = getCookieOrUndefined(name);
    if (cookie === undefined) {
        return cookie;
    }
    const num = parseInt(cookie);
    if (!isNaN(num)) {
        return num;
    }
    return undefined;
}
export function getBooleanCookieOrUndefined(name) {
    const cookie = getCookieOrUndefined(name);
    if (cookie === "true") {
        return true;
    }
    if (cookie === "false") {
        return false;
    }
    return undefined;
}
export function deleteCookie(name) {
    setCookieWithExpiration(name, "", { days: -1 });
}
