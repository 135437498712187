import { showPmSession } from "../../cb/components/pm/userActionEvents"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { userInitiatedPm } from "../userActionEvents"
import { MobileConversationList } from "./mobileConversationList"
import { MobilePmWindow } from "./mobilePmWindow"
import { userSwitchedTab } from "./userActionEvents"

export class PmManager extends Component {
    private pmList: MobileConversationList
    private pmWindow: MobilePmWindow

    constructor() {
        super()

        roomLoaded.listen(() => {
            this.showPmList()
        })

        showPmSession.listen((username: string) => {
            this.showPmWindow()
            this.pmWindow.definePMLinkOnClick(username)
        })

        userInitiatedPm.listen((notification) => {
            if (notification.focus) {
                this.showPmWindow()
            }
        })

        userSwitchedTab.listen(() => {
            this.showPmList()
        })
    }

    protected initUI(): void {
        // use data-hj-surpress to surpress recordings for private messages due to privacy concern
        // read more about data-hj-surpress on :
        // https://help.hotjar.com/hc/en-us/articles/115012439167-How-to-Suppress-Text-Images-and-User-Input-from-Collected-Data
        this.element.classList.add("data-hj-suppress")
        this.pmWindow = new MobilePmWindow({
            leavePmWindow: () => {
                this.showPmList()
            },
        })
        this.pmList = new MobileConversationList({
            onItemRemoved: (username: string) => {this.pmWindow.afterListItemRemoved(username)},
            isDropdown: false,
            openConversationEvent: showPmSession,
        })

        this.addChild(this.pmList)
        this.addChild(this.pmWindow)
        this.showPmList()
    }

    private showPmList(): void {
        this.pmList.showElement()
        this.pmWindow.hideCurrentPMSession()

        // use `visibility` instead of `display` because it affects
        // chatContents input from being positioned to the top of the keyboard when it's first focused
        this.pmWindow.element.style.visibility = "hidden"
    }

    private showPmWindow(): void {
        this.pmList.hideElement()
        this.pmWindow.element.style.visibility = "visible"
    }

    public getPmWindow(): MobilePmWindow {
        return this.pmWindow
    }
}
