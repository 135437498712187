import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { mediumPopupLinkFeatures, safeWindowOpen } from "../../../common/windowUtils"

export function bindAgeGateSignup(): void {
    const agHome = document.querySelector<HTMLDivElement>("#age_gate_home")
    const agSignup = document.querySelector<HTMLDivElement>("#ag_signup")
    if (agHome === null || agSignup === null) {
        return
    }

    // Make sure popup links (eg, SSO signup) function correctly, similar to JoinOverlay component implementation.
    const loadingMsg = agSignup.querySelector(".loading-msg") as HTMLDivElement
    const iframeForm = agSignup.querySelector("iframe") as HTMLIFrameElement
    addEventListenerPoly("load", iframeForm,  () => {
        iframeForm.contentDocument?.querySelectorAll<HTMLAnchorElement>("a.medium_popup_link").forEach((link) => {
            link.onclick = (event) => {
                event.preventDefault()
                safeWindowOpen(link.href, "_blank", mediumPopupLinkFeatures)
            }
        })
        loadingMsg.style.display = "none"
        iframeForm.style.display = "inline"
    })

    addEventListenerPoly("orientationchange", iframeForm,  () => {
        iframeForm.contentWindow?.location.reload()
    })

    // Show user signup form when they click verify button
    const submitButton = agHome.querySelector("a.age-gate-verify") as HTMLAnchorElement
    addEventListenerPoly("click", submitButton, () => {
        agHome.style.display = "none";
        agSignup.style.display = "block";

        if (iframeForm.src === "") {
            iframeForm.src = iframeForm.dataset.src ?? ""
        }
    })
}
