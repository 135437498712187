import { addColorClass, removeColorClass } from "../../cb/colorClasses"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { dom } from "../tsxrender/dom"
import { RoomTabs } from "./roomTabs"
import { TabName } from "./tabList"
import { userSwitchedTab } from "./userActionEvents"
import type { BaseRoomTab } from "./baseRoomTab"
import type { TabList } from "./tabList"

interface IHeaderProps {
    tabList: TabList
}

export class Header extends Component<HTMLUListElement, IHeaderProps> {
    private activeTab: TabName
    private headerTabs: { tabName: TabName, element: HTMLLIElement }[]
    private numChatUnreadContainer: HTMLSpanElement
    private numPmsUnreadContainer: HTMLSpanElement
    private chatUnreadBubble: HTMLSpanElement
    private pmUnreadBubble: HTMLSpanElement

    constructor(props: IHeaderProps) {
        super("ul", props)
    }

    protected initData(props: IHeaderProps): void {
        this.activeTab = TabName.Chat
    }

    protected initUI(props: IHeaderProps): void {
        const containerStyle: CSSX.Properties = {
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            boxSizing: "border-box",
            width: "100%",
            height: "43px",
            margin: 0,
            padding: 0,
            overflowX: "scroll",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            display: "flex",
        }
        const numUnreadStyle: CSSX.Properties = {
            fontSize: "10px",
            fontFamily: "UbuntuBold, Arial, Helvetica, sans-serif",
        }

        this.numChatUnreadContainer = <span colorClass="numUnread" style={numUnreadStyle} />
        this.numPmsUnreadContainer = <span colorClass="numUnread" style={numUnreadStyle} />
        this.chatUnreadBubble = <UnreadBubble unreadContainer={this.numChatUnreadContainer} />
        this.pmUnreadBubble = <UnreadBubble unreadContainer={this.numPmsUnreadContainer} />
        this.headerTabs = props.tabList.getList().map((tab) => {
            return {
                tabName: tab.getTabName(),
                element:
                    <HeaderTab
                        tab={tab}
                        onClick={() => {userSwitchedTab.fire(tab.getTabName())}}
                        chatUnreadBubble={this.chatUnreadBubble}
                        pmUnreadBubble={this.pmUnreadBubble}
                    />,
            }
        })
        this.element = (
            <ul id="tab-room-header" style={containerStyle} colorClass="Header" className="noScrollbar">
                {this.headerTabs.map((tab) => tab.element)}
            </ul>
        )

        props.tabList.getChatTab().getChatWindow().numUnreadChanged.listen((num) => {
            this.setChatUnreadBubble(num)
        })

        props.tabList.getPrivateTab().numUnreadChanged.listen((num) => {
            this.setPmsUnreadBubble(num)
        })

        this.setActiveTabStyles(this.activeTab)
        userSwitchedTab.listen((tabName) => {
            const headerTab = this.headerTabs.find((tab) => tab.tabName === tabName)

            if (headerTab === undefined) {
                this.setActiveTab(RoomTabs.currentTab)
                return
            }

            const inFirstHalfOfHeader = headerTab.element.offsetLeft < this.element.offsetWidth / 2

            if (inFirstHalfOfHeader) {
                this.element.scrollLeft = 0
            } else {
                this.element.scrollLeft = headerTab.element.offsetLeft
            }

            this.setActiveTab(tabName)
        })
    }

    private setChatUnreadBubble(numUnread: number): void {
        if (numUnread > 0) {
            this.chatUnreadBubble.style.display = "flex"

            if (numUnread >= 99) {
                this.numChatUnreadContainer.style.fontSize = "9px"
                this.numChatUnreadContainer.innerText = "99+"
            } else {
                this.numChatUnreadContainer.style.fontSize = "10px"
                this.numChatUnreadContainer.innerText = `${numUnread}`
            }
        } else {
            this.chatUnreadBubble.style.display = "none"
        }
    }

    private setPmsUnreadBubble(numUnread: number): void {
        if (numUnread > 0) {
            this.pmUnreadBubble.style.display = "flex"

            if (numUnread >= 99) {
                this.numPmsUnreadContainer.style.fontSize = "9px"
                this.numPmsUnreadContainer.innerText = "99+"
            } else {
                this.numPmsUnreadContainer.style.fontSize = "10px"
                this.numPmsUnreadContainer.innerText = `${numUnread}`
            }
        } else {
            this.pmUnreadBubble.style.display = "none"
        }
    }

    private setActiveTab(tabName: TabName): void {
        this.setInactiveTabStyles(this.activeTab)
        this.activeTab = tabName
        this.setActiveTabStyles(this.activeTab)
    }

    private setActiveTabStyles(tabName: TabName): void {
        const headerTab = this.headerTabs.find((tab) => tab.tabName === tabName)

        if (headerTab === undefined) {
            return
        }

        removeColorClass(headerTab.element, "inactiveTab")
        addColorClass(headerTab.element, "activeTab")
        applyStyles(headerTab.element, {
            borderBottomStyle: "solid",
            borderBottomWidth: "3px",
        })
    }

    private setInactiveTabStyles(tabName: TabName): void {
        const headerTab = this.headerTabs.find((tab) => tab.tabName === tabName)

        if (headerTab === undefined) {
            return
        }

        const isRoomMenuTab = tabName === TabName.RoomMenu
        removeColorClass(headerTab.element, "activeTab")
        addColorClass(headerTab.element, "inactiveTab")

        if (isRoomMenuTab) {
            addColorClass(headerTab.element, "roomMenu")
        }

        applyStyles(headerTab.element, {
            borderBottom: "none",
        })
    }
}

// eslint-disable-next-line complexity
const HeaderTab = (props: {
    tab: BaseRoomTab,
    onClick: () => void,
    chatUnreadBubble: HTMLSpanElement
    pmUnreadBubble: HTMLSpanElement
}) => {
    const isChatTab = props.tab.getTabName() === TabName.Chat
    const isTokensTab = props.tab.getTabName() === TabName.Tokens
    const isRoomMenuTab = props.tab.getTabName() === TabName.RoomMenu
    const isPrivateTab = props.tab.getTabName() === TabName.Private
    const listItemStyle: CSSX.Properties = {
        listStyleType: "none",
        display: "inline-block",
        height: "100%",
        lineHeight: "42px",
        fontFamily: "'UbuntuBold', Arial, Helvetica, sans-serif",
        fontSize: "14px",
        boxSizing: "border-box",
        textAlign: "center",
        position: "relative",
        flex: 1,
        letterSpacing: isRoomMenuTab ? "1px" : "",
    }
    const iconStyle: CSSX.Properties = {
        display: "inline-block",
        width: "16px",
        height: "16px",
        marginRight: "4px",
        position: "relative",
        top: "4px",
    }
    const roomMenuStyle: CSSX.Properties = {
        transform: "rotate(90deg)",
        display: "inline-block",
    }
    const centerTextStyle: CSSX.Properties = {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }

    let innerContent: HTMLSpanElement

    if (isChatTab) {
        innerContent = (
            <span style={centerTextStyle} data-testid="chat-tab-default">
                {props.tab.getTabLabel()}
                {props.chatUnreadBubble}
            </span>
        )
    } else if (isTokensTab) {
        innerContent = (
            <span data-testid="tokens-tab">
                <span style={iconStyle} colorClass="tipIcon" />
                {props.tab.getTabLabel()}
            </span>
        )
    } else if (isRoomMenuTab) {
        innerContent = (
            <span style={roomMenuStyle} data-testid="additional-menu-options">
                {props.tab.getTabLabel()}</span>
        )
    } else if (isPrivateTab) {
        innerContent = (
            <span style={centerTextStyle} data-testid="pm-tab-default">
                {props.tab.getTabLabel()}
                {props.pmUnreadBubble}
            </span>
        )
    } else {
        let testid = ""

        switch (props.tab.getTabName()) {
            case TabName.MoreRooms:
                testid = "more-rooms-tab"
                break
            case TabName.Bio:
                testid = "bio-tab"
                break
            default:
                testid = ""
                break
        }

        innerContent = (
            <span data-testid={testid}>{props.tab.getTabLabel()}</span>
        )
    }

    return (
        <li
            style={listItemStyle}
            colorClass={[
                "inactiveTab",
                "smallPadding",
                ...[isRoomMenuTab ? "roomMenu" : ""],
            ]}
            onClick={props.onClick}
        >
            {innerContent}
        </li>
    )
}

const UnreadBubble = (props: { unreadContainer: HTMLSpanElement }) => {
    const bubbleStyle: CSSX.Properties = {
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        padding: "6px",
        marginLeft: "4px",
    }

    return (
        <span style={bubbleStyle} colorClass="bubble">
            {props.unreadContainer}
        </span>
    )
}
