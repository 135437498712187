import { buildQueryString, parseQueryString } from "../../../common/urlUtil"

export function addPageQueryParam(pageNumber: number, url?: string, paramKey?: string): string {
    paramKey = paramKey ?? "page"
    let baseUrl = ""
    let queryString = ""

    if (url !== undefined) {
        const splitUrl = url.split("?")
        if (splitUrl.length === 2) {
            baseUrl = splitUrl[0]
            queryString = splitUrl[1]
        } else if (splitUrl.length <= 1) {
            baseUrl = url
        }
    } else {
        baseUrl = window.location.pathname
        queryString = window.location.search
    }

    const queryParams = parseQueryString(queryString)
    queryParams[paramKey] = `${pageNumber}`
    return `${baseUrl}?${buildQueryString(queryParams)}`
}
