import { addColorClass, colorClass } from "../../colorClasses"
import { MultipleCheckboxStyles } from "../../ui/fieldStyles"


export class AdvancedOptionsCheckboxStyles extends MultipleCheckboxStyles {
    // Class to prevent inline styling of MultipleCheckboxes, but keep color classes.
    public styleRow(row: HTMLElement): void {
        // Override
    }

    public styleLabelContainer(labelContainer: HTMLElement): void {
        // Override
    }

    public styleLabel(label: HTMLElement): void {
        addColorClass(label, colorClass.defaultColor)
    }

    public styleFieldContainer(fieldContainer: HTMLElement): void {
        addColorClass(fieldContainer, colorClass.defaultColor)
    }

    public styleField(field: HTMLElement): void {
        // Override
    }

    public styleHelpText(helpText: HTMLElement): void {
        addColorClass(helpText, colorClass.defaultColor)
    }
}