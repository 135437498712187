import { postCb } from "../common/api"
import { i18n } from "../common/translation"
import { chaturbateLogo } from "./ui/logo"

export interface ISiteSettings {
    CBAlias: string
    DBWLDomain: string
    tagLine: string
    tagLineColor: string
    siteName: string
    sanitizedSiteName: string
    jpegStreamUrl: string
    jpegRoomImgUrl: string
    cbGamesUrl: string
    canonicalBaseUrl: string
    bgColor: string
    navigationBgColor: string
    navigationFontColor: string
    navigationAltBgColor: string
    navigationAlt2BgColor: string
    navigationAlt2BgImage: string
    h1Color: string
    textColor: string
    defaultColor: string
    defaultTooltipColor: string
    userInfoAreaTextColor: string
    hrefColor: string
    tabBorderColor: string
    tabActiveColor: string
    tabInactiveColor: string
    tabActiveFontColor: string
    tabInactiveFontColor: string
    tagExhibColor: string
    tagFontColor: string
    tagOfflineColor: string
    tagPrivateColor: string
    tagSpyPriceColor: string
    contentBgColor: string
    camBgColor: string
    camBorderColor: string
    camSubjectColor: string
    camSubjectTagColor: string
    camAltTextColor: string
    camHrefColor: string
    tokenHrefColor: string
    footerHrefColor: string
    footerTextColor: string
    copyrightTextColor: string
    logoImageName: string
    signupBgColor: string
    signupAltBgColor: string
    logoWidth: number | undefined
    footerHtml: string
    userInfoBgColor: string
    headerGif: string
    footerGif: string
    usernameColor: string
    nextCamBgColor: string
    footerColNameColor: string
    footerRowColor: string
    footerSiteNavColor: string
    chatAreaTabColor: string
    defaultCampaignSlug: string
    isWhiteLabel: boolean
    shouldHideWaterMark: boolean
    shouldChangeFooterBorder: boolean
    shouldChangeFooterTextColor: boolean
    shouldAddWhiteLabelPoweredBy: boolean
    shouldShowHeaderAd: boolean
    showEarnFreeTokens: boolean
    shouldShowAltLanguages: boolean
    shouldShowFooterAddress: boolean
    roomSubjectToolTipColor: string
    loginOverlayColor: string
    dismissibleMessageColor: string
    recordHitURL?: string
    userInfoDropdownBgColor: string
    userInfoDropdownHighlightColor: string
    userInfoDropdownTextColor: string
    userUpdatesFeedbackLink: string,
    hideEntranceTerms: boolean,
    carouselActiveCircleColor: string,
    carouselArrowBgColor: string,
    carouselArrowColor: string,
    carouselBgColor: string,
    carouselBorderColor: string,
    carouselCircleColor: string,
    carouselHrefColor: string,
    carouselTitleColor: string,
    filterPanelBgColor: string,
    filterOptionBorderColor: string,
    filterPanelHighlightColor: string,
    filterSectionPrimaryTextColor: string,
    filterSectionSupportTextColor: string,
    filterPanelButtonActiveBgColor: string,
    filterPanelButtonHoverBgColor: string,
    metaDescription?: string,
    homepageTitle?: string,
}

export const defaultSiteSettings: ISiteSettings = {
    CBAlias: "chaturbate.com",
    DBWLDomain: "",
    tagLine: i18n.tagLine,
    tagLineColor: "#0b5d81",
    siteName: "Chaturbate",
    sanitizedSiteName: "Chaturbate",
    jpegStreamUrl: "https://jpeg.live.mmcdn.com/",
    jpegRoomImgUrl: "https://thumb.live.mmcdn.com/",
    cbGamesUrl: "https://games.multimediallc.com/",
    canonicalBaseUrl: "https://chaturbate.com/",
    bgColor: "#ffffff",
    navigationBgColor: "#0c6a93",
    navigationFontColor: "#ffffff",
    navigationAltBgColor: "#f47321",
    navigationAlt2BgColor: "#ffffff",
    navigationAlt2BgImage: `url(${STATIC_URL_ROOT}tsdefaultassets/gender_tab_bg.gif) repeat-x`,
    h1Color: "#004B5E",
    textColor: "#494949",
    defaultColor: "#494949",
    defaultTooltipColor: "#222222",
    userInfoAreaTextColor: "#222222",
    hrefColor: "#0A5B83",
    tabBorderColor: "#8bb3da",
    tabActiveColor: "#ffffff",
    tabInactiveColor: "#dde9f5",
    tabActiveFontColor: "#dc5500",
    tabInactiveFontColor: "#5e81a4",
    tagExhibColor: "#6D85B5",
    tagFontColor: "#FFF",
    tagOfflineColor: "#4F4F4F",
    tagPrivateColor: "#B60A42",
    tagSpyPriceColor: "#F37321",
    contentBgColor: "#FFFFFF",
    camBgColor: "#F0F1F1",
    camBorderColor: "#ACACAC",
    camSubjectColor: "#000",
    camSubjectTagColor: "#0A5A83",
    camAltTextColor: "#575757",
    camHrefColor: "#0A5A83",
    tokenHrefColor: "#e45900",
    footerHrefColor: "#676767",
    footerTextColor: "#494949",
    copyrightTextColor: "#494949",
    logoImageName: chaturbateLogo(),
    signupBgColor: "#f47321",
    signupAltBgColor: "#CD5D26",
    logoWidth: undefined,
    footerHtml: "",
    headerGif: `#ffffff url(${STATIC_URL_ROOT}tsdefaultassets/header_bg.gif) repeat-x`,
    footerGif: `#ffffff url(${STATIC_URL_ROOT}tsdefaultassets/footer_bg.gif) repeat-x`,
    userInfoBgColor: "#e9e9e9",
    usernameColor: "#ffffff",
    nextCamBgColor: "#FCEADB",
    footerColNameColor: "#494949",
    footerRowColor: "#0A5A83",
    footerSiteNavColor: "#dc5500",
    chatAreaTabColor: "#4C4C4C",
    defaultCampaignSlug: "",
    isWhiteLabel: false,
    shouldHideWaterMark: false,
    shouldChangeFooterBorder: false,
    shouldChangeFooterTextColor: false,
    shouldAddWhiteLabelPoweredBy: false,
    shouldShowHeaderAd: true,
    showEarnFreeTokens: true,
    shouldShowAltLanguages: true,
    shouldShowFooterAddress: true,
    roomSubjectToolTipColor: "#494949",
    loginOverlayColor: "#000000",
    dismissibleMessageColor: "#494949",
    userInfoDropdownBgColor: "#3C87BA",
    userInfoDropdownHighlightColor: "#F47321",
    userInfoDropdownTextColor: "#ffffff",
    userUpdatesFeedbackLink: "https://www.surveymonkey.com/r/DXYF5T8",
    hideEntranceTerms: window["hide_entrance_terms"] === true,
    carouselActiveCircleColor: "#F47321",
    carouselArrowBgColor: "#FFFFFF",
    carouselArrowColor: "#000000",
    carouselBgColor: "#F8F9fA",
    carouselBorderColor: "#ACACAC",
    carouselCircleColor: "#6E6F70",
    carouselHrefColor: "#0A5B83",
    carouselTitleColor: "#494949",
    filterPanelBgColor: "#F8F9FA",
    filterOptionBorderColor: "#E0EEFB",
    filterPanelHighlightColor: "#306A91",
    filterSectionPrimaryTextColor: "#525252",
    filterSectionSupportTextColor: "#6E6F70",
    filterPanelButtonActiveBgColor: "#E9E9E9",
    filterPanelButtonHoverBgColor: "#EEEEEE",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseSiteSettings(settings: Record<string, any>): ISiteSettings {
    const navigationFontColor = settings["navigation_font_color"]
    const textColor = settings["text_color"]
    const tabInactiveColor = settings["tab_inactive_color"]
    const tabInactiveFontColor = settings["tab_inactive_font_color"]
    const contentBgColor = settings["content_bgcolor"]
    const footerHrefColor = settings["footer_href_color"]
    const footerTextColor = settings["footer_text_color"]
    const userInfoDropdownBgColor = settings["tab_inactive_color"]
    const userInfoDropdownHighlightColor = settings["tab_active_color"]
    const userInfoDropdownTextColor = settings["tab_active_font_color"]
    const camHrefColor = settings["cam_href_color"]
    const siteName = settings["site_name"]

    const siteNameSantitizer = document.createElement("span")
    siteNameSantitizer.innerText = siteName
    const sanitizedSiteName = siteNameSantitizer.innerHTML // eslint-disable-line @multimediallc/no-inner-html

    return {
        CBAlias: settings["chaturbate_alias"],
        DBWLDomain: settings["dbwl_domain"],
        tagLine: settings["tagline"],
        tagLineColor: settings["tagline_color"],
        siteName: siteName,
        sanitizedSiteName: sanitizedSiteName,
        jpegStreamUrl: settings["jpeg_stream_url"],
        jpegRoomImgUrl: settings["jpeg_room_img_url"],
        cbGamesUrl: settings["cb_games_url"],
        canonicalBaseUrl: settings["canonical_base_url"],
        bgColor: settings["bgcolor"],
        navigationBgColor: settings["navigation_bgcolor"],
        navigationFontColor: navigationFontColor,
        navigationAltBgColor: settings["navigation_alt_bgcolor"],
        navigationAlt2BgColor: settings["navigation_alt_2_bgcolor"],
        navigationAlt2BgImage: "",
        h1Color: settings["h1_color"],
        textColor: textColor,
        defaultColor: defaultSiteSettings.defaultColor,
        defaultTooltipColor: defaultSiteSettings.defaultTooltipColor,
        userInfoAreaTextColor: textColor, // this overrides the default value with textColor
        hrefColor: settings["href_color"],
        tabBorderColor: settings["tab_border_color"],
        tabActiveColor: settings["tab_active_color"],
        tabInactiveColor: tabInactiveColor,
        tabActiveFontColor: settings["tab_active_font_color"],
        tabInactiveFontColor: tabInactiveFontColor,
        tagExhibColor: settings["tag_exhibitionist_color"],
        tagFontColor: settings["tag_font_color"],
        tagOfflineColor: settings["tag_offline_color"],
        tagPrivateColor: settings["tag_private_group_color"],
        tagSpyPriceColor: settings["tag_spy_price_color"],
        contentBgColor: contentBgColor,
        camBgColor: settings["cam_background_color"],
        camBorderColor: settings["cam_border_color"],
        camSubjectColor: settings["cam_text_color"],
        camSubjectTagColor: "#0C6A93",
        camAltTextColor: settings["cam_text_alt_color"],
        camHrefColor: camHrefColor,
        tokenHrefColor: camHrefColor,
        footerHrefColor: footerHrefColor,
        footerTextColor: footerTextColor,
        copyrightTextColor: footerTextColor,
        logoImageName: settings["logo_image_name"],
        signupBgColor: settings["signup_bg_color"],
        signupAltBgColor: settings["signup_alt_bg_color"],
        logoWidth: settings["_logo_width"],
        footerHtml: settings["footer_html"],
        headerGif: "", // removes the background gif
        footerGif: "", // removes the background gif
        userInfoBgColor: contentBgColor, // this overrides the default value with the content background color
        usernameColor: navigationFontColor, // this overrides the default value with the generic nav font color
        nextCamBgColor: tabInactiveColor, // this overrides the default value of the next cam background color tab
        footerColNameColor: tabInactiveFontColor,
        footerRowColor: footerHrefColor,
        footerSiteNavColor: footerHrefColor,
        chatAreaTabColor: "#0C6A93", // this color is hard coded into all white labels
        defaultCampaignSlug: settings["default_campaign_slug"],
        isWhiteLabel: true,
        shouldHideWaterMark: true,
        shouldChangeFooterBorder: true,
        shouldChangeFooterTextColor: true,
        shouldAddWhiteLabelPoweredBy: true,
        shouldShowHeaderAd: false,
        showEarnFreeTokens: false,
        shouldShowAltLanguages: false,
        shouldShowFooterAddress: false,
        roomSubjectToolTipColor: "#222222", // this color is hard coded into all white labels
        loginOverlayColor: textColor,
        dismissibleMessageColor: textColor,
        recordHitURL: settings["whitelabel_hit_count_referring_url"],
        userInfoDropdownBgColor: userInfoDropdownBgColor,
        userInfoDropdownHighlightColor: userInfoDropdownHighlightColor,
        userInfoDropdownTextColor: userInfoDropdownTextColor,
        userUpdatesFeedbackLink: defaultSiteSettings.userUpdatesFeedbackLink,
        hideEntranceTerms: defaultSiteSettings.hideEntranceTerms,
        carouselActiveCircleColor: settings["carousel_active_circle_color"],
        carouselArrowBgColor: settings["carousel_arrow_bg_color"],
        carouselArrowColor: settings["carousel_arrow_color"],
        carouselBgColor: settings["carousel_bg_color"],
        carouselBorderColor: settings["carousel_border_color"],
        carouselCircleColor: settings["carousel_circle_color"],
        carouselHrefColor: settings["carousel_href_color"],
        carouselTitleColor: settings["carousel_title_color"],
        filterPanelBgColor: settings["filter_panel_bg_color"],
        filterOptionBorderColor: settings["filter_option_border_color"],
        filterPanelHighlightColor: settings["filter_panel_highlight_color"],
        filterSectionPrimaryTextColor: settings["filter_section_primary_text_color"],
        filterSectionSupportTextColor: settings["filter_section_support_text_color"],
        filterPanelButtonActiveBgColor: settings["filter_panel_button_active_bg_color"],
        filterPanelButtonHoverBgColor: settings["filter_panel_button_hover_bg_color"],
        metaDescription: settings["meta_description"],
        homepageTitle: settings["title"],
    }
}

export let currentSiteSettings = defaultSiteSettings
if (window["siteSettings"] !== undefined) {
    currentSiteSettings = parseSiteSettings(window["siteSettings"])
}
else if (window["siteUrls"] !== undefined) {
    currentSiteSettings.jpegStreamUrl = window["siteUrls"]["jpeg_stream_url"]
    currentSiteSettings.jpegRoomImgUrl = window["siteUrls"]["jpeg_room_img_url"]
    currentSiteSettings.cbGamesUrl = window["siteUrls"]["cb_games_url"]
    currentSiteSettings.canonicalBaseUrl = window["siteUrls"]["canonical_base_url"]
}
if (currentSiteSettings.recordHitURL !== undefined) {
    postCb("whitelabels/record_hit/", { "referer": currentSiteSettings.recordHitURL })
        .catch((xhr) => {
            error("Error recording whitelabel hit", xhr)
        })
}
