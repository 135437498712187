import { i18n } from "../translation"
import { BaseRoomTab } from "./baseRoomTab"
import { BioContent } from "./bioContent"
import { TabName } from "./tabList"

export class BioTab extends BaseRoomTab {
    private bioContent: BioContent

    constructor() {
        super({ tabName: TabName.Bio, tabLabel: i18n.bioText, pageActionName: "BioOpened" })
    }

    protected initUI(): void {
        super.initUI()
        this.bioContent = new BioContent()
        this.addChild(this.bioContent)
    }
}
