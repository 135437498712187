import { addEventListenerMultiPoly, addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { modalAlert, modalConfirm } from "../../../common/alerts"
import { XhrError } from "../../../common/api"
import { isAnonymous, isNotLoggedIn } from "../../../common/auth"
import { IChatContents } from "../../../common/chatRoot"
import { Component } from "../../../common/defui/component"
import { applyStyles } from "../../../common/DOMutils"
import { EventRouter, ListenerGroup } from "../../../common/events"
import { MediaPreviewModal } from "../../../common/mediaPreviewModal"
import { IChatMedia } from "../../../common/messageInterfaces"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { VideoMode, videoModeHandler } from "../../../common/videoModeHandler"
import {
    ChatMediaUploadError, deletePhoto, getLatestPhotos, pmMediaDeleted, pmMediaDrop, pmMediaRejected,
    pmMediaUpload, throttledGetLatestPhotos, uploadChatMedia,
} from "../../api/pmMedia"
import { addColorClass } from "../../colorClasses"
import { WrappedSpinnerIcon } from "../../ui/spinnerIcon"
import { caretLeft, caretRight } from "../../ui/svg/caret"
import { chatMediaThumbnailSize, thumbnailStyle } from "./chatMediaCarousel"

const dockPreviewStyle: CSSX.Properties = {
    ...thumbnailStyle,
    cursor: "default",
}
export const libraryMediaDockItemLimit = 20
export const mobileMediaDockItemLimit = 10

export const showMobileMediaDock = new EventRouter<boolean>("showMobileMediaDock")
export const mobileMediaChanged = new EventRouter<void>("mobileMediaUploadProgress")
export const mobileMediaDockHeight = (): number => 64

abstract class MediaDock extends Component {
    static padding = 5
    protected scrollSize = MediaDockItem.itemWidth

    private scrollContainer: HTMLDivElement
    private scrollLeftBtn: HTMLButtonElement
    private scrollRightBtn: HTMLButtonElement

    protected dock: Component
    public sibling: MediaDock

    private mediaPreviewModal = new MediaPreviewModal()

    protected listeners = new ListenerGroup()

    constructor(public parentContents: IChatContents) {
        super()
        const containerStyle: CSSX.Properties = {
            flexShrink: 0,
            position: "relative", // for scroll buttons
            display: "none",
            overflowX: "hidden",
            whiteSpace: "nowrap",
            bottom: 0,
        }
        const buttonStyle: CSSX.Properties = {
            height: "100%",
            position: "absolute",
            zIndex: 1000,
            opacity: 0.8,
            borderRadius: 0,
            border: "none",
            fill: "currentColor",
            cursor: "pointer",
        }
        const scrollContainerStyle: CSSX.Properties = {
            overflow: "scroll",
            width: "100%",
            position: "relative",
        }
        const dockStyle: CSSX.Properties = {
            minHeight: "77px",
            width: "auto",
            minWidth: "100%",
            cssFloat: "left",
            boxSizing: "border-box",
            padding: `${MediaDock.padding}px`,
            whiteSpace: "nowrap",
            position: "relative",
        }

        this.dock = new Component()
        applyStyles(this.dock, dockStyle)

        this.element = <div className="pmMediaDockContainer" style={containerStyle}>
            <button ref={(elm: HTMLButtonElement) => this.scrollLeftBtn = elm}
                    onClick={() => {this.scrollLeft()}}
                    style={{ ...buttonStyle, left: 0 }}
                    colorClass="scrollButton">{caretLeft()}</button>
            <button ref={(elm: HTMLButtonElement) => this.scrollRightBtn = elm}
                    onClick={() => {this.scrollRight()}}
                    style={{ ...buttonStyle, right: 0 }}
                    colorClass="scrollButton">{caretRight()}</button>
            <div ref={(elm: HTMLDivElement) => this.scrollContainer = elm} onScroll={() => {this.setScrollButtonVisibility()}} style={scrollContainerStyle} className="noScrollbar">
                { this.dock.element }
            </div>
        </div>

        this.setScrollButtonVisibility()

        pmMediaDeleted.listen((mediaId) => {
            this.getImageById(mediaId)?.removeElement()
        }).addTo(this.listeners)
        pmMediaRejected.listen((mediaId) => {
            this.getImageById(mediaId)?.markRejected()
        }).addTo(this.listeners)
    }

    public repositionChildren(): void {
        this.setScrollButtonVisibility()
    }

    private scrollLeft(): void {
        this.scrollContainer.scrollLeft -= this.scrollSize
        this.setScrollButtonVisibility()
    }

    private scrollRight(): void {
        this.scrollContainer.scrollLeft += this.scrollSize
        this.setScrollButtonVisibility()
    }

    public setScrollButtonVisibility(): void {
        const scrollDiv = this.scrollContainer
        this.scrollLeftBtn.style.display = scrollDiv.scrollLeft > 0 ? "block" : "none"
        this.scrollRightBtn.style.display = scrollDiv.scrollLeft + 2 < scrollDiv.scrollWidth - scrollDiv.offsetWidth ? "block" : "none"
    }

    public toggle(): void {
        this.isShown() ? this.hide() : this.show()
        window.setTimeout(() => {
            this.setScrollButtonVisibility()
        }, 0)
    }

    public show(): void {
        if (!this.isShown()) {
            this.showElement()
            this.scrollRightBtn.click()
            this.scrollLeftBtn.click()
            this.parentContents.repositionChildrenRecursive()
        }
    }

    public hide(): void {
        this.hideElement()
        this.onHide()
        this.hideMediaPreview()
        this.parentContents.repositionChildrenRecursive()
    }

    public setPreviewModal(mediaUrl: string): void {
        this.mediaPreviewModal.initAndShow(mediaUrl)
    }

    public hideMediaPreview(): void {
        this.mediaPreviewModal.hide()
    }

    public abstract addItem(item: MediaDockItem): void

    public removeItem(item: MediaDockItem | MobileMediaDockItem): void {
        this.dock.removeChild(item)
    }

    public items(): MediaDockItem[] {
        return this.dock.children() as unknown as MediaDockItem[]
    }

    public getImageById(imageId: number): MediaDockItem | undefined {
        return this.items().find((item) => item.media?.mediaId === imageId)
    }

    public clear(): void {
        while (this.items().length > 0) {
            this.items().forEach(item => { item.remove() })
        }
    }

    public isEmpty(): boolean {
        return this.items().length === 0
    }

    public async mediaList(): Promise<IChatMedia[]> {
        return Promise.all(this.items().map(item => item.mediaPromise))
    }

    public onHide(): void {}
}

abstract class MediaDockItem extends Component {
    static itemWidth = chatMediaThumbnailSize + 2  // +2 due to 1px margins
    public loadingSpinner: HTMLDivElement
    protected imageContainer: HTMLDivElement
    public media: IChatMedia | undefined
    protected removeBtn: HTMLImageElement
    public parentDock: MediaDock

    constructor(public mediaPromise: Promise<IChatMedia>, parentDock: MediaDock) {
        super()
        this.parentDock = parentDock
        this.element = (
            <div style={{ ...dockPreviewStyle, backgroundImage: "" }} colorClass="mediaDockItem">
                <img src={`${STATIC_URL_ROOT}tsdefaultassets/mediaDock/closeButton.svg`}
                     onClick={(evt) => {
                         evt.stopPropagation()
                         this.remove()
                     }}
                     ref={(elm: HTMLImageElement) => this.removeBtn = elm}
                     style={{ position: "absolute", top: 0, right: 0, zIndex: 1, cursor: "pointer" }}/>
                <div style={{ ...dockPreviewStyle, position: "absolute" }} ref={(elm: HTMLDivElement) => this.imageContainer = elm}>
                    <WrappedSpinnerIcon myRef={(div) => { this.loadingSpinner = div }}/>
                </div>
            </div>
        )
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        mediaPromise.then(media => {
            this.onload(media)
        })
        this.loadingSpinner.dataset["testid"] = "chat-image-loading-spinner"
    }

    protected onload(media: IChatMedia): void {
        this.media = media
        const bgImg = new Image()
        bgImg.onload = () => {
            const imgElement = new Image()
            imgElement.src = bgImg.src
            imgElement.style.width = "100%"
            imgElement.style.height = "auto"
            this.imageContainer.appendChild(imgElement)
            this.loadingSpinner.style.display = "none"
        }
        bgImg.src = media.thumbnailUrl
    }

    public removeElement(): void {
        this.parentDock.removeItem(this)
    }

    public abstract remove(): void

    public abstract markRejected(): void
}

export class LibraryMediaDock extends MediaDock {
    private fileInput: HTMLInputElement = <input type="file" accept=".jpg,.jpeg,.png,.gif,.heic,.heif,.webp" multiple/>
    private loadingSpinner = new SpinnerDockItem()
    public sibling: SelectedMediaDock

    constructor(parentContents: IChatContents, private toUser: string, private videoModes: VideoMode[]) {
        super(parentContents)

        this.initSibling()

        const indicator = <div style={{ ...dockPreviewStyle, cursor: "pointer", backgroundImage: "none", backgroundColor: "#2C2C2C" }}>
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%) scale(1.5)" }}>
                <img src={`${STATIC_URL_ROOT}tsdefaultassets/mediaDock/uploadBackground.svg`}/>
                <img src={`${STATIC_URL_ROOT}tsdefaultassets/mediaDock/uploadSymbol.svg`}
                     style={{ position: "absolute", bottom: 0, right: 0, transform: "translate(50%, 25%) scale(0.66)" }}/>
            </div>
        </div>
        indicator.dataset["testid"] = "upload-image-button"
        indicator.onclick = () => { this.showSelectDialog() }
        this.dock.element.appendChild(indicator)
        this.dock.element.dataset["testid"] = "image-dock"

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.fileInput.onchange = () => { this.onFileInputChange() }

        // TODO periodically refetch media so that the cloudfront urls don't expire
        if (!isAnonymous()) {
            this.getLatest()
        }
        videoModeHandler.changeVideoMode.listen((evt) => {
            if (this.videoModes.includes(evt.currentMode) && !isAnonymous()) {
                this.getLatest()
            }
        }).addTo(this.listeners)

        pmMediaDrop.listen(args => {
            const currentVideoMode = videoModeHandler.getVideoMode()
            if (!this.videoModes.includes(currentVideoMode) || this.toUser !== args.toUser) {
                return
            }
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.handleFileUpload(...args.files)
            this.show()
        }).addTo(this.listeners)

        pmMediaUpload.listen(args => {
            if (this.videoModes.includes(videoModeHandler.getVideoMode()) ) {
                this.addItem(new LibraryMediaDockItem(args.promise, this, this.toUser === args.toUser))
            }
        }, false).addTo(this.listeners)
    }

    private initSibling(): void {
        this.sibling = new SelectedMediaDock(this.parentContents)
        this.sibling.sibling = this
    }

    public dispose(): void {
        this.listeners.removeAll()
        this.sibling.dispose()
    }

    private async onFileInputChange(): Promise<void> {
        if (this.fileInput.files !== null) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.handleFileUpload(...this.fileInput.files)
            this.fileInput.value = ""
        }
    }

    private async handleFileUpload(...files: File[]): Promise<void> {
        for (const file of files) {
            await uploadChatMedia(file, this.toUser)
        }
    }

    public showSelectDialog(): void {
        this.fileInput.click()
    }

    public addItem(item: LibraryMediaDockItem, fromLatest = false): void {
        if (!fromLatest && this.items().length > 0) {
            this.dock.addChildBeforeIndex(item, 0)
        } else {
            this.dock.addChild(item)
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        item.mediaPromise.then((media) => {
            const itemSibling = this.sibling.getImageById(media.mediaId)
            // It is possible for a sibling to already exist if you have >= libraryMediaDockItemLimit items loaded ->
            // select the last one -> upload another so the last one is deleted -> delete an item so that the last one
            // comes back. Since it was previously selected, it already has a SelectedMediaDockItem sibling
            if (itemSibling !== undefined) {
                item.setSibling(itemSibling)
            }
        })

        while (this.items().length > libraryMediaDockItemLimit) {
            this.dock.removeChild(this.dock.lastChild() as unknown as Component)
        }
        this.setScrollButtonVisibility()
    }

    public getImageById(imageId: number): LibraryMediaDockItem | undefined {
        return this.items().find((item) => item.media?.mediaId === imageId)
    }

    public getLatest(): void {
        this.dock.removeAllChildren()
        this.dock.addChild(this.loadingSpinner)
        this.setScrollButtonVisibility()
        throttledGetLatestPhotos().then((latest_media) => {
            // Simultaneous getLatest calls will result in duplicate media items. For now fix by reremoving children here
            this.dock.removeAllChildren()
            this.loadingSpinner.element.style.display = "none"
            for (const media of latest_media) {
                this.addItem(new LibraryMediaDockItem(Promise.resolve(media), this), true)
            }
            this.setScrollButtonVisibility()
        }).catch(() => {
            this.loadingSpinner.element.style.display = "none"
            this.setScrollButtonVisibility()
        })
    }

    public items(): LibraryMediaDockItem[] {
        return super.items() as unknown as LibraryMediaDockItem[]
    }

    public show(): void {
        super.show()
        this.sibling.showIfNonempty()
    }

    public hide(): void {
        super.hide()
        this.sibling.hide()
    }
}

export class SelectedMobileMediaDock extends MediaDock {
    protected scrollSize = MobileMediaDockItem.itemWidth
    private loadingSpinner = new SpinnerDockItem()
    private fileInput: HTMLInputElement = <input type="file" accept=".jpg,.jpeg,.png,.gif,.heic,.heif,.webp" multiple/>
    private room = ""

    constructor(public parentContents: IChatContents) {
        super(parentContents)
        this.element.style.boxSizing = "border-box"
        this.element.style.borderTopLeftRadius = "4px"
        this.element.style.borderTopRightRadius = "4px"
        this.element.style.borderBottom = "none"
        this.element.dataset["testid"] = "mobile-image-dock"
        addColorClass(this.element, "SelectedMediaDock")

        this.dock.element.style.borderTopColor = "#EFEFEF"
        this.dock.element.style.borderTopWidth = "1px"
        this.dock.element.style.borderTopStyle = "solid"

        this.dock.removeAllChildren()
        this.hideLoadingSpinner()

        this.fileInput.onchange = () => { this.onFileInputChange() }
        // prevent changing tabs when swiping on media dock
        this.element.ontouchmove = (e) => { e.stopPropagation() }
    }

    public repositionChildren(): void {
        this.setScrollButtonVisibility()
    }

    public show(): void {
        if (!this.isShown()) {
            window.setTimeout(() => {
                this.setScrollButtonVisibility()
            }, 0)
            this.showElement()
            this.parentContents.repositionChildrenRecursive()
        }
    }

    public hide(): void {
        super.hide()
        showMobileMediaDock.fire(false)
    }

    public showLoadingSpinner(): void {
        this.dock.removeAllChildren()
        this.dock.addChild(this.loadingSpinner)
        this.loadingSpinner.showElement()
    }

    public hideLoadingSpinner(): void {
        this.loadingSpinner.parent?.removeChild(this.loadingSpinner)
        this.loadingSpinner.hideElement()
    }

    public setRoom(room: string): void {
        this.room = room
    }

    public getRoom(): string {
        return this.room
    }

    private onFileInputChange(): void {
        if (this.fileInput.files !== null) {
            this.processFiles(...this.fileInput.files)
            this.fileInput.value = ""
        }
    }

    private processFiles(...files: File[]): void {
        for (const file of files) {
            if (this.mobileItems().length >= mobileMediaDockItemLimit) {
                modalAlert(i18n.errorUploadMediaLimit(mobileMediaDockItemLimit))
                break
            } else {
                this.addMediaItem(new MobileMediaDockItem(file, this))
            }
        }
        Promise.all(this.mobileItems().map(item => item.uploadPromise)).then(result=> {
            if (result.some(r=> r === false)) {
                modalAlert(i18n.errorUploadingMedia)
            }
        })
    }

    public showSelectDialog(): void {
        this.fileInput.click()
    }

    public addItem(item: MediaDockItem): void {
        return
    }

    public addMediaItem(item: MobileMediaDockItem): void {
        if (this.mobileItems().length > 0) {
            this.dock.addChildBeforeIndex(item, 0)
        } else {
            this.show()
            this.dock.addChild(item)
            showMobileMediaDock.fire(true)
        }

        while (this.mobileItems().length > mobileMediaDockItemLimit) {
            this.dock.removeChild(this.dock.lastChild() as unknown as Component)
        }
        mobileMediaChanged.fire()
    }

    public removeItem(item: MobileMediaDockItem): void {
        this.dock.removeChild(item)
        if(this.isEmpty()) {
            this.hide()
        }
        mobileMediaChanged.fire()
    }

    public mobileItems(): MobileMediaDockItem[] {
        return this.dock.children().filter((c) => {
           return c !== this.loadingSpinner
        }) as unknown as MobileMediaDockItem[]
    }

    public clear(): void {
        while (this.mobileItems().length > 0) {
            this.mobileItems().forEach(item => { item.removeElement() })
        }
    }

    public isEmpty(): boolean {
        return this.mobileItems().length === 0
    }

    public mobileMediaList(): (IChatMedia)[] {
        return this.mobileItems().map(item => item.media as IChatMedia)
    }

    public isUploading(): boolean {
        return this.mobileItems().some(item => item.media === undefined)
    }

    public onHide(): void {
        this.clear()
    }

    public dispose(): void {
        this.listeners.removeAll()
    }
}

class SpinnerDockItem extends Component {
    constructor() {
        super()
        this.element = <div style={{ ...dockPreviewStyle, backgroundImage: "" }}>
            <div style={{ ...dockPreviewStyle, position: "absolute" }}>
                <WrappedSpinnerIcon/>
            </div>
        </div>
    }
}

class MobileMediaDockItem extends Component {
    static itemWidth = chatMediaThumbnailSize + 2  // +2 due to 1px margins
    public parentDock: SelectedMobileMediaDock
    public loadingSpinner: HTMLDivElement
    protected imageContainer: HTMLDivElement
    public file: File
    public media: IChatMedia | undefined;
    public uploadPromise: Promise<boolean | undefined>

    constructor(file: File, parentDock: SelectedMobileMediaDock) {
        super()
        this.parentDock = parentDock
        this.file = file
        this.element = (
            <div style={{ ...dockPreviewStyle, backgroundImage: "" }} colorClass="mediaDockItem">
                <img src={`${STATIC_URL_ROOT}tsdefaultassets/mediaDock/closeButton.svg`}
                     onClick={(evt) => {
                         evt.stopPropagation()
                         this.remove()
                     }}
                     style={{ position: "absolute", top: 0, right: 0, zIndex: 1, cursor: "pointer",width: "15px", height: "15px" }}/>

                <div style={{ ...dockPreviewStyle, position: "absolute" }} ref={(elm: HTMLDivElement) => this.imageContainer = elm}>
                    <WrappedSpinnerIcon myRef={(div) => { this.loadingSpinner = div }}
                                        extraStyle={{ background: "rgba(0,0,0,0.1)" }}/>
                </div>
            </div>
        )

        applyStyles(this.imageContainer, {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        })
        const imgElement = this.createThumbnail()
        this.imageContainer.appendChild(imgElement)
        this.uploadPromise = this.uploadMediaFile(file, imgElement)
    }

    private createThumbnail(): HTMLImageElement {
        const imgElement = new Image()
        imgElement.style.width = "auto"
        imgElement.style.width = "-moz-available"
        imgElement.style.width = "-webkit-fill-available"
        imgElement.style.width = "fill-available"
        imgElement.style.height = "auto"
        imgElement.style.height = "-moz-available"
        imgElement.style.height = "-webkit-fill-available"
        imgElement.style.height = "fill-available"
        imgElement.onclick = () => {
            if (this.media !== undefined) {
                this.parentDock.setPreviewModal(this.media.url)
            }
        }
        return imgElement
    }

    private uploadMediaFile(file: File, imgElement: HTMLImageElement): Promise<boolean | undefined> {
        return uploadChatMedia(file, this.parentDock.getRoom()).then((media) => {
            imgElement.src = media.thumbnailUrl
            this.media = media
            this.hideLoadingSpinner()
            return true
        }).catch((err: XhrError | ChatMediaUploadError) => {
            this.parentDock.removeItem(this)
            if (err instanceof XhrError) {
                if (err.xhr.status === 400) {
                    const errors = JSON.parse(err.xhr.responseText)["errors"]
                    if (errors !== undefined) {
                        if (errors["media"] === ChatMediaUploadError.InvalidFile) {
                            modalAlert(i18n.invalidFileErrorUploadingMedia)
                        } else if (errors["media"] === ChatMediaUploadError.LegalBlock) {
                            modalAlert(i18n.legalBlockErrorUploadingMedia)
                        }
                        return
                    }
                }
                return false
            } else if (err === ChatMediaUploadError.FileTooLarge) {
                modalAlert(i18n.fileSizeErrorUploadingMedia)
            }
        }).finally(() => {
            mobileMediaChanged.fire()
        })
    }

    public remove(): void {
        if (this.media === undefined || this.isSpinnerVisible()) {
            return
        }
        this.showLoadingSpinner()
        const imgElement = this.imageContainer.querySelector("img")
        this.imageContainer.removeChild((imgElement as HTMLImageElement))
        deletePhoto(this.media.mediaId).then(() => {
            // pmMediaDeleted listener has removed the item so don't need to remove here
        }).catch(() => {
            this.hideLoadingSpinner()
            this.imageContainer.appendChild((imgElement as HTMLImageElement))
            modalAlert(i18n.errorDeletingMedia)
        })
    }


    public removeElement(): void {
        this.parentDock.removeItem(this)
    }

    public showLoadingSpinner(): void {
        this.loadingSpinner.style.display = "block"
    }

    public hideLoadingSpinner(): void {
        this.loadingSpinner.style.display = "none"
    }

    private isSpinnerVisible(): boolean {
        return this.loadingSpinner.style.display !== "none"
    }

}

class LibraryMediaDockItem extends MediaDockItem {
    public selected = false
    public sibling?: SelectedMediaDockItem
    private selectedIcon: HTMLImageElement
    public parentDock: LibraryMediaDock
    private rejected = false

    constructor(mediaPromise: Promise<IChatMedia>, parentDock: LibraryMediaDock, private autoselect = false) {
        super(mediaPromise, parentDock)
        mediaPromise.catch((err: XhrError | ChatMediaUploadError) => {
            this.removeElement()
            if (err instanceof XhrError) {
                if (err.xhr.status === 400) {
                    const errors = JSON.parse(err.xhr.responseText)["errors"]
                    if (errors !== undefined) {
                        if (errors["media"] === ChatMediaUploadError.InvalidFile) {
                            modalAlert(i18n.invalidFileErrorUploadingMedia)
                        } else if (errors["media"] === ChatMediaUploadError.LegalBlock) {
                            modalAlert(i18n.legalBlockErrorUploadingMedia)
                        }
                        return
                    }
                }
                modalAlert(i18n.errorUploadingMedia)
            } else if (err === ChatMediaUploadError.FileTooLarge) {
                modalAlert(i18n.fileSizeErrorUploadingMedia)
            }
        })
        this.selectedIcon = <img ref={(elm: HTMLImageElement) => this.selectedIcon = elm}
                                 src={`${STATIC_URL_ROOT}tsdefaultassets/mediaDock/selected.svg`}
                                 style={{ position: "absolute", bottom: 0, right: 0, zIndex: 1, display: "none" }} />
        this.element.appendChild(this.selectedIcon)
        this.removeBtn.style.display = "none"
    }

    protected onload(media: IChatMedia): void {
        super.onload(media)
        this.removeBtn.style.display = "block"
        if (this.autoselect) {
            window.setTimeout(() => { this.select() }, 0)
        }
        this.element.onclick = () => {
            this.toggleSelect()
        }
    }

    public toggleSelect(): void {
        this.selected ? this.deselect() : this.select()
    }

    public select(): void {
        if (!this.selected) {
            this.selected = true
            this.removeBtn.style.display = "none"
            this.selectedIcon.style.display = "block"
            this.createSibling()
            this.parentDock.sibling.addItem(this.sibling as SelectedMediaDockItem)
            this.parentDock.sibling.show()
            this.parentDock.sibling.setScrollButtonVisibility()
        }
    }

    private createSibling(): void {
        if (this.sibling === undefined) {
            this.sibling = new SelectedMediaDockItem(this.mediaPromise, this.parentDock.sibling)
            this.sibling.sibling = this
        }
    }

    public setSibling(sibling: SelectedMediaDockItem): void {
        if (this.sibling !== undefined) {
            error("LibraryMediaDockItem sibling exists")
            return
        }
        this.selected = true
        this.removeBtn.style.display = "none"
        this.selectedIcon.style.display = "block"
        this.sibling = sibling
        this.sibling.sibling = this
    }

    public deselect(fromSibling = false): void {
        this.selected = false
        this.removeBtn.style.display = "block"
        this.selectedIcon.style.display = "none"
        if (!fromSibling) {
            this.sibling?.remove(true)
        }
    }

    // unreachable if media is currently selected
    public remove(): void {
        if (this.rejected) {
            this.removeElement()
            return
        }

        modalConfirm(i18n.confirmPhotoDelete, () => {
            if (this.media === undefined) {
                error("Attempting to delete media before it is loaded")
                return
            }
            this.loadingSpinner.style.display = "block"
            const imgElement = this.imageContainer.querySelector("img")
            this.imageContainer.removeChild((imgElement as HTMLImageElement))
            deletePhoto(this.media.mediaId).then(() => {
                // pmMediaDeleted listener has removed the item so don't need to remove here
            }).catch(() => {
                this.loadingSpinner.style.display = "none"
                this.imageContainer.appendChild((imgElement as HTMLImageElement))
                modalAlert(i18n.errorDeletingMedia)
            })
        })
    }

    public markRejected(): void {
        this.element.removeChild(this.imageContainer)
        this.element.onclick = null  // eslint-disable-line @multimediallc/no-null-usage

        const rejectedStyle: CSSX.Properties = {
            whiteSpace: "break-spaces",
            fontSize: "11px",
            width: "100%",
            height: "100%",
            padding: "2px",
        }
        const rejectedNotice = (
            <div style={rejectedStyle} colorClass="rejected">
                { i18n.removedForViolation }
            </div>
        )
        this.element.appendChild(rejectedNotice)
        this.rejected = true
    }

    public removeElement(): void {
        super.removeElement()
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getLatestPhotos(1, libraryMediaDockItemLimit - 1).then(latest_media => {
            if (latest_media.length > 0) {
                const promise = Promise.resolve(latest_media[0])
                const mediaItem = new LibraryMediaDockItem(promise, this.parentDock)
                this.parentDock.addItem(mediaItem, true)
            }
            this.parentDock?.setScrollButtonVisibility()
        })
        this.loadingSpinner.style.display = "none"
        this.parentDock?.setScrollButtonVisibility()
    }
}

export class SelectedMediaDock extends MediaDock {
    public sibling: LibraryMediaDock

    constructor(parentContents: IChatContents) {
        super(parentContents)
        this.element.style.boxSizing = "border-box"
        this.element.style.borderTopLeftRadius = "4px"
        this.element.style.borderTopRightRadius = "4px"
        this.element.style.borderBottom = "none"
        this.element.dataset["testid"] = "staged-image-dock"
        addColorClass(this.element, "SelectedMediaDock")
    }

    public addItem(item: SelectedMediaDockItem): void {
        this.dock.addChild(item)
    }

    public getImageById(imageId: number): SelectedMediaDockItem | undefined {
        return super.getImageById(imageId) as SelectedMediaDockItem
    }

    public showIfNonempty(): void {
        if (!this.isEmpty()) {
            this.show()
        }
    }

    public hideIfEmpty(): void {
        if (this.isEmpty()) {
            this.hide()
        }
    }

    public onHide(): void {
        this.clear()
    }

    public dispose(): void {
        this.listeners.removeAll()
    }
}

class SelectedMediaDockItem extends MediaDockItem {
    public sibling?: LibraryMediaDockItem
    public parentDock: SelectedMediaDock

    constructor(mediaPromise: Promise<IChatMedia>, parentDock: SelectedMediaDock) {
        super(mediaPromise, parentDock)
        this.element.onclick = () => {
            if (this.media !== undefined) {
                this.parentDock.setPreviewModal(this.media.url)
            }
        }
    }

    public remove(fromSibling = false): void {
        this.removeElement()
        this.parentDock.setScrollButtonVisibility()
        this.parentDock.hideIfEmpty()

        if (!fromSibling) {
            this.sibling?.deselect(true)
        }
    }

    public markRejected(): void {
        this.remove()
    }
}

export function initMediaDragDrop(dropArea: HTMLElement, toUser: string): void {
    const style: CSSX.Properties = {
        position: "absolute",
        top: "0",
        left: "0",
        boxSizing: "border-box",
        height: "100%",
        width: "100%",
        background: "rgba(0,0,0,0.5)",
        padding: "10px",
        display: "none",
        zIndex: 1000,
    }
    const indicator = <div style={style}>
        <div style={{ border: "3px dashed white", padding: "10px", height: "calc(100% - 26px)" }}></div>
    </div>

    dropArea.appendChild(indicator)
    addEventListenerMultiPoly(["dragenter", "dragover", "dragleave", "drop"], dropArea, (evt) => {
        evt.preventDefault()
        evt.stopPropagation()
    })
    addEventListenerMultiPoly(["dragenter", "dragover"], dropArea, (evt: DragEvent) => {
        // Show the drag area indicator only if a file is dragged from the file selector.
        // Should be hidden for any other text/links.
        if (evt.dataTransfer && evt.dataTransfer.types.includes("Files")) {
            indicator.style.display = "block"
        }
    })
    addEventListenerMultiPoly(["dragleave", "drop"], indicator, (evt) => {
        indicator.style.display = "none"
    })
    addEventListenerPoly("drop", indicator, (evt: DragEvent) => {
        if (!isNotLoggedIn()) {
            const files: File[] = Array.from(evt.dataTransfer?.files ?? [])
            pmMediaDrop.fire({ toUser: toUser, files: files })
        }
    })
}
