import { hasWellSupportedEmojis, isIE } from "@multimediallc/web-utils/modernizr"
import { addColorClass } from "../../cb/colorClasses"
import { isRoomRoomlistSpaEligiblePage, spaNavigation } from "../../cb/components/roomlist/spaHelpers"
import { MentionUserList } from "../../cb/components/userMentions/MentionUserList"
import { pageContext } from "../../cb/interfaces/context"
import { normalizeResource } from "../api"
import { setColors } from "../chatcolors/darkModeColors"
import { parseColorToRGB } from "../colorUtils"
import { roomLoaded } from "../context"
import { exitFullscreen, isFullscreen } from "../fullscreen"
import { Luminance } from "../luminance"
import { PartType } from "../messageInterfaces"
import { setTimestamp } from "../messageToDOM"
import { addPageAction } from "../newrelic"
import { enterSpyShowAlertChain } from "../privateShow"
import { renderMessage, renderShortcodeMessage, renderShortcodePart } from "../renderMessage"
import { RoomStatus } from "../roomStatus"
import { ShortcodeParser } from "../specialoutgoingmessages"
import { i18n } from "../translation"
import { UsernameLabel } from "../usernameLabel"
import { setPureChatColorData } from "./pureChatUtil"
import { openTipCalloutRequest } from "./userActionEvents"
import type { IShortcode } from "../../cb/interfaces/shortcode"
import type { IChatConnection } from "../context"
import type {
    IRoomMessage,
    IRoomNoticePart,
    IShortcodeMessage,
    IUserInfo,
} from "../messageInterfaces"

export const theaterModeMessagePaddingPx = 2

let gender: string
let chatConnection: IChatConnection

// eslint-disable-next-line @multimediallc/no-global-listener
roomLoaded.listen((context) => {
    gender = context.dossier.roomGender
    chatConnection = context.chatConnection
})

function brighten(color: string): string {
    const parsedRGB = parseColorToRGB(color)
    const messageLum = new Luminance(parsedRGB.red, parsedRGB.green, parsedRGB.blue)
    const oldBrightness = messageLum.brightness()
    messageLum.raiseToMin(255 - (oldBrightness / 3))
    return `rgb(${messageLum.red}, ${messageLum.green}, ${messageLum.blue})`
}

interface IEmoticonBounds {
    height: number
    width: number
}

export interface IRatioScale {
    emoticonBounds: IEmoticonBounds
    maxHeight: number
    maxWidth: number
}

export function newPossibleImageBounds(ratioScale: IRatioScale): IEmoticonBounds {
    const ratio = ratioScale.emoticonBounds.width / ratioScale.emoticonBounds.height
    let height = ratioScale.emoticonBounds.height
    let width = ratioScale.emoticonBounds.width

    if (width > ratioScale.maxWidth) {
        width = ratioScale.maxWidth
        height = Math.round(width / ratio)
    }
    if (height > ratioScale.maxHeight) {
        height = ratioScale.maxHeight
        width = Math.round(height * ratio)
    }

    return { height, width }
}

function createUsernameLabel(fromUser: IUserInfo, message?: IRoomMessage): UsernameLabel {
    return new UsernameLabel(fromUser, message)
}

export function createRoomMessage(m: IRoomMessage): HTMLDivElement {
    const div = createBaseMessageDiv(m.fromUser.username)
    div.style.fontSize = ""

    const content = document.createElement("div")
    content.style.borderRadius = "4px"
    content.style.padding = `0 ${theaterModeMessagePaddingPx}px`
    div.appendChild(content)

    const username = createUsernameLabel(m.fromUser, m)
    username.element.style.paddingRight = "4px"
    content.appendChild(username.element)
    const span = renderMessage(m.message)
    span.style.color = (m.textColor !== undefined) ? m.textColor : "#FFFFFF"
    if (m.font !== undefined) {
        username.element.style.fontFamily = m.font
        span.style.fontFamily = m.font
    }
    span.classList.add("msg-text")
    span.dataset["testid"] = "chat-message-text"
    username.element.dataset["paction"] = "TheaterChat"
    username.element.dataset["pactionName"] = "Username"
    username.element.dataset["testid"] = "chat-message-username"
    content.appendChild(span)
    if (isIE()) {
        // Without this IE11 de-parses the emojis, don't know why that happens
        window.setTimeout(() => { twemoji.parse(content, { className: "emojiChat" }) }, 0)
    } else {
        if (!hasWellSupportedEmojis()) {
            twemoji.parse(content, { className: "emojiChat" })
        }
    }
    setColors(content, m.textColor, m.backgroundColor)
    styleUsernameMention(div)
    setTimestamp(m, div)
    return div
}

export function styleUsernameMention(element: HTMLDivElement): void {
    const mentions = element.querySelectorAll<HTMLSpanElement>(".username-mention")
    const invalidMentions = []
    const mentionUserList = MentionUserList.getInstance()
    const userList = mentionUserList.users()
    for (const mentionSpan of mentions) {
        const username = mentionSpan.innerText.replace("@", "").toLowerCase()
        const user = userList.find((u) => u.username === username)
        if (user !== undefined) {
            const userlabel = createUsernameLabel(user)
            const userLabelEl = userlabel.createMentionLabel(pageContext.current.loggedInUser?.username === user.username)
            mentionSpan.parentElement?.replaceChild(userLabelEl, mentionSpan)
        } else {
            invalidMentions.push(username)
        }
    }
    mentionUserList.addInvalidUsers(invalidMentions)
}

export function combineNoticeParts(container: HTMLDivElement, messages: IRoomNoticePart[][], shortcodes?: IShortcode[]): HTMLDivElement {
    for (const message of messages) {
        const msgDiv = document.createElement("div")
        for (const part of message) {
            switch (part.partType) {
                case PartType.message:
                    msgDiv.appendChild(createRoomNoticeMessagePart(part, shortcodes))
                    break
                case PartType.user:
                    msgDiv.appendChild(createUsernameLabel(part.user as IUserInfo, undefined).element)
                    break
                case PartType.hashtag:
                    msgDiv.appendChild(createHashtagAnchor(part.message as string))
                    break
                case PartType.spyPrivateShow:
                    msgDiv.appendChild(createLink(i18n.spyPrivateShowLower, () => {
                        if (chatConnection.status !== RoomStatus.PrivateSpying) {
                            enterSpyShowAlertChain(chatConnection)
                        }
                    }))
                    break
                default:
                    error(`Unknown roomNotice type: ${part.partType}`, part)
            }
        }
        container.appendChild(msgDiv)
    }
    return container
}

export function createShortcodeMessage(message: IShortcodeMessage): HTMLDivElement {
    // Form base message div to to hold our our shortcode msg content
    const div = createBaseMessageDiv(message.fromUser.username)
    div.style.fontSize = ""

    const content = document.createElement("div")
    content.style.borderRadius = "4px"
    content.style.padding = `0 ${theaterModeMessagePaddingPx}px`
    div.appendChild(content)

    const username = createUsernameLabel(message.fromUser, message)
    username.element.style.paddingRight = "4px"
    content.appendChild(username.element)

    const containerDiv = document.createElement("div")
    containerDiv.classList.add("msg-text")
    containerDiv.style.display = "inline-block"
    const span = renderShortcodeMessage(message, createShortcodeLink, openTipCalloutRequest)
    if (message.font !== undefined) {
        username.element.style.fontFamily = message.font
        span.style.fontFamily = message.font
    }
    containerDiv.appendChild(span)

    const wrapperDiv = document.createElement("div")
    wrapperDiv.style.display = "inline-block"
    wrapperDiv.appendChild(containerDiv)
    content.appendChild(wrapperDiv)

    setColors(content, message.textColor, message.backgroundColor)
    styleUsernameMention(div)
    setTimestamp(message, div)
    return div
}

function createShortcodeLink(linkText: string, linkUrl: string|undefined, callback: () => void): HTMLAnchorElement {
    const link = createLink(linkText, callback)
    if (linkUrl !== undefined && linkUrl !== "") {
        link.href = normalizeResource(linkUrl)
        link.target = "_blank"
    }
    return link
}

function createLink(text: string, onClick: () => void): HTMLAnchorElement {
    const anchor = document.createElement("a")
    addColorClass(anchor, "msg-link")
    anchor.style.textDecoration = "underline"
    anchor.innerText = text
    anchor.onclick = onClick
    anchor.style.cursor = "pointer"
    return anchor
}

function createHashtagAnchor(hashtag: string): HTMLAnchorElement {
    const anchor = document.createElement("a")
    anchor.href = normalizeResource(`/tag/${hashtag}/${gender ?? ""}`)
    addColorClass(anchor, "msg-link")
    anchor.style.textDecoration = "none"
    anchor.innerText = `#${hashtag}`

    if (isRoomRoomlistSpaEligiblePage()) {
        anchor.onclick = (ev: MouseEvent) => {
            if (isFullscreen() ) {
                exitFullscreen()
            }
            spaNavigation.fire(ev)
        }
    }

    return anchor
}

export function createLogMessage(msg: string): HTMLDivElement {
    const d = createBaseMessageDiv()
    d.innerText = msg
    setPureChatColorData(d, brighten("#aaaaaa"))
    return d
}

export function createSupporterSignupMessage(broadcaster: string, isAgeVerified: boolean): HTMLDivElement {
    function createTextSpan(text: string): HTMLSpanElement {
        const span = document.createElement("span")
        span.innerText = text
        return span
    }

    const div = createBaseMessageDiv()
    setPureChatColorData(div, brighten("#aaaaaa"))

    const pmSupportNotice = isAgeVerified ? i18n.pmSupporterNoticeAgeVerified : i18n.pmSupporterNotice
    div.appendChild(createTextSpan(pmSupportNotice))
    div.appendChild(document.createElement("br"))
    div.appendChild(createTextSpan(`${i18n.pmSupporterSignUp} `))  // Add space at the end to split from the next text.
    const link = createLink(i18n.pmSupporterMembership, () => {})
    link.href = normalizeResource(`/supporter/upgrade/?source=${pageContext.current.PurchaseEventSources["SUPPORTER_SOURCE_PM_UPSELL"]}`)
    link.target = "_blank"
    link.dataset["testid"] = "supporter-upsell-link"
    link.onclick = () => {
        addPageAction("SupporterPageOpened", { "source": "chat" })
    }
    div.appendChild(link)
    div.appendChild(createTextSpan(` ${i18n.pmSupporterSendPm(broadcaster)}.`))  // Add space at the beginning.
    return div
}

export function createBaseMessageDiv(dataNick = ""): HTMLDivElement {
    const div = document.createElement("div")
    div.dataset["testid"] = "chat-message"

    div.style.fontFamily = "Tahoma,Arial,Helvetica,sans-serif"
    div.style.boxSizing = "border-box"
    div.style.paddingTop = "3px"
    div.style.paddingBottom = "3px"
    div.style.paddingLeft = "5px"
    div.style.paddingRight = "5px"
    div.style.overflowWrap = "break-word"
    div.style.wordWrap = "break-word"
    if (dataNick.length > 0) {
        div.setAttribute("data-nick", dataNick) // eslint-disable-line @multimediallc/no-set-attribute
    }
    return div
}

function createRoomNoticeMessagePart(part: IRoomNoticePart, shortcodes?: IShortcode[]): HTMLSpanElement {
    let span = document.createElement("span")
    if (
        part.message !== undefined &&
        shortcodes &&
        ShortcodeParser.isShortcodeSyntax(part.message)
    ) {
        span = renderShortcodePart(
            part,
            shortcodes,
            createShortcodeLink,
            openTipCalloutRequest,
        )
    }
    else if (part.parseEmoticon !== false) {
        span = renderMessage(part.message as string)
    } else {
        span.innerText = part.message as string
    }
    span.style.color = part.foreground !== undefined ? part.foreground : ""
    span.style.background = part.background !== undefined ? part.background : ""
    span.style.fontWeight = part.weight !== undefined ? part.weight : ""
    return span
}

export function createPMChatLinkMessage(msg: string, pmChatLink: HTMLElement): HTMLDivElement {
    const d = createBaseMessageDiv(msg)
    d.innerText = msg
    d.appendChild(pmChatLink)
    setPureChatColorData(d, brighten("#aaaaaa"))
    d.classList.add("roomNotice")
    return d
}
