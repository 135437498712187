import { addColorClass } from "../cb/colorClasses"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { applyStyles } from "./DOMutils"
import { ModalComponent } from "./modalComponent"

export class MediaPreviewModal extends ModalComponent {
    protected readonly previewImage: HTMLImageElement

    constructor() {
        super()

        addColorClass(this.element, "mediaPreviewModal")
        applyStyles(this.element, {
            position: "fixed",
            width: "auto",
            height: "auto",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "6px",
            padding: "10px",
            fontSize: "12px",
            fontFamily: "UbuntuRegular, Helvetica, Arial, sans-serif",
            zIndex: 1005, // Similar to dm window
            textAlign: "center",
            boxShadow: "0 0 18px rgba(0, 0, 0, 0.4)",
        })

        applyStyles(this.overlay, {
            backgroundColor: "#000000",
            opacity: "0.4",
            zIndex: 1005, // Similar to dm window
        })

        this.element.dataset.testid = "emoticon-modal"

        const closeButton = document.createElement("div")
        addColorClass(closeButton, "closeButton")
        applyStyles(closeButton, {
            display: "inline-block",
            position: "absolute",
            width: "10px",
            height: "10px",
            top: "9px",
            right: "9px",
            cursor: "pointer",
            opacity: "1",
        })

        closeButton.onclick = () => {
            this.hide()
        }
        closeButton.onmouseenter = () => {
            closeButton.style.opacity = "1"
        }
        closeButton.onmouseleave = () => {
            closeButton.style.opacity = "0.5"
        }
        closeButton.dataset.testid = "close-button"
        this.element.appendChild(closeButton)

        this.previewImage = document.createElement("img")
        applyStyles(this.previewImage, {
            verticalAlign: "middle",
            padding: "10px",
            maxHeight: "60vh",
            maxWidth: "80vw",
        })

        addEventListenerPoly("load", this.previewImage, () => {
            this.previewImage.style.visibility = "visible"
            this.repositionChildren()
        })
        this.previewImage.dataset.testid = "preview-image"
        this.element.appendChild(this.previewImage)

        this.overlayClick.listen(() => {
            this.hide()
        })
    }

    public initAndShow(mediaUrl: string): void {
        this.previewImage.style.visibility = "hidden"
        this.previewImage.src = mediaUrl
        super.show()
        this.removeDocumentFocus()
        this.repositionChildren()
    }

    public hide(): void {
        super.hide()
    }

    public show(): void {
        super.show()
        this.element.style.visibility = ""
        this.repositionChildren()
    }

    private removeDocumentFocus(): void {
        if (document.activeElement !== null) {
            (<HTMLElement>document.activeElement).blur()
        }
    }
}
