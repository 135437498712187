import { deepCompare } from "../cb/pushservicelib/deepCompare"
import type { EventRouter } from "./events"

export interface IStore<T> {
    readonly current: T
}

interface IMutableStore<T> {
    onUpdate: EventRouter<T>
}

export class SetOnceStore<T> implements IStore<T> {
    protected value: T
    protected isSet = false

    public get current(): T {
        return this.value
    }

    public set current(value: T) {
        // Only set if our value is unset. Otherwise, no-op.
        if (!this.isSet) {
            this.value = value
            this.isSet = true
        }
    }
}

export abstract class MutableStore<T> implements IMutableStore<T> {
    protected state: T
    public onUpdate: EventRouter<T>

    constructor(defaultState: T) {
        this.state = defaultState
    }

    public getState(): T {
        return {
            ...this.state,
        }
    }

    public setState(nextState: Partial<T>): void {
        if (!this.hasChanges(nextState)) {
            return
        }
        const prevState = this.state
        this.state = {
            ...this.state,
            ...nextState,
        }
        this.onUpdate.fire(prevState)
    }

    private hasChanges(nextState: Partial<T>): boolean {
        for (const key in nextState) {
            if (!deepCompare(nextState[key], this.state[key])) {
                return true
            }
        }
        return false
    }
}
