import { ArgJSONMap } from "@multimediallc/web-utils"
import { isiOS, isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { modalAlert } from "../../../common/alerts"
import { getCb } from "../../../common/api"
import { roomLoaded } from "../../../common/context"
import { Component } from "../../../common/defui/component"
import { DivotPosition } from "../../../common/divot"
import { applyStyles, stopScrollMomentum } from "../../../common/DOMutils"
import { ListenerGroup } from "../../../common/events"
import { isScrollDownNoticeActive } from "../../../common/featureFlagUtil"
import { isFollowAllowed } from "../../../common/follow"
import { MobileDmTippingUI } from "../../../common/mobilelib/mobileDmTippingUI"
import { MobileDmWindowInput } from "../../../common/mobilelib/mobileDmWindowInput"
import { toggleDms } from "../../../common/mobilelib/userActionEvents"
import { DmUserPanel } from "../../../common/mobilelib/userPanel"
import { getViewportHeight } from "../../../common/mobilelib/viewportDimension"
import { addPageAction } from "../../../common/newrelic"
import { ignoreCatch } from "../../../common/promiseUtils"
import { getUsernameColorClass, RoomType } from "../../../common/roomUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { addIgnoreUser, removeIgnoreUser } from "../../api/ignore"
import { getDmHistoryMessages, getUserInfo, pmHistoryBatchSize } from "../../api/pm"
import { TipType } from "../../api/tipping"
import { addColorClass, removeColorClass } from "../../colorClasses"
import { pageContext } from "../../interfaces/context"
import { UserMessageTopic } from "../../pushservicelib/topics/user"
import { currentSiteSettings } from "../../siteSettings"
import { NewMessageLine } from "../../ui/newMessageLine"
import { ScrollDownButton } from "../../ui/scrollDownButton"
import { SpinnerIcon } from "../../ui/spinnerIcon"
import { DmTippingUI } from "../dmTippingUI"
import { RoomFollowStar } from "../followStar"
import { ConversationListData } from "./conversationListData"
import { DmDuplicateChecker } from "./dmDuplicateChecker"
import { createDmMessageBubble, DmMessageItem } from "./dmMessageItem"
import { DmPopout } from "./dmPopout"
import { DmUserContextMenu } from "./dmUserContextMenu"
import { DmWindowInput } from "./dmWindowInput"
import { dmsHeightChanged, removeDmWindowRequest, updateWindowIsOpenEvent } from "./dmWindowsManager"
import { ToolTip } from "./toolTip"
import { allDmsRead } from "./userActionEvents"
import { UsernameLabel } from "./usernameLabel"
import type { IDmWindow } from "./dmWindowsManager"
import type { XhrError } from "../../../common/api"
import type { IRoomContext } from "../../../common/context"
import type { IPrivateMessage, IPushTipAlert, IUserInfo } from "../../../common/messageInterfaces"
import type { TsxChild } from "../../../common/tsxrender/dom"
import type { IPMError, IUserInfoAndUnread } from "../../api/pm"

export const maxDmInput = 4096

const ButtonDiv = (props: {
    parentDiv?: HTMLDivElement,
    style: CSSX.Properties,
    clickHandler: (e: MouseEvent) => void,
    children?: TsxChild[],
    testID?: string,
}): HTMLDivElement => {
    return <div
        style={{ position: "absolute", height: "25px", width: "25px", borderRadius: "5px", top: "5px", ...props.style }}
        onMouseEnter={() => props.parentDiv && addColorClass(props.parentDiv, "noHighlight")}
        onMouseLeave={() => props.parentDiv && removeColorClass(props.parentDiv, "noHighlight")}
        colorClass="button"
        onClick={props.clickHandler}
        data-testid={props.testID}
    >
        {props.children}
    </div>
}

export const CloseButton = (props: { parentDiv?: HTMLDivElement, style: CSSX.Properties, clickHandler: (ev?: MouseEvent) => void }): HTMLDivElement => {
    const imageStyle: CSSX.Properties = {
        position: "relative",
        height: "13px",
        width: "13px",
        left: "6px",
        top: "7px",
    }

    const onClick = (e: MouseEvent) => {
        e.stopPropagation()
        props.clickHandler()
    }

    return <ButtonDiv parentDiv={props.parentDiv} style={props.style} clickHandler={onClick} testID="close">
        <img src={`${STATIC_URL}close-gray.svg`} height="13px" width="13px" style={imageStyle}/>
    </ButtonDiv>
}

const enum DmNoticeType {
    Center,
    AsOtherUser,
    AsSelf,
}

export interface IDmWindowProps {
    username: string,
    myUsername: string,
    open: boolean,
    markAsRead: boolean,
    raiseWindowZIndexToTop: (username: string) => void,
}

// Assuming this class is only instantiated if user is logged in
export class DmWindow extends Component {
    public colorClass: string | undefined
    public readonly username: string

    protected headerBar: HTMLDivElement
    protected chatDiv: HTMLDivElement
    protected input: DmWindowInput
    protected messageDiv: HTMLDivElement
    protected messageList: HTMLDivElement
    protected messageHistoryDiv: HTMLDivElement
    protected usernameLabel: UsernameLabel
    protected followButton: HTMLDivElement
    protected followStar: RoomFollowStar
    private fetchFailed = false
    private menu: DmUserContextMenu | undefined
    private closeToolTip?: ToolTip  // undefined on mobile
    protected followToolTip?: ToolTip  // undefined on mobile
    private myUsername: string
    private isOpen: boolean
    private isShowing = true
    private fetched = false
    private numUnread?: number
    protected lastReceivedMessage?: IPrivateMessage
    private isAllHistoryLoaded = false
    private earliestMessageId: string | undefined
    protected tippingUI: DmTippingUI
    protected tippingWindow: HTMLElement
    protected tippingOverlay: HTMLElement
    protected listenerGroup: ListenerGroup
    private userInfo: IUserInfo
    private canSendDm: boolean
    private raiseWindowZIndexToTop: (username: string) => void
    private dmDuplicateChecker = new DmDuplicateChecker()
    protected loadingDiv: HTMLDivElement
    private latestTimestamp?: Date
    private oldestMessage?: DmMessageItem
    private loadedHighlightTimeout?: number
    private followProhibited: boolean
    protected newMessageNotice?: NewMessageLine
    protected scrollDownButton?: ScrollDownButton
    public static readonly headerHeight = "34px"
    public static readonly expandedHeight = "260px"

    constructor(props: IDmWindowProps) {
        super()
        this.username = props.username
        this.myUsername = props.myUsername
        this.raiseWindowZIndexToTop = props.raiseWindowZIndexToTop
        this.listenerGroup = new ListenerGroup()

        this.initTippingUI()
        this.constructElement()
        this.setFollowStar()
        this.openOrCollapseWindow(props.open, props.markAsRead, true)
        if (isScrollDownNoticeActive()) {
            this.newMessageNotice = new NewMessageLine({
                getUnreadCount: () => this.scrollDownButton?.getUnreadCount(),
                isConversationShowing: () => this.isOpenAndShowing(),
                isScrolledUp: () => this.isScrolledUp(),
                setParentScrollTop: (oldScrollTop: number) => this.setScrollTop(oldScrollTop),
                scrollParentDiv: this.messageDiv,
            })
        }

        const userUid = pageContext.current.loggedInUser?.userUid
        if (userUid !== undefined) {
            const userMessageTopic = new UserMessageTopic(userUid)
            if (userMessageTopic.isSubscribed()) {
                this.enableSend()
            } else {
                userMessageTopic.onSubscribeChange.listen((event) => {
                    if (event.subscribed) {
                        this.enableSend()
                    }
                }).addTo(this.listenerGroup)
            }
        }

        getUserInfo(this.username).then(({ sitewideUser, canPm, canTip }: IUserInfoAndUnread) => {
            this.userInfo = sitewideUser
            this.colorClass = getUsernameColorClass(sitewideUser)
            this.canSendDm = canPm
            if (canTip) {
                this.enableTip()
            }
            this.usernameLabel.applyColor(this.colorClass)
            this.fetchConversation(props.markAsRead)

            roomLoaded.listen((context: IRoomContext) => {
                this.userInfo.isBroadcaster = this.username === context.dossier.room
                this.colorClass = getUsernameColorClass(this.userInfo)
                this.usernameLabel.applyColor(this.colorClass)
            })
        }).catch((err: XhrError) => {
            const errorMessage = new ArgJSONMap(err.xhr.responseText).getStringOrUndefined("error")
            if (errorMessage !== undefined) {
                    modalAlert(errorMessage)
                } else {
                    modalAlert(i18n.errorLoadingDms(this.username))
                }
            removeDmWindowRequest.fire({ username: this.username, deleteWindow: true })
        })
    }

    protected constructElement(): void {
        this.createHeaderBar()
        this.createChatDiv()
        this.constructFollowStarUI()

        const chatWindowStyle: CSSX.Properties = {
            cssFloat: "right",
            position: "relative",
            marginRight: "7px",
            borderRadius: "4px 4px 0px 0px",
            width: "280px",
            height: DmWindow.expandedHeight,
        }

        this.element = <div colorClass="dmWindow" style={chatWindowStyle}>
            {this.headerBar}
            {this.chatDiv}
        </div>
    }

    protected createHeaderBar(): void {
        const chatHeaderStyle: CSSX.Properties = {
            position: "relative",
            height: DmWindow.headerHeight,
            borderRadius: "4px 4px 0px 0px",
            borderBottomWidth: "2px",
            borderBottomStyle: "solid",
            cursor: "pointer",
        }

        this.headerBar = <div
            style={chatHeaderStyle}
            colorClass="dmWindowHeader"
            onMouseEnter={() => {this.highlightHeader()}}
            onMouseLeave={() => {this.unHighlightHeader()}}
            onClick={() => this.openOrCollapseWindow(!this.isOpen)}/>

        this.usernameLabel = new UsernameLabel({ username: this.username, colorClass: this.colorClass, clickUsernameFn: () => this.showUCM() })
        this.headerBar.appendChild(this.usernameLabel.element)

        const closeButton = <CloseButton parentDiv={this.headerBar}
                                         style={{ right: "5px" }}
                                         data-testid = "dm-close-button"
                                         clickHandler={() => removeDmWindowRequest.fire({ username: this.username })} />
        this.closeToolTip = new ToolTip({ message: i18n.dmCloseTabShortcut })
        addEventListenerPoly("mouseenter", closeButton, () => {
            this.closeToolTip?.show()
        })
        addEventListenerPoly("mouseleave", closeButton, () => {
            this.closeToolTip?.hide()
        })
        closeButton.appendChild(this.closeToolTip.element)
        this.headerBar.appendChild(closeButton)
    }

    protected initTippingUI(): void {
        this.tippingUI = new DmTippingUI(this.username, () => this.hideTipping())
        this.tippingWindow = this.tippingUI.createUI()
        this.tippingOverlay = this.tippingUI.createOverlay()
    }

    private enableSend(): void {
        this.input.enableSend()
    }

    private enableTip(): void {
        this.input.enableTip()
    }

    private removeMenu = (): void => {
        if (this.menu !== undefined) {
            this.element.removeChild(this.menu.overlay)
            this.element.removeChild(this.menu.element)
            this.unHighlightHeader()
            this.menu = undefined
        }
    }

    public changeWindowZIndex(num: number): void {
        this.element.style.zIndex = `${num}`
    }

    private popoutConvo = (): void => {
        DmPopout.show(this.username)
        this.openOrCollapseWindow(false)
    }

    // TODO add logic like chatconnection.ignore where it handles hitting the ignore limit
    private ignoreUser = (): void => {
        const onFailure = () => this.handleNewNotice(this.createLogMessage(i18n.errorIgnoringUser(this.username)), false)
        addIgnoreUser(this.username).then(success => !success && onFailure()).catch(onFailure)
    }

    private unignoreUser = (): void => {
        const onFailure = () => this.handleNewNotice(this.createLogMessage(i18n.errorUnignoringUser(this.username)), false)
        removeIgnoreUser(this.username).then(success => !success && onFailure()).catch(onFailure)
    }

    public onIgnoreUnignore(ignored: boolean): void {
        if (ignored) {
            this.handleNewNotice(this.createLogMessage(i18n.ignoringUser(this.username)))
        } else {
            this.handleNewNotice(this.createLogMessage(i18n.noLongerIgnoring(this.username)))
        }
    }

    protected showUCM(): void {
        if (!this.isOpen) {
            this.openOrCollapseWindow(true)
            return
        }
        if (this.menu === undefined) {
            const usernameLabelClone = this.usernameLabel.clone()
            usernameLabelClone.activateUsernameLink()
            usernameLabelClone.element.style.margin = "0"
            this.menu = new DmUserContextMenu({
                currentUsername: this.myUsername,
                userInfo: this.userInfo,
                usernameLabel: usernameLabelClone,
                tearDownFunc: this.removeMenu,
                popoutFunc: this.popoutConvo,
                ignoreFunc: this.ignoreUser,
                unignoreFunc: this.unignoreUser,
                includePopoutLink: !(this instanceof PopoutDmWindow),
                dontRepositionForThumbnail: this instanceof PopoutDmWindow,
            })
            this.element.appendChild(this.menu.overlay)
            this.element.appendChild(this.menu.element)
        }

        this.menu.setLastReceivedMessage(this.lastReceivedMessage)
        this.raiseWindowZIndexToTop(this.username)

        if (this.menu.element.style.display === "none") {
            this.menu.element.style.display = "block"
            this.menu.showOverlay()
            addPageAction("DmMenuClicked", { "location": "DmWindow" })
        }
    }

    protected showTipping(tipAmount?: number, message?: string, delayFocus?: boolean): void {
        this.tippingUI.setFields(tipAmount, message)
        this.element.appendChild(this.tippingOverlay)
        this.element.appendChild(this.tippingWindow)

        if (delayFocus === true) {
            window.setTimeout(() => {
                this.tippingUI.focus()
            }, 990)
        } else {
            this.tippingUI.focus()
        }

        addPageAction("SendTipViewed", {
            "location": "DmWindow",
            "tipType": TipType.public,
            "localStorage": isLocalStorageSupported(),
        })
    }

    protected hideTipping(): void {
        this.tippingOverlay.parentElement?.removeChild(this.tippingOverlay)
        this.tippingWindow.parentElement?.removeChild(this.tippingWindow)
    }

    public onTip(tipAlert: IPushTipAlert): void {
        const isFromCurrentUser = tipAlert.fromUser.username === this.myUsername
        let notice
        if (isFromCurrentUser) {
            notice = i18n.sentTipDmNotice(tipAlert.amount)
        } else {
            if (tipAlert.message.length > 0) {
                notice = i18n.receivedTipDmNoticeWithMessage(this.username, tipAlert.amount, tipAlert.message)
            } else {
                notice = i18n.receivedTipDmNotice(this.username, tipAlert.amount)
            }
        }
        const noticeDiv = this.createLogMessage(notice, isFromCurrentUser ? DmNoticeType.AsSelf : DmNoticeType.AsOtherUser, "tipNotice")
        applyStyles(noticeDiv, { fontWeight: "bold" })
        this.handleNewNotice(noticeDiv, tipAlert.roomType !== RoomType.DM, isFromCurrentUser)
    }

    public getNumUnread(): number {
        return this.numUnread ?? 0
    }

    protected createChatDiv(): void {
        const chatBoxStyle: CSSX.Properties = {
            height: `calc(100% - ${DmWindow.headerHeight})`,
            position: "relative",
            fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
            fontSize: "12px",
        }
        const chatMessagesDivStyle: CSSX.Properties = {
            position: "relative",
            height: `calc(100% - ${DmWindowInput.height})`,
            paddingBottom: "8px",
            boxSizing: "border-box",
            overflowY: "auto",
        }
        if (isScrollDownNoticeActive()) {
            chatMessagesDivStyle.overflowX = "hidden"
        }
        const chatListStyle: CSSX.Properties = {
            width: "100%",
            boxSizing: "border-box",
            padding: "0px 4px",
        }

        let mouseDownX: number, mouseDownY: number
        const messagesDivMouseDown = (e: MouseEvent) => {
            mouseDownX = e.pageX
            mouseDownY = e.pageY
        }
        const messagesDivMouseUp = (e: MouseEvent) => {
            const minDragDistance = 2  // Arbitrary distance to count mousedown -> mouseup as drag rather than click
            const deltaX = e.pageX - mouseDownX
            const deltaY = e.pageY - mouseDownY
            const distance = Math.sqrt(deltaX*deltaX + deltaY*deltaY)
            if (distance < minDragDistance) {
                this.input.focus()
            }
        }

        // const emojiButtonClickHandler = () => {
        //     standardEmojiRequest.fire(this.emojiButton)
        // }

        this.chatDiv = <div style={chatBoxStyle} ref={(el: HTMLDivElement) => {this.chatDiv = el}}>
            <div style={chatMessagesDivStyle}
                 onScroll={() => {this.handleChatDivScroll()}}
                 onMouseDown={messagesDivMouseDown}
                 onMouseUp={messagesDivMouseUp}
                 ref={(el: HTMLDivElement) => this.messageDiv = el}>
                <div style={chatListStyle} ref={(el: HTMLDivElement) => this.messageList = el}>
                    { this.createLogMessage(i18n.privateConversationWithText(this.username)) }
                    { this.createLogMessage(i18n.conversationCautionMessage(currentSiteSettings.siteName)) }
                    <div ref={(el: HTMLDivElement) => this.messageHistoryDiv = el}>
                        <div style={{ marginTop: "44px" }} ref={(el: HTMLDivElement) => this.loadingDiv = el}>
                            <SpinnerIcon extraStyle={{ margin: "auto" }}/>
                        </div>
                    </div>
                </div>
            </div>
            <DmWindowInput
                classRef={(dmWindowInput: DmWindowInput) => this.input = dmWindowInput}
                toUsername={this.username}
                onInputExpand={() => this.onInputExpand()}
                onInputCollapse={() => this.onInputCollapse()}
                showTipping={(amount, message) => this.showTipping(amount, message)}
                sendDmFailedCallback={(error: IPMError) => {this.handleNewNotice(this.createLogMessage(error.errorMessage), false)}}
                onFocus={() => this.onInputFocus()}
            />
            { isScrollDownNoticeActive() &&
                <ScrollDownButton scrollToBottom={() => this.scrollToBottom()}
                                  bottomStyle={`calc(${DmWindowInput.height} + 4px)`}
                                  classRef={(scrollDownButton: ScrollDownButton) => this.scrollDownButton = scrollDownButton}
                /> }
        </div>
    }

    protected handleChatDivScroll(): void {
        if (this.messageDiv.scrollTop === 0 && !this.isAllHistoryLoaded) {
            this.fetchConversation(true, this.earliestMessageId)
        }

        // Intentionally using different `isScrolledUp` buffer sizes for officially marking messages read vs managing scrollDownButton
        if (!this.isScrolledUp(0)) {
            this.fireDmsRead()
        }

        if (this.isScrolledUp()) {
            this.scrollDownButton?.showElement()
        } else {
            this.scrollDownButton?.hideElement()
            this.scrollDownButton?.clearUnread()
        }
    }

    // Assuming class is created only with logged-in user & valid broadcaster username -- consider having checks still?
    protected setFollowStar(): void {
        isFollowAllowed(this.username).then(canFollow => {
            this.followProhibited = !canFollow
            getCb(`follow/is_following/${this.username}/`).then((response) => {
                const data = JSON.parse(response.responseText)
                const isFollowing: boolean = data["following"]
                if (canFollow || isFollowing) {
                    this.followUnfollow(isFollowing)
                }
            }).catch(ignoreCatch)
        }).catch(ignoreCatch)
    }

    protected constructFollowStarUI(): void {
        this.followButton = <ButtonDiv parentDiv={this.headerBar} testID="dm-follow-button" style={{ right: "30px" }} clickHandler={() => {}}/>
        this.followStar = new RoomFollowStar({ slug: this.username, hideTitle: true })
        applyStyles(this.followStar, {
            height: "20px",
            width: "20px",
            backgroundSize: "20px 20px",
            backgroundPosition: "center",
            padding: "2px 3px",
            margin: "0px",
        })

        this.constructFollowToolTip()

        addEventListenerPoly("mouseenter", this.followButton, () => {
            if (this.followStar.isShown()) {
                this.followToolTip?.show()
            }
        })
        addEventListenerPoly("mouseleave", this.followButton, () => {
            this.followToolTip?.hide()
        })

        this.followButton.appendChild(this.followStar.element)
        if (this.followToolTip !== undefined) {
            this.followButton.appendChild(this.followToolTip.element)
        }
        this.headerBar.appendChild(this.followButton)
    }

    protected constructFollowToolTip(): void {
        this.followToolTip = new ToolTip({ message: i18n.unfollowText })
    }

    public followUnfollow(follow: boolean): void {
        this.followStar.setFollowing(follow)
        this.followToolTip?.changeMessage(follow ? i18n.unfollowText : i18n.followText)
        if (this.followProhibited && !follow) {
            this.followButton.style.display = "none"
        } else {
            this.followButton.style.display = "block"
        }
    }

    public getUserInfo(): IUserInfo {
        return this.userInfo
    }

    public setUserInfo(newUserInfo: Partial<IUserInfo>): void {
        this.userInfo = { ...this.userInfo, ...newUserInfo }
        this.usernameLabel.applyColor(getUsernameColorClass(this.userInfo))
    }

    protected openWindow(markAsRead: boolean, noFocus = false): void {
        this.chatDiv.style.display = "block"
        removeColorClass(this.element, "collapsed")
        this.element.style.bottom = DmWindow.expandedHeight
        this.element.style.height = DmWindow.expandedHeight
        this.closeToolTip?.changeMessage(i18n.dmCloseTabShortcut)
        this.usernameLabel.toggleUnreadDot(true)
        if (markAsRead) {
            if (!noFocus) {
                this.scrollAndFocus()
            }
            this.fireDmsRead()
        }
    }

    protected collapseWindow(): void {
        this.chatDiv.style.display = "none"
        addColorClass(this.element, "collapsed")
        this.element.style.bottom = "36px"
        this.element.style.height = "36px"
        this.closeToolTip?.changeMessage(i18n.dmCloseTab)
        this.usernameLabel.toggleUnreadDot(false)
        if (this.getNumUnread() > 0) {
            this.usernameLabel.updateNumUnread(this.getNumUnread())
        } else {
            // Only clear the newline notice while collapsing dmWindow if there are no unreads (userlabel has no unread count)
            this.newMessageNotice?.remove(true)
        }
        this.hideTipping()
    }

    public openOrCollapseWindow(open: boolean, markAsRead = true, noFocus = false): void {
        this.isOpen = open
        open ? this.openWindow(markAsRead, noFocus) : this.collapseWindow()
        const userOpen: IDmWindow = { username: this.username, isOpen: open }
        updateWindowIsOpenEvent.fire(userOpen)
        dmsHeightChanged.fire(undefined)
    }

    public setIsShowing(shown: boolean): void {
        this.isShowing = shown
        if (shown && this.fetchFailed) {
            // If fetching the conversation failed originally, try again next time the window is shown
            this.fetchConversation(true)
        }
    }

    public removeFromDOM(): void {
        this.closeToolTip?.hide()
        this.hideTipping()
        this.element.remove()
        this.setIsShowing(false)
        this.newMessageNotice?.remove(true)
    }

    public isWindowOpen(): boolean {
        return this.isOpen
    }

    public isInputFocused(): boolean {
        return this.input.isFocused()
    }

    public blurInput(): void {
        this.input.blur()
    }

    protected isScrolledUp(buffer=20): boolean {
        return this.messageList.offsetHeight - (this.messageDiv.scrollTop + this.messageDiv.offsetHeight) > buffer
    }

    public scrollToBottom(): void {
        if (isiOS()) {
            stopScrollMomentum(this.messageDiv)
        }
        this.messageDiv.scrollTop = this.messageList.offsetHeight

        this.scrollDownButton?.hideElement()
    }

    private handleImageLoadScrolling(messageDiv: HTMLDivElement): void {
        if (!this.isScrolledUp()) {
            messageDiv.querySelectorAll("img").forEach((img) => {
                const src = img.src
                img.src = ""
                img.onload = () => this.scrollToBottom()
                img.src = src
            })
        }
    }

    public scrollAndFocus(): void {
        this.scrollToBottom()
        if (document.activeElement !== this.input.element) {
            this.input.focus()
        }
    }

    protected onInputFocus(): void {
        if (!this.isScrolledUp()) {
            this.fireDmsRead()
        }
    }

    private onInputExpand(): void {
        const wasScrolledUp = this.isScrolledUp()
        this.messageDiv.style.height = `calc(100% - ${DmWindowInput.expandedHeight})`
        if (!wasScrolledUp) {
            this.scrollToBottom()
        }
    }

    private onInputCollapse(): void {
        this.messageDiv.style.height = `calc(100% - ${DmWindowInput.height})`
    }

    private fetchConversation(markAsRead: boolean, offset?: string): void {
        getDmHistoryMessages(this.username, offset).then((data) => {
            const dmList = data.messages

            if (dmList.length < pmHistoryBatchSize) {
                this.isAllHistoryLoaded = true
            }
            if (!this.fetched) {
                this.fetched = true
                this.fetchFailed = false
                this.loadingDiv.remove()
                // To handle possible unread messages in an un-fetched & overflown chat window that gets clicked on
                this.setNumUnread(data.numUnread)
                // Explicitly increment the scrollbutton with the number of unreads here to make sure we have the newline notice
                // before the correct message from history after page loads
                this.scrollDownButton?.incUnread(data.numUnread)
                if (markAsRead) {
                    this.fireDmsRead()
                }
            }
            if (dmList.length > 0) {
                this.earliestMessageId = dmList[0].messageID
                if (this.lastReceivedMessage === undefined) {
                    this.lastReceivedMessage = [...dmList].reverse().find(dm => dm.fromUser.username === this.username)
                }
                if (this.latestTimestamp === undefined) {
                    this.latestTimestamp = dmList[dmList.length - 1].createdAt
                }

                // Add new messages to bottom or top of chat
                if (offset === undefined) {
                    const dedupedDmList = this.dmDuplicateChecker.filterDuplicates(dmList, true)
                    this.addPreviousBatch(dedupedDmList, data.numUnread)
                    this.maybeAddCanDmNotice()
                    this.scrollToBottom()
                    this.handleImageLoadScrolling(this.messageHistoryDiv)
                } else {
                    this.addPreviousBatch(dmList)
                }
            } else if (offset === undefined) {
                this.maybeAddCanDmNotice()
            }
            this.maybeUpdateConversationListItem(dmList)
        }).catch(() => {
            this.handleNewNotice(this.createLogMessage(i18n.errorLoadingConversationHistory), false)
            this.loadingDiv.remove()
            this.fetchFailed = true
        })
    }

    private maybeUpdateConversationListItem(dmList: IPrivateMessage[]) {
        if (dmList.length === 0) {
            return
        }
        const lastMessage = dmList[dmList.length - 1]
        ConversationListData.conversationLoaded.fire({
            message: lastMessage.message,
            numUnread: this.numUnread ?? 0,
            time: this.latestTimestamp?.getTime(),
            fromUsername: lastMessage.fromUser.username,
            otherUser: this.userInfo,
            hasMedia: lastMessage.mediaList.length > 0,
            room: "",
        })
    }

    private addPreviousBatch(dmList: IPrivateMessage[], firstBatchUnread = -1): void {
        if (dmList.length === 0) {
            return
        }

        const batchContainer = <div/>
        let currentMessage: DmMessageItem

        if (this.oldestMessage !== undefined) {
            this.oldestMessage.updateForPrevTimestamp(dmList[dmList.length - 1].createdAt)
        }

        let batchLatestTimestamp: Date | undefined
        dmList.forEach((dmObject, idx) => {
            const isMine = dmObject.fromUser.username === this.myUsername
            currentMessage = new DmMessageItem({
                dm: dmObject,
                isMine: isMine,
                prevTimestamp: batchLatestTimestamp,
            })
            if (isScrollDownNoticeActive() && idx === dmList.length - firstBatchUnread) {
                // Add new line before any unread DMs in history after page reload/when dm window is opened for the first time
                batchContainer.appendChild(this.newMessageNotice?.element)
            }
            batchContainer.appendChild(currentMessage.element)

            batchLatestTimestamp = dmObject.createdAt
            if (idx === 0) {
                currentMessage.showTimestamp()
                this.oldestMessage = currentMessage
            }
        })


        this.messageHistoryDiv.insertBefore(batchContainer, this.messageHistoryDiv.firstChild)

        // For smooth scrolling effect
        this.messageDiv.scrollTop = batchContainer.offsetHeight
    }

    protected createLogMessage(text: string, noticeType = DmNoticeType.Center, colorClass?: string): HTMLDivElement {
        switch (noticeType) {
            case DmNoticeType.Center:
                const logMessageStyle: CSSX.Properties = {
                    maxWidth: "271px",
                    textAlign: "center",
                    fontSize: "11px",
                    lineHeight: "12px",
                    padding: "4px",
                    margin: "auto",
                }
                return <div style={logMessageStyle} colorClass="logMessage">{text}</div>
            case DmNoticeType.AsSelf:
                return createDmMessageBubble(text, true, "logMessage", colorClass)
            case DmNoticeType.AsOtherUser:
                return createDmMessageBubble(text, false, "logMessage", colorClass)
        }
    }

    private addMessageToEnd(messageEl: HTMLDivElement, isMine: boolean, skipJumpToBottom = false): void {
        const oldScrollTop = this.messageDiv.scrollTop
        const wasScrolledUp = this.isScrolledUp()

        this.handleImageLoadScrolling(messageEl)
        if (!isMine) {
            this.setNumUnread(this.getNumUnread() + 1)
        }

        if ((wasScrolledUp || !this.isOpenAndShowing()) && !isMine) {
            this.scrollDownButton?.incUnread()
        }

        if (this.newMessageNotice?.shouldAppendNewMessageNotice(true, false) === true) {
            this.messageList.appendChild(this.newMessageNotice.element)
        }

        this.messageList.appendChild(messageEl)
        this.handleScrollAfterNewMessage(isMine, wasScrolledUp, oldScrollTop, skipJumpToBottom)

        if (this.isOpenAndShowing() && !wasScrolledUp) {
            this.fireDmsRead()
        }
    }

    private isOpenAndShowing(): boolean {
        return this.isOpen && this.isShowing
    }

    private maybeAddCanDmNotice(): void {
        // TODO update with notice text specific to sitewide DMs
        if (!this.canSendDm) {
            const message = pageContext.current.loggedInUser?.isAgeVerified === true ? i18n.dmSupporterNoticeAgeVerified : i18n.dmSupporterNotice
            this.handleNewNotice(this.createLogMessage(message))
        }
    }

    protected handleNewNotice(c: HTMLDivElement, skipJumpToBottom = true, isMine = true): void {
        this.addMessageToEnd(c, isMine, skipJumpToBottom)
    }

    public handleNewMessage(dm: IPrivateMessage): void {
        if (this.dmDuplicateChecker.isMessageDuplicate(dm, false)) {
            return
        }
        const isMine = dm.fromUser.username === this.myUsername
        if (!isMine) {
            this.lastReceivedMessage = dm
        }
        const messageItem = new DmMessageItem({
            dm: dm,
            isMine: isMine,
            prevTimestamp: this.latestTimestamp,
        })
        this.latestTimestamp = dm.createdAt
        if (this.oldestMessage === undefined) {
            messageItem.showTimestamp()
            this.oldestMessage = messageItem
        }

        this.addMessageToEnd(messageItem.element, isMine)
    }

    private handleScrollAfterNewMessage(isMine: boolean, wasScrolledUp: boolean, oldScrollTop: number, skipJumpToBottom: boolean): void {
        if (!wasScrolledUp || (isMine && !skipJumpToBottom)) {
            this.scrollToBottom()
        } else {
            this.newMessageNotice?.maybeScrollJump(oldScrollTop)
        }
    }

    public setScrollTop(top: number): void {
        this.messageDiv.scrollTo({ top: top })
    }

    private setNumUnread(numUnread: number): void {
        if (numUnread === 0) {
            this.fireDmsRead()
            return
        }
        this.numUnread = numUnread
        this.usernameLabel.updateNumUnread(numUnread)
    }

    protected fireDmsRead(): void {
        if (this.getNumUnread() === 0 || !document.hasFocus()) {
            return
        }
        this.markRead()
        allDmsRead.fire({ username: this.username })
    }

    public markRead(): void {
        if (this.getNumUnread() === 0) {
            return
        }
        this.numUnread = 0
        this.usernameLabel.updateNumUnread(0)
    }

    private highlightHeader(): void {
        addColorClass(this.headerBar, "highlight")
    }

    private unHighlightHeader(): void {
        removeColorClass(this.headerBar, "highlight")
    }

    public highlightHeaderForShow(): void {
        addColorClass(this.headerBar, "loadedHighlight")
        window.clearTimeout(this.loadedHighlightTimeout)
        this.loadedHighlightTimeout = window.setTimeout(() => removeColorClass(this.headerBar, "loadedHighlight"), 4*1000)
    }
}

// TODO: fix inheritance/import errors and put into a new file in mobilelib
export class MobileDmWindow extends DmWindow {
    private userPanel?: DmUserPanel

    constructor(props: IDmWindowProps) {
        super(props)

        if ("ResizeObserver" in window) {
            const resizeObserver = new ResizeObserver(() => this.onResize())
            resizeObserver.observe(this.messageDiv)
        }
    }

    protected constructElement(): void {
        this.createHeaderBar()
        this.createChatDiv()
        this.constructFollowStarUI()

        const chatWindowStyle: CSSX.Properties = {
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "1px",
        }

        this.element = (
            <div colorClass="dmWindow" style={chatWindowStyle}>
                {this.headerBar}
                {this.chatDiv}
                { isScrollDownNoticeActive() &&
                    <ScrollDownButton scrollToBottom={() => this.scrollToBottom()}
                                        bottomStyle={`calc(${DmWindowInput.height} + 4px)`}
                                        classRef={(scrollDownButton: ScrollDownButton) => this.scrollDownButton = scrollDownButton}
                    /> }
                <MobileDmWindowInput
                    classRef={(dmWindowInput: MobileDmWindowInput) => this.input = dmWindowInput}
                    toUsername={this.username}
                    onInputExpand={() => {}}
                    onInputCollapse={() => {}}
                    showTipping={(amount, message, delayFocus) => this.showTipping(amount, message, delayFocus)}
                    sendDmFailedCallback={(error: IPMError) => {this.handleNewNotice(this.createLogMessage(error.errorMessage), false)}}
                    onFocus={() => this.onInputFocus()}
                />
            </div>
        )
    }

    protected createHeaderBar(): void {
        const chatHeaderStyle: CSSX.Properties = {
            position: "relative",
            height: "34px",
            borderRadius: "4px 4px 0px 0px",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
        }

        this.headerBar = <div style={chatHeaderStyle} colorClass="dmWindowHeader" />
        this.usernameLabel = new UsernameLabel({ username: this.username, colorClass: this.colorClass, clickUsernameFn: () => this.showUserPanel() })
        this.headerBar.appendChild(this.usernameLabel.element)
    }

    protected createChatDiv(): void {
        const chatBoxStyle: CSSX.Properties = {
            fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
            fontSize: "12px",
            flex: 1,
            height: `calc(100% - ${DmWindow.headerHeight} - ${DmWindowInput.height})`,
            display: "flex",
            position: "relative",
        }
        const chatMessagesDivStyle: CSSX.Properties = {
            width: "100%",
            height: "100%",
            overflowY: "auto",
            position: "relative",
        }
        const chatListStyle: CSSX.Properties = {
            width: "100%",
            boxSizing: "border-box",
            padding: "4px 4px 8px 4px",
        }

        // const emojiButtonClickHandler = () => {
        //     standardEmojiRequest.fire(this.emojiButton)
        // }

        this.chatDiv = (
            <div style={chatBoxStyle} ref={(el: HTMLDivElement) => {this.chatDiv = el}}>
                <div style={chatMessagesDivStyle}
                    onScroll={() => {this.handleChatDivScroll()}}
                    ref={(el: HTMLDivElement) => this.messageDiv = el}>
                    <div style={chatListStyle} ref={(el: HTMLDivElement) => this.messageList = el}>
                        {this.createLogMessage(i18n.privateConversationWithText(this.username))}
                        {this.createLogMessage(i18n.conversationCautionMessage(currentSiteSettings.siteName))}
                        <div ref={(el: HTMLDivElement) => this.messageHistoryDiv = el}>
                            <div style={{ marginTop: "44px" }} ref={(el: HTMLDivElement) => this.loadingDiv = el}>
                                <SpinnerIcon extraStyle={{ margin: "auto" }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected initTippingUI(): void {
        this.tippingUI = new MobileDmTippingUI(this.username, () => this.hideTipping())
        this.tippingWindow = this.tippingUI.createUI()
        this.tippingOverlay = this.tippingUI.createOverlay()
    }

    protected constructFollowStarUI(): void {
        this.followStar = new RoomFollowStar({ slug: this.username })
        applyStyles(this.followStar, {
            height: "20px",
            width: "20px",
            backgroundSize: "20px 20px",
            backgroundPosition: "center",
            padding: "2px 3px",
            margin: "0px",
        })

        this.followButton = <ButtonDiv style={{ right: "8px" }} clickHandler={() => {}} />
        this.followButton.appendChild(this.followStar.element)
        this.headerBar.appendChild(this.followButton)
    }

    public openOrCollapseWindow(open: boolean, markAsRead = true): void {
        open ? this.showElement() : this.hideElement()
        super.openOrCollapseWindow(open, markAsRead)
    }

    hideElement(): void {
        super.hideElement()
        this.removeUserPanelDOM()
    }

    protected openWindow(markAsRead: boolean): void {
        this.chatDiv.style.display = "flex"
        removeColorClass(this.element, "collapsed")
        this.scrollToBottom()

        if (markAsRead) {
            this.fireDmsRead()
        }
    }

    public resizeTipCallout(): void {
        const viewportHeight = getViewportHeight()

        if (viewportHeight < 250) {
            // Make tip callout visible and scrollable on small viewport
            applyStyles(this.tippingUI, {
                height: `${viewportHeight}px`,
                overflow: "scroll",
                transform: `translate(-50%)`,
                top: "",
                bottom: "0",
                overscrollBehavior: "contain",
            })
        } else {
            // Restore initial styles when viewport is big again
            applyStyles(this.tippingUI, {
                height: "",
                overflow: "",
                transform: `translate(-50%, -50%)`,
                top: "50%",
                bottom: "",
                overscrollBehavior: "",
            })
        }
    }

    protected showUCM(): void {
        return
    }

    private showUserPanel(): void {
        if (this.userPanel === undefined) {
            this.userPanel = new DmUserPanel()
            applyStyles(this.userPanel.element, {
                top: `${this.headerBar.offsetHeight}px`,
                backgroundColor: "#FFF",
                fontSize: "12px",
            })

            toggleDms.listen(() => {
                this.removeUserPanelDOM()
            }).addTo(this.listenerGroup)

            this.userPanel.overlayClick.listen(() => {
                this.removeUserPanelDOM()
            }).addTo(this.listenerGroup)
        }

        this.userPanel.updateContents(this.username, this.lastReceivedMessage)
        this.headerBar.appendChild(this.userPanel.overlay)
        this.headerBar.appendChild(this.userPanel.element)
        this.userPanel.showOverlay()
    }

    private removeUserPanelDOM(): void {
        this.userPanel?.hide()
    }

    public showElement(): void {
        super.showElement("flex")
    }

    public highlightHeaderForLoad(): void {}

    private onResize(): void {
        // 600 is an estimate for the max vertical space a virtual keyboard might take up
        if (!this.isScrolledUp(600)) {
            this.scrollToBottom()
        }
    }
}

export class PopoutDmWindow extends DmWindow {
    constructor(username: string) {
        super({
            username: username,
            myUsername: pageContext.current.loggedInUser?.username ?? "",
            open: true,
            markAsRead: true,
            raiseWindowZIndexToTop: () => {},
        })

        if ("ResizeObserver" in window) {
            const resizeObserver = new ResizeObserver(() => this.onResize())
            resizeObserver.observe(this.messageDiv)
        }
    }

    protected constructElement(): void {
        this.createHeaderBar()
        this.createChatDiv()
        this.constructFollowStarUI()

        const chatWindowStyle: CSSX.Properties = {
            position: "relative",
            height: "100%",
        }

        this.element = <div colorClass="dmWindow" style={chatWindowStyle} data-testid="dm-window">
            {this.headerBar}
            {this.chatDiv}
        </div>
    }

    protected createHeaderBar(): void {
        const chatHeaderStyle: CSSX.Properties = {
            position: "relative",
            height: "34px",
            borderRadius: "4px 4px 0px 0px",
            borderBottomWidth: "2px",
            borderBottomStyle: "solid",
        }

        this.headerBar = <div style={chatHeaderStyle} colorClass="dmWindowHeader"/>
        this.usernameLabel = new UsernameLabel({ username: this.username, colorClass: this.colorClass, clickUsernameFn: () => this.showUCM() })
        this.headerBar.appendChild(this.usernameLabel.element)
    }

    protected constructFollowStarUI(): void {
        super.constructFollowStarUI()
        this.followButton.style.right = "8px"
    }

    protected constructFollowToolTip(): void {
        this.followToolTip = new ToolTip({
            message: i18n.unfollowText,
            toolTipBottom: "auto",
            toolTipRight: "40px",
            divotPosition: DivotPosition.Right,
            caretRight: "0px",
            caretBottom: "24px",
        })
    }

    protected openWindow(markAsRead: boolean): void {
        super.openWindow(markAsRead)
        this.element.style.bottom = ""
        this.element.style.height = "100%"
    }

    public highlightHeaderForLoad(): void {}

    private onResize(): void {
        // On my ipad, 516 is the lowest isScrolledUp buffer that will keep the chat scrolled down when rotating from
        // portrait to landscape if the keyboard is up. Rounding up to 600 in case of devices with larger keyboards
        if (!this.isScrolledUp(600)) {
            this.scrollToBottom()
        }
    }
}
