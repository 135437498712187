const keyMap = {
    enter: 13,
    esc: 27,
    modifierKeys: {
        ctrl: 17,
        shift: 16,
        alt: 18,
        leftMeta: 91,
        rightMeta: 93,
        caps: 20,
        tab: 9,
    },
    arrowKeys: {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
    },
}

export function isCharacterKey(keyNum: number | undefined): boolean {
    if (keyNum === undefined || keyNum === keyMap.enter || keyNum === keyMap.esc) {
        return false
    }
    if (Object.values(keyMap.modifierKeys).includes(keyNum)) {
        return false
    }
    if (Object.values(keyMap.arrowKeys).includes(keyNum)) {
        return false
    }
    return true
}
