export class ReconnectLimiter {
    private failures = 0

    millisecondsForRetry(): number {
        this.failures += 1
        return Math.min(10000, 1000 * this.failures)
    }

    reset(): void {
        this.failures = 0
    }
}
