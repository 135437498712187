import { RoomReload } from "../cb/roomList"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { NEEDS_BF_CACHE_FIX } from "./roomImage"

export function applySafariBfCacheImageFix(): void {
    if (!NEEDS_BF_CACHE_FIX) {
        return
    }

    addEventListenerPoly("pageshow", window, (event: PageTransitionEvent) => {
        // This listener will be fired when the page is loaded from the cache on a back-button press.
        if (event.persisted) {
            RoomReload.getInstance().safariReloadAllImages()
        }
    })
}