import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { Debouncer, DebounceTypes } from "../debouncer"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { styleTransition } from "../safeStyle"
import { i18n } from "../translation"
import { getPortraitDimensions } from "./viewportDimension"

export class MenuComponent extends Component<HTMLDivElement> {
    public width: number
    public transitionMS: number
    private visibilityDebouncer: Debouncer

    constructor(width: number, childComponent?: Component, showBackLabel?: boolean, backLabelText?: string) {
        super()

        this.width = getPortraitDimensions().width
        this.transitionMS = 300
        this.visibilityDebouncer = new Debouncer(() => {
            this.element.style.visibility = "hidden"
        }, { bounceLimitMS: this.transitionMS, debounceType: DebounceTypes.debounce })

        applyStyles(this.element, {
            backgroundColor: "#ffffff",
            top: "0",
            bottom: "0",
            zIndex: 1,
            visibility: "visible",
            position: "relative",
        })

        if (showBackLabel === true) {
            applyStyles(this.element, {
                position: "absolute",
                visibility: "hidden",
                right: "-100%",
                width: "100%",
            })

            const BackToSettingsMenuRoot = document.createElement("div")
            const BackToSettingsMenuLabel = ReactComponentRegistry.get("MenuLabel")
            new BackToSettingsMenuLabel({
                "onClick": () => {
                    this.hide()
                },
                "text": backLabelText !== undefined ? backLabelText : i18n.backToRoomMenu,
                "showChevron": true,
                "isBackLabel": true,
            }, BackToSettingsMenuRoot)
            this.element.appendChild(BackToSettingsMenuRoot)
        }

        styleTransition(this.element, `right ${this.transitionMS}ms ease-in-out`)

        if (childComponent !== undefined) {
            this.addChild(childComponent)
        }
    }

    public show(): void {
        this.element.style.visibility = "visible"
        this.element.style.right = "0"
    }

    public hide(): void {
        this.element.style.right = "-100%"

        if (this.element.style.visibility !== "hidden") {
            this.visibilityDebouncer.callFunc()
        }
    }
}
