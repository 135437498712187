import { gettext, interpolate, ngettext } from "./translation";
// translated, human-readable time strings
export function getElapsedTimeString(pastDate) {
    const secondsElapsed = (Date.now() - pastDate.getTime()) / 1000;
    if (secondsElapsed < 60) {
        return gettext("now");
    }
    else if (secondsElapsed < 60 * 60) {
        const minutesElapsed = Math.floor(secondsElapsed / 60);
        return interpolate(ngettext("%(minutes)s minute ago", "%(minutes)s minutes ago", minutesElapsed), { minutes: minutesElapsed }, true);
    }
    else if (secondsElapsed <= 60 * 60 * 24) {
        const hoursElapsed = Math.floor(secondsElapsed / (60 * 60));
        return interpolate(ngettext("%(hours)s hour ago", "%(hours)s hours ago", hoursElapsed), { hours: hoursElapsed }, true);
    }
    else if (secondsElapsed < 60 * 60 * 24 * 7) {
        const daysElapsed = Math.floor(secondsElapsed / (60 * 60 * 24));
        return interpolate(ngettext("%(days)s day ago", "%(days)s days ago", daysElapsed), { days: daysElapsed }, true);
    }
    return pastDate.toLocaleDateString();
}
// shorthand time strings of format "1h2m3s"
export function getFormattedRelativeTimespan(secondsLeft, includeSeconds = true) {
    if (secondsLeft <= 0) {
        return "";
    }
    const hours = Math.floor(secondsLeft / 3600);
    secondsLeft = secondsLeft % 3600;
    const minutes = Math.floor(secondsLeft / 60);
    secondsLeft = secondsLeft % 60;
    let hourString = "";
    if (hours > 1) {
        hourString = `${hours}h`;
    }
    else if (hours === 1) {
        hourString = `${hours}h`;
    }
    let minuteString = "";
    if (minutes >= 1) {
        minuteString = `${minutes}m`;
    }
    else if (minutes === 0) {
        minuteString = includeSeconds ? "" : "<1m";
    }
    if (hours > 0 && minutes > 0) {
        minuteString = ` ${minuteString}`;
    }
    const secondString = includeSeconds ? `${secondsLeft}s` : "";
    return `${hourString}${minuteString}${secondString}`;
}
export function dateNSecondsAgo(seconds) {
    return new Date(Date.now() - seconds * 1000);
}
// Note this can return a negative result if date1 is earlier than date2
export function timeDifferenceSeconds(date1, date2) {
    return (date1.getTime() - date2.getTime()) / 1000;
}
export function minFromEpochTimestamp(epochTimestamp) {
    return (Date.now() - epochTimestamp) / (1000 * 60);
}
