import { Debouncer, DebounceTypes } from "../../common/debouncer"
import { EventRouter } from "../../common/events"

export const cssVhVarUpdate = new EventRouter<void>("cssVhVarUpdate", { reportIfNoListeners: false })

// Sets and maintains css --vh var to be 1% of the actual visual viewport.
// Excludes things like the address bar, virtual keyboard, or the home bar that some androids (samsung only?)
// have at the bottom of the screen
export function initCssVhVarHandling(): void {
    updateCssVhVar()

    const resizeDebouncer = new Debouncer(updateCssVhVar, { bounceLimitMS: 100, debounceType: DebounceTypes.debounce })
    window.visualViewport?.addEventListener("resize", () => resizeDebouncer.callFunc())
    // Fallback for edge cases where the resize event may fail to fire
    window.setInterval(() => resizeDebouncer.callFunc(), 500)
}

function updateCssVhVar(): void {
    const prevVh = document.documentElement.style.getPropertyValue("--vh")
    const viewportHeight = window.visualViewport?.height ?? window.innerHeight
    const newVh = `${viewportHeight * 0.01}px`
    if (newVh !== prevVh) {
        document.documentElement.style.setProperty("--vh", newVh)
        cssVhVarUpdate.fire()
    }
}
