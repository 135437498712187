import { getCb } from "../../common/api"
import { parseRoomUsersInfo } from "../../common/chatconnection/users"
import { UserListSortOptions } from "../../common/roomDossier"
import { buildQueryString } from "../../common/urlUtil"
import { pageContext } from "../interfaces/context"
import { IRoomUsersInfo } from "../roomUsers"

const roomUsersPromises = new Map<string, Promise<IRoomUsersInfo>>()

// TODO update this comment once RoomUsers.fetchRoomUsers is private and the way to go is RoomUsers.getRoomUsersInfo
// Don't call this directly. Go through RoomUsers.fetchRoomUsers instead
export function fetchRoomUsers(room: string, sortBy: UserListSortOptions, forPrivate: boolean): Promise<IRoomUsersInfo> {
    const params: Record<string, string|boolean> = {
        "roomname": room,
        "private": forPrivate,
        "sort_by": sortBy,
        "exclude_staff": true,
    }
    if (!pageContext.current.isNoninteractiveUser) {
        params.exclude_staff = false
    }
    const paramsQueryString = buildQueryString(params)

    // don't fetch user list again if one is already pending.
    const pendingPromise = roomUsersPromises.get(paramsQueryString)
    if (pendingPromise !== undefined) {
        return pendingPromise
    }

    const roomUsersPromise = new Promise<IRoomUsersInfo>((resolve, reject) => {
        getCb(`api/getchatuserlist/?${paramsQueryString}`).then((xhr) => {
            resolve(parseRoomUsersInfo(xhr.responseText))
            window.setTimeout(() => roomUsersPromises.delete(paramsQueryString), 3000)
        }).catch((err) => {
            reject(err)
            roomUsersPromises.delete(paramsQueryString)
        })
    })
    roomUsersPromises.set(paramsQueryString, roomUsersPromise)
    return roomUsersPromise
}
