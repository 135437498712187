export function mouseEventPolyfill(eventType: string, params: MouseEventInit): MouseEvent { // eslint-disable-line complexity
    try {
        return new MouseEvent(eventType, params)
    } catch {
        const mouseEvent = document.createEvent("MouseEvent")
        mouseEvent.initMouseEvent(eventType,
            params.bubbles !== undefined ? params.bubbles : false,
            params.cancelable !== undefined ? params.cancelable : false,
            window,
            0,
            params.screenX !== undefined ? params.screenX : 0,
            params.screenY !== undefined ? params.screenY : 0,
            params.clientX !== undefined ? params.clientX : 0,
            params.clientY !== undefined ? params.clientY : 0,
            params.ctrlKey !== undefined ? params.ctrlKey : false,
            params.altKey !== undefined ? params.altKey : false,
            params.shiftKey !== undefined ? params.shiftKey : false,
            params.metaKey !== undefined ? params.metaKey : false,
            params.button !== undefined ? params.button : 0,
            params.relatedTarget !== undefined ? params.relatedTarget : null, // eslint-disable-line @multimediallc/no-null-usage
        )
        return mouseEvent
    }
}
