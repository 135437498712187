import { isPuffin } from "@multimediallc/web-utils/modernizr"
import { getIgnoredSet } from "../../cb/api/ignore"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { roomLoaded } from "../context"
import { ModalComponent } from "../modalComponent"
import { i18n } from "../translation"
import type { IChatConnection } from "../context"

export abstract class RemoveIgnoredUsersModal extends ModalComponent {
    protected ignoredUsersSelect: HTMLSelectElement
    protected unignoredUsersSelect: HTMLSelectElement
    protected helpText: HTMLDivElement
    protected chatConnection: IChatConnection

    constructor() {
        super()

        roomLoaded.listen(context => this.chatConnection = context.chatConnection)

        this.element.id = "RemoveIgnoredUsersModal"
        this.element.style.position = "fixed"
        this.element.style.width = "450px"
        this.element.style.height = ""
        this.element.style.borderRadius = "6px"
        this.element.style.padding = "10px"
        this.element.style.fontSize = "12px"
        this.element.style.left = "50%"
        this.element.style.top = "50%"
        this.element.style.transform = "translate(-50%, -50%)"

        addColorClass(this.overlay, "overlay")
        this.overlay.style.background = ""
        this.overlay.style.opacity = "0.4"

        const lightCloseButton = this.createCloseButton(`${STATIC_URL}close.svg`)
        addColorClass(lightCloseButton, "lightCloseButton")
        lightCloseButton.style.opacity = "0.5"
        this.element.appendChild(lightCloseButton)
        const darkCloseButton = this.createCloseButton(`${STATIC_URL}close-gray.svg`)
        addColorClass(darkCloseButton, "darkCloseButton")
        this.element.appendChild(darkCloseButton)

        const header = document.createElement("div")
        addColorClass(header, "header")
        header.innerText = i18n.removeIgnoredUsers
        header.style.fontSize = "15px"
        header.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        header.style.textAlign = "center"
        this.element.appendChild(header)

        this.helpText = document.createElement("div")
        addColorClass(this.helpText, colorClass.textColor)
        this.helpText.innerText = ""
        this.helpText.style.margin = "10px 0"
        this.helpText.style.textAlign = "center"
        this.element.appendChild(this.helpText)

        this.element.appendChild(this.createIgnoreWrapper())
        this.element.appendChild(this.createUnignoreWrapper())

        const saveButtonWrapper = document.createElement("div")
        saveButtonWrapper.style.textAlign = "right"

        const saveButton = document.createElement("span")
        addColorClass(saveButton, "saveButton")
        saveButton.innerText = i18n.saveCAPS
        saveButton.style.display = "inline-block"
        addColorClass(saveButton, "gradient")
        saveButton.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        saveButton.style.fontSize = "14px"
        saveButton.style.padding = "5px 15px 5px 15px"
        saveButton.style.marginTop = "10px"
        saveButton.style.borderRadius = "4px"
        saveButton.style.cursor = "pointer"
        saveButton.onclick = () => {
            this.saveButtonHandler()
        }
        saveButtonWrapper.appendChild(saveButton)

        this.element.appendChild(saveButtonWrapper)

        this.overlayClick.listen(() => {
            this.hide()
        })
    }

    protected createSelectLabel(text: string): HTMLDivElement {
        const selectLabel = document.createElement("div")
        addColorClass(selectLabel, "bluetxt")
        selectLabel.textContent = text
        selectLabel.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        selectLabel.style.fontWeight = "bold"
        return selectLabel
    }

    protected createMultipleSelect(): HTMLSelectElement {
        const multipleSelect = document.createElement("select")
        addColorClass(multipleSelect, "select")
        multipleSelect.multiple = true
        return multipleSelect
    }

    private createCloseButton(icon: string): HTMLImageElement {
        const closeButton = document.createElement("img")
        closeButton.src = icon
        closeButton.style.position = "absolute"
        closeButton.style.width = "14px"
        closeButton.style.height = "14px"
        closeButton.style.top = "10px"
        closeButton.style.right = "10px"
        closeButton.style.cursor = "pointer"
        closeButton.onclick = () => {
            this.hide()
        }
        closeButton.onmouseenter = () => {
            closeButton.style.opacity = "1"
        }
        closeButton.onmouseleave = () => {
            closeButton.style.opacity = "0.5"
        }
        return closeButton
    }

    abstract initAndShow(): void

    protected abstract createIgnoreWrapper(): HTMLDivElement

    protected abstract createUnignoreWrapper(): HTMLDivElement

    protected abstract saveButtonHandler(): void
}

export class DesktopRemoveIgnoredUsersModal extends RemoveIgnoredUsersModal {
    private marginBetweenIgnoreUnignorePx: number

    constructor() {
        super()

        this.helpText.innerText = i18n.clickToUnignoreText
    }

    protected initData(): void {
        this.marginBetweenIgnoreUnignorePx = 10
    }

    public initAndShow(): void {
        while (this.ignoredUsersSelect.firstChild !== null) {
            this.ignoredUsersSelect.removeChild(this.ignoredUsersSelect.firstChild)
        }
        while (this.unignoredUsersSelect.firstChild !== null) {
            this.unignoredUsersSelect.removeChild(this.unignoredUsersSelect.firstChild)
        }
        const ignoredUsers = getIgnoredSet(true)
        for (const user of ignoredUsers) {
            const option = document.createElement("option")
            option.innerText = user
            option.value = user
            this.ignoredUsersSelect.appendChild(option)
        }
        super.show()
    }

    protected createIgnoreWrapper(): HTMLDivElement {
        const ignoredUsersWrapper = document.createElement("div")
        ignoredUsersWrapper.style.display = "inline-block"
        ignoredUsersWrapper.style.marginRight = `${this.marginBetweenIgnoreUnignorePx}px`
        ignoredUsersWrapper.style.width = `calc(50% - ${this.marginBetweenIgnoreUnignorePx / 2}px)`

        const ignoredUsersLabel = this.createSelectLabel(i18n.ignoredUsers)
        ignoredUsersWrapper.appendChild(ignoredUsersLabel)

        this.ignoredUsersSelect = this.createMultipleSelect()
        this.ignoredUsersSelect.style.borderWidth = "1px"
        this.ignoredUsersSelect.style.borderStyle = "solid"
        this.ignoredUsersSelect.style.borderRadius = "4px"
        this.ignoredUsersSelect.style.marginTop = "4px"
        this.ignoredUsersSelect.style.width = "100%"
        this.ignoredUsersSelect.style.height = "200px"
        this.ignoredUsersSelect.style.padding = "2px"
        this.ignoredUsersSelect.style.overflow = "auto"
        this.ignoredUsersSelect.onchange = () => {
            const selectedCount = this.ignoredUsersSelect.selectedOptions.length
            const selectedList = [...this.ignoredUsersSelect.selectedOptions]
            for (let i = 0; i < selectedCount; i += 1) {
                const selectedOption = selectedList[i]
                selectedOption.selected = false
                this.unignoredUsersSelect.appendChild(selectedOption)
            }
        }
        ignoredUsersWrapper.appendChild(this.ignoredUsersSelect)
        return ignoredUsersWrapper
    }

    protected createUnignoreWrapper(): HTMLDivElement {
        const unignoredUsersWrapper = document.createElement("div")
        unignoredUsersWrapper.style.display = "inline-block"
        unignoredUsersWrapper.style.width = `calc(50% - ${this.marginBetweenIgnoreUnignorePx / 2}px)`

        const unignoredUsersLabel = this.createSelectLabel(i18n.unignoredUsers)
        unignoredUsersWrapper.appendChild(unignoredUsersLabel)

        this.unignoredUsersSelect = this.createMultipleSelect()
        this.unignoredUsersSelect.style.borderWidth = "1px"
        this.unignoredUsersSelect.style.borderStyle = "solid"
        this.unignoredUsersSelect.style.borderRadius = "4px"
        this.unignoredUsersSelect.style.marginTop = "4px"
        this.unignoredUsersSelect.style.width = "100%"
        this.unignoredUsersSelect.style.height = "200px"
        this.unignoredUsersSelect.style.padding = "2px"
        this.unignoredUsersSelect.style.overflow = "auto"
        this.unignoredUsersSelect.onchange = () => {
            const selectedCount = this.unignoredUsersSelect.selectedOptions.length
            const selectedList = [...this.unignoredUsersSelect.selectedOptions]
            for (let i = 0; i < selectedCount; i += 1) {
                const selectedOption = selectedList[i]
                selectedOption.selected = false
                this.ignoredUsersSelect.appendChild(selectedOption)
            }
        }
        unignoredUsersWrapper.appendChild(this.unignoredUsersSelect)
        return unignoredUsersWrapper
    }

    protected saveButtonHandler(): void {
        for (const option of this.unignoredUsersSelect.options) {
            this.chatConnection.unignore(option.value)  // eslint-disable-line @typescript-eslint/no-floating-promises
        }
        this.hide()
    }
}

// For desktop site on mobile devices. Mobile site uses ManageIgnoredUsers
export class TabletRemoveIgnoredUsersModal extends RemoveIgnoredUsersModal {
    constructor() {
        super()

        this.element.style.width = "400px"
        this.helpText.innerText = i18n.clickToUnignoreMobileText
    }

    public initAndShow(): void {
        while (this.unignoredUsersSelect.firstChild !== null) {
            this.unignoredUsersSelect.removeChild(this.unignoredUsersSelect.firstChild)
        }

        const ignoredUsers = getIgnoredSet(true)
        for (const user of ignoredUsers) {
            const option = document.createElement("option")
            option.innerText = user
            option.value = user
            this.unignoredUsersSelect.appendChild(option)
        }
        super.show()
    }

    protected createIgnoreWrapper(): HTMLDivElement {
        // Mobile version will have only one select multiple field for ignoring/unignoring users
        return document.createElement("div")
    }

    protected createUnignoreWrapper(): HTMLDivElement {
        const unignoredUsersWrapper = document.createElement("div")
        unignoredUsersWrapper.style.display = "inline-block"

        const unignoredUsersLabel = this.createSelectLabel(i18n.unignoredUsers)
        unignoredUsersWrapper.appendChild(unignoredUsersLabel)

        this.unignoredUsersSelect = this.createMultipleSelect()
        this.unignoredUsersSelect.style.width = "200px"
        if (isPuffin()) {
            this.unignoredUsersSelect.style.height = "200px"
        }
        unignoredUsersWrapper.appendChild(this.unignoredUsersSelect)
        return unignoredUsersWrapper
    }

    protected saveButtonHandler(): void {
        for (const option of this.unignoredUsersSelect.selectedOptions) {
            this.chatConnection.unignore(option.value)  // eslint-disable-line @typescript-eslint/no-floating-promises
        }
        this.hide()
    }
}
